export enum AlertType {
  success = 'success',
  warning = 'warning',
  error = 'error',
  danger = 'danger',
  info = 'info',
}

export type AlertProps = {
  id: string;
  message: string;
  type: AlertType;
};

export type AlertStateType = {
  alerts: AlertProps[];
};

export const AlertState: AlertStateType = {
  alerts: [],
};
