import { ReactComponent as AnghamiLight } from '../../../../assets/images/music-services/anghami-light.svg';
import { ReactComponent as AnghamiDark } from '../../../../assets/images/music-services/anghami-dark.svg';
import { ReactComponent as AnghamiLightPadding } from '../../../../assets/images/music-services/anghami-light-padding.svg';
import { ReactComponent as AnghamiDarkPadding } from '../../../../assets/images/music-services/anghami-dark-padding.svg';
import { ReactComponent as AnghamiColorLight } from '../../../../assets/images/music-services/anghami-color-light.svg';
import { ReactComponent as AnghamiColorDark } from '../../../../assets/images/music-services/anghami-color-dark.svg';
import { ReactComponent as AnghamiColorLightPadding } from '../../../../assets/images/music-services/anghami-color-light-padding.svg';
import { ReactComponent as AnghamiColorDarkPadding } from '../../../../assets/images/music-services/anghami-color-dark-padding.svg';
import { ReactComponent as AnghamiDarkDanger } from '../../../../assets/images/music-services-danger/anghami-dark-danger.svg';
import { ReactComponent as AnghamiLightDanger } from '../../../../assets/images/music-services-danger/anghami-light-danger.svg';
import { ReactComponent as AnghamiLightDangerPadding } from '../../../../assets/images/music-services-danger/anghami-light-padding-danger.svg';
import { ReactComponent as AnghamiDarkDangerPadding } from '../../../../assets/images/music-services-danger/anghami-dark-padding-danger.svg';
import { ReactComponent as AnghamiDarkWarning } from '../../../../assets/images/music-services-warning/anghami-dark-warning.svg';
import { ReactComponent as AnghamiLightWarning } from '../../../../assets/images/music-services-warning/anghami-light-warning.svg';
import { ReactComponent as AnghamiLightWarningPadding } from '../../../../assets/images/music-services-warning/anghami-light-padding-warning.svg';
import { ReactComponent as AnghamiDarkWarningPadding } from '../../../../assets/images/music-services-warning/anghami-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const anghamiImages: ImporterImages = {
  logo: {
    light: AnghamiLight,
    dark: AnghamiDark,
  },
  logoPadding: {
    light: AnghamiLightPadding,
    dark: AnghamiDarkPadding,
  },
  logoColor: {
    light: AnghamiColorLight,
    dark: AnghamiColorDark,
  },
  logoColorPadding: {
    light: AnghamiColorLightPadding,
    dark: AnghamiColorDarkPadding,
  },
  logoOnBackground: AnghamiColorDark,
  logoDanger: {
    dark: AnghamiDarkDanger,
    light: AnghamiLightDanger,
  },
  logoDangerPadding: {
    dark: AnghamiDarkDangerPadding,
    light: AnghamiLightDangerPadding,
  },
  logoWarning: {
    dark: AnghamiDarkWarning,
    light: AnghamiLightWarning,
  },
  logoWarningPadding: {
    light: AnghamiLightWarningPadding,
    dark: AnghamiDarkWarningPadding,
  },
};
