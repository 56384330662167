import { artistsToString } from '../../utils/artistsConverter';
import { tryParseInt } from '../../utils/tryParseInt';
import { Artist, Image, SearchFilter } from './models/types';
import { SearchQueryProperties } from '../../generics/types';

export function getTimestampFromMMSS(time: string): number | undefined {
  if (!time.match(/^[0-9]{2}:[0-9]{2}$/)) {
    console.error(`Unexpected time format: [${time}]`);
    return undefined;
  }

  const timeParts = time.split(':');
  const [minutes, seconds] = timeParts.map((part) => tryParseInt(part) ?? 0);
  if (minutes === undefined || seconds === undefined) {
    return undefined;
  }
  return minutes * 60 * 1000 + seconds * 1000;
}

export function getArtistsNamesAsString(artists: (Artist | null)[] | undefined) {
  if (!artists || artists.length === 0) {
    return '';
  }

  const artistsNames = artists.reduce<string[]>((result, artist) => {
    if (artist?.name) {
      result.push(artist.name);
    }
    return result;
  }, []);

  return artistsToString(artistsNames);
}

export function getArtistsIds(artists: (Artist | null)[] | undefined): string[] | undefined {
  if (!artists || artists.length === 0) {
    return undefined;
  }

  return artists.reduce<string[]>((result, artist) => {
    if (artist?.id) {
      result.push(artist.id);
    }
    return result;
  }, []);
}

export function constructSearchFilters(query: SearchQueryProperties, advancedSearch: boolean) {
  const { track, artist, album, isrc } = query;
  const searchFilters: SearchFilter[] = [];
  if (isrc && advancedSearch) {
    searchFilters.push({ query: isrc, field: 'isrc' });
  }
  if (track) {
    const titleFilter: SearchFilter = advancedSearch ? { query: track, field: 'title' } : { query: track };
    searchFilters.push(titleFilter);
  }
  if (artist) {
    const artistFilter: SearchFilter = advancedSearch ? { query: artist, field: 'artistName' } : { query: artist };
    searchFilters.push(artistFilter);
  }
  if (album) {
    const albumFilter: SearchFilter = advancedSearch ? { query: album, field: 'albumName' } : { query: album };
    searchFilters.push(albumFilter);
  }
  return searchFilters;
}

export function getBiggestImageUrlFromImages(images?: (Image | null)[] | null) {
  if (!images) {
    return undefined;
  }
  const nonEmptyImages = images.filter((image): image is NonNullable<typeof image> => !!image);
  const primaryImage = nonEmptyImages.find((image) => image.imageType === 'PRIMARY');
  return (
    primaryImage?.url ??
    nonEmptyImages.sort((a, b) => {
      return a.height === null || a.height === undefined || b.height === null || b.height === undefined
        ? 0
        : b.height - a.height;
    })[0]?.url
  );
}

export function convertPlayedAt(playedAt: string | number | undefined): number | undefined {
  if (playedAt === undefined) {
    return undefined;
  }
  return typeof playedAt === 'number' ? playedAt : new Date(playedAt).getTime();
}

const amazonPublicResourceUrl = 'https://music.amazon.com';

export function createAmazonAlbumUrl(albumId: string) {
  return `${amazonPublicResourceUrl}/albums/${albumId}`;
}

export function createAmazonPlaylistUrl(playlistId: string) {
  return `${amazonPublicResourceUrl}/playlists/${playlistId}`;
}
