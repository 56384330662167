/* eslint-disable class-methods-use-this */
import * as ls from 'local-storage';
import { Store as StoreType, StoreHandlerInterface } from './StoreInterface';
import { StoreListener } from './StoreListener';

export class StoreHandler extends StoreListener implements StoreHandlerInterface {
  async get<K extends keyof StoreType>(key: K): Promise<StoreType[K] | null> {
    return Promise.resolve(ls.get<StoreType[K] | null>(key));
  }

  async set<K extends keyof StoreType>(key: K, value: StoreType[K]): Promise<void> {
    ls.set(key, value);
    super.set(key, value);
    return Promise.resolve();
  }

  async remove<K extends keyof StoreType>(key: K): Promise<void> {
    ls.remove(key);
    super.remove(key);
    return Promise.resolve();
  }

  async clear(): Promise<void> {
    ls.clear();
    super.clear();
    return Promise.resolve();
  }
}

export const store: StoreHandlerInterface = new StoreHandler();
