import { RessoAlbum } from './RessoAlbum';
import { RessoAlbumsRawResponse } from './RessoResponseModels';

export class RessoAlbumsResponse {
  private readonly data;

  constructor(data: RessoAlbumsRawResponse | undefined) {
    this.data = data;
  }

  get albums(): RessoAlbum[] {
    if (!this.data?.items) {
      return [];
    }
    return this.data.items.map((item) => RessoAlbum.fromData(item)).filter((album): album is RessoAlbum => !!album);
  }

  get next() {
    return this.data?.next_cursor;
  }
}
