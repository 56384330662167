import { config } from '../../config/config';

let abortController = new AbortController();
const callServer = async () => {
  const result = await window.fetch(`${config.fymApiURL(true)}common/api_status`, { signal: abortController.signal });
  return result.status >= 200 && result.status < 300;
};

export function serverStatusListener(callback: (isServerUp: boolean) => void) {
  callServer()
    .then((isOk) => {
      callback(isOk);
    })
    .catch(() => callback(false))
    .finally(() => {
      setTimeout(() => {
        serverStatusListener(callback);
      }, 5000);
    });

  return () => {
    if (!abortController.signal.aborted) {
      abortController.abort();
      abortController = new AbortController();
    }
  };
}
