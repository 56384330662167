import React, { useCallback, useState } from 'react';
import { tryParseInt } from 'fym-common/src/utils/tryParseInt';
import { TypedMemo } from '../../common/types';
import { useGetSmartLinkQuery } from '../../../redux/api/fymAPI';
import { useQueryParam } from '../../../utils/useQueryParam';
import { LoadingView } from '../../common/views/LoadingView';
import { SmartLinkManageViewRenderer } from './SmartLinkManageViewRenderer';
import { EmbedSmartLinkModal } from '../embedSmartLinkModal/EmbedSmartLinkModal';
import { useDeleteSmartLinkFlow } from '../hooks';
import { openExternal } from '../../../utils/openExternal';
import { SmartLinkUpsell } from '../upselling/SmartLinkUpsell';
import { useLicenseInfo } from '../../../redux/slices/license/hooks';
import { ErrorView } from '../../common/views/ErrorView/ErrorView';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';

interface SmartLinkManageViewProps {
  route?: {
    params: {
      accountId: number;
      smartLinkId: number;
    };
  };
}

const SmartLinkManageViewComponent: React.FC<SmartLinkManageViewProps> = ({ route }) => {
  const router = useSMLRouter();
  const [isEmbedModalOpen, setIsEmbedModalOpen] = useState<boolean>(false);
  const accountIdString = useQueryParam('accountId', route);
  const accountId = tryParseInt(accountIdString);
  const smartLinkIdString = useQueryParam('smartLinkId', route);
  const smartLinkId = tryParseInt(smartLinkIdString);
  const licenseInfo = useLicenseInfo();

  const [isUpsellingOpen, setIsUpsellingOpen] = useState<boolean>(false);
  const closeUpselling = useCallback(() => setIsUpsellingOpen(false), []);

  if (accountId === undefined || smartLinkId === undefined) {
    throw new Error(`Missing accountId: [${accountId}] or smartLinkId: [${smartLinkId}]`);
  }

  const { openSmartLinkDeletionModal, isDeleteSmartLinkLoading, deleteSmartLinkError, SmartLinkDeletionModal } =
    useDeleteSmartLinkFlow(accountId, smartLinkId);

  const {
    data: smartLinkResponse,
    isLoading: isGetSmartLinkLoading,
    error: getSmartLinkError,
  } = useGetSmartLinkQuery({ accountId, smartLinkId });

  const goToSmartLinkDetailsView = useCallback(() => {
    router.push(SMLRoutes.details, { accountId, smartLinkId });
  }, [router, accountId, smartLinkId]);

  const goToSmartLinkDuplicationView = useCallback(() => {
    router.push(SMLRoutes.duplicate, { accountId, smartLinkId });
  }, [router, accountId, smartLinkId]);

  const closeEmbedModal = useCallback(() => setIsEmbedModalOpen(false), []);

  const openWeb = useCallback(() => {
    if (!smartLinkResponse?.smartLink?.shareUrl) {
      return;
    }
    openExternal(smartLinkResponse?.smartLink.shareUrl).catch((e) => console.log(e));
  }, [smartLinkResponse?.smartLink?.shareUrl]);

  const openEmbedModal = useCallback(() => {
    if (licenseInfo.hasSharingEmbedAccess) {
      setIsEmbedModalOpen(true);
    } else {
      setIsUpsellingOpen(true);
    }
  }, [licenseInfo.hasSharingEmbedAccess]);

  const error = getSmartLinkError ?? deleteSmartLinkError;
  if (error) {
    return <ErrorView error={error} />;
  }

  if (!smartLinkResponse || isGetSmartLinkLoading || isDeleteSmartLinkLoading) {
    return <LoadingView />;
  }

  const { smartLink } = smartLinkResponse;
  const disabledEmbedCode = smartLink.publishedSmartLinkVersion === null;

  return (
    <>
      <SmartLinkUpsell isOpen={isUpsellingOpen} onClose={closeUpselling} />
      {smartLink.embedUrl && (
        <EmbedSmartLinkModal isOpen={isEmbedModalOpen} embedUrl={smartLink.embedUrl} closeModal={closeEmbedModal} />
      )}
      {SmartLinkDeletionModal}
      <SmartLinkManageViewRenderer
        smartLink={smartLink}
        openEmbedModal={openEmbedModal}
        openInWeb={openWeb}
        onDelete={openSmartLinkDeletionModal}
        goToSmartLinkDetailsView={goToSmartLinkDetailsView}
        goToSmartLinkDuplicationView={goToSmartLinkDuplicationView}
        disabledEmbedCode={disabledEmbedCode}
      />
    </>
  );
};

export const SmartLinkManageView = TypedMemo(SmartLinkManageViewComponent);
