import URL from 'url-parse';

export enum LinkType {
  // -------- GENERIC --------
  generic = 'generic',
  // -------- MUSIC SERVICES --------
  amazonmusic = 'amazonmusic',
  applemusic = 'applemusic',
  deezer = 'deezer',
  pandora = 'pandora',
  soundcloud = 'soundcloud',
  spotify = 'spotify',
  tidal = 'tidal',
  youtube = 'youtube',
  youtubemusic = 'youtubemusic',
  napster = 'napster',
  qobuz = 'qobuz',
  qqmusic = 'qqmusic',
  yandexmusic = 'yandexmusic',
  vkmusic = 'vkmusic',
  anghami = 'anghami',
  zvuk = 'zvuk',
  gaana = 'gaana',
  jiosaavn = 'jiosaavn',
  resso = 'resso',
  boomplay = 'boomplay',
  // -------- OTHER --------
  facebook = 'facebook',
  instagram = 'instagram',
  twitter = 'twitter',
  linkedin = 'linkedin',
  tiktok = 'tiktok',
  reddit = 'reddit',
  snapchat = 'snapchat',
}

const musicServices = [
  LinkType.amazonmusic,
  LinkType.applemusic,
  LinkType.deezer,
  LinkType.pandora,
  LinkType.soundcloud,
  LinkType.spotify,
  LinkType.tidal,
  LinkType.youtube,
  LinkType.youtubemusic,
  LinkType.napster,
  LinkType.qobuz,
  LinkType.qqmusic,
  LinkType.yandexmusic,
  LinkType.vkmusic,
  LinkType.anghami,
  LinkType.zvuk,
  LinkType.gaana,
  LinkType.jiosaavn,
  LinkType.resso,
  LinkType.boomplay,
];

export function isMusicService(linkType: LinkType) {
  return musicServices.includes(linkType);
}

export function recognizeLinkTypeByUrl(url: URL): LinkType {
  const { host } = url;
  if (host.includes('facebook')) {
    return LinkType.facebook;
  }
  if (host.includes('instagram')) {
    return LinkType.instagram;
  }
  if (host.includes('twitter')) {
    return LinkType.twitter;
  }
  if (host.includes('music.youtube')) {
    return LinkType.youtubemusic;
  }
  if (host.includes('youtube') || host.includes('youtu.be')) {
    return LinkType.youtube;
  }
  if (host.includes('linkedin')) {
    return LinkType.linkedin;
  }
  if (host.includes('soundcloud')) {
    return LinkType.soundcloud;
  }
  if (host.includes('tidal')) {
    return LinkType.tidal;
  }
  if (host.includes('amazon')) {
    return LinkType.amazonmusic;
  }
  if (host.includes('tiktok')) {
    return LinkType.tiktok;
  }
  if (host.includes('music.apple')) {
    return LinkType.applemusic;
  }
  if (host.includes('deezer')) {
    return LinkType.deezer;
  }
  if (host.includes('pandora')) {
    return LinkType.pandora;
  }
  if (host.includes('spotify')) {
    return LinkType.spotify;
  }
  if (host.includes('tidal')) {
    return LinkType.tidal;
  }
  if (host.includes('napster')) {
    return LinkType.napster;
  }
  if (host.includes('qobuz')) {
    return LinkType.qobuz;
  }
  if (host.includes('qq.com')) {
    return LinkType.qqmusic;
  }
  if (host.includes('music.yandex.ru')) {
    return LinkType.yandexmusic;
  }
  if (host.includes('vk.com')) {
    return LinkType.vkmusic;
  }
  if (host.includes('anghami.com')) {
    return LinkType.anghami;
  }
  if (host.includes('zvuk')) {
    return LinkType.zvuk;
  }
  if (host.includes('gaana.com')) {
    return LinkType.gaana;
  }
  if (host.includes('jiosaavn.com')) {
    return LinkType.jiosaavn;
  }
  if (host.includes('resso.com')) {
    return LinkType.resso;
  }
  if (host.includes('boomplay.com') || host.includes('boomplaymusic.com')) {
    return LinkType.boomplay;
  }
  if (host.includes('reddit.com')) {
    return LinkType.reddit;
  }
  if (host.includes('snapchat.com')) {
    return LinkType.snapchat;
  }

  return LinkType.generic;
}
