import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../../common/types';
import { SmartLinkUpsellProps } from './props';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { SmartLinkUpsellFeatures } from './SmartLinkUpsellFeatures';
import { FYMModalText } from '../../common/views/modals/FYMModal/text/FYMModalText';
import { Bold } from '../../common/views/bold/Bold';
import { Br } from '../../common/views/br/Br';
import { SMLModal } from '../../common/views/modals/SMLModal/SMLModal';

const SmartLinkUpsellComponent: React.FC<SmartLinkUpsellProps> = ({ isOpen, onClose, isTrialModal }) => {
  const intl = useIntl();
  const router = useSMLRouter();

  const boldRed = useCallback((str: React.ReactNode) => <Bold color="#de376a">{str}</Bold>, []);

  const onClick = useCallback(() => {
    onClose();
    router.push(SMLRoutes.premium);
  }, [onClose, router]);

  return (
    <SMLModal isOpen={isOpen} onClosed={onClose}>
      <FYMModalText size="big" weight="bold" color="#222222" marginBottom={10}>
        {isTrialModal === true
          ? intl.formatMessage({
              id: 'app.modals.trial-ended.title',
              defaultMessage: 'Your TRIAL has ended!',
            })
          : intl.formatMessage({
              id: 'app.modals.upsell.title',
              defaultMessage: 'Maximize your influence today',
            })}
      </FYMModalText>
      <FYMModalText size="medium" weight="regular" color="#222222" marginBottom={30}>
        {isTrialModal === false
          ? intl.formatMessage(
              {
                id: 'app.modals.upsell.subtitle',
                defaultMessage: `🚀 Upgrade your account to enjoy <bold>PRO</bold> features:`,
              },
              {
                br: <Br />,
                bold: boldRed,
              }
            )
          : intl.formatMessage(
              {
                id: 'app.modals.upsell.trial.subtitle',
                defaultMessage: `🚀 Upgrade your account to keep <bold>PRO</bold> features:`,
              },
              {
                br: <Br />,
                bold: boldRed,
              }
            )}
      </FYMModalText>
      <SmartLinkUpsellFeatures onClick={onClick} />
    </SMLModal>
  );
};

export const SmartLinkUpsell = TypedMemo(SmartLinkUpsellComponent);
