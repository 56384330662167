import { GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';

export class SoundCloudLikedTracks extends GenericCollection {
  constructor(userId: string) {
    super({
      type: CollectionType.MY_SONGS,
      rawId: `sound-cloud-liked-tracks-${userId}`,
      name: 'Liked tracks',
    });
  }
}
