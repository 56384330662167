import Joi from 'joi';
import { StoreType } from '../../../redux/store';
import { fymAPI } from '../../../redux/api/fymAPI';
import { asyncDebounce } from '../../../utils/asyncDebounce';
import { isSuccessfulResult } from '../../../redux/api/utils/isSuccessfulResult';
import { intl } from '../../app/views/intl';

const verifyAccountNameSlug = (store: StoreType, slug: string, accountId?: number) =>
  store.dispatch(fymAPI.endpoints.verifyAccountNameSlug.initiate({ slug, accountId }));

const debouncedVerifyAccountNameSlug = asyncDebounce(verifyAccountNameSlug, 500, { leading: true });

export function getValidateAccountSlugFn(store: StoreType, path: string, accountId?: number) {
  return async (slug: string) => {
    const validationResult = await debouncedVerifyAccountNameSlug(store, slug, accountId);
    if (!isSuccessfulResult(validationResult)) {
      throw new Joi.ValidationError(
        'slug.validationError',
        [
          {
            message: intl.formatMessage({
              id: 'app.validator.error',
              defaultMessage: `Validation error, please try again later`,
            }),
            path: [path],
          },
        ],
        slug
      );
    }

    const { isValid, isAvailable, availableSlug } = validationResult.data;
    if (!isValid) {
      throw new Joi.ValidationError(
        'slug.illegal',
        [
          {
            message: intl.formatMessage(
              {
                id: 'app.validator.slug.illegal',
                defaultMessage: `Slug "{slugValue}" contains illegal characters, you can use "{availableSlug}" instead`,
              },
              {
                slugValue: slug,
                availableSlug,
              }
            ),
            path: [path],
          },
        ],
        slug
      );
    }
    if (!isAvailable) {
      throw new Joi.ValidationError(
        'slug.taken',
        [
          {
            message: intl.formatMessage(
              {
                id: 'app.validator.slug.not-available',
                defaultMessage: `Slug "{slugValue}" is taken, you can use "{availableSlug}" instead`,
              },
              {
                slugValue: slug,
                availableSlug,
              }
            ),
            path: [path],
          },
        ],
        slug
      );
    }
  };
}
