import { GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { SpotifyAPI, SpotifyAPITimeRange } from './SpotifyAPI';
import { SpotifyPlaylist } from './models/SpotifyPlaylist';
import { CollectionAccess, CollectionType } from '../../generics/models/Collection';
import { FetchError } from '../../generics/errors/FetchError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { SpotifyMySongs } from './models/SpotifyMySongs';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { SpotifyAlbum } from './models/SpotifyAlbum';
import { SearchQueryProperties } from '../../generics/types';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { ImporterID } from '../types';
import { spotify } from '../../musicServices/services/Spotify';
import { refreshAuthData } from '../../musicApi/utils/refreshAuthData';
import { MusicAPIIntegrationID } from '../../musicServices/types';

interface SpotifyAuthenticationData extends GenericAuthenticationData {
  expiresAt: Date;
  additionalData: {
    accessToken: string;
    refreshToken: string;
    country: string;
  };
}

const createSpotifyInstance = (authenticationData: SpotifyAuthenticationData): SpotifyAPI => {
  return new SpotifyAPI({
    accessToken: authenticationData.additionalData.accessToken,
    userId: authenticationData.authId,
  });
};

export const SpotifyImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.Spotify;

  public static musicService = spotify;

  public authenticationData: SpotifyAuthenticationData;

  private spotifyApi: SpotifyAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as SpotifyAuthenticationData;
    this.spotifyApi = createSpotifyInstance(this.authenticationData);
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.authenticationData = authenticationData as SpotifyAuthenticationData;
    this.spotifyApi = createSpotifyInstance(this.authenticationData);
  }

  async getPaginatedCollections(
    onBatch: (collections: (SpotifyMySongs | SpotifyPlaylist | SpotifyAlbum)[]) => Promise<void>
  ): Promise<void> {
    const likedSongsPage = await this.spotifyApi.loadMySongsPage(0, 1);
    const mySongs = new SpotifyMySongs(this.spotifyApi.userId, likedSongsPage.total);
    await onBatch([mySongs]);

    await this.spotifyApi.loadPaginatedPlaylists(async (playlists) => {
      await onBatch(
        playlists.filter((playlist) => {
          // Filter out Spotify curated playlists
          return !playlist.rawId.startsWith('37i9dQZF');
        })
      );
    });
    await this.spotifyApi.loadPaginatedAlbums(onBatch);
  }

  async getCollection(collection: GenericCollection): Promise<SpotifyPlaylist | SpotifyAlbum> {
    let result: SpotifyPlaylist | SpotifyAlbum | null;
    if (collection.type === CollectionType.ALBUM) {
      if (!collection.additionalData?.href) {
        throw new Error(`Missing href in collection[${collection.rawId}] for SpotifyImporter`);
      }
      result = await this.spotifyApi.loadAlbum(collection.additionalData.href);
    } else {
      if (collection.type === CollectionType.MY_SONGS) {
        const likedSongsPage = await this.spotifyApi.loadMySongsPage(0, 1);
        return new SpotifyMySongs(this.spotifyApi.userId, likedSongsPage.total);
      }
      const doesFollowPlaylist = await this.spotifyApi.doesUserFollowPlaylist(collection.rawId, this.spotifyApi.userId);
      if (!doesFollowPlaylist) {
        throw new CollectionDoesNotExistsError();
      }
      result = await this.spotifyApi.loadPlaylist(collection.rawId);
    }
    if (!result) {
      throw new CollectionDoesNotExistsError();
    }
    return result;
  }

  async createCollection(collection: GenericCollection, description?: string): Promise<SpotifyPlaylist> {
    let newCollection: SpotifyPlaylist | null = null;
    try {
      newCollection = await this.spotifyApi.createPlaylist(collection.name, { description });
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!newCollection) {
      throw new CouldNotCreateCollection();
    }
    return newCollection;
  }

  async addItemToCollection(collection: GenericCollection, matchedItem: GenericMatchedItem, trackPosition?: number) {
    if (collection.type === CollectionType.MY_SONGS) {
      await this.spotifyApi.addTracksToLibrary([matchedItem.rawId]);
    } else {
      await this.spotifyApi.addTracksToPlaylist(collection.rawId, [matchedItem.rawId], trackPosition);
    }
    return convertMatchedItemToCollectionItem(matchedItem);
  }

  async addManyItemsToCollection(
    collection: GenericCollection,
    data: {
      matchedItem: GenericMatchedItem;
      position?: number;
    }[]
  ) {
    const tracksIds = data.map(({ matchedItem }) => matchedItem.rawId);
    if (collection.type === CollectionType.MY_SONGS) {
      await this.spotifyApi.addTracksToLibrary(tracksIds);
    } else {
      const firstPosition = data[0]?.position;
      await this.spotifyApi.addTracksToPlaylist(collection.rawId, tracksIds, firstPosition);
    }
  }

  async moveManyItems(props: Parameters<NonNullable<GenericImporter['moveManyItems']>>[0]) {
    const { itemsToMove, collection, offset } = props;
    if (itemsToMove.length === 0) {
      return;
    }
    itemsToMove.sort((a, b) => a.position - b.position);
    const additionalOffset = offset > 0 ? itemsToMove.length : 0;
    const firstItem = itemsToMove[0];
    if (firstItem === undefined) {
      throw new Error('Missing firstItems from itemsToMove');
    }
    const firstItemPosition = firstItem.position;
    await this.spotifyApi.moveTracksInPlaylist(
      collection.rawId,
      firstItemPosition,
      firstItemPosition + offset + additionalOffset,
      itemsToMove.length
    );
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    collectionItems: GenericCollectionItem[]
  ): Promise<void> {
    if (collection.type !== CollectionType.PLAYLIST || collectionItems.length === 0) {
      return;
    }
    const itemsIds = collectionItems.map((item) => item.rawId);
    await this.spotifyApi.removeTracksFromPlaylist(collection.rawId, itemsIds);
  }

  async clearCollection(collection: GenericCollection) {
    const itemsIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    await this.getPaginatedItems(collection, async (items) => {
      itemsIds.push(...items.map((item) => item.rawId));
    });
    await this.spotifyApi.removeTracksFromPlaylist(collection.rawId, itemsIds);
  }

  async matchItems(queryProps: SearchQueryProperties): Promise<GenericMatchedItem[]> {
    let { tracks } = await this.spotifyApi.search({ queryProps, searchType: 'track', advancedSearch: true });
    if (tracks.length === 0) {
      ({ tracks } = await this.spotifyApi.search({ queryProps, searchType: 'track', advancedSearch: false }));
    }
    return tracks;
  }

  async matchAlbums(queryProps: SearchQueryProperties) {
    let { albums } = await this.spotifyApi.search({ queryProps, searchType: 'album', advancedSearch: true });
    if (albums.length === 0) {
      ({ albums } = await this.spotifyApi.search({ queryProps, searchType: 'album', advancedSearch: false }));
    }
    return albums;
  }

  async addAlbumToLibrary(album: GenericCollection): Promise<void> {
    await this.spotifyApi.addAlbumToLibrary(album.rawId);
  }

  async updateCollection(
    collection: GenericCollection,
    props: { name?: string; access?: CollectionAccess; description?: string }
  ) {
    const { name, access, description } = props;
    const isPublic = access === undefined ? undefined : access === CollectionAccess.public;
    await this.spotifyApi.updatePlaylist(collection.rawId, { name, isPublic, description });
  }

  async removeCollection(collection: GenericCollection): Promise<void> {
    await this.spotifyApi.removePlaylist(collection.rawId);
  }

  async reAuthenticate(
    withData: GenericAuthenticationData,
    forceFetchRefreshTokens?: boolean
  ): Promise<GenericAuthenticationData> {
    return refreshAuthData({
      authId: withData.authId,
      userUUID: withData.additionalData?.userUUID ?? null,
      integrationId: MusicAPIIntegrationID.Spotify,
      forceFetchRefreshTokens,
    });
  }

  public static shouldReverseItemsOrder(collectionType: CollectionType | undefined) {
    return collectionType === CollectionType.MY_SONGS;
  }

  doesSupportReAuth(): boolean {
    return true;
  }

  public doesSupportAlbums(): boolean {
    return true;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return true;
  }

  public doesSupportSearchByISRC(): boolean {
    return true;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return true;
  }

  public doesSupportMovingManyItems(): boolean {
    return true;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }

  public doesSupportAddingItemsToLikedSongs(): boolean {
    return true;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    switch (forCollection.type) {
      case CollectionType.PLAYLIST:
      case CollectionType.LIKED_PLAYLIST:
        return this.spotifyApi.loadPaginatedPlaylistItems(forCollection.rawId, onBatch);
      case CollectionType.MY_SONGS:
        return this.spotifyApi.loadPaginatedMySongs(onBatch);
      case CollectionType.ALBUM:
        return this.spotifyApi.loadPaginatedAlbumItems(forCollection as SpotifyAlbum, onBatch);
      default:
        return undefined;
    }
  }

  async getCollectionPublicUrl(collection: GenericCollection) {
    return Promise.resolve(`https://open.spotify.com/playlist/${collection.rawId}`);
  }

  async getStatsData() {
    const user = await SpotifyAPI.fetchMe(this.authenticationData.additionalData.accessToken);
    return {
      profile: user.privateData,
      songs: [
        {
          time_range: SpotifyAPITimeRange.OneMonth,
          songs: await this.spotifyApi.loadAllTopSongs(SpotifyAPITimeRange.OneMonth),
        },
        {
          time_range: SpotifyAPITimeRange.HalfYear,
          songs: await this.spotifyApi.loadAllTopSongs(SpotifyAPITimeRange.HalfYear),
        },
        {
          time_range: SpotifyAPITimeRange.AllTime,
          songs: await this.spotifyApi.loadAllTopSongs(SpotifyAPITimeRange.AllTime),
        },
      ],
      artists: [
        {
          time_range: SpotifyAPITimeRange.OneMonth,
          artists: await this.spotifyApi.loadAllTopArtists(SpotifyAPITimeRange.OneMonth),
        },
        {
          time_range: SpotifyAPITimeRange.HalfYear,
          artists: await this.spotifyApi.loadAllTopArtists(SpotifyAPITimeRange.HalfYear),
        },
        {
          time_range: SpotifyAPITimeRange.AllTime,
          artists: await this.spotifyApi.loadAllTopArtists(SpotifyAPITimeRange.AllTime),
        },
      ],
    };
  }
};
