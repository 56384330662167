import { RessoAlbum } from './RessoAlbum';
import { RessoTrackRawResponse } from './RessoResponseModels';
import { RessoArtist } from './RessoArtist';
import { GenericMatchedItem, MatchedItemModelData } from '../../../generics/models/GenericMatchedItem';
import { convertRessoTrackData } from '../utils/convertRessoTrackData';

export class RessoMatchedTrack extends GenericMatchedItem {
  constructor(
    data: MatchedItemModelData,
    public albumExtended: RessoAlbum | null = null,
    public artists: RessoArtist[] | null = null
  ) {
    super(data);
  }

  static fromData(data: RessoTrackRawResponse | undefined): RessoMatchedTrack | null {
    const trackData = convertRessoTrackData(data);
    if (!trackData) {
      return null;
    }
    const { track, album, artists } = trackData;
    return new RessoMatchedTrack(track, album, artists);
  }
}
