import { CollectionType } from '../generics/models/Collection';
import { musicServices } from '../musicServices/allMusicServices';
import { MusicService } from '../musicServices/services/types';
import { MusicAPIIntegrationID } from '../musicServices/types';

export enum SearchResponseStatus {
  success = 'success',
  error = 'error',
  not_found = 'not_found',
}

export enum SearchType {
  unknown = 'unknown',
  track = 'track',
  album = 'album',
}

export type SearchResponseTrack = {
  name: string;
  externalId: string | null;
  albumName: string | null;
  artistNames: string[] | null;
  isrc: string | null;
  imageUrl: string | null;
  duration: number | null;
  previewUrl: string | null;
  url: string | null;
};

export type SearchResponseCollection = {
  name: string;
  externalId: string | null;
  artistNames: string[] | null;
  itemCount: number | null;
  collectionType: CollectionType | null;
  followers: number | null;
  imageUrl: string | null;
  url: string | null;
};

export type SearchResult<T> = {
  status: SearchResponseStatus;
  source?: MusicAPIIntegrationID;
  type: SearchType;
  data: T | null;
};

export type SearchResponse = {
  tracks?: SearchResult<SearchResponseTrack>[];
  albums?: SearchResult<SearchResponseCollection>[];
};

export type InspectByUrlResponse = SearchResult<SearchResponseTrack | SearchResponseCollection>;

export const availableSearchSources = musicServices
  .map((service) => service.musicAPIID)
  .filter((s): s is MusicAPIIntegrationID => s !== undefined);

export const availableSearchSourcesAccept: Record<string, MusicService> = {};

musicServices
  .filter((ms) => ms.musicAPIID !== undefined)
  .forEach((ms) => {
    if (ms.musicAPIID) {
      availableSearchSourcesAccept[ms.musicAPIID] = ms;
    }
    availableSearchSourcesAccept[ms.importerID] = ms;
    availableSearchSourcesAccept[ms.id] = ms;
  });

export const availableSearchTypes = Object.values(SearchType).filter((type) => type !== SearchType.unknown);
