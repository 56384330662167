import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { DeezerAlbumRaw } from '../rawTypes/DeezerAlbumRaw';
import {
  buildCoverUrl,
  convertDeezerId,
  createDeezerAlbumUrl,
  getArtistsForAlbum,
  getArtistsNamesAsString,
  getArtistsNamesAsStringFromSharedUrl,
} from '../utils';
import { DeezerArtist } from './DeezerArtist';
import { DeezerArtistRaw } from '../rawTypes/DeezerArtistRaw';
import { tryParseInt } from '../../../utils/tryParseInt';

export class DeezerAlbum extends GenericCollection {
  constructor(data: Omit<CollectionModelData, 'type'>, public artists: DeezerArtist[] | null = null) {
    super({ type: CollectionType.ALBUM, ...data });
  }

  static fromData(data: DeezerAlbumRaw, artistRaw: DeezerArtistRaw | undefined): DeezerAlbum | null {
    const rawId = convertDeezerId(data.id);
    const name = `${data.title}`;
    if (!rawId || !name) {
      return null;
    }
    return new DeezerAlbum(
      {
        rawId,
        name,
        additionalData: {
          artist: getArtistsNamesAsString(data.contributors) ?? data.artist?.name,
          imageUrl: data.cover,
        },
        itemCount: data.nb_tracks,
        publicUrl: data.link,
        followers: data.fans,
      },
      getArtistsForAlbum(data.contributors, data.artist ?? artistRaw)
    );
  }

  static fromShareUrlData(data: any): DeezerAlbum | null {
    if (!data?.ALB_ID || !data.ALB_TITLE) {
      return null;
    }
    return new DeezerAlbum({
      rawId: data.ALB_ID,
      name: data.ALB_TITLE,
      additionalData: {
        artist: getArtistsNamesAsStringFromSharedUrl(data.ARTISTS, data.ART_NAME),
        imageUrl: buildCoverUrl(data.ALB_PICTURE),
      },
      itemCount: tryParseInt(data.NUMBER_TRACK),
      publicUrl: createDeezerAlbumUrl(data.ALB_ID),
    });
  }
}
