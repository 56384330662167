import { useEffect, useState } from 'react';
import { allPaddleSkus } from 'fym-common/src/utils/paddleSkus';
import { singletonPromise } from 'fym-common/src/utils/singletonPromise';
import { PaddlePricesResponse } from '../models/PaddlePricesResponse';
import { fetchPrices } from '../utils/fetchPrices';

async function _fetchAllPaddlePrices() {
  return fetchPrices(allPaddleSkus);
}
const fetchAllPaddlePrices = singletonPromise(_fetchAllPaddlePrices);
export const usePaddlePricing = (): [PaddlePricesResponse | undefined, string | undefined] => {
  const [paddleResponse, setPaddleResponse] = useState<PaddlePricesResponse | undefined>();
  const [error, setError] = useState<string | undefined>();
  useEffect(() => {
    fetchAllPaddlePrices()
      .then((response) => {
        setPaddleResponse(response);
      })
      .catch((e) => {
        console.error(e);
        setError('Could not fetch pricing');
      });
  }, []);
  return [paddleResponse, error];
};
