import { AppleMusicGetSongByISRCType } from './types';
import { AppleMusicMatchedTrack } from './AppleMusicMatchedTrack';

// Apple Music may return more than one track by ISRC. All of them are probably the same track, but placed in different albums.

export class AppleMusicTracksByISRCResponse {
  private readonly data;

  constructor(data: AppleMusicGetSongByISRCType | null) {
    this.data = data;
  }

  get tracks(): AppleMusicMatchedTrack[] {
    if (!this.data?.data) {
      return [];
    }

    return this.data.data
      .map((item) => AppleMusicMatchedTrack.fromData(item))
      .filter((track): track is AppleMusicMatchedTrack => !!track);
  }

  get firstTrack(): AppleMusicMatchedTrack | undefined {
    if (this.tracks.length === 0) {
      return undefined;
    }
    return this.tracks[0];
  }
}
