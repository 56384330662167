import React from 'react';
import { TypedMemo } from '../../../common/types';
import { SocialLinkItemProps } from './props';
import { SocialLinkItemRenderer } from './SocialLinkItemRenderer';

const SocialLinkItemComponent: React.FC<SocialLinkItemProps> = ({ socialLink }) => {
  return <SocialLinkItemRenderer type={socialLink.type} />;
};

export const SocialLinkItem = TypedMemo(SocialLinkItemComponent);
