import { YouTubePlaylist } from './YouTubePlaylist';

export class YouTubePlaylistsResponse {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get playlists(): YouTubePlaylist[] {
    if (!this.data?.items) {
      return [];
    }
    return this.data.items
      .map((item: any) => YouTubePlaylist.fromData(item))
      .filter((playlist: YouTubePlaylist | null) => !!playlist);
  }

  get nextPageToken(): string | undefined {
    return this.data?.nextPageToken;
  }
}
