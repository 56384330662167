import { ReactComponent as VKLight } from '../../../../assets/images/music-services/vk-light.svg';
import { ReactComponent as VKDark } from '../../../../assets/images/music-services/vk-dark.svg';
import { ReactComponent as VKLightPadding } from '../../../../assets/images/music-services/vk-light-padding.svg';
import { ReactComponent as VKDarkPadding } from '../../../../assets/images/music-services/vk-dark-padding.svg';
import { ReactComponent as VKColorLight } from '../../../../assets/images/music-services/vk-color-light.svg';
import { ReactComponent as VKColorDark } from '../../../../assets/images/music-services/vk-color-dark.svg';
import { ReactComponent as VKColorLightPadding } from '../../../../assets/images/music-services/vk-color-light-padding.svg';
import { ReactComponent as VKColorDarkPadding } from '../../../../assets/images/music-services/vk-color-dark-padding.svg';
import { ReactComponent as VKDarkDanger } from '../../../../assets/images/music-services-danger/vk-dark-danger.svg';
import { ReactComponent as VKLightDanger } from '../../../../assets/images/music-services-danger/vk-light-danger.svg';
import { ReactComponent as VKLightDangerPadding } from '../../../../assets/images/music-services-danger/vk-light-padding-danger.svg';
import { ReactComponent as VKDarkDangerPadding } from '../../../../assets/images/music-services-danger/vk-dark-padding-danger.svg';
import { ReactComponent as VKDarkWarning } from '../../../../assets/images/music-services-warning/vk-dark-warning.svg';
import { ReactComponent as VKLightWarning } from '../../../../assets/images/music-services-warning/vk-light-warning.svg';
import { ReactComponent as VKLightWarningPadding } from '../../../../assets/images/music-services-warning/vk-light-padding-warning.svg';
import { ReactComponent as VKDarkWarningPadding } from '../../../../assets/images/music-services-warning/vk-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const vkImages: ImporterImages = {
  logo: {
    light: VKLight,
    dark: VKDark,
  },
  logoPadding: {
    light: VKLightPadding,
    dark: VKDarkPadding,
  },
  logoColor: {
    light: VKColorLight,
    dark: VKColorDark,
  },
  logoColorPadding: {
    light: VKColorLightPadding,
    dark: VKColorDarkPadding,
  },
  logoOnBackground: VKColorLight,
  logoDanger: {
    dark: VKDarkDanger,
    light: VKLightDanger,
  },
  logoDangerPadding: {
    dark: VKDarkDangerPadding,
    light: VKLightDangerPadding,
  },
  logoWarning: {
    dark: VKDarkWarning,
    light: VKLightWarning,
  },
  logoWarningPadding: {
    light: VKLightWarningPadding,
    dark: VKDarkWarningPadding,
  },
};
