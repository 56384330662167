import { LicenseType } from 'fym-common/src/license/consts';
import React from 'react';
import { TypedMemo } from '../../common/types';
import { PaddlePaymentPlan } from './PaddlePaymentPlan';
import { PaddleSmartLinksPremiumLicensePlansProps } from './props';

const PaddleSmartLinksPremiumLicensePlansComponent: React.FC<PaddleSmartLinksPremiumLicensePlansProps> = ({
  smartLinksRookiePriceData,
  smartLinksInfluencerPriceData,
  smartLinksArtistPriceData,
  onSmartLinksRookieClick,
  onSmartLinksInfluencerClick,
  onSmartLinksArtistClick,
  currentPlan,
}) => {
  return (
    <div className="premium-plans-container">
      <PaddlePaymentPlan
        title={{ id: 'app.plan_box.premium.smartlinks-rookie.title', defaultMessage: 'ROOKIE' }}
        highlightedTextSpace
        price={smartLinksRookiePriceData.amount}
        currency={smartLinksRookiePriceData.currency}
        onClick={onSmartLinksRookieClick}
        isDisabled
      />
      <PaddlePaymentPlan
        title={{ id: 'app.plan_box.premium.smartlinks-influencer.title', defaultMessage: 'INFLUENCER' }}
        description={{
          id: 'app.plan_box.premium.smartlinks-influencer.description',
          defaultMessage: 'Renews Monthly',
        }}
        highlightedText={{
          id: 'app.plan_box.premium.smartlinks-influencer.highlighted_text',
          defaultMessage: 'MOST POPULAR',
        }}
        price={smartLinksInfluencerPriceData.amount}
        currency={smartLinksInfluencerPriceData.currency}
        onClick={onSmartLinksInfluencerClick}
        isDisabled={currentPlan === LicenseType.SmartLinksInfluencer}
      />
      <PaddlePaymentPlan
        title={{ id: 'app.plan_box.premium.smartlinks-artist.title', defaultMessage: 'ARTIST' }}
        description={{
          id: 'app.plan_box.premium.smartlinks-artist.description',
          defaultMessage: 'Renews Monthly',
        }}
        highlightedText={{
          id: 'app.plan_box.premium.smartlinks-influencer.highlighted_text',
          defaultMessage: 'THE BEST',
        }}
        price={smartLinksArtistPriceData.amount}
        currency={smartLinksArtistPriceData.currency}
        onClick={onSmartLinksArtistClick}
        isDisabled={currentPlan === LicenseType.SmartLinksArtist}
      />
    </div>
  );
};

export const PaddleSmartLinksPremiumLicensePlans = TypedMemo(PaddleSmartLinksPremiumLicensePlansComponent);
