import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { tryParseInt } from '../../../utils/tryParseInt';
import { createGaanaAlbumUrl, getArtistsNamesAsString } from '../utils';

export class GaanaAlbum extends GenericCollection {
  constructor(data: Omit<CollectionModelData, 'type'>) {
    super({ type: CollectionType.ALBUM, ...data });
  }

  static fromData(data: any, url?: string): GaanaAlbum | null {
    if ((!data.album_id && !data.seokey) || !data.title) {
      return null;
    }
    return new GaanaAlbum({
      rawId: `${data.album_id}|${data.seokey}`,
      name: data.title,
      itemCount: tryParseInt(data.trackcount),
      additionalData: {
        imageUrl: data.artwork,
        artist: getArtistsNamesAsString(data.artist) ?? data.primaryartist?.name,
      },
      publicUrl: url ?? createGaanaAlbumUrl(data.seokey),
    });
  }

  static fromSearchData(data: any): GaanaAlbum | null {
    if ((!data.iid && !data.seo) || !data.ti) {
      return null;
    }
    return new GaanaAlbum({
      rawId: `${data.iid}|${data.seo}`,
      name: data.ti,
      additionalData: { imageUrl: data.aw },
      publicUrl: createGaanaAlbumUrl(data.seo),
    });
  }
}
