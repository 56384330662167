import React from 'react';
import classnames from 'classnames';
import { TypedMemo } from '../../common/types';
import { LicenseStatusBoxProps } from './props';

const LicenseStatusBoxComponent: React.FC<LicenseStatusBoxProps> = ({
  title,
  subtitle,
  statusText,
  inactive = false,
}) => {
  return (
    <div className="opacity-bg license-status-box">
      <div className="license-status-box-text-content">
        <span className="mb-2">{title}</span>
        <h5>{subtitle}</h5>
      </div>
      <div
        className={classnames('license-status-box-status-bar', {
          'fym-gradient-background': !inactive,
          'sml-gradient-background': !inactive,
          'license-status-box-inactive-status-bar': inactive,
        })}
      >
        <span>{statusText}</span>
      </div>
    </div>
  );
};

export const LicenseStatusBox = TypedMemo(LicenseStatusBoxComponent);
