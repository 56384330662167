import { SoundCloudPlaylistTracksRawResponse } from './responseTypes';
import { SoundCloudCollectionTrack } from './SoundCloudCollectionTrack';

export class SoundCloudPlaylistTracksResponse {
  private readonly data;

  constructor(data: SoundCloudPlaylistTracksRawResponse | undefined) {
    this.data = data;
  }

  get tracks() {
    if (!this.data?.collection) {
      return [];
    }
    return this.data.collection
      .map((trackData) => SoundCloudCollectionTrack.fromData(trackData))
      .filter((track): track is NonNullable<SoundCloudCollectionTrack> => !!track);
  }

  get nextHref() {
    return this.data?.next_href;
  }
}
