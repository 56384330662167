import { useSelector } from 'react-redux';
import {
  selectAccountId,
  selectAppInfoState,
  selectDeviceId,
  selectIsOnline,
  selectIsServerUp,
  selectRealmJWT,
  selectRealmPartition,
  selectUserToken,
  selectUserTokenDecoded,
} from './selectors';

export const useAppInfoState = () => useSelector(selectAppInfoState);

export const useRealmPartition = () => useSelector(selectRealmPartition);

export const useRealmJWT = () => useSelector(selectRealmJWT);

export const useUserToken = () => useSelector(selectUserToken);

export const useUserTokenDecoded = () => useSelector(selectUserTokenDecoded);

export const useAccountId = () => useSelector(selectAccountId);

export const useDeviceId = () => useSelector(selectDeviceId);

export const useIsOnline = () => useSelector(selectIsOnline);

export const useIsServerUp = () => useSelector(selectIsServerUp);
