import { GaanaCollectionTrack } from './GaanaCollectionTrack';
import { GaanaPlaylist } from './GaanaPlaylist';
import { tryParseInt } from '../../../utils/tryParseInt';
import { CollectionType } from '../../../generics/models/Collection';

export class GaanaPlaylistWithTracksResponse {
  private readonly data: any;

  private readonly userId: string;

  constructor(data: any, userId: string) {
    this.data = data;
    this.userId = userId;
  }

  get playlist(): GaanaPlaylist | null {
    const isUserOwner = this.data.playlist.user_id === this.userId;
    const type = isUserOwner ? CollectionType.PLAYLIST : CollectionType.LIKED_PLAYLIST;
    return GaanaPlaylist.fromData(this.data.playlist, type);
  }

  get tracks(): GaanaCollectionTrack[] {
    if (!this.data?.tracks) {
      return [];
    }

    return this.data.tracks
      .map((item: any) => GaanaCollectionTrack.fromData(item))
      .filter((track: GaanaCollectionTrack | null) => !!track);
  }

  get tracksTotal(): number {
    return tryParseInt(this.data.count) ?? 0;
  }
}
