import { useEffect } from 'react';
import { Environment } from 'fym-common/src/config/types';
import { PaddleProductSku, PaddleSubscriptionSku } from 'fym-common/src/utils/paddleSkus';
import { useScript } from './useScript';
import { config } from '../../../../config/config';
import { paddleSkuToAnalyticsPurchasePlan } from '../utils/paddleAnalytics';
import { SMLAnalytics } from '../../../analytics/sml/analytics';
import { AnalyticsPurchasePlan } from '../../../analytics/types';

export function usePaddle() {
  const isLoading = useScript('https://cdn.paddle.com/paddle/paddle.js');

  useEffect(() => {
    if (isLoading) {
      return;
    }
    window.Paddle.Setup({
      vendor: 14161,
      completeDetails: true,
      debug: config.environment !== Environment.production,
      eventCallback: (data: any) => {
        if (data.event === 'Checkout.Complete') {
          console.log(data.eventData?.product);
          const productId = data.eventData?.product?.id;
          const analyticsProductName: AnalyticsPurchasePlan | undefined =
            paddleSkuToAnalyticsPurchasePlan[productId as PaddleProductSku | PaddleSubscriptionSku];
          if (analyticsProductName !== undefined) {
            SMLAnalytics.trackPurchaseCompleted({
              plan: analyticsProductName,
              value: data.eventData?.checkout?.prices?.vendor?.unit,
              currency: data.eventData?.checkout?.prices?.vendor?.currency,
            }).catch(console.error);
          } else {
            console.error(`Got product[${productId}] that cannot be translated into AnalyticsPurchasePlan`);
          }
        }
      },
    });
  }, [isLoading]);

  return isLoading;
}
