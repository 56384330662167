import React from 'react';
import { FormText } from 'reactstrap';
import { TypedMemo } from '../../../common/types';

const FormHelpComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div>
      <FormText className="mb-2">{children}</FormText>
    </div>
  );
};

export const FormHelp = TypedMemo(FormHelpComponent);
