import { CollectionAdditionalData } from './AdditionalData';
import { CollectionAccess, CollectionType } from './Collection';

export type CollectionModelData = {
  rawId: string;
  name: string;
  type: CollectionType;
  itemCount?: number | null;
  followers?: number | null;
  publicUrl?: string | null;
  additionalData?: CollectionAdditionalData | null;
  access?: CollectionAccess | null;
};

export class GenericCollection {
  public rawId;

  public name;

  public itemCount;

  public type;

  public additionalData;

  public followers;

  public publicUrl;

  public access;

  constructor(data: CollectionModelData) {
    const { type, rawId, name, itemCount, additionalData, followers, publicUrl, access } = data;
    this.type = type;
    this.rawId = Number.isInteger(rawId) ? `${rawId}` : rawId;
    this.name = name;
    this.itemCount = itemCount ?? null;
    this.additionalData = additionalData ?? null;
    this.followers = followers ?? null;
    this.publicUrl = publicUrl ?? null;
    this.access = access ?? null;
  }
}
