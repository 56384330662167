import { APIError } from './APIError';
import { HttpStatusCode } from './HttpStatusCode';

export class HTTP429Error extends APIError {
  constructor(retryAfter: number) {
    super({
      message:
        'You have exceeded the rate limit for this endpoint or server is currently unable to handle the request. Please wait and retry after the indicated time.',
      httpStatusCode: HttpStatusCode.TooManyRequests,
      isOperational: true,
      headers: { 'retry-after': `${retryAfter}` },
    });
  }
}
