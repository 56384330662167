import { isNumber } from '../../../utils/isNumber';
import { RecentlyPlayedTrack } from '../types';
import { GroupedRecentlyPlayedTracks, BasicTrackStats, Year } from './types';

function serialiseAndSortStats(
  groupedRecentlyPlayedTracks: GroupedRecentlyPlayedTracks
): Record<Year, BasicTrackStats[]> {
  const results: Record<Year, BasicTrackStats[]> = {};
  groupedRecentlyPlayedTracks.forEach((map, year) => {
    results[year] = Array.from(map.values()).sort((a, b) => b.playCount - a.playCount);
  });
  return results;
}

export function groupRecentlyPlayedTracks(
  recentlyPlayedTracksMap: Map<string, RecentlyPlayedTrack>
): Record<Year, BasicTrackStats[]> {
  const recentlyPlayedTracks = Array.from(recentlyPlayedTracksMap.values());
  const groupedTracks = recentlyPlayedTracks.reduce<GroupedRecentlyPlayedTracks>((results, { rawId, playedAt }) => {
    playedAt.forEach((trackPlayedAt) => {
      const year = new Date(trackPlayedAt).getFullYear().toString();
      let tracksPerYear = results.get(year);
      if (!tracksPerYear) {
        tracksPerYear = new Map();
        results.set(year, tracksPerYear);
      }
      const existingTrack = tracksPerYear.get(rawId);
      if (!existingTrack) {
        tracksPerYear.set(rawId, {
          rawId,
          playCount: 1,
          lastPlayed: trackPlayedAt,
        });
      } else {
        tracksPerYear.set(rawId, {
          ...existingTrack,
          playCount: existingTrack.playCount + 1,
          lastPlayed: isNumber(existingTrack.lastPlayed)
            ? Math.max(existingTrack.lastPlayed, trackPlayedAt)
            : trackPlayedAt,
        });
      }
    });
    return results;
  }, new Map());

  return serialiseAndSortStats(groupedTracks);
}
