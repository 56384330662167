import { AnghamiCollectionTrack } from './AnghamiCollectionTrack';
import { AnghamiAlbum } from './AnghamiAlbum';
import { tryParseInt } from '../../../utils/tryParseInt';

export class AnghamiAlbumWithTracksResponse {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get album(): AnghamiAlbum | null {
    return AnghamiAlbum.fromData(this.data);
  }

  get totalCount(): number | undefined {
    return tryParseInt(this.data.nbrsongs);
  }

  get tracks(): AnghamiCollectionTrack[] {
    if (!this.data.sections) {
      return [];
    }

    const section = this.data.sections.find((s: any) => s.type === 'song');
    if (!section) {
      return [];
    }
    return section.data.map((item: any) => AnghamiCollectionTrack.fromData(item));
  }
}
