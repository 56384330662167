import { domParser, getMetaContentByProperty, getScriptsContents } from '../../../utils/htmlUtils';
import { getArtistsNamesAsString } from '../utils';
import { TidalAlbum } from './TidalAlbum';
import { TidalArtist } from './TidalArtist';
import { tryParseDurationToMs } from '../../../utils/tryParseDurationToMs';
import { GenericMatchedItem, MatchedItemModelData } from '../../../generics/models/GenericMatchedItem';
import { convertTidalTrackData } from '../utils/convertTidalTrackData';

function getDuration(metaElements: HTMLCollectionOf<HTMLMetaElement>): number | undefined {
  const durationString = getMetaContentByProperty(metaElements, 'music:duration');
  return tryParseDurationToMs(durationString, 's');
}

function getImageUrl(metaElements: HTMLCollectionOf<HTMLMetaElement>): string | undefined {
  return getMetaContentByProperty(metaElements, 'og:image');
}

function getAlbum(htmlDoc: Document): string | undefined {
  const h1Elements = htmlDoc.getElementsByTagName('h1');
  const title = h1Elements[0];
  if (!title) {
    return undefined;
  }
  const anchors = title.getElementsByTagName('a');
  const anchor = anchors.item(0);
  return anchor?.firstChild?.nodeValue ?? undefined;
}

export class TidalMatchedTrack extends GenericMatchedItem {
  constructor(
    data: MatchedItemModelData,
    public albumExtended: TidalAlbum | null = null,
    public artists: TidalArtist[] | null = null
  ) {
    super(data);
  }

  static fromData(data: any): TidalMatchedTrack | null {
    const convertedData = convertTidalTrackData(data);
    if (!convertedData) {
      return null;
    }
    const { trackData, album, artists } = convertedData;
    return new TidalMatchedTrack(trackData, album, artists);
  }

  static fromShareURLData(HTMLString: string, url: string): TidalMatchedTrack | null {
    const htmlDoc = domParser.parseFromString(HTMLString);
    const scriptsContents = getScriptsContents(HTMLString);
    const trackDataString = scriptsContents.find((content) => content.includes('context'));

    if (!trackDataString) {
      return null;
    }

    let trackData: any;
    try {
      trackData = JSON.parse(trackDataString);
    } catch (error) {
      console.error(error);
      return null;
    }
    if (!trackData) {
      return null;
    }
    const metaElements = htmlDoc.getElementsByTagName('meta');
    const urlParts = trackData.url.split('/');
    const rawId = urlParts[urlParts.length - 1];
    if (!rawId || !trackData.name) {
      return null;
    }
    return new TidalMatchedTrack({
      rawId,
      name: trackData.name,
      album: getAlbum(htmlDoc),
      artist: getArtistsNamesAsString(trackData.byArtist),
      imageUrl: getImageUrl(metaElements),
      duration: getDuration(metaElements),
      sourceURL: url,
    });
  }
}
