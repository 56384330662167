import { QQMusicAlbum, QQMusicSinger } from './types';

export function extractArtistNameFromSingers(singers: QQMusicSinger[] | undefined) {
  if (!singers) {
    return undefined;
  }
  return singers.map((singer) => singer.title).join(', ');
}

export function getCoverUrlFromAlbumMid(albumMid: QQMusicAlbum['mid'] | undefined) {
  return albumMid ? `https://y.gtimg.cn/music/photo_new/T002R800x800M000${albumMid}.jpg?max_age=2592000` : undefined;
}
