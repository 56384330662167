import { GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { createPandoraPublicUrl } from '../utils';

export class PandoraPlaylist extends GenericCollection {
  static fromData(data: any, url?: string): PandoraPlaylist | null {
    if (!data?.pandoraId || !data.name) {
      return null;
    }
    const playlistType = data.viewerInfo?.editable ? CollectionType.PLAYLIST : CollectionType.LIKED_PLAYLIST;
    return new PandoraPlaylist({
      type: playlistType,
      rawId: data.pandoraId,
      name: data.name,
      itemCount: data.totalTracks,
      additionalData: {
        version: data.version,
        shareableUrlPath: data.shareableUrlPath,
      },
      publicUrl: url ?? createPandoraPublicUrl(data.shareableUrlPath),
    });
  }
}
