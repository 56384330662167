import qs from 'qs';
import chunk from 'lodash/chunk';
import { defaultRetryOptions, fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { FetchError } from '../../generics/errors/FetchError';
import { ZvukPlaylist } from './models/ZvukPlaylist';
import { ZvukSearchResponse } from './models/ZvukSearchResponse';
import { ZvukAuthenticationData } from './ZvukAuthenticationData';
import { ZvukUser } from './models/ZvukUser';
import { ZvukAuth } from './models/ZvukAuth';
import { wait } from '../../utils/wait';
import { ZvukLibrary } from './models/ZvukLibrary';
import { ZvukAlbum } from './models/ZvukAlbum';
import { ZvukCollectionTrack } from './models/ZvukCollectionTrack';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { ImporterID } from '../types';

export class ZvukAPI {
  private static API_BASE_URL = 'https://zvuk.com/api/';

  private static SAPI_BASE_URL = 'https://zvuk.com/sapi/';

  private readonly accessToken: string;

  private readonly sapiAccessToken: string;

  public readonly userId: number;

  constructor(accessToken: string, sapiAccessToken: string, userId: number) {
    this.accessToken = accessToken;
    this.sapiAccessToken = sapiAccessToken;
    this.userId = userId;
  }

  private requestHeaders(isSapiEndpoint = false): { [key: string]: string } {
    return {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Auth-Token': isSapiEndpoint ? this.sapiAccessToken : this.accessToken,
      'X-Device-Id': `${this.userId}`,
    };
  }

  async fetch(url: string, options: any, retryOptions: RetryOptions = defaultRetryOptions): Promise<any> {
    const response = await fetchRetry(url, options, retryOptions);
    if (response.status === 418) {
      // Too many requests
      await wait(1000);
      return this.fetch(url, options, retryOptions);
    }
    const text = await response.text();
    if (response.status === 401) {
      throw new NotAuthenticatedError({ authId: `${this.userId}`, importerId: ImporterID.Zvuk }, text);
    }
    if (!response.ok) {
      throw new FetchError(response.status, text);
    }
    if (!text) {
      return undefined;
    }
    let data: any;
    try {
      data = JSON.parse(text);
    } catch (e) {
      throw new Error(`Could not parse JSON response text for ${url}: ${text}`);
    }

    if (data.errors) {
      throw new FetchError(400, JSON.stringify(data.errors));
    }

    return data;
  }

  async search(query: string, include: '(track release)' | 'release'): Promise<ZvukSearchResponse> {
    if (!query) {
      return new ZvukSearchResponse(null);
    }
    const data = await this.fetch(
      `${ZvukAPI.SAPI_BASE_URL}search/?${qs.stringify({
        include,
        from_shazam: 0,
        limit: 10,
        offset: 0,
        query,
        voice: 0,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders(true),
      }
    );

    return new ZvukSearchResponse(data?.result);
  }

  async loadPaginatedPlaylistItems(
    playlistId: string,
    onBatch: (tracks: ZvukCollectionTrack[]) => Promise<void>
  ): Promise<undefined> {
    const data = await this.fetch(
      `${ZvukAPI.SAPI_BASE_URL}meta/?${qs.stringify({
        include: '(playlist)',
        playlists: playlistId,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders(true),
      }
    );
    if (!data) {
      return;
    }
    const trackIds: string[] = data.result.playlists[playlistId].track_ids;
    const idsChunks = chunk(trackIds, 100);
    for (const ids of idsChunks) {
      const metaResponse = await this.fetch(
        `${ZvukAPI.SAPI_BASE_URL}meta/?${qs.stringify({
          include: '(track artist (release))',
          tracks: ids.join(','),
        })}`,
        {
          method: 'GET',
          headers: this.requestHeaders(true),
        }
      );
      if (metaResponse) {
        await onBatch(
          ids
            .map((trackId: any) => ZvukCollectionTrack.fromData(metaResponse.result.tracks[trackId]))
            .filter((track: ZvukCollectionTrack | null): track is NonNullable<typeof track> => !!track)
        );
      }
    }
  }

  async loadPaginatedAlbumItems(
    albumId: string,
    onBatch: (tracks: ZvukCollectionTrack[]) => Promise<void>
  ): Promise<void> {
    const data = await this.fetch(
      `${ZvukAPI.SAPI_BASE_URL}meta/?${qs.stringify({
        include: '(release)',
        releases: albumId,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders(true),
      }
    );
    if (!data) {
      return;
    }
    const trackIds: string[] = data.result.releases[albumId].track_ids;
    const idsChunks = chunk(trackIds, 100);
    for (const ids of idsChunks) {
      const metaResponse = await this.fetch(
        `${ZvukAPI.SAPI_BASE_URL}meta/?${qs.stringify({
          include: '(track artist (release))',
          tracks: ids.join(','),
        })}`,
        {
          method: 'GET',
          headers: this.requestHeaders(true),
        }
      );
      if (metaResponse) {
        await onBatch(
          ids
            .map((itemId: any) => ZvukCollectionTrack.fromData(metaResponse.result.tracks[itemId]))
            .filter((track: ZvukCollectionTrack | null): track is NonNullable<typeof track> => !!track)
        );
      }
    }
  }

  async loadPaginatedLikedSongsItems(onBatch: (tracks: ZvukCollectionTrack[]) => Promise<void>): Promise<void> {
    const { trackIds } = await this.loadUserLibrary();
    if (!trackIds) {
      return;
    }
    const idsChunks = chunk(trackIds, 100);
    for (const ids of idsChunks) {
      const metaResponse = await this.fetch(
        `${ZvukAPI.SAPI_BASE_URL}meta/?${qs.stringify({
          include: '(track artist (release))',
          tracks: ids.join(','),
        })}`,
        {
          method: 'GET',
          headers: this.requestHeaders(true),
        }
      );
      if (metaResponse) {
        await onBatch(
          ids
            .map((itemId: any) => ZvukCollectionTrack.fromData(metaResponse.result.tracks[itemId]))
            .filter((track: ZvukCollectionTrack | null): track is NonNullable<typeof track> => !!track)
        );
      }
    }
  }

  async loadPaginatedPlaylists(onBatch: (collections: ZvukPlaylist[]) => Promise<void>): Promise<void> {
    const { playlistIds } = await this.loadUserLibrary();
    if (!playlistIds) {
      return undefined;
    }
    const idsChunks = chunk(playlistIds, 100);
    for (const ids of idsChunks) {
      const metaResponse = await this.fetch(
        `${ZvukAPI.SAPI_BASE_URL}meta/?${qs.stringify({
          include: '(playlist)',
          playlists: ids.join(','),
        })}`,
        {
          method: 'GET',
          headers: this.requestHeaders(true),
        }
      );
      await onBatch(
        ids
          .map((itemId: any) => ZvukPlaylist.fromData(metaResponse.result.playlists[itemId], this.userId))
          .filter((playlist: ZvukPlaylist | null): playlist is NonNullable<typeof playlist> => !!playlist)
      );
    }
    return undefined;
  }

  async loadPaginatedAlbums(onBatch: (albums: ZvukAlbum[]) => Promise<void>): Promise<void> {
    const { releaseIds } = await this.loadUserLibrary();
    if (!releaseIds) {
      return;
    }
    const idsChunks = chunk(releaseIds, 100);
    for (const ids of idsChunks) {
      const metaResponse = await this.fetch(
        `${ZvukAPI.SAPI_BASE_URL}meta/?${qs.stringify({
          include: '(release)',
          releases: ids.join(','),
        })}`,
        {
          method: 'GET',
          headers: this.requestHeaders(true),
        }
      );
      await onBatch(
        ids
          .map((itemId: any) => ZvukAlbum.fromData(metaResponse.result.releases[itemId]))
          .filter((album: ZvukAlbum | null): album is NonNullable<typeof album> => !!album)
      );
    }
  }

  async loadUserLibrary(): Promise<ZvukLibrary> {
    const url = `${ZvukAPI.API_BASE_URL}v2/user/library`;
    const data = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders(),
    });
    return new ZvukLibrary(data);
  }

  async createPlaylist(name: string): Promise<ZvukPlaylist | null> {
    const data = await this.fetch(`${ZvukAPI.API_BASE_URL}playlist`, {
      method: 'POST',
      headers: this.requestHeaders(),
      body: qs.stringify({
        name,
        track_list: '[]',
        _timestamp: new Date().getTime(),
      }),
    });
    if (!data) {
      throw new Error('Could not create playlist');
    }
    return ZvukPlaylist.fromCreation(Object.values(data.result.playlists)[0]);
  }

  async getPlaylist(playlistId: string): Promise<ZvukPlaylist> {
    const data = await this.fetch(
      `${ZvukAPI.SAPI_BASE_URL}meta/?${qs.stringify({
        include: '(playlist)',
        playlists: playlistId,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders(true),
      }
    );
    if (!data?.result?.playlists) {
      throw new CollectionDoesNotExistsError('Zvuk did not return playlist');
    }
    const playlist = ZvukPlaylist.fromData(Object.values(data.result.playlists)[0], this.userId);
    if (!playlist) {
      throw new CollectionDoesNotExistsError('Zvuk did not return playlist');
    }
    return playlist;
  }

  async getAlbum(albumId: string): Promise<ZvukAlbum | null> {
    const data = await this.fetch(
      `${ZvukAPI.SAPI_BASE_URL}meta/?${qs.stringify({
        include: '(release)',
        releases: albumId,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders(true),
      }
    );
    if (!data) {
      throw new Error('Could not create playlist');
    }
    return ZvukAlbum.fromData(Object.values(data.result.releases)[0]);
  }

  async addTrackToPlaylist(playlistId: string, trackId: string): Promise<void> {
    const url = `${ZvukAPI.API_BASE_URL}tiny/playlist/append/?${qs.stringify({
      id: playlistId,
      track: trackId,
    })}`;
    await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders(),
    });
  }

  async addAlbumToLibrary(albumId: string): Promise<void> {
    const url = `${ZvukAPI.API_BASE_URL}tiny/library/add-release/?${qs.stringify({
      release_id: albumId,
    })}`;
    await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders(),
    });
  }

  async removeTracksFromPlaylist(playlistId: string, idsToBeRemoved: number[]): Promise<void> {
    if (idsToBeRemoved.length === 0) {
      return;
    }
    const { trackIds, updated, name } = await this.getPlaylist(playlistId);
    const url = `${ZvukAPI.API_BASE_URL}tiny/playlist/?${qs.stringify({
      id: playlistId,
      updated,
      name,
    })}`;
    const leftOverTracks = trackIds.filter((id) => idsToBeRemoved.indexOf(id) === -1);
    await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders(),
      body: qs.stringify({
        tracks: leftOverTracks.join(','),
      }),
    });
  }

  static getAuthData(auth: ZvukAuth, user: ZvukUser): ZvukAuthenticationData {
    return {
      authId: `${user.id}`,
      userUUID: null,
      title: user.name,
      subTitle: user.username,
      imageUrl: null,
      expiresAt: null,
      additionalData: {
        sapiAccessToken: auth.sapiAccessToken,
        accessToken: auth.accessToken,
      },
    };
  }

  static async login(email: string, password: string): Promise<ZvukAuth> {
    const response = await fetchRetry(`${ZvukAPI.API_BASE_URL}login_or_register`, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      },
      body: qs.stringify({
        email,
        password,
        active: 'true',
      }),
      method: 'POST',
    });
    if (response.status !== 200) {
      throw new Error(`Could not login [${response.status}]: ${await response.text()}`);
    }
    const data = await response.json();
    return new ZvukAuth(data.result);
  }

  async fetchMe(): Promise<ZvukUser> {
    return ZvukAPI.fetchProfile(this.accessToken);
  }

  static async fetchProfile(token: string): Promise<ZvukUser> {
    const response = await fetchRetry(`${ZvukAPI.API_BASE_URL}v2/tiny/profile`, {
      headers: {
        'X-Auth-Token': token,
      },
      method: 'GET',
    });
    if (response.status !== 200) {
      throw new Error(`Could not login [${response.status}]: ${await response.text()}`);
    }
    const data = await response.json();
    return new ZvukUser(data.result);
  }
}
