import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransferCollectionsState } from './state';
import { transferCollectionsSliceName } from './constants';
import { TransferCollectionModel } from '../../../realm/models/TransferCollectionModel';
import {
  FilterOptions,
  FilterOptionsEnum,
  SortOptions,
} from '../../../modules/transfersCollections/views/transfersCollectionsView/filters/props';
import { SongFilterOptions } from '../../../modules/transfersCollections/types';
import { TransferCollectionItemModel } from '../../../realm/models/TransferCollectionItemModel';

type ToggleCollectionModelIdPayload = {
  transferCollectionModelId: ReturnType<TransferCollectionModel['_id']['toHexString']>;
};
type ToggleManyCollectionModelIdsPayload = {
  transferCollectionModelIds: ReturnType<TransferCollectionModel['_id']['toHexString']>[];
};
type SetSortOptionPayload = {
  sortOption: SortOptions;
};
type SetFilterOptionsPayload = {
  filterOption: FilterOptions;
};
type SetManageMode = {
  manageMode: boolean;
};
type SetSearchQuery = {
  searchQuery: string;
};
type SetSongSearchQuery = {
  songSearchQuery: string;
};
type SetSongFilterOption = {
  songFilterOption: SongFilterOptions;
};
type SetItemBeingProcessedPayload = {
  transferCollectionIdString: ReturnType<TransferCollectionModel['_id']['toHexString']>;
  transferCollectionItemIdString: ReturnType<TransferCollectionItemModel['_id']['toHexString']>;
};
type ClearItemBeingProcessedPayload = {
  transferCollectionIdString: ReturnType<TransferCollectionModel['_id']['toHexString']>;
};
type SetTransferCollectionBeingProcessedPayload = {
  transferCollectionIdString: ReturnType<TransferCollectionModel['_id']['toHexString']>;
  priority: number;
};

const transferCollectionsSlice = createSlice({
  name: transferCollectionsSliceName,
  initialState: TransferCollectionsState,
  reducers: {
    resetTransferCollectionsState: () => TransferCollectionsState,
    toggleTransferCollectionModelId: (state, action: PayloadAction<ToggleCollectionModelIdPayload>) => {
      const { transferCollectionModelId } = action.payload;
      const index = state.selectedTransferCollectionsIds.indexOf(transferCollectionModelId);
      if (index === -1) {
        state.selectedTransferCollectionsIds.push(transferCollectionModelId);
      } else {
        state.selectedTransferCollectionsIds.splice(index, 1);
      }
    },
    toggleManyTransferCollectionModelIds: (state, action: PayloadAction<ToggleManyCollectionModelIdsPayload>) => {
      const { transferCollectionModelIds } = action.payload;
      const areAllSelected = transferCollectionModelIds.every((collectionId) =>
        state.selectedTransferCollectionsIds.includes(collectionId)
      );
      if (areAllSelected) {
        state.selectedTransferCollectionsIds = [];
      } else {
        state.selectedTransferCollectionsIds = Array.from(
          new Set([...state.selectedTransferCollectionsIds, ...transferCollectionModelIds])
        );
      }
    },
    setSortOption(state, action: PayloadAction<SetSortOptionPayload>) {
      state.sortOption = action.payload.sortOption;
    },
    setFilterOption(state, action: PayloadAction<SetFilterOptionsPayload>) {
      state.filterOption = action.payload.filterOption;
    },
    setManageMode(state, action: PayloadAction<SetManageMode>) {
      state.manageMode = action.payload.manageMode;
      state.selectedTransferCollectionsIds = [];
    },
    setSearchQuery(state, action: PayloadAction<SetSearchQuery>) {
      state.searchQuery = action.payload.searchQuery;
    },
    clearTransferSearchQueryAndFilterOption(state) {
      state.filterOption = {
        option: FilterOptionsEnum.All,
      };
      state.searchQuery = '';
    },
    setSongSearchQuery(state, action: PayloadAction<SetSongSearchQuery>) {
      state.songSearchQuery = action.payload.songSearchQuery;
    },
    setSongFilterOption(state, action: PayloadAction<SetSongFilterOption>) {
      state.songFilterOption = action.payload.songFilterOption;
    },
    clearSongSearchQueryAndFilterOption(state) {
      state.songFilterOption = SongFilterOptions.ALL;
      state.songSearchQuery = '';
    },

    setItemBeingProcessed(state, action: PayloadAction<SetItemBeingProcessedPayload>) {
      const { transferCollectionIdString, transferCollectionItemIdString } = action.payload;
      state.itemBeingProcessedByTransferCollection[transferCollectionIdString] = transferCollectionItemIdString;
    },
    clearItemBeingProcessed(state, action: PayloadAction<ClearItemBeingProcessedPayload>) {
      const { transferCollectionIdString } = action.payload;
      delete state.itemBeingProcessedByTransferCollection[transferCollectionIdString];
    },
    setTransferCollectionBeingProcessed: (state, action: PayloadAction<SetTransferCollectionBeingProcessedPayload>) => {
      if (
        state.transferCollectionBeingProcessed === undefined ||
        state.transferCollectionBeingProcessed.priority <= action.payload.priority
      ) {
        state.transferCollectionBeingProcessed = {
          id: action.payload.transferCollectionIdString,
          priority: action.payload.priority,
        };
      }
    },
    clearTransferCollectionBeingProcessed(state, action: PayloadAction<{ priority: number }>) {
      if (
        state.transferCollectionBeingProcessed === undefined ||
        state.transferCollectionBeingProcessed.priority <= action.payload.priority
      ) {
        state.transferCollectionBeingProcessed = undefined;
      }
    },
  },
});

const { actions, reducer } = transferCollectionsSlice;
export { reducer as transferCollectionsReducer };
export const {
  toggleManyTransferCollectionModelIds,
  toggleTransferCollectionModelId,
  resetTransferCollectionsState,
  setFilterOption,
  setSortOption,
  setManageMode,
  setSearchQuery,
  clearTransferSearchQueryAndFilterOption,
  setSongSearchQuery,
  setSongFilterOption,
  clearSongSearchQueryAndFilterOption,
  setItemBeingProcessed,
  clearItemBeingProcessed,
  setTransferCollectionBeingProcessed,
  clearTransferCollectionBeingProcessed,
} = actions;
