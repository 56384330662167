import { QQMusicTrackSearchData } from '../types';
import { extractArtistNameFromSingers, getCoverUrlFromAlbumMid } from '../utils';
import { GenericMatchedItem } from '../../../generics/models/GenericMatchedItem';

export class QQMusicMatchedTrack extends GenericMatchedItem {
  static fromData(data: QQMusicTrackSearchData | undefined): QQMusicMatchedTrack | null {
    if (data?.id === undefined || !data.title) {
      return null;
    }
    return new QQMusicMatchedTrack({
      rawId: `${data.id}`,
      name: data.title,
      album: data.album?.title,
      artist: extractArtistNameFromSingers(data?.singer),
      additionalData: { mid: data.mid },
      imageUrl: getCoverUrlFromAlbumMid(data?.album?.mid),
    });
  }

  static fromShareUrlData(data: any): QQMusicMatchedTrack | null {
    if (data?.id === undefined || !data.title) {
      return null;
    }
    return new QQMusicMatchedTrack({
      rawId: `${data.id}`,
      name: data.title,
      album: data.albumName,
      artist: extractArtistNameFromSingers(data.singer),
      imageUrl: getCoverUrlFromAlbumMid(data.albumMid),
    });
  }
}
