import { convertGaanaTrackData, createGaanaTrackUrl } from '../utils';
import { GenericMatchedItem } from '../../../generics/models/GenericMatchedItem';

export class GaanaMatchedTrack extends GenericMatchedItem {
  static fromData(data: any, url?: string): GaanaMatchedTrack | null {
    const trackData = convertGaanaTrackData(data, url);
    return trackData ? new GaanaMatchedTrack(trackData) : null;
  }

  static fromSearchData(data: any): GaanaMatchedTrack | null {
    if ((!data.iid && !data.seo) || !data.ti) {
      return null;
    }
    const id = `${data.iid}|${data.seo}`;
    return new GaanaMatchedTrack({
      rawId: id,
      name: data.ti,
      album: data.sti,
      imageUrl: data.aw,
      sourceURL: createGaanaTrackUrl(data.seo),
    });
  }
}
