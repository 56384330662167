import { AmazonMusicPlaylist } from './AmazonMusicPlaylist';
import { Playlist } from './types';
import { CollectionType } from '../../../generics/models/Collection';

interface TrackAdditionResponse {
  data?: {
    appendTracks?: Playlist;
  };
}

export class AmazonMusicTrackAdditionResponse {
  private readonly data;

  private readonly userId;

  constructor(data: TrackAdditionResponse | undefined, userId: string | undefined) {
    this.data = data;
    this.userId = userId;
  }

  get playlist() {
    return AmazonMusicPlaylist.fromData(this.data?.data?.appendTracks, this.userId, CollectionType.PLAYLIST);
  }
}
