import React from 'react';
import { useIntl } from 'react-intl';
import { ErrorBoundary } from './ErrorBoundary';
import { TypedMemo } from '../common/types';
import { useAsync } from '../../utils/useAsync';
import { LicenseInfo } from '../../realm/LicenseInfo';

async function loadLicenseFromStore() {
  return LicenseInfo.get();
}

const GlobalErrorBoundaryHandlerComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  const intl = useIntl();
  const { value: license } = useAsync(loadLicenseFromStore);

  return (
    <ErrorBoundary
      locale={intl.locale}
      realmId={undefined}
      licenseKey={license?.validLicense?.licenseKey}
      email={license?.validLicense?.email ?? ''}
    >
      {children}
    </ErrorBoundary>
  );
};

export const GlobalErrorBoundaryHandler = TypedMemo(GlobalErrorBoundaryHandlerComponent);
