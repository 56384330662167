import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { faPalette } from '@fortawesome/pro-solid-svg-icons/faPalette';
import { Col, Row } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import { TypedMemo } from '../../common/types';
import { SmartLinkDetailsViewRendererProps } from './props';
import { SmartLinkDetailsPublishButton } from './SmartLinkDetailsPublishButton';
import { SmartLinksDraftBadge } from '../smartLinksDraftBadge/SmartLinksDraftBadge';
import { WidgetsOptionsButton } from '../widgets/views/widgetsOptionsButton/WidgetsOptionsButton';
import { SMLPreview } from '../smlPreview/SMLPreview';
import { SMLBreadcrumbs } from '../smlBreadcrumbs/SMLBreadcrumbs';
import { SMLContainer } from '../smlContainer/SMLContainer';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { SMLButton } from '../../common/views/SMLButton/SMLButton';

const SmartLinkDetailsViewRendererComponent: React.FC<SmartLinkDetailsViewRendererProps> = ({
  goToSmartLinkEditionView,
  openModal,
  title,
  smartLink,
  isDraft,
  openUpselling,
  openSlugTakenModal,
  onPreviewMessageEvent,
}) => {
  const intl = useIntl();
  const router = useSMLRouter();

  const breadcrumbs = useMemo(() => {
    return [
      {
        name: intl.formatMessage({ id: 'app.sml.breadcrumbs.home', defaultMessage: 'Home' }),
        onClick: () => router.push(SMLRoutes.home),
      },
      {
        name: title,
        onClick: () =>
          router.push(SMLRoutes.manage, {
            accountId: smartLink.accountId,
            smartLinkId: smartLink.id,
          }),
      },
      { name: intl.formatMessage({ id: 'app.sml.breadcrumbs.creator', defaultMessage: 'Creator' }) },
    ];
  }, [intl, router, smartLink.accountId, smartLink.id, title]);

  const isBootstrapLessThanLG = useMediaQuery({ query: '(max-width: 991px)' });

  return (
    <>
      <SMLContainer header={<SMLBreadcrumbs breadcrumbs={breadcrumbs} />} lightBG>
        <Row className="h-100">
          <Col xs={12} lg={5} className="d-flex flex-column">
            <div className="shadow rounded-3 bg-white flex-column mb-4 py-3 px-4 d-flex flex-row align-content-start">
              {isDraft && (
                <div className="mb-3">
                  <SmartLinksDraftBadge size="lg" />
                </div>
              )}
              <div className="d-flex flex-column align-items-start justify-content-center flex-grow-1 pe-1">
                <p className="lead">{title}</p>
              </div>
              <div className="d-flex justify-content-between">
                <SMLButton onClick={goToSmartLinkEditionView} leftIcon={faPalette} color="dark" outline>
                  {intl.formatMessage({ id: 'app.buttons.customize', defaultMessage: 'Customize' })}
                </SMLButton>
                {isDraft && (
                  <SmartLinkDetailsPublishButton
                    accountId={smartLink.accountId}
                    smartLinkId={smartLink.id}
                    openModal={openModal}
                    openSlugTakenModal={openSlugTakenModal}
                    numberOfWidgets={smartLink.recentSmartLinkVersion.widgets?.length ?? 0}
                  />
                )}
              </div>
            </div>

            <div className="shadow rounded-3 bg-white flex-column mb-4 py-4 px-4 d-flex flex-column">
              <p className="font-weight-500 fs-4">
                {intl.formatMessage({
                  id: 'app.widgets.add.title',
                  defaultMessage: 'Click to add widgets',
                })}
              </p>
              <WidgetsOptionsButton
                accountId={smartLink.accountId}
                smartLinkId={smartLink.id}
                openUpselling={openUpselling}
              />
            </div>
          </Col>
          <Col xs={12} lg={7} className="h-100 d-none d-lg-block">
            {!isBootstrapLessThanLG && (
              <SMLPreview smartLink={smartLink} type="column" onPreviewMessageEvent={onPreviewMessageEvent} />
            )}
          </Col>
        </Row>
      </SMLContainer>
      {isBootstrapLessThanLG && (
        <SMLPreview smartLink={smartLink} type="button" onPreviewMessageEvent={onPreviewMessageEvent} />
      )}
    </>
  );
};

export const SmartLinkDetailsViewRenderer = TypedMemo(SmartLinkDetailsViewRendererComponent);
