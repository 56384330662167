import concat from 'lodash/concat';
import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import {
  getItemText,
  nav,
  parseArtistsNamesFromRuns,
  parseSearchArtistsNames,
  YouTubeMusicParser,
} from '../youTubeMusicParser';
import { createYouTubeMusicCollectionUrl, getBiggestImageUrlFromThumbnails } from '../utils';
import { tryParseInt } from '../../../utils/tryParseInt';

export class YouTubeMusicAlbum extends GenericCollection {
  constructor(data: Omit<CollectionModelData, 'type'>) {
    super({ type: CollectionType.ALBUM, ...data });
  }

  static fromData(data: any): YouTubeMusicAlbum | null {
    const browseId = nav(data, concat(YouTubeMusicParser.TITLE, YouTubeMusicParser.NAVIGATION_BROWSE_ID));
    const title = nav(data, YouTubeMusicParser.TITLE_TEXT);
    const runs = nav(data, YouTubeMusicParser.SUBTITLE);
    const artistFromRuns = parseArtistsNamesFromRuns(runs);
    const artist = artistFromRuns || nav(data, YouTubeMusicParser.SUBTITLE2);
    const thumbnails = nav(data, YouTubeMusicParser.THUMBNAIL_RENDERER);
    const imageUrl = getBiggestImageUrlFromThumbnails(thumbnails);
    if (!browseId || !title) {
      return null;
    }
    return new YouTubeMusicAlbum({
      rawId: browseId,
      name: title,
      additionalData: { artist, imageUrl },
      publicUrl: createYouTubeMusicCollectionUrl(nav(data, YouTubeMusicParser.PLAYLIST_ID)),
    });
  }

  static fromAlbumResponse(data: any, browseId: string): YouTubeMusicAlbum | null {
    const title = nav(data, YouTubeMusicParser.TITLE_TEXT);
    const secondSubtitleFirstText = nav(data, YouTubeMusicParser.SECOND_SUBTITLE)[0]?.text;
    let itemCount;
    if (secondSubtitleFirstText) {
      const parsedCount = tryParseInt(secondSubtitleFirstText.split(' ')[0]);
      if (Number.isInteger(parsedCount)) {
        itemCount = parsedCount;
      }
    }
    const runs = nav(data, YouTubeMusicParser.SUBTITLE);
    const artistFromRuns = parseArtistsNamesFromRuns(runs);
    const artist = artistFromRuns || nav(data, YouTubeMusicParser.SUBTITLE2);
    const thumbnails = nav(data, YouTubeMusicParser.THUMBNAIL_SQUARE_RENDERER);
    const imageUrl = thumbnails && getBiggestImageUrlFromThumbnails(thumbnails);
    const menuItems = nav(data, YouTubeMusicParser.MENU_ITEMS);
    const shuffleItem = menuItems.find((menuItem: any) => {
      return menuItem?.menuNavigationItemRenderer?.icon?.iconType === 'MUSIC_SHUFFLE';
    });
    const playlistId = shuffleItem?.menuNavigationItemRenderer?.navigationEndpoint?.watchPlaylistEndpoint?.playlistId;
    if (!browseId || !title) {
      return null;
    }
    return new YouTubeMusicAlbum({
      rawId: browseId,
      name: title,
      additionalData: { artist, imageUrl, playlistId },
      itemCount,
      publicUrl: createYouTubeMusicCollectionUrl(nav(data, YouTubeMusicParser.PLAYLIST_ID)),
    });
  }

  static fromSearchData(data: any): YouTubeMusicAlbum | null {
    const browseId = nav(data, YouTubeMusicParser.NAVIGATION_BROWSE_ID);
    const title = getItemText(data, 0);
    const artist = parseSearchArtistsNames(data, 2);
    const thumbnails = nav(data, YouTubeMusicParser.THUMBNAILS);
    const imageUrl = getBiggestImageUrlFromThumbnails(thumbnails);
    const menuItems = nav(data, YouTubeMusicParser.MENU_ITEMS);
    const addToPlaylistMenuItem = menuItems.find(
      (menuItem: any) => menuItem?.menuNavigationItemRenderer?.icon?.iconType === 'ADD_TO_PLAYLIST'
    );
    const playlistId =
      addToPlaylistMenuItem?.menuNavigationItemRenderer?.navigationEndpoint?.addToPlaylistEndpoint?.playlistId;
    if (!browseId || !title) {
      return null;
    }
    return new YouTubeMusicAlbum({
      rawId: browseId,
      name: title,
      additionalData: { artist, imageUrl, playlistId },
      publicUrl: createYouTubeMusicCollectionUrl(nav(data, YouTubeMusicParser.PLAYLIST_ID)),
    });
  }
}
