import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { MusicApiIntegrationUserModel } from '../types';
import { MusicAPIIntegrationID } from '../../musicServices/types';

// This is used, so we know the schema of AmazonMusic auth data is new like MusicAPI wants
export const confirmationKeyNameForAuthData = 'authHasBeenUpgraded';

export function integrationUserToGenericAuthData<AuthData>(
  integrationId: MusicAPIIntegrationID,
  user: MusicApiIntegrationUserModel<AuthData>
): GenericAuthenticationData {
  const authData: GenericAuthenticationData = {
    authId: user.integrationUserId,
    userUUID: user.uuid,
    title: user.name ?? user.integrationUserId,
    subTitle: user.email ?? '',
    imageUrl: user.imageUrl ?? null,
    expiresAt:
      user.authDataExpiresAt !== null && user.authDataExpiresAt !== undefined ? new Date(user.authDataExpiresAt) : null,
    additionalData: user.authData ?? null,
  };
  if (integrationId === MusicAPIIntegrationID.AmazonMusic) {
    if (authData.additionalData) {
      authData.additionalData[confirmationKeyNameForAuthData] = true;
    }
  }
  if (integrationId === MusicAPIIntegrationID.Tidal) {
    // Workaround for our system
    if (authData.additionalData) {
      authData.additionalData.countryCode = user.country;
    }
  }
  return authData;
}
