import { ReactComponent as AmazonLight } from '../../../../assets/images/music-services/amazon-light.svg';
import { ReactComponent as AmazonDark } from '../../../../assets/images/music-services/amazon-dark.svg';
import { ReactComponent as AmazonLightPadding } from '../../../../assets/images/music-services/amazon-light-padding.svg';
import { ReactComponent as AmazonDarkPadding } from '../../../../assets/images/music-services/amazon-dark-padding.svg';
import { ReactComponent as AmazonColorLight } from '../../../../assets/images/music-services/amazon-color-light.svg';
import { ReactComponent as AmazonColorDark } from '../../../../assets/images/music-services/amazon-color-dark.svg';
import { ReactComponent as AmazonColorLightPadding } from '../../../../assets/images/music-services/amazon-color-light-padding.svg';
import { ReactComponent as AmazonColorDarkPadding } from '../../../../assets/images/music-services/amazon-color-dark-padding.svg';
import { ReactComponent as AmazonDarkDanger } from '../../../../assets/images/music-services-danger/amazon-dark-danger.svg';
import { ReactComponent as AmazonLightDanger } from '../../../../assets/images/music-services-danger/amazon-light-danger.svg';
import { ReactComponent as AmazonLightDangerPadding } from '../../../../assets/images/music-services-danger/amazon-light-padding-danger.svg';
import { ReactComponent as AmazonDarkDangerPadding } from '../../../../assets/images/music-services-danger/amazon-dark-padding-danger.svg';
import { ReactComponent as AmazonDarkWarning } from '../../../../assets/images/music-services-warning/amazon-dark-warning.svg';
import { ReactComponent as AmazonLightWarning } from '../../../../assets/images/music-services-warning/amazon-light-warning.svg';
import { ReactComponent as AmazonLightWarningPadding } from '../../../../assets/images/music-services-warning/amazon-light-padding-warning.svg';
import { ReactComponent as AmazonDarkWarningPadding } from '../../../../assets/images/music-services-warning/amazon-dark-padding-warning.svg';
import { ImporterImages } from '../../generics/ImporterImages';

export const amazonImages: ImporterImages = {
  logo: {
    light: AmazonLight,
    dark: AmazonDark,
  },
  logoPadding: {
    light: AmazonLightPadding,
    dark: AmazonDarkPadding,
  },
  logoColor: {
    light: AmazonColorLight,
    dark: AmazonColorDark,
  },
  logoColorPadding: {
    light: AmazonColorLightPadding,
    dark: AmazonColorDarkPadding,
  },
  logoOnBackground: AmazonColorDark,
  logoDanger: {
    dark: AmazonDarkDanger,
    light: AmazonLightDanger,
  },
  logoDangerPadding: {
    dark: AmazonDarkDangerPadding,
    light: AmazonLightDangerPadding,
  },
  logoWarning: {
    dark: AmazonDarkWarning,
    light: AmazonLightWarning,
  },
  logoWarningPadding: {
    light: AmazonLightWarningPadding,
    dark: AmazonDarkWarningPadding,
  },
};
