import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { emailSchema } from 'fym-common/src/joi/schemas';
import { FieldsName } from './consts';

const baseEmailSchema = emailSchema.label('Email').required();

export const resolver = joiResolver(
  Joi.object({
    [FieldsName.email]: baseEmailSchema,
  })
);
