import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { PlatformUniversal } from 'fym-common/src/utils/types';
import { Environment } from 'fym-common/src/config/types';
import { TypedMemo } from '../../common/types';
import { SMLSettingsViewRenderer } from './SMLSettingsViewRenderer';
import { config } from '../../../config/config';
import { SettingSection } from './SettingsProps';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { useSMLNavigateToWebsitePage } from '../useSMLNavigateToWebsitePage';
import { SMLBreadcrumbs } from '../../smartLinks/smlBreadcrumbs/SMLBreadcrumbs';
import { SMLContainer } from '../../smartLinks/smlContainer/SMLContainer';
import { appInformation } from '../../../utils/appInformation';
import { RemoveAccountDataModalSML } from '../../common/views/modals/removeAccountDataModalSML/RemoveAccountDataModalSML';

const SMLSettingsViewComponent: React.FC = () => {
  const intl = useIntl();
  const router = useSMLRouter();
  const navigateToWebsitePage = useSMLNavigateToWebsitePage();
  const [isRemoveAccountModalVisible, setRemoveAccountModalVisible] = useState(false);
  const closeRemoveAccountModal = useCallback(() => {
    setRemoveAccountModalVisible(false);
  }, []);
  const openRemoveAccountModal = useCallback(() => {
    setRemoveAccountModalVisible(true);
  }, []);

  const goToSmartLinksPremiumView = useCallback(() => {
    router.push(SMLRoutes.premium);
  }, [router]);
  const goToChangePassword = useCallback(() => {
    router.push(SMLRoutes.changePasswordLoggedIn);
  }, [router]);

  const getGoToUrlFn = useCallback(
    (url: string) => async () => {
      await navigateToWebsitePage(url);
    },
    [navigateToWebsitePage]
  );

  const fymSettings: SettingSection = useMemo(
    () => ({
      title: intl.formatMessage({
        id: 'app.settings.settings',
        defaultMessage: 'Settings',
      }),
      data: [
        {
          id: 'premium',
          text: intl.formatMessage({
            id: 'app.settings.premium',
            defaultMessage: 'Premium',
          }),
          onClick: goToSmartLinksPremiumView,
        },
        {
          id: 'change_password',
          text: intl.formatMessage({
            id: 'app.settings.change-password',
            defaultMessage: 'Change Password',
          }),
          onClick: goToChangePassword,
        },
      ],
    }),
    [goToChangePassword, goToSmartLinksPremiumView, intl]
  );

  const otherSettings: SettingSection = useMemo(
    () => ({
      title: intl.formatMessage({
        id: 'app.settings.other',
        defaultMessage: 'Other',
      }),
      data: [
        {
          id: 'delete_account',
          text: intl.formatMessage({
            id: 'app.settings.delete-account-data',
            defaultMessage: 'Delete account',
          }),
          onClick: openRemoveAccountModal,
          isHidden: appInformation.platform !== PlatformUniversal.ios && config.environment === Environment.production,
        },
        {
          id: 'privacy_policy',
          text: intl.formatMessage({
            id: 'app.settings.privacy_policy',
            defaultMessage: 'Privacy Policy',
          }),
          onClick: getGoToUrlFn(`${config.smartLinksWebURL}en/privacy-policy`),
        },
        {
          id: 'terms_of_service',
          text: intl.formatMessage({
            id: 'app.settings.terms_of_service',
            defaultMessage: 'Terms Of Service',
          }),
          onClick: getGoToUrlFn(`${config.smartLinksWebURL}en/terms-of-service`),
        },
      ],
    }),
    [getGoToUrlFn, intl, openRemoveAccountModal]
  );

  const sectionListData: SettingSection[] = useMemo(() => {
    return [fymSettings, otherSettings];
  }, [fymSettings, otherSettings]);

  const breadcrumbs = useMemo(() => {
    return [
      {
        name: intl.formatMessage({ id: 'app.sml.breadcrumbs.home', defaultMessage: 'Home' }),
        onClick: () => router.push(SMLRoutes.home),
      },
      {
        name: intl.formatMessage({ id: 'app.sml.breadcrumbs.settings', defaultMessage: 'Settings' }),
      },
    ];
  }, [intl, router]);

  return (
    <SMLContainer header={<SMLBreadcrumbs breadcrumbs={breadcrumbs} />}>
      <RemoveAccountDataModalSML isVisible={isRemoveAccountModalVisible} closeModal={closeRemoveAccountModal} />
      <SMLSettingsViewRenderer sectionListData={sectionListData} />
    </SMLContainer>
  );
};

export const SMLSettingsView = TypedMemo(SMLSettingsViewComponent);
