import { ReactComponent as FileLight } from '../../../../assets/images/music-services/file-light.svg';
import { ReactComponent as FileDark } from '../../../../assets/images/music-services/file-dark.svg';
import { ReactComponent as FileLightPadding } from '../../../../assets/images/music-services/file-light-padding.svg';
import { ReactComponent as FileDarkPadding } from '../../../../assets/images/music-services/file-dark-padding.svg';
import { ReactComponent as FileColorLight } from '../../../../assets/images/music-services/file-color-light.svg';
import { ReactComponent as FileColorDark } from '../../../../assets/images/music-services/file-color-dark.svg';
import { ReactComponent as FileColorLightPadding } from '../../../../assets/images/music-services/file-color-light-padding.svg';
import { ReactComponent as FileColorDarkPadding } from '../../../../assets/images/music-services/file-color-dark-padding.svg';
import { ReactComponent as FileDarkDanger } from '../../../../assets/images/music-services-danger/file-dark-danger.svg';
import { ReactComponent as FileLightDanger } from '../../../../assets/images/music-services-danger/file-light-danger.svg';
import { ReactComponent as FileLightDangerPadding } from '../../../../assets/images/music-services-danger/file-light-padding-danger.svg';
import { ReactComponent as FileDarkDangerPadding } from '../../../../assets/images/music-services-danger/file-dark-padding-danger.svg';
import { ReactComponent as FileDarkWarning } from '../../../../assets/images/music-services-warning/file-dark-warning.svg';
import { ReactComponent as FileLightWarning } from '../../../../assets/images/music-services-warning/file-light-warning.svg';
import { ReactComponent as FileLightWarningPadding } from '../../../../assets/images/music-services-warning/file-light-padding-warning.svg';
import { ReactComponent as FileDarkWarningPadding } from '../../../../assets/images/music-services-warning/file-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const fileImages: ImporterImages = {
  logo: {
    light: FileLight,
    dark: FileDark,
  },
  logoPadding: {
    light: FileLightPadding,
    dark: FileDarkPadding,
  },
  logoColor: {
    light: FileColorLight,
    dark: FileColorDark,
  },
  logoColorPadding: {
    light: FileColorLightPadding,
    dark: FileColorDarkPadding,
  },
  logoOnBackground: FileDark,
  logoDanger: {
    dark: FileDarkDanger,
    light: FileLightDanger,
  },
  logoDangerPadding: {
    dark: FileDarkDangerPadding,
    light: FileLightDangerPadding,
  },
  logoWarning: {
    dark: FileDarkWarning,
    light: FileLightWarning,
  },
  logoWarningPadding: {
    light: FileLightWarningPadding,
    dark: FileDarkWarningPadding,
  },
};
