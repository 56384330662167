import React, { useCallback } from 'react';
import { SMLCreationModalProps } from './props';
import { TypedMemo } from '../../../types';
import { SMLRoutes } from '../../../../../routing/sml/SMLRoutes';
import { useSMLRouter } from '../../../../../routing/sml/hooks';
import { SMLCreationModalRenderer } from './SMLCreationModalRenderer';
import { SMLAnalytics } from '../../../../analytics/sml/analytics';

const SMLCreationModalComponent: React.FC<SMLCreationModalProps> = ({ isOpen, closeModal, accountId }) => {
  const router = useSMLRouter();

  const goToCustomSMLCreation = useCallback(() => {
    closeModal();
    SMLAnalytics.trackCreateOpened('custom').catch(console.error);
    router.push(SMLRoutes.create, { accountId });
  }, [router, accountId, closeModal]);

  const goToPlaylistLinkCreation = useCallback(() => {
    closeModal();
    SMLAnalytics.trackCreateOpened('playlist').catch(console.error);
    router.push(SMLRoutes.playlistLink, { accountId });
  }, [router, accountId, closeModal]);

  const goToReleaseLinkCreation = useCallback(() => {
    closeModal();
    SMLAnalytics.trackCreateOpened('release-link').catch(console.error);
    router.push(SMLRoutes.releaseLink, { accountId });
  }, [router, accountId, closeModal]);

  return (
    <SMLCreationModalRenderer
      isOpen={isOpen}
      closeModal={closeModal}
      onCustomSmartLinkClick={goToCustomSMLCreation}
      onPlaylistLinkClick={goToPlaylistLinkCreation}
      onReleaseLinkClick={goToReleaseLinkCreation}
    />
  );
};

export const SMLCreationModal = TypedMemo(SMLCreationModalComponent);
