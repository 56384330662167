import React from 'react';
import { Pagination } from 'reactstrap';
import { TypedMemo } from '../../types';
import { APIPaginationRendererProps } from './types';
import { APIPaginationItem } from './APIPaginationItem';

export const APIPaginationRendererComponent: React.FC<APIPaginationRendererProps> = ({ pages, onPageChange }) => {
  return (
    <Pagination className="pt-3 pb-2 d-flex justify-content-center">
      {pages.map(({ page, label, key, disabled, active }) => (
        <APIPaginationItem
          page={page}
          label={label}
          key={key}
          disabled={disabled}
          active={active}
          onPageChange={onPageChange}
        />
      ))}
    </Pagination>
  );
};
export const APIPaginationRenderer = TypedMemo(APIPaginationRendererComponent);
