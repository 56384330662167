import { ReactComponent as PandoraLight } from '../../../../assets/images/music-services/pandora-light.svg';
import { ReactComponent as PandoraDark } from '../../../../assets/images/music-services/pandora-dark.svg';
import { ReactComponent as PandoraLightPadding } from '../../../../assets/images/music-services/pandora-light-padding.svg';
import { ReactComponent as PandoraDarkPadding } from '../../../../assets/images/music-services/pandora-dark-padding.svg';
import { ReactComponent as PandoraColorLight } from '../../../../assets/images/music-services/pandora-color-light.svg';
import { ReactComponent as PandoraColorDark } from '../../../../assets/images/music-services/pandora-color-dark.svg';
import { ReactComponent as PandoraColorLightPadding } from '../../../../assets/images/music-services/pandora-color-light-padding.svg';
import { ReactComponent as PandoraColorDarkPadding } from '../../../../assets/images/music-services/pandora-color-dark-padding.svg';
import { ReactComponent as PandoraDarkDanger } from '../../../../assets/images/music-services-danger/pandora-dark-danger.svg';
import { ReactComponent as PandoraLightDanger } from '../../../../assets/images/music-services-danger/pandora-light-danger.svg';
import { ReactComponent as PandoraLightDangerPadding } from '../../../../assets/images/music-services-danger/pandora-light-padding-danger.svg';
import { ReactComponent as PandoraDarkDangerPadding } from '../../../../assets/images/music-services-danger/pandora-dark-padding-danger.svg';
import { ReactComponent as PandoraDarkWarning } from '../../../../assets/images/music-services-warning/pandora-dark-warning.svg';
import { ReactComponent as PandoraLightWarning } from '../../../../assets/images/music-services-warning/pandora-light-warning.svg';
import { ReactComponent as PandoraLightWarningPadding } from '../../../../assets/images/music-services-warning/pandora-light-padding-warning.svg';
import { ReactComponent as PandoraDarkWarningPadding } from '../../../../assets/images/music-services-warning/pandora-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const pandoraImages: ImporterImages = {
  logo: {
    light: PandoraLight,
    dark: PandoraDark,
  },
  logoPadding: {
    light: PandoraLightPadding,
    dark: PandoraDarkPadding,
  },
  logoColor: {
    light: PandoraColorLight,
    dark: PandoraColorDark,
  },
  logoColorPadding: {
    light: PandoraColorLightPadding,
    dark: PandoraColorDarkPadding,
  },
  logoOnBackground: PandoraDark,
  logoDanger: {
    dark: PandoraDarkDanger,
    light: PandoraLightDanger,
  },
  logoDangerPadding: {
    dark: PandoraDarkDangerPadding,
    light: PandoraLightDangerPadding,
  },
  logoWarning: {
    dark: PandoraDarkWarning,
    light: PandoraLightWarning,
  },
  logoWarningPadding: {
    light: PandoraLightWarningPadding,
    dark: PandoraDarkWarningPadding,
  },
};
