import { PaddlePrice } from './PaddlePrice';

export class PaddleSubscription {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get frequency(): number {
    return this.data.frequency;
  }

  get trialDays(): number {
    return this.data.trial_days;
  }

  get interval(): string {
    return this.data.interval;
  }

  get months(): number {
    if (this.interval === 'year') {
      return this.frequency * 12;
    }
    return this.frequency;
  }

  get price() {
    return new PaddlePrice(this.data.price);
  }

  get listPrice() {
    return new PaddlePrice(this.data.list_price);
  }
}
