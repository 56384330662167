import React from 'react';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../../common/types';
import { PaymentPlanBox } from '../paymentPlanBox/PaymentPlanBox';
import { PaddlePaymentPlanProps } from './props';

const PaddlePaymentPlanComponent: React.FC<PaddlePaymentPlanProps> = ({
  title,
  description,
  highlightedText,
  highlightedTextSpace,
  price,
  currency,
  onClick,
  isDisabled,
}) => {
  const intl = useIntl();

  return (
    <PaymentPlanBox
      onClick={onClick}
      title={intl.formatMessage(title)}
      description={description ? intl.formatMessage(description) : ''}
      highlightedText={highlightedText && intl.formatMessage(highlightedText)}
      highlightedTextSpace={highlightedTextSpace}
      price={
        price > 0
          ? intl.formatNumber(price, {
              currency,
              style: 'currency',
            })
          : intl.formatMessage({
              id: 'app.plan_box.premium.free',
              defaultMessage: 'FREE',
            })
      }
      isDisabled={isDisabled}
    />
  );
};

export const PaddlePaymentPlan = TypedMemo(PaddlePaymentPlanComponent);
