import { GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { CollectionType } from '../../generics/models/Collection';
import { PandoraAPI } from './PandoraAPI';
import { PandoraAuthenticationData } from './PandoraAuthenticationData';
import { FetchError } from '../../generics/errors/FetchError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { PandoraPlaylist } from './models/PandoraPlaylist';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { SearchQueryProperties } from '../../generics/types';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { convertQueryPropsToString } from '../services/MatchingService.helpers';
import { ImporterID } from '../types';
import { pandora } from '../../musicServices/services/Pandora';
import { PandoraCollectionTrack } from './models/PandoraCollectionTrack';

const createPandoraInstance = (authenticationData: PandoraAuthenticationData): PandoraAPI => {
  return new PandoraAPI(authenticationData.authId, authenticationData.additionalData.authToken);
};

export const PandoraImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.Pandora;

  public static musicService = pandora;

  public authenticationData: PandoraAuthenticationData;

  private pandoraApi: PandoraAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as PandoraAuthenticationData;
    this.pandoraApi = createPandoraInstance(this.authenticationData);
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.authenticationData = authenticationData as PandoraAuthenticationData;
    this.pandoraApi = createPandoraInstance(this.authenticationData);
  }

  async getPaginatedCollections(onBatch: (collections: PandoraPlaylist[]) => Promise<void>): Promise<void> {
    await this.pandoraApi.loadPaginatedPlaylists(onBatch);
  }

  async getCollection(collection: GenericCollection): Promise<PandoraPlaylist> {
    let result: PandoraPlaylist | null = null;
    try {
      result = (await this.pandoraApi.loadPlaylistItemPage(collection.rawId)).playlist;
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CollectionDoesNotExistsError(e.message);
      }
      throw e;
    }
    if (!result) {
      throw new CollectionDoesNotExistsError();
    }
    return result;
  }

  async createCollection(collection: GenericCollection): Promise<PandoraPlaylist> {
    let newCollection: PandoraPlaylist | null = null;
    try {
      newCollection = await this.pandoraApi.createPlaylist(collection.name);
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!newCollection) {
      throw new CouldNotCreateCollection();
    }
    return newCollection;
  }

  async addItemToCollection(collection: GenericCollection, matchedItem: GenericMatchedItem) {
    await this.pandoraApi.addTracksToPlaylist(collection.rawId, [matchedItem.rawId]);
    return convertMatchedItemToCollectionItem(matchedItem);
  }

  async addManyItemsToCollection(
    collection: GenericCollection,
    data: {
      matchedItem: GenericMatchedItem;
      position?: number;
    }[]
  ) {
    const tracksIds = data.map(({ matchedItem }) => matchedItem.rawId);
    await this.pandoraApi.addTracksToPlaylist(collection.rawId, tracksIds);
  }

  async moveManyItems(props: Parameters<NonNullable<GenericImporter['moveManyItems']>>[0]) {
    const { collection, itemsToMove, offset } = props;
    const collectionItemsWithPositions: (PandoraCollectionTrack & { position: number })[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    const version = await this.pandoraApi.loadPaginatedPlaylistItems(collection.rawId, async (items) => {
      collectionItemsWithPositions.push(
        ...items.map((item, index) => ({
          ...item,
          position: item.position ?? index,
        }))
      );
    });
    const collectionItemsToMove = itemsToMove.map((itemToMove) => {
      const foundItem = collectionItemsWithPositions.find(
        (collectionItem) => collectionItem.rawId === itemToMove.rawId
      );
      if (!foundItem) {
        throw new Error('Item to move does not exist in playlist');
      }
      return foundItem;
    });
    const moves: Parameters<typeof this.pandoraApi.moveTrackInPlaylist>[2] = [];
    for (const item of collectionItemsToMove) {
      if (item.itemId === undefined) {
        throw new Error(`Missing itemId`);
      }
      moves.push({
        itemId: item.itemId,
        oldIndex: item.position,
        newIndex: item.position + offset,
      });
    }
    await this.pandoraApi.moveTrackInPlaylist(collection.rawId, version, moves);
  }

  async matchItems(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const { tracks } = await this.pandoraApi.search(query);
    return tracks;
  }

  async reAuthenticate(_withData: GenericAuthenticationData): Promise<GenericAuthenticationData> {
    return Promise.reject(new Error('Cannot reauthenticate'));
  }

  doesSupportReAuth() {
    return false;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportAlbums(): boolean {
    return false;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return true;
  }

  public doesSupportSearchByISRC(): boolean {
    return false;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return false;
  }

  public doesSupportMovingManyItems(): boolean {
    return true;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    switch (forCollection.type) {
      case CollectionType.PLAYLIST:
      case CollectionType.LIKED_PLAYLIST:
        await this.pandoraApi.loadPaginatedPlaylistItems(forCollection.rawId, onBatch);
        return undefined;
      default:
        return undefined;
    }
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    itemsToRemove: GenericCollectionItem[]
  ): Promise<void> {
    if (collection.type !== CollectionType.PLAYLIST || itemsToRemove.length === 0) {
      return;
    }
    const itemsIdsToRemove = itemsToRemove.map((item) => item.rawId);
    await this.pandoraApi.removeTracksFromPlaylist(collection.rawId, itemsIdsToRemove);
  }

  async clearCollection(collection: GenericCollection) {
    if (collection.type !== CollectionType.PLAYLIST) {
      return;
    }
    await this.pandoraApi.removeAllTracksFromPlaylist(collection.rawId);
  }

  async getCollectionPublicUrl(collection: GenericCollection) {
    if (collection.type !== CollectionType.PLAYLIST) {
      return null;
    }
    const playlistResponse = await this.pandoraApi.loadPlaylistItemPage(collection.rawId);
    if (!playlistResponse.playlist) {
      return null;
    }
    return `https://pandora.com${playlistResponse.playlist.additionalData?.shareableUrlPath}`;
  }
};
