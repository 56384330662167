export class BaseObject {
  public privateData: any;

  constructor(data: any = null) {
    if (data) {
      this.data = data;
    } else {
      this.data = {};
    }
  }

  set data(data: any) {
    this.privateData = data;
    // You can override it to parse custom JSON
  }
}
