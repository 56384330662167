import { SpotifyCollectionTrack } from './SpotifyCollectionTrack';
import { SpotifyTopSongsResponseType } from './types';

export class SpotifyTopSongsResponse {
  private readonly data;

  constructor(data: SpotifyTopSongsResponseType | undefined) {
    this.data = data;
  }

  get tracks(): SpotifyCollectionTrack[] {
    if (!this.data?.items) {
      return [];
    }
    return this.data.items
      .map((item) => SpotifyCollectionTrack.fromData(item))
      .filter((track): track is SpotifyCollectionTrack => !!track);
  }

  get next() {
    return this.data?.next;
  }
}
