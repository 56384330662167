import { SerializedSmartLink } from 'fym-common/src/db/serializersTypes';
import { useCallback, useEffect, useMemo } from 'react';
import { useSendMessageToPreview } from './useSendMessageToPreview';
import { PostMessagableWindow, SMLAppMessages } from './types';

export const useSendSmartLinkUpdateToPreview = (childWindow: PostMessagableWindow, smartLink?: SerializedSmartLink) => {
  if (smartLink === undefined) {
    return undefined;
  }
  const { editUrl } = smartLink.recentSmartLinkVersion;
  const domain = useMemo(() => (editUrl ? new URL(editUrl).origin : ''), [editUrl]);
  const sendMessageToPreview = useSendMessageToPreview(domain);

  const update = useCallback(() => {
    sendMessageToPreview(childWindow, {
      type: SMLAppMessages.smartLinkUpdate,
      data: { smartLink },
    });
  }, [childWindow, sendMessageToPreview, smartLink]);
  useEffect(() => {
    update();
  }, [update]);

  return update;
};
