import React from 'react';
import classnames from 'classnames';
import { Container } from 'reactstrap';
import { TypedMemo } from '../../common/types';
import { SMLContainerProps } from './props';

const SMLContainerComponent: React.FC<React.PropsWithChildren<SMLContainerProps>> = ({
  children,
  header,
  lightBG = false,
}) => {
  return (
    <Container
      className={classnames('sml-container d-flex flex-column p-0 mt-4 position-relative', {
        'sml-container-light': lightBG,
      })}
    >
      {header !== null && <div className="px-4 px-sm-5 sml-container-header shadow">{header}</div>}
      <div
        className={classnames('flex-grow-1 px-4 px-sm-5', {
          'py-4 py-sm-5': header === null,
          'py-4': header !== null,
        })}
      >
        {children}
      </div>
    </Container>
  );
};

export const SMLContainer = TypedMemo(SMLContainerComponent);
