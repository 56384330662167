import { AmazonMusicPlaylist } from './AmazonMusicPlaylist';
import { LikedPlaylistsResponse } from './types';
import { CollectionType } from '../../../generics/models/Collection';

export class AmazonMusicLikedPlaylistsResponse {
  private readonly data;

  private readonly userId;

  constructor(data: LikedPlaylistsResponse | undefined, userId: string | undefined) {
    this.data = data;
    this.userId = userId;
  }

  get playlists(): AmazonMusicPlaylist[] {
    return (
      this.data?.data?.user?.followedPlaylists?.edges
        ?.map((edge) => AmazonMusicPlaylist.fromData(edge?.node, this.userId, CollectionType.LIKED_PLAYLIST))
        .filter((playlist): playlist is AmazonMusicPlaylist => !!playlist) ?? []
    );
  }

  get pageInfoToken() {
    return this.data?.data?.user?.followedPlaylists?.pageInfo?.token;
  }

  get hasNextPage() {
    return this.data?.data?.user?.followedPlaylists?.pageInfo?.hasNextPage;
  }

  get userID() {
    return this.data?.data?.user?.id;
  }
}
