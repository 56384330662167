export async function wait(ms: number, onTimeoutSet?: (clearTimeout: () => void) => void): Promise<void> {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      resolve();
    }, ms);
    if (onTimeoutSet) {
      onTimeoutSet(() => {
        clearTimeout(timeoutId);
        reject();
      });
    }
  });
}

export async function waitInTest(ms: number, isFixtureLoaded: boolean | undefined) {
  if (isFixtureLoaded === true) {
    return;
  }
  await wait(ms);
}
