import React, { useCallback } from 'react';
import { config } from 'fym-common/src/config/config';
import { TypedMemo } from '../../../common/types';
import { FormThemeSelectionPreviewRenderer } from './FormThemeSelectionPreviewRenderer';

interface FormThemeSelectionPreviewProps {
  onClick: (value: string) => void;
  checked: boolean;
  option: string;
  disabled?: boolean;
}

const FormThemeSelectionPreviewComponent: React.FC<FormThemeSelectionPreviewProps> = ({
  onClick,
  checked,
  option,
  disabled,
}) => {
  const handleClick = useCallback(() => {
    onClick(option);
  }, [onClick, option]);

  return (
    <FormThemeSelectionPreviewRenderer
      onClick={handleClick}
      option={option}
      checked={checked}
      disabled={disabled}
      previewURL={config.themePreviewURL(option, false)}
    />
  );
};

export const FormThemeSelectionPreview = TypedMemo(FormThemeSelectionPreviewComponent);
