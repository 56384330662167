import { NapsterCollectionTrack } from './NapsterCollectionTrack';

export class NapsterTracksResponse {
  private readonly data;

  constructor(data: Record<string, any> | null) {
    this.data = data;
  }

  get tracks(): NapsterCollectionTrack[] {
    if (!this.data?.tracks) {
      return [];
    }
    return this.data.tracks
      .map((item: any) => NapsterCollectionTrack.fromData(item))
      .filter((track: NapsterCollectionTrack | null) => !!track);
  }

  get totalTracks(): number | undefined {
    return this.data?.meta?.totalCount;
  }
}
