import { JioSaavnCollectionTrack } from './JioSaavnCollectionTrack';
import { JioSaavnPlaylist } from './JioSaavnPlaylist';
import { tryParseInt } from '../../../utils/tryParseInt';

export class JioSaavnPlaylistWithTracksResponse {
  private readonly data: any;

  private readonly userId: string;

  constructor(data: any, userId: string) {
    this.data = data;
    this.userId = userId;
  }

  get playlist(): JioSaavnPlaylist | null {
    return JioSaavnPlaylist.fromData(this.data, this.userId);
  }

  get tracks(): JioSaavnCollectionTrack[] {
    if (!this.data?.list) {
      return [];
    }

    return this.data.list
      .map((item: any) => JioSaavnCollectionTrack.fromData(item))
      .filter((track: JioSaavnCollectionTrack | null) => !!track);
  }

  get tracksTotal(): number {
    return tryParseInt(this.data.list_count) ?? 0;
  }
}
