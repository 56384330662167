import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useAlertInfo } from '../../redux/slices/alerts/hooks';
import { AlertProps } from '../../redux/slices/alerts/state';
import { removeAlert } from '../../redux/slices/alerts/slice';
import { AlertRenderer } from './AlertRenderer';

export const Alerts: React.FC = () => {
  const { alerts } = useAlertInfo();
  const dispatch = useDispatch();

  const deleteAlert = useCallback(
    (alert: AlertProps) => {
      dispatch(removeAlert(alert));
    },
    [dispatch]
  );

  return (
    <>
      {alerts.map((alert) => (
        <AlertRenderer key={alert.id} alert={alert} deleteAlert={deleteAlert} />
      ))}
    </>
  );
};
