import { defineMessages } from 'react-intl';
import { CustomUpdaterMessageCallback } from '../../../generics/GenericImporter';

const {
  fetchingHistoryMessage,
  fetchingTracksMessage,
  tracksCountMessage,
  tracksCountAllMessage,
  fetchingArtistInformationMessage,
  artistCountAllMessage,
  fetchingAlbumsMessage,
  albumsCountAllMessage,
  generatingStatsMessage,
} = defineMessages({
  fetchingHistoryMessage: {
    id: 'app.updater.fetching-history',
    defaultMessage: 'Fetching your listening history',
  },
  fetchingTracksMessage: {
    id: 'app.updater.fetching-tracks',
    defaultMessage: 'Fetching tracks information',
  },
  tracksCountMessage: {
    id: 'app.updater.tracks-count',
    defaultMessage: '{count} played tracks',
  },
  tracksCountAllMessage: {
    id: 'app.updater.tracks-count-all',
    defaultMessage: '{count}',
  },
  fetchingArtistInformationMessage: {
    id: 'app.updater.fetching-artists',
    defaultMessage: 'Fetching artists information',
  },
  artistCountAllMessage: {
    id: 'app.updater.artists-count-all',
    defaultMessage: '{count} / {all} artists',
  },
  fetchingAlbumsMessage: {
    id: 'app.updater.fetching-albums',
    defaultMessage: 'Fetching albums information',
  },
  albumsCountAllMessage: {
    id: 'app.updater.albums-count-all',
    defaultMessage: '{count} / {all} albums',
  },
  generatingStatsMessage: {
    id: 'app.updater.generating-stats',
    defaultMessage: 'Generating stats',
  },
});

interface Props {
  updater?: CustomUpdaterMessageCallback;
  messageType: 'recently' | 'tracks' | 'artists' | 'albums' | 'generate';
}

export function getStatsMessageUpdater({
  updater,
  messageType,
}: {
  updater: Props['updater'];
  messageType: 'recently' | 'tracks';
}): ((count: number) => void) | undefined;
export function getStatsMessageUpdater({
  updater,
  messageType,
}: {
  updater: Props['updater'];
  messageType: 'artists' | 'albums';
}): ((count: number, all: number) => void) | undefined;
export function getStatsMessageUpdater({
  updater,
  messageType,
}: {
  updater: Props['updater'];
  messageType: 'generate';
}): (() => void) | undefined;
export function getStatsMessageUpdater({
  updater,
  messageType,
}: Props): ((count: number, all?: number) => void) | undefined {
  if (!updater) {
    return undefined;
  }
  switch (messageType) {
    case 'recently':
      return (count: number) => {
        updater({
          title: fetchingHistoryMessage,
          subTitle: {
            ...tracksCountMessage,
            values: { count: `${count}` },
          },
        });
      };
    case 'tracks':
      return (count: number) => {
        updater({
          title: fetchingTracksMessage,
          subTitle: {
            ...tracksCountAllMessage,
            values: { count: `${count}` },
          },
        });
      };
    case 'artists':
      return (count: number, all?: number) => {
        updater({
          title: fetchingArtistInformationMessage,
          subTitle: {
            ...artistCountAllMessage,
            values: { count: `${count}`, all: `${all ?? ''}` },
          },
        });
      };
    case 'albums':
      return (count: number, all?: number) => {
        updater({
          title: fetchingAlbumsMessage,
          subTitle: {
            ...albumsCountAllMessage,
            values: { count: `${count}`, all: `${all ?? ''}` },
          },
        });
      };
    case 'generate':
      return () => {
        updater({ title: generatingStatsMessage });
      };
    default:
      return undefined;
  }
}
