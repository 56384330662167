import React, { useCallback, useState } from 'react';
import { tryParseInt } from 'fym-common/src/utils/tryParseInt';
import { TypedMemo } from '../../../common/types';
import { SMLRoutes } from '../../../../routing/sml/SMLRoutes';
import { useSMLRouter } from '../../../../routing/sml/hooks';
import { useQueryParam } from '../../../../utils/useQueryParam';
import { ReleaseLinkData } from './types';
import { ReleaseLinkWizardViewRenderer } from './ReleaseLinkWizardViewRenderer';
import { useCreateWizardReleaseLinkMutation } from '../../../../redux/api/fymAPI';
import { isSuccessfulResult } from '../../../../redux/api/utils/isSuccessfulResult';
import { ReleaseLinkWizardViewProps } from './props';
import { SMLAnalytics } from '../../../analytics/sml/analytics';
import { ReleaseLinkWizardFormView } from './ReleaseLinkWizardFormView';

const ReleaseLinkWizardViewComponent: React.FC<ReleaseLinkWizardViewProps> = ({ route }) => {
  const router = useSMLRouter();
  const accountIdString = useQueryParam('accountId', route);
  const accountId = tryParseInt(accountIdString);

  if (accountId === undefined) {
    throw new Error(`Missing accountId:[${accountId}] in ReleaseLinkWizardView`);
  }

  const [mainReleaseLink, setMainReleaseLink] = useState<ReleaseLinkData>();
  const [releaseLinks, setReleaseLinks] = useState<ReleaseLinkData[]>([]);

  const onResults = useCallback((releaseLink: ReleaseLinkData, links: ReleaseLinkData[]) => {
    setMainReleaseLink(releaseLink);
    setReleaseLinks(links);
  }, []);

  const [createWizardReleaseLink, { isLoading: isCreateMediaLoading, error: createMediaError }] =
    useCreateWizardReleaseLinkMutation();

  const onClose = useCallback(() => {
    router.goBack(SMLRoutes.releaseLink);
  }, [router]);

  const onDelete = useCallback((id: string) => {
    setReleaseLinks((prevItems) => prevItems.filter((item) => item.id !== id));
  }, []);

  const onCreate = useCallback((releaseLinkData: ReleaseLinkData) => {
    setReleaseLinks((prevItems) => {
      return [...prevItems, releaseLinkData];
    });
  }, []);

  const onContinue = useCallback(() => {
    if (!mainReleaseLink) {
      return;
    }

    const additionalUrls = releaseLinks.reduce((result, releaseLinkResult) => {
      if (releaseLinkResult.data.url) {
        result.push(releaseLinkResult.data.url);
      }
      return result;
    }, [] as string[]);

    const urls = mainReleaseLink.data.url ? [mainReleaseLink.data.url, ...additionalUrls] : additionalUrls;
    createWizardReleaseLink({
      accountId,
      urls,
      title: mainReleaseLink.data.track ?? undefined,
      subtitle: mainReleaseLink.data.album ?? undefined,
      extra: mainReleaseLink.data.artist ?? undefined,
      imageURL: mainReleaseLink.data.imageUrl ?? undefined,
    })
      .then((singleTrackResult) => {
        if (!isSuccessfulResult(singleTrackResult)) {
          return;
        }
        router.popToRoot();
        router.push(SMLRoutes.details, { accountId, smartLinkId: singleTrackResult.data.smartLink.id });
        SMLAnalytics.trackMediaSmartLinkCreated().catch(console.error);
      })
      .catch(console.error);
  }, [accountId, createWizardReleaseLink, router, releaseLinks, mainReleaseLink]);

  if (!mainReleaseLink) {
    return <ReleaseLinkWizardFormView onResults={onResults} />;
  }

  return (
    <ReleaseLinkWizardViewRenderer
      mainItem={mainReleaseLink}
      error={createMediaError}
      isLoading={isCreateMediaLoading}
      releaseLinks={releaseLinks}
      onDelete={onDelete}
      onClose={onClose}
      onContinue={onContinue}
      onCreate={onCreate}
    />
  );
};

export const ReleaseLinkWizardView = TypedMemo(ReleaseLinkWizardViewComponent);
