import { SearchQueryProperties } from '../../generics/types';

export function convertQueryPropsToString(queryProps: SearchQueryProperties) {
  return [queryProps.track, queryProps.artist, queryProps.album].filter((prop) => !!prop).join(' ');
}

export function convertQueryPropsWithoutTrackToString(queryProps: SearchQueryProperties) {
  return [queryProps.artist, queryProps.album].filter((prop) => !!prop).join(' ');
}

export function convertQueryPropsToStringWithoutAlbum(queryProps: SearchQueryProperties) {
  /**
   * We need to ignore album in Tidal search, because of worse search results
   * i.e look for "Fool In the Rain Led Zeppelin In Through the out Door"
   * [
   *  title: Fool In the Rain
   *  artist: Led Zeppelin
   *  album: In Through the out Door
   * ]
   */
  return [queryProps.track, queryProps.artist].filter((prop) => !!prop).join(' ');
}
