export enum LicenseType {
  Basic,
  Premium,
  SmartLinksInfluencer,
  SmartLinksArtist,
  LostBasket,
  SmartLinksRookie,
  Trial,
}

export enum LicenseModelTransactionVendor {
  Unknown,
  Paddle,
  Apple,
  Google,
  OldLicense,
  ComputerBild,
  KomputerSwiat,
  StackCommerce,
  BT,
  InvitedBy,
  Amazon,
  Trial,
  LostBasket,
  Tidal,
  Resso,
  AppleSmartLinks,
  Setapp,
}

export enum LicenseModelProductType {
  Unknown,
  OldSingleApp,
  OldBundle,
  SingleApp,
  SubscriptionYearly,
  SubscriptionQuarterly,
  SubscriptionLifeTime,
  SubscriptionFromReferral,
  SmartLinksInfluencer,
  SmartLinksArtist,
  SmartLinksTrial,
  SmartLinksRookie,
}

export const BasicLicenseModelProductTypes = [
  LicenseModelProductType.OldSingleApp,
  LicenseModelProductType.OldBundle,
  LicenseModelProductType.SingleApp,
];

export const PremiumLicenseModelProductTypes = [
  LicenseModelProductType.SubscriptionYearly,
  LicenseModelProductType.SubscriptionQuarterly,
  LicenseModelProductType.SubscriptionFromReferral,
  LicenseModelProductType.SubscriptionLifeTime,
];
export enum LicenseRequestType {
  WEB,
  IAP,
}

export const nonChargingTransactionVendors = [
  LicenseModelTransactionVendor.ComputerBild,
  LicenseModelTransactionVendor.KomputerSwiat,
  LicenseModelTransactionVendor.StackCommerce,
  LicenseModelTransactionVendor.BT,
  LicenseModelTransactionVendor.InvitedBy,
  LicenseModelTransactionVendor.OldLicense,
  LicenseModelTransactionVendor.Trial,
  LicenseModelTransactionVendor.LostBasket,
  LicenseModelTransactionVendor.Tidal,
  LicenseModelTransactionVendor.Resso,
];
export const LOST_BASKET_SONGS_LIMIT = 1000;
export const BASE_SONGS_LIMIT = 500;
