import { ImporterID } from 'fym-common/src/importers/types';
import { ImporterAuthenticationModel } from '../../../realm/models/ImporterAuthenticationModel';
import { CollectionModel } from '../../../realm/models/CollectionModel';
import { CollectionPickerTabType } from '../../../modules/collectionsPicker/views/tabs/props';

export type StatsStateType = {
  selectedImporterId?: ImporterID;
  authModelUniqueIdString?: ReturnType<ImporterAuthenticationModel['_id']['toHexString']>;
  selectedCollectionIds: ReturnType<CollectionModel['_id']['toHexString']>[];
  selectedCollectionTabType: CollectionPickerTabType | null;
};

export const StatsState: StatsStateType = {
  selectedImporterId: undefined,
  authModelUniqueIdString: undefined,
  selectedCollectionIds: [],
  selectedCollectionTabType: null,
};
