import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SmartLinkWidgetType } from 'fym-common/src/db/enums';
import { SerializedPlayerWidget } from 'fym-common/src/db/serializersTypes';
import { TypedMemo } from '../../../../../common/types';
import { WidgetEditionProps } from '../../props';
import { PlayerWidgetForm, PlayerWidgetFormProps } from '../forms/PlayerWidgetForm';

const PlayerWidgetVersion = 2;

const PlayerWidgetEditionViewComponent: React.FC<WidgetEditionProps<SerializedPlayerWidget>> = ({
  widget,
  saveWidget,
  deleteWidget,
  isLoading,
  error,
  smartLinkId,
  accountId,
}) => {
  const intl = useIntl();
  const onSubmit: PlayerWidgetFormProps['onSubmit'] = useCallback(
    async ({ url }) => {
      await saveWidget({ url, version: PlayerWidgetVersion }, SmartLinkWidgetType.player);
    },
    [saveWidget]
  );

  return (
    <PlayerWidgetForm
      title={intl.formatMessage({ id: 'app.smart_links.widgets.player.title', defaultMessage: 'Player widget' })}
      onSubmit={onSubmit}
      widget={widget}
      onDelete={deleteWidget}
      isLoading={isLoading}
      error={error}
      smartLinkId={smartLinkId}
      accountId={accountId}
    />
  );
};

export const PlayerWidgetEditionView = TypedMemo(PlayerWidgetEditionViewComponent);
