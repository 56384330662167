import { BoomplayTrackRawResponse } from './types';
import { BoomplayAlbum } from './BoomplayAlbum';
import { BoomplayArtist } from './BoomplayArtist';
import { artistsToString } from '../../../utils/artistsConverter';
import { GenericMatchedItem, MatchedItemModelData } from '../../../generics/models/GenericMatchedItem';
import { convertBoomplayTrackData } from '../utils/convertBoomplayTrackData';
import { parseBoomplayDuration } from '../utils/parseBoomplayDuration';

export class BoomplayMatchedTrack extends GenericMatchedItem {
  constructor(
    data: MatchedItemModelData,
    public albumExtended: BoomplayAlbum | null = null,
    public artists: BoomplayArtist[] | null = null
  ) {
    super(data);
  }

  static fromData(data: BoomplayTrackRawResponse | undefined): BoomplayMatchedTrack | null {
    const convertedData = convertBoomplayTrackData(data);
    if (!convertedData) {
      return null;
    }
    const { track, artists, album } = convertedData;
    return new BoomplayMatchedTrack(track, album, artists ?? null);
  }

  static fromShareUrlData(data: any) {
    const trackUrlParts = data?.['@id']?.split('/');
    if (!data || !trackUrlParts) {
      return null;
    }
    const rawId = trackUrlParts[trackUrlParts.length - 1];
    const album = BoomplayAlbum.fromShareUrlData(data.inAlbum);
    const artists: BoomplayArtist[] | undefined = data.byArtist
      ?.map((artistData: any) => BoomplayArtist.fromShareUrlData(artistData))
      .filter((artist: BoomplayArtist | null): artist is BoomplayArtist => !!artist);
    const artistsNames = artists?.map((artist) => artist.name);
    const durationString = data.duration?.replace(/\D+/g, ' ').trim().split(' ').join(':');
    const duration = durationString ? parseBoomplayDuration(`00:${durationString}`) : undefined;

    return new BoomplayMatchedTrack(
      {
        rawId,
        name: data.name,
        sourceURL: data.url,
        album: album?.name,
        imageUrl: data.image,
        artist: artistsToString(artistsNames),
        duration,
      },
      album,
      artists
    );
  }
}
