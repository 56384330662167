import React, { useCallback, FC, PropsWithChildren, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal } from 'reactstrap';
import { TypedMemo } from '../../modules/common/types';
import { SMLRouteNames, SMLRoutes } from './SMLRoutes';
import { useSMLRouter } from './hooks';

interface SMLFormModalRouterProps {
  path: SMLRouteNames;
}

const SMLFormModalRouterComponent: FC<PropsWithChildren<SMLFormModalRouterProps>> = ({ children, path }) => {
  const router = useSMLRouter();
  const { pathname } = useLocation();
  const matchToPath = useMemo(() => {
    const rootPath = `/${path}/`;
    const hasNestedRoot = pathname.length > rootPath.length;
    return pathname.startsWith(rootPath) && hasNestedRoot;
  }, [path, pathname]);

  const toggle = useCallback(() => {
    router.goBack(SMLRoutes.home);
  }, [router]);

  return (
    <Modal
      isOpen={matchToPath}
      toggle={toggle}
      centered
      scrollable
      contentClassName="sml-form-modal-container"
      size="lg"
    >
      {children}
    </Modal>
  );
};

export const SMLFormModalRouter = TypedMemo(SMLFormModalRouterComponent);
