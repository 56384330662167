import React from 'react';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { TypedMemo } from '../../../common/types';
import { ReleaseLinkItemRendererProps } from './props';
import { ReactComponent as CoverBoardPlaceholder } from '../../../../assets/images/cover-board-placeholder.svg';
import { SMLButton } from '../../../common/views/SMLButton/SMLButton';

const ReleaseLinkItemRendererComponent: React.FC<ReleaseLinkItemRendererProps> = ({
  title,
  text1,
  text2,
  imageURL,
  ImporterLogo,
  onDelete,
}) => {
  return (
    <div className="border rounded mt-2 p-2 d-flex align-items-center">
      <SMLButton color="link" className="text-body" leftIcon={faTimesCircle} onClick={onDelete} />

      <div className="me-2">
        {imageURL && <img className="img-fluid" src={imageURL} alt={title} width={32} height={32} />}
        {!imageURL && <CoverBoardPlaceholder width={32} height={32} />}
      </div>
      <div className="mx-2 flex-grow-1">
        <p className="text-2-line mb-0 fw-bold">{title}</p>
        <p className="text-2-line mb-0 opacity-56">{text1}</p>
      </div>
      <div className="mx-2">
        <p className="text-2-line mb-0 opacity-56">{text2}</p>
      </div>
      {ImporterLogo && (
        <div className="text-end ms-2">
          <ImporterLogo height="32px" width={100} />
        </div>
      )}
    </div>
  );
};

export const ReleaseLinkItemRenderer = TypedMemo(ReleaseLinkItemRendererComponent);
