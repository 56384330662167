import React, { useCallback, useEffect } from 'react';
import { TypedMemo } from '../../common/types';
import { SMLLogInViewRenderer } from './SMLLogInViewRenderer';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { SMLAnalytics } from '../../analytics/sml/analytics';

const SMLLogInViewComponent: React.FC = () => {
  const router = useSMLRouter();

  const goToSignUpView = useCallback(() => {
    router.push(SMLRoutes.signup);
  }, [router]);

  const goToRemindPasswordView = useCallback(() => {
    router.push(SMLRoutes.remindPassword);
  }, [router]);

  useEffect(() => {
    SMLAnalytics.trackLogInStarted().catch(console.error);
  });
  return <SMLLogInViewRenderer goToSignUpView={goToSignUpView} goToRemindPasswordView={goToRemindPasswordView} />;
};

export const SMLLogInView = TypedMemo(SMLLogInViewComponent);
