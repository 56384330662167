import { validateISRC } from '../../utils/validateISRC';

const qobuzPublicResourceUrl = 'https://open.qobuz.com';

export function createQobuzAlbumUrl(albumId: string) {
  return `${qobuzPublicResourceUrl}/album/${albumId}`;
}

export function createQobuzPlaylistUrl(playlistId: string) {
  return `${qobuzPublicResourceUrl}/playlist/${playlistId}`;
}

export function createQobuzTrackUrl(trackId: string) {
  return `${qobuzPublicResourceUrl}/track/${trackId}`;
}

export function convertQobuzTrackData(data: any) {
  if (!data?.id || !data.title) {
    return null;
  }
  return {
    rawId: `${data.id}`,
    name: data.title,
    album: data.album?.title,
    artist: data.performer?.name ?? data.artist?.name,
    isrc: validateISRC(data?.isrc),
    additionalData: {
      playlistTrackId: data.playlist_track_id !== undefined ? `${data.playlist_track_id}` : undefined,
    },
    imageUrl: data.album?.image?.large,
    duration: data.duration ? data.duration * 1000 : undefined,
    sourceURL: createQobuzTrackUrl(data.id),
  };
}
