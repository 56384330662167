import qs from 'qs';
import { defaultRetryOptions, fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { FetchError } from '../../generics/errors/FetchError';
import { YandexPlaylistsResponse } from './models/YandexPlaylistsResponse';
import { YandexAlbumsResponse } from './models/YandexAlbumsResponse';
import { YandexAlbumWithTracksResponse } from './models/YandexAlbumWithTracksResponse';
import { YandexAlbum } from './models/YandexAlbum';
import { YandexPlaylist } from './models/YandexPlaylist';
import { YandexSearchResponse } from './models/YandexSearchResponse';
import { YandexAuthenticationData } from './YandexAuthenticationData';
import { YandexUser } from './models/YandexUser';
import { YandexPlaylistWithTracksResponse } from './models/YandexPlaylistWithTracksResponse';
import { YandexAccountStatus } from './models/YandexAccountStatus';
import { CollectionType } from '../../generics/models/Collection';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { ImporterID } from '../types';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';

export class YandexAPI {
  public static LOGIN_URL =
    'https://passport.yandex.com/am?app_platform=ios&app_id=ru.yandex.mobile.music&app_version=93023&am_version_name=6.6.1&device_id=&scheme=yandexmusic&theme=dark&lang=en&locale=en&source&mode=relogin&reg_type=neophonish&device_name=iPhone%20Mike&auth_type=lite,social,yandex&siwa=true&uuid=&uid=0';

  private static API_BASE_URL = 'https://api.music.yandex.net/';

  private static AUTH_BASE_URL = 'https://mobileproxy.passport.yandex.net/';

  private static AUTH_USER_AGENT = 'com.yandex.mobile.auth.sdk/6.6.1.93503 (Apple iPhone13,4; iOS 15.1.1)';

  private static AUTH_CLIENT_ID = '23cabbbdc6cd418abb4b39c32c41195d';

  private static AUTH_CLIENT_SECRET = '53bc75238f0c4d08a118e51fe9203300';

  private static AUTH_X_TOKEN_CLIENT_ID = 'c0ebe342af7d48fbbbfcf2d2eedb8f9e';

  private static AUTH_X_TOKEN_CLIENT_SECRET = 'ad0a908f0aa341a182a37ecd75bc319e';

  private readonly oAuthToken: string;

  public readonly userId: number;

  constructor(oAuthToken: string, userId: number) {
    this.oAuthToken = oAuthToken;
    this.userId = userId;
  }

  private get requestHeaders(): Record<string, string> {
    return {
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      Authorization: `OAuth ${this.oAuthToken}`,
    };
  }

  async fetch(
    url: string,
    options: any,
    retryOptions: RetryOptions = defaultRetryOptions
  ): Promise<{ [key: string]: any }> {
    const response = await fetchRetry(url, options, retryOptions);

    const text = await response.text();
    if (response.status === 401) {
      throw new NotAuthenticatedError({ authId: `${this.userId}`, importerId: ImporterID.Yandex }, text);
    }

    let data: any;
    try {
      data = JSON.parse(text);
    } catch (e) {
      throw new Error(`Could not parse JSON response text for ${url}: ${text}`);
    }

    if (data.error) {
      if (data.error.name === 'session-expired') {
        throw new NotAuthenticatedError({ authId: `${this.userId}`, importerId: ImporterID.Yandex }, text);
      }
      throw new FetchError(-1, JSON.stringify(data.error));
    }
    return data;
  }

  async search(query: string, searchType: 'track' | 'album'): Promise<YandexSearchResponse> {
    if (!query) {
      return new YandexSearchResponse(null);
    }
    const url = `${YandexAPI.API_BASE_URL}search?${qs.stringify({
      text: query,
      nocorrect: false,
      type: searchType,
      page: 0,
      'playlist-in-best': 1,
    })}`;

    const data = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    return new YandexSearchResponse(data?.result);
  }

  async fetchMe(): Promise<YandexUser> {
    const url = `${YandexAPI.API_BASE_URL}users/${this.userId}`;

    const data = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    return new YandexUser(data.result);
  }

  async getPlaylist(collection: GenericCollection): Promise<YandexPlaylist | null> {
    const [uid, kind] = collection.rawId.split(':');
    const url = `${YandexAPI.API_BASE_URL}users/${uid}/playlists/${kind}`;

    const data = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    return YandexPlaylist.fromData(data.result, collection.type);
  }

  async loadAllPlaylistItems(collection: GenericCollection): Promise<YandexPlaylistWithTracksResponse> {
    const [uid, kind] = collection.rawId.split(':');
    const url = `${YandexAPI.API_BASE_URL}users/${uid}/playlists/${kind}`;

    const data = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    return new YandexPlaylistWithTracksResponse(data, collection.type);
  }

  async loadAllPlaylists(): Promise<YandexPlaylist[]> {
    const url = `${YandexAPI.API_BASE_URL}users/${this.userId}/playlists/list`;
    const data = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });
    return new YandexPlaylistsResponse(data).playlists;
  }

  async loadAllLikedPlaylists(): Promise<YandexPlaylist[]> {
    const url = `${YandexAPI.API_BASE_URL}users/${this.userId}/likes/playlists`;
    const data = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });
    return new YandexPlaylistsResponse(data).likedPlaylists;
  }

  async createPlaylist(name: string): Promise<YandexPlaylist | null> {
    const data = await this.fetch(`${YandexAPI.API_BASE_URL}users/${this.userId}/playlists/create`, {
      method: 'POST',
      headers: this.requestHeaders,
      body: qs.stringify({
        title: name,
        visibility: 'private',
      }),
    });
    return YandexPlaylist.fromData(data.result, CollectionType.PLAYLIST);
  }

  async changeVisibility(collection: GenericCollection, isPublic: boolean): Promise<YandexPlaylist | null> {
    const [uid, kind] = collection.rawId.split(':');
    const data = await this.fetch(`${YandexAPI.API_BASE_URL}users/${uid}/playlists/${kind}/visibility`, {
      method: 'POST',
      headers: this.requestHeaders,
      body: qs.stringify({
        value: isPublic ? 'public' : 'private',
      }),
    });
    return YandexPlaylist.fromData(data.result, collection.type);
  }

  async addTracksToPlaylist(
    collection: GenericCollection,
    ids: { rawId: string; albumId: string | undefined }[]
  ): Promise<void> {
    const [uid, kind] = collection.rawId.split(':');
    const playlist = await this.getPlaylist(collection);
    const url = `${YandexAPI.API_BASE_URL}users/${uid}/playlists/${kind}/change-relative`;
    if (!playlist) {
      throw new CollectionDoesNotExistsError();
    }

    await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders,
      body: qs.stringify({
        diff: JSON.stringify([
          {
            op: 'insert',
            at: playlist.itemCount,
            tracks: ids.map(({ rawId, albumId }) => {
              if (albumId === undefined && rawId.includes(':')) {
                // Previousy tracks in Yandex contained trackId and albumId under rawId property, now they are separate fields
                const [id, albumIdFromRawId] = rawId.split(':');
                return { id, albumId: albumIdFromRawId };
              }
              return { id: rawId, albumId };
            }),
          },
        ]),
        revision: playlist.revision,
      }),
    });
  }

  async removeTracksFromPlaylist(playlistId: string, indexesToRemove: number[], revision: number): Promise<void> {
    if (indexesToRemove.length === 0) {
      return;
    }
    const [uid, kind] = playlistId.split(':');
    const url = `${YandexAPI.API_BASE_URL}users/${uid}/playlists/${kind}/change-relative`;

    await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders,
      body: qs.stringify({
        diff: JSON.stringify(
          indexesToRemove.map((indexToRemove, index) => ({
            op: 'delete',
            from: indexToRemove - index,
            to: indexToRemove + 1 - index,
          }))
        ),
        revision,
      }),
    });
  }

  async loadAllAlbums(): Promise<YandexAlbum[]> {
    const url = `${YandexAPI.API_BASE_URL}users/${this.userId}/likes/albums?${qs.stringify({
      rich: true,
    })}`;

    const data = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });
    return new YandexAlbumsResponse(data).albums;
  }

  async loadAlbum(albumId: string): Promise<YandexAlbumWithTracksResponse> {
    const url = `${YandexAPI.API_BASE_URL}albums/${albumId}/with-tracks`;
    const data = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    return new YandexAlbumWithTracksResponse(data.result);
  }

  async addAlbumToLibrary(albumId: string) {
    const url = `${YandexAPI.API_BASE_URL}users/${this.userId}/likes/albums/add-multiple`;

    await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders,
      body: qs.stringify({
        albumIds: albumId,
      }),
    });
  }

  getAuthData(user: YandexUser, expiresIn: number): YandexAuthenticationData {
    const userId = `${user.id}`;
    return {
      authId: userId,
      userUUID: null,
      title: user.fullName || user.displayName || userId,
      subTitle: user.displayName ?? '',
      imageUrl: null,
      expiresAt: new Date(Date.now() + expiresIn),
      additionalData: {
        oAuthToken: this.oAuthToken,
      },
    };
  }

  static async exchangeXTokenForAccessToken(xToken: string) {
    const response = await fetchRetry(
      `${YandexAPI.AUTH_BASE_URL}1/token/?${qs.stringify({
        app_id: 'ru.yandex.mobile.music',
        app_version_name: '5.18',
        app_platform: 'iPad',
      })}`,
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
          'User-Agent': YandexAPI.AUTH_USER_AGENT,
        },
        body: qs.stringify({
          access_token: xToken,
          grant_type: 'x-token',
          client_id: YandexAPI.AUTH_CLIENT_ID,
          client_secret: YandexAPI.AUTH_CLIENT_SECRET,
        }),
        method: 'POST',
      }
    );
    if (response.status !== 200) {
      throw new Error(`Could not exchange xToken [${response.status}]: ${await response.text()}`);
    }
    const data: {
      uid: number;
      access_token: string;
      expires_in: number;
    } = await response.json();

    return { accessToken: data.access_token, expiresIn: data.expires_in, userId: data.uid };
  }

  static async getXTokenFromSession(cookies: Record<string, string>) {
    const formattedCookies = Object.keys(cookies)
      .map((key) => {
        return `${key}=${cookies[key]}`;
      })
      .join('; ');
    const response = await fetchRetry(
      `${YandexAPI.AUTH_BASE_URL}1/bundle/oauth/token_by_sessionid?${qs.stringify({
        app_id: 'ru.yandex.mobile.music',
        app_version_name: '5.58',
        am_version_name: '6.6.1',
        app_platform: 'iPad',
        manufacturer: 'Apple',
      })}`,
      {
        headers: {
          Host: 'mobileproxy.passport.yandex.net',
          'Ya-Client-Host': 'yandex.com',
          'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
          'User-Agent': YandexAPI.AUTH_USER_AGENT,
          'Ya-Client-Cookie': formattedCookies,
          Cookie: formattedCookies,
        },
        body: qs.stringify({
          cookies: formattedCookies,
          grant_type: 'sessionid',
          host: 'yandex.com',
          client_id: YandexAPI.AUTH_X_TOKEN_CLIENT_ID,
          client_secret: YandexAPI.AUTH_X_TOKEN_CLIENT_SECRET,
        }),
        method: 'POST',
      }
    );
    if (response.status !== 200) {
      throw new Error(`Could not exchange cookies for xToken [${response.status}]: ${await response.text()}`);
    }
    const data: {
      status: 'error' | 'ok';
      access_token: string;
      expires_in: number;
    } = await response.json();
    if (data.status === 'error') {
      throw new Error(JSON.stringify(data));
    }
    return data.access_token;
  }

  static async accountStatus(): Promise<YandexAccountStatus> {
    const url = `${YandexAPI.API_BASE_URL}account/status`;

    const response = await fetchRetry(url, {
      method: 'GET',
    });
    const data = await response.json();

    if (data.error) {
      throw new FetchError(-1, JSON.stringify(data.error));
    }

    return new YandexAccountStatus(data);
  }
}
