import React, { PropsWithChildren } from 'react';
import { Col, Row } from 'reactstrap';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../../../common/types';
import { ReleaseLinkWizardFormViewRendererProps } from './props';
import { SMLFormContainer } from '../../SMLFormContainer/SMLFormContainer';
import { SMLRoutes } from '../../../../routing/sml/SMLRoutes';
import ImageExampleMrEaziPatek from '../../../../assets/images/smartLinks/releaselink-example-form.png';
import { FormHelp } from '../../../forms/components/FormHelp/FormHelp';

const ReleaseLinkWizardFormViewRendererComponent: React.FC<
  PropsWithChildren<ReleaseLinkWizardFormViewRendererProps>
> = ({ children, onSubmit, error, isLoading }) => {
  const intl = useIntl();

  return (
    <SMLFormContainer
      backRoute={SMLRoutes.home}
      title={intl.formatMessage({
        id: 'app.smart_links.release_smart_link.form_title',
        defaultMessage: 'Create Release Link',
      })}
      error={error}
      isLoading={isLoading}
      submitButtonColor="secondary"
      submitButtonTitle={intl.formatMessage({
        id: 'app.forms.single_track.buttons.next',
        defaultMessage: 'Next',
      })}
      centerFooterContent={
        <FormHelp>
          {intl.formatMessage(
            {
              id: 'app.forms.single_track.steps.info',
              defaultMessage: '{step} Upload link',
            },
            {
              step: '1/2',
            }
          )}
        </FormHelp>
      }
      onSubmit={onSubmit}
    >
      <Row>
        <Col md={8}>{children}</Col>
        <Col md={4} className="d-none d-md-flex">
          <img src={ImageExampleMrEaziPatek} className="img-fluid" alt="mr eazi example" />
        </Col>
      </Row>
    </SMLFormContainer>
  );
};

export const ReleaseLinkWizardFormViewRenderer = TypedMemo(ReleaseLinkWizardFormViewRendererComponent);
