import { BaseError } from './BaseError';

export class CouldNotCreateCollection extends BaseError {
  constructor(message?: string) {
    super({ message });
    // see: typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, CouldNotCreateCollection.prototype);
    this.name = CouldNotCreateCollection.name; // stack traces display correctly now
  }
}
