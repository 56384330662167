import { BaseError } from './BaseError';
import { InternalErrorCode } from '../../errors/internalErrorCodes';

type FYMAPIErrorProps = {
  statusCode: number;
  message?: string;
  internalCode?: InternalErrorCode;
};

export class FYMAPIError extends BaseError {
  public internalCode: InternalErrorCode | undefined;

  constructor({ message, statusCode, internalCode }: FYMAPIErrorProps) {
    super({ message, isOperational: statusCode < 500 });
    // see: typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, FYMAPIError.prototype);
    this.name = FYMAPIError.name; // stack traces display correctly now
    this.internalCode = internalCode;
  }
}
