import { JioSaavnMatchedTrack } from './JioSaavnMatchedTrack';

export class JioSaavnSearchResponse {
  private readonly data;

  constructor(data: Record<string, any> | null) {
    this.data = data;
  }

  get tracks(): JioSaavnMatchedTrack[] {
    if (!this.data?.results) {
      return [];
    }
    return this.data.results
      .map((item: any) => JioSaavnMatchedTrack.fromData(item))
      .filter((track: JioSaavnMatchedTrack | null) => !!track);
  }

  get firstTrack(): JioSaavnMatchedTrack | undefined {
    if (this.tracks.length === 0) {
      return undefined;
    }
    return this.tracks[0];
  }
}
