import { GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionAccess, CollectionType } from '../../../generics/models/Collection';
import { SoundCloudPlaylistRawResponse } from './responseTypes';
import { isFalsy } from '../../../utils/isFalsy';

function convertSharingToAccess(sharing: SoundCloudPlaylistRawResponse['sharing']) {
  switch (sharing) {
    case 'public':
      return CollectionAccess.public;
    case 'private':
      return CollectionAccess.private;
    case undefined:
    case null:
    default:
      return undefined;
  }
}

export class SoundCloudPlaylist extends GenericCollection {
  static fromData(
    data: SoundCloudPlaylistRawResponse | undefined,
    userId: string | undefined,
    collectionType?: CollectionType
  ): SoundCloudPlaylist | null {
    if (!data || isFalsy(data?.id) || !data.title) {
      return null;
    }
    let _collectionType: CollectionType;
    const userIdFromResponse = `${data.user_id}`;
    if (userId && userIdFromResponse) {
      _collectionType = userId === userIdFromResponse ? CollectionType.PLAYLIST : CollectionType.LIKED_PLAYLIST;
    } else {
      _collectionType = collectionType ?? CollectionType.LIKED_PLAYLIST;
    }
    return new SoundCloudPlaylist({
      type: _collectionType,
      rawId: `${data.id}`,
      name: data.title,
      itemCount: data.track_count,
      followers: data.likes_count,
      additionalData: { imageUrl: data.artwork_url ?? undefined, description: data.description ?? undefined },
      publicUrl: data.permalink_url,
      access: convertSharingToAccess(data.sharing),
    });
  }
}
