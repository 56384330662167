import { MusicService } from './types';
import { MusicAPIIntegrationID } from '../types';
import { ImporterID } from '../../importers/types';
import { LinkType } from '../../utils/recognizeLinkTypeByUrl';

export const amazonMusic: MusicService = {
  id: 'amazon-music',
  importerID: ImporterID.AmazonMusic,
  linkType: LinkType.amazonmusic,
  musicAPIID: MusicAPIIntegrationID.AmazonMusic,
  shortName: 'Amazon Music',
  name: 'Amazon Music',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: true,
  isSupported: true,
  color: { label: '#0077C1', background: '#56A6D8' },
  tracksPerPlaylistLimit: 2500,
};
