import qs from 'qs';
import chunk from 'lodash/chunk';
import { v4 as uuid4 } from 'uuid';
import { defaultRetryOptions, fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { FetchError } from '../../generics/errors/FetchError';
import { JioSaavnPlaylistsResponse } from './models/JioSaavnPlaylistsResponse';
import { JioSaavnPlaylist } from './models/JioSaavnPlaylist';
import { JioSaavnCollectionTrack } from './models/JioSaavnCollectionTrack';
import { JioSaavnSearchResponse } from './models/JioSaavnSearchResponse';
import { JioSaavnAuthenticationData } from './JioSaavnAuthenticationData';
import { JioSaavnUser } from './models/JioSaavnUser';
import { JioSaavnPlaylistWithTracksResponse } from './models/JioSaavnPlaylistWithTracksResponse';
import { ImporterID } from '../types';
import { Response } from '../../utils/fetch-types';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';

export class JioSaavnAPI {
  private static BASE_URL = 'https://www.jiosaavn.com/api.php';

  public static LOGIN_URL = 'https://www.jiosaavn.com/login';

  public static CTX = 'wap6dot0';

  private readonly cookies: Record<string, any>;

  public readonly userId: string;

  constructor(cookies: Record<string, any>, userId: string) {
    this.cookies = cookies;
    this.userId = userId;
  }

  private requestHeaders(isJson = false): { [key: string]: string } {
    return {
      Accept: '*/*; charset=utf-8',
      'User-Agent':
        'Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1',
      'Content-Type': !isJson ? 'application/x-www-form-urlencoded' : 'application/json',
      Cookie: Object.keys(this.cookies)
        .map((name) => {
          return `${name}=${this.cookies[name]}`;
        })
        .join('; '),
    };
  }

  private static qs(call: string, params: Record<string, any> = {}): string {
    return qs.stringify({
      __call: call,
      api_version: 4,
      _format: 'json',
      _marker: 0,
      ctx: JioSaavnAPI.CTX,
      ...params,
    });
  }

  async fetch(
    url: string,
    options: any,
    retryOptions: RetryOptions = defaultRetryOptions
  ): Promise<{ jsonData: Record<string, any>; response: Response }> {
    const response = await fetchRetry(url, options, retryOptions);

    if (response.status === 401 || response.status === 403) {
      const text = await response.text();
      throw new NotAuthenticatedError({ authId: `${this.userId}`, importerId: ImporterID.JioSaavn }, text);
    }

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to fetch "${url}" got wrong response[${response.status}]: ${text}`
      );
    }
    const data = await response.json();
    if (typeof data.error !== 'undefined' && data.error) {
      if (data.error.code === 'LOGIN_REQUIRED') {
        throw new NotAuthenticatedError({ authId: `${this.userId}`, importerId: ImporterID.JioSaavn }, data.msg);
      }
      if (data.error.code === 'INPUT_MISSING') {
        throw new FetchError(500, `${data.error.code}`);
      }
      throw new FetchError(500, `[${data.error.code}] ${data.error.msg}`);
    }
    return { jsonData: data, response };
  }

  async apiCall(call: string, params: Record<string, any> = {}) {
    const { jsonData } = await this.fetch(`${JioSaavnAPI.BASE_URL}?${JioSaavnAPI.qs(call, params)}`, {
      method: 'GET',
      headers: this.requestHeaders(),
    });
    return jsonData;
  }

  async fetchProfile(): Promise<JioSaavnUser> {
    return new JioSaavnUser(await this.apiCall('library.getAll'));
  }

  async searchSongs(query: string): Promise<JioSaavnSearchResponse> {
    if (!query) {
      return new JioSaavnSearchResponse(null);
    }
    const data = await this.apiCall('search.getResults', {
      n: 10,
      p: 1,
      q: query,
    });
    return new JioSaavnSearchResponse(data);
  }

  async loadPaginatedPlaylistsItems(
    playlistId: string,
    onBatch?: (collections: JioSaavnCollectionTrack[]) => Promise<void>
  ): Promise<JioSaavnPlaylist | null> {
    let tracksLength = 0;
    const loadPlaylistsItems = async (page: number): Promise<JioSaavnPlaylist | null> => {
      const trackResponse = await this.loadPlaylistItemPage(playlistId, page);
      if (onBatch !== undefined) {
        await onBatch(trackResponse.tracks);
      }
      tracksLength += trackResponse.tracks.length;
      if (tracksLength < trackResponse.tracksTotal && trackResponse.tracks.length > 0) {
        return loadPlaylistsItems(page + 1);
      }
      return trackResponse.playlist;
    };

    return loadPlaylistsItems(0);
  }

  async loadPlaylistItemPage(playlistId: string, page = 0, limit = 20): Promise<JioSaavnPlaylistWithTracksResponse> {
    const [, token] = playlistId.split('|');
    try {
      const data = await this.apiCall('webapi.get', {
        token,
        p: page + 1,
        n: limit,
        includeMetaTags: 0,
        type: 'playlist',
      });
      return new JioSaavnPlaylistWithTracksResponse(data, this.userId);
    } catch (error) {
      if (error instanceof FetchError) {
        if (error.message === 'INPUT_MISSING') {
          throw new CollectionDoesNotExistsError();
        }
      }
      throw error;
    }
  }

  async loadAllPlaylists(): Promise<JioSaavnPlaylist[]> {
    const jsonData = await this.apiCall('playlist.list', {
      all_playlists: true,
      contents: 1,
      onlypids: true,
    });

    const playlistResponse = new JioSaavnPlaylistsResponse(jsonData, this.userId);
    return playlistResponse.playlists;
  }

  async loadAllLikedTracks(): Promise<JioSaavnCollectionTrack[]> {
    const user = await this.fetchProfile();
    const songIdsChunks = chunk(user.songIds, 20);

    let songs: JioSaavnCollectionTrack[] = [];
    for (const songIds of songIdsChunks) {
      songs = [...songs, ...(await this.fetchSongsDetails(songIds))];
    }
    return songs;
  }

  async fetchSongsDetails(songIds: string[]): Promise<JioSaavnCollectionTrack[]> {
    const data = await this.apiCall('library.getDetails', {
      entity_ids: songIds.join(','),
      entity_type: 'song',
      n: 50,
    });
    return data.songs
      .map((s: any) => JioSaavnCollectionTrack.fromData(s))
      .filter((track: JioSaavnCollectionTrack | null) => !!track);
  }

  static newPlaylistName(name: string) {
    return `${name} - ${uuid4().substring(0, 6)}`;
  }

  async createPlaylist(name: string): Promise<JioSaavnPlaylist | null> {
    try {
      const data = await this.apiCall('playlist.create', {
        listname: name,
        contents: '',
        share: true,
      });
      if (data.status !== 'ok') {
        throw new FetchError(500, `Could not create playlist: ${JSON.stringify(data)}`);
      }
      return JioSaavnPlaylist.fromData(data.details, this.userId);
    } catch (e) {
      if (e instanceof FetchError) {
        if (e.message.includes('You already have a playlist by this name. Please enter a different name')) {
          return this.createPlaylist(JioSaavnAPI.newPlaylistName(name));
        }
      }
      throw e;
    }
  }

  async addTrackToPlaylist(playlistId: string, trackId: string): Promise<JioSaavnPlaylist | null> {
    const [id] = playlistId.split('|');
    const data = await this.apiCall('playlist.add', {
      listid: id,
      contents: `~~${trackId}~`,
    });
    if (data.status !== 'ok') {
      throw new FetchError(500, `Could not add track (${trackId}) to playlist: ${JSON.stringify(data)}`);
    }
    return JioSaavnPlaylist.fromData(data.details, this.userId);
  }

  async removeTrackFromPlaylist(playlistId: string, trackId: string): Promise<JioSaavnPlaylist | null> {
    const [id] = playlistId.split('|');
    const data = await this.apiCall('playlist.remove', {
      listid: id,
      pid: trackId,
    });

    return JioSaavnPlaylist.fromData(data.details, this.userId);
  }

  static getAuthData(cookies: Record<string, any>, user: JioSaavnUser): JioSaavnAuthenticationData {
    return {
      authId: `${user.uid}`,
      userUUID: null,
      title: user.name,
      subTitle: user.uid,
      imageUrl: user.image,
      expiresAt: null,
      additionalData: {
        cookies,
      },
    };
  }
}
