import { Environment } from './types';

export const getLocalIP = (environment: Environment): string | undefined => {
  if (environment !== Environment.local && environment !== Environment.localProduction) {
    return undefined;
  }
  try {
    // eslint-disable-next-line global-require,import/no-unresolved
    const ip = `${require('./my_ip.json')}`;

    if (
      !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        ip
      )
    ) {
      return undefined;
    }
    return ip;
  } catch (e) {
    return undefined;
  }
};
