export class ZvukAuth {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get sapiAccessToken(): string {
    return this.data.sauth.value;
  }

  get accessToken(): string {
    return this.data.auth.value;
  }
}
