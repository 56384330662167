import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { tryParseInt } from 'fym-common/src/utils/tryParseInt';
import { TypedMemo } from '../../common/types';
import { useGetSmartLinkQuery } from '../../../redux/api/fymAPI';
import { useQueryParam } from '../../../utils/useQueryParam';
import { LoadingView } from '../../common/views/LoadingView';
import { SmartLinkDetailsViewRenderer } from './SmartLinkDetailsViewRenderer';
import { ErrorView } from '../../common/views/ErrorView/ErrorView';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { SMLPublishingModal } from '../../common/views/modals/smlPublishingModal/SMLPublishingModal';
import { isSmartLinkDraft } from '../utils/isSmartLinkDraft';
import { SmartLinkUpsell } from '../upselling/SmartLinkUpsell';
import { SMLAnalytics } from '../../analytics/sml/analytics';
import { SMLSlugTakenModal } from '../../common/views/modals/smlSlugTakenModal/SMLSlugTakenModal';
import { useWidgetUpdatePosition } from '../widgets/views/arrows/useWidgetUpdatePosition';
import { useOnPreviewMessageDoAction } from './messageListeners/useOnPreviewMessageDoAction';
import { SMLPreviewMessageData, SMLPreviewMessages } from './messageListeners/types';

interface SmartLinkDetailsViewProps {
  route?: {
    params: {
      accountId: number;
      smartLinkId: number;
    };
  };
}

const SmartLinkDetailsViewComponent: React.FC<SmartLinkDetailsViewProps> = ({ route }) => {
  const intl = useIntl();
  const router = useSMLRouter();
  const accountIdString = useQueryParam('accountId', route);
  const accountId = tryParseInt(accountIdString);
  const smartLinkIdString = useQueryParam('smartLinkId', route);
  const smartLinkId = tryParseInt(smartLinkIdString);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isModalSlugTakenOpen, setModalSlugTakenOpen] = useState<boolean>(false);
  const [isUpsellingOpen, setIsUpsellingOpen] = useState<boolean>(false);
  const closeUpselling = useCallback(() => setIsUpsellingOpen(false), []);
  const openUpselling = useCallback(() => setIsUpsellingOpen(true), []);
  if (accountId === undefined || smartLinkId === undefined) {
    throw new Error(`Missing accountId: [${accountId}] or smartLinkId: [${smartLinkId}]`);
  }

  const { smartLink, widgets, isGetSmartLinkLoading, getSmartLinkError, refetch } = useGetSmartLinkQuery(
    { accountId, smartLinkId },
    {
      selectFromResult: ({ data, isLoading, error }) => {
        return {
          smartLink: data?.smartLink,
          widgets: data?.smartLink?.recentSmartLinkVersion?.widgets ?? null,
          isGetSmartLinkLoading: isLoading,
          getSmartLinkError: error,
        };
      },
    }
  );

  const goToSmartLinkEditionView = useCallback(() => {
    router.push(SMLRoutes.edit, { accountId, smartLinkId });

    SMLAnalytics.trackSmartLinkCustomizeStarted().catch(console.error);
  }, [router, accountId, smartLinkId]);

  const goToWidgetEditionView = useCallback(
    (widgetUUID: string) => {
      const widget = widgets?.find((w) => w.uuid === widgetUUID);
      if (!widget) {
        return;
      }
      router.push(SMLRoutes.widgetEdit, { accountId, smartLinkId, widgetUUID, widgetType: widget.type });
    },
    [widgets, router, accountId, smartLinkId]
  );

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const openSlugTakenModal = useCallback(() => {
    setModalSlugTakenOpen(true);
  }, []);

  const closeSlugTakenModal = useCallback(() => {
    setModalSlugTakenOpen(false);
  }, []);

  const { incrementPosition, decrementPosition } = useWidgetUpdatePosition(
    smartLink?.accountId ?? 0,
    smartLink?.id ?? 0,
    widgets?.length ?? 0
  );
  const onPositionIncrement = useCallback(
    (widgetUUID: string) => {
      const widget = widgets?.find((w) => w.uuid === widgetUUID);
      if (!widget) {
        return;
      }
      incrementPosition(widget);
    },
    [incrementPosition, widgets]
  );
  const onPositionDecrement = useCallback(
    (widgetUUID: string) => {
      const widget = widgets?.find((w) => w.uuid === widgetUUID);
      if (!widget) {
        return;
      }
      decrementPosition(widget);
    },
    [decrementPosition, widgets]
  );
  const onParentUp = useOnPreviewMessageDoAction(SMLPreviewMessages.up, onPositionDecrement);
  const onParentDown = useOnPreviewMessageDoAction(SMLPreviewMessages.down, onPositionIncrement);
  const onParentEdit = useOnPreviewMessageDoAction(SMLPreviewMessages.edit, goToWidgetEditionView);

  const onPreviewMessageEvent = useCallback(
    (data: SMLPreviewMessageData) => {
      const callbacks = [onParentUp, onParentDown, onParentEdit];
      callbacks.forEach((fn) => {
        fn(data);
      });
    },
    [onParentDown, onParentEdit, onParentUp]
  );

  if (getSmartLinkError) {
    return <ErrorView error={getSmartLinkError} retry={refetch} />;
  }

  if (!smartLink || isGetSmartLinkLoading) {
    return <LoadingView />;
  }

  const { shareUrl, isMainSmartLink, recentSmartLinkVersion } = smartLink;
  const smartLinkName = recentSmartLinkVersion.name;
  const isDraft = isSmartLinkDraft(smartLink);
  const title = isMainSmartLink
    ? intl.formatMessage({ id: 'app.sml.bio-link', defaultMessage: 'Bio Link' })
    : recentSmartLinkVersion.name;

  return (
    <>
      <SmartLinkUpsell isOpen={isUpsellingOpen} onClose={closeUpselling} />
      <SMLPublishingModal
        closeModal={closeModal}
        isOpen={isModalOpen}
        smartLinkName={smartLinkName}
        shareUrl={shareUrl}
      />
      <SMLSlugTakenModal
        closeModal={closeSlugTakenModal}
        isOpen={isModalSlugTakenOpen}
        accountId={accountId}
        smartLink={smartLink}
        setIsPublishModalOpen={openModal}
      />
      <SmartLinkDetailsViewRenderer
        goToSmartLinkEditionView={goToSmartLinkEditionView}
        openModal={openModal}
        openSlugTakenModal={openSlugTakenModal}
        title={title}
        smartLink={smartLink}
        isDraft={isDraft}
        openUpselling={openUpselling}
        onPreviewMessageEvent={onPreviewMessageEvent}
      />
    </>
  );
};

export const SmartLinkDetailsView = TypedMemo(SmartLinkDetailsViewComponent);
