import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SmartLinkWidgetType } from 'fym-common/src/db/enums';
import { SerializedLinkWidget } from 'fym-common/src/db/serializersTypes';
import { TypedMemo } from '../../../../../common/types';
import { LinkWidgetForm, LinkWidgetFormProps } from '../../../../../forms/LinkWidgetForm/LinkWidgetForm';
import { WidgetEditionProps } from '../../props';

const LinkWidgetEditionViewComponent: React.FC<WidgetEditionProps<SerializedLinkWidget>> = ({
  widget,
  saveWidget,
  deleteWidget,
  isLoading,
  error,
  smartLinkId,
  accountId,
}) => {
  const intl = useIntl();
  const onSubmit: LinkWidgetFormProps['onSubmit'] = useCallback(
    async (data) => {
      await saveWidget({ url: data.url, label: data.label, smartLinkId: data.smartLinkId }, SmartLinkWidgetType.link);
    },
    [saveWidget]
  );

  return (
    <LinkWidgetForm
      title={intl.formatMessage({ id: 'app.smart_links.widgets.link.title', defaultMessage: 'Link widget' })}
      onSubmit={onSubmit}
      widget={widget}
      onDelete={deleteWidget}
      isLoading={isLoading}
      error={error}
      smartLinkId={smartLinkId}
      accountId={accountId}
    />
  );
};

export const LinkWidgetEditionView = TypedMemo(LinkWidgetEditionViewComponent);
