import { CollectionItemModelData, GenericCollectionItem } from '../../../generics/models/GenericCollectionItem';
import { getBrowseId, getFlexColumnItem, getItemText, nav, YouTubeMusicParser } from '../youTubeMusicParser';
import { YouTubeMusicTrackType, YouTubeMusicPlaylistTrackAdditionalData } from '../types';
import { tryParseInt } from '../../../utils/tryParseInt';
import { createYouTubeMusicTrackUrl } from '../utils';
import { artistsToString } from '../../../utils/artistsConverter';

export class YouTubeMusicPlaylistTrack extends GenericCollectionItem {
  public type;

  constructor(
    data: CollectionItemModelData & { additionalData?: YouTubeMusicPlaylistTrackAdditionalData },
    type: YouTubeMusicTrackType
  ) {
    super(data);
    this.type = type;
  }

  static fromData(data: any, isOwned: boolean): YouTubeMusicPlaylistTrack | null {
    if (!data?.musicResponsiveListItemRenderer) {
      return null;
    }
    const track = data.musicResponsiveListItemRenderer;
    let duration: string | undefined;
    if (track.fixedColumns) {
      if (track.fixedColumns[0].musicResponsiveListItemFixedColumnRenderer.text.simpleText) {
        duration = track.fixedColumns[0].musicResponsiveListItemFixedColumnRenderer.text.simpleText;
      } else {
        duration = track.fixedColumns[0].musicResponsiveListItemFixedColumnRenderer.text.runs[0].text;
      }
    }

    let parsedDuration: number | undefined;
    if (duration) {
      const [minutes, seconds] = duration.split(':');
      parsedDuration = ((tryParseInt(minutes) ?? 0) * 60 + (tryParseInt(seconds) ?? 0)) * 1000;
    }
    let videoId: string | undefined;
    let entryId: string | undefined;
    let type = YouTubeMusicTrackType.Video;
    if (isOwned) {
      const menuItems = nav(track, YouTubeMusicParser.MENU_ITEMS) as any[];
      menuItems.forEach((menuItem) => {
        if (menuItem.menuServiceItemRenderer) {
          const menuService = nav(menuItem, YouTubeMusicParser.MENU_SERVICE);
          if (!menuService || !menuService.playlistEditEndpoint) {
            return;
          }
          entryId = menuService.playlistEditEndpoint.actions[0].setVideoId;
          videoId = menuService.playlistEditEndpoint.actions[0].removedVideoId;
          return;
        }
        if (menuItem?.toggleMenuServiceItemRenderer?.defaultIcon?.iconType === 'LIBRARY_ADD') {
          type = YouTubeMusicTrackType.Song;
        }
      });
    } else {
      const playButton = nav(track, YouTubeMusicParser.PLAY_BUTTON);
      videoId = playButton.playNavigationEndpoint?.watchEndpoint.videoId;
      entryId = playButton.playNavigationEndpoint?.watchEndpoint.playlistSetVideoId;
    }
    const title = getItemText(track, 0);
    if (!entryId || !title) {
      return null;
    }
    const artistsData = YouTubeMusicPlaylistTrack.parseArtistsData(track, 1);
    const artistsNames = artistsData.filter((artist) => !!artist.name).map((artist) => artist.name);
    const album = YouTubeMusicPlaylistTrack.parseAlbumData(track, 2);
    const thumbnails = nav(track, YouTubeMusicParser.THUMBNAILS);
    const thumbnail = thumbnails && thumbnails.length > 0 ? thumbnails[thumbnails.length - 1].url : undefined;

    return new YouTubeMusicPlaylistTrack(
      {
        rawId: entryId,
        globalId: videoId,
        name: title,
        album: album?.name,
        artist: artistsToString(artistsNames),
        imageUrl: thumbnail,
        duration: parsedDuration,
        sourceURL: createYouTubeMusicTrackUrl(videoId),
      },
      type
    );
  }

  private static parseAlbumData(data: any, index: number) {
    const flexItem = getFlexColumnItem(data, index);
    if (!flexItem) {
      return undefined;
    }
    return {
      name: getItemText(data, index),
      id: getBrowseId(flexItem, 0),
    };
  }

  private static parseArtistsData(data: any, index: number) {
    const flexItem = getFlexColumnItem(data, index);
    if (!flexItem?.text?.runs) {
      return [];
    }
    const count = Math.floor(flexItem.text.runs.length / 2) + 1;
    const artists: any[] = [];
    for (let i = 0; i < count; i += 1) {
      artists.push({
        name: getItemText(data, index, i * 2),
        id: getBrowseId(flexItem, i * 2),
      });
    }
    return artists;
  }
}
