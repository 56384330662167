import qs from 'qs';
import chunk from 'lodash/chunk';
import { defaultRetryOptions, fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { Response } from '../../utils/fetch-types';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { FetchError } from '../../generics/errors/FetchError';
import { AmazonMusicUserProfileResponse } from './models/AmazonMusicUserProfileResponse';
import { AmazonMusicAPIData, RecentlyPlayedTrack, TrackBaseInfo } from './types';
import { AmazonMusicMyPlaylistsResponse } from './models/AmazonMusicMyPlaylistsResponse';
import { AmazonMusicPlaylist } from './models/AmazonMusicPlaylist';
import { AmazonMusicPlaylistWithTracksResponse } from './models/AmazonMusicPlaylistWithTracksResponse';
import { AmazonMusicCollectionTrack } from './models/AmazonMusicCollectionTrack';
import { AmazonMusicPlaylistCreationResponse } from './models/AmazonMusicPlaylistCreationResponse';
import { AmazonMusicTracksResponse } from './models/AmazonMusicTracksResponse';
import { AmazonMusicTrackAdditionResponse } from './models/AmazonMusicTrackAdditionResponse';
import { AmazonMusicSearchResponse } from './models/AmazonMusicSearchResponse';
import { constructSearchFilters } from './utils';
import { SearchQueryProperties } from '../../generics/types';
import { ImporterID } from '../types';
import { config } from '../../config/config';
import { AmazonMusicLikedPlaylistsResponse } from './models/AmazonMusicLikedPlaylistsResponse';
import { AmazonMusicUserResponse } from './models/AmazonMusicUserResponse';
import { AmazonMusicLikedTracksResponse } from './models/AmazonMusicLikedTracksResponse';
import { AmazonMusicTrackRemovalResponse } from './models/AmazonMusicTrackRemovalResponse';
import { AmazonMusicAlbum } from './models/AmazonMusicAlbum';
import { AmazonMusicRecentlyPlayedTracksResponse } from './models/AmazonMusicRecentlyPlayedTracksResponse';
import { AmazonMusicAlbumsResponse } from './models/AmazonMusicAlbumsResponse';
import { AmazonMusicArtistsResponse } from './models/AmazonMusicArtistsResponse';
import { AmazonMusicArtist } from './models/AmazonMusicArtist';
import { tryParseJSON } from '../../utils/tryParseJSON';
import { AmazonPlaylistVisibility } from './models/types';
import { CollectionType } from '../../generics/models/Collection';
import { AmazonMusicMatchedTrack } from './models/AmazonMusicMatchedTrack';
import { MusicServiceError, MusicServiceErrorType } from '../../errors/musicServiceError/MusicServiceError';

export const amazonRetryOptions = {
  ...defaultRetryOptions,
  statusCodes: defaultRetryOptions.statusCodes.filter((code) => code !== 500),
};

export class AmazonMusicAPI {
  private static readonly BASE_API_URL = 'https://api.music.amazon.dev/v1/';

  private readonly accessToken: string;

  private readonly profileID: string;

  private readonly userId: string;

  private readonly maxTitleLength = 100;

  constructor(data: AmazonMusicAPIData) {
    const { accessToken, profileID, userId } = data;
    this.accessToken = accessToken;
    this.profileID = profileID;
    this.userId = userId;
  }

  private get requestHeaders() {
    return {
      Authorization: `Bearer ${this.accessToken}`,
      'x-api-key': config.amazonSecurityProfileId,
      'Content-Type': 'application/json',
    };
  }

  public static async getUserProfile(accessToken: string) {
    const url = `https://api.amazon.com/user/profile?${qs.stringify({
      access_token: accessToken,
    })}`;
    const headers = {
      Host: 'api.amazon.com',
    };

    const response = await fetchRetry(url, {
      method: 'GET',
      headers,
    });

    if (!response.ok) {
      const text = await response.text();
      if (text.includes('DENIED_SUBSCRIPTION_TIER')) {
        throw new MusicServiceError({ message: text, errorType: MusicServiceErrorType.deniedSubscriptionTier });
      }
      throw new FetchError(
        response.status,
        `Got wrong response when getting user data for AmazonMusic [${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new AmazonMusicUserProfileResponse(data);
  }

  public async getMe() {
    const url = `${AmazonMusicAPI.BASE_API_URL}me`;
    const response = await fetchRetry(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        'x-api-key': config.amazonSecurityProfileId,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when getting user data for AmazonMusic [${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new AmazonMusicUserResponse(data);
  }

  public async createPlaylist(title: string, props?: { isPublic?: boolean; description?: string }) {
    const { isPublic = false, description } = props ?? {};
    const url = `${AmazonMusicAPI.BASE_API_URL}playlists`;
    const body = JSON.stringify({
      title: title.slice(0, this.maxTitleLength),
      visibility: isPublic ? AmazonPlaylistVisibility.public : AmazonPlaylistVisibility.private,
      description,
    });
    const data = await this.fetch(url, { method: 'POST', headers: this.requestHeaders, body });
    const { playlist } = new AmazonMusicPlaylistCreationResponse(data, this.userId);
    let updatedPlaylist: AmazonMusicPlaylist | null = null;
    if (description && playlist && !playlist.additionalData?.description) {
      /*
       * TODO - workaround for setting description
       * Setting description during playlist's creation is not working (it works during playlist's update only).
       * Amazon was informed about that issue. For now we update playlist just after creation to add
       * that description, but it should be removed when Amazon fix this issue
       * */
      try {
        updatedPlaylist = await this.updatePlaylist(playlist.rawId, { title, description });
      } catch (err) {
        if (err instanceof NotAuthenticatedError) {
          throw err;
        }
        // In case of any other error do nothing as it is only a try to set description
        console.error(err);
      }
    }
    return updatedPlaylist ?? playlist;
  }

  public async getPlaylist(playlistId: string) {
    const url = `${AmazonMusicAPI.BASE_API_URL}playlists/${playlistId}`;
    const data = await this.fetch(url, { method: 'GET', headers: this.requestHeaders });
    return AmazonMusicPlaylist.fromData(data.data?.playlist, this.userId);
  }

  public async updatePlaylist(playlistId: string, props: { title: string; description?: string; isPublic?: boolean }) {
    const { title, description, isPublic } = props;
    const url = `${AmazonMusicAPI.BASE_API_URL}playlists/${playlistId}`;
    const body = JSON.stringify({
      title: title.slice(0, this.maxTitleLength), // Title is required even if you do not want to change it…
      description,
      visibility:
        isPublic === undefined
          ? undefined
          : isPublic
          ? AmazonPlaylistVisibility.public
          : AmazonPlaylistVisibility.private,
    });
    const data = await this.fetch(url, { method: 'PUT', headers: this.requestHeaders, body });
    return AmazonMusicPlaylist.fromData(data.data?.updatePlaylist, this.userId, CollectionType.PLAYLIST);
  }

  public async removePlaylists(playlistsIds: string[]) {
    const url = `${AmazonMusicAPI.BASE_API_URL}playlists`;
    const body = JSON.stringify({ ids: playlistsIds });
    await this.fetch(url, { method: 'DELETE', headers: this.requestHeaders, body });
  }

  public async getMyPlaylistsPage(limit: number, cursor?: string | null) {
    const url = `${AmazonMusicAPI.BASE_API_URL}me/playlists?${qs.stringify({ limit, cursor })}`;
    const data = await this.fetch(url, { method: 'GET', headers: this.requestHeaders });
    return new AmazonMusicMyPlaylistsResponse(data, this.userId);
  }

  public async getLikedPlaylistsPage(limit: number, cursor?: string | null) {
    const url = `${AmazonMusicAPI.BASE_API_URL}me/followed/playlists?${qs.stringify({ limit, cursor })}`;
    const data = await this.fetch(url, { method: 'GET', headers: this.requestHeaders });
    return new AmazonMusicLikedPlaylistsResponse(data, this.userId);
  }

  public async getLikedTracksPage(limit: number, cursor?: string | null) {
    // TODO: Limit cannot be passed, we got error: 'Float cannot represent non numeric value'
    const url = `${AmazonMusicAPI.BASE_API_URL}me/tracks?${qs.stringify({ cursor })}`;
    const data = await this.fetch(url, { method: 'GET', headers: this.requestHeaders });
    return new AmazonMusicLikedTracksResponse(data);
  }

  public async getPaginatedMyPlaylists(onBatch: (collections: AmazonMusicPlaylist[]) => Promise<void>) {
    const limit = 50;
    const getPlaylists = async (cursor?: string | null) => {
      const playlistsResponse = await this.getMyPlaylistsPage(limit, cursor);
      await onBatch(playlistsResponse.playlists);
      if (playlistsResponse.hasNextPage === true) {
        await getPlaylists(playlistsResponse.pageInfoToken);
      }
    };

    await getPlaylists();
  }

  public async getPaginatedLikedPlaylists(onBatch: (collections: AmazonMusicPlaylist[]) => Promise<void>, limit = 50) {
    const getLikedPlaylists = async (cursor?: string | null) => {
      const playlistsResponse = await this.getLikedPlaylistsPage(limit, cursor);
      await onBatch(playlistsResponse.playlists);
      if (playlistsResponse.hasNextPage === true) {
        await getLikedPlaylists(playlistsResponse.pageInfoToken);
      }
    };

    await getLikedPlaylists();
  }

  public async getPaginatedLikedTracks(onBatch: (tracks: AmazonMusicCollectionTrack[]) => Promise<void>) {
    const limit = 50;
    const getLikedTracks = async (cursor?: string | null) => {
      const tracksResponse = await this.getLikedTracksPage(limit, cursor);
      const tracksBaseInfo = tracksResponse.tracks.map((track) => ({
        rawId: track.rawId,
        entryId: track.additionalData?.entryId,
      }));
      const tracksWithAlbums = await this.getTracksPage(tracksBaseInfo);
      await onBatch(tracksWithAlbums);
      if (tracksResponse.hasNextPage === true) {
        await getLikedTracks(tracksResponse.pageInfoToken);
      }
    };

    await getLikedTracks();
  }

  public async getPlaylistWithTracksPage(playlistId: string, limit: number, cursor?: string | null) {
    const url = `${AmazonMusicAPI.BASE_API_URL}playlists/${playlistId}/tracks?${qs.stringify({
      limit,
      cursor,
    })}`;
    const data = await this.fetch(url, { method: 'GET', headers: this.requestHeaders });
    if (!data.data.playlist) {
      throw new FetchError(200, `AmazonMusic - could not find playlist with id:[${playlistId}]`);
    }
    return new AmazonMusicPlaylistWithTracksResponse(data, this.userId);
  }

  public async getPaginatedPlaylistTracks(
    playlistId: string,
    onBatch: (tracks: AmazonMusicCollectionTrack[]) => Promise<void>
  ) {
    const limit = 50;
    const getTracks = async (cursor?: string | null) => {
      const { tracks, hasNextPage, pageInfoToken } = await this.getPlaylistWithTracksPage(playlistId, limit, cursor);
      await onBatch(tracks);
      if (hasNextPage === true) {
        await getTracks(pageInfoToken);
      }
    };

    await getTracks();
  }

  public async appendTracksToPlaylist(playlistId: string, trackIds: string[]) {
    const url = `${AmazonMusicAPI.BASE_API_URL}playlists/${playlistId}/tracks`;
    const body = JSON.stringify({ trackIds });
    const data = await this.fetch(url, { method: 'PUT', headers: this.requestHeaders, body });
    return new AmazonMusicTrackAdditionResponse(data, this.userId).playlist;
  }

  public async addAlbumToLibrary(albumId: string): Promise<void> {
    const url = `${AmazonMusicAPI.BASE_API_URL}me/library/albums/${albumId}`;
    await this.fetch(url, { method: 'PUT', headers: this.requestHeaders });
  }

  public async removeTracksFromPlaylist(playlistId: string, entryIds: string[]) {
    const url = `${AmazonMusicAPI.BASE_API_URL}playlists/${playlistId}/tracks`;
    const body = JSON.stringify({ entryIds });
    const data = await this.fetch(url, { method: 'DELETE', headers: this.requestHeaders, body });
    return new AmazonMusicTrackRemovalResponse(data, this.userId).playlist;
  }

  public async moveTracksInPlaylist(
    playlistId: string,
    entryIds: string[],
    entryIdAbove: string,
    entryIdBelow: string
  ) {
    const url = `${AmazonMusicAPI.BASE_API_URL}playlists/${playlistId}/tracks`;
    /*
     * Treat playlist like vertical list, so the first item is on the top, last item is on the bottom,
     * that is why `entryIdAbove` means track which will be above moved tracks (so position of that track will be smaller)
     * This endpoint works odd, both parameters: entryIdAbove and entryIdBelow are required even if there will be no tracks above or below…
     * if you want to move some tracks in the middle of playlist, then it works fine,
     * if you want to move some tracks to the last position, then `entryIdBelow` must be equal to entry id of last item which is moved
     * if you want to move some tracks to the first position, then you have to move tracks from first positions after the tracks you want to move
     *
     * Look into tests of AmazonAPI and AmazonImporter for better understanding…
     * */
    const body = JSON.stringify({
      entryIds,
      entryIdAbove,
      entryIdBelow,
    });
    await this.fetch(url, { method: 'PATCH', headers: this.requestHeaders, body });
  }

  public async getTracksPage(tracksBaseInfo: TrackBaseInfo[]) {
    const ids = tracksBaseInfo.map(({ rawId }) => rawId).join(',');
    if (ids.length === 0) {
      return [];
    }
    const url = `${AmazonMusicAPI.BASE_API_URL}tracks?${qs.stringify({ ids })}`;
    const data = await this.fetch(url, { method: 'GET', headers: this.requestHeaders });
    return new AmazonMusicTracksResponse(data, tracksBaseInfo).tracks;
  }

  public async searchTracks(props: {
    queryProps: SearchQueryProperties;
    advancedSearch?: boolean;
    limit?: number;
    nextParam?: string;
  }): Promise<{
    tracks: AmazonMusicMatchedTrack[];
    hasNextPage: boolean | undefined;
    nextParam: string | undefined;
  }> {
    const { queryProps, advancedSearch = true, limit = 10, nextParam } = props;
    const searchFilters = constructSearchFilters(queryProps, advancedSearch);
    if (searchFilters.length === 0) {
      return { tracks: [], hasNextPage: undefined, nextParam: undefined };
    }
    const url = `${AmazonMusicAPI.BASE_API_URL}search/tracks/`;
    const body = JSON.stringify({ limit, searchFilters, token: nextParam });
    const data = await this.fetch(
      url,
      { method: 'POST', headers: this.requestHeaders, body },
      { ...amazonRetryOptions, shouldWait: true }
    );
    const { tracks, tracksHasNextPage, tracksPageInfoToken } = new AmazonMusicSearchResponse(data);
    return {
      tracks,
      hasNextPage: tracksHasNextPage,
      nextParam: tracksPageInfoToken,
    };
  }

  public async searchAlbums(props: {
    queryProps: SearchQueryProperties;
    advancedSearch?: boolean;
    limit?: number;
    nextParam?: string;
  }): Promise<{ albums: AmazonMusicAlbum[]; hasNextPage: boolean | undefined; nextParam: string | undefined }> {
    const { queryProps, advancedSearch = true, limit = 10, nextParam } = props;
    const searchFilters = constructSearchFilters(queryProps, advancedSearch);
    const url = `${AmazonMusicAPI.BASE_API_URL}search/albums`;
    const body = JSON.stringify({ limit, searchFilters, token: nextParam });
    const data = await this.fetch(
      url,
      { method: 'POST', headers: this.requestHeaders, body },
      { ...amazonRetryOptions, shouldWait: true }
    );
    const { albums, albumsHasNextPage, albumsPageInfoToken } = new AmazonMusicSearchResponse(data);
    return { albums, hasNextPage: albumsHasNextPage, nextParam: albumsPageInfoToken };
  }

  public async getAlbumsPage(albumsIds: string[]): Promise<AmazonMusicAlbum[]> {
    const url = `${AmazonMusicAPI.BASE_API_URL}albums?${qs.stringify({ ids: albumsIds.join(',') })}`;
    const data = await this.fetch(url, { method: 'GET', headers: this.requestHeaders });
    return new AmazonMusicAlbumsResponse(data).albums;
  }

  public async getAlbums(albumsIds: string[], callback?: (albumsCount: number) => void): Promise<AmazonMusicAlbum[]> {
    const maxAmazonLimit = 100;
    const albumsIdsChunks = chunk(albumsIds, maxAmazonLimit);
    const albums: AmazonMusicAlbum[] = [];
    let albumsCount = 0;
    for (const albumsIdsChunk of albumsIdsChunks) {
      albumsCount += albumsIdsChunk.length;
      callback?.(albumsCount);
      const albumsPage = await this.getAlbumsPage(albumsIdsChunk);
      albums.push(...albumsPage);
    }
    return albums;
  }

  public async getArtistsPage(artistsIds: string[]): Promise<AmazonMusicArtist[]> {
    const url = `${AmazonMusicAPI.BASE_API_URL}artists?${qs.stringify({ ids: artistsIds.join(',') })}`;
    const data = await this.fetch(url, { method: 'GET', headers: this.requestHeaders });
    return new AmazonMusicArtistsResponse(data).artists;
  }

  public async getArtists(
    artistsIds: string[],
    callback?: (artistsCount: number) => void
  ): Promise<AmazonMusicArtist[]> {
    const maxAmazonLimit = 100;
    const artistsIdsChunks = chunk(artistsIds, maxAmazonLimit);
    const artists: AmazonMusicArtist[] = [];
    let artistsCount = 0;
    for (const artistsIdsChunk of artistsIdsChunks) {
      artistsCount += artistsIdsChunk.length;
      callback?.(artistsCount);
      const artistsPage = await this.getArtistsPage(artistsIdsChunk);
      artists.push(...artistsPage);
    }
    return artists;
  }

  public async getRecentlyPlayedTracksPage(limit: number, cursor?: string | null) {
    const url = `${AmazonMusicAPI.BASE_API_URL}player/recentlyPlayed?${qs.stringify({ limit, cursor })}`;
    const data = await this.fetch(url, { method: 'GET', headers: this.requestHeaders });
    return new AmazonMusicRecentlyPlayedTracksResponse(data);
  }

  public async getAllRecentlyPlayedTracks(
    callback?: (fetchedTracksCount: number) => void
  ): Promise<Map<string, RecentlyPlayedTrack>> {
    const limit = 100;
    let tracksCount = 0;
    const recentlyPlayedTracks = new Map<string, { rawId: string; playCount: number; playedAt: number[] }>();
    const getRecentlyPlayedTracks = async (cursor?: string | null) => {
      const tracksResponse = await this.getRecentlyPlayedTracksPage(limit, cursor);
      tracksCount += tracksResponse.tracks.length;
      callback?.(tracksCount);
      tracksResponse.tracks.forEach((track) => {
        const playedAt = track.additionalData?.playedAt;
        const existingTrack = recentlyPlayedTracks.get(track.rawId);
        if (!existingTrack) {
          recentlyPlayedTracks.set(track.rawId, {
            rawId: track.rawId,
            playCount: 1,
            playedAt: playedAt !== undefined ? [playedAt] : [],
          });
        } else {
          recentlyPlayedTracks.set(track.rawId, {
            ...existingTrack,
            playCount: existingTrack.playCount + 1,
            playedAt: playedAt !== undefined ? [...existingTrack.playedAt, playedAt] : existingTrack.playedAt,
          });
        }
      });
      const { hasNextPage, pageInfoToken } = tracksResponse;
      if (hasNextPage === true && pageInfoToken !== 'lastPage') {
        await getRecentlyPlayedTracks(pageInfoToken);
      }
    };

    await getRecentlyPlayedTracks();
    return recentlyPlayedTracks;
  }

  static responseHasErrors(data: Record<string, unknown> | undefined) {
    return !!(
      data &&
      Array.isArray(data.errors) &&
      data.errors.length > 0 &&
      data.errors.some(
        (error: any) =>
          typeof error !== 'object' || (typeof error === 'object' && error !== null && Object.keys(error).length > 0)
      )
    );
  }

  static async handleError500(response: Response) {
    const text = await response.text();
    const data = tryParseJSON(text);
    if (!data) {
      throw new FetchError(response.status, `Wrong response status code (${response.status}): ${text}`);
    }
    if (AmazonMusicAPI.responseHasErrors(data)) {
      throw new FetchError(response.status, JSON.stringify(data.errors));
    }
    return data;
  }

  private async fetch(url: string, options: any, retryOptions: RetryOptions = amazonRetryOptions) {
    const response = await fetchRetry(url, options, retryOptions);

    if (response.status === 403) {
      const text = await response.text();
      throw new NotAuthenticatedError({ authId: this.profileID, importerId: ImporterID.AmazonMusic }, text);
    }

    if (response.status === 500) {
      return AmazonMusicAPI.handleError500(response);
    }

    if (!response.ok) {
      const text = await response.text();
      const message = `AmazonMusic - got wrong response:
        URL: [${url}]
        body: [${JSON.stringify(options.body)}]
        status code: [${response.status}]
        message: [${text}]`;

      throw new FetchError(response.status, message);
    }

    const data = await response.json();
    if (AmazonMusicAPI.responseHasErrors(data)) {
      throw new FetchError(response.status, JSON.stringify(data.errors));
    }
    return data;
  }
}
