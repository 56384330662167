import { BoomplayPlaylist } from './BoomplayPlaylist';
import { BoomplayPlaylistsRawResponse } from './types';
import { CollectionType } from '../../../generics/models/Collection';

export class BoomplayPlaylistsResponse {
  private readonly data;

  private readonly type;

  constructor(data: BoomplayPlaylistsRawResponse | undefined, type: CollectionType) {
    this.data = data;
    this.type = type;
  }

  get playlists(): BoomplayPlaylist[] {
    if (!this.data?.data) {
      return [];
    }
    return this.data.data
      .map((item) => BoomplayPlaylist.fromData(item, this.type))
      .filter((playlist): playlist is BoomplayPlaylist => !!playlist);
  }

  get counts() {
    return this.data?.counts;
  }
}
