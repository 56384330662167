import React, { useCallback, useMemo } from 'react';
import { TypedMemo } from '../../common/types';
import { isSmartLinkDraft } from '../utils/isSmartLinkDraft';
import { stringToColor } from '../../../utils/stringToColor';
import { SMLListItemBoxRenderer } from './SMLListItemBoxRenderer';
import { SMLListItemBoxProps } from './props';

const SMLListItemBoxComponent: React.FC<SMLListItemBoxProps> = ({ smartLink, onClick }) => {
  const handleOnClick = useCallback(() => onClick(smartLink.id), [onClick, smartLink.id]);
  const isDraft = isSmartLinkDraft(smartLink);
  const { name, imagePreviewURL } = smartLink.recentSmartLinkVersion;
  const backgroundColor = useMemo(() => stringToColor(name), [name]);

  return (
    <SMLListItemBoxRenderer
      onClick={handleOnClick}
      isDraft={isDraft}
      name={name}
      backgroundColor={backgroundColor}
      imagePreviewURL={imagePreviewURL}
    />
  );
};

export const SMLListItemBox = TypedMemo(SMLListItemBoxComponent);
