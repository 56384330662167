import { BaseObject } from '../../../universal/BaseObject';

export class TidalSessions extends BaseObject {
  get id(): string {
    return this.privateData.userId;
  }

  get country(): string {
    return this.privateData.countryCode;
  }
}
