export enum AlertLevel {
  success,
  warning,
  danger,
  info,
  dark,
}

export interface AlertProps {
  center?: boolean;
  level: AlertLevel;
}
