interface GenericAuthorData {
  rawId: string;
  name: string;
  additionalData?: any;
  followers?: number | null;
  imageUrl?: string | null;
  publicUrl?: string | null;
}

export class GenericAuthor {
  public rawId;

  public name;

  public additionalData;

  public followers;

  public imageUrl;

  public publicUrl;

  constructor(data: GenericAuthorData) {
    const { rawId, name, additionalData, followers, imageUrl, publicUrl } = data;
    this.rawId = Number.isInteger(rawId) ? `${rawId}` : rawId;
    this.name = name;
    this.additionalData = additionalData ?? null;
    this.followers = followers ?? null;
    this.imageUrl = imageUrl ?? null;
    this.publicUrl = publicUrl ?? null;
  }
}
