import {
  createSpotifyTrackUrl,
  getArtistsNamesFromPublicSearchAsString,
  getArtistsWithRolesNamesAsString,
  getBiggestCovertArtFromPublicSearch,
} from '../utils';
import { SpotifyAlbum } from './SpotifyAlbum';
import { SpotifyTrackResponseType, TrackFromPublicSearch } from './types';
import { SpotifyArtist } from './SpotifyArtist';
import { GenericMatchedItem, MatchedItemModelData } from '../../../generics/models/GenericMatchedItem';
import { convertSpotifyTrackData } from '../utils/convertSpotifyTrackData';

export class SpotifyMatchedTrack extends GenericMatchedItem {
  constructor(
    data: MatchedItemModelData,
    public albumExtended: SpotifyAlbum | null = null,
    public artists: SpotifyArtist[] | null = null
  ) {
    super(data);
  }

  static fromData(data: SpotifyTrackResponseType | undefined): SpotifyMatchedTrack | null {
    const convertedData = convertSpotifyTrackData(data);
    if (!convertedData) {
      return null;
    }
    const { trackData, album, artists } = convertedData;
    return new SpotifyMatchedTrack(trackData, album, artists);
  }

  static fromShareURLData(data: any, albumName?: string, url?: string): SpotifyMatchedTrack | null {
    const rawId = data?.id ?? data?.uri?.split('spotify:track:')[1];
    if (!rawId || !data?.name) {
      return null;
    }

    return new SpotifyMatchedTrack({
      rawId,
      name: data.name,
      album: albumName,
      artist: getArtistsWithRolesNamesAsString(data.artistsWithRoles),
      imageUrl: data.album?.coverArt?.sources?.[0]?.url,
      duration: data.duration?.totalMilliseconds,
      sourceURL: url?.split('?')[0] ?? createSpotifyTrackUrl(rawId),
    });
  }

  static fromPublicSearchData(data: TrackFromPublicSearch | undefined): SpotifyMatchedTrack | null {
    const rawId = data?.id ?? data?.uri?.split('spotify:track:')[1];
    if (!rawId || !data?.name) {
      return null;
    }
    return new SpotifyMatchedTrack({
      rawId,
      name: data.name,
      album: data.albumOfTrack?.name,
      artist: getArtistsNamesFromPublicSearchAsString(data.artists),
      imageUrl: getBiggestCovertArtFromPublicSearch(data.albumOfTrack?.coverArt),
      duration: data.duration?.totalMilliseconds,
      sourceURL: createSpotifyTrackUrl(rawId),
    });
  }
}
