import { BoomplayTracksRawResponse } from './types';
import { BoomplayCollectionTrack } from './BoomplayCollectionTrack';

export class BoomplayMySongsResponse {
  private readonly data;

  constructor(data: BoomplayTracksRawResponse | undefined) {
    this.data = data;
  }

  get tracks(): BoomplayCollectionTrack[] {
    if (!this.data?.data) {
      return [];
    }
    return this.data.data
      .map((item) => BoomplayCollectionTrack.fromData(item))
      .filter((track): track is BoomplayCollectionTrack => track !== null);
  }

  get next() {
    return this.data?.next;
  }

  get counts() {
    return this.data?.counts;
  }
}
