import React, { useCallback, useState } from 'react';
import { TypedMemo } from '../../common/types';
import { SMLCreationBoxRenderer } from './SMLCreationBoxRenderer';
import { SMLCreationModal } from '../../common/views/modals/smlCreationModal/SMLCreationModal';
import { SMLCreationBoxProps } from './props';
import { SMLAnalytics } from '../../analytics/sml/analytics';

const SMLCreationBoxComponent: React.FC<SMLCreationBoxProps> = ({ accountId }) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const openModal = useCallback(() => {
    SMLAnalytics.trackCreateStarted().catch(console.error);
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <SMLCreationModal accountId={accountId} isOpen={isModalOpen} closeModal={closeModal} />
      <SMLCreationBoxRenderer onClick={openModal} />
    </>
  );
};

export const SMLCreationBox = TypedMemo(SMLCreationBoxComponent);
