import React from 'react';
import { Button, Modal } from 'reactstrap';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { TypedMemo } from '../../../types';
import { ClickableDiv } from '../../ClickableDiv';
import { SMLModalProps } from './props';

const SMLModalComponent: React.FC<React.PropsWithChildren<SMLModalProps>> = ({
  isOpen,
  children,
  onClosed,
  onClick,
  size,
  customModal = false,
}) => {
  return (
    <Modal size={size ?? 'lg'} isOpen={isOpen} toggle={onClosed} className="sml-modal-container" centered>
      <div className="sml-modal-close">
        <Button onClick={onClosed} color="link">
          <FontAwesomeIcon icon={faTimesCircle} color="black" />
        </Button>
      </div>
      <ClickableDiv onClick={onClick} className={classNames({ 'sml-modal-content': !customModal })}>
        {children}
      </ClickableDiv>
    </Modal>
  );
};

export const SMLModal = TypedMemo(SMLModalComponent);
