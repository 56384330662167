import qs from 'qs';
import jwtDecode from 'jwt-decode';
import { defaultRetryOptions, fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { FetchError } from '../../generics/errors/FetchError';
import { GaanaPlaylistsResponse } from './models/GaanaPlaylistsResponse';
import { GaanaPlaylist } from './models/GaanaPlaylist';
import { GaanaCollectionTrack } from './models/GaanaCollectionTrack';
import { GaanaSearchResponse } from './models/GaanaSearchResponse';
import { GaanaAuthenticationData } from './GaanaAuthenticationData';
import { GaanaPlaylistWithTracksResponse } from './models/GaanaPlaylistWithTracksResponse';
import { GaanaAlbumsResponse } from './models/GaanaAlbumsResponse';
import { GaanaAlbum } from './models/GaanaAlbum';
import { GaanaAlbumWithTracksResponse } from './models/GaanaAlbumWithTracksResponse';
import { GaanaLikedTracksResponse } from './models/GaanaLikedTracksResponse';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { ImporterID } from '../types';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { Response } from '../../utils/fetch-types';

export class GaanaAPI {
  private static BASE_URL = 'https://gaana.com/api';

  public static LOGIN_URL = 'https://gaana.com/';

  public static USER_AGENT =
    'Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1';

  private readonly cookies: Record<string, any>;

  public readonly userId: string;

  private geoLocation?: string;

  constructor(cookies: Record<string, any>, userId: string) {
    this.cookies = cookies;
    this.userId = userId;
  }

  private requestHeaders(isJson = false): { [key: string]: string } {
    return {
      Accept: '*/*; charset=utf-8',
      'User-Agent':
        'Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1',
      'Content-Type': !isJson ? 'application/x-www-form-urlencoded' : 'application/json',
      Cookie: Object.keys(this.cookies)
        .map((name) => {
          return `${name}=${this.cookies[name]}`;
        })
        .join('; '),
    };
  }

  async fetch(
    url: string,
    options: any,
    retryOptions: RetryOptions = defaultRetryOptions
  ): Promise<{ jsonData: Record<string, any>; response: Response }> {
    const response = await fetchRetry(url, options, retryOptions);

    if (response.status === 401 || response.status === 403) {
      const text = await response.text();
      throw new NotAuthenticatedError({ authId: `${this.userId}`, importerId: ImporterID.Gaana }, text);
    }

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to fetch "${url}" got wrong response[${response.status}]: ${text}`
      );
    }
    const data = await response.json();
    if (typeof data.Status !== 'undefined' && data.Status < 1) {
      if (data.Error === 'Authentication failed' || data.Error === 'Username or Password missing') {
        throw new NotAuthenticatedError({ authId: `${this.userId}`, importerId: ImporterID.Gaana }, data.msg);
      }
      throw new FetchError(500, JSON.stringify(data));
    }
    return { jsonData: data, response };
  }

  async search(query: string, include: 'track' | 'album'): Promise<GaanaSearchResponse> {
    if (!query) {
      return new GaanaSearchResponse(null);
    }
    if (!this.geoLocation) {
      const { geoLocation } = await this.loadPlaylistPage();
      this.geoLocation = geoLocation ?? undefined;
    }
    const url = `${GaanaAPI.BASE_URL}v2?${qs.stringify({
      type: 'search',
      keyword: query,
      country: 'PL',
      page: 0,
      secType: include,
    })}`;

    const { jsonData } = await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders(),
    });

    return new GaanaSearchResponse(jsonData);
  }

  async loadAllPlaylistsItems(playlistId: string): Promise<GaanaPlaylistWithTracksResponse> {
    const [id, seokey] = playlistId.split('|');
    const url = `${GaanaAPI.BASE_URL}v2?${qs.stringify({
      id,
      seokey,
      type: 'playlistDetail',
    })}`;
    const { jsonData } = await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders(),
    });

    if (jsonData.playlist === null) {
      throw new CollectionDoesNotExistsError();
    }

    return new GaanaPlaylistWithTracksResponse(jsonData, this.userId);
  }

  async loadAllAlbumItems(albumId: string): Promise<GaanaAlbumWithTracksResponse> {
    const [, seokey] = albumId.split('|');
    const url = `${GaanaAPI.BASE_URL}v2?${qs.stringify({
      seokey,
      type: 'albumDetail',
    })}`;

    const { jsonData } = await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders(),
    });

    if (jsonData.error_code === '4011') {
      throw new CollectionDoesNotExistsError();
    }
    return new GaanaAlbumWithTracksResponse(jsonData);
  }

  async loadPaginatedPlaylists(
    onBatch: (collections: GaanaPlaylist[]) => Promise<void>,
    type = 'myPlaylists'
  ): Promise<void> {
    let playlistsLength = 0;
    const loadPlaylists = async (page: number): Promise<void> => {
      const playlistResponse = await this.loadPlaylistPage(page, type);
      await onBatch(playlistResponse.playlists);
      playlistsLength += playlistResponse.playlists.length;
      if (playlistsLength < playlistResponse.total && playlistResponse.playlists.length > 0) {
        await loadPlaylists(page + 1);
      }
    };

    await loadPlaylists(0);
  }

  async loadPlaylistPage(page = 0, type = 'myPlaylists'): Promise<GaanaPlaylistsResponse> {
    const { jsonData, response } = await this.fetch(`${GaanaAPI.BASE_URL}v2?${qs.stringify({ page, type })}`, {
      method: 'POST',
      headers: this.requestHeaders(),
    });

    return new GaanaPlaylistsResponse(jsonData, type, response.headers.get('X-AC') ?? '');
  }

  async loadPaginatedLikedPlaylists(onBatch: (collections: GaanaPlaylist[]) => Promise<void>): Promise<void> {
    return this.loadPaginatedPlaylists(onBatch, 'myFavPlaylists');
  }

  async loadAllLikedTracks(): Promise<GaanaCollectionTrack[]> {
    let tracks: GaanaCollectionTrack[] = [];
    const loadLikedTracks = async (page: number): Promise<void> => {
      const likedTracksResponse = await this.loadLikedTracksPage(page);
      tracks = [...tracks, ...likedTracksResponse.tracks];
      if (tracks.length < likedTracksResponse.tracksTotal && likedTracksResponse.tracks.length > 0) {
        await loadLikedTracks(page + 1);
      }
    };

    await loadLikedTracks(0);
    return tracks;
  }

  async loadLikedTracksPage(page = 0): Promise<GaanaLikedTracksResponse> {
    const { jsonData } = await this.fetch(`${GaanaAPI.BASE_URL}v2?${qs.stringify({ page, type: 'myFavSongs' })}`, {
      method: 'POST',
      headers: this.requestHeaders(),
    });

    return new GaanaLikedTracksResponse(jsonData);
  }

  async loadPaginatedAlbums(onBatch: (collections: GaanaAlbum[]) => Promise<void>): Promise<void> {
    let albumsLength = 0;
    const loadAlbums = async (page: number): Promise<void> => {
      const albumsPageResponse = await this.loadAlbumPage(page);
      albumsLength += albumsPageResponse.albums.length;
      await onBatch(albumsPageResponse.albums);
      if (albumsLength < albumsPageResponse.total && albumsPageResponse.albums.length > 0) {
        await loadAlbums(page + 1);
      }
    };

    await loadAlbums(0);
  }

  async loadAlbumPage(page = 0): Promise<GaanaAlbumsResponse> {
    const { jsonData } = await this.fetch(`${GaanaAPI.BASE_URL}v2?${qs.stringify({ page, type: 'myFavAlbums' })}`, {
      method: 'POST',
      headers: this.requestHeaders(),
    });

    return new GaanaAlbumsResponse(jsonData);
  }

  async createPlaylist(name: string): Promise<GaanaPlaylist | undefined> {
    const { jsonData } = await this.fetch(`${GaanaAPI.BASE_URL}/createPlaylist`, {
      method: 'POST',
      headers: this.requestHeaders(false),
      body: qs.stringify({
        title: name,
      }),
    });
    if (jsonData.status !== 1) {
      throw new CouldNotCreateCollection(jsonData.message);
    }
    const playlistId = jsonData.msg;
    const playlistPage = await this.loadPlaylistPage(0);
    return playlistPage.playlists.find((p) => {
      const pId = p.rawId.split('|')[0];
      return pId === playlistId;
    });
  }

  async addTracksToPlaylist(playlistId: string, trackIds: string[]): Promise<void> {
    const [id] = playlistId.split('|');
    const trackIdsString = trackIds.map((tId) => tId.split('|')[0]).join(',');
    await this.fetch(`${GaanaAPI.BASE_URL}/addTracksToPlaylist`, {
      method: 'POST',
      headers: this.requestHeaders(),
      body: qs.stringify({
        id,
        trackids: trackIdsString,
      }),
    });
  }

  async addAlbumToLibrary(albumId: string): Promise<void> {
    const [id] = albumId.split('|');
    await this.fetch(`${GaanaAPI.BASE_URL}/toggleFav`, {
      method: 'POST',
      headers: this.requestHeaders(),
      body: qs.stringify({
        id,
        status: 1,
        type: 'album',
      }),
    });
  }

  async removeTracksFromPlaylist(playlistId: string, itemsIdsToRemove: string[]): Promise<void> {
    if (itemsIdsToRemove.length === 0) {
      return;
    }
    const { playlist } = await this.loadAllPlaylistsItems(playlistId);
    if (!playlist) {
      throw new Error(`Could not fetch Gaana playlist with id:[${playlistId}]`);
    }

    const [id] = playlistId.split('|');
    const qsData = {
      id,
      title: playlist.name,
      // These are ids to be removed, not to be kept... weird but that's how they did it...
      trackids: itemsIdsToRemove.map((t) => t.split('|')[0]).join(','),
    };
    await this.fetch(`${GaanaAPI.BASE_URL}/editPlaylist`, {
      method: 'POST',
      headers: this.requestHeaders(),
      body: qs.stringify(qsData),
    });
  }

  static getAuthData(cookies: Record<string, any>, token: string): GaanaAuthenticationData {
    const { id, fullName, email, img, exp } = jwtDecode(token) as {
      id: string;
      fullName: string;
      email: string;
      img: string;
      exp: number;
    };
    return {
      authId: `${id}`,
      userUUID: null,
      title: fullName,
      subTitle: email,
      imageUrl: img,
      expiresAt: new Date(exp * 1000),
      additionalData: {
        cookies,
        token,
      },
    };
  }
}
