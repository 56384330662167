export function tryParseJSON(value: string | undefined | null) {
  if (!value) {
    return undefined;
  }
  try {
    return JSON.parse(value);
  } catch (e) {
    return undefined;
  }
}
