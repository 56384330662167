import { GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionAccess, CollectionType } from '../../../generics/models/Collection';
import { createPlaylistHref, createSpotifyPlaylistUrl } from '../utils';
import { SpotifyPlaylistResponseType } from './types';

function convertPublicToAccess(_public: boolean | undefined) {
  switch (_public) {
    case true:
      return CollectionAccess.public;
    case false:
      return CollectionAccess.private;
    case undefined:
    default:
      return undefined;
  }
}

export class SpotifyPlaylist extends GenericCollection {
  static fromData(data: SpotifyPlaylistResponseType | undefined, userId: string | undefined): SpotifyPlaylist | null {
    const {
      owner,
      images,
      id,
      uri,
      name,
      tracks,
      href,
      description,
      external_urls,
      followers,
      public: _public,
    } = data ?? {};
    const rawId = id ?? uri?.split('spotify:playlist:')[1];
    if (!rawId || !name) {
      return null;
    }
    const isUserOwner = !!(userId && owner && owner.id === userId);
    const type = isUserOwner ? CollectionType.PLAYLIST : CollectionType.LIKED_PLAYLIST;
    return new SpotifyPlaylist({
      type,
      rawId,
      name,
      itemCount: tracks?.total,
      additionalData: { href, imageUrl: images?.[0]?.url, description },
      followers: followers?.total,
      publicUrl: external_urls?.spotify ?? createSpotifyPlaylistUrl(rawId),
      access: convertPublicToAccess(_public),
    });
  }

  static fromPlaylistCreationData(data: SpotifyPlaylistResponseType | undefined): SpotifyPlaylist | null {
    const { images, id, uri, name, tracks, href, description, followers, public: _public } = data ?? {};
    const rawId = id ?? uri?.split('spotify:playlist:')[1];
    if (!rawId || !name) {
      return null;
    }
    return new SpotifyPlaylist({
      type: CollectionType.PLAYLIST,
      rawId,
      name,
      itemCount: tracks?.total,
      additionalData: { href, imageUrl: images?.[0]?.url, description },
      followers: followers?.total,
      publicUrl: undefined,
      access: convertPublicToAccess(_public),
    });
  }

  static fromPublicSearchData(data: any, playlistId: string, url: string): SpotifyPlaylist | null {
    if (!data?.name) {
      return null;
    }
    return new SpotifyPlaylist({
      type: CollectionType.LIKED_PLAYLIST,
      rawId: playlistId,
      name: data.name,
      itemCount: data.tracks?.total,
      additionalData: {
        href: createPlaylistHref(playlistId),
        imageUrl: data.images?.[0]?.url,
        description: data.description,
      },
      followers: data.followers?.total,
      publicUrl: url?.split('?')[0] ?? createSpotifyPlaylistUrl(playlistId),
      access: convertPublicToAccess(data.public),
    });
  }
}
