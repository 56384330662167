import { SoundCloudMatchedTrack } from './SoundCloudMatchedTrack';
import { SoundCloudSearchTracksRawResponse } from './responseTypes';

export class SoundCloudSearchTracksResponse {
  private readonly data;

  constructor(data: SoundCloudSearchTracksRawResponse | undefined) {
    this.data = data;
  }

  get tracks(): SoundCloudMatchedTrack[] {
    if (!this.data?.collection) {
      return [];
    }
    return this.data.collection
      .map((trackData) => SoundCloudMatchedTrack.fromData(trackData))
      .filter((track): track is NonNullable<SoundCloudMatchedTrack> => !!track);
  }

  get nextHref() {
    return this.data?.next_href;
  }
}
