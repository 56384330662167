import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { createNapsterAlbumUrl, getCoverUrlFromResourceId } from '../utils';

export class NapsterAlbum extends GenericCollection {
  constructor(data: Omit<CollectionModelData, 'type'>) {
    super({ type: CollectionType.ALBUM, ...data });
  }

  static fromData(data: any): NapsterAlbum | null {
    if (!data?.id || !data.name) {
      return null;
    }

    return new NapsterAlbum({
      rawId: data.id,
      name: data.name,
      itemCount: data.trackCount,
      additionalData: { artist: data.artistName, imageUrl: getCoverUrlFromResourceId(data.id) },
      publicUrl: createNapsterAlbumUrl(data.id),
    });
  }
}
