import React from 'react';
import { TypedMemo } from '../../common/types';
import { SmartLinksNavbar } from '../smartLinksNavbar/SmartLinksNavbar';

const SmartLinksContainerRendererComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      <SmartLinksNavbar />
      {children}
      <div id="root-bg" />
    </>
  );
};

export const SmartLinksContainerRenderer = TypedMemo(SmartLinksContainerRendererComponent);
