import React from 'react';
import { Modal } from 'reactstrap';
import { useIntl } from 'react-intl';
import { SMLCreationModalRendererProps } from './props';
import { TypedMemo } from '../../../types';
import { SMLButton } from '../../SMLButton/SMLButton';

const SMLCreationModalRendererComponent: React.FC<SMLCreationModalRendererProps> = ({
  isOpen,
  closeModal,
  onCustomSmartLinkClick,
  onPlaylistLinkClick,
  onReleaseLinkClick,
}) => {
  const intl = useIntl();

  return (
    <Modal isOpen={isOpen} toggle={closeModal} centered className="sml-modal">
      <div className="sml-modal-content p-5">
        <h3 className="text-center px-3 ui-text">
          {intl.formatMessage({
            id: 'app.sml.modals.sml_creation.title',
            defaultMessage: 'Create new Smart Link',
          })}
        </h3>
        <p className="text-center px-3 ui-text opacity-56">
          {intl.formatMessage({
            id: 'app.sml.modals.sml_creation.subtitle',
            defaultMessage: 'What is you objective for this link?',
          })}
        </p>

        <div
          className="create-smartlink create-custom-smartlink-bg"
          onClick={onCustomSmartLinkClick}
          role="button"
          aria-hidden
        >
          <div className="d-flex flex-column pe-2 flex-grow-1">
            <h5>
              {intl.formatMessage({
                id: 'app.sml.modals.sml_creation.create_custom.title',
                defaultMessage: `Custom Smart Link`,
              })}
            </h5>
            <p className="mb-0 opacity-56">
              {intl.formatMessage(
                {
                  id: 'app.sml.modals.sml_creation.create_custom.description',
                  defaultMessage: `Promote any type of content.{br}Set up your project from scratch.`,
                },
                {
                  br: <br />,
                }
              )}
            </p>
          </div>
          <SMLButton color="secondary">
            {intl.formatMessage({
              id: 'app.sml.modals.sml_creation.pick',
              defaultMessage: `Pick`,
            })}
          </SMLButton>
        </div>

        <div
          className="create-smartlink create-release-smartlink-bg"
          onClick={onReleaseLinkClick}
          role="button"
          aria-hidden
        >
          <div className="d-flex flex-column pe-2 flex-grow-1">
            <h5>
              {intl.formatMessage({
                id: 'app.sml.modals.sml_creation.release_link.title',
                defaultMessage: `Release Link`,
              })}
            </h5>
            <p className="mb-0 opacity-56">
              {intl.formatMessage(
                {
                  id: 'app.sml.modals.sml_creation.release_link.description',
                  defaultMessage: `Promote your single or album.{br}Insert the release link and we’ll do the magic.`,
                },
                {
                  br: <br />,
                }
              )}
            </p>
          </div>
          <SMLButton color="primary">
            {intl.formatMessage({
              id: 'app.sml.modals.sml_creation.pick',
              defaultMessage: `Pick`,
            })}
          </SMLButton>
        </div>

        <div
          className="create-smartlink create-playlist-smartlink-bg"
          onClick={onPlaylistLinkClick}
          role="button"
          aria-hidden
        >
          <div className="d-flex flex-column pe-2 flex-grow-1">
            <h5>
              {intl.formatMessage({
                id: 'app.sml.modals.sml_creation.playlist_link.title',
                defaultMessage: `Playlist Link`,
              })}
            </h5>
            <p className="mb-0 opacity-56">
              {intl.formatMessage(
                {
                  id: 'app.sml.modals.sml_creation.playlist_link.description',
                  defaultMessage: `Promote playlists you created on streaming services.{br}Insert the playlist link and we’ll do the magic.`,
                },
                {
                  br: <br />,
                }
              )}
            </p>
          </div>
          <SMLButton color="primary">
            {intl.formatMessage({
              id: 'app.sml.modals.sml_creation.pick',
              defaultMessage: `Pick`,
            })}
          </SMLButton>
        </div>
      </div>
    </Modal>
  );
};

export const SMLCreationModalRenderer = TypedMemo(SMLCreationModalRendererComponent);
