import React, { useEffect } from 'react';
import { TypedMemo } from '../modules/common/types';
import { useAccountId, useUserTokenDecoded } from '../redux/slices/appInfo/hooks';
import { RouterContext } from '../contexts/contexts';
import { useSMLRouter } from './sml/hooks';
import { SMLAnalytics } from '../modules/analytics/sml/analytics';
import { anonymousIdentifier } from '../modules/analytics/sml/anonymousIdentifier';

const SMLRootWrapperComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  const accountId = useAccountId();
  const userTokenDecoded = useUserTokenDecoded();

  useEffect(() => {
    anonymousIdentifier()
      .then((aId) => {
        console.log('Anonymous identity is', aId);
        SMLAnalytics.identifyAnonymous(aId).catch(console.error);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (userTokenDecoded === null || accountId === null) {
      return;
    }
    const { id, licenseId } = userTokenDecoded;
    if (licenseId === undefined) {
      return;
    }
    console.log('Updating identity to', { userId: id, accountId, licenseId });
    SMLAnalytics.sentryIdentity(id, accountId, licenseId);
    (async () => {
      const aId = await anonymousIdentifier();
      await SMLAnalytics.identify(id, licenseId, aId);
    })().catch(console.error);
  }, [accountId, userTokenDecoded]);
  return <RouterContext.Provider value={useSMLRouter}>{children}</RouterContext.Provider>;
};

export const SMLRootWrapper = TypedMemo(SMLRootWrapperComponent);
