import React from 'react';
import { TypedMemo } from '../../common/types';
import { ErrorAlert } from '../../common/views/ErrorAlert/ErrorAlert';
import { SMLOnboardingFormStep3Props } from './props';
import { useSMLOnboardingFormStep3Component } from './useSMLOnboardingFormStep3';

const SMLOnboardingFormStep3Component: React.FC<SMLOnboardingFormStep3Props> = ({
  onSkip,
  onSubmit,
  isLoading,
  error,
}) => {
  const { SocialLinksInputs, AddSocialLinkButton, SkipButton, SubmitButton, submit } =
    useSMLOnboardingFormStep3Component({
      onSkip,
      onSubmit,
      isLoading,
    });

  return (
    <>
      {error && <ErrorAlert error={error} />}
      <form onSubmit={submit}>
        <div className="sml-onboarding-form-social-links-container mb-3 pe-1">{SocialLinksInputs}</div>
        <div className="mb-3">{AddSocialLinkButton}</div>
        <div className="d-flex justify-content-between">
          {SkipButton}
          {SubmitButton}
        </div>
      </form>
    </>
  );
};

export const SMLOnboardingFormStep3 = TypedMemo(SMLOnboardingFormStep3Component);
