import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';
import { SearchResponseStatus, SearchType } from 'fym-common/src/types/searchService';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons/faCirclePlus';
import { FieldsName, FieldsType } from './consts';
import { resolver } from './validators';
import { FormInput } from '../../../../forms/components/FormInput/FormInput';
import { InputType } from '../../../../forms/types';
import { useLazyInspectByUrlQuery } from '../../../../../redux/api/musicAPI';
import { ReleaseLinkData } from '../../releaseLink/types';
import { parseSearchResultToReleaseLinkData, parseUrlToReleaseLinkData } from '../../releaseLink/utils';
import { SMLButton } from '../../../../common/views/SMLButton/SMLButton';

const defaultValues = {
  [FieldsName.releaseLink]: '',
};

interface ReleaseLinkFormProps {
  onSuccess: (releaseLinkData: ReleaseLinkData) => void;
  useMinimalistic: boolean;
}

export function useReleaseLinkForm({ onSuccess, useMinimalistic }: ReleaseLinkFormProps) {
  const intl = useIntl();
  const [inspectByUrl, { isLoading, error }] = useLazyInspectByUrlQuery();
  const { control, handleSubmit, resetField } = useForm<FieldsType>({ resolver, defaultValues });

  const onSubmit: SubmitHandler<FieldsType> = useCallback(
    async ({ releaseLink }) => {
      const inspectResult = await inspectByUrl(releaseLink);
      if (!inspectResult.isSuccess) {
        return;
      }
      let releaseLinkData: ReleaseLinkData | undefined;
      if (inspectResult.data.type === SearchType.unknown) {
        releaseLinkData = parseUrlToReleaseLinkData(releaseLink);
      } else {
        if (inspectResult.data.status !== SearchResponseStatus.success) {
          return;
        }
        releaseLinkData = parseSearchResultToReleaseLinkData(inspectResult.data);
      }
      if (!releaseLinkData) {
        return;
      }
      resetField(FieldsName.releaseLink, { defaultValue: '' });
      onSuccess(releaseLinkData);
    },
    [inspectByUrl, onSuccess, resetField]
  );

  const submit = useCallback(
    (event?: React.BaseSyntheticEvent) => {
      handleSubmit(onSubmit)(event).catch(console.error);
    },
    [handleSubmit, onSubmit]
  );

  return {
    isLoading,
    submit,
    error,
    MediaUrlInput: (
      <FormInput
        name={FieldsName.releaseLink}
        control={control}
        type={InputType.text}
        disabled={isLoading}
        label={intl.formatMessage({
          id: 'app.forms.track_link.url',
          defaultMessage: 'Track / Album URL',
        })}
        placeholder={intl.formatMessage({
          id: 'app.forms.track_link.placeholder',
          defaultMessage: 'Enter or paste URL',
        })}
        inputSuffix={
          useMinimalistic ? (
            <SMLButton leftIcon={faCirclePlus} onClick={submit}>
              {intl.formatMessage({
                id: 'app.forms.buttons.add',
                defaultMessage: 'Add',
              })}
            </SMLButton>
          ) : undefined
        }
        help={
          !useMinimalistic
            ? intl.formatMessage({
                id: 'app.forms.track_link.subtitle',
                defaultMessage: 'Grab the link of the track / album you want to user',
              })
            : undefined
        }
      />
    ),
  };
}
