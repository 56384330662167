import { YandexAlbum } from './YandexAlbum';

export class YandexAlbumsResponse {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get albums(): YandexAlbum[] {
    return this.data.result.map((item: any) => YandexAlbum.fromData(item.album));
  }
}
