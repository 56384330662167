import qs from 'qs';
import { v4 as uuid4 } from 'uuid';
import { defaultRetryOptions, fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { FetchError } from '../../generics/errors/FetchError';
import { QobuzPlaylistsResponse } from './models/QobuzPlaylistsResponse';
import { QobuzPlaylist } from './models/QobuzPlaylist';
import { QobuzCollectionTrack } from './models/QobuzCollectionTrack';
import { QobuzSearchResponse } from './models/QobuzSearchResponse';
import { QobuzAuthenticationData } from './QobuzAuthenticationData';
import { QobuzUser } from './models/QobuzUser';
import { getCookie } from '../../utils/getCookie';
import { QobuzPlaylistWithTracksResponse } from './models/QobuzPlaylistWithTracksResponse';
import { QobuzAlbumsResponse } from './models/QobuzAlbumsResponse';
import { QobuzAlbum } from './models/QobuzAlbum';
import { QobuzAlbumWithTracksResponse } from './models/QobuzAlbumWithTracksResponse';
import { ImporterID } from '../types';
import { Response } from '../../utils/fetch-types';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';

export class QobuzAPI {
  public static BASE_URL = 'https://www.qobuz.com/api.json/0.2/';

  public static X_APP_ID = '950096963';

  private readonly oAuthToken: string;

  private readonly oAuthRefreshToken: string;

  public readonly userId: number;

  public readonly qobuzSessionAws: string;

  public readonly userPlan: string;

  static getDeviceId() {
    return uuid4();
  }

  constructor(
    qobuzSessionAws: string,
    oAuthToken: string,
    oAuthRefreshToken: string,
    userId: number,
    userPlan: string
  ) {
    this.oAuthToken = oAuthToken;
    this.oAuthRefreshToken = oAuthRefreshToken;
    this.qobuzSessionAws = qobuzSessionAws;
    this.userId = userId;
    this.userPlan = userPlan;
  }

  private requestHeaders(token?: string, isFormData = false): Record<string, string> {
    return {
      Accept: '*/*; charset=utf-8',
      'Content-Type': isFormData ? 'application/x-www-form-urlencoded' : 'application/json',
      'x-user-auth-token': token ?? this.oAuthToken,
      'x-app-id': QobuzAPI.X_APP_ID,
      Cookie: `qobuz-session-aws=${this.qobuzSessionAws}`,
    };
  }

  async fetch(url: string, options: any, retryOptions: RetryOptions = defaultRetryOptions): Promise<Response> {
    const response = await fetchRetry(url, options, retryOptions);
    if (response.status === 401) {
      const text = await response.text();
      throw new NotAuthenticatedError({ authId: `${this.userId}`, importerId: ImporterID.Qobuz }, text);
    }
    return response;
  }

  async search(query: string, type: 'tracks' | 'albums'): Promise<QobuzSearchResponse> {
    if (!query) {
      return new QobuzSearchResponse(null);
    }
    const url = `${QobuzAPI.BASE_URL}catalog/search?${qs.stringify({
      query,
      zone: 'GB',
      store: 'GB-en',
      plan: this.userPlan,
      type,
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders(),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to search for ${query} on Qobuz got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    return new QobuzSearchResponse(jsonBody);
  }

  async apiLogin(useRefreshToken = false): Promise<QobuzUser> {
    const url = `${QobuzAPI.BASE_URL}user/login`;

    const response = await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders(useRefreshToken ? this.oAuthRefreshToken : this.oAuthToken),
      body: qs.stringify({
        extra: 'partner',
        device_manufacturer_id: QobuzAPI.getDeviceId(),
      }),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to apiLogin on Qobuz got wrong response[${response.status}]: ${text}`
      );
    }

    const qobuzSessionAws = getCookie(response.headers, 'qobuz-session-aws');
    const data = await response.json();
    return new QobuzUser(data.user, data.user_auth_token, qobuzSessionAws ?? '');
  }

  async loadPaginatedPlaylistsItems(
    playlistId: string,
    onBatch: (tracks: QobuzCollectionTrack[]) => Promise<void>
  ): Promise<void> {
    const limit = 100;
    const loadPlaylistsItems = async (offset: number): Promise<void> => {
      const trackResponse = await this.loadPlaylistItemPage(playlistId, offset, limit);
      await onBatch(trackResponse.tracks);
      const newOffset = offset + limit;
      if (newOffset < trackResponse.tracksTotal) {
        await loadPlaylistsItems(newOffset);
      }
    };

    await loadPlaylistsItems(0);
  }

  async loadPlaylistItemPage(playlistId: string, offset = 0, limit = 100): Promise<QobuzPlaylistWithTracksResponse> {
    const url = `${QobuzAPI.BASE_URL}playlist/get?${qs.stringify({
      offset,
      limit,
      playlist_id: playlistId,
      extra: 'tracks',
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders(),
    });

    if (response.status !== 200) {
      if (response.status === 404) {
        throw new CollectionDoesNotExistsError();
      }
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading tracks of playlist ${playlistId} in Qobuz[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new QobuzPlaylistWithTracksResponse(data, this.userId);
  }

  async loadPaginatedAlbumItems(
    albumId: string,
    onBatch: (tracks: QobuzCollectionTrack[]) => Promise<void>
  ): Promise<void> {
    const limit = 100;
    const loadAlbumItems = async (offset: number): Promise<void> => {
      const trackResponse = await this.loadAlbumItemsPage(albumId, offset, limit);
      await onBatch(trackResponse.tracks);
      const newOffset = offset + limit;
      if (newOffset < trackResponse.tracksTotal) {
        await loadAlbumItems(newOffset);
      }
    };

    await loadAlbumItems(0);
  }

  async loadAlbumItemsPage(albumId: string, offset = 0, limit = 100): Promise<QobuzAlbumWithTracksResponse> {
    const url = `${QobuzAPI.BASE_URL}album/get?${qs.stringify({
      offset,
      limit,
      album_id: albumId,
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders(),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading tracks of album ${albumId} in Qobuz[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new QobuzAlbumWithTracksResponse(data);
  }

  async loadPaginatedPlaylists(onBatch: (collections: QobuzPlaylist[]) => Promise<void>): Promise<void> {
    const limit = 50;
    const loadPlaylists = async (offset: number): Promise<void> => {
      const playlistResponse = await this.loadPlaylistPage(offset, limit);
      await onBatch(playlistResponse.playlists);
      const newOffset = offset + limit;
      if (newOffset < playlistResponse.total) {
        await loadPlaylists(newOffset);
      }
    };

    await loadPlaylists(0);
  }

  async loadPlaylistPage(offset = 0, limit = 100): Promise<QobuzPlaylistsResponse> {
    const url = `${QobuzAPI.BASE_URL}playlist/getUserPlaylists?${qs.stringify({
      offset,
      limit,
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders(),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading playlists in Qobuz[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new QobuzPlaylistsResponse(data, this.userId);
  }

  async loadPaginatedAlbums(onBatch: (collections: QobuzAlbum[]) => Promise<void>): Promise<void> {
    const limit = 50;
    const loadAlbums = async (offset: number): Promise<void> => {
      const albumsPageResponse = await this.loadAlbumPage(offset, limit);
      await onBatch(albumsPageResponse.albums);
      const newOffset = offset + limit;
      if (newOffset < albumsPageResponse.total) {
        await loadAlbums(newOffset);
      }
    };

    await loadAlbums(0);
  }

  async loadAlbumPage(offset = 0, limit = 100): Promise<QobuzAlbumsResponse> {
    const url = `${QobuzAPI.BASE_URL}favorite/getUserFavorites?${qs.stringify({
      type: 'albums',
      offset,
      limit,
      user_id: this.userId,
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders(),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading albums in Qobuz[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new QobuzAlbumsResponse(data);
  }

  async createPlaylist(name: string): Promise<QobuzPlaylist | null> {
    const qsData = {
      name,
      description: '',
      is_public: false,
      is_collaborative: false,
    };
    const parsedData = qs.stringify(qsData);
    const url = `${QobuzAPI.BASE_URL}playlist/create?${parsedData}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders(),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to create playlist (${parsedData}) on Qobuz got wrong response[${response.status}]: ${text}`
      );
    }
    const data = await response.json();
    return QobuzPlaylist.fromData(data, this.userId);
  }

  async updatePlaylist(playlistId: string, isPublic: boolean): Promise<QobuzPlaylist | null> {
    const url = `${QobuzAPI.BASE_URL}playlist/update`;
    const formData = qs.stringify({
      playlist_id: playlistId,
      is_public: isPublic,
    });
    const response = await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders(undefined, true),
      body: formData,
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to update playlist (${formData}) on Qobuz got wrong response[${response.status}]: ${text}`
      );
    }
    const data = await response.json();
    return QobuzPlaylist.fromData(data, this.userId);
  }

  async addTracksToPlaylist(playlistId: string, trackIds: string[]): Promise<void> {
    const qsData = {
      playlist_id: playlistId,
      track_ids: trackIds.join(','),
    };
    const parsedData = qs.stringify(qsData);
    const url = `${QobuzAPI.BASE_URL}playlist/addTracks?${parsedData}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders(),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to add tracks (${parsedData}) to Qobuz for playlist [${playlistId}] got wrong response[${response.status}]: ${text}`
      );
    }
  }

  async addAlbumToLibrary(albumId: string): Promise<void> {
    const url = `${QobuzAPI.BASE_URL}favorite/create?${qs.stringify({
      album_ids: albumId,
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders(),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to add album (${albumId}) to library in Qobuz, got wrong response[${response.status}]: ${text}`
      );
    }
  }

  async removeTracksFromPlaylist(playlistId: string, playlistTracksIds: string[]): Promise<void> {
    if (playlistTracksIds.length === 0) {
      return;
    }
    const qsData = {
      playlist_id: playlistId,
      playlist_track_ids: playlistTracksIds.join(','),
    };
    const parsedData = qs.stringify(qsData);
    const url = `${QobuzAPI.BASE_URL}playlist/deleteTracks?${parsedData}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders(),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to remove tracks (${parsedData}) from Qobuz for playlist [${playlistId}] got wrong response[${response.status}]: ${text}`
      );
    }
  }

  getAuthData(user: QobuzUser): QobuzAuthenticationData {
    return {
      authId: `${user.id}`,
      userUUID: null,
      title: user.name,
      subTitle: user.email,
      imageUrl: user.avatarUrl,
      expiresAt: null,
      additionalData: {
        oAuthToken: this.oAuthToken,
        oAuthRefreshToken: this.oAuthRefreshToken,
        qobuzSessionAws: this.qobuzSessionAws,
        userPlan: user.plan,
      },
    };
  }

  static async login(username: string, password: string): Promise<QobuzUser> {
    const response = await fetchRetry(`${QobuzAPI.BASE_URL}user/login`, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'x-app-id': QobuzAPI.X_APP_ID,
        referrer: 'https://play.qobuz.com/login',
        'referrer-policy': 'no-referrer-when-downgrade',
      },
      body: qs.stringify({
        username,
        email: username,
        password,
        extra: 'partner',
        device_manufacturer_id: QobuzAPI.getDeviceId(),
      }),
      method: 'POST',
    });
    if (response.status !== 200) {
      throw new Error(`Could not login [${response.status}]: ${await response.text()}`);
    }
    const qobuzSessionAws = getCookie(response.headers, 'qobuz-session-aws');
    const data = await response.json();
    return new QobuzUser(data.user, data.user_auth_token, qobuzSessionAws ?? '');
  }
}
