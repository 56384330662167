import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../../common/types';
import { SMLSetUpContainer } from '../smlSetUpContainer/SMLSetUpContainer';
import { useRemindPasswordForm } from './RemindPasswordForm/useRemindPasswordForm';
import { Alert } from '../../forms/components/Alert/Alert';
import { AlertLevel } from '../../forms/components/Alert/props';
import { boldIntlText } from '../../common/views/bold/boldIntlText';
import { SMLRemindPasswordViewRendererProps } from './props';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';

const SMLRemindPasswordViewRendererComponent: React.FC<SMLRemindPasswordViewRendererProps> = ({ goToLogInView }) => {
  const intl = useIntl();
  const { EmailInput, SubmitButton, submit, ErrorView, isSuccessful } = useRemindPasswordForm();

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      goToLogInView();
    },
    [goToLogInView]
  );

  const LinkIntl = useCallback(
    (str: React.ReactNode) => (
      <a href={`/${SMLRoutes.login}`} onClick={onClick} className="sml-sing-up-link font-weight-500">
        {str}
      </a>
    ),
    [onClick]
  );

  return (
    <SMLSetUpContainer
      title={intl.formatMessage({ id: 'app.sml.remind_password.title', defaultMessage: 'Password Reminder' })}
    >
      <div className="d-flex justify-content-center">
        <span className="sml-set-up-text ui-text text-center mb-3">
          {intl.formatMessage({
            id: 'app.sml.remind_password.description',
            defaultMessage: `Enter your email address and we will send you password reminder to your mailbox.`,
          })}
        </span>
      </div>
      {isSuccessful && (
        <div className="mb-3">
          <Alert level={AlertLevel.success}>
            <p className="mb-0">
              {intl.formatMessage(
                {
                  id: 'app.sml.remind_password.success',
                  defaultMessage: `<b>Check your mailbox!</b>{br}If the account with this email address exists you will get an email.`,
                },
                {
                  b: boldIntlText,
                  br: <br />,
                  link: LinkIntl,
                }
              )}
            </p>
          </Alert>
        </div>
      )}
      {ErrorView}
      <form onSubmit={submit}>
        {EmailInput}
        <div className="mt-5">{SubmitButton}</div>
      </form>
      <div className="d-flex justify-content-center">
        <span className="sml-set-up-text ui-text text-center m-3">
          {intl.formatMessage(
            {
              id: 'app.sml.sign_up.go_to_log_in',
              defaultMessage: `Already have an account? <link>Log In</link>`,
            },
            {
              link: LinkIntl,
            }
          )}
        </span>
      </div>
    </SMLSetUpContainer>
  );
};

export const SMLRemindPasswordViewRenderer = TypedMemo(SMLRemindPasswordViewRendererComponent);
