import { YandexAlbum } from './YandexAlbum';
import { YandexMatchedTrack } from './YandexMatchedTrack';

const filterAvailableItems = (item: any) => item.available || item.availableForPremiumUsers;

export class YandexSearchResponse {
  private readonly data;

  constructor(data: Record<string, any> | null) {
    this.data = data;
  }

  get tracks(): YandexMatchedTrack[] {
    if (!this.data?.tracks?.results) {
      return [];
    }
    return this.data.tracks.results.filter(filterAvailableItems).map((item: any) => YandexMatchedTrack.fromData(item));
  }

  get albums(): YandexAlbum[] {
    if (!this.data?.albums?.results) {
      return [];
    }
    return this.data.albums.results.filter(filterAvailableItems).map((item: any) => YandexAlbum.fromData(item));
  }

  get firstTrack(): YandexMatchedTrack | undefined {
    if (this.tracks.length === 0) {
      return undefined;
    }
    return this.tracks[0];
  }

  get firstAlbum(): YandexAlbum | undefined {
    if (this.albums.length === 0) {
      return undefined;
    }
    return this.albums[0];
  }
}
