interface UserProfileResponse {
  user_id?: string;
  name?: string;
  email?: string;
}

export class AmazonMusicUserProfileResponse {
  private readonly data;

  constructor(data: UserProfileResponse | undefined) {
    this.data = data;
  }

  get profileID() {
    return this.data?.user_id;
  }

  get name() {
    return this.data?.name;
  }

  get email() {
    return this.data?.email;
  }
}
