import React from 'react';
import classNames from 'classnames';
import { Alert as AlertBS } from 'reactstrap';
import { TypedMemo } from '../../../common/types';
import { AlertLevel, AlertProps } from './props';

function levelToColor(level: AlertLevel) {
  switch (level) {
    case AlertLevel.success:
      return 'success';
    case AlertLevel.info:
      return 'info';
    case AlertLevel.dark:
      return 'dark';
    case AlertLevel.warning:
      return 'warning';
    case AlertLevel.danger:
    default:
      return 'danger';
  }
}

const AlertComponent: React.FC<React.PropsWithChildren<AlertProps>> = ({ center, children, level }) => {
  return (
    <AlertBS
      style={{ zIndex: 100 }}
      color={levelToColor(level)}
      className={classNames('d-flex mb-0', {
        'justify-content-center': center,
        'justify-content-start': center !== true,
        'text-center': center,
      })}
    >
      {children}
    </AlertBS>
  );
};

export const Alert = TypedMemo(AlertComponent);
