import { SpotifyAlbum } from './SpotifyAlbum';
import { SpotifyAlbumsResponseType } from './types';

export class SpotifyAlbumsResponse {
  private readonly data;

  constructor(data: SpotifyAlbumsResponseType) {
    this.data = data;
  }

  get albums(): SpotifyAlbum[] {
    if (!this.data?.items) {
      return [];
    }
    return this.data.items
      .map((item) => SpotifyAlbum.fromData(item.album))
      .filter((album): album is SpotifyAlbum => !!album);
  }

  get next() {
    return this.data.next;
  }
}
