import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import URL from 'url-parse';
import { LinkType, recognizeLinkTypeByUrl } from 'fym-common/src/utils/recognizeLinkTypeByUrl';
import { SerializedLinkWidget } from 'fym-common/src/db/serializersTypes';
import { TypedMemo } from '../../common/types';
import { FieldsName, FieldsType } from './consts';
import { FormInput } from '../components/FormInput/FormInput';
import { Spacer } from '../../common/views/spacer/Spacer';
import { resolver } from './validators';
import { SMLFormContainer } from '../../smartLinks/SMLFormContainer/SMLFormContainer';
import { InputType } from '../types';
import { ErrorAPIResult } from '../../../redux/api/utils/isSuccessfulResult';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { useGetSmartLinkQuery, useGetSmartLinksQuery } from '../../../redux/api/fymAPI';
import { SMLListChoiceSmartLinkView } from '../../smartLinks/smlListView/SMLListChoiceSmartLinkView';
import { TextLink } from '../../common/views/textLink/TextLink';

export interface LinkWidgetFormProps {
  widget?: SerializedLinkWidget | null;
  onSubmit: SubmitHandler<FieldsType>;
  onDelete: () => void;
  title: string;
  isLoading?: boolean;
  error?: ErrorAPIResult['error'];
  accountId: number;
  smartLinkId: number;
}

const LinkWidgetFormComponent: React.FC<LinkWidgetFormProps> = ({
  widget,
  onSubmit,
  onDelete,
  title,
  isLoading = false,
  error,
  accountId,
  smartLinkId,
}) => {
  const intl = useIntl();
  const [isChoiceSmartLink, setIsChoiceSmartLink] = useState<boolean>(false);
  const [selectedSmartLinkId, setSelectedSmartLinkId] = useState<number | undefined>(undefined);
  const [isLabelVisible, setIsLabelVisible] = useState<boolean>(false);
  const {
    data: smartLinksResponse,
    isLoading: smartLinksLoading,
    error: smartLinksError,
  } = useGetSmartLinksQuery({ accountId, page: 0, limit: 2 });
  const hasMoreSmartLinks = !!smartLinksResponse && smartLinksResponse.totalItems > 1;

  const { smartLink, getSmartLinkError, isGetSmartLinkLoading } = useGetSmartLinkQuery(
    { accountId, smartLinkId: selectedSmartLinkId ?? 0 },
    {
      skip: selectedSmartLinkId === undefined,
      selectFromResult: (result) => ({
        smartLink: result.data?.smartLink,
        isGetSmartLinkLoading: result.isLoading,
        getSmartLinkError: result.error,
      }),
    }
  );
  const { control, handleSubmit, reset, setValue, watch } = useForm<FieldsType>({
    resolver,
    defaultValues: {
      [FieldsName.url]: widget?.data.url ?? '',
      [FieldsName.label]: widget?.data.label ?? '',
    },
  });

  const submit = useCallback(
    (event?: React.BaseSyntheticEvent) => {
      handleSubmit(onSubmit)(event).catch(console.error);
    },
    [handleSubmit, onSubmit]
  );

  const { shareUrl, publishedSmartLinkVersion } = smartLink ?? {};
  const watchedUrlField = watch(FieldsName.url);
  const recognizedUrl = useMemo(() => recognizeLinkTypeByUrl(new URL(watchedUrlField)), [watchedUrlField]);

  useEffect(() => {
    reset({
      [FieldsName.url]: (shareUrl || widget?.data.url) ?? '',
      [FieldsName.label]: (shareUrl ? publishedSmartLinkVersion?.name : widget?.data.label) ?? '',
      [FieldsName.smartLinkId]: smartLinkId,
    });
  }, [publishedSmartLinkVersion?.name, reset, setValue, shareUrl, smartLinkId, widget?.data.label, widget?.data.url]);

  useEffect(() => {
    if (watchedUrlField !== '' && recognizedUrl === LinkType.generic) {
      setIsLabelVisible(true);
    }
  }, [recognizedUrl, watchedUrlField]);

  const showSMLListView = useCallback(() => {
    setIsChoiceSmartLink(true);
  }, []);

  const onSmartLinkSelected = useCallback((id: number) => {
    setSelectedSmartLinkId(id);
    setIsChoiceSmartLink(false);
  }, []);

  const backRouteParams = useMemo(() => ({ accountId, smartLinkId }), [accountId, smartLinkId]);
  return (
    <SMLFormContainer
      backRoute={SMLRoutes.details}
      backRouteParams={backRouteParams}
      title={title}
      onSubmit={submit}
      submitDisabled={isChoiceSmartLink}
      onDelete={widget ? onDelete : undefined}
      isLoading={isLoading || isGetSmartLinkLoading || smartLinksLoading}
      error={error || getSmartLinkError || smartLinksError}
    >
      {!isChoiceSmartLink ? (
        <>
          <FormInput
            name={FieldsName.url}
            control={control}
            type={InputType.text}
            disabled={isLoading || shareUrl !== undefined}
            label={intl.formatMessage({
              id: 'app.forms.link_widget.url',
              defaultMessage: 'URL',
            })}
            readonly={shareUrl !== undefined}
          />
          {isLabelVisible ? (
            <FormInput
              name={FieldsName.label}
              control={control}
              type={InputType.text}
              disabled={isLoading}
              label={intl.formatMessage({
                id: 'app.forms.link_widget.label',
                defaultMessage: 'Label',
              })}
            />
          ) : null}
          <Spacer marginBottom={20} />
          {hasMoreSmartLinks && (
            <TextLink onClick={showSMLListView}>
              {intl.formatMessage({
                id: 'app.forms.link_widget.smartlink',
                defaultMessage: 'I want to link my other smartlink',
              })}
            </TextLink>
          )}
        </>
      ) : (
        <SMLListChoiceSmartLinkView smartLinkId={smartLinkId} onSmartLinkSelected={onSmartLinkSelected} />
      )}
    </SMLFormContainer>
  );
};

export const LinkWidgetForm = TypedMemo(LinkWidgetFormComponent);
