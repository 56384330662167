import { QobuzMatchedTrack } from './QobuzMatchedTrack';
import { QobuzAlbum } from './QobuzAlbum';

export class QobuzSearchResponse {
  private readonly data;

  constructor(data: Record<string, any> | null) {
    this.data = data;
  }

  get tracks(): QobuzMatchedTrack[] {
    if (!this.data?.tracks?.items) {
      return [];
    }
    return this.data.tracks.items
      .map((item: any) => QobuzMatchedTrack.fromData(item))
      .filter((track: QobuzMatchedTrack | null) => !!track);
  }

  get albums(): QobuzAlbum[] {
    if (!this.data?.albums?.items) {
      return [];
    }
    return this.data.albums.items
      .map((item: any) => QobuzAlbum.fromData(item))
      .filter((album: QobuzAlbum | null) => !!album);
  }

  get firstTrack(): QobuzMatchedTrack | undefined {
    if (this.tracks.length === 0) {
      return undefined;
    }
    return this.tracks[0];
  }

  get firstAlbum(): QobuzAlbum | undefined {
    if (this.albums.length === 0) {
      return undefined;
    }
    return this.albums[0];
  }
}
