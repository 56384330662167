import { JioSaavnPlaylist } from './JioSaavnPlaylist';

export class JioSaavnPlaylistsResponse {
  private readonly data: any;

  private readonly userId: string;

  constructor(data: any, userId: string) {
    this.data = data;
    this.userId = userId;
  }

  get playlists(): JioSaavnPlaylist[] {
    if (!this.data) {
      return [];
    }
    return this.data
      .map((item: any) => JioSaavnPlaylist.fromData(item, this.userId))
      .filter((playlist: JioSaavnPlaylist | null) => !!playlist);
  }
}
