import { SoundClodTrackRawResponse } from '../models/responseTypes';
import { isFalsy } from '../../../utils/isFalsy';
import { validateISRC } from '../../../utils/validateISRC';

export function convertSoundCloudTrackData(data: SoundClodTrackRawResponse | undefined, url?: string) {
  if (!data || isFalsy(data?.id) || !data.title) {
    return null;
  }
  return {
    rawId: `${data.id}`,
    name: data.title,
    isrc: validateISRC(data.isrc),
    imageUrl: data.artwork_url,
    duration: data.duration,
    sourceURL: data.permalink_url ?? url,
    artist: data.user?.username,
  };
}
