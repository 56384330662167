import { MusicService } from './types';
import { ImporterID } from '../../importers/types';
import { MusicAPIIntegrationID } from '../types';
import { LinkType } from '../../utils/recognizeLinkTypeByUrl';

export const qqMusic: MusicService = {
  id: 'qq-music',
  importerID: ImporterID.QQMusic,
  linkType: LinkType.qqmusic,
  musicAPIID: MusicAPIIntegrationID.QQMusic,
  shortName: 'QQ音乐',
  name: 'QQ Music (QQ 音乐)',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: false,
  color: { label: '#F8D124', background: '#FFF1B0' },
};
