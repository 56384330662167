import qs from 'qs';
import { defaultRetryOptions, FetchRequestInit, fetchRetry } from '../../utils/fetch-retry';
import { DeezerUser } from './models/DeezerUser';
import { DeezerPlaylist } from './models/DeezerPlaylist';
import { DeezerAlbumsResponse } from './models/DeezerAlbumsResponse';
import { DeezerAlbumResponse } from './models/DeezerAlbumResponse';
import { DeezerTracksResponse } from './models/DeezerTracksResponse';
import { DeezerCollectionTrack } from './models/DeezerCollectionTrack';
import { SearchQueryProperties } from '../../generics/types';
import { constructSearchQuery } from './utils';
import { DeezerPlaylistsResponse } from './models/DeezerPlaylistsResponse';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { FetchError } from '../../generics/errors/FetchError';
import { DeezerErrorCodes } from './consts';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { ImporterID } from '../types';
import { DeezerSearchResponse } from './models/DeezerSearchResponse';
import { DeezerAlbum } from './models/DeezerAlbum';

export class DeezerAPI {
  public static readonly BASE_URL = 'https://api.deezer.com/';

  public userId;

  public accessToken;

  constructor(props: { accessToken: string; userId: string }) {
    this.accessToken = props.accessToken;
    this.userId = props.userId;
  }

  private static get requestHeaders() {
    return {
      Accept: 'application/json',
      'content-type': 'application/json; charset=utf-8',
    };
  }

  private static handleError(error: { type?: string; message?: string; code?: number }, userId: string | undefined) {
    const { message, code } = error;
    switch (code) {
      case DeezerErrorCodes.tokenInvalid:
        throw new NotAuthenticatedError({ authId: `${userId}`, importerId: ImporterID.Deezer }, message);
      case DeezerErrorCodes.dataNotFound:
        throw new CollectionDoesNotExistsError();
      default:
        throw new FetchError(code, message);
    }
  }

  private async fetch(pathname: string, params: Record<string, any> = {}): Promise<Record<string, any>> {
    const url = `${DeezerAPI.BASE_URL}${pathname}?${qs.stringify({
      access_token: this.accessToken,
      output: 'json',
      ...params,
    })}`;
    const options: FetchRequestInit = {
      method: 'GET',
      headers: DeezerAPI.requestHeaders,
    };
    const response = await fetchRetry(url, options);
    const data = await response.json();
    if (data.error) {
      DeezerAPI.handleError(data.error, this.userId);
    }
    return data;
  }

  public static async fetchUser(props: { accessToken: string; forceWaitForRateLimits: boolean }): Promise<DeezerUser> {
    const { accessToken, forceWaitForRateLimits = defaultRetryOptions.shouldWait } = props;
    const url = `${DeezerAPI.BASE_URL}user/me?${qs.stringify({ access_token: accessToken, output: 'json' })}`;
    const response = await fetchRetry(
      url,
      {
        method: 'GET',
        headers: DeezerAPI.requestHeaders,
      },
      { ...defaultRetryOptions, shouldWait: forceWaitForRateLimits }
    );
    const data = await response.json();
    if (data.error) {
      DeezerAPI.handleError(data.error, undefined);
    }
    return new DeezerUser(data);
  }

  public async loadPaginatedPlaylists(onBatch: (playlists: DeezerPlaylist[]) => Promise<void>): Promise<void> {
    const limit = 50;
    const loadPlaylists = async (offset: number): Promise<void> => {
      const { playlists, next } = await this.loadPlaylistsPage(offset, limit);
      await onBatch(playlists);
      if (next) {
        await loadPlaylists(offset + limit);
      }
    };
    await loadPlaylists(0);
  }

  public async loadPlaylistsPage(offset: number, limit: number): Promise<DeezerPlaylistsResponse> {
    const data = await this.fetch('user/me/playlists', {
      index: offset,
      limit,
    });
    return new DeezerPlaylistsResponse(data, this.userId);
  }

  public async loadPlaylist(playlistId: string): Promise<DeezerPlaylist | null> {
    const data = await this.fetch(`playlist/${playlistId}`);
    return DeezerPlaylist.fromData(data, this.userId);
  }

  public async loadPaginatedPlaylistItems(
    playlistId: string,
    onBatch: (tracks: DeezerCollectionTrack[]) => Promise<void>
  ): Promise<void> {
    const limit = 50;
    const loadItems = async (offset: number): Promise<void> => {
      const { tracks, next } = await this.loadPlaylistItemsPage(playlistId, offset, limit);
      await onBatch(tracks);
      if (next) {
        await loadItems(offset + limit);
      }
    };
    await loadItems(0);
  }

  public async loadPlaylistItemsPage(playlistId: string, offset: number, limit: number): Promise<DeezerTracksResponse> {
    const data = await this.fetch(`playlist/${playlistId}/tracks`, {
      index: offset,
      limit,
    });
    return new DeezerTracksResponse(data);
  }

  public async loadPaginatedMySongs(onBatch: (tracks: DeezerCollectionTrack[]) => Promise<void>): Promise<void> {
    const limit = 50;
    const loadItems = async (offset: number): Promise<void> => {
      const { tracks, next } = await this.loadMySongsPage(offset, limit);
      await onBatch(tracks);
      if (next) {
        await loadItems(offset + limit);
      }
    };
    await loadItems(0);
  }

  public async loadMySongsPage(offset: number, limit: number) {
    const data = await this.fetch(`user/me/tracks`, {
      index: offset,
      limit,
    });
    return new DeezerTracksResponse(data);
  }

  public async loadPaginatedAlbums(onBatch: (albums: DeezerAlbum[]) => Promise<void>): Promise<void> {
    const limit = 50;
    const loadAlbums = async (offset: number): Promise<void> => {
      const { albums, next } = await this.loadAlbumsPage(offset, limit);
      await onBatch(albums);
      if (next) {
        await loadAlbums(offset + limit);
      }
    };
    await loadAlbums(0);
  }

  public async loadAlbumsPage(offset: number, limit: number): Promise<DeezerAlbumsResponse> {
    const data = await this.fetch(`user/me/albums`, {
      index: offset,
      limit,
    });
    return new DeezerAlbumsResponse(data);
  }

  public async loadAlbum(albumId: string): Promise<DeezerAlbumResponse> {
    const data = await this.fetch(`album/${albumId}`);
    return new DeezerAlbumResponse(data);
  }

  public async createPlaylist(title: string, description?: string): Promise<string | undefined> {
    const playlist = await this.fetch(`user/me/playlists`, {
      request_method: 'post',
      title,
    });
    const playlistId = playlist?.id?.toString();
    if (description && playlistId) {
      /*
       * TODO - workaround for setting description (maybe there is better way to do it)
       * Setting description during playlist's creation is not working (API docs doesn't say how can we do it, I tried in many ways without success).
       * So in description exists, we update that playlist just after creation
       * */
      try {
        await this.updatePlaylist(playlistId, { description });
      } catch (err) {
        if (err instanceof NotAuthenticatedError) {
          throw err;
        }
        // In case of any other error do nothing as it is only a try to set description
        console.error(err);
      }
    }
    return playlistId;
  }

  public async updatePlaylist(playlistId: string, props: { title?: string; description?: string }) {
    const { title, description } = props;
    await this.fetch(`playlist/${playlistId}`, {
      request_method: 'post',
      title,
      description,
      // public: false, // Setting playlist access is possible, but if you set public to `false` then the playlist cannot be editable on web browser even be the owner, and all tracks are hidden.
    });
  }

  public async removePlaylist(playlistId: string) {
    await this.fetch(`playlist/${playlistId}`, {
      request_method: 'delete',
      playlist_id: playlistId,
    });
  }

  async addAlbumToLibrary(albumId: string): Promise<void> {
    await this.fetch(`user/me/albums`, {
      request_method: 'post',
      album_id: albumId,
    });
  }

  public async search(props: {
    queryProps: SearchQueryProperties;
    type: 'track' | 'album';
    advancedSearch?: boolean;
    limit?: number;
    offset?: number;
  }): Promise<DeezerSearchResponse> {
    const { queryProps, type, advancedSearch = true, limit = 10, offset = 0 } = props;
    const query = constructSearchQuery(queryProps, advancedSearch);
    if (!query) {
      return new DeezerSearchResponse(null, type);
    }
    const data = await this.fetch(`search/${type}`, {
      q: query,
      limit,
      index: offset,
    });
    return new DeezerSearchResponse(data, type);
  }

  private async updateTracksInPlaylist(
    playlistId: string,
    trackIds: string[],
    action: 'add' | 'remove' | 'move'
  ): Promise<void> {
    if (trackIds.length === 0) {
      return;
    }
    const method = action === 'remove' ? 'DELETE' : 'POST';
    const property = action === 'move' ? 'order' : 'songs';
    await this.fetch(`playlist/${playlistId}/tracks`, {
      request_method: method,
      [property]: trackIds.join(','),
    });
  }

  public async addTracksToPlaylist(playlistId: string, trackIds: string[]): Promise<void> {
    await this.updateTracksInPlaylist(playlistId, trackIds, 'add');
  }

  public async removeTracksFromPlaylist(playlistId: string, trackIds: string[]): Promise<void> {
    await this.updateTracksInPlaylist(playlistId, trackIds, 'remove');
  }

  public async moveTracksInPlaylist(playlistId: string, trackIds: string[]): Promise<void> {
    await this.updateTracksInPlaylist(playlistId, trackIds, 'move');
  }
}
