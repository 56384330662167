import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ImporterID } from 'fym-common/src/importers/types';
import { StatsState } from './state';
import { statsSliceName } from './constants';
import { Analytics } from '../../../modules/analytics/fym/analytics';
import { ImporterAuthenticationModel } from '../../../realm/models/ImporterAuthenticationModel';
import { CollectionModel } from '../../../realm/models/CollectionModel';
import { CollectionPickerTabType } from '../../../modules/collectionsPicker/views/tabs/props';

type SelectImporterAuthDataPayload = {
  importerId: ImporterID;
  authModelUniqueIdString?: ReturnType<ImporterAuthenticationModel['_id']['toHexString']>;
};
type ToggleCollectionModelIdPayload = {
  collectionModelId: ReturnType<CollectionModel['_id']['toHexString']>;
};
type ToggleManyCollectionModelIdsPayload = {
  collectionModelIds: ReturnType<CollectionModel['_id']['toHexString']>[];
};
type SelectCollectionTabTypePayload = {
  collectionTabType: CollectionPickerTabType | null;
};

const statsSlice = createSlice({
  name: statsSliceName,
  initialState: StatsState,
  reducers: {
    resetStatsState: () => StatsState,
    selectImporterAuthData: (state, action: PayloadAction<SelectImporterAuthDataPayload>) => {
      const { importerId, authModelUniqueIdString } = action.payload;
      Analytics.trackSourceServiceSelected(importerId).catch(console.error);
      state.selectedImporterId = importerId;
      state.authModelUniqueIdString = authModelUniqueIdString;
      // We clear set when setting importer or auth model
      state.selectedCollectionIds = [];
    },
    toggleCollectionModelId: (state, action: PayloadAction<ToggleCollectionModelIdPayload>) => {
      const { collectionModelId } = action.payload;
      const selectedCollectionIds = new Set(state.selectedCollectionIds);
      if (selectedCollectionIds.has(collectionModelId)) {
        selectedCollectionIds.delete(collectionModelId);
      } else {
        selectedCollectionIds.add(collectionModelId);
      }
      state.selectedCollectionIds = Array.from(selectedCollectionIds);
    },
    toggleManyCollectionModelIds: (state, action: PayloadAction<ToggleManyCollectionModelIdsPayload>) => {
      const { collectionModelIds } = action.payload;
      const areAllSelected = collectionModelIds.every((collectionId) =>
        state.selectedCollectionIds.includes(collectionId)
      );
      if (areAllSelected) {
        state.selectedCollectionIds = state.selectedCollectionIds.filter(
          (collectionId) => !collectionModelIds.includes(collectionId)
        );
      } else {
        state.selectedCollectionIds = Array.from(new Set([...state.selectedCollectionIds, ...collectionModelIds]));
      }
    },
    updateManyCollectionModelIds: (state, action: PayloadAction<ToggleManyCollectionModelIdsPayload>) => {
      const { collectionModelIds } = action.payload;
      state.selectedCollectionIds = Array.from(new Set(collectionModelIds));
    },
    selectCollectionTabType: (state, action: PayloadAction<SelectCollectionTabTypePayload>) => {
      const { collectionTabType } = action.payload;
      state.selectedCollectionTabType = collectionTabType;
    },
  },
});

const { actions, reducer } = statsSlice;
export { reducer as statsReducer };
export const {
  resetStatsState,
  selectImporterAuthData,
  toggleCollectionModelId,
  selectCollectionTabType,
  toggleManyCollectionModelIds,
  updateManyCollectionModelIds,
} = actions;
