import { GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { ImporterID } from '../types';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { BoomplayAPI } from './BoomplayAPI';
import { BoomplayPlaylist } from './models/BoomplayPlaylist';
import { BoomplayAlbum } from './models/BoomplayAlbum';
import { BoomplayMySongs } from './models/BoomplayMySongs';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { CollectionAccess, CollectionType } from '../../generics/models/Collection';
import { FetchError } from '../../generics/errors/FetchError';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { SearchQueryProperties } from '../../generics/types';
import { boomplay } from '../../musicServices/services/Boomplay';
import { refreshAuthData } from '../../musicApi/utils/refreshAuthData';
import { MusicAPIIntegrationID } from '../../musicServices/types';
import { BoomplayMatchedTrack } from './models/BoomplayMatchedTrack';

export const BoomplayImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.Boomplay;

  public static musicService = boomplay;

  public authenticationData: GenericAuthenticationData;

  private boomplayApi: BoomplayAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData;
    this.boomplayApi = new BoomplayAPI(
      authenticationData.additionalData?.accessToken as string,
      authenticationData.authId
    );
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.boomplayApi = new BoomplayAPI(
      authenticationData.additionalData?.accessToken as string,
      authenticationData.authId
    );
  }

  async getPaginatedCollections(
    onBatch: (collections: (BoomplayMySongs | BoomplayPlaylist | BoomplayAlbum)[]) => Promise<void>
  ): Promise<void> {
    const { counts } = await this.boomplayApi.loadMySongsPage(0, 1);
    const mySongs = new BoomplayMySongs(this.authenticationData.authId, counts);
    await onBatch([mySongs]);
    await this.boomplayApi.loadPaginatedLikedPlaylists(onBatch);
    await this.boomplayApi.loadPaginatedOwnPlaylists(onBatch);
    await this.boomplayApi.loadPaginatedAlbums(onBatch);
  }

  async getCollection(collection: GenericCollection): Promise<BoomplayPlaylist | BoomplayAlbum> {
    let result: BoomplayPlaylist | BoomplayAlbum | null;
    if (collection.type === CollectionType.ALBUM) {
      result = await this.boomplayApi.loadAlbum(collection.rawId);
    } else {
      result = await this.boomplayApi.loadPlaylist(collection.rawId);
    }

    if (!result) {
      throw new CollectionDoesNotExistsError();
    }
    return result;
  }

  async createCollection(collection: GenericCollection, description?: string): Promise<BoomplayPlaylist> {
    let newCollection: BoomplayPlaylist | null = null;
    try {
      newCollection = await this.boomplayApi.createPlaylist(collection.name, { description });
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!newCollection) {
      throw new CouldNotCreateCollection();
    }
    return newCollection;
  }

  async addItemToCollection(collection: GenericCollection, matchedItem: GenericMatchedItem) {
    await this.boomplayApi.addTracksToPlaylist(collection.rawId, [matchedItem.rawId]);
    return convertMatchedItemToCollectionItem(matchedItem);
  }

  async addManyItemsToCollection(
    collection: GenericCollection,
    data: {
      matchedItem: GenericMatchedItem;
      position?: number;
    }[]
  ) {
    const tracksIds = data.map(({ matchedItem }) => matchedItem.rawId);
    await this.boomplayApi.addTracksToPlaylist(collection.rawId, tracksIds);
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    collectionItems: GenericCollectionItem[]
  ): Promise<void> {
    if (collection.type !== CollectionType.PLAYLIST || collectionItems.length === 0) {
      return;
    }
    const itemsIds = collectionItems.map((item) => item.rawId);
    await this.boomplayApi.removeTracksFromPlaylist(collection.rawId, itemsIds);
  }

  async clearCollection(collection: GenericCollection) {
    const itemIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    await this.getPaginatedItems(collection, async (items) => {
      itemIds.push(...items.map((item) => item.rawId));
    });
    await this.boomplayApi.removeTracksFromPlaylist(collection.rawId, itemIds);
  }

  async matchItems(queryProps: SearchQueryProperties): Promise<GenericMatchedItem[]> {
    let tracks: BoomplayMatchedTrack[];
    if (queryProps.isrc) {
      ({ tracks } = await this.boomplayApi.searchByISRC(queryProps.isrc));
    } else {
      ({ tracks } = await this.boomplayApi.search({ queryProps, searchType: 'track' }));
    }
    return tracks;
  }

  async matchAlbums(queryProps: SearchQueryProperties) {
    const { albums } = await this.boomplayApi.search({ queryProps, searchType: 'album' });
    return albums;
  }

  async addAlbumToLibrary(album: GenericCollection): Promise<void> {
    await this.boomplayApi.addAlbumToLibrary(album.rawId);
  }

  async updateCollection(
    collection: GenericCollection,
    props: { name?: string; access?: CollectionAccess; description?: string }
  ) {
    const { name, access, description } = props;
    const isPublic = access === undefined ? undefined : access === CollectionAccess.public;
    await this.boomplayApi.updatePlaylist(collection.rawId, { name, isPublic, description });
  }

  removeCollection: undefined;

  async reAuthenticate(
    withData: GenericAuthenticationData,
    forceFetchRefreshTokens?: boolean
  ): Promise<GenericAuthenticationData> {
    return refreshAuthData({
      authId: withData.authId,
      userUUID: withData.additionalData?.userUUID ?? null,
      integrationId: MusicAPIIntegrationID.Boomplay,
      forceFetchRefreshTokens,
    });
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    switch (forCollection.type) {
      case CollectionType.PLAYLIST:
      case CollectionType.LIKED_PLAYLIST:
        return this.boomplayApi.loadPaginatedPlaylistItems(forCollection.rawId, onBatch);
      case CollectionType.MY_SONGS:
        return this.boomplayApi.loadPaginatedMySongs(onBatch);
      case CollectionType.ALBUM:
        return this.boomplayApi.loadPaginatedAlbumItems(forCollection.rawId, onBatch);
      default:
        return undefined;
    }
  }

  doesSupportReAuth(): boolean {
    return true;
  }

  public doesSupportAlbums(): boolean {
    return true;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return false;
  }

  public doesSupportSearchByISRC(): boolean {
    return true;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return false;
  }

  public doesSupportMovingManyItems(): boolean {
    return false;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }
};
