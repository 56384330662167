import { GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { CollectionType } from '../../generics/models/Collection';
import { YandexAPI } from './YandexAPI';
import { YandexPlaylist } from './models/YandexPlaylist';
import { YandexAlbum } from './models/YandexAlbum';
import { FetchError } from '../../generics/errors/FetchError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { YandexAuthenticationData } from './YandexAuthenticationData';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { SearchQueryProperties } from '../../generics/types';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { convertQueryPropsToString } from '../services/MatchingService.helpers';
import { ImporterID } from '../types';
import { yandexMusic } from '../../musicServices/services/YandexMusic';
import { tryParseInt } from '../../utils/tryParseInt';

const createYandexInstance = (authenticationData: YandexAuthenticationData): YandexAPI => {
  const userId = tryParseInt(authenticationData.authId);
  if (userId === undefined) {
    throw new Error('Could not get userId');
  }
  return new YandexAPI(authenticationData.additionalData.oAuthToken, userId);
};

export const YandexImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.Yandex;

  public static musicService = yandexMusic;

  public authenticationData: YandexAuthenticationData;

  private yandexApi: YandexAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as YandexAuthenticationData;
    this.yandexApi = createYandexInstance(this.authenticationData);
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.authenticationData = authenticationData as YandexAuthenticationData;
    this.yandexApi = createYandexInstance(this.authenticationData);
  }

  async getPaginatedCollections(
    onBatch: (collections: (YandexPlaylist | YandexAlbum)[]) => Promise<void>
  ): Promise<void> {
    const playlists = await this.yandexApi.loadAllPlaylists();
    await onBatch(playlists);

    const likedPlaylists = await this.yandexApi.loadAllLikedPlaylists();
    await onBatch(likedPlaylists);

    const albums = await this.yandexApi.loadAllAlbums();
    await onBatch(albums);
  }

  async getCollection(collection: GenericCollection): Promise<YandexPlaylist | YandexAlbum> {
    let result: YandexPlaylist | YandexAlbum | null;
    try {
      if (collection.type === CollectionType.ALBUM) {
        result = (await this.yandexApi.loadAlbum(collection.rawId)).album;
      } else {
        result = (await this.yandexApi.loadAllPlaylistItems(collection)).playlist;
      }
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CollectionDoesNotExistsError(e.message);
      }
      throw e;
    }
    if (!result) {
      throw new CollectionDoesNotExistsError();
    }
    return result;
  }

  async createCollection(collection: GenericCollection): Promise<YandexPlaylist> {
    let newCollection: YandexPlaylist | null = null;
    try {
      newCollection = await this.yandexApi.createPlaylist(collection.name);
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!newCollection) {
      throw new CouldNotCreateCollection();
    }
    return newCollection;
  }

  async addItemToCollection(collection: GenericCollection, matchedItem: GenericMatchedItem) {
    await this.yandexApi.addTracksToPlaylist(collection, [
      { rawId: matchedItem.rawId, albumId: matchedItem.additionalData?.albumId },
    ]);
    return convertMatchedItemToCollectionItem(matchedItem);
  }

  async addManyItemsToCollection(
    collection: GenericCollection,
    data: {
      matchedItem: GenericMatchedItem;
      position?: number;
    }[]
  ) {
    const tracksData = data.reduce<{ rawId: string; albumId: string | undefined }[]>((results, { matchedItem }) => {
      return [...results, { rawId: matchedItem.rawId, albumId: matchedItem.additionalData?.albumId }];
    }, []);
    await this.yandexApi.addTracksToPlaylist(collection, tracksData);
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    collectionItems: GenericCollectionItem[]
  ): Promise<void> {
    if (collection.type !== CollectionType.PLAYLIST || collectionItems.length === 0) {
      return;
    }
    const itemsIds = collectionItems.map((item) => item.rawId);
    const { tracks: allItems, playlist } = await this.yandexApi.loadAllPlaylistItems(collection);
    if (!playlist) {
      throw new CollectionDoesNotExistsError();
    }
    const { revision } = playlist;
    const indexesToRemove = allItems
      .map((item, index) => (itemsIds.includes(item.rawId) ? index : null))
      .filter((index): index is number => index !== null);

    await this.yandexApi.removeTracksFromPlaylist(collection.rawId, indexesToRemove, revision);
  }

  async clearCollection(collection: GenericCollection) {
    if (collection.type !== CollectionType.PLAYLIST) {
      return;
    }
    const { tracks: allItems, playlist } = await this.yandexApi.loadAllPlaylistItems(collection);
    if (!playlist) {
      throw new CollectionDoesNotExistsError();
    }
    const { revision } = playlist;
    const indexesToRemove = allItems.map((_item, index) => index);
    await this.yandexApi.removeTracksFromPlaylist(collection.rawId, indexesToRemove, revision);
  }

  async matchItems(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const { tracks } = await this.yandexApi.search(query, 'track');
    return tracks;
  }

  async matchAlbums(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const { albums } = await this.yandexApi.search(query, 'album');
    return albums;
  }

  async addAlbumToLibrary(album: GenericCollection): Promise<void> {
    await this.yandexApi.addAlbumToLibrary(album.rawId);
  }

  async reAuthenticate(_withData: GenericAuthenticationData): Promise<GenericAuthenticationData> {
    return Promise.reject(new Error('reauth not supported'));
  }

  doesSupportReAuth() {
    return false;
  }

  public doesSupportAlbums(): boolean {
    return true;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return true;
  }

  public doesSupportSearchByISRC(): boolean {
    return false;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return false;
  }

  public doesSupportMovingManyItems(): boolean {
    return false;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    const items = await (async () => {
      switch (forCollection.type) {
        case CollectionType.PLAYLIST:
        case CollectionType.LIKED_PLAYLIST:
          return (await this.yandexApi.loadAllPlaylistItems(forCollection)).tracks;
        case CollectionType.ALBUM:
          return (await this.yandexApi.loadAlbum(forCollection.rawId)).tracks;
        default:
          return [];
      }
    })();
    await onBatch(items);
  }

  async getCollectionPublicUrl(collection: GenericCollection) {
    const playlistResponse = await this.yandexApi.changeVisibility(collection, true);
    if (!playlistResponse) {
      return null;
    }
    const [uid, kind] = playlistResponse.rawId.split(':');
    return `https://music.yandex.com/users/${uid}/playlists/${kind}`;
  }
};
