import { SearchType } from 'fym-common/src/types/searchService';
import { useMemo } from 'react';
import { musicServicesByMusicAPIID } from 'fym-common/src/musicServices/allMusicServices';
import { allImportersImagesById } from '../../../importers';
import { ReleaseLinkData } from './types';

export const useExtractInfoFromReleaseData = (releaseLinkData: ReleaseLinkData) => {
  const { source, data, type } = releaseLinkData;
  const { title, text1, text2 } = useMemo(() => {
    const { track, url, artist, album } = data;
    if (type === SearchType.track) {
      return { title: track, text1: album, text2: artist };
    }
    if (type === SearchType.album) {
      return { title: album, text1: artist };
    }
    return { title: url };
  }, [data, type]);

  let ImporterLogo;
  let importerColor;
  if (source) {
    const musicService = musicServicesByMusicAPIID[source];
    if (!musicService) {
      throw new Error(`Could not get ${source} from musicServicesByMusicAPIID`);
    }
    const sourceImporter = allImportersImagesById[musicService.importerID];
    ImporterLogo = sourceImporter?.logoColorPadding.light;
    importerColor = musicService.color;
  }

  return {
    title,
    text1,
    text2,
    ImporterLogo,
    importerColor,
    imageUrl: releaseLinkData.data.imageUrl,
  };
};
