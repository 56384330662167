import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { FieldsName } from './consts';

const schema = Joi.object({
  [FieldsName.text]: Joi.string().label('Text').required(),
  [FieldsName.type]: Joi.string().label('Type').required(),
});

export const resolver = joiResolver(schema);
