import { artistsToString } from '../../utils/artistsConverter';

export const YouTubeMusicParser = {
  ITEM_SECTION: ['itemSectionRenderer', 'contents', 0],
  SECTION_LIST: ['sectionListRenderer', 'contents'],
  SINGLE_COLUMN_TAB: ['contents', 'singleColumnBrowseResultsRenderer', 'tabs', 0, 'tabRenderer', 'content'],
  PLAY_BUTTON: ['overlay', 'musicItemThumbnailOverlayRenderer', 'content', 'musicPlayButtonRenderer'],
  NAVIGATION_BROWSE_ID: ['navigationEndpoint', 'browseEndpoint', 'browseId'],
  THUMBNAILS: ['thumbnail', 'musicThumbnailRenderer', 'thumbnail', 'thumbnails'],
  THUMBNAIL_RENDERER: ['thumbnailRenderer', 'musicThumbnailRenderer', 'thumbnail', 'thumbnails'],
  THUMBNAIL_SQUARE_RENDERER: ['thumbnail', 'croppedSquareThumbnailRenderer', 'thumbnail', 'thumbnails'],
  TITLE: ['title', 'runs', 0],
  TITLE_TEXT: ['title', 'runs', 0, 'text'],
  CHANNEL_ID: ['subtitle', 'runs', 0, 'navigationEndpoint', 'browseEndpoint', 'browseId'],
  SUBTITLE: ['subtitle', 'runs'],
  SECOND_SUBTITLE: ['secondSubtitle', 'runs'],
  SUBTITLE2: ['subtitle', 'runs', 2, 'text'],
  CONTINUATION: ['continuations', 0, 'nextContinuationData', 'continuation'],
  MENU_ITEMS: ['menu', 'menuRenderer', 'items'],
  MENU_SERVICE: ['menuServiceItemRenderer', 'serviceEndpoint'],
  ACTIVE_ACCOUNT: ['actions', 0, 'openPopupAction', 'popup', 'multiPageMenuRenderer'],
  ACTIVE_ACCOUNT_NAME: ['header', 'activeAccountHeaderRenderer', 'accountName', 'runs', 0, 'text'],
  ACTIVE_ACCOUNT_PHOTO: ['header', 'activeAccountHeaderRenderer', 'accountPhoto', 'thumbnails', 0, 'url'],
  ACTIVE_ACCOUNT_CHANNEL_ID: ['navigationEndpoint', 'browseEndpoint', 'browseId'],
  FRAMEWORK_MUTATIONS: ['frameworkUpdates', 'entityBatchUpdate', 'mutations'],
  DOUBLE_TAP_COMMAND: ['doubleTapCommand', 'watchPlaylistEndpoint', 'playlistId'],
  SHELF_RENDERER: ['sectionListRenderer', 'contents', 0, 'musicShelfRenderer', 'contents'],
  PLAYLIST_ID: [
    'menu',
    'menuRenderer',
    'items',
    0,
    'menuNavigationItemRenderer',
    'navigationEndpoint',
    'watchPlaylistEndpoint',
    'playlistId',
  ],
};

export const nav = (data: any, path: (string | number)[]) => {
  let results = data;
  path.forEach((p) => {
    results = results !== undefined ? results[p] : undefined;
  });
  return results;
};

export const getItemText = (item: any, index: number, runIndex = 0) => {
  const column = getFlexColumnItem(item, index);
  if (!column?.text?.runs?.length || column?.text?.runs?.length < runIndex + 1) {
    return undefined;
  }
  return column?.text?.runs[runIndex].text;
};

export const getFlexColumnItem = (item: any, index: number) => {
  return item?.flexColumns[index]?.musicResponsiveListItemFlexColumnRenderer;
};

export const getBrowseId = (item: any, index: number) => {
  if (!item?.text.runs) {
    return undefined;
  }
  if (!item?.text.runs[index]?.navigationEndpoint) {
    return undefined;
  }
  return nav(item?.text.runs[index], YouTubeMusicParser.NAVIGATION_BROWSE_ID);
};

export const findObjectByKey = (key: string, nested?: string) => {
  return (item: any) => {
    let itemLet = item;
    if (nested) {
      itemLet = itemLet[nested];
    }
    return Object.prototype.hasOwnProperty.call(itemLet, key);
  };
};

export const getContinuationToken = (results: any): string | undefined => {
  if (!results?.continuations) {
    return undefined;
  }
  return nav(results, YouTubeMusicParser.CONTINUATION);
};

export const getShareID = (results: any): string | undefined => {
  const menuItems =
    results.musicEditablePlaylistDetailHeaderRenderer.header.musicDetailHeaderRenderer.menu.menuRenderer.items;
  for (const item of menuItems) {
    const serializedShareEntity =
      item.menuNavigationItemRenderer?.navigationEndpoint?.shareEntityEndpoint?.serializedShareEntity;
    if (serializedShareEntity) {
      return serializedShareEntity;
    }
  }
  return undefined;
};

export const getContinuationResults = (response: any, continuationType: string) => {
  if (!response.continuationContents) {
    return { results: undefined, items: undefined };
  }
  const results = response.continuationContents[continuationType];
  const continuationContents = continuationType.indexOf('Shelf') === -1 ? 'items' : 'contents';
  return { results, items: results[continuationContents] };
};

export const prepareBrowseData = (type: string, browseId: string) => {
  return {
    browseEndpointContextSupportedConfigs: {
      browseEndpointContextMusicConfig: {
        pageType: `MUSIC_PAGE_TYPE_${type}`,
      },
    },
    browseId,
  };
};

export function parseArtistsNamesFromRuns(runs: any): string {
  if (!runs || !Array.isArray(runs)) {
    return '';
  }
  const artistsNames = runs
    .filter(
      (run: any) =>
        run?.navigationEndpoint?.browseEndpoint?.browseEndpointContextSupportedConfigs?.browseEndpointContextMusicConfig
          ?.pageType === 'MUSIC_PAGE_TYPE_ARTIST'
    )
    .map((run: any) => run.text);

  return artistsToString(artistsNames);
}

export function parseSearchArtistsNames(data: any, runIndex = 0): string {
  const flexItem = getFlexColumnItem(data, 1);
  if (!flexItem?.text?.runs) {
    return '';
  }

  const artistsNames = parseArtistsNamesFromRuns(flexItem.text.runs);
  return artistsNames || flexItem.text.runs[runIndex]?.text;
}
