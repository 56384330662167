import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { buildCoverUrl, createTidalAlbumUrl, getArtistsNamesAsString, getImageUrlFromMetaElements } from '../utils';
import { domParser, getScriptsContents } from '../../../utils/htmlUtils';
import { convertTidalRawArtists, TidalArtist } from './TidalArtist';

export class TidalAlbum extends GenericCollection {
  constructor(data: Omit<CollectionModelData, 'type'>, public artists?: TidalArtist[]) {
    super({ type: CollectionType.ALBUM, ...data });
  }

  static fromData(data: any): TidalAlbum | null {
    if (!data?.id || !data.title) {
      return null;
    }
    return new TidalAlbum(
      {
        rawId: `${data.id}`,
        name: data.title,
        itemCount: data.numberOfTracks,
        additionalData: {
          href: data.url,
          imageUrl: buildCoverUrl(data.cover),
          artist: getArtistsNamesAsString(data.artists),
        },
        publicUrl: createTidalAlbumUrl(data.id),
      },
      convertTidalRawArtists(data.artists)
    );
  }

  static fromShareURLData(HTMLString: string, url: string): TidalAlbum | null {
    const htmlDoc = domParser.parseFromString(HTMLString);
    const scriptsContents = getScriptsContents(HTMLString);
    const albumDataString = scriptsContents.find((content) => content.includes('context'));

    if (!albumDataString) {
      return null;
    }

    let albumData: any;
    try {
      albumData = JSON.parse(albumDataString);
    } catch (error) {
      console.error(error);
      return null;
    }
    if (!albumData) {
      return null;
    }

    const metaElements = htmlDoc.getElementsByTagName('meta');
    const urlParts = albumData.url.split('/');
    const rawId = urlParts[urlParts.length - 1];
    if (!rawId || !albumData.name) {
      return null;
    }
    return new TidalAlbum({
      rawId,
      name: albumData.name,
      additionalData: {
        artist: getArtistsNamesAsString(albumData.byArtist),
        imageUrl: getImageUrlFromMetaElements(metaElements),
      },
      publicUrl: url,
    });
  }
}
