import { useCallback, useEffect, useState } from 'react';
import { isFinishableFunction } from 'fym-common/src/utils/singletonPromise';

export type AsyncStatus = 'idle' | 'pending' | 'success' | 'error';
export function useAsync<T, E = Error>(asyncFunction: (...args: any[]) => Promise<T>, immediate = true) {
  const [status, setStatus] = useState<AsyncStatus>(() => {
    if (isFinishableFunction(asyncFunction) && asyncFunction.finished) {
      return asyncFunction.error !== undefined ? 'error' : 'success';
    }
    return immediate ? 'pending' : 'idle';
  });
  const [value, setValue] = useState<T | undefined>(() => {
    if (isFinishableFunction(asyncFunction)) {
      return asyncFunction.lastValue;
    }
    return undefined;
  });
  const [error, setError] = useState<E | undefined>(undefined);

  const call = useCallback(
    async (...args: any[]) => {
      setStatus('pending');
      setValue(undefined);
      setError(undefined);
      let response: T | undefined;
      try {
        response = await asyncFunction(...args);
        setValue(response);
        setStatus('success');
      } catch (err: any) {
        setError(err);
        setStatus('error');
        throw err;
      }
      return response;
    },
    [asyncFunction]
  );
  const execute = useCallback(
    async (...args: any[]) => {
      if (isFinishableFunction(asyncFunction)) {
        asyncFunction.reset();
      }
      return call(...args);
    },
    [asyncFunction, call]
  );

  useEffect(() => {
    if (immediate) {
      call().catch(console.error);
    }
  }, [call, immediate]);
  return { execute, status, value, error };
}
