import { validateISRC } from '../../../utils/validateISRC';
import { createPandoraImageUrl, createPandoraPublicUrl } from '../utils';
import { GenericMatchedItem } from '../../../generics/models/GenericMatchedItem';

export class PandoraMatchedTrack extends GenericMatchedItem {
  static fromData(data: any, url?: string): PandoraMatchedTrack | null {
    if (!data?.pandoraId || !data.name) {
      return null;
    }
    let durationInMillis: number | undefined;
    const durationInSec = data.duration;
    if (data.durationMillis) {
      durationInMillis = data.durationMillis;
    } else if (durationInSec) {
      durationInMillis = durationInSec * 1000;
    }
    return new PandoraMatchedTrack({
      rawId: data.pandoraId,
      name: data.name,
      album: data.albumName,
      artist: data.artistName,
      isrc: validateISRC(data?.isrc),
      imageUrl: createPandoraImageUrl(data.icon?.artUrl),
      duration: durationInMillis,
      sourceURL: url ?? createPandoraPublicUrl(data.shareableUrlPath),
    });
  }
}
