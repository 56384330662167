import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useStore } from 'react-redux';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle';
import { FieldsName, FieldsType } from './consts';
import { FormInput } from '../components/FormInput/FormInput';
import { createResolver } from './validators';
import { RootStateType } from '../../../redux/store';
import { InputType } from '../types';
import { SMLOnboardingFormStep1Props } from './props';
import { SMLButton } from '../../common/views/SMLButton/SMLButton';

export function useSMLOnboardingFormStep1({
  onSubmit,
  isLoading = false,
  withName,
}: Pick<SMLOnboardingFormStep1Props, 'onSubmit' | 'isLoading' | 'withName'>) {
  const intl = useIntl();
  const store = useStore<RootStateType>();
  const resolver = useMemo(() => createResolver(store, withName), [withName, store]);
  const [isFileLoading] = useState<boolean>(false);
  const { control, handleSubmit } = useForm<FieldsType>({
    resolver,
    defaultValues: {
      [FieldsName.name]: '',
      [FieldsName.slug]: '',
    },
  });

  const submit = useCallback(
    (event?: React.BaseSyntheticEvent) => {
      handleSubmit(onSubmit)(event).catch(console.error);
    },
    [handleSubmit, onSubmit]
  );

  return {
    submit,
    NameInput: (
      <FormInput
        name={FieldsName.name}
        control={control}
        type={InputType.text}
        disabled={isLoading}
        icon={faUserCircle}
        label={intl.formatMessage({
          id: 'app.forms.account.name',
          defaultMessage: 'Name',
        })}
        placeholder={intl.formatMessage({
          id: 'app.forms.account.name.placeholder',
          defaultMessage: 'Enter name',
        })}
        help={intl.formatMessage({
          id: 'app.forms.account.name.help',
          defaultMessage: 'The name will be displayed on your Bio Link',
        })}
      />
    ),
    SlugInput: (
      <FormInput
        name={FieldsName.slug}
        control={control}
        type={InputType.text}
        disabled={isLoading}
        label={intl.formatMessage({
          id: 'app.forms.account.slug',
          defaultMessage: 'Slug',
        })}
        placeholder={intl.formatMessage({
          id: 'app.forms.account.slug.placeholder',
          defaultMessage: 'Enter slug',
        })}
        help={intl.formatMessage({
          id: 'app.forms.account.help.slug',
          defaultMessage: 'The short name that is your custom subdomain for all Smart Links',
        })}
        inputTextPrefix="https://"
        inputTextSuffix=".fym.fm"
      />
    ),
    SubmitButton: (
      <SMLButton
        color="secondary"
        onClick={submit}
        disabled={isLoading || isFileLoading}
        isLoading={isLoading}
        rightIcon={faArrowRight}
        submit
      >
        {intl.formatMessage({
          id: 'app.sml.onboarding.next',
          defaultMessage: 'Next',
        })}
      </SMLButton>
    ),
  };
}
