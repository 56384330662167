import { GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { CollectionAccess, CollectionType } from '../../generics/models/Collection';
import { AnghamiAPI } from './AnghamiAPI';
import { AnghamiPlaylist } from './models/AnghamiPlaylist';
import { AnghamiAlbum } from './models/AnghamiAlbum';
import { FetchError } from '../../generics/errors/FetchError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { AnghamiAuthenticationData } from './AnghamiAuthenticationData';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { AnghamiLikedPlaylist } from './models/AnghamiLikedPlaylist';
import { SearchQueryProperties } from '../../generics/types';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { convertQueryPropsToString } from '../services/MatchingService.helpers';
import { ImporterID } from '../types';
import { anghami } from '../../musicServices/services/Anghami';

const createAnghamiInstance = (authenticationData: AnghamiAuthenticationData): AnghamiAPI => {
  return new AnghamiAPI({
    sessionId: authenticationData.additionalData.sessionId,
    fingerprint: authenticationData.additionalData.fingerprint,
    userId: authenticationData.authId,
  });
};

export const AnghamiImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.Anghami;

  public static musicService = anghami;

  public authenticationData: AnghamiAuthenticationData;

  private anghamiApi: AnghamiAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as AnghamiAuthenticationData;
    this.anghamiApi = createAnghamiInstance(this.authenticationData);
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.authenticationData = authenticationData as AnghamiAuthenticationData;
    this.anghamiApi = createAnghamiInstance(this.authenticationData);
  }

  async getPaginatedCollections(
    onBatch: (collections: (AnghamiPlaylist | AnghamiLikedPlaylist | AnghamiAlbum)[]) => Promise<void>
  ): Promise<void> {
    const { playlists, likedPlaylists } = await this.anghamiApi.loadAllPlaylists();
    await onBatch([...playlists, ...likedPlaylists]);

    const { albums } = await this.anghamiApi.loadAllAlbums();
    await onBatch(albums);
  }

  async getCollection(collection: GenericCollection): Promise<AnghamiPlaylist> {
    let result: AnghamiPlaylist | AnghamiAlbum | null;
    if (collection.type === CollectionType.ALBUM) {
      result = (await this.anghamiApi.loadAlbumItemPage(collection.rawId)).album;
    } else {
      result = (await this.anghamiApi.loadPlaylistItemPage(collection.rawId)).playlist;
    }
    if (!result) {
      throw new CollectionDoesNotExistsError();
    }
    return result;
  }

  async createCollection(collection: GenericCollection, description?: string): Promise<AnghamiPlaylist> {
    let newCollection: AnghamiPlaylist | null = null;
    try {
      newCollection = await this.anghamiApi.createPlaylist(collection.name, { description });
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!newCollection) {
      throw new CouldNotCreateCollection();
    }
    return newCollection;
  }

  async addItemToCollection(collection: GenericCollection, matchedItem: GenericMatchedItem) {
    await this.anghamiApi.addTrackToPlaylist(collection.rawId, matchedItem.rawId);
    return convertMatchedItemToCollectionItem(matchedItem);
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    collectionItems: GenericCollectionItem[]
  ): Promise<void> {
    if (collection.type !== CollectionType.PLAYLIST || collectionItems.length === 0) {
      return;
    }
    const itemsIdsToRemove = collectionItems.map((item) => item.rawId);
    let itemIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    await this.getPaginatedItems(collection, async (items) => {
      itemIds = [...itemIds, ...items.map((item) => item.rawId)];
    });

    const itemsIdsToLeave = itemIds.filter((itemId) => !itemsIdsToRemove.includes(itemId));
    await this.anghamiApi.setTracksInPlaylist(collection.rawId, itemsIdsToLeave);
  }

  async clearCollection(collection: GenericCollection) {
    if (collection.type !== CollectionType.PLAYLIST) {
      return;
    }
    await this.anghamiApi.setTracksInPlaylist(collection.rawId, []);
  }

  async matchItems(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const { tracks } = await this.anghamiApi.search(query, 'song');
    return tracks;
  }

  async matchAlbums(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const { albums } = await this.anghamiApi.search(query, 'album');
    return albums;
  }

  async addAlbumToLibrary(album: GenericCollection): Promise<void> {
    await this.anghamiApi.addAlbumToLibrary(album.rawId);
  }

  async updateCollection(
    collection: GenericCollection,
    props: { name?: string; access?: CollectionAccess; description?: string }
  ) {
    const { name, access, description } = props;
    const isPublic = access === undefined ? undefined : access === CollectionAccess.public;
    if (name || description) {
      await this.anghamiApi.updatePlaylistNameAndDescription(collection.rawId, { name, description });
    }
    if (isPublic !== undefined) {
      await this.anghamiApi.updatePlaylistAccess(collection.rawId, isPublic);
    }
  }

  async removeCollection(collection: GenericCollection): Promise<void> {
    await this.anghamiApi.removePlaylist(collection.rawId);
  }

  async reAuthenticate(_withData: GenericAuthenticationData): Promise<GenericAuthenticationData> {
    return Promise.reject(new Error('reauth not supported'));
  }

  doesSupportReAuth() {
    return false;
  }

  public doesSupportAlbums(): boolean {
    return true;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return true;
  }

  public doesSupportSearchByISRC(): boolean {
    return false;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return false;
  }

  public doesSupportMovingManyItems(): boolean {
    return false;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    switch (forCollection.type) {
      case CollectionType.PLAYLIST:
      case CollectionType.LIKED_PLAYLIST:
        return this.anghamiApi.loadPaginatedPlaylistsItems(forCollection.rawId, onBatch);
      case CollectionType.ALBUM:
        return this.anghamiApi.loadPaginatedAlbumItems(forCollection.rawId, onBatch);
      default:
        return undefined;
    }
  }

  async getCollectionPublicUrl(collection: GenericCollection) {
    if (collection.type !== CollectionType.PLAYLIST) {
      return null;
    }
    await this.anghamiApi.updatePlaylistAccess(collection.rawId, true);
    return `https://play.anghami.com/playlist/${collection.rawId}`;
  }
};
