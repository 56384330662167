import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldsName, FieldsType } from './consts';
import { resolver } from './validators';
import { FormInput } from '../../../../forms/components/FormInput/FormInput';
import { InputType } from '../../../../forms/types';
import { useCreateWizardPlaylistLinkMutation } from '../../../../../redux/api/fymAPI';
import { isSuccessfulResult } from '../../../../../redux/api/utils/isSuccessfulResult';
import { SmartLinkResponse } from '../../../../../redux/api/fymAPI.types';

const defaultValues = {
  [FieldsName.playlistLink]: '',
};

interface PlaylistLinkFormProps {
  onSuccess: (releaseLinkData: SmartLinkResponse) => void;
  accountId: number;
}

export function usePlaylistLinkForm({ onSuccess, accountId }: PlaylistLinkFormProps) {
  const intl = useIntl();

  const [createWizardPlaylistLink, { isLoading, error }] = useCreateWizardPlaylistLinkMutation();
  const { control, handleSubmit } = useForm<FieldsType>({ resolver, defaultValues });

  const onSubmit: SubmitHandler<FieldsType> = useCallback(
    async ({ playlistLink }) => {
      const url = playlistLink;
      const results = await createWizardPlaylistLink({ url, accountId });

      if (!isSuccessfulResult(results)) {
        return;
      }
      onSuccess(results.data);
    },
    [accountId, createWizardPlaylistLink, onSuccess]
  );

  const submit = useCallback(
    (event?: React.BaseSyntheticEvent) => {
      handleSubmit(onSubmit)(event).catch(console.error);
    },
    [handleSubmit, onSubmit]
  );

  return {
    isLoading,
    submit,
    error,
    MediaUrlInput: (
      <FormInput
        name={FieldsName.playlistLink}
        control={control}
        type={InputType.text}
        disabled={isLoading}
        label={intl.formatMessage({
          id: 'app.forms.playlist_link.url',
          defaultMessage: 'Playlist URL',
        })}
        placeholder={intl.formatMessage({
          id: 'app.forms.playlist_link.placeholder',
          defaultMessage: 'Enter or paste URL',
        })}
        help={intl.formatMessage({
          id: 'app.forms.playlist_link.subtitle',
          defaultMessage: 'Using this link we will create a player widget.',
        })}
      />
    ),
  };
}
