import React, { useMemo } from 'react';
import { TypedMemo } from '../../types';

const BoldComponent: React.FC<React.PropsWithChildren<{ color?: string }>> = ({ children, color }) => {
  const styles: React.CSSProperties = useMemo(
    () => ({
      color: color ?? undefined,
    }),
    [color]
  );
  return (
    <span style={styles} className="fw-bold">
      {children}
    </span>
  );
};

export const Bold = TypedMemo(BoldComponent);
