import { useCallback } from 'react';
import { openSupportEmail } from '../../utils/openSupportEmail';
import { appInformation } from '../../utils/appInformation';

export function useSupportEmail(licenseKey?: string, email?: string, deviceId?: string) {
  return useCallback(() => {
    const buildVersion = appInformation.buildVersion ? `(${appInformation.buildVersion})` : '';
    openSupportEmail(
      `${appInformation.platform} ${appInformation.platformVersion}`,
      `${appInformation.version} ${buildVersion}`,
      deviceId,
      licenseKey,
      email
    ).catch((error) => console.error(error));
  }, [deviceId, email, licenseKey]);
}
