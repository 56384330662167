import { PaddleProductSku, PaddleSubscriptionSku } from 'fym-common/src/utils/paddleSkus';
import { PaddlePricesResponse } from '../models/PaddlePricesResponse';

export function preparePaddlePricing(paddlePricing: PaddlePricesResponse) {
  const { productsById } = paddlePricing;
  const singleLicenseProduct = productsById[PaddleProductSku.SingleLicense];
  if (singleLicenseProduct === undefined) {
    throw new Error('Missing singleLicenseProduct');
  }
  const lifetimeProduct = productsById[PaddleProductSku.Lifetime];
  if (lifetimeProduct === undefined) {
    throw new Error('Missing lifetimeProduct');
  }

  const quarterlySubscription = productsById[PaddleSubscriptionSku.Quarterly];
  if (quarterlySubscription === undefined) {
    throw new Error('Missing quarterlySubscription');
  }
  const yearlySubscription = productsById[PaddleSubscriptionSku.Yearly];
  if (yearlySubscription === undefined) {
    throw new Error('Missing yearlySubscription');
  }
  const smlInfluencerSubscription = productsById[PaddleSubscriptionSku.SmartLinksInfluencer];
  if (smlInfluencerSubscription === undefined) {
    throw new Error('Missing smlInfluencerSubscription');
  }
  const smlArtistSubscription = productsById[PaddleSubscriptionSku.SmartLinksArtist];
  if (smlArtistSubscription === undefined) {
    throw new Error('Missing smlArtistSubscription');
  }
  return {
    basicPriceData: {
      amount: singleLicenseProduct.price.gross,
      currency: singleLicenseProduct.currency,
    },
    quarterlyPriceData: {
      amount: quarterlySubscription.price.gross,
      currency: quarterlySubscription.currency,
    },
    annualPriceData: {
      amount: yearlySubscription.price.gross,
      currency: yearlySubscription.currency,
    },
    lifetimePriceData: {
      amount: lifetimeProduct.price.gross,
      currency: lifetimeProduct.currency,
    },
    smartLinksInfluencerPriceData: {
      amount: smlInfluencerSubscription.price.gross,
      currency: smlInfluencerSubscription.currency,
    },
    smartLinksArtistPriceData: {
      amount: smlArtistSubscription.price.gross,
      currency: smlArtistSubscription.currency,
    },
    smartLinksRookiePriceData: {
      amount: 0,
      currency: smlArtistSubscription.currency,
    },
  };
}
