import React, { useMemo } from 'react';
import { SerializedSocialLinksWidget } from 'fym-common/src/db/serializersTypes';
import { TypedMemo } from '../../../../../common/types';
import { WidgetListItemProps } from '../../props';
import { SocialLinkItem } from '../../../../socialLinksList/socialLinkItem/SocialLinkItem';

const SocialWidgetListItemComponent: React.FC<WidgetListItemProps<SerializedSocialLinksWidget>> = ({ widget }) => {
  const { data } = widget;
  const slicedSocialLinks = useMemo(() => data.socialLinks.slice(0, 5), [data.socialLinks]);

  return (
    <div className="widget-list-item-text-container">
      <div className="smart-links-social-links">
        {slicedSocialLinks.map((socialLink) => (
          <SocialLinkItem key={socialLink.id} socialLink={socialLink} />
        ))}
      </div>
    </div>
  );
};

export const SocialWidgetListItem = TypedMemo(SocialWidgetListItemComponent);
