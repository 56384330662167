import { GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { CollectionType } from '../../generics/models/Collection';
import { QobuzAPI } from './QobuzAPI';
import { QobuzPlaylist } from './models/QobuzPlaylist';
import { FetchError } from '../../generics/errors/FetchError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { QobuzAuthenticationData } from './QobuzAuthenticationData';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { QobuzAlbum } from './models/QobuzAlbum';
import { QobuzItemWithPlaylistId } from './types';
import { SearchQueryProperties } from '../../generics/types';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { convertQueryPropsToString } from '../services/MatchingService.helpers';
import { ImporterID } from '../types';
import { qobuz } from '../../musicServices/services/Qobuz';
import { tryParseInt } from '../../utils/tryParseInt';

const createSoundCloudInstance = (authenticationData: QobuzAuthenticationData): QobuzAPI => {
  const userId = tryParseInt(authenticationData.authId);
  if (userId === undefined) {
    throw new Error('Could not get userId');
  }
  return new QobuzAPI(
    authenticationData.additionalData.qobuzSessionAws,
    authenticationData.additionalData.oAuthToken,
    authenticationData.additionalData.oAuthRefreshToken,
    userId,
    authenticationData.additionalData.userPlan
  );
};

export const QobuzImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.Qobuz;

  public static musicService = qobuz;

  public authenticationData: QobuzAuthenticationData;

  private qobuzApi: QobuzAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as QobuzAuthenticationData;
    this.qobuzApi = createSoundCloudInstance(this.authenticationData);
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.authenticationData = authenticationData as QobuzAuthenticationData;
    this.qobuzApi = createSoundCloudInstance(this.authenticationData);
  }

  async getPaginatedCollections(
    onBatch: (collections: (QobuzPlaylist | QobuzAlbum)[]) => Promise<void>
  ): Promise<void> {
    await this.qobuzApi.loadPaginatedPlaylists(onBatch);
    await this.qobuzApi.loadPaginatedAlbums(onBatch);
  }

  async getCollection(collection: GenericCollection): Promise<QobuzPlaylist | QobuzAlbum> {
    let result: QobuzPlaylist | QobuzAlbum | null;
    if (collection.type === CollectionType.ALBUM) {
      result = (await this.qobuzApi.loadAlbumItemsPage(collection.rawId)).album;
    } else {
      result = (await this.qobuzApi.loadPlaylistItemPage(collection.rawId)).playlist;
    }

    if (!result) {
      throw new CollectionDoesNotExistsError();
    }
    return result;
  }

  async createCollection(collection: GenericCollection): Promise<QobuzPlaylist> {
    let newCollection: QobuzPlaylist | null = null;
    try {
      newCollection = await this.qobuzApi.createPlaylist(collection.name);
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!newCollection) {
      throw new CouldNotCreateCollection();
    }
    return newCollection;
  }

  async addItemToCollection(collection: GenericCollection, matchedItem: GenericMatchedItem) {
    await this.qobuzApi.addTracksToPlaylist(collection.rawId, [matchedItem.rawId]);
    return convertMatchedItemToCollectionItem(matchedItem);
  }

  async addManyItemsToCollection(
    collection: GenericCollection,
    data: {
      matchedItem: GenericMatchedItem;
      position?: number;
    }[]
  ) {
    const tracksIds = data.map(({ matchedItem }) => matchedItem.rawId);
    await this.qobuzApi.addTracksToPlaylist(collection.rawId, tracksIds);
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    collectionItems: GenericCollectionItem[]
  ): Promise<void> {
    if (collection.type !== CollectionType.PLAYLIST || collectionItems.length === 0) {
      return;
    }
    const itemsIdsToRemove = collectionItems.map((item) => item.rawId);
    const playlistTracksIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    await this.getPaginatedItems(collection, async (items) => {
      playlistTracksIds.push(
        ...items
          .filter(
            (item): item is QobuzItemWithPlaylistId =>
              itemsIdsToRemove.includes(item.rawId) && !!item.additionalData?.playlistTrackId
          )
          .map((item) => item.additionalData.playlistTrackId)
      );
    });
    await this.qobuzApi.removeTracksFromPlaylist(collection.rawId, playlistTracksIds);
  }

  async clearCollection(collection: GenericCollection) {
    const playlistTracksIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    await this.getPaginatedItems(collection, async (items) => {
      if (items.length === 0) {
        return;
      }
      playlistTracksIds.push(
        ...items
          .filter((item): item is QobuzItemWithPlaylistId => !!item.additionalData?.playlistTrackId)
          .map((item) => item.additionalData?.playlistTrackId)
      );
    });
    await this.qobuzApi.removeTracksFromPlaylist(collection.rawId, playlistTracksIds);
  }

  async matchItems(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const { tracks } = await this.qobuzApi.search(query, 'tracks');
    return tracks;
  }

  async matchAlbums(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const { albums } = await this.qobuzApi.search(query, 'albums');
    return albums;
  }

  async addAlbumToLibrary(album: GenericCollection): Promise<void> {
    await this.qobuzApi.addAlbumToLibrary(album.rawId);
  }

  async reAuthenticate(_withData: GenericAuthenticationData): Promise<GenericAuthenticationData> {
    const qobuzUser = await this.qobuzApi.apiLogin(true);
    return new QobuzAPI(
      qobuzUser.qobuzSessionAws,
      qobuzUser.token,
      qobuzUser.token,
      qobuzUser.id,
      qobuzUser.plan
    ).getAuthData(qobuzUser);
  }

  doesSupportReAuth() {
    return true;
  }

  public doesSupportAlbums(): boolean {
    return true;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return true;
  }

  public doesSupportSearchByISRC(): boolean {
    return false;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return false;
  }

  public doesSupportMovingManyItems(): boolean {
    return false;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    switch (forCollection.type) {
      case CollectionType.PLAYLIST:
      case CollectionType.LIKED_PLAYLIST:
        return this.qobuzApi.loadPaginatedPlaylistsItems(forCollection.rawId, onBatch);
      case CollectionType.ALBUM:
        return this.qobuzApi.loadPaginatedAlbumItems(forCollection.rawId, onBatch);
      default:
        return undefined;
    }
  }

  async getCollectionPublicUrl(collection: GenericCollection) {
    const playlist = await this.qobuzApi.updatePlaylist(collection.rawId, true);
    if (!playlist) {
      return null;
    }
    return `https://open.qobuz.com/playlist/${playlist.rawId}`;
  }
};
