import { RessoArtistRawResponse } from './RessoResponseModels';
import { GenericAuthor } from '../../../generics/models/GenericAuthor';

export class RessoArtist extends GenericAuthor {
  static fromData(data: RessoArtistRawResponse | undefined): RessoArtist | null {
    if (!data?.id || !data.name) {
      return null;
    }
    return new RessoArtist({
      rawId: data.id,
      name: data.name,
      additionalData: { imageUrl: data.avatar?.url },
    });
  }
}
