import chunk from 'lodash/chunk';
import { CustomUpdaterMessageCallback, GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { CollectionAccess, CollectionType } from '../../generics/models/Collection';
import { FetchError } from '../../generics/errors/FetchError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { AmazonMusicAPI } from './AmazonMusicAPI';
import { AmazonMusicAuthenticationData } from './types';
import { AmazonMusicPlaylist } from './models/AmazonMusicPlaylist';
import { SearchQueryProperties } from '../../generics/types';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { amazonMusic } from '../../musicServices/services/AmazonMusic';
import { ImporterID } from '../types';
import { AmazonMusicAlbum } from './models/AmazonMusicAlbum';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { AmazonMusicLikedTracks } from './models/AmazonMusicLikedTracks';
import { refreshAuthData } from '../../musicApi/utils/refreshAuthData';
import { MusicAPIIntegrationID } from '../../musicServices/types';
import { groupRecentlyPlayedTracks } from './utils/groupRecentlyPlayedTracks';
import { IdsPerYear, RawId } from './utils/types';
import { AmazonMusicCollectionTrack } from './models/AmazonMusicCollectionTrack';
import { generateStats } from './utils/generateStats';
import { getStatsMessageUpdater } from './utils/getStatsMessageUpdater';

const createAmazonMusicInstance = (authenticationData: AmazonMusicAuthenticationData): AmazonMusicAPI => {
  return new AmazonMusicAPI({
    accessToken: authenticationData.additionalData.accessToken,
    profileID: authenticationData.additionalData.profileID,
    userId: authenticationData.authId,
  });
};

export const AmazonMusicImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.AmazonMusic;

  public static musicService = amazonMusic;

  public static areTrackEntryIdAndGlobalIdDifferent = true;

  public authenticationData: AmazonMusicAuthenticationData;

  private amazonMusicApi: AmazonMusicAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as AmazonMusicAuthenticationData;
    this.amazonMusicApi = createAmazonMusicInstance(this.authenticationData);
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.authenticationData = authenticationData as AmazonMusicAuthenticationData;
    this.amazonMusicApi = createAmazonMusicInstance(this.authenticationData);
  }

  async getPaginatedCollections(onBatch: (collections: AmazonMusicPlaylist[]) => Promise<void>): Promise<void> {
    await onBatch([new AmazonMusicLikedTracks(this.authenticationData.additionalData.profileID)]);
    await this.amazonMusicApi.getPaginatedMyPlaylists(onBatch);
    await this.amazonMusicApi.getPaginatedLikedPlaylists(onBatch);
  }

  async getCollection(collection: GenericCollection): Promise<AmazonMusicPlaylist> {
    let result: AmazonMusicPlaylist | null;
    switch (collection.type) {
      case CollectionType.PLAYLIST:
      case CollectionType.LIKED_PLAYLIST:
        result = await this.amazonMusicApi.getPlaylist(collection.rawId);
        break;
      default:
        throw new CollectionDoesNotExistsError();
    }
    if (!result) {
      throw new CollectionDoesNotExistsError();
    }
    return result;
  }

  async createCollection(collection: GenericCollection, description?: string): Promise<AmazonMusicPlaylist> {
    let newCollection: AmazonMusicPlaylist | null = null;
    try {
      newCollection = await this.amazonMusicApi.createPlaylist(collection.name, { description });
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!newCollection) {
      throw new CouldNotCreateCollection();
    }
    return newCollection;
  }

  async addItemToCollection(collection: GenericCollection, matchedItem: GenericMatchedItem) {
    await this.amazonMusicApi.appendTracksToPlaylist(collection.rawId, [matchedItem.rawId]);
    return convertMatchedItemToCollectionItem(matchedItem, AmazonMusicImporter.areTrackEntryIdAndGlobalIdDifferent);
  }

  async addManyItemsToCollection(
    collection: GenericCollection,
    data: {
      matchedItem: GenericMatchedItem;
      position?: number;
    }[]
  ) {
    const tracksIds = data.map(({ matchedItem }) => matchedItem.rawId);
    await this.amazonMusicApi.appendTracksToPlaylist(collection.rawId, tracksIds);
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    collectionItems: GenericCollectionItem[]
  ): Promise<void> {
    if (collection.type !== CollectionType.PLAYLIST || collectionItems.length === 0) {
      return;
    }
    const idsToRemove = collectionItems.map((item) => item.rawId);
    await this.amazonMusicApi.removeTracksFromPlaylist(collection.rawId, idsToRemove);
  }

  async matchItems(queryProps: SearchQueryProperties): Promise<GenericMatchedItem[]> {
    let { tracks } = await this.amazonMusicApi.searchTracks({ queryProps, advancedSearch: true });
    if (tracks.length === 0) {
      ({ tracks } = await this.amazonMusicApi.searchTracks({ queryProps, advancedSearch: false }));
    }
    return tracks;
  }

  async matchAlbums(queryProps: SearchQueryProperties): Promise<AmazonMusicAlbum[]> {
    let { albums } = await this.amazonMusicApi.searchAlbums({ queryProps });
    if (albums.length === 0) {
      ({ albums } = await this.amazonMusicApi.searchAlbums({ queryProps, advancedSearch: false }));
    }
    return albums;
  }

  async addAlbumToLibrary(album: GenericCollection): Promise<void> {
    await this.amazonMusicApi.addAlbumToLibrary(album.rawId);
  }

  async updateCollection(
    collection: GenericCollection,
    props: { name?: string; access?: CollectionAccess; description?: string }
  ) {
    const { name, access, description } = props;
    const isPublic = access === undefined ? undefined : access === CollectionAccess.public;
    await this.amazonMusicApi.updatePlaylist(collection.rawId, {
      title: name ?? collection.name,
      description,
      isPublic,
    });
  }

  async removeCollection(collection: GenericCollection): Promise<void> {
    await this.amazonMusicApi.removePlaylists([collection.rawId]);
  }

  async reAuthenticate(
    withData: GenericAuthenticationData,
    forceFetchRefreshTokens?: boolean
  ): Promise<GenericAuthenticationData> {
    return refreshAuthData({
      authId: withData.authId,
      userUUID: withData.additionalData?.userUUID ?? null,
      integrationId: MusicAPIIntegrationID.AmazonMusic,
      forceFetchRefreshTokens,
    });
  }

  async moveManyItems(props: Parameters<NonNullable<GenericImporter['moveManyItems']>>[0]) {
    const { itemsToMove, collection, offset, allItems } = props;
    if (itemsToMove.length === 0) {
      return;
    }
    const sortedItemsToMove = itemsToMove.sort((a, b) => a.position - b.position);
    const sortedAllItems = allItems.sort((a, b) => a.position - b.position);
    const firstItemToMove = sortedItemsToMove[0];
    const lastItemToMove = sortedItemsToMove[sortedItemsToMove.length - 1];
    if (!firstItemToMove || !lastItemToMove) {
      throw new Error(
        `Missing firstItem:[${firstItemToMove}] or lastItem:[${lastItemToMove}] in Amazon's moveManyItems`
      );
    }
    const indexOfFirstItemToMove = sortedAllItems.findIndex(({ rawId }) => rawId === firstItemToMove.rawId);
    const indexOfLastItemToMove = sortedAllItems.findIndex(({ rawId }) => rawId === lastItemToMove.rawId);
    if (indexOfFirstItemToMove === -1 || indexOfLastItemToMove === -1) {
      throw new Error(`Invalid index in Amazon's moveManyItems`);
    }

    const newIndexOfFirstItemToMove = indexOfFirstItemToMove + offset;
    const newIndexOfLastItemToMove = indexOfLastItemToMove + offset;
    const isMovedToStart = newIndexOfFirstItemToMove <= 0;
    const isMovedToEnd = newIndexOfLastItemToMove >= sortedAllItems.length - 1;

    let entryIdsToMove: string[];
    let entryIdAbove: string | undefined;
    let entryIdBelow: string | undefined;

    if (isMovedToStart) {
      const itemsBeforeFirstItemToMove = sortedAllItems.slice(0, indexOfFirstItemToMove);
      entryIdsToMove = itemsBeforeFirstItemToMove.map((item) => item.rawId);
      entryIdAbove = lastItemToMove.rawId;
      const willItemsBeMovedToEnd = itemsBeforeFirstItemToMove.length + itemsToMove.length === sortedAllItems.length;
      entryIdBelow = willItemsBeMovedToEnd
        ? itemsBeforeFirstItemToMove[itemsBeforeFirstItemToMove.length - 1]?.rawId
        : sortedAllItems[indexOfLastItemToMove + 1]?.rawId;
    } else if (isMovedToEnd) {
      entryIdsToMove = itemsToMove.map((item) => item.rawId);
      entryIdAbove = sortedAllItems[sortedAllItems.length - 1]?.rawId;
      entryIdBelow = lastItemToMove.rawId;
    } else {
      entryIdsToMove = itemsToMove.map((item) => item.rawId);
      if (offset < 0) {
        entryIdAbove = sortedAllItems[newIndexOfFirstItemToMove - 1]?.rawId;
        entryIdBelow = sortedAllItems[newIndexOfFirstItemToMove]?.rawId;
      } else {
        entryIdAbove = sortedAllItems[newIndexOfFirstItemToMove + itemsToMove.length - 1]?.rawId;
        entryIdBelow = sortedAllItems[newIndexOfFirstItemToMove + itemsToMove.length]?.rawId;
      }
    }

    if (!entryIdAbove || !entryIdBelow) {
      throw new Error(`Missing entryIdAbove:[${entryIdAbove}] or entryIdBelow:[${entryIdBelow}]`);
    }

    await this.amazonMusicApi.moveTracksInPlaylist(collection.rawId, entryIdsToMove, entryIdAbove, entryIdBelow);
  }

  doesSupportReAuth(): boolean {
    return true;
  }

  public doesSupportSearchByISRC(): boolean {
    return true;
  }

  public doesSupportAlbums(): boolean {
    return false;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return false; // Should be implemented by Amazon soon
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return false;
  }

  public doesSupportMovingManyItems(): boolean {
    return true;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    switch (forCollection.type) {
      case CollectionType.PLAYLIST:
      case CollectionType.LIKED_PLAYLIST:
        return this.amazonMusicApi.getPaginatedPlaylistTracks(forCollection.rawId, onBatch);
      case CollectionType.MY_SONGS:
        return this.amazonMusicApi.getPaginatedLikedTracks(onBatch);
      default:
        return undefined;
    }
  }

  async getStatsData(updater?: CustomUpdaterMessageCallback) {
    const limitPerYear = 30;
    const recentlyPlayedCallback = getStatsMessageUpdater({ updater, messageType: 'recently' });
    const tracksCallback = getStatsMessageUpdater({ updater, messageType: 'tracks' });
    const recentlyPlayedTracksMap = await this.amazonMusicApi.getAllRecentlyPlayedTracks(recentlyPlayedCallback);
    const groupedRecentlyPlayedTracks = groupRecentlyPlayedTracks(recentlyPlayedTracksMap);
    const groupedRecentlyPlayedTracksEntries = Object.entries(groupedRecentlyPlayedTracks);

    const tracksMap = new Map<RawId, AmazonMusicCollectionTrack>();
    const allAlbumsIdsSet = new Set<string>();
    const allArtistsIdsSet = new Set<string>();

    const albumsIdsPerYear: IdsPerYear = new Map();
    const artistsIdsPerYear: IdsPerYear = new Map();

    const fetchLimit = 100;
    let processedTracks = 0;

    for (const [year, tracksStats] of groupedRecentlyPlayedTracksEntries) {
      let lastFetchedIndex = 0;
      let chunkIndex = 0;
      const trackForCurrentYear = new Set<string>();
      const albumsForCurrentYear = new Set<string>();
      const artistsForCurrentYear = new Set<string>();
      albumsIdsPerYear.set(year, albumsForCurrentYear);
      artistsIdsPerYear.set(year, artistsForCurrentYear);
      const tracksStatsChunks = chunk(tracksStats, fetchLimit);
      let tracksStatsChunk = tracksStatsChunks[chunkIndex];
      while (
        tracksStatsChunk &&
        (trackForCurrentYear.size < limitPerYear ||
          albumsForCurrentYear.size < limitPerYear ||
          artistsForCurrentYear.size < limitPerYear)
      ) {
        processedTracks += tracksStatsChunk.length;
        tracksCallback?.(processedTracks);
        const shouldFetchNextTracksPage = lastFetchedIndex < (chunkIndex + 1) * fetchLimit;
        if (shouldFetchNextTracksPage) {
          const tracksToFetch = [];
          for (
            let index = lastFetchedIndex;
            index < tracksStats.length && tracksToFetch.length < fetchLimit;
            index += 1
          ) {
            lastFetchedIndex = index;
            const trackStats = tracksStats[index];
            if (!trackStats || tracksMap.has(trackStats.rawId)) {
              continue;
            }
            tracksToFetch.push(trackStats);
          }
          const tracksPage = await this.amazonMusicApi.getTracksPage(tracksToFetch);
          tracksPage.forEach((track) => tracksMap.set(track.rawId, track));
        }

        tracksStatsChunk.forEach((trackStats) => {
          const track = tracksMap.get(trackStats.rawId);
          if (!track) {
            return;
          }
          tracksMap.set(track.rawId, track);
          if (trackForCurrentYear.size < limitPerYear) {
            trackForCurrentYear.add(track.rawId);
          }
          if (albumsForCurrentYear.size < limitPerYear && track.additionalData?.albumId) {
            albumsForCurrentYear.add(track.additionalData.albumId);
            allAlbumsIdsSet.add(track.additionalData.albumId);
          }
          if (artistsForCurrentYear.size < limitPerYear && track.additionalData?.artistsIds) {
            track.additionalData.artistsIds.forEach((artistId) => {
              artistsForCurrentYear.add(artistId);
              allArtistsIdsSet.add(artistId);
            });
          }
        });
        chunkIndex += 1;
        tracksStatsChunk = tracksStatsChunks[chunkIndex];
      }
    }

    const artistsIds = Array.from(allArtistsIdsSet.values());
    const albumsIds = Array.from(allAlbumsIdsSet.values());
    const artistsCallback = getStatsMessageUpdater({ updater, messageType: 'artists' });
    const albumsCallback = getStatsMessageUpdater({ updater, messageType: 'albums' });
    const artists = await this.amazonMusicApi.getArtists(artistsIds, (artistCount: number) => {
      artistsCallback?.(artistCount, artistsIds.length);
    });
    const albums = await this.amazonMusicApi.getAlbums(albumsIds, (albumsCount: number) => {
      albumsCallback?.(albumsCount, albumsIds.length);
    });
    const generateCallback = getStatsMessageUpdater({ updater, messageType: 'generate' });
    generateCallback?.();

    return generateStats({
      groupedRecentlyPlayedTracks,
      albumsIdsPerYear,
      artistsIdsPerYear,
      tracksMap,
      artists,
      albums,
      limitPerYear,
    });
  }
};
