import React, { useMemo } from 'react';
import { APIPaginationProps, APIPaginationRendererProps } from './types';
import { APIPaginationRenderer } from './APIPaginationRenderer';
import { TypedMemo } from '../../types';

const limitPages = 6;

export const APIPaginationComponent: React.FC<APIPaginationProps> = ({ total, limit, page, onPageChange }) => {
  const totalPages = Math.ceil(total / limit);
  const pages = useMemo(() => {
    const _pages: APIPaginationRendererProps['pages'] = [];
    if (page > 0) {
      _pages.push({
        key: 'prev',
        label: '<',
        page: page - 1,
      });
    }

    if (totalPages > limitPages) {
      if (page > limitPages / 2) {
        _pages.push({
          key: 'first',
          label: '1',
          page: 0,
          active: page === 0,
        });
      }
      if (page - 1 > limitPages / 2) {
        _pages.push({
          key: 'start-placeholder',
          label: '...',
          disabled: true,
        });
      }

      for (let i = -limitPages / 2; i <= limitPages / 2; i += 1) {
        const p = i + page;
        if (p < 0 || p > totalPages - 1) {
          // eslint-disable-next-line no-continue
          continue;
        }
        _pages.push({
          key: `${p}`,
          label: `${p + 1}`,
          active: p === page,
          page: p,
        });
      }
      if (page + 2 < totalPages - limitPages / 2) {
        _pages.push({
          key: `end-placeholder`,
          label: `...`,
          disabled: true,
        });
      }
      if (page + 1 < totalPages - limitPages / 2) {
        _pages.push({
          key: `last`,
          label: `${totalPages}`,
          active: page === totalPages - 1,
          page: totalPages - 1,
        });
      }
    } else {
      for (let i = 0; i < totalPages; i += 1) {
        _pages.push({
          key: `${i}`,
          label: `${i + 1}`,
          active: page === i,
          page: i,
        });
      }
    }

    if (page < totalPages - 1) {
      _pages.push({
        key: `next`,
        label: `>`,
        page: page + 1,
      });
    }
    return _pages;
  }, [page, totalPages]);

  if (totalPages <= 1) {
    return null;
  }

  return <APIPaginationRenderer pages={pages} onPageChange={onPageChange} />;
};

export const APIPagination = TypedMemo(APIPaginationComponent);
