import { AnghamiCollectionTrack } from './AnghamiCollectionTrack';
import { AnghamiPlaylist } from './AnghamiPlaylist';

export class AnghamiPlaylistWithTracksResponse {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get playlist(): AnghamiPlaylist | null {
    return AnghamiPlaylist.fromData(this.data);
  }

  get totalCount(): number {
    return this.data.PlaylistCount;
  }

  get tracks(): AnghamiCollectionTrack[] {
    if (!this.data.sections) {
      return [];
    }

    const section = this.data.sections.find((s: any) => s.type === 'song');
    if (!section) {
      return [];
    }
    return section.data.map((item: any) => AnghamiCollectionTrack.fromData(item));
  }
}
