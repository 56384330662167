import { wait } from './wait';
import { FetchError } from '../generics/errors/FetchError';
import { xhrFetch, Response } from './xhrFetch';

export interface RetryOptions {
  delay: number;
  statusCodes: number[];
  attempts: number;
}

export const defaultRetryOptions: RetryOptions = {
  delay: 1000,
  statusCodes: [429, 500, 501, 502, 503, 504],
  attempts: 5,
};

export const xhrFetchRetry = async (
  url: string,
  // eslint-disable-next-line default-param-last
  options: any = {},
  // eslint-disable-next-line default-param-last
  retryOptions: RetryOptions = defaultRetryOptions,
  _attempt?: number
): Promise<Response> => {
  const attempt = _attempt ?? retryOptions.attempts;
  if (_attempt !== undefined) {
    console.warn(
      `Retrying ${retryOptions.attempts - _attempt} times request to ${url} with options ${JSON.stringify(options)}`
    );
  }
  try {
    const response = await xhrFetch(url, options);
    if (retryOptions.statusCodes.indexOf(response.status) !== -1) {
      throw new FetchError(response.status, `Wrong response status code (${response.status})`);
    }
    return response;
  } catch (error) {
    if (attempt <= 1) {
      throw error;
    }
    await wait(retryOptions.delay);
    return xhrFetchRetry(url, options, retryOptions, attempt - 1);
  }
};
