import { CollectionItemModelData, GenericCollectionItem } from '../../../generics/models/GenericCollectionItem';
import { BoomplayTrackRawResponse } from './types';
import { BoomplayAlbum } from './BoomplayAlbum';
import { BoomplayArtist } from './BoomplayArtist';
import { convertBoomplayTrackData } from '../utils/convertBoomplayTrackData';

export class BoomplayCollectionTrack extends GenericCollectionItem {
  constructor(
    data: CollectionItemModelData,
    public albumExtended: BoomplayAlbum | null = null,
    public artists: BoomplayArtist[] | null = null
  ) {
    super(data);
  }

  static fromData(data: BoomplayTrackRawResponse | undefined): BoomplayCollectionTrack | null {
    const convertedData = convertBoomplayTrackData(data);
    if (!convertedData) {
      return null;
    }
    const { track, artists, album } = convertedData;
    return new BoomplayCollectionTrack({ ...track, globalId: track.rawId }, album, artists ?? null);
  }
}
