import { GaanaAlbum } from './GaanaAlbum';
import { tryParseInt } from '../../../utils/tryParseInt';

export class GaanaAlbumsResponse {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get albums(): GaanaAlbum[] {
    if (!this.data?.album) {
      return [];
    }
    return this.data?.album
      ?.map((item: any) => GaanaAlbum.fromData(item))
      .filter((album: GaanaAlbum | null) => !!album);
  }

  get total(): number {
    return tryParseInt(this.data.count) ?? 0;
  }
}
