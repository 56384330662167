import { PaddlePrice } from './PaddlePrice';
import { PaddleSubscription } from './PaddleSubscription';

export class PaddleProduct {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get id() {
    return this.data.product_id;
  }

  get title() {
    return this.data.product_title;
  }

  get currency() {
    return this.data.currency;
  }

  get price() {
    return new PaddlePrice(this.data.price);
  }

  get listPrice() {
    return new PaddlePrice(this.data.list_price);
  }

  get subscription() {
    if (!this.data.subscription) {
      return undefined;
    }
    return new PaddleSubscription(this.data.subscription);
  }
}
