import { GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { RessoPlaylistRawResponse } from './RessoResponseModels';

export class RessoPlayList extends GenericCollection {
  static fromData(data: RessoPlaylistRawResponse | undefined): RessoPlayList | null {
    if (!data?.id || !data.name) {
      return null;
    }
    return new RessoPlayList({
      type: CollectionType.PLAYLIST,
      rawId: data.id,
      name: data.name,
    });
  }
}
