import { CollectionItemModelData, GenericCollectionItem } from '../../../generics/models/GenericCollectionItem';
import { SpotifyAlbum } from './SpotifyAlbum';
import { SpotifyTrackResponseType } from './types';
import { SpotifyArtist } from './SpotifyArtist';
import { convertSpotifyTrackData } from '../utils/convertSpotifyTrackData';

export class SpotifyCollectionTrack extends GenericCollectionItem {
  constructor(
    data: CollectionItemModelData,
    public albumExtended: SpotifyAlbum | null = null,
    public artists: SpotifyArtist[] | null = null
  ) {
    super(data);
  }

  static fromData(data: SpotifyTrackResponseType | undefined): SpotifyCollectionTrack | null {
    const convertedData = convertSpotifyTrackData(data);
    if (!convertedData) {
      return null;
    }
    const { trackData, album, artists } = convertedData;
    return new SpotifyCollectionTrack({ ...trackData, globalId: trackData.rawId }, album, artists);
  }

  static fromAlbumData(
    data: Omit<SpotifyTrackResponseType, 'album'> | undefined,
    album: SpotifyAlbum
  ): SpotifyCollectionTrack | null {
    const track = SpotifyCollectionTrack.fromData(data);
    if (!track) {
      return track;
    }
    track.album = track.album ? track.album : album.name;
    track.imageUrl = track.imageUrl ? track.imageUrl : album.additionalData?.imageUrl ?? null;
    return track;
  }
}
