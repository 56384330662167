import React, { useEffect } from 'react';
import { TypedMemo } from '../../common/types';
import { useUserToken } from '../../../redux/slices/appInfo/hooks';
import { LoadingView } from '../../common/views/LoadingView';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';

const PrivateOnlyRouteControllerComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  const userToken = useUserToken();
  const router = useSMLRouter();

  useEffect(() => {
    if (userToken === null) {
      router.replace(SMLRoutes.signup);
    }
  }, [router, userToken]);

  if (userToken === null) {
    return <LoadingView />;
  }

  return <>{children}</>;
};

export const PrivateOnlyRouteController = TypedMemo(PrivateOnlyRouteControllerComponent);
