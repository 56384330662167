import React, { useCallback, useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import { useIntl } from 'react-intl';
import { Tooltip } from 'reactstrap';
import { TypedMemo } from '../../types';
import { CopyableWrapperProps } from './props';
import { ClickableDiv } from '../ClickableDiv';

const CopyableWrapperComponent: React.FC<React.PropsWithChildren<CopyableWrapperProps>> = ({
  textToCopy,
  children,
}) => {
  const intl = useIntl();
  const [tooltipIsOpen, setTooltipIsOpen] = useState<boolean>(false);
  const copyText = useCallback(() => {
    if (textToCopy === undefined) {
      return;
    }
    copy(textToCopy);
    setTooltipIsOpen(true);
  }, [textToCopy]);

  useEffect(() => {
    if (!tooltipIsOpen) {
      return undefined;
    }
    const timeoutId = setTimeout(() => setTooltipIsOpen(false), 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [tooltipIsOpen]);

  return (
    <>
      <Tooltip target="copyable-text" isOpen={tooltipIsOpen}>
        {intl.formatMessage({ defaultMessage: 'Copied', id: 'app.copyable_wrapper.copied' })}
      </Tooltip>
      <ClickableDiv id="copyable-text" onClick={copyText} disabled={textToCopy === undefined}>
        {children}
      </ClickableDiv>
    </>
  );
};

export const CopyableWrapper = TypedMemo(CopyableWrapperComponent);
