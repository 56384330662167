import { Artist } from './models/GaanaResponseModels';
import { artistsToString } from '../../utils/artistsConverter';
import { validateISRC } from '../../utils/validateISRC';
import { tryParseDurationToMs } from '../../utils/tryParseDurationToMs';

export function getArtistsNamesAsString(artists: Artist[] | undefined) {
  if (!artists || artists.length === 0) {
    return '';
  }

  const artistsNames = artists.reduce((result, artist) => {
    if (artist.name) {
      result.push(artist.name);
    }
    return result;
  }, [] as string[]);

  return artistsToString(artistsNames);
}

const gaanaPublicResourceUrl = 'https://gaana.com';

export function createGaanaAlbumUrl(seokey: string | undefined) {
  if (!seokey) {
    return undefined;
  }
  return `${gaanaPublicResourceUrl}/album/${seokey}`;
}

export function createGaanaPlaylistUrl(seokey: string | undefined) {
  if (!seokey) {
    return undefined;
  }
  return `${gaanaPublicResourceUrl}/playlist/${seokey}`;
}

export function createGaanaTrackUrl(seokey: string | undefined) {
  if (!seokey) {
    return undefined;
  }
  return `${gaanaPublicResourceUrl}/song/${seokey}`;
}

export function convertGaanaTrackData(data: any, url?: string) {
  if ((!data.track_id && !data.seokey) || !data.track_title) {
    return null;
  }
  const id = `${data.track_id}|${data.seokey}`;
  return {
    rawId: id,
    name: data.track_title,
    album: data.album_title || data.track_title,
    artist: getArtistsNamesAsString(data.artist),
    isrc: validateISRC(data?.isrc),
    imageUrl: data.artwork_large,
    duration: tryParseDurationToMs(data.duration, 's'),
    sourceURL: url ?? createGaanaTrackUrl(data.seokey),
  };
}
