import { Analytics } from '../../../analytics/fym/analytics';
import { analyticsPurchasePlanToPaddleSku } from './paddleAnalytics';
import { OpenSmartLinksPaddlePaymentProps } from './types';

export async function openSmartLinksPaddlePayment({
  email,
  licenseKey,
  plan,
  deviceId,
  coupon,
  locale,
  onSuccess,
}: OpenSmartLinksPaddlePaymentProps): Promise<void> {
  const product = analyticsPurchasePlanToPaddleSku[plan];
  window.Paddle.Checkout.open({
    product,
    email,
    coupon,
    passthrough: JSON.stringify({ licenseId: licenseKey, deviceId, language: locale }),
    displayModeTheme: 'light',
    successCallback(data: any) {
      onSuccess(data.checkout.id);
    },
  });
  Analytics.trackPurchaseStarted(plan).catch(console.error);
  return Promise.resolve();
}
