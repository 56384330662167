import { ReleaseChannel, ReleaseChannelApp } from '../types/releaseChannel';

export enum Environment {
  local = 'local',
  staging = 'staging',
  production = 'production',
  localProduction = 'localProduction',
  uitest = 'uitest',
}

export enum Platform {
  electron = 'electron',
  ios = 'ios',
  android = 'android',
  web = 'web',
  server = 'server',
}

export enum AppTarget {
  freeyourmusic = 'freeyourmusic',
  smartlinks = 'smartlinks',
  server = 'server',
}

export function isAppTarget(target: string): target is AppTarget {
  return Object.values<string>(AppTarget).includes(target);
}

export interface ConfigSchema {
  fymApiURL: (supportsCORS: boolean) => string;
  musicApiURL: string;
  musicAppURL: string;
  musicApiToken: string;
  fymWebURL: string;
  smartLinksWebURL: string;
  boomplayAppId: string;
  amazonSecurityProfileId: string;
  loadLocalRealm: boolean;
  protocolSchema: string;
  appDomains: string[];
  smartLinkAppUrl: string;
  smartLinkCookieDomain: string;
  appReleaseLinks: {
    app: ReleaseChannelApp;
    channel: ReleaseChannel;
  };
  environment: Environment;
  target: AppTarget;
  platform: Platform;
  themePreviewURL: (theme: string, forDesktop: boolean) => string;
  appleSubscriptionManagementSupportURL: string;
  googlePlaySubscriptionManagementSupportURL: string;
}

export type Config = (platform: Platform, target: AppTarget, environment: Environment) => ConfigSchema;
