import React from 'react';
import { TypedMemo } from '../../../common/types';
import { FormInputContainerProps } from './props';
import { FormLabel } from '../FormLabel/FormLabel';
import { FormGroup } from '../FormGroup/FormGroup';
import { Alert } from '../Alert/Alert';
import { AlertLevel } from '../Alert/props';
import { FormHelp } from '../FormHelp/FormHelp';

const FormInputContainerComponent: React.FC<React.PropsWithChildren<FormInputContainerProps>> = ({
  name,
  label,
  error,
  help,
  children,
}) => {
  return (
    <FormGroup>
      {!!label && <FormLabel forName={name}>{label}</FormLabel>}
      {help !== undefined && <FormHelp>{help}</FormHelp>}
      {children}
      {!!error && <Alert level={AlertLevel.danger}>{error}</Alert>}
    </FormGroup>
  );
};

export const FormInputContainer = TypedMemo(FormInputContainerComponent);
