import React from 'react';
import { Col } from 'reactstrap';
import { TypedMemo } from '../../../common/types';
import { TrackersListItemRendererProps } from './props';
import { ClickableDiv } from '../../../common/views/ClickableDiv';

const TrackersListItemRendererComponent: React.FC<TrackersListItemRendererProps> = ({
  Logo,
  name,
  color,
  onClick,
  trackerId,
}) => {
  return (
    <Col xs={12} sm={6} md={4} lg={3} className="my-2 m-md-2">
      <ClickableDiv
        onClick={onClick}
        style={{ border: `2px solid ${color}`, borderRadius: '8px' }}
        className="d-flex w-100 h-100 flex-md-column justify-content-center align-items-center px-4 py-2 p-sm-2"
      >
        <Logo width="75px" height="75px" />
        <div className="d-flex flex-grow-1 flex-column overflow-hidden w-100">
          <h5 className="mt-2 text-center w-100">{name}</h5>
          <p className="text-center text-muted">{trackerId}</p>
        </div>
      </ClickableDiv>
    </Col>
  );
};

export const TrackersListItemRenderer = TypedMemo(TrackersListItemRendererComponent);
