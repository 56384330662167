import qs from 'qs';
import { FetchError } from '../../generics/errors/FetchError';
import { defaultRetryOptions, fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { ImporterID } from '../types';
import { BoomplayPlaylist } from './models/BoomplayPlaylist';
import { BoomplayPlaylistsResponse } from './models/BoomplayPlaylistsResponse';
import { BoomplayAlbum } from './models/BoomplayAlbum';
import { BoomplayAlbumsResponse } from './models/BoomplayAlbumsResponse';
import { BoomplayUser } from './models/BoomplayUser';
import { config } from '../../config/config';
import { BoomplayCollectionTrack } from './models/BoomplayCollectionTrack';
import { BoomplayPlaylistPrivacy, BoomplayPlaylistsRawResponse, BoomplayTrackListRawResponse } from './models/types';
import { BoomplayTracksResponse } from './models/BoomplayTracksResponse';
import { BoomplayMySongsResponse } from './models/BoomplayMySongsResponse';
import { SearchQueryProperties } from '../../generics/types';
import { BoomplaySearchResponse } from './models/BoomplaySearchResponse';
import { wait } from '../../utils/wait';
import { CollectionType } from '../../generics/models/Collection';
import { Response } from '../../utils/fetch-types';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';

export class BoomplayAPI {
  private static BASE_URL = 'https://openapi.boomplay.com/';

  private readonly accessToken;

  public userId?;

  constructor(accessToken: string, userId?: string) {
    this.accessToken = accessToken;
    this.userId = userId;
  }

  private get requestHeaders(): Record<string, string> {
    return {
      Accept: 'application/json; charset=utf-8',
      'Content-Type': 'application/json',
      Authorization: `${this.accessToken}`,
      app_id: config.boomplayAppId,
    };
  }

  async fetch(
    url: string,
    options: any,
    retryOptions: RetryOptions = defaultRetryOptions,
    retries = 0
  ): Promise<ReturnType<Response['json']>> {
    const response = await fetchRetry(url, options, retryOptions);

    if (response.status === 401) {
      const text = await response.text();
      throw new NotAuthenticatedError({ authId: this.userId, importerId: ImporterID.Boomplay }, text);
    }
    if (response.status !== 200 && response.status !== 201) {
      const text = await response.text();
      throw new FetchError(response.status, text);
    }

    const data = await response.json();
    if (data.code === 4005) {
      if (retries >= 5) {
        throw new Error(`Limit exceeded for Boomplay`);
      }
      // {"desc":"Current limit exceeded","code":4005,"data":null}'
      await wait(1000);
      return this.fetch(url, options, retryOptions, retries + 1);
    }
    if (data.code === 4002) {
      // token expire
      throw new NotAuthenticatedError(
        {
          authId: this.userId,
          importerId: ImporterID.Boomplay,
        },
        JSON.stringify(data)
      );
    }
    return data;
  }

  async fetchMe(): Promise<BoomplayUser> {
    const data = await this.fetch(`${BoomplayAPI.BASE_URL}user/getUserInfo`, {
      method: 'GET',
      headers: this.requestHeaders,
    });
    return new BoomplayUser(data);
  }

  async loadPaginatedOwnPlaylists(onBatch: (collections: BoomplayPlaylist[]) => Promise<void>): Promise<void> {
    const limit = 30;
    const loadPlaylists = async (offset: number): Promise<void> => {
      const playlistResponse = await this.loadOwnPlaylistPage(offset, limit);
      await onBatch(playlistResponse.playlists);
      if (playlistResponse.counts !== undefined && playlistResponse.counts > offset) {
        await loadPlaylists(offset + limit);
      }
    };
    await loadPlaylists(0);
  }

  async loadOwnPlaylistPage(offset: number, limit: number): Promise<BoomplayPlaylistsResponse> {
    const url = `${BoomplayAPI.BASE_URL}user/playlists?${qs.stringify({
      offset: offset / limit,
      limit,
    })}`;

    const data = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });
    return new BoomplayPlaylistsResponse(data, CollectionType.PLAYLIST);
  }

  async loadPaginatedLikedPlaylists(onBatch: (collections: BoomplayPlaylist[]) => Promise<void>): Promise<void> {
    const limit = 30;
    const loadPlaylists = async (offset: number): Promise<void> => {
      const playlistResponse = await this.loadLikedPlaylistPage(offset, limit);
      await onBatch(playlistResponse.playlists);
      if (playlistResponse.counts !== undefined && playlistResponse.counts > offset) {
        await loadPlaylists(offset + limit);
      }
    };

    await loadPlaylists(0);
  }

  async loadLikedPlaylistPage(offset: number, limit: number): Promise<BoomplayPlaylistsResponse> {
    const url = `${BoomplayAPI.BASE_URL}favourite/v1/playlists?${qs.stringify({
      offset,
      limit,
    })}`;

    const data = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });
    return new BoomplayPlaylistsResponse(data, CollectionType.LIKED_PLAYLIST);
  }

  async loadPaginatedAlbums(onBatch: (collections: BoomplayAlbum[]) => Promise<void>): Promise<void> {
    const limit = 30;
    const loadAlbums = async (offset: number): Promise<void> => {
      const albumResponse = await this.loadAlbumPage(offset, limit);
      await onBatch(albumResponse.albums);
      if (albumResponse.next) {
        await loadAlbums(offset + limit);
      }
    };
    await loadAlbums(0);
  }

  async loadAlbumPage(offset: number, limit: number) {
    const url = `${BoomplayAPI.BASE_URL}favourite/v1/albums?${qs.stringify({
      offset,
      limit,
    })}`;

    const data = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });
    return new BoomplayAlbumsResponse(data);
  }

  async loadAlbum(albumId: string): Promise<BoomplayAlbum | null> {
    const url = `${BoomplayAPI.BASE_URL}album/v1/albumIds?${qs.stringify({
      ids: albumId,
    })}`;
    const data = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });
    return BoomplayAlbum.fromData(data.data[0]);
  }

  async loadPlaylist(id: string): Promise<BoomplayPlaylist | null> {
    const url = `${BoomplayAPI.BASE_URL}playlist/v1/playlistIds?${qs.stringify({
      ids: id,
    })}`;
    const data = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });
    if (data?.data.length === 0) {
      throw new CollectionDoesNotExistsError();
    }
    // No way to check if it is ours or not (except for loading all our playlists)
    return BoomplayPlaylist.fromData(data.data[0], CollectionType.PLAYLIST);
  }

  async loadPaginatedPlaylistItems(
    id: string,
    onBatch: (tracks: BoomplayCollectionTrack[]) => Promise<void>
  ): Promise<void> {
    const limit = 30;
    const loadPlaylistsItems = async (offset: number): Promise<void> => {
      const trackResponse = await this.loadPlaylistItemsPage(id, offset, limit);
      await onBatch(trackResponse.tracks);
      // DO NOT LOAD NEXT PAGES
      // Boomplay does not support it even though docs allow it
    };

    await loadPlaylistsItems(0);
  }

  async loadPlaylistItemsPage(
    id: string,
    offset: number,
    limit: number
  ): Promise<{ response: BoomplayPlaylistsRawResponse; tracks: BoomplayCollectionTrack[] }> {
    const url = `${BoomplayAPI.BASE_URL}playlist/v1/playlistIds?${qs.stringify({
      offset,
      limit,
      ids: id,
    })}`;

    const data: BoomplayPlaylistsRawResponse = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });
    if (data?.data?.[0]?.tracks) {
      return { response: data, tracks: await this.loadAllTracks(data.data[0].tracks) };
    }
    return { response: data, tracks: [] };
  }

  async loadAllTracks(boomplayTracksList: BoomplayTrackListRawResponse[]): Promise<BoomplayCollectionTrack[]> {
    let tracks: BoomplayCollectionTrack[] = [];
    const loadPlaylistsItems = async (offset: number, limit: number): Promise<void> => {
      const trackResponse = await this.loadTracksPage(boomplayTracksList, offset, limit);
      tracks = [...tracks, ...trackResponse.tracks];
      if (tracks.length < boomplayTracksList.length) {
        await loadPlaylistsItems(offset, offset + limit);
      }
    };

    await loadPlaylistsItems(0, 20);
    return tracks;
  }

  async loadTracksPage(
    boomplayTracks: BoomplayTrackListRawResponse[],
    offset: number,
    limit: number
  ): Promise<BoomplayTracksResponse> {
    const listTracks = boomplayTracks
      .slice(offset, limit)
      .map((track) => `${track.track_id}`)
      .join(',');

    const url = `${BoomplayAPI.BASE_URL}track/v1/trackIds?${qs.stringify({
      ids: listTracks,
    })}`;

    const data = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });
    return new BoomplayTracksResponse(data);
  }

  async loadPaginatedAlbumItems(
    id: string,
    onBatch: (tracks: BoomplayCollectionTrack[]) => Promise<void>
  ): Promise<void> {
    const loadAlbumItems = async (): Promise<void> => {
      const trackResponse = await this.loadAlbumItemsPage(id);
      await onBatch(trackResponse);
    };

    await loadAlbumItems();
  }

  async loadAlbumItemsPage(id: string): Promise<BoomplayCollectionTrack[]> {
    const url = `${BoomplayAPI.BASE_URL}album/v1/albumIds?${qs.stringify({
      ids: id,
    })}`;
    const data = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });
    return this.loadAllTracks(data.data[0].tracks);
  }

  async loadPaginatedMySongs(onBatch: (tracks: BoomplayCollectionTrack[]) => Promise<void>): Promise<void> {
    const limit = 30;
    const loadMySongs = async (offset: number): Promise<void> => {
      const playlistResponse = await this.loadMySongsPage(offset, limit);
      await onBatch(playlistResponse.tracks);
      if (playlistResponse.next) {
        await loadMySongs(offset + limit);
      }
    };

    await loadMySongs(0);
  }

  async loadMySongsPage(offset: number, limit: number): Promise<BoomplayMySongsResponse> {
    const url = `${BoomplayAPI.BASE_URL}favourite/v1/tracks?${qs.stringify({
      offset,
      limit,
    })}`;

    const data = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });
    return new BoomplayMySongsResponse(data);
  }

  async createPlaylist(
    name: string,
    props?: { isPublic?: boolean; description?: string }
  ): Promise<BoomplayPlaylist | null> {
    const { isPublic = false, description } = props ?? {};
    const data = await this.fetch(`${BoomplayAPI.BASE_URL}user/playlists`, {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        name,
        description,
        privacy: isPublic ? BoomplayPlaylistPrivacy.public : BoomplayPlaylistPrivacy.private,
      }),
    });
    return BoomplayPlaylist.fromData(data.data, CollectionType.PLAYLIST);
  }

  async updatePlaylist(
    playlistId: string,
    { name, isPublic, description }: { name?: string; isPublic?: boolean; description?: string }
  ): Promise<BoomplayPlaylist | null> {
    const data = await this.fetch(`${BoomplayAPI.BASE_URL}playlist/v1/${playlistId}`, {
      method: 'PUT',
      headers: this.requestHeaders,
      body: JSON.stringify({
        name,
        description,
        privacy:
          isPublic === undefined
            ? undefined
            : isPublic
            ? BoomplayPlaylistPrivacy.public
            : BoomplayPlaylistPrivacy.private,
      }),
    });
    return BoomplayPlaylist.fromData(data.data, CollectionType.PLAYLIST);
  }

  async addTracksToPlaylist(playlistId: string, trackIds: string[]): Promise<void> {
    await this.fetch(`${BoomplayAPI.BASE_URL}playlist/v1/${playlistId}/tracks`, {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        track_ids: trackIds,
      }),
    });
  }

  async removeTracksFromPlaylist(playlistId: string, trackIds: string[]): Promise<void> {
    if (trackIds.length === 0) {
      return;
    }
    const body = JSON.stringify({
      track_ids: trackIds,
    });
    await this.fetch(`${BoomplayAPI.BASE_URL}playlist/v1/${playlistId}/tracks`, {
      method: 'DELETE',
      headers: this.requestHeaders,
      body,
    });
  }

  async search(props: {
    queryProps: SearchQueryProperties;
    searchType: 'track' | 'album' | 'artist';
    limit?: number;
    offset?: number;
  }): Promise<BoomplaySearchResponse> {
    const { queryProps, searchType, limit = 10, offset = 0 } = props;
    const query = queryProps[searchType];
    if (!query) {
      return new BoomplaySearchResponse(null);
    }
    const url = `${BoomplayAPI.BASE_URL}search/v1?${qs.stringify({
      q: query,
      type: searchType,
      limit,
      offset,
    })}`;

    const data = await this.fetch(
      url,
      {
        method: 'GET',
        headers: this.requestHeaders,
      },
      { ...defaultRetryOptions, shouldWait: true }
    );
    return new BoomplaySearchResponse(data);
  }

  async addAlbumToLibrary(ids: string): Promise<void> {
    await this.fetch(`${BoomplayAPI.BASE_URL}favourite/v1/albums`, {
      method: 'PUT',
      headers: this.requestHeaders,
      body: JSON.stringify({ ids: [ids] }),
    });
  }

  async searchByISRC(isrc: string): Promise<BoomplayTracksResponse> {
    if (!isrc) {
      return new BoomplayTracksResponse(null);
    }
    const url = `${BoomplayAPI.BASE_URL}track/v1/isrc/${isrc}`;
    const data = await this.fetch(
      url,
      {
        method: 'GET',
        headers: this.requestHeaders,
      },
      { ...defaultRetryOptions, shouldWait: true }
    );
    return new BoomplayTracksResponse(data);
  }
}
