import React, { useCallback } from 'react';
import { TypedMemo } from '../../common/types';
import { SMLBioLinkBoxRenderer } from './SMLBioLinkBoxRenderer';
import { SMLBioLinkBoxProps } from './props';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';

const SMLBioLinkBoxComponent: React.FC<SMLBioLinkBoxProps> = ({ accountId, mainSmartLinkId }) => {
  const router = useSMLRouter();

  const goToMainSmartLinkManageView = useCallback(() => {
    router.push(SMLRoutes.manage, { accountId, smartLinkId: mainSmartLinkId });
  }, [router, accountId, mainSmartLinkId]);

  return <SMLBioLinkBoxRenderer onClick={goToMainSmartLinkManageView} />;
};

export const SMLBioLinkBox = TypedMemo(SMLBioLinkBoxComponent);
