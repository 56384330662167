import { BoomplayArtistRawResponse } from './types';
import { GenericAuthor } from '../../../generics/models/GenericAuthor';

export class BoomplayArtist extends GenericAuthor {
  static fromData(data: BoomplayArtistRawResponse | undefined): BoomplayArtist | null {
    if (!data || data.artist_id === undefined || !data.artist_name) {
      return null;
    }
    return new BoomplayArtist({
      rawId: `${data.artist_id}`,
      name: data.artist_name,
    });
  }

  static fromShareUrlData(data: any) {
    const urlParts = data?.['@id']?.split('/');
    if (!data || !urlParts) {
      return null;
    }
    const rawId = urlParts[urlParts.length - 1];
    return new BoomplayArtist({
      rawId,
      name: data.name,
      imageUrl: data.image,
      publicUrl: data.url ?? data['@id'],
    });
  }
}
