import { DeezerCollectionTrack } from './DeezerCollectionTrack';
import { DeezerListRaw } from '../rawTypes/DeezerListRaw';
import { DeezerTrackRaw } from '../rawTypes/DeezerTrackRaw';

export class DeezerTracksResponse {
  private readonly data;

  constructor(data: DeezerListRaw<DeezerTrackRaw> | null) {
    this.data = data;
  }

  get tracks(): DeezerCollectionTrack[] {
    if (!this.data?.data) {
      return [];
    }
    return this.data?.data.reduce<DeezerCollectionTrack[]>((results, trackData) => {
      const track = DeezerCollectionTrack.fromData(trackData);
      return track ? [...results, track] : results;
    }, []);
  }

  get total() {
    return this.data?.total;
  }

  get next() {
    return this.data?.next;
  }
}
