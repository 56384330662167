import { TransferCollectionStatus } from 'fym-common/src/realm/consts';
import { ImporterID } from 'fym-common/src/importers//types';
import { CollectionType } from 'fym-common/src/generics/models/Collection';
import { CollectionModel } from '../../realm/models/CollectionModel';
import { ImporterAuthenticationModel } from '../../realm/models/ImporterAuthenticationModel';
import { TransferCollectionModel } from '../../realm/models/TransferCollectionModel';

export type TransferCollectionAccount = {
  idString: ReturnType<ImporterAuthenticationModel['_id']['toHexString']>;
  title: ImporterAuthenticationModel['title'];
  url: ImporterAuthenticationModel['imageUrl'];
  needsReauth: boolean;
  importerId: ImporterID;
  authId: string;
};

export interface TransferCollectionSummary {
  name: CollectionModel['name'];
  targetName: CollectionModel['name'];
  sourceImporterId: ImporterID;
  targetImporterId: ImporterID;
  doesSupportOrderingItems: boolean;
  covers: string[] | undefined;
  completed: boolean;
  isPaused: boolean;
  status: TransferCollectionStatus;
  isFailed: boolean;
  wasTargetAlbumFound: boolean;
  lastSyncedAt: number | undefined;
  type: CollectionType;
  isTransferToLikedSongs: boolean;
  hasItemsWhichCannotBeDeleted: boolean;
  processedOnce: boolean;
}
export interface TransferCollectionQuerySummary {
  idString: ReturnType<TransferCollectionModel['_id']['toHexString']>;
  name: CollectionModel['name'];
  completed: boolean;
  status: TransferCollectionStatus;
  isFailed: boolean;
  createdAt: number;
}

export enum SongFilterOptions {
  ALL = 'ALL',
  LOW_MATCH = 'LOW_MATCH',
  MISSING = 'MISSING',
  REMATCHED = 'REMATCHED',
  FAILED = 'FAILED',
}

export const LOW_MATCH_PERCENTAGE = 30;
