import { ReactComponent as AppleLight } from '../../../../assets/images/music-services/apple-light.svg';
import { ReactComponent as AppleDark } from '../../../../assets/images/music-services/apple-dark.svg';
import { ReactComponent as AppleLightPadding } from '../../../../assets/images/music-services/apple-light-padding.svg';
import { ReactComponent as AppleDarkPadding } from '../../../../assets/images/music-services/apple-dark-padding.svg';
import { ReactComponent as AppleColorLight } from '../../../../assets/images/music-services/apple-color-light.svg';
import { ReactComponent as AppleColorDark } from '../../../../assets/images/music-services/apple-color-dark.svg';
import { ReactComponent as AppleColorLightPadding } from '../../../../assets/images/music-services/apple-color-light-padding.svg';
import { ReactComponent as AppleColorDarkPadding } from '../../../../assets/images/music-services/apple-color-dark-padding.svg';
import { ReactComponent as AppleDarkDanger } from '../../../../assets/images/music-services-danger/apple-dark-danger.svg';
import { ReactComponent as AppleLightDanger } from '../../../../assets/images/music-services-danger/apple-light-danger.svg';
import { ReactComponent as AppleLightDangerPadding } from '../../../../assets/images/music-services-danger/apple-light-padding-danger.svg';
import { ReactComponent as AppleDarkDangerPadding } from '../../../../assets/images/music-services-danger/apple-dark-padding-danger.svg';
import { ReactComponent as AppleDarkWarning } from '../../../../assets/images/music-services-warning/apple-dark-warning.svg';
import { ReactComponent as AppleLightWarning } from '../../../../assets/images/music-services-warning/apple-light-warning.svg';
import { ReactComponent as AppleLightWarningPadding } from '../../../../assets/images/music-services-warning/apple-light-padding-warning.svg';
import { ReactComponent as AppleDarkWarningPadding } from '../../../../assets/images/music-services-warning/apple-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const appleMusicImages: ImporterImages = {
  logo: {
    light: AppleLight,
    dark: AppleDark,
  },
  logoPadding: {
    light: AppleLightPadding,
    dark: AppleDarkPadding,
  },
  logoColor: {
    light: AppleColorLight,
    dark: AppleColorDark,
  },
  logoColorPadding: {
    light: AppleColorLightPadding,
    dark: AppleColorDarkPadding,
  },
  logoOnBackground: AppleColorDark,
  logoDanger: {
    dark: AppleDarkDanger,
    light: AppleLightDanger,
  },
  logoDangerPadding: {
    dark: AppleDarkDangerPadding,
    light: AppleLightDangerPadding,
  },
  logoWarning: {
    dark: AppleDarkWarning,
    light: AppleLightWarning,
  },
  logoWarningPadding: {
    light: AppleLightWarningPadding,
    dark: AppleDarkWarningPadding,
  },
};
