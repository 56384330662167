import { AppleMusicPlaylist } from './AppleMusicPlaylist';
import { AppleMusicPlaylistsRawResponse } from './types';

export class AppleMusicPlaylistsResponse {
  private readonly data;

  constructor(data: AppleMusicPlaylistsRawResponse | undefined) {
    this.data = data;
  }

  get playlists(): AppleMusicPlaylist[] {
    if (!this.data?.data) {
      return [];
    }
    return this.data?.data
      ?.map((item) => AppleMusicPlaylist.fromData(item))
      .filter((playlist): playlist is AppleMusicPlaylist => !!playlist);
  }

  get next(): string | undefined {
    return this.data?.next;
  }

  get total(): number | undefined {
    return this.data?.meta?.total;
  }
}
