import React from 'react';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../../common/types';
import { ClickableDiv } from '../../common/views/ClickableDiv';
import { SMLBioLinkBoxRendererProps } from './props';
import { SMLButton } from '../../common/views/SMLButton/SMLButton';

const SMLBioLinkBoxRendererComponent: React.FC<SMLBioLinkBoxRendererProps> = ({ onClick }) => {
  const intl = useIntl();
  return (
    <ClickableDiv onClick={onClick} className="sml-box sml-artist-page-box shadow" testID="ArtistPage">
      <h2 className="text-white m-0 text-center">
        {intl.formatMessage({ id: 'app.smart-links.list.bio-link-manage-title', defaultMessage: 'Bio Link' })}
      </h2>
      <SMLButton color="light" size="md">
        <span className="text-primary">
          {intl.formatMessage({
            id: 'app.smart-links.list.bio-link-manage',
            defaultMessage: 'Manage',
          })}
        </span>
      </SMLButton>
    </ClickableDiv>
  );
};

export const SMLBioLinkBoxRenderer = TypedMemo(SMLBioLinkBoxRendererComponent);
