import React, { useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faKey } from '@fortawesome/pro-solid-svg-icons/faKey';
import { useStore } from 'react-redux';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FieldsName, FieldsType } from './consts';
import { createResolver } from './validators';
import { FormInput } from '../../../forms/components/FormInput/FormInput';
import { InputType } from '../../../forms/types';
import { RootStateType } from '../../../../redux/store';
import { SMLRoutes } from '../../../../routing/sml/SMLRoutes';
import { useSMLRouter } from '../../../../routing/sml/hooks';
import { isSuccessfulResult } from '../../../../redux/api/utils/isSuccessfulResult';
import { useCreateSMLTrialLicenseMutation } from '../../../../redux/api/fymAPI';
import { ErrorAlert } from '../../../common/views/ErrorAlert/ErrorAlert';
import { SMLButton } from '../../../common/views/SMLButton/SMLButton';

export function useSignUpForm() {
  const intl = useIntl();
  const store = useStore<RootStateType>();
  const resolver = useMemo(() => createResolver(store), [store]);
  const router = useSMLRouter();

  const [createSMLTrialLicense, { isLoading, error }] = useCreateSMLTrialLicenseMutation();

  const onSubmit: SubmitHandler<FieldsType> = useCallback(
    async ({ email, password, name }) => {
      const result = await createSMLTrialLicense({
        email,
        password,
        language: intl.locale,
        existingLicenseId: '',
        name,
      });

      if (!isSuccessfulResult(result)) {
        return;
      }
      router.popToRoot();
      router.replace(SMLRoutes.home);
    },
    [createSMLTrialLicense, intl.locale, router]
  );

  const { control, handleSubmit } = useForm<FieldsType>({
    resolver,
    defaultValues: {
      [FieldsName.email]: '',
      [FieldsName.password]: '',
      [FieldsName.name]: '',
    },
  });

  const submit = useCallback(
    (event?: React.BaseSyntheticEvent) => {
      handleSubmit(onSubmit)(event).catch(console.error);
    },
    [handleSubmit, onSubmit]
  );

  return {
    submit,
    NameInput: (
      <FormInput
        name={FieldsName.name}
        control={control}
        type={InputType.text}
        disabled={isLoading}
        icon={faUser}
        placeholder={intl.formatMessage({
          id: 'app.forms.login.name.placeholder',
          defaultMessage: 'Enter your name',
        })}
        label={intl.formatMessage({
          id: 'app.forms.login.name.label',
          defaultMessage: 'Name',
        })}
      />
    ),
    EmailInput: (
      <FormInput
        name={FieldsName.email}
        control={control}
        type={InputType.email}
        disabled={isLoading}
        icon={faEnvelope}
        placeholder={intl.formatMessage({
          id: 'app.forms.login.email.placeholder',
          defaultMessage: 'Enter your email',
        })}
        label={intl.formatMessage({
          id: 'app.forms.login.email.label',
          defaultMessage: 'Email',
        })}
      />
    ),
    PasswordInput: (
      <FormInput
        name={FieldsName.password}
        control={control}
        type={InputType.password}
        disabled={isLoading}
        icon={faKey}
        placeholder={intl.formatMessage({
          id: 'app.forms.login.password.placeholder',
          defaultMessage: 'Enter your password',
        })}
        label={intl.formatMessage({
          id: 'app.forms.login.password.label',
          defaultMessage: 'Password',
        })}
      />
    ),
    SubmitButton: (
      <SMLButton
        color="secondary"
        onClick={submit}
        disabled={isLoading}
        isLoading={isLoading}
        stretch
        omitCap
        size="lg"
        submit
      >
        {intl.formatMessage({
          id: 'app.sml.sign_up.submit_label',
          defaultMessage: 'Create FREE account',
        })}
      </SMLButton>
    ),
    ErrorView: <>{error && <ErrorAlert error={error} />}</>,
  };
}
