import { MusicService } from './types';
import { MusicAPIIntegrationID } from '../types';
import { ImporterID } from '../../importers/types';
import { LinkType } from '../../utils/recognizeLinkTypeByUrl';

export const boomplay: MusicService = {
  id: 'boomplay',
  importerID: ImporterID.Boomplay,
  linkType: LinkType.boomplay,
  musicAPIID: MusicAPIIntegrationID.Boomplay,
  shortName: 'Boomplay',
  name: 'Boomplay',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: true,
  isSupported: true,
  color: { label: '#02D8D8', background: '#02D8D877' },
  addingTracksLimit: 20, // https://developer.boomplay.com/openApi/playlist/PlaylistAddTracks.html
};
