import { SearchType } from 'fym-common/src/types/searchService';
import { config } from 'fym-common/src/config/config';
import { Environment } from 'fym-common/src/config/types';
import { SMLGenericAnalytics } from './genericAnalytics';
import { AnalyticsPurchasePlan } from '../types';

export const SMLAnalytics = {
  ...SMLGenericAnalytics,
  async track(event: string, data?: any) {
    if (config.environment === Environment.local) {
      console.log(`Analytics: ${event}\n${JSON.stringify(data)}`);
    }
    await SMLGenericAnalytics.track(event, data);
  },
  async trackPurchaseStarted(plan: AnalyticsPurchasePlan) {
    await SMLAnalytics.track('purchase_started', {
      plan,
    });
  },
  async trackPurchaseCompleted(data: { plan: AnalyticsPurchasePlan; value?: number; currency?: string }) {
    await SMLAnalytics.track('purchase_completed', data);
  },
  async trackPurchaseRestored(plan: AnalyticsPurchasePlan) {
    await SMLAnalytics.track('purchase_restored', { plan });
  },
  async trackPurchaseFailed(code?: string, message?: string) {
    await SMLAnalytics.track('purchase_failed', { code, message });
  },
  async trackCreateStarted() {
    await SMLAnalytics.track('create_started', {});
  },
  async trackCreateOpened(type: 'custom' | 'playlist' | 'release-link') {
    await SMLAnalytics.track('create_opened', { type });
  },
  async trackCustomSmartLinkCreated() {
    await SMLAnalytics.track('custom_smartLink_created', {});
  },
  async trackMediaSmartLinkStarted(type: SearchType) {
    await SMLAnalytics.track('media_smartLink_started', { type });
  },
  async trackMediaSmartLinkCreated() {
    await SMLAnalytics.track('media_smart_link_created', {});
  },
  async trackPlaylistSmartLinkStarted() {
    await SMLAnalytics.track('playlist_smart_link_started', {});
  },
  async trackPlaylistSmartLinkCreated() {
    await SMLAnalytics.track('playlist_smart_link_created', {});
  },
  async trackSmartLinkCustomizeStarted() {
    await SMLAnalytics.track('smart_link_customize_started', {});
  },
  async trackSmartLinkCustomizeChanged() {
    await SMLAnalytics.track('smart_link_customize_changed', {});
  },
  async trackSignUpStarted() {
    await SMLAnalytics.track('sign_up_started', {});
  },
  async trackLogInStarted() {
    await SMLAnalytics.track('log_in_started', {});
  },
  async trackOnboardingStep1Started() {
    await SMLAnalytics.track('onboarding_step_1_started', {});
  },
  async trackOnboardingStep1Finished() {
    await SMLAnalytics.track('onboarding_step_1_finished', {});
  },
  async trackOnboardingStep2Started() {
    await SMLAnalytics.track('onboarding_step_2_started', {});
  },
  async trackOnboardingStep2Finished() {
    await SMLAnalytics.track('onboarding_step_2_finished', {});
  },
  async trackOnboardingStep3Started() {
    await SMLAnalytics.track('onboarding_step_3_started', {});
  },
  async trackOnboardingStep3Finished() {
    await SMLAnalytics.track('onboarding_step_3_finished', {});
  },
};
