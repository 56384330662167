export const decodeHtmlEntity = (str: string) => {
  return str.replace(/&#(\d+);/g, (match, dec) => {
    return String.fromCharCode(dec);
  });
};
export const decodeUnicodeEntity = (str: string) => {
  return str.replace(/\\x([0-9a-f]{2})/g, (match, dec) => {
    return String.fromCharCode(parseInt(dec, 16));
  });
};
