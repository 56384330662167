import { v4 as uuidv4 } from 'uuid';
import { singletonPromise } from 'fym-common/src/utils/singletonPromise';
import { store } from '../store/Store';

async function _generateNewDeviceId(): Promise<string> {
  const newUUID = uuidv4();
  await store.set('deviceId', newUUID);
  return newUUID;
}

export const generateNewDeviceId = singletonPromise(_generateNewDeviceId);

const _getDeviceId = async (): Promise<string> => {
  const existingDeviceId = await store.get('deviceId');
  if (existingDeviceId) {
    return existingDeviceId;
  }
  return generateNewDeviceId();
};

// To avoid race condition, where at the same time the function is called from two parts of the app, when the deviceId has not been generated yet,
// we need to memoize the Promise. This will make sure only one deviceId generation is happening at the same time.
export const getDeviceId = singletonPromise(_getDeviceId);
