import { DeezerAlbum } from './DeezerAlbum';
import { DeezerCollectionTrack } from './DeezerCollectionTrack';
import { DeezerAlbumRaw } from '../rawTypes/DeezerAlbumRaw';

export class DeezerAlbumResponse {
  private readonly data;

  constructor(data: DeezerAlbumRaw) {
    this.data = data;
  }

  get album(): DeezerAlbum | null {
    return DeezerAlbum.fromData(this.data, undefined);
  }

  get tracks(): DeezerCollectionTrack[] {
    if (!this.data.tracks?.data) {
      return [];
    }
    return this.data.tracks.data.reduce<DeezerCollectionTrack[]>((results, trackData) => {
      const track = DeezerCollectionTrack.fromData(trackData);
      return track ? [...results, track] : results;
    }, []);
  }
}
