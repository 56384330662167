import { faGlobeAmericas } from '@fortawesome/pro-light-svg-icons/faGlobeAmericas';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faSoundcloud } from '@fortawesome/free-brands-svg-icons/faSoundcloud';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faAmazon } from '@fortawesome/free-brands-svg-icons/faAmazon';
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faDeezer } from '@fortawesome/free-brands-svg-icons/faDeezer';
import { faSpotify } from '@fortawesome/free-brands-svg-icons/faSpotify';
import { faMusic } from '@fortawesome/pro-solid-svg-icons/faMusic';
import { faNapster } from '@fortawesome/free-brands-svg-icons/faNapster';
import { faQq } from '@fortawesome/free-brands-svg-icons/faQq';
import { faYandexInternational } from '@fortawesome/free-brands-svg-icons/faYandexInternational';
import { faVk } from '@fortawesome/free-brands-svg-icons/faVk';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { faReddit } from '@fortawesome/free-brands-svg-icons/faReddit';
import { faSnapchat } from '@fortawesome/free-brands-svg-icons/faSnapchat';
import { LinkType } from 'fym-common/src/utils/recognizeLinkTypeByUrl';
import { musicServiceForLink } from './musicServiceForLink';
import { amazonImages } from '../../importers/modules/amazonMusic/importerImages';
import { appleMusicImages } from '../../importers/modules/appleMusic/importerImages';
import { deezerImages } from '../../importers/modules/deezer/importerImages';
import { pandoraImages } from '../../importers/modules/pandora/importerImages';
import { yandexImages } from '../../importers/modules/yandex/importerImages';
import { napsterImages } from '../../importers/modules/napster/importerImages';
import { zvukImages } from '../../importers/modules/zvuk/importerImages';
import { youTubeMusicImages } from '../../importers/modules/youtubeMusic/importerImages';
import { qqMusicImages } from '../../importers/modules/QQMusic/importerImages';
import { vkImages } from '../../importers/modules/vk/importerImages';
import { jioSaavnImages } from '../../importers/modules/jioSaavn/importerImages';
import { soundCloudImages } from '../../importers/modules/soundCloud/importerImages';
import { spotifyImages } from '../../importers/modules/spotify/importerImages';
import { gaanaImages } from '../../importers/modules/gaana/importerImages';
import { boomplayImages } from '../../importers/modules/boomplay/importerImages';
import { anghamiImages } from '../../importers/modules/anghami/importerImages';
import { youTubeImages } from '../../importers/modules/youtube/importerImages';
import { tidalImages } from '../../importers/modules/tidal/importerImages';
import { ressoImages } from '../../importers/modules/resso/importerImages';
import { qobuzImages } from '../../importers/modules/qobuz/importerImages';

export function iconForLink(type: LinkType) {
  switch (type) {
    case LinkType.amazonmusic:
      return faAmazon;
    case LinkType.applemusic:
      return faApple;
    case LinkType.deezer:
      return faDeezer;
    case LinkType.pandora:
      return faMusic; // Missing brand icon
    case LinkType.soundcloud:
      return faSoundcloud;
    case LinkType.spotify:
      return faSpotify;
    case LinkType.tidal:
      return faMusic; // Missing brand icon
    case LinkType.youtube:
      return faYoutube;
    case LinkType.youtubemusic:
      return faYoutube;
    case LinkType.napster:
      return faNapster;
    case LinkType.qobuz:
      return faMusic; // Missing brand icon
    case LinkType.qqmusic:
      return faQq;
    case LinkType.yandexmusic:
      return faYandexInternational;
    case LinkType.vkmusic:
      return faVk;
    case LinkType.anghami:
      return faMusic; // Missing brand icon
    case LinkType.zvuk:
      return faMusic; // Missing brand icon
    case LinkType.gaana:
      return faMusic; // Missing brand icon
    case LinkType.jiosaavn:
      return faMusic; // Missing brand icon
    case LinkType.boomplay:
      return faMusic; // Missing brand icon
    case LinkType.resso:
      return faMusic; // Missing brand icon
    case LinkType.facebook:
      return faFacebookF;
    case LinkType.instagram:
      return faInstagram;
    case LinkType.twitter:
      return faTwitter;
    case LinkType.linkedin:
      return faLinkedinIn;
    case LinkType.tiktok:
      return faTiktok;
    case LinkType.snapchat:
      return faSnapchat;
    case LinkType.reddit:
      return faReddit;
    case LinkType.generic:
    default:
      return faGlobeAmericas;
  }
}

export function stylesForLink(type: LinkType) {
  const musicService = musicServiceForLink(type);
  if (musicService) {
    return { backgroundColor: musicService.color.label };
  }
  switch (type) {
    case LinkType.facebook:
      return { backgroundColor: '#1877f2' };
    case LinkType.instagram:
      return { backgroundColor: '#e1306c' };
    case LinkType.twitter:
      return { backgroundColor: '#1da1f2' };
    case LinkType.linkedin:
      return { backgroundColor: '#0077b5' };
    case LinkType.tiktok:
      return { backgroundColor: '#000000' };
    case LinkType.snapchat:
      return { backgroundColor: '#FFFC00', color: '#000000' };
    case LinkType.reddit:
      return { backgroundColor: '#FF4500' };
    case LinkType.generic:
    default:
      return {};
  }
}

export function logosForLink(type: LinkType) {
  switch (type) {
    case LinkType.amazonmusic:
      return amazonImages;
    case LinkType.applemusic:
      return appleMusicImages;
    case LinkType.deezer:
      return deezerImages;
    case LinkType.pandora:
      return pandoraImages;
    case LinkType.soundcloud:
      return soundCloudImages;
    case LinkType.spotify:
      return spotifyImages;
    case LinkType.tidal:
      return tidalImages;
    case LinkType.youtube:
      return youTubeImages;
    case LinkType.youtubemusic:
      return youTubeMusicImages;
    case LinkType.napster:
      return napsterImages;
    case LinkType.qobuz:
      return qobuzImages;
    case LinkType.qqmusic:
      return qqMusicImages;
    case LinkType.yandexmusic:
      return yandexImages;
    case LinkType.vkmusic:
      return vkImages;
    case LinkType.anghami:
      return anghamiImages;
    case LinkType.zvuk:
      return zvukImages;
    case LinkType.gaana:
      return gaanaImages;
    case LinkType.jiosaavn:
      return jioSaavnImages;
    case LinkType.boomplay:
      return boomplayImages;
    case LinkType.resso:
      return ressoImages;
    default:
      return null;
  }
}
