import type { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { RequestHeaders } from 'fym-common/src/utils/fetch-types';
import { FYMAPIError } from 'fym-common/src/generics/errors/FYMAPIError';
import { config } from 'fym-common/src/config/config';
import { AppInfoStateType } from '../../slices/appInfo/state';
import { APIType } from '../types';
import { appInformation } from '../../../utils/appInformation';

function prepareHeaders(getState: BaseQueryApi['getState'], apiType: APIType) {
  const { appInfo } = getState() as { appInfo: AppInfoStateType };
  const { deviceId, realmPartition, userToken } = appInfo;
  const headers: RequestHeaders = {
    Accept: 'application/json; charset=utf-8',
    'x-appinfo-target': config.target,
    'x-appinfo-platform': config.platform,
    'x-appinfo-environment': config.environment,
    'x-device-id': deviceId,
  };
  if (realmPartition) {
    headers['x-realm-id'] = realmPartition;
  }
  if (apiType === APIType.fym && userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }
  if (apiType === APIType.music) {
    headers['Content-Type'] = 'application/json';
    headers.Authorization = `Token ${config.musicApiToken}`;
  }
  const { version, platformVersion, buildVersion } = appInformation;
  if (version) {
    headers['x-appinfo-version'] = version;
  }
  if (platformVersion) {
    headers['x-appinfo-platformVersion'] = platformVersion;
  }
  if (buildVersion) {
    headers['x-appinfo-buildVersion'] = buildVersion;
  }

  return headers;
}

async function handleErrorResponse(response: any) {
  const text = await response.text();
  let data: Record<string, any> | undefined;
  let errorMessage: string;
  try {
    data = JSON.parse(text);
    errorMessage = data?.message;
  } catch (e: any) {
    errorMessage = text;
  }
  throw new FYMAPIError({
    message: errorMessage,
    statusCode: response.status,
    internalCode: data?.internalCode,
  });
}

export const customBaseQuery =
  (
    baseUrl: string,
    apiType: APIType
  ): BaseQueryFn<
    {
      url: string;
      method: string;
      headers?: Record<string, string>;
      body?: any;
    },
    unknown,
    Error | FYMAPIError
  > =>
  async ({ url, method, body, headers }, api) => {
    const allHeaders = { ...prepareHeaders(api.getState, apiType), ...headers };
    try {
      const response = await window.fetch(baseUrl + url, { method, headers: allHeaders, body });
      if (!response.ok) {
        await handleErrorResponse(response);
      }
      return { data: await response.json() };
    } catch (err: any) {
      const error: Error | FYMAPIError = err;
      console.error('Networking error', error);
      return { error };
    }
  };
