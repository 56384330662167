import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons/faSyncAlt';
import { faMedkit } from '@fortawesome/pro-solid-svg-icons/faMedkit';
import { faCalendarTimes } from '@fortawesome/pro-solid-svg-icons/faCalendarTimes';
import { faChartNetwork } from '@fortawesome/pro-solid-svg-icons/faChartNetwork';
import {
  faBooks,
  faCirclePlay,
  faCitrusSlice,
  faCloudUploadAlt,
  faCode,
  faDatabase,
  faEyeSlash,
  faFileUser,
  faFrame,
  faGlobeEurope,
  faHeadset,
  faMemo,
  faMp3Player,
  faRectangleAd,
  faShuffle,
  faUserMusic,
  faPhoneLaptop,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { Feature, FeatureLabel } from './types';
import { intl } from '../../../app/views/intl';

export const features: Feature[] = [
  {
    title: intl.formatMessage({
      id: 'web.features.auto_sync.title',
      defaultMessage: 'Auto-Sync',
    }),
    description: intl.formatMessage({
      id: 'web.features.auto_sync.description',
      defaultMessage: 'Auto-synchronisation of all playlists / albums every 15 minutes!',
    }),
    labels: [FeatureLabel.Premium],
    icon: faSyncAlt,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.dedicated_support.title',
      defaultMessage: 'Dedicated Support',
    }),
    description: intl.formatMessage({
      id: 'web.features.dedicated_support.description',
      defaultMessage: 'In case of any issues, we provide dedicated support to help you with setting up your transfers.',
    }),
    labels: [FeatureLabel.Premium],
    icon: faMedkit,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.cloud_backup.title',
      defaultMessage: 'Cloud Backup',
    }),
    description: intl.formatMessage({
      id: 'web.features.cloud_backup.description',
      defaultMessage: 'Keep your music library safe & sound in the cloud.',
    }),
    labels: [FeatureLabel.Premium],
    icon: faCloudUploadAlt,
    hideForSetapp: true,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.cancel_anytime.title',
      defaultMessage: 'Cancel anytime',
    }),
    description: intl.formatMessage({
      id: 'web.features.cancel_anytime.description',
      defaultMessage:
        'You can cancel Premium subscription anytime. You’ll still keep the Basic functionality for a lifetime!',
    }),
    labels: [FeatureLabel.Premium],
    icon: faCalendarTimes,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.keep_order.title',
      defaultMessage: 'Song order',
    }),
    description: intl.formatMessage({
      id: 'web.features.keep_order.description',
      defaultMessage: 'Auto-sync song order across platforms.',
    }),
    labels: [FeatureLabel.Premium],
    icon: faMemo,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.rematch.title',
      defaultMessage: 'Rematch',
    }),
    description: intl.formatMessage({
      id: 'web.features.rematch.description',
      defaultMessage: 'Review the transfer and fix any incorrect or failed matches.',
    }),
    labels: [FeatureLabel.Basic],
    icon: faShuffle,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.unlimited_transfers.title',
      defaultMessage: 'Unlimited Transfers',
    }),
    description: intl.formatMessage({
      id: 'web.features.unlimited_transfers.description',
      defaultMessage: 'Transfer unlimited number of songs and playlists and albums.',
    }),
    labels: [FeatureLabel.Basic],
    icon: faUserMusic,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.all_platforms.title',
      defaultMessage: 'All platforms',
    }),
    description: intl.formatMessage({
      id: 'web.features.all_platforms.description',
      defaultMessage: 'The app is available on all major platforms.',
    }),
    labels: [FeatureLabel.Basic],
    icon: faPhoneLaptop,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.multi_account.title',
      defaultMessage: 'Multi-account support',
    }),
    description: intl.formatMessage({
      id: 'web.features.multi_account.description',
      defaultMessage: `Transfer your, your spouse's or anyone's music library using one license.`,
    }),
    labels: [FeatureLabel.Basic],
    icon: faUsers,
  },
];

export const smartLinksFeatures: Feature[] = [
  {
    title: intl.formatMessage({
      id: 'web.features.smart-links-own-domain.title',
      defaultMessage: 'Get your own domain',
    }),
    description: intl.formatMessage({
      id: 'web.features.smart-links-own-domain.description',
      defaultMessage: 'Set up a custom domain to improve your brand.',
    }),
    labels: [FeatureLabel.SmartLinksRookie, FeatureLabel.SmartLinksInfluencer, FeatureLabel.SmartLinksArtist],
    icon: faGlobeEurope,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.smart-links-short-domain.title',
      defaultMessage: 'Custom fym.fm short subdomain',
    }),
    description: intl.formatMessage({
      id: 'web.features.smart-links-short-domain.description',
      defaultMessage: 'Create a custom sub-domain that showcases your brand name.',
    }),
    labels: [FeatureLabel.SmartLinksRookie, FeatureLabel.SmartLinksInfluencer, FeatureLabel.SmartLinksArtist],
    icon: faCitrusSlice,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.smart-links-email-support.title',
      defaultMessage: 'Email support',
    }),
    description: intl.formatMessage({
      id: 'web.features.smart-links-email-support.description',
      defaultMessage: 'Our friendly support team is available to assist you with setting up and creating smart links.',
    }),
    labels: [FeatureLabel.SmartLinksRookie, FeatureLabel.SmartLinksInfluencer, FeatureLabel.SmartLinksArtist],
    icon: faHeadset,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.smart-links-create-up.title',
      defaultMessage: 'Create up to 150 smartlinks',
    }),
    description: intl.formatMessage({
      id: 'web.features.smart-links-create-up.description',
      defaultMessage: 'That’s a lot of songs, albums, and playlists to promote!',
    }),
    labels: [FeatureLabel.SmartLinksRookie, FeatureLabel.SmartLinksInfluencer, FeatureLabel.SmartLinksArtist],
    icon: faBooks,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.manage-account.title',
      defaultMessage: 'Manage 10 accounts',
    }),
    description: intl.formatMessage({
      id: 'web.features.manage-account.description',
      defaultMessage:
        'You can now easily switch between multiple artist accounts without the hassle of repeatedly logging in and out.',
    }),
    labels: [FeatureLabel.SmartLinksRookie, FeatureLabel.SmartLinksArtist, FeatureLabel.SmartLinksInfluencer],
    icon: faFileUser,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.actionable-analytics.title',
      defaultMessage: 'Actionable Analytics',
    }),
    description: intl.formatMessage({
      id: 'web.features.actionable-analytics.description',
      defaultMessage: 'Check your audience insights and measure your performance.',
    }),
    labels: [FeatureLabel.SmartLinksInfluencer, FeatureLabel.SmartLinksArtist],
    icon: faMedkit,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.retargeting.title',
      defaultMessage: 'Retargeting (FB Pixel, GTag)',
    }),
    description: intl.formatMessage({
      id: 'web.features.retargeting.description',
      defaultMessage: 'Measure, optimize & retarget audiences for your ad campaigns.',
    }),
    labels: [FeatureLabel.SmartLinksInfluencer, FeatureLabel.SmartLinksArtist],
    icon: faFacebookSquare,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.embed.title',
      defaultMessage: 'Embed your SmartLinks anywhere',
    }),
    description: intl.formatMessage({
      id: 'web.features.embed.description',
      defaultMessage: 'Turn your smart links into an embeddable widget for your website.',
    }),
    labels: [FeatureLabel.SmartLinksInfluencer, FeatureLabel.SmartLinksArtist],
    icon: faFrame,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.smart-links-music-player.title',
      defaultMessage: 'Music Player',
    }),
    description: intl.formatMessage({
      id: 'web.features.smart-links-music-player.description',
      defaultMessage: 'Allow your audience to listen to your playlist on SmartLink website.',
    }),
    labels: [FeatureLabel.SmartLinksInfluencer, FeatureLabel.SmartLinksArtist],
    icon: faMp3Player,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.smart-links.customize.title',
      defaultMessage: 'Change themes, colors and fonts',
    }),
    description: intl.formatMessage({
      id: 'web.features.smart-links.customize.description',
      defaultMessage: 'Customize the look of your smart links.',
    }),
    labels: [FeatureLabel.SmartLinksInfluencer, FeatureLabel.SmartLinksArtist],
    icon: faChartNetwork,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.promote.title',
      defaultMessage: 'Let us promote you!',
    }),
    description: intl.formatMessage({
      id: 'web.features.promote.description',
      defaultMessage: 'Gain additional exposure from our social media promotion.',
    }),
    labels: [FeatureLabel.SmartLinksArtist],
    icon: faRectangleAd,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.white-label.title',
      defaultMessage: 'White-label',
    }),
    description: intl.formatMessage({
      id: 'web.features.white-label.description',
      defaultMessage: 'Hide our logo on your smart links.',
    }),
    labels: [FeatureLabel.SmartLinksArtist],
    icon: faEyeSlash,
  },
];

export const smartLinksFeaturesForUpsell: Feature[] = [
  {
    title: intl.formatMessage({
      id: 'web.features.embed-player.title',
      defaultMessage: 'Embed player',
    }),
    description: intl.formatMessage({
      id: 'web.features.embed-player.description',
      defaultMessage: 'Place your playlists on your blog and website as a sleek player',
    }),
    labels: [FeatureLabel.SmartLinksRookie, FeatureLabel.SmartLinksArtist, FeatureLabel.SmartLinksInfluencer],
    icon: faCode,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.upsell-analytics.title',
      defaultMessage: 'Actionable analytics',
    }),
    description: intl.formatMessage({
      id: 'web.features.upsell-analytics.description',
      defaultMessage: 'Dive into your playlist data and measure your performance',
    }),
    labels: [FeatureLabel.SmartLinksArtist, FeatureLabel.SmartLinksInfluencer],
    icon: faDatabase,
  },
  {
    title: intl.formatMessage({
      id: 'web.features.upsell-music-player.title',
      defaultMessage: 'Music player',
    }),
    description: intl.formatMessage({
      id: 'web.features.upsell-music-player.description',
      defaultMessage: 'Allow your audience to listen to your playlist on SmartLink website',
    }),
    labels: [FeatureLabel.SmartLinksArtist, FeatureLabel.SmartLinksInfluencer],
    icon: faCirclePlay,
  },
];

export const premiumFeatures = features.filter((f) => f.labels?.includes(FeatureLabel.Premium));
export const basicFeatures = features.filter((f) => f.labels?.includes(FeatureLabel.Basic));
export const featuresWithoutLabels = features.map((f) => ({ ...f, labels: undefined }));
