import React, { useCallback, useMemo } from 'react';
import { TypedMemo } from '../../../common/types';
import { useSMLRouter } from '../../../../routing/sml/hooks';
import { SMLRoutes } from '../../../../routing/sml/SMLRoutes';
import { TrackersListItemProps } from './props';
import { TrackersListItemRenderer } from './TrackersListItemRenderer';
import { getUIDataForTrackerType } from './utils';

const TrackersListItemComponent: React.FC<TrackersListItemProps> = ({ tracker, accountId }) => {
  const router = useSMLRouter();

  const editTracker = useCallback(() => {
    router.push(SMLRoutes.trackerEdit, { accountId, trackerId: tracker.id });
  }, [router, accountId, tracker.id]);

  const UIData = useMemo(() => getUIDataForTrackerType(tracker.type), [tracker.type]);
  if (!UIData) {
    return null;
  }

  const { Logo, name, color } = UIData;
  return (
    <TrackersListItemRenderer
      Logo={Logo}
      name={name}
      color={color}
      onClick={editTracker}
      trackerId={tracker.trackerId}
    />
  );
};

export const TrackersListItem = TypedMemo(TrackersListItemComponent);
