export enum ReleaseChannelApp {
  freeyourmusic = 'freeyourmusic',
  smartlinks = 'smartlinks',
}

export enum ReleaseChannel {
  production = 'production',
  staging = 'staging',
  beta = 'beta',
}
