import Joi from 'joi';
import { StoreType } from '../../../redux/store';
import { fymAPI } from '../../../redux/api/fymAPI';
import { asyncDebounce } from '../../../utils/asyncDebounce';
import { isSuccessfulResult } from '../../../redux/api/utils/isSuccessfulResult';
import { FieldsName } from '../SmartLinkForm/consts';

const verifySmartLinkSlug = (store: StoreType, slug: string, accountId: number, smartLinkId?: number) =>
  store.dispatch(fymAPI.endpoints.verifySmartLinkSlug.initiate({ slug, accountId, smartLinkId }));

const debouncedVerifyAccountNameSlug = asyncDebounce(verifySmartLinkSlug, 500, { leading: true });

export function getValidateSmartLinkSlugFn(store: StoreType, accountId: number, smartLinkId?: number) {
  return async (slug: string) => {
    const validationResult = await debouncedVerifyAccountNameSlug(store, slug, accountId, smartLinkId);
    if (!isSuccessfulResult(validationResult)) {
      throw new Joi.ValidationError(
        'slug.validationError',
        [
          {
            message: `Validation error, please try again later`,
            path: [FieldsName.slug],
          },
        ],
        slug
      );
    }

    const { isValid, isAvailable, availableSlug } = validationResult.data;
    if (!isValid) {
      throw new Joi.ValidationError(
        'slug.illegal',
        [
          {
            message: `Slug "${slug}" contains illegal characters, you can use "${availableSlug}" instead`,
            path: [FieldsName.slug],
          },
        ],
        slug
      );
    }
    if (!isAvailable) {
      throw new Joi.ValidationError(
        'slug.taken',
        [
          {
            message: `Slug "${slug}" is taken, you can use "${availableSlug}" instead`,
            path: [FieldsName.slug],
          },
        ],
        slug
      );
    }
  };
}
