import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { BoomplayAlbumRawResponse } from './types';
import { BoomplayArtist } from './BoomplayArtist';
import { artistsToString } from '../../../utils/artistsConverter';
import { getBoomplayArtistsNamesAsString } from '../utils/getBoomplayArtistsNamesAsString';

export class BoomplayAlbum extends GenericCollection {
  constructor(data: Omit<CollectionModelData, 'type'>, public artists: BoomplayArtist[] | null = null) {
    super({ type: CollectionType.ALBUM, ...data });
  }

  static fromData(data: BoomplayAlbumRawResponse | undefined): BoomplayAlbum | null {
    if (!data || data.album_id === undefined || !data.album_title) {
      return null;
    }
    const artists = data.artists
      ?.map((artist) => BoomplayArtist.fromData(artist))
      .filter((a): a is NonNullable<typeof a> => a !== null);
    return new BoomplayAlbum(
      {
        rawId: `${data.album_id}`,
        name: data.album_title,
        itemCount: data.track_count,
        additionalData: { imageUrl: data.artwork?.url, artist: getBoomplayArtistsNamesAsString(data.artists) },
        publicUrl: data.web_url,
      },
      artists ?? null
    );
  }

  static fromShareUrlData(data: any) {
    const urlParts = data?.['@id']?.split('/');
    if (!data || !urlParts) {
      return null;
    }
    const rawId = urlParts[urlParts.length - 1];
    const artists: BoomplayArtist[] | undefined = data.byArtist
      ?.map((artist: any) => BoomplayArtist.fromShareUrlData(artist))
      .filter((artist: BoomplayArtist | null): artist is BoomplayArtist => artist !== null);
    const artistsNames = artists?.map((artist) => artist.name);

    return new BoomplayAlbum(
      {
        rawId,
        name: data.name,
        itemCount: data.track?.length,
        additionalData: { imageUrl: data.image ?? '', artist: artistsToString(artistsNames) },
        publicUrl: data.url ?? data['@id'],
      },
      artists
    );
  }
}
