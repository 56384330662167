import { fetchRetry } from '../../utils/fetch-retry';
import { config } from '../../config/config';
import { MusicApiIntegrationFymRefreshAuthResponse } from '../types';
import { integrationUserToGenericAuthData } from './integrationUserToGenericAuthData';
import { MusicAPIIntegrationID } from '../../musicServices/types';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { CannotReauthenticateError } from '../../generics/errors/CannotReauthenticateError';

export async function refreshAuthData({
  authId,
  userUUID,
  integrationId,
  forceFetchRefreshTokens,
}: {
  authId: string;
  userUUID: string | null;
  forceFetchRefreshTokens?: boolean;
  integrationId: MusicAPIIntegrationID;
}): Promise<GenericAuthenticationData> {
  const response = await fetchRetry(`${config.musicApiURL}public/integrations/fym-refresh`, {
    headers: { Authorization: `Token ${config.musicApiToken}`, 'content-type': 'application/json' },
    method: 'post',
    body: JSON.stringify({
      integrationUserId: authId,
      userUUID: userUUID ?? undefined,
      integrationType: integrationId,
      forceFetchRefreshTokens: forceFetchRefreshTokens ?? false,
    }),
  });
  if (!response.ok) {
    const text = await response.text();
    const errMsg = `Could not refresh music auth data for authId[${authId}]: ${response.status}: ${text}`;
    console.error(errMsg);
    throw new CannotReauthenticateError(errMsg);
  }
  const data = (await response.json()) as MusicApiIntegrationFymRefreshAuthResponse<any>;
  return integrationUserToGenericAuthData(integrationId, data.integrationUser);
}
