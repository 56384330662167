import { AmazonMusicCollectionTrack } from './AmazonMusicCollectionTrack';
import { Track } from './types';
import { TrackBaseInfo } from '../types';

interface TracksResponse {
  data?: {
    tracks?: (Track | null)[];
  };
}

export class AmazonMusicTracksResponse {
  private readonly data;

  private readonly tracksBaseInfo;

  constructor(data: TracksResponse | undefined, tracksBaseInfo: TrackBaseInfo[]) {
    this.data = data;
    this.tracksBaseInfo = tracksBaseInfo;
  }

  get tracks() {
    if (!this.data?.data?.tracks) {
      return [];
    }

    return this.data.data.tracks.reduce((results, trackData) => {
      if (!trackData) {
        return results;
      }
      const trackInfo = this.tracksBaseInfo.find(({ rawId }) => trackData.id && trackData.id === rawId);
      const track = AmazonMusicCollectionTrack.fromData(trackData, {
        entryId: trackInfo?.entryId,
        playedAt: trackInfo?.playedAt,
      });
      if (track) {
        results.push(track);
      }
      return results;
    }, [] as AmazonMusicCollectionTrack[]);
  }
}
