import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useQueryParam } from '../../../../utils/useQueryParam';
import { openSmartLinksPaddlePayment } from '../utils/openSmartLinksPaddlePayment';
import { useExistingLicenseKey } from '../../../licenses/hooks/useExistingLicenseKey';
import { useValidatePaddleOrder } from '../../../licenses/hooks/useValidatePaddleOrder';
import { AnalyticsPurchasePlan } from '../../../analytics/types';

export function useTriggerPaddle(isPaddleLoading: boolean) {
  const intl = useIntl();
  const currentLicenseKey = useExistingLicenseKey();
  const licenseId = useQueryParam('licenseId', undefined, '');
  const email = useQueryParam('email', undefined, '');
  const plan = useQueryParam('plan', undefined, null) as AnalyticsPurchasePlan | null;
  const deviceId = useQueryParam('deviceId', undefined, '');
  const coupon = useQueryParam('coupon', undefined, '');
  const [validatePaddleOrder] = useValidatePaddleOrder();

  useEffect(() => {
    if (!plan || isPaddleLoading) {
      return;
    }
    openSmartLinksPaddlePayment({
      email: email ?? '',
      licenseKey: licenseId,
      plan,
      deviceId,
      coupon,
      locale: intl.locale,
      onSuccess: (checkoutId) => {
        if (checkoutId && currentLicenseKey === licenseId) {
          // We want to trigger it only if we are logged in on the same user as on desktop
          validatePaddleOrder({ orderId: checkoutId }).catch(console.error);
        }
      },
    }).catch(console.error);
  }, [coupon, currentLicenseKey, deviceId, email, intl.locale, isPaddleLoading, licenseId, plan, validatePaddleOrder]);
}
