import { DefaultRouteNameForFYMTab, FYMTabNames } from '../../../routing/fym/FYMRoutes';

const tabNamesArray = Object.values(FYMTabNames);

export function getActiveTab(pathname: string): FYMTabNames | undefined {
  return tabNamesArray.find((tabName) => pathname.startsWith(`/${tabName}`));
}

export function getDefaultTabPath(tabName: FYMTabNames) {
  return `/${tabName}/${DefaultRouteNameForFYMTab[tabName]}`;
}
