import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Col, Input, Row } from 'reactstrap';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TypedMemo } from '../../common/types';
import { SMLListViewRendererProps } from './props';
import { SmartLinkInfoBox } from '../smartLinkInfoBox/SmartLinkInfoBox';
import { APIPagination } from '../../common/views/pagination/APIPagination';
import { SMLListItemBox } from '../smlListItemBox/SMLListItemBox';

const SMLListForChoiceViewRendererComponent: React.FC<SMLListViewRendererProps> = ({
  smartLinksResponse,
  smartLinksLoading,
  onSmartLinkClick,
  onPageChange,
  onSearchChange,
  availableSmartLinkToSelect,
}) => {
  const intl = useIntl();

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onSearchChange(e.target.value);
    },
    [onSearchChange]
  );

  return (
    <>
      {smartLinksLoading || !smartLinksResponse ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <SmartLinkInfoBox
            text={intl.formatMessage({
              id: 'app.smart-links.loading',
              defaultMessage: `Loading... Please wait`,
            })}
          />
        </div>
      ) : (
        <>
          <Row className="mb-4 d-flex align-items-center">
            <Col xs={12} className="sml-search-bar d-flex align-items-center">
              <FontAwesomeIcon icon={faSearch} className="position-absolute search-icon" />
              <Input className="search-input" onChange={handleSearch} />
              <span className="ps-4 w-100 text-muted">
                {intl.formatMessage(
                  {
                    id: 'app.smart-links.search.result',
                    defaultMessage: `{items} results found`,
                  },
                  { items: smartLinksResponse.totalItems }
                )}
              </span>
            </Col>
          </Row>
          <Row className="align-items-center">
            {availableSmartLinkToSelect?.map((smartLink) => {
              return (
                <Col key={smartLink.id} xs={12} sm={6} className="mb-3">
                  <SMLListItemBox smartLink={smartLink} onClick={onSmartLinkClick} />
                </Col>
              );
            })}
          </Row>
          <Row className="d-flex justify-content-center">
            <APIPagination
              total={smartLinksResponse.totalItems}
              limit={smartLinksResponse.itemsPerPage}
              page={smartLinksResponse.currentPage}
              onPageChange={onPageChange}
            />
          </Row>
        </>
      )}
    </>
  );
};

export const SMLListForChoiceViewRenderer = TypedMemo(SMLListForChoiceViewRendererComponent);
