import { ReactComponent as TidalLight } from '../../../../assets/images/music-services/tidal-light.svg';
import { ReactComponent as TidalDark } from '../../../../assets/images/music-services/tidal-dark.svg';
import { ReactComponent as TidalLightPadding } from '../../../../assets/images/music-services/tidal-light-padding.svg';
import { ReactComponent as TidalDarkPadding } from '../../../../assets/images/music-services/tidal-dark-padding.svg';
import { ReactComponent as TidalColorLight } from '../../../../assets/images/music-services/tidal-color-light.svg';
import { ReactComponent as TidalColorDark } from '../../../../assets/images/music-services/tidal-color-dark.svg';
import { ReactComponent as TidalColorLightPadding } from '../../../../assets/images/music-services/tidal-color-light-padding.svg';
import { ReactComponent as TidalColorDarkPadding } from '../../../../assets/images/music-services/tidal-color-dark-padding.svg';
import { ReactComponent as TidalDarkDanger } from '../../../../assets/images/music-services-danger/tidal-dark-danger.svg';
import { ReactComponent as TidalLightDanger } from '../../../../assets/images/music-services-danger/tidal-light-danger.svg';
import { ReactComponent as TidalLightDangerPadding } from '../../../../assets/images/music-services-danger/tidal-light-padding-danger.svg';
import { ReactComponent as TidalDarkDangerPadding } from '../../../../assets/images/music-services-danger/tidal-dark-padding-danger.svg';
import { ReactComponent as TidalDarkWarning } from '../../../../assets/images/music-services-warning/tidal-dark-warning.svg';
import { ReactComponent as TidalLightWarning } from '../../../../assets/images/music-services-warning/tidal-light-warning.svg';
import { ReactComponent as TidalLightWarningPadding } from '../../../../assets/images/music-services-warning/tidal-light-padding-warning.svg';
import { ReactComponent as TidalDarkWarningPadding } from '../../../../assets/images/music-services-warning/tidal-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const tidalImages: ImporterImages = {
  logo: {
    light: TidalLight,
    dark: TidalDark,
  },
  logoPadding: {
    light: TidalLightPadding,
    dark: TidalDarkPadding,
  },
  logoColor: {
    light: TidalColorLight,
    dark: TidalColorDark,
  },
  logoColorPadding: {
    light: TidalColorLightPadding,
    dark: TidalColorDarkPadding,
  },
  logoOnBackground: TidalDark,
  logoDanger: {
    dark: TidalDarkDanger,
    light: TidalLightDanger,
  },
  logoDangerPadding: {
    dark: TidalDarkDangerPadding,
    light: TidalLightDangerPadding,
  },
  logoWarning: {
    dark: TidalDarkWarning,
    light: TidalLightWarning,
  },
  logoWarningPadding: {
    light: TidalLightWarningPadding,
    dark: TidalDarkWarningPadding,
  },
};
