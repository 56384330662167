export function convertWidgetFontSize(fontSize: string): string {
  switch (fontSize) {
    case 'title':
      return 'widget-list-item-title';
    case 'small':
      return 'widget-list-item-small';
    case 'normal':
    default:
      return 'widget-list-item-normal';
  }
}
