import { DeezerAlbum } from './DeezerAlbum';
import { DeezerListRaw } from '../rawTypes/DeezerListRaw';
import { DeezerAlbumRaw } from '../rawTypes/DeezerAlbumRaw';

export class DeezerAlbumsResponse {
  private readonly data;

  constructor(data: DeezerListRaw<DeezerAlbumRaw> | null) {
    this.data = data;
  }

  get albums(): DeezerAlbum[] {
    if (!this.data?.data) {
      return [];
    }
    return this.data?.data.reduce<DeezerAlbum[]>((results, albumData) => {
      const album = DeezerAlbum.fromData(albumData, undefined);
      return album ? [...results, album] : results;
    }, []);
  }

  get total() {
    return this.data?.total;
  }

  get next() {
    return this.data?.next;
  }
}
