import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { imageSchema } from 'fym-common/src/joi/schemas';
import { FieldsName } from './consts';

const schema = Joi.object({
  [FieldsName.avatar]: imageSchema.label('Avatar').required(),
});

export const resolver = joiResolver(schema);
