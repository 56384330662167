import { NapsterMatchedTrack } from './NapsterMatchedTrack';
import { NapsterAlbum } from './NapsterAlbum';

export class NapsterSearchResponse {
  private readonly data;

  constructor(data: Record<string, any> | null) {
    this.data = data;
  }

  get annotations(): Record<string, any> {
    return this.data?.annotations;
  }

  get tracks(): NapsterMatchedTrack[] {
    if (!this.data?.search?.data?.tracks) {
      return [];
    }
    return this.data.search.data.tracks
      .map((trackData: any) => NapsterMatchedTrack.fromData(trackData))
      .filter((track: NapsterMatchedTrack | null) => !!track);
  }

  get albums(): NapsterAlbum[] {
    if (!this.data?.search?.data?.albums) {
      return [];
    }
    return this.data.search.data.albums
      .map((albumData: any) => NapsterAlbum.fromData(albumData))
      .filter((album: NapsterAlbum | null) => !!album);
  }

  get firstTrack(): NapsterMatchedTrack | undefined {
    if (this.tracks.length < 1) {
      return undefined;
    }
    return this.tracks[0];
  }

  get firstAlbum(): NapsterAlbum | undefined {
    if (this.albums.length < 1) {
      return undefined;
    }
    return this.albums[0];
  }

  get totalCount(): number | undefined {
    return this.data?.meta?.totalCount;
  }
}
