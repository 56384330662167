import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { TypedMemo } from '../../common/types';
import { ErrorAlert } from '../../common/views/ErrorAlert/ErrorAlert';
import { useAccountForm } from './useAccountForm';
import { AccountFormProps } from './props';
import { SMLBreadcrumbs } from '../../smartLinks/smlBreadcrumbs/SMLBreadcrumbs';
import { SMLContainer } from '../../smartLinks/smlContainer/SMLContainer';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { useSMLRouter } from '../../../routing/sml/hooks';

const AccountFormComponent: React.FC<AccountFormProps> = ({
  account,
  socialLinks,
  onSubmit: onSubmitFromProps,
  isLoading,
  error,
}) => {
  const intl = useIntl();
  const router = useSMLRouter();
  const {
    SaveButton,
    CustomDomainInfo,
    NameInput,
    SocialLinksInputs,
    SocialLinkAdditionButton,
    SlugInput,
    CustomDomainInput,
    AvatarInput,
  } = useAccountForm({
    account,
    socialLinks,
    onSubmit: onSubmitFromProps,
    isLoading,
  });

  const breadcrumbs = useMemo(() => {
    return [
      {
        name: intl.formatMessage({ id: 'app.sml.breadcrumbs.home', defaultMessage: 'Home' }),
        onClick: () => router.push(SMLRoutes.home),
      },
      { name: intl.formatMessage({ id: 'app.sml.breadcrumbs.edit_profile', defaultMessage: 'Edit Profile' }) },
    ];
  }, [intl, router]);

  return (
    <SMLContainer header={<SMLBreadcrumbs breadcrumbs={breadcrumbs}>{SaveButton}</SMLBreadcrumbs>}>
      {error && <ErrorAlert error={error} />}
      {CustomDomainInfo && (
        <Row className="mb-3">
          <Col>{CustomDomainInfo}</Col>
        </Row>
      )}
      <Row>
        <Col xs={12} md={6}>
          {NameInput}
        </Col>
        <Col xs={12} md={6}>
          {SlugInput}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <div className="sml-account-form-social-links-container pe-1">{SocialLinksInputs}</div>
          <div className="mb-4">{SocialLinkAdditionButton}</div>
          {CustomDomainInput}
        </Col>
        <Col xs={12} md={6}>
          {AvatarInput}
        </Col>
      </Row>
    </SMLContainer>
  );
};

export const AccountForm = TypedMemo(AccountFormComponent);
