import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../../common/types';
import { SMLChangePasswordViewRenderer } from './SMLChangePasswordViewRenderer';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { useUserTokenDecoded } from '../../../redux/slices/appInfo/hooks';
import { SMLContainer } from '../smlContainer/SMLContainer';
import { SMLBreadcrumbs } from '../smlBreadcrumbs/SMLBreadcrumbs';

const SMLChangePasswordLoggedInViewComponent: React.FC = () => {
  const intl = useIntl();

  const router = useSMLRouter();
  const userTokenDecoded = useUserTokenDecoded();
  const userId = userTokenDecoded?.id ?? undefined;

  const breadcrumbs = useMemo(() => {
    return [
      {
        name: intl.formatMessage({
          id: 'app.sml.change_password.title',
          defaultMessage: 'Password Change',
        }),
      },
    ];
  }, [intl]);

  const goToLogIn = useCallback(() => {
    router.replace(SMLRoutes.login);
  }, [router]);

  return (
    <SMLContainer header={<SMLBreadcrumbs breadcrumbs={breadcrumbs} />}>
      <SMLChangePasswordViewRenderer authToken={undefined} userId={userId} goToLogIn={goToLogIn} />
    </SMLContainer>
  );
};

export const SMLChangePasswordLoggedInView = TypedMemo(SMLChangePasswordLoggedInViewComponent);
