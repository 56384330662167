import { SupportedServicesForPlayerWidget } from 'fym-common/src/utils/isSupportedUrlForPlayerWidget';
import React from 'react';
import { musicServicesByLinkType } from 'fym-common/src/musicServices/allMusicServices';
import { TypedMemo } from '../../../../../common/types';
import { allImportersImagesById } from '../../../../../importers';

const LogoServicesComponent = () => {
  return (
    <>
      {SupportedServicesForPlayerWidget.map((service) => musicServicesByLinkType[service]?.importerID)
        .filter((importerId): importerId is NonNullable<typeof importerId> => importerId !== undefined)
        .map((importerId) => {
          const sourceImporter = allImportersImagesById[importerId];
          const ImporterLogo = sourceImporter?.logoColor.light;
          return ImporterLogo ? <ImporterLogo key={importerId} className="player-widget-icon me-3" /> : null;
        })}
    </>
  );
};

export const LogoServices = TypedMemo(LogoServicesComponent);
