import { BoomplayUserRawResponse } from './types';

export class BoomplayUser {
  constructor(private readonly data: BoomplayUserRawResponse) {
    this.data = data;
  }

  get id() {
    return this.data.data?.user_id;
  }

  get avatar() {
    return this.data.data?.avatar?.url;
  }

  get name() {
    return this.data.data?.user_name ?? '';
  }

  get description() {
    return this.data.desc;
  }
}
