import { buildArtworkUrl, convertDurationToMs, getArtistsNames } from '../utils';
import { AppleMusicTrackRawResponse } from './types';
import { GenericMatchedItem } from '../../../generics/models/GenericMatchedItem';

export class AppleMusicMatchedTrack extends GenericMatchedItem {
  static fromData(data: AppleMusicTrackRawResponse | undefined, url?: string): AppleMusicMatchedTrack | null {
    if (!data?.id || !data.attributes?.name) {
      return null;
    }

    const albumId = data.relationships?.albums?.data?.[0]?.id;
    const artistsIds = data.relationships?.artists?.data
      ?.map((artistData) => artistData.id)
      ?.filter((id): id is string => !!id);

    return new AppleMusicMatchedTrack({
      rawId: data.id,
      name: data.attributes.name,
      album: data.attributes.albumName,
      artist: data.attributes.artistName,
      additionalData: albumId || artistsIds ? { albumId, artistsIds } : undefined,
      imageUrl: buildArtworkUrl(data.attributes.artwork),
      duration: data.attributes.durationInMillis,
      sourceURL: url ?? data.attributes.url,
      isrc: data.attributes.isrc ?? data.relationships?.catalog?.data[0]?.attributes?.isrc,
    });
  }

  static fromShareUrlData(data: any, url: string): AppleMusicMatchedTrack | null {
    const rawId = data?.url?.split('/').pop() ?? url.split('?')[0]?.split('/').pop();
    const name = data?.name;
    if (!rawId || !name) {
      return null;
    }
    return new AppleMusicMatchedTrack({
      rawId,
      name,
      album: data.audio?.inAlbum?.name,
      artist: getArtistsNames(data.audio?.byArtist),
      additionalData: {
        albumId: data.audio?.inAlbum?.url?.split('/').pop(),
      },
      imageUrl: data.image,
      duration: convertDurationToMs(data.audio?.duration),
      sourceURL: data.url ?? url,
    });
  }
}
