import React, { useCallback, useMemo, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { SerializedImageWidget } from 'fym-common/src/db/serializersTypes';
import { TypedMemo } from '../../common/types';
import { FieldsName, FieldsType } from './consts';
import { Spacer } from '../../common/views/spacer/Spacer';
import { resolver } from './validators';
import { FileLoader } from '../components/FileLoader/FileLoader';
import { SMLFormContainer } from '../../smartLinks/SMLFormContainer/SMLFormContainer';
import { ErrorAPIResult } from '../../../redux/api/utils/isSuccessfulResult';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';

export interface ImageWidgetFormProps {
  accountId: number;
  smartLinkId: number;
  widget?: SerializedImageWidget | null;
  onSubmit: SubmitHandler<FieldsType>;
  onDelete: () => void;
  title: string;
  isLoading?: boolean;
  error?: ErrorAPIResult['error'];
}

const ImageWidgetFormComponent: React.FC<ImageWidgetFormProps> = ({
  widget,
  accountId,
  onSubmit,
  onDelete,
  title,
  isLoading,
  error,
  smartLinkId,
}) => {
  const intl = useIntl();
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const { control, handleSubmit, resetField } = useForm<FieldsType>({
    resolver,
    defaultValues: {
      [FieldsName.image]: widget?.data?.image ?? undefined,
    },
  });

  const submit = useCallback(
    (event?: React.BaseSyntheticEvent) => {
      handleSubmit(onSubmit)(event).catch(console.error);
    },
    [handleSubmit, onSubmit]
  );

  const removeImage = useCallback(() => {
    resetField(FieldsName.image, { defaultValue: undefined });
  }, [resetField]);

  const backRouteParams = useMemo(() => ({ accountId, smartLinkId }), [accountId, smartLinkId]);
  return (
    <SMLFormContainer
      backRoute={SMLRoutes.details}
      backRouteParams={backRouteParams}
      title={title}
      onSubmit={submit}
      onDelete={widget ? onDelete : undefined}
      isLoading={isLoading}
      error={error}
      submitDisabled={isFileLoading}
    >
      <Spacer flex flexDirection="column">
        <FileLoader
          name={FieldsName.image}
          accountId={accountId}
          control={control}
          removeImage={removeImage}
          disabled={isLoading}
          onLoadingStateChange={setIsFileLoading}
          label={intl.formatMessage({
            id: 'app.forms.widget.image',
            defaultMessage: 'Image',
          })}
        />
      </Spacer>
    </SMLFormContainer>
  );
};

export const ImageWidgetForm = TypedMemo(ImageWidgetFormComponent);
