import qs from 'qs';
import jwtDecode from 'jwt-decode';
import { defaultRetryOptions, fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { FetchError } from '../../generics/errors/FetchError';
import { AppleStorefront } from './models/AppleStorefront';
import { AppleMusicPlaylistsResponse } from './models/AppleMusicPlaylistsResponse';
import { AppleMusicPlaylist } from './models/AppleMusicPlaylist';
import { AppleMusicTracksResponse } from './models/AppleMusicTracksResponse';
import { AppleMusicCollectionTrack } from './models/AppleMusicCollectionTrack';
import { AppleMusicSearchResponse } from './models/AppleMusicSearchResponse';
import { AppleUser } from './models/AppleUser';
import { AppleMusicAuthenticationData } from './AppleMusicAuthenticationData';
import { AppleMusicAlbum } from './models/AppleMusicAlbum';
import { AppleMusicAlbumsResponse } from './models/AppleMusicAlbumsResponse';
import { AppleMusicAlbumTracksResponse } from './models/AppleMusicAlbumTracksResponse';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { ImporterID } from '../types';
import { AppleMusicTracksByISRCResponse } from './models/AppleMusicTracksByISRCResponse';
import { Response } from '../../utils/fetch-types';
import { AppleMusicRecentlyPlayedTracksResponse } from './models/AppleMusicRecentlyPlayedTracksResponse';
import { AppleMusicRecentlyAddedResourcesResponse } from './models/AppleMusicRecentlyAddedResourcesResponse';
import { AppleMusicDefaultRecommendationsResponse } from './models/AppleMusicDefaultRecommendationsResponse';
import { AppleMusicMatchedTrack } from './models/AppleMusicMatchedTrack';

export class AppleMusicAPI {
  public static WEB_PLAYER_URL = 'https://music.apple.com';

  private static BASE_URL = 'https://api.music.apple.com/v1/';

  public readonly userId: string;

  public storefront: AppleStorefront | undefined;

  private readonly musicUserToken: string;

  private readonly jwtToken: string;

  constructor(musicUserToken: string, jwtToken: string, userId: string) {
    this.musicUserToken = musicUserToken;
    this.jwtToken = jwtToken;
    this.userId = userId;
  }

  private get requestHeaders(): { [key: string]: string } {
    return {
      Origin: AppleMusicAPI.WEB_PLAYER_URL,
      Accept: 'application/json; charset=utf-8',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.jwtToken}`,
      'Media-User-Token': this.musicUserToken,
    };
  }

  static async renewMusicToken(jwtToken: string, musicToken: string): Promise<string> {
    const response = await fetchRetry(`https://play.itunes.apple.com/WebObjects/MZPlay.woa/wa/renewMusicToken`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'X-Apple-Music-User-Token': musicToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to renewMusicToken from AppleMusic got wrong response[${response.status}]: ${text}`
      );
    }
    const data = await response.json();
    return data['music-token'];
  }

  async fetch(url: string, options: any, retryOptions: RetryOptions = defaultRetryOptions): Promise<Response> {
    const response = await fetchRetry(url, options, retryOptions);

    if (response.status === 401 || response.status === 403) {
      const text = await response.text();
      throw new NotAuthenticatedError({ authId: this.userId, importerId: ImporterID.AppleMusic }, text);
    }

    return response;
  }

  async getStorefront(): Promise<string | undefined> {
    if (this.storefront?.id) {
      return this.storefront.id;
    }
    const response = await this.fetch(`${AppleMusicAPI.BASE_URL}me/storefront`, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to fetch storefront on Apple Music got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    const storefront = new AppleStorefront(jsonBody);
    this.storefront = storefront;
    return storefront.id;
  }

  async search(props: {
    query: string;
    searchType: 'songs' | 'albums';
    limit?: number;
    offset?: number;
  }): Promise<AppleMusicSearchResponse> {
    const { query, searchType, limit = 10, offset = 0 } = props;
    if (!query) {
      return new AppleMusicSearchResponse(null);
    }
    const storefront = await this.getStorefront();
    const response = await this.fetch(
      `${AppleMusicAPI.BASE_URL}catalog/${storefront}/search?${qs.stringify({
        types: searchType,
        term: query,
        limit,
        offset,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders,
      },
      { ...defaultRetryOptions, shouldWait: true }
    );

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to search for ${query} on Apple Music got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    return new AppleMusicSearchResponse(jsonBody);
  }

  async getTracksByISRC(isrc: string): Promise<AppleMusicTracksByISRCResponse> {
    if (!isrc) {
      return new AppleMusicTracksByISRCResponse(null);
    }
    const storefront = await this.getStorefront();
    const response = await this.fetch(
      `${AppleMusicAPI.BASE_URL}catalog/${storefront}/songs?${qs.stringify({
        'filter[isrc]': isrc,
        include: 'catalog',
        extend: 'isrc',
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders,
      },
      { ...defaultRetryOptions, shouldWait: true }
    );

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to search for ISRC:[${isrc}] on Apple Music got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    return new AppleMusicTracksByISRCResponse(jsonBody);
  }

  async loadPaginatedSongs(onBatch: (collections: AppleMusicCollectionTrack[]) => Promise<void>): Promise<void> {
    const limit = 100;
    const loadPlaylistsItems = async (offset: number): Promise<void> => {
      const trackResponse = await this.loadAllSongsPage(offset, limit);
      await onBatch(trackResponse.tracks);
      if (trackResponse.next) {
        await loadPlaylistsItems(offset + limit);
      }
    };

    await loadPlaylistsItems(0);
  }

  async loadAllSongsPage(offset = 0, limit = 100): Promise<AppleMusicTracksResponse> {
    const url = `${AppleMusicAPI.BASE_URL}me/library/songs?${qs.stringify({
      offset,
      limit,
      include: 'catalog',
      extend: 'isrc',
    })}`;
    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading all songs in AppleMusic[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new AppleMusicTracksResponse(data);
  }

  async loadPaginatedAlbums(onBatch: (collections: AppleMusicAlbum[]) => Promise<void>): Promise<void> {
    const limit = 100;
    const loadAlbumItems = async (offset: number): Promise<void> => {
      const trackResponse = await this.loadAllAlbumsPage(offset, limit);
      await onBatch(trackResponse.albums);
      if (trackResponse.next) {
        await loadAlbumItems(offset + limit);
      }
    };
    await loadAlbumItems(0);
  }

  async loadAllAlbumsPage(offset = 0, limit = 100): Promise<AppleMusicAlbumsResponse> {
    const url = `${AppleMusicAPI.BASE_URL}me/library/albums?${qs.stringify({
      offset,
      limit,
    })}`;
    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading all albums in AppleMusic[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new AppleMusicAlbumsResponse(data);
  }

  async loadPaginatedAlbumItems(
    albumId: string,
    onBatch: (collections: AppleMusicCollectionTrack[]) => Promise<void>
  ): Promise<void> {
    const limit = 100;

    const loadAlbumItems = async (offset: number): Promise<void> => {
      const trackResponse = await this.loadAllAlbumItemPage(albumId, offset, limit);
      await onBatch(trackResponse.tracks);
      if (trackResponse.next) {
        await loadAlbumItems(offset + limit);
      }
    };

    await loadAlbumItems(0);
  }

  async loadAllAlbumItemPage(albumId: string, offset = 0, limit = 100): Promise<AppleMusicAlbumTracksResponse> {
    const url = `${AppleMusicAPI.BASE_URL}me/library/albums/${albumId}/tracks?${qs.stringify({
      offset,
      limit,
      include: 'catalog',
      extend: 'isrc',
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading tracks for ${albumId} in AppleMusic[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new AppleMusicAlbumTracksResponse(data);
  }

  async loadPaginatedPlaylistsItems(
    playlistId: string,
    onBatch: (collections: AppleMusicCollectionTrack[]) => Promise<void>
  ): Promise<void> {
    const limit = 100;
    const loadPlaylistsItems = async (offset: number): Promise<void> => {
      const trackResponse = await this.loadPlaylistItemPage(playlistId, offset, limit);
      await onBatch(trackResponse.tracks);
      if (trackResponse.next) {
        await loadPlaylistsItems(offset + limit);
      }
    };

    await loadPlaylistsItems(0);
  }

  async loadPlaylistItemPage(playlistId: string, offset = 0, limit = 100): Promise<AppleMusicTracksResponse> {
    const url = `${AppleMusicAPI.BASE_URL}me/library/playlists/${playlistId}/tracks?${qs.stringify({
      offset,
      limit,
      include: 'catalog',
      extend: 'isrc',
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (response.status === 404) {
      return new AppleMusicTracksResponse(undefined);
    }

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading tracks for ${playlistId} in AppleMusic[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new AppleMusicTracksResponse(data);
  }

  async loadPaginatedPlaylists(onBatch: (collections: AppleMusicPlaylist[]) => Promise<void>): Promise<void> {
    const limit = 100;
    const loadPlaylists = async (offset: number): Promise<void> => {
      const playlistResponse = await this.loadPlaylistPage(offset, limit);
      await onBatch(playlistResponse.playlists);
      if (playlistResponse.next) {
        await loadPlaylists(offset + limit);
      }
    };

    await loadPlaylists(0);
  }

  async loadPlaylistPage(offset = 0, limit = 100): Promise<AppleMusicPlaylistsResponse> {
    const url = `${AppleMusicAPI.BASE_URL}me/library/playlists?${qs.stringify({
      offset,
      limit,
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading playlists in AppleMusic[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new AppleMusicPlaylistsResponse(data);
  }

  async getPlaylist(playlistId: string): Promise<AppleMusicPlaylist | null> {
    const url = `${AppleMusicAPI.BASE_URL}me/library/playlists/${playlistId}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading playlist[${playlistId}] in AppleMusic[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return AppleMusicPlaylist.fromData(data.data[0]);
  }

  async getAlbum(albumId: string): Promise<AppleMusicAlbum | null> {
    const storefront = await this.getStorefront();
    const url = `${AppleMusicAPI.BASE_URL}catalog/${storefront}/albums/${albumId}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading album[${albumId}] in AppleMusic[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return AppleMusicAlbum.fromData(data.data[0]);
  }

  async createPlaylist(name: string, description?: string): Promise<AppleMusicPlaylist | null> {
    const body = JSON.stringify({
      attributes: {
        name,
        description,
      },
    });
    const response = await this.fetch(`${AppleMusicAPI.BASE_URL}me/library/playlists`, {
      method: 'POST',
      headers: this.requestHeaders,
      body,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to create playlist (${body}) on AppleMusic got wrong response[${response.status}]: ${text}`
      );
    }
    const data = await response.json();
    return AppleMusicPlaylist.fromData(data.data[0]);
  }

  async addTracksToPlaylist(playlistId: string, trackIds: string[]): Promise<void> {
    const body = JSON.stringify({
      data: trackIds.map((trackId) => {
        return {
          id: trackId,
          type: 'songs',
        };
      }),
    });
    const response = await this.fetch(`${AppleMusicAPI.BASE_URL}me/library/playlists/${playlistId}/tracks`, {
      method: 'POST',
      headers: this.requestHeaders,
      body,
    });

    if (response.status !== 204) {
      const text = await response.text();
      if (text.includes("Requested resource does not exist in user's Library")) {
        throw new CollectionDoesNotExistsError();
      }
      throw new FetchError(
        response.status,
        `When trying to add tracks (${body}) to AppleMusic for playlist [${playlistId}] got wrong response[${response.status}]: ${text}`
      );
    }
  }

  async addResourcesToLibrary(props: {
    tracksIds?: string[];
    playlistsIds?: string[];
    artistsIds?: string[];
    albumsIds?: string[];
  }): Promise<void> {
    const { tracksIds, playlistsIds, artistsIds, albumsIds } = props;
    const anyIdPassed = [tracksIds, playlistsIds, artistsIds, albumsIds].some((array) => array && array.length > 0);
    if (!anyIdPassed) {
      return;
    }
    const response = await this.fetch(
      `${AppleMusicAPI.BASE_URL}me/library?${qs.stringify({
        ids: {
          songs: tracksIds?.join(','),
          playlists: playlistsIds?.join(','),
          artists: artistsIds?.join(','),
          albums: albumsIds?.join(','),
        },
      })}`,
      {
        method: 'POST',
        headers: this.requestHeaders,
      }
    );

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to add resources (${JSON.stringify(props)}) to AppleMusic library got wrong response[${
          response.status
        }]: ${text}`
      );
    }
  }

  async getRecentlyPlayedTracksPage(offset = 0, limit = 3) {
    const url = `${AppleMusicAPI.BASE_URL}me/recent/played/tracks?${qs.stringify({
      types: 'songs',
      limit,
      offset,
    })}`;

    const data = await this.fetch(url, { method: 'GET', headers: this.requestHeaders });
    return new AppleMusicRecentlyPlayedTracksResponse(await data.json());
  }

  async getAllRecentlyPlayedTracks(): Promise<AppleMusicMatchedTrack[]> {
    const limit = 10;
    let tracks: AppleMusicMatchedTrack[] = [];
    const getRecentlyPlayedTracks = async (offset: number) => {
      const tracksResponse = await this.getRecentlyPlayedTracksPage(offset, limit);
      tracks = [...tracks, ...tracksResponse.tracks];
      const { hasNextPage } = tracksResponse;
      if (hasNextPage) {
        await getRecentlyPlayedTracks(offset + limit);
      }
    };

    await getRecentlyPlayedTracks(0);
    return tracks;
  }

  async getAllAddedResources(): Promise<(AppleMusicCollectionTrack | AppleMusicAlbum | AppleMusicPlaylist)[]> {
    const url = `${AppleMusicAPI.BASE_URL}me/library/recently-added`;

    const data = await this.fetch(url, { method: 'GET', headers: this.requestHeaders });
    return new AppleMusicRecentlyAddedResourcesResponse(await data.json()).resources;
  }

  async getDefaultRecommendationPage(offset = 0, limit = 3) {
    const url = `${AppleMusicAPI.BASE_URL}me/recommendations?${qs.stringify({
      limit,
      offset,
    })}`;

    const data = await this.fetch(url, { method: 'GET', headers: this.requestHeaders });
    return new AppleMusicDefaultRecommendationsResponse(await data.json());
  }

  async getDefaultRecommendations(): Promise<(AppleMusicCollectionTrack | AppleMusicPlaylist | AppleMusicAlbum)[]> {
    const limit = 10;
    let recommendations: (AppleMusicCollectionTrack | AppleMusicPlaylist | AppleMusicAlbum)[] = [];
    const getRecentlyPlayedTracks = async (offset: number) => {
      const recommendationsResponse = await this.getDefaultRecommendationPage(offset, limit);
      recommendations = [...recommendations, ...recommendationsResponse.recommendations];
      const { hasNextPage } = recommendationsResponse;
      if (hasNextPage) {
        await getRecentlyPlayedTracks(offset + limit);
      }
    };

    await getRecentlyPlayedTracks(0);
    return recommendations;
  }

  getAuthData(user: AppleUser): AppleMusicAuthenticationData {
    if (!user.id) {
      throw new Error('Could not get AppleMusic user id in getAuthDataForPublicApi');
    }
    const { exp } = jwtDecode(this.jwtToken) as { exp: number };
    return {
      authId: this.userId,
      title: user.fullName ?? user.id,
      subTitle: user.fullName ? user.id : '',
      imageUrl: null,
      userUUID: null,
      expiresAt: new Date(exp * 1000),
      additionalData: {
        jwtToken: this.jwtToken,
        musicUserToken: this.musicUserToken,
      },
    };
  }
}
