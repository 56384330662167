import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { createAmazonAlbumUrl, getArtistsNamesAsString, getBiggestImageUrlFromImages } from '../utils';
import { Album, Artist } from './types';
import { tryParseInt } from '../../../utils/tryParseInt';

export class AmazonMusicAlbum extends GenericCollection {
  constructor(data: Omit<CollectionModelData, 'type'>, public artists: Pick<Artist, 'id' | 'name'>[] | undefined) {
    super({ type: CollectionType.ALBUM, ...data });
  }

  static fromData(data: Album | undefined | null): AmazonMusicAlbum | null {
    const { id, shortTitle, title, url, artists, images, trackCount } = data ?? {};
    const name = shortTitle || title;
    if (!id || !name) {
      return null;
    }
    return new AmazonMusicAlbum(
      {
        rawId: id,
        name,
        itemCount: trackCount,
        additionalData: {
          imageUrl: getBiggestImageUrlFromImages(images),
          artist: getArtistsNamesAsString(artists),
        },
        publicUrl: url ?? createAmazonAlbumUrl(id),
      },
      artists
        ?.filter((artist): artist is NonNullable<typeof artist> => !!artist)
        .map((artist) => ({ id: artist.id, name: artist.name }))
    );
  }

  static fromShareURLData(data: any, rawId: string, url: string): AmazonMusicAlbum {
    const itemCountStringMaybe = data?.headerTertiaryText?.match(/^(.*?) SONGS/)?.[1];
    return new AmazonMusicAlbum(
      {
        rawId,
        name: data.headerImageAltText,
        additionalData: { imageUrl: data.headerImage, artist: data.headerPrimaryText },
        publicUrl: url,
        itemCount: tryParseInt(itemCountStringMaybe),
      },
      undefined
    );
  }
}
