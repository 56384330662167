import { ReactComponent as ZvukLight } from '../../../../assets/images/music-services/zvuk-light.svg';
import { ReactComponent as ZvukDark } from '../../../../assets/images/music-services/zvuk-dark.svg';
import { ReactComponent as ZvukLightPadding } from '../../../../assets/images/music-services/zvuk-light-padding.svg';
import { ReactComponent as ZvukDarkPadding } from '../../../../assets/images/music-services/zvuk-dark-padding.svg';
import { ReactComponent as ZvukColorLight } from '../../../../assets/images/music-services/zvuk-color-light.svg';
import { ReactComponent as ZvukColorDark } from '../../../../assets/images/music-services/zvuk-color-dark.svg';
import { ReactComponent as ZvukColorLightPadding } from '../../../../assets/images/music-services/zvuk-color-light-padding.svg';
import { ReactComponent as ZvukColorDarkPadding } from '../../../../assets/images/music-services/zvuk-color-dark-padding.svg';
import { ReactComponent as ZvukDarkDanger } from '../../../../assets/images/music-services-danger/zvuk-dark-danger.svg';
import { ReactComponent as ZvukLightDanger } from '../../../../assets/images/music-services-danger/zvuk-light-danger.svg';
import { ReactComponent as ZvukLightDangerPadding } from '../../../../assets/images/music-services-danger/zvuk-light-padding-danger.svg';
import { ReactComponent as ZvukDarkDangerPadding } from '../../../../assets/images/music-services-danger/zvuk-dark-padding-danger.svg';
import { ReactComponent as ZvukDarkWarning } from '../../../../assets/images/music-services-warning/zvuk-dark-warning.svg';
import { ReactComponent as ZvukLightWarning } from '../../../../assets/images/music-services-warning/zvuk-light-warning.svg';
import { ReactComponent as ZvukLightWarningPadding } from '../../../../assets/images/music-services-warning/zvuk-light-padding-warning.svg';
import { ReactComponent as ZvukDarkWarningPadding } from '../../../../assets/images/music-services-warning/zvuk-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const zvukImages: ImporterImages = {
  logo: {
    light: ZvukLight,
    dark: ZvukDark,
  },
  logoPadding: {
    light: ZvukLightPadding,
    dark: ZvukDarkPadding,
  },
  logoColor: {
    light: ZvukColorLight,
    dark: ZvukColorDark,
  },
  logoColorPadding: {
    light: ZvukColorLightPadding,
    dark: ZvukColorDarkPadding,
  },
  logoOnBackground: ZvukColorDark,
  logoDanger: {
    dark: ZvukDarkDanger,
    light: ZvukLightDanger,
  },
  logoDangerPadding: {
    dark: ZvukDarkDangerPadding,
    light: ZvukLightDangerPadding,
  },
  logoWarning: {
    dark: ZvukDarkWarning,
    light: ZvukLightWarning,
  },
  logoWarningPadding: {
    light: ZvukLightWarningPadding,
    dark: ZvukDarkWarningPadding,
  },
};
