import { TidalAlbum } from './TidalAlbum';
import { TidalCollectionTrack } from './TidalCollectionTrack';

export class TidalAlbumWithTracksResponse {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get album(): TidalAlbum | null {
    return TidalAlbum.fromData(this.data?.rows?.[0]?.modules?.[0]?.album);
  }

  get tracks(): TidalCollectionTrack[] {
    const items = this.data?.rows?.[1]?.modules?.[0]?.pagedList?.items;
    if (!items) {
      return [];
    }

    return items
      .map((item: any) => TidalCollectionTrack.fromAlbumData(item.item))
      .filter((track: TidalCollectionTrack | null) => !!track);
  }
}
