export enum MusicServiceErrorType {
  // These values also should be defined in `IntegrationAuthErrorType` from `musicapi/prisma/main/schema.prisma`
  accessDenied = 'accessDenied',
  notAllowedInYourCountry = 'notAllowedInYourCountry',
  missingScopes = 'missingScopes',
  deniedSubscriptionTier = 'deniedSubscriptionTier',
}

export class MusicServiceError extends Error {
  public readonly errorType;

  constructor({ message, errorType }: { message: string; errorType: MusicServiceErrorType }) {
    super(message);
    this.errorType = errorType;
  }
}
