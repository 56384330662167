import React from 'react';
import { Alert } from 'reactstrap';
import { TypedMemo } from '../../common/types';
import { SMLPremiumViewRendererProps } from './props';
import { LoadingView } from '../../common/views/LoadingView';
import { smartLinksFeatures } from './features/consts';
import { PaddleSmartLinksPaymentView } from '../../payments/paddle/PaddleSmartLinksPaymentView';
import { SMLLinks } from './SMLLinks';
import { SmartLinksFeatures } from './features/SmartLinksFeatures';
import { usePaddlePricing } from '../../payments/paddle/hooks/usePaddlePricing';

const SmartLinksPremiumViewRendererComponent: React.FC<SMLPremiumViewRendererProps> = ({ licenseInfo }) => {
  const [paddlePricing, paddleError] = usePaddlePricing();
  if (!paddlePricing) {
    return <LoadingView />;
  }

  if (paddleError) {
    return <Alert color="danger">{paddleError}</Alert>;
  }

  return (
    <div className="main-padding overflow-auto">
      <PaddleSmartLinksPaymentView licenseInfo={licenseInfo} paddlePricing={paddlePricing} />
      <div className="d-flex align-items-center flex-column my-3">
        <SMLLinks />
      </div>
      <SmartLinksFeatures licenseInfo={licenseInfo} features={smartLinksFeatures} />
    </div>
  );
};

export const SmartLinksPremiumViewRenderer = TypedMemo(SmartLinksPremiumViewRendererComponent);
