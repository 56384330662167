import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LicenseRequestType } from 'fym-common/src/license/consts';
import { getSystemLocale } from '../../../utils/getUserLocale';
import { isSuccessfulResult } from '../../../redux/api/utils/isSuccessfulResult';
import { setLicenseInfoResult } from '../../../redux/slices/license/slice';
import { useExistingLicenseKey } from './useExistingLicenseKey';
import { useValidateLicenseAPI } from './useValidateLicenseAPI';

export type ValidateLicenseCallType = ReturnType<typeof useValidateLicense>['validateLicense'];

export function useValidateLicense() {
  const userLocale = getSystemLocale();
  const existingLicenseId = useExistingLicenseKey();
  const [isValid, setIsValid] = useState<boolean | undefined>();
  const [validateLicenseCall, { isLoading, isError, error }] = useValidateLicenseAPI();
  const dispatch = useDispatch();
  const validateLicense = useCallback(
    async (requestToCheck: { licenseKey: string; email: string; type: LicenseRequestType }) => {
      const { licenseKey, email, type } = requestToCheck;
      const validationResult = await validateLicenseCall({
        licenseId: licenseKey,
        licenseType: type,
        email,
        language: userLocale,
        existingLicenseId,
      });
      if (!isSuccessfulResult(validationResult) || !validationResult.data.isValid) {
        setIsValid(false);
        return false;
      }
      const { data } = validationResult;
      dispatch(setLicenseInfoResult(data));
      setIsValid(true);
      return true;
    },
    [validateLicenseCall, userLocale, existingLicenseId, dispatch]
  );

  return { isValid, isLoading, isError, validateLicense, error };
}
