import { QQMusicLikedPlaylistsResponseData } from '../types';
import { QQMusicPlaylist } from './QQMusicPlaylist';
import { tryParseInt } from '../../../utils/tryParseInt';

export class QQMusicLikedPlaylistsResponse {
  private readonly data: QQMusicLikedPlaylistsResponseData['data'] | undefined;

  constructor(data: QQMusicLikedPlaylistsResponseData | undefined) {
    this.data = data?.data;
  }

  get likedPlaylists(): QQMusicPlaylist[] {
    if (!this.data?.cdlist) {
      return [];
    }
    return this.data.cdlist
      .filter((item) => item.dir_show === '1')
      .map((item) => QQMusicPlaylist.fromLikedPlaylistData(item))
      .filter((playlist): playlist is QQMusicPlaylist => !!playlist);
  }

  get total() {
    return tryParseInt(this.data?.totaldiss);
  }
}
