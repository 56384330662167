import { AnghamiAlbum } from './AnghamiAlbum';

export class AnghamiAlbumsResponse {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get albums(): AnghamiAlbum[] {
    return this.data.sections[0].data
      .map((item: any) => AnghamiAlbum.fromData(item))
      .filter((album: AnghamiAlbum | null) => !!album);
  }
}
