import React, { useCallback } from 'react';
import { PaginationItem, PaginationLink } from 'reactstrap';
import { APIPaginationItemProps } from './types';
import { TypedMemo } from '../../types';

export const APIPaginationItemComponent: React.FC<APIPaginationItemProps> = ({
  active,
  label,
  onPageChange,
  disabled,
  page,
}) => {
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (!onPageChange || page === undefined) {
        return;
      }
      event.preventDefault();
      onPageChange(page);
    },
    [onPageChange, page]
  );

  return (
    <PaginationItem active={active} disabled={disabled} onClick={onClick}>
      <PaginationLink className="ui-text">{label}</PaginationLink>
    </PaginationItem>
  );
};
export const APIPaginationItem = TypedMemo(APIPaginationItemComponent);
