import { SpotifyTrackResponseType } from '../models/types';
import { validateISRC } from '../../../utils/validateISRC';
import { SpotifyAlbum } from '../models/SpotifyAlbum';
import { SpotifyArtist } from '../models/SpotifyArtist';
import { createSpotifyTrackUrl, getArtistsNamesAsString } from '../utils';

export function convertSpotifyTrackData(data: SpotifyTrackResponseType | undefined) {
  const { name, id, uri, album, artists, external_ids, duration_ms, preview_url, external_urls } = data ?? {};
  const rawId = id ?? uri?.split('spotify:track:')[1];
  if (!name || !rawId) {
    return null;
  }
  return {
    trackData: {
      rawId,
      name,
      album: album?.name,
      artist: getArtistsNamesAsString(artists),
      isrc: validateISRC(external_ids?.isrc),
      imageUrl: album?.images?.[0]?.url,
      duration: duration_ms,
      previewUrl: preview_url,
      sourceURL: external_urls?.spotify ?? createSpotifyTrackUrl(rawId),
    },
    album: SpotifyAlbum.fromData(album),
    artists:
      artists?.map((a) => SpotifyArtist.fromData(a)).filter((a): a is NonNullable<typeof a> => a !== null) ?? null,
  };
}
