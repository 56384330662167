import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { createQobuzAlbumUrl } from '../utils';

export class QobuzAlbum extends GenericCollection {
  constructor(data: Omit<CollectionModelData, 'type'>) {
    super({ type: CollectionType.ALBUM, ...data });
  }

  static fromData(data: any): QobuzAlbum | null {
    if (!data?.id || !data.title) {
      return null;
    }
    return new QobuzAlbum({
      rawId: data.id,
      name: data.title,
      itemCount: data.tracks_count,
      additionalData: { imageUrl: data.image?.large, artist: data.artist.name },
      publicUrl: createQobuzAlbumUrl(data.id),
    });
  }
}
