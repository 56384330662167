import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ErrorBoundaryProps, ErrorBoundaryState } from './ErrorBoundaryProps';
import { onErrorHappened } from './onErrorHappened';
import { Sentry } from './sentry';
import { reloadApp } from '../../utils/reloadApp';

/* eslint-disable @typescript-eslint/unbound-method */

export class ErrorBoundary extends React.Component<React.PropsWithChildren<ErrorBoundaryProps>, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, eventId: '' };
    this.handleShowReportDialog = this.handleShowReportDialog.bind(this);
  }

  componentDidMount(): void {
    onErrorHappened();
  }

  static getDerivedStateFromError(_error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    const { realmId, licenseKey } = this.props;
    Sentry.withScope((scope) => {
      scope.setExtras({
        ...errorInfo,
        realmId,
        licenseKey,
      });

      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  handleShowReportDialog() {
    const { eventId } = this.state;
    const { email, locale } = this.props;
    Sentry.showReportDialog({
      eventId,
      locale,
      user: { email },
    });
  }

  render() {
    const { hasError, eventId } = this.state;
    const { children, openClearAppModal } = this.props;

    if (hasError) {
      return (
        <Container className="d-flex h-100 w-100">
          <Row className="align-items-center justify-content-around">
            <Col>
              <h3>
                <FormattedMessage
                  id="app.error_boundary.title"
                  defaultMessage="Oops! That wasn't supposed to happen."
                />
              </h3>
              <p>
                <FormattedMessage
                  id="app.error_boundary.error_sent"
                  defaultMessage="The error has been sent to our tech team who are fixing it."
                />
              </p>
              {eventId && (
                <p>
                  <FormattedMessage id="app.error_boundary.issue_id" defaultMessage="Issue Identifier" />
                  {`: ${eventId}`}
                </p>
              )}
              <p>
                <FormattedMessage
                  id="app.error_boundary.help_devs"
                  defaultMessage="If you can spare a second to help our devs, would you mind sharing what steps have you taken that led to the
            error? They will be able to resolve the error soooo much faster."
                />
              </p>
              <Button color="primary" onClick={this.handleShowReportDialog} className="me-2">
                <FormattedMessage id="app.error_boundary.share_feedback" defaultMessage="Share feedback" />
              </Button>
              <Button color="warning" onClick={reloadApp} className="me-2">
                <FormattedMessage id="app.error_boundary.restart" defaultMessage="Restart" />
              </Button>
              {openClearAppModal && (
                <Button color="danger" onClick={openClearAppModal}>
                  <FormattedMessage id="app.error_boundary.clear_data" defaultMessage="Clear app data" />
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      );
    }

    return children;
  }
}
