import { createApi } from '@reduxjs/toolkit/query/react';
import { InspectByUrlResponse, SearchResponse } from 'fym-common/src/types/searchService';
import { config } from '../../config/config';
import { customBaseQuery } from './utils/customBaseQuery';
import { RematchOptions, RematchResponse, SearchOptions } from './musicAPI.types';
import { APIType } from './types';

export const baseUrl = config.musicApiURL;
console.log('Using redux musicAPI base url:', baseUrl);

export const musicAPI = createApi({
  reducerPath: 'musicAPI',
  baseQuery: customBaseQuery(baseUrl, APIType.music),
  endpoints: (builder) => ({
    inspectByUrl: builder.query<InspectByUrlResponse, string>({
      query: (url) => ({
        url: `inspect/url`,
        method: 'POST',
        body: JSON.stringify({ url }),
      }),
    }),
    search: builder.query<SearchResponse, SearchOptions>({
      query: ({ track, album, artist, sources, type }) => ({
        url: `search`,
        method: 'POST',
        body: JSON.stringify({ type, track, album, artist, sources }),
      }),
    }),
    rematch: builder.query<RematchResponse, RematchOptions>({
      query: ({
        originalTrackId,
        rematchTrackId,
        sourceTrackId,
        searchedPhrase,
        targetIntegrationType,
        sourceIntegrationType,
        userId,
      }) => ({
        url: `data/rematch`,
        method: 'POST',
        body: JSON.stringify({
          originalTrackId,
          rematchTrackId,
          sourceTrackId,
          searchedPhrase,
          targetIntegrationType,
          sourceIntegrationType,
          userId,
        }),
      }),
    }),
  }),
});

export const { useLazySearchQuery, useLazyInspectByUrlQuery, useLazyRematchQuery } = musicAPI;
