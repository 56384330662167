import { QobuzPlaylist } from './QobuzPlaylist';

export class QobuzPlaylistsResponse {
  private readonly data: any;

  private readonly userId: number;

  constructor(data: any, userId: number) {
    this.data = data;
    this.userId = userId;
  }

  get playlists(): QobuzPlaylist[] {
    if (!this.data?.playlists?.items) {
      return [];
    }
    return this.data.playlists.items
      .map((item: any) => QobuzPlaylist.fromData(item, this.userId))
      .filter((playlist: QobuzPlaylist | null) => !!playlist);
  }

  get total(): number {
    return this.data.playlists.total;
  }
}
