import { validateISRC } from '../../../utils/validateISRC';
import { TidalAlbum } from '../models/TidalAlbum';
import { convertTidalRawArtists } from '../models/TidalArtist';
import { buildCoverUrl, createTidalTrackUrl, getArtistsNamesAsString } from '../utils';

export function convertTidalTrackData(data: any) {
  if (!data?.id || !data.title) {
    return null;
  }
  return {
    trackData: {
      rawId: `${data.id}`,
      name: data.title,
      album: data.album?.title,
      artist: getArtistsNamesAsString(data.artists),
      isrc: validateISRC(data?.isrc),
      imageUrl: buildCoverUrl(data.album?.cover),
      duration: data.duration ? data.duration * 1000 : undefined,
      sourceURL: createTidalTrackUrl(data.id),
    },
    album: TidalAlbum.fromData(data.album),
    artists: convertTidalRawArtists(data.artists),
  };
}
