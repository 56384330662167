import { SmartLinkWidgetType } from 'fym-common/src/db/enums';
import {
  SerializedImageWidget,
  SerializedInfoWidget,
  SerializedLinkWidget,
  SerializedPlayerWidget,
  SerializedProfileWidget,
  SerializedSocialLinksWidget,
  SerializedWidget,
} from 'fym-common/src/db/serializersTypes';
import { WidgetManager } from './types';
import { InfoWidgetListItem } from './views/infoWidget/infoWidgetListItem/InfoWidgetListItem';
import { InfoWidgetEditionView } from './views/infoWidget/infoWidgetEditionView/InfoWidgetEditionView';
import { ImageWidgetListItem } from './views/imageWidget/listItem/ImageWidgetListItem';
import { ImageWidgetEditionView } from './views/imageWidget/editionView/ImageWidgetEditionView';
import { LinkWidgetListItem } from './views/linkWidget/linkWidgetListItem/LinkWidgetListItem';
import { LinkWidgetEditionView } from './views/linkWidget/linkWidgetEditionView/LinkWidgetEditionView';
import { SocialWidgetListItem } from './views/socialWidget/socialWidgetListItem/SocialWidgetListItem';
import { SocialWidgetEditionView } from './views/socialWidget/socialWidgetEditionView/SocialWidgetEditionView';
import { PlayerWidgetListItem } from './views/playerWidget/playerWidgetListItem/PlayerWidgetListItem';
import { PlayerWidgetEditionView } from './views/playerWidget/playerWidgetEditionView/PlayerWidgetEditionView';
import { ProfileWidgetEditionView } from './views/profileWidget/profileWidgetEditionView/ProfileWidgetEditionView';
import { ProfileWidgetListItem } from './views/profileWidget/profileWidgetListItem/ProfileWidgetListItem';

/* eslint-disable react/jsx-props-no-spreading */
const InfoWidget: WidgetManager<SerializedInfoWidget> = {
  type: SmartLinkWidgetType.info,
  listItemView: InfoWidgetListItem,
  editionView: InfoWidgetEditionView,
};

const ImageWidget: WidgetManager<SerializedImageWidget> = {
  type: SmartLinkWidgetType.image,
  listItemView: ImageWidgetListItem,
  editionView: ImageWidgetEditionView,
};

const LinkWidget: WidgetManager<SerializedLinkWidget> = {
  type: SmartLinkWidgetType.link,
  listItemView: LinkWidgetListItem,
  editionView: LinkWidgetEditionView,
};

const SocialLinksWidget: WidgetManager<SerializedSocialLinksWidget> = {
  type: SmartLinkWidgetType.socialLinks,
  listItemView: SocialWidgetListItem,
  editionView: SocialWidgetEditionView,
};

const PlayerWidget: WidgetManager<SerializedPlayerWidget> = {
  type: SmartLinkWidgetType.player,
  listItemView: PlayerWidgetListItem,
  editionView: PlayerWidgetEditionView,
};

const ProfileWidget: WidgetManager<SerializedProfileWidget> = {
  type: SmartLinkWidgetType.profile,
  listItemView: ProfileWidgetListItem,
  editionView: ProfileWidgetEditionView,
};

/* eslint-enable react/jsx-props-no-spreading */

const allWidgetManagers = [InfoWidget, ImageWidget, LinkWidget, SocialLinksWidget, PlayerWidget, ProfileWidget];

const allWidgetManagersByType = (() => {
  return allWidgetManagers.reduce((result, widgetManager) => {
    result[`${widgetManager.type}`] = widgetManager;
    return result;
  }, {} as Record<string, typeof allWidgetManagers[number]>);
})();

export function getWidgetManager<T extends SerializedWidget>(type: SmartLinkWidgetType): WidgetManager<T> {
  return allWidgetManagersByType[type] as unknown as WidgetManager<T>;
}
