import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { TypedMemo } from '../types';

const HorizontalVerticalCenterContainerComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Container fluid className="flex-grow-1 flex-shrink-1 d-flex h-100 w-100">
      <Row className="align-items-center justify-content-around flex-grow-1 flex-shrink-1">
        <Col>{children}</Col>
      </Row>
    </Container>
  );
};

export const HorizontalVerticalCenterContainer = TypedMemo(HorizontalVerticalCenterContainerComponent);
