import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { TypedMemo } from '../../common/types';
import { SettingsListItemProps } from './SettingsProps';
import { ClickableDiv } from '../../common/views/ClickableDiv';
import { UserAvatar } from '../../common/views/userAvatar/UserAvatar';

const SettingsListItemComponent: React.FC<SettingsListItemProps> = ({ item }) => {
  return (
    <ClickableDiv onClick={item.onClick}>
      <div className="settings-item-container m-0 d-flex flex-row">
        <div className="d-flex">
          {item.color && <div className="settings-item-color-line" style={{ backgroundColor: item.color }} />}
          <span className="settings-item-text ui-text">{item.text}</span>
        </div>
        <div className="d-flex flex-row align-items-center">
          {item.avatars?.map(({ url, danger, key }) => (
            <div key={key} className="me-3">
              <UserAvatar size={40} url={url} danger={danger} />
            </div>
          ))}
          {item.icon && <item.icon width={42} className="me-3" />}
          <FontAwesomeIcon icon={faChevronRight} className="active-icon" />
        </div>
      </div>
    </ClickableDiv>
  );
};

export const SettingsListItem = TypedMemo(SettingsListItemComponent);
