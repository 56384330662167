import React from 'react';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../../../common/types';
import { usePlaylistLinkForm } from '../forms/PlaylistLinkForm/usePlaylistLinkForm';
import { Alert } from '../../../forms/components/Alert/Alert';
import { AlertLevel } from '../../../forms/components/Alert/props';
import { PlaylistLinkWizardFormViewProps } from './props';
import { PlaylistLinkWizardFormViewRenderer } from './PlaylistLinkWizardFormViewRenderer';

const PlaylistLinkWizardFormViewComponent: React.FC<PlaylistLinkWizardFormViewProps> = ({ onSuccess, accountId }) => {
  const intl = useIntl();

  const { isLoading, error, submit, MediaUrlInput } = usePlaylistLinkForm({ onSuccess, accountId });

  return (
    <PlaylistLinkWizardFormViewRenderer isLoading={isLoading} error={error} onSubmit={submit}>
      {MediaUrlInput}
      <Alert level={AlertLevel.info} center={false}>
        {intl.formatMessage({
          id: 'app.forms.playlist_link.help2',
          defaultMessage: 'Using this link we will create a player widget.',
        })}
      </Alert>
    </PlaylistLinkWizardFormViewRenderer>
  );
};

export const PlaylistLinkWizardFormView = TypedMemo(PlaylistLinkWizardFormViewComponent);
