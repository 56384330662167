import { ReactComponent as BoomplayLight } from '../../../../assets/images/music-services/boomplay-light.svg';
import { ReactComponent as BoomplayDark } from '../../../../assets/images/music-services/boomplay-dark.svg';
import { ReactComponent as BoomplayLightPadding } from '../../../../assets/images/music-services/boomplay-light-padding.svg';
import { ReactComponent as BoomplayDarkPadding } from '../../../../assets/images/music-services/boomplay-dark-padding.svg';
import { ReactComponent as BoomplayColorLight } from '../../../../assets/images/music-services/boomplay-color-light.svg';
import { ReactComponent as BoomplayColorDark } from '../../../../assets/images/music-services/boomplay-color-dark.svg';
import { ReactComponent as BoomplayColorLightPadding } from '../../../../assets/images/music-services/boomplay-color-light-padding.svg';
import { ReactComponent as BoomplayColorDarkPadding } from '../../../../assets/images/music-services/boomplay-color-dark-padding.svg';
import { ReactComponent as BoomplayDarkDanger } from '../../../../assets/images/music-services-danger/boomplay-dark-danger.svg';
import { ReactComponent as BoomplayLightDanger } from '../../../../assets/images/music-services-danger/boomplay-light-danger.svg';
import { ReactComponent as BoomplayLightDangerPadding } from '../../../../assets/images/music-services-danger/boomplay-light-padding-danger.svg';
import { ReactComponent as BoomplayDarkDangerPadding } from '../../../../assets/images/music-services-danger/boomplay-dark-padding-danger.svg';
import { ReactComponent as BoomplayDarkWarning } from '../../../../assets/images/music-services-warning/boomplay-dark-warning.svg';
import { ReactComponent as BoomplayLightWarning } from '../../../../assets/images/music-services-warning/boomplay-light-warning.svg';
import { ReactComponent as BoomplayLightWarningPadding } from '../../../../assets/images/music-services-warning/boomplay-light-padding-warning.svg';
import { ReactComponent as BoomplayDarkWarningPadding } from '../../../../assets/images/music-services-warning/boomplay-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const boomplayImages: ImporterImages = {
  logo: {
    light: BoomplayLight,
    dark: BoomplayDark,
  },
  logoPadding: {
    light: BoomplayLightPadding,
    dark: BoomplayDarkPadding,
  },
  logoColor: {
    light: BoomplayColorLight,
    dark: BoomplayColorDark,
  },
  logoColorPadding: {
    light: BoomplayColorLightPadding,
    dark: BoomplayColorDarkPadding,
  },
  logoOnBackground: BoomplayDark,
  logoDanger: {
    dark: BoomplayDarkDanger,
    light: BoomplayLightDanger,
  },
  logoDangerPadding: {
    dark: BoomplayDarkDangerPadding,
    light: BoomplayLightDangerPadding,
  },
  logoWarning: {
    dark: BoomplayDarkWarning,
    light: BoomplayLightWarning,
  },
  logoWarningPadding: {
    light: BoomplayLightWarningPadding,
    dark: BoomplayDarkWarningPadding,
  },
};
