import React, { useCallback, useMemo } from 'react';
import { Control, useController } from 'react-hook-form';
import { SerializedSmartLinkVersionTheme } from 'fym-common/src/db/serializersTypes';
import { TypedMemo } from '../../../common/types';
import { FormInputContainer } from '../FormInputContainer/FormInputContainer';
import { FormThemeSelectionRenderer } from './FormThemeSelectionRenderer';
import {
  FieldsName as SmartLinkFormFieldsName,
  FieldsType as SmartLinkFormFieldsType,
} from '../../SmartLinkForm/consts';

interface FormThemeSelectionProps {
  control: Control<SmartLinkFormFieldsType>;
  label: string;
  disabled?: boolean;
}

const FormThemeSelectionComponent: React.FC<FormThemeSelectionProps> = ({ control, disabled, label }) => {
  const name = SmartLinkFormFieldsName.theme;
  const { field, fieldState } = useController({ control, name });
  const { value, onChange } = field;
  const { error, isDirty, isTouched } = fieldState;

  const onClick = useCallback(
    (option: string) => {
      onChange(option);
    },
    [onChange]
  );

  const themes = useMemo(() => Object.values(SerializedSmartLinkVersionTheme), []);
  return (
    <FormInputContainer label={label} name={name} error={error?.message} isDirty={isDirty} isTouched={isTouched}>
      <FormThemeSelectionRenderer themes={themes} value={value} disabled={disabled} onClick={onClick} />
    </FormInputContainer>
  );
};
export const FormThemeSelection = TypedMemo(FormThemeSelectionComponent);
