import React from 'react';
import { useIntl } from 'react-intl';
import { Row } from 'reactstrap';
import { TypedMemo } from '../../../common/types';
import { Title } from '../../../common/views/title/Title';
import { FeaturesProps } from './types';
import { featuresColorsClassNames } from './colorClasses';
import { SmartLinksFeatureBox } from './featureBox/SmartLinksFeatureBox';

const SmartLinksFeaturesComponent: React.FC<FeaturesProps> = ({ licenseInfo, features }) => {
  const intl = useIntl();
  return (
    <>
      <Title center>{intl.formatMessage({ id: 'app.profile.features', defaultMessage: 'Features' })}</Title>
      <Row className="justify-content-center">
        {features.map((feature, index) => {
          const iconColorClass = featuresColorsClassNames[index % featuresColorsClassNames.length] ?? 'text-gold';
          return (
            <SmartLinksFeatureBox
              key={feature.title}
              feature={feature}
              color={iconColorClass}
              licenseInfo={licenseInfo}
            />
          );
        })}
      </Row>
    </>
  );
};

export const SmartLinksFeatures = TypedMemo(SmartLinksFeaturesComponent);
