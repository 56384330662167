import URL from 'url-parse';
import { LinkType, recognizeLinkTypeByUrl } from './recognizeLinkTypeByUrl';

export function isSupportedUrlForPlayerWidget(url: string): boolean {
  try {
    const parsedURL = new URL(url);
    const recognizedService = recognizeLinkTypeByUrl(parsedURL);
    return SupportedServicesForPlayerWidget.includes(recognizedService);
  } catch {
    return false;
  }
}

export const SupportedServicesForPlayerWidget = [
  LinkType.spotify,
  LinkType.applemusic,
  LinkType.tidal,
  LinkType.youtube,
  LinkType.amazonmusic,
  LinkType.soundcloud,
  LinkType.deezer,
  LinkType.napster,
  LinkType.boomplay,
  LinkType.qobuz,
  LinkType.anghami,
  LinkType.zvuk,
];
