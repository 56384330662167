export class YandexAccountStatus {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get isAvailable(): boolean {
    return this.data.result.account.serviceAvailable;
  }
}
