import { MusicService } from './types';
import { ImporterID } from '../../importers/types';
import { MusicAPIIntegrationID } from '../types';
import { LinkType } from '../../utils/recognizeLinkTypeByUrl';

export const gaana: MusicService = {
  id: 'gaana',
  importerID: ImporterID.Gaana,
  linkType: LinkType.gaana,
  musicAPIID: MusicAPIIntegrationID.Gaana,
  shortName: 'Gaana',
  name: 'Gaana',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: false,
  color: { label: '#E72C30', background: '#EC7668' },
};
