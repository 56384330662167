import { MusicService } from './types';
import { ImporterID } from '../../importers/types';
import { MusicAPIIntegrationID } from '../types';
import { LinkType } from '../../utils/recognizeLinkTypeByUrl';

export const yandexMusic: MusicService = {
  id: 'yandex-music',
  importerID: ImporterID.Yandex,
  linkType: LinkType.yandexmusic,
  musicAPIID: MusicAPIIntegrationID.YandexMusic,
  shortName: 'Yandex Music',
  name: 'Yandex Music',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: true,
  color: { label: '#FF5555', background: '#F8A6A6' },
};
