import React, { useCallback } from 'react';
import { TypedMemo } from '../../common/types';
import { PaddleUpgradeViewRenderer } from './PaddleUpgradeViewRenderer';
import { PaddleUpgradeViewProps } from './types';
import { useLicenseChange } from './useLicenseChange';

const PaddleUpgradeViewComponent: React.FC<PaddleUpgradeViewProps> = ({ productType, onClose }) => {
  const { isLoading, isError, isSuccess, changeLicense } = useLicenseChange(onClose);

  const onUpgrade = useCallback(() => {
    if (productType === undefined) {
      return;
    }
    changeLicense({ productType });
  }, [changeLicense, productType]);

  return (
    <PaddleUpgradeViewRenderer
      isShown={productType !== undefined}
      isLoading={isLoading}
      onUpgrade={onUpgrade}
      isError={isError}
      isSuccess={isSuccess}
      onClose={onClose}
    />
  );
};

export const PaddleUpgradeView = TypedMemo(PaddleUpgradeViewComponent);
