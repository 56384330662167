import React, { useCallback, useMemo, useState } from 'react';
import { IframeProps } from './props';
import { LoadingView } from '../LoadingView';
import { TypedMemo } from '../../types';

const IframeComponent = React.forwardRef<HTMLIFrameElement, IframeProps>(
  ({ embedUrl, adjustSize = false, onLoad }, ref) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const startLoading = useCallback(() => setIsLoading(true), []);
    const finishLoading = useCallback(() => {
      setIsLoading(false);
      onLoad?.();
    }, [onLoad]);
    const width = useMemo(() => {
      if (adjustSize) {
        return isLoading ? 0 : '100%';
      }
      return isLoading ? 0 : 400;
    }, [adjustSize, isLoading]);

    return (
      <>
        <iframe
          title="embed Code"
          width={width}
          height={adjustSize ? '100%' : 500}
          src={embedUrl}
          frameBorder="0"
          allow="encrypted-media"
          // eslint-disable-next-line react/no-unknown-property
          onLoadStart={startLoading}
          onLoad={finishLoading}
          ref={ref}
        />
        {isLoading && <LoadingView />}
      </>
    );
  }
);

export const Iframe = TypedMemo(IframeComponent);
