import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { AlertProps, AlertState } from './state';
import { alertSliceName } from './constants';

const alertSlice = createSlice({
  name: alertSliceName,
  initialState: AlertState,
  reducers: {
    createAlert: (state, action: PayloadAction<AlertProps>) => {
      state.alerts.push({
        id: uuidv4(),
        message: action.payload.message,
        type: action.payload.type,
      });
    },
    removeAlert: (state, action: PayloadAction<AlertProps>) => {
      state.alerts = state.alerts.filter((_alert) => _alert.id !== action.payload.id);
    },
    resetAlertState: () => AlertState,
  },
});

const { actions, reducer } = alertSlice;
export { reducer as alertReducer };
export const { createAlert, resetAlertState, removeAlert } = actions;
