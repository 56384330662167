import { ClearingIterationCallback, GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { SoundCloudAPI } from './SoundCloudAPI';
import { SoundCloudAuthenticationData } from './SoundCloudAuthenticationData';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { SoundCloudLikedTracks } from './models/SoundCloudLikedTracks';
import { SearchQueryProperties } from '../../generics/types';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { ImporterID } from '../types';
import { soundcloud } from '../../musicServices/services/SoundCloud';
import { refreshAuthData } from '../../musicApi/utils/refreshAuthData';
import { MusicAPIIntegrationID } from '../../musicServices/types';
import { SoundCloudPlaylist } from './models/SoundCloudPlaylist';
import { CollectionAccess, CollectionType } from '../../generics/models/Collection';
import { FetchError } from '../../generics/errors/FetchError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { convertQueryPropsToString } from '../services/MatchingService.helpers';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { moveItemsByOffset } from '../../utils/moveItemsByOffset';
import { insertElementOnIndex } from '../../utils/insertElementOnIndex';

const createSoundCloudInstance = (authenticationData: SoundCloudAuthenticationData): SoundCloudAPI => {
  return new SoundCloudAPI({
    accessToken: authenticationData.additionalData.accessToken,
    userId: authenticationData.authId,
  });
};

export const SoundCloudImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.SoundCloud;

  public static musicService = soundcloud;

  public authenticationData: SoundCloudAuthenticationData;

  private soundCloudApi: SoundCloudAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as SoundCloudAuthenticationData;
    this.soundCloudApi = createSoundCloudInstance(this.authenticationData);
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.authenticationData = authenticationData as SoundCloudAuthenticationData;
    this.soundCloudApi = createSoundCloudInstance(this.authenticationData);
  }

  async getPaginatedCollections(onBatch: (collections: SoundCloudPlaylist[]) => Promise<void>): Promise<void> {
    const likedTracks = new SoundCloudLikedTracks(this.soundCloudApi.userId);
    await onBatch([likedTracks]);
    await this.soundCloudApi.getPaginatedUserPlaylists(onBatch);
    await this.soundCloudApi.getPaginatedLikedPlaylists(onBatch);
  }

  async getCollection(collection: GenericCollection): Promise<SoundCloudPlaylist> {
    if (collection.type === CollectionType.MY_SONGS) {
      return new SoundCloudLikedTracks(this.soundCloudApi.userId);
    }
    const result = await this.soundCloudApi.getPlaylist(collection.rawId);
    if (!result) {
      throw new CollectionDoesNotExistsError();
    }
    return result;
  }

  async createCollection(collection: GenericCollection, description?: string): Promise<SoundCloudPlaylist> {
    let newCollection: SoundCloudPlaylist | null = null;
    try {
      newCollection = await this.soundCloudApi.createPlaylist(collection.name, { description });
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!newCollection) {
      throw new CouldNotCreateCollection();
    }
    return newCollection;
  }

  async addItemToCollection(
    collection: GenericCollection,
    matchedItem: GenericMatchedItem,
    position: number | undefined,
    getExistingItems: () => GenericCollectionItem[]
  ) {
    let tracksIds = getExistingItems()
      .sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
      .map(({ rawId }) => ({ id: rawId }));
    const idToAdd = { id: matchedItem.rawId };
    if (position !== undefined) {
      tracksIds = insertElementOnIndex(tracksIds, position, idToAdd);
    } else {
      tracksIds.push(idToAdd);
    }
    await this.soundCloudApi.updatePlaylist(collection.rawId, { tracks: tracksIds });
    return convertMatchedItemToCollectionItem(matchedItem);
  }

  async addManyItemsToCollection(
    collection: GenericCollection,
    data: {
      matchedItem: GenericMatchedItem;
      position?: number;
    }[],
    getExistingItems: () => GenericCollectionItem[]
  ) {
    let tracksIds = getExistingItems()
      .sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
      .map(({ rawId }) => ({ id: rawId }));
    data
      .sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
      .forEach(({ matchedItem, position }) => {
        const idToAdd = { id: matchedItem.rawId };
        if (position !== undefined) {
          tracksIds = insertElementOnIndex(tracksIds, position, idToAdd);
        } else {
          tracksIds.push(idToAdd);
        }
      });
    await this.soundCloudApi.updatePlaylist(collection.rawId, { tracks: tracksIds });
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    collectionItems: GenericCollectionItem[],
    _callback: ClearingIterationCallback | undefined,
    getExistingItems: () => GenericCollectionItem[]
  ): Promise<void> {
    if (collection.type !== CollectionType.PLAYLIST || collectionItems.length === 0) {
      return;
    }
    const trackIdsToRemove = collectionItems.map((item) => item.rawId);
    const trackIdsToLeave = getExistingItems()
      .filter((track) => !trackIdsToRemove.includes(track.rawId))
      .map((track) => ({ id: track.rawId }));
    await this.soundCloudApi.updatePlaylist(collection.rawId, { tracks: trackIdsToLeave });
  }

  async clearCollection(collection: GenericCollection) {
    await this.soundCloudApi.updatePlaylist(collection.rawId, { tracks: [] });
  }

  async matchItems(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const { tracks } = await this.soundCloudApi.searchTracks(query);
    return tracks;
  }

  async updateCollection(
    collection: GenericCollection,
    props: { name?: string; access?: CollectionAccess; description?: string }
  ) {
    const { name, access, description } = props;
    const isPublic = access === undefined ? undefined : access === CollectionAccess.public;
    await this.soundCloudApi.updatePlaylist(collection.rawId, { title: name, isPublic, description });
  }

  async removeCollection(collection: GenericCollection): Promise<void> {
    await this.soundCloudApi.removePlaylist(collection.rawId);
  }

  async moveManyItems(props: Parameters<NonNullable<GenericImporter['moveManyItems']>>[0]) {
    const { collection, allItems, itemsToMove, offset } = props;
    const movedItems = moveItemsByOffset({ allItems, itemsToMove, offset });
    const tracks = movedItems.map(({ rawId }) => ({ id: rawId }));
    await this.soundCloudApi.updatePlaylist(collection.rawId, { tracks });
  }

  async reAuthenticate(
    withData: GenericAuthenticationData,
    forceFetchRefreshTokens?: boolean
  ): Promise<GenericAuthenticationData> {
    return refreshAuthData({
      authId: withData.authId,
      userUUID: withData.additionalData?.userUUID ?? null,
      integrationId: MusicAPIIntegrationID.SoundCloud,
      forceFetchRefreshTokens,
    });
  }

  doesSupportReAuth(): boolean {
    return true;
  }

  public doesSupportAlbums(): boolean {
    return false;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return true;
  }

  public doesSupportSearchByISRC(): boolean {
    return false;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return true;
  }

  public doesSupportMovingManyItems(): boolean {
    return true;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    switch (forCollection.type) {
      case CollectionType.PLAYLIST:
      case CollectionType.LIKED_PLAYLIST:
        return this.soundCloudApi.getPaginatedPlaylistTracks(forCollection.rawId, onBatch);
      case CollectionType.MY_SONGS:
        return this.soundCloudApi.getPaginatedLikedTracks(onBatch);
      default:
        return undefined;
    }
  }

  async getCollectionPublicUrl(collection: GenericCollection) {
    await this.soundCloudApi.updatePlaylist(collection.rawId, { isPublic: true });
    const playlist = await this.soundCloudApi.getPlaylist(collection.rawId);
    if (!playlist) {
      return null;
    }
    return playlist.publicUrl;
  }
};
