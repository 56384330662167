import React from 'react';
import classnames from 'classnames';
import { TypedMemo } from '../../common/types';
import { ClickableDiv } from '../../common/views/ClickableDiv';
import { PaymentPlanBoxProps } from './props';

const PaymentPlanBoxComponent: React.FC<PaymentPlanBoxProps> = ({
  title,
  price,
  description,
  highlightedText,
  highlightedTextSpace = false,
  onClick,
  isDisabled,
}) => {
  return (
    <ClickableDiv
      className={classnames('opacity-bg payment-plan-box', { 'payment-plan-box-disabled': isDisabled })}
      onClick={onClick}
      disabled={isDisabled}
    >
      {(highlightedTextSpace || highlightedText) && (
        <div
          className={classnames('payment-plan-box-highlighted-text-container ui-text', {
            'fym-gradient-background': highlightedText,
            'sml-gradient-background': highlightedText,
          })}
        >
          <span>{highlightedText || <>&nbsp;</>}</span>
        </div>
      )}
      <div className="payment-plan-box-text-content">
        <span className="payment-plan-box-title ui-text">{title}</span>
        <span className="payment-plan-box-price">{price}</span>
        <span className="payment-plan-box-description ui-text">{description}</span>
      </div>
    </ClickableDiv>
  );
};

export const PaymentPlanBox = TypedMemo(PaymentPlanBoxComponent);
