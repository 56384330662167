import React, { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { FieldsType } from './consts';
import { FormInput } from '../components/FormInput/FormInput';
import { resolver } from './validators';
import { extractChangedSocialLinks, generateIconForUrl } from './utils';
import { InputType } from '../types';
import { SMLOnboardingFormStep3Props } from './props';
import { SMLButton } from '../../common/views/SMLButton/SMLButton';

export function useSMLOnboardingFormStep3Component({
  onSkip,
  onSubmit: onSubmitFromProps,
  isLoading,
}: Pick<SMLOnboardingFormStep3Props, 'onSkip' | 'onSubmit' | 'isLoading'>) {
  const intl = useIntl();
  const defaultValues = useMemo(() => {
    return { socialLinks: [{ url: '' }] };
  }, []);

  const { control, handleSubmit, reset } = useForm<FieldsType>({ resolver, defaultValues });
  const { fields, append, remove } = useFieldArray({ name: 'socialLinks', control });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const onRemove = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  const addSocialLink = useCallback(() => {
    append({ url: '' });
  }, [append]);

  const onValid: SubmitHandler<FieldsType> = useCallback(
    (form) => {
      const data = extractChangedSocialLinks(form.socialLinks);
      onSubmitFromProps(data).catch(console.error);
    },
    [onSubmitFromProps]
  );

  const submit = useCallback(
    (event?: React.BaseSyntheticEvent) => {
      handleSubmit(onValid)(event).catch(console.error);
    },
    [handleSubmit, onValid]
  );

  return {
    submit,
    SocialLinksInputs: fields.map((field, index) => {
      const isFirstInput = index === 0;
      const icon = generateIconForUrl(field.url);

      return (
        <FormInput
          key={field.id}
          name={`socialLinks.${index}.url`}
          control={control}
          type={InputType.text}
          disabled={isLoading}
          icon={icon}
          index={index}
          label={
            isFirstInput
              ? intl.formatMessage({
                  id: 'app.forms.social_links.social_link_url.label',
                  defaultMessage: 'Social media',
                })
              : undefined
          }
          placeholder={intl.formatMessage(
            {
              id: 'app.forms.social_links.social_link_url.placeholder',
              defaultMessage: 'eg. {link}',
            },
            { link: 'https://twitter.com/freeyourmusic' }
          )}
          help={
            isFirstInput
              ? intl.formatMessage({
                  id: 'app.forms.social_links.social_link_url.help',
                  defaultMessage:
                    'All the platforms you use to promote your art. You’ll be able to add them all at once as a widget later.',
                })
              : undefined
          }
          onRemove={fields.length > 1 ? onRemove : undefined}
        />
      );
    }),
    AddSocialLinkButton: (
      <SMLButton color="primary" onClick={addSocialLink} disabled={isLoading} size="sm">
        {intl.formatMessage({
          id: 'app.forms.social_links.add_social_link',
          defaultMessage: 'Add more',
        })}
      </SMLButton>
    ),
    SkipButton: (
      <SMLButton color="secondary" onClick={onSkip} outline>
        {intl.formatMessage({
          id: 'app.sml.onboarding.skip',
          defaultMessage: 'Skip',
        })}
      </SMLButton>
    ),
    SubmitButton: (
      <SMLButton
        color="secondary"
        onClick={submit}
        disabled={isLoading}
        isLoading={isLoading}
        rightIcon={faArrowRight}
        submit
      >
        {intl.formatMessage({
          id: 'app.sml.onboarding.next',
          defaultMessage: 'Next',
        })}
      </SMLButton>
    ),
  };
}
