import { GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { createQobuzPlaylistUrl } from '../utils';

export class QobuzPlaylist extends GenericCollection {
  static fromData(data: any, userId: number | undefined): QobuzPlaylist | null {
    if (!data?.id || !data.name) {
      return null;
    }
    const isUserOwner = userId !== undefined && data.owner.id === userId;
    const playlistType = isUserOwner ? CollectionType.PLAYLIST : CollectionType.LIKED_PLAYLIST;
    return new QobuzPlaylist({
      type: playlistType,
      rawId: `${data.id}`,
      name: data.name,
      itemCount: data.tracks_count,
      followers: data.users_count,
      publicUrl: createQobuzPlaylistUrl(data.id),
    });
  }
}
