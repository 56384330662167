import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { FieldsName, FieldsType } from './consts';
import { resolver } from './validators';
import { FileLoader } from '../components/FileLoader/FileLoader';
import { SMLOnboardingFormStep2Props } from './props';
import { SMLButton } from '../../common/views/SMLButton/SMLButton';

export function useSMLOnboardingFormStep2Component({
  account,
  onSubmit,
  isLoading = false,
  onSkip,
}: Pick<SMLOnboardingFormStep2Props, 'account' | 'onSubmit' | 'isLoading' | 'onSkip'>) {
  const intl = useIntl();
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const { control, handleSubmit, resetField } = useForm<FieldsType>({
    resolver,
    defaultValues: {
      [FieldsName.avatar]: account?.avatarFile ?? undefined,
    },
  });

  const submit = useCallback(
    (event?: React.BaseSyntheticEvent) => {
      handleSubmit(onSubmit)(event).catch(console.error);
    },
    [handleSubmit, onSubmit]
  );

  const removeAvatar = useCallback(() => {
    resetField(FieldsName.avatar, { defaultValue: undefined });
  }, [resetField]);

  return {
    submit,
    AvatarInput: (
      <FileLoader
        name={FieldsName.avatar}
        accountId={account.id}
        control={control}
        removeImage={removeAvatar}
        disabled={isLoading}
        roundedCircle
        onLoadingStateChange={setIsFileLoading}
        label={intl.formatMessage({
          id: 'app.forms.account.avatar',
          defaultMessage: 'Avatar',
        })}
        help={intl.formatMessage({
          id: 'app.forms.account.help.avatar',
          defaultMessage:
            'Use your logo, your favourite avatar, or photo of yourself. It will be displayed on your Bio Link.',
        })}
      />
    ),
    SkipButton: (
      <SMLButton color="secondary" onClick={onSkip} outline>
        {intl.formatMessage({
          id: 'app.sml.onboarding.skip',
          defaultMessage: 'Skip',
        })}
      </SMLButton>
    ),
    SubmitButton: (
      <SMLButton
        color="secondary"
        onClick={submit}
        disabled={isLoading || isFileLoading}
        isLoading={isLoading}
        rightIcon={faArrowRight}
        submit
      >
        {intl.formatMessage({
          id: 'app.sml.onboarding.next',
          defaultMessage: 'Next',
        })}
      </SMLButton>
    ),
  };
}
