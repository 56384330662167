import { GenericCollectionItem } from '../../../generics/models/GenericCollectionItem';
import { buildCoverUrl, convertYandexTrackData, createYandexTrackUrl, getArtistsNamesAsString } from '../utils';

export class YandexCollectionTrack extends GenericCollectionItem {
  static fromData(data: any, url?: string): YandexCollectionTrack | null {
    const trackData = convertYandexTrackData(data, url);
    return trackData ? new YandexCollectionTrack({ ...trackData, globalId: trackData.rawId }) : null;
  }

  static fromAlbumData(data: any): YandexCollectionTrack | null {
    if (!data?.id || !data.title) {
      return null;
    }
    const album = data.albums[0];
    const id = `${data.id}`;
    return new YandexCollectionTrack({
      rawId: id,
      globalId: id,
      name: data.title,
      album: album.title,
      artist: getArtistsNamesAsString(data.artists),
      imageUrl: buildCoverUrl(album.coverUri),
      additionalData: { albumId: `${album.id}` },
      sourceURL: createYandexTrackUrl(album.id, data.id),
    });
  }
}
