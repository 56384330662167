import { GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { CollectionType } from '../../generics/models/Collection';
import { VKAPI } from './VKAPI';
import { VKAuthenticationData } from './VKAuthenticationData';
import { VKPlaylist } from './models/VKPlaylist';
import { FetchError } from '../../generics/errors/FetchError';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { SearchQueryProperties } from '../../generics/types';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { convertQueryPropsToString } from '../services/MatchingService.helpers';
import { ImporterID } from '../types';
import { vkMusic } from '../../musicServices/services/VKMusic';

const createVKInstance = (authenticationData: VKAuthenticationData): VKAPI => {
  return new VKAPI(authenticationData.additionalData.cookies, authenticationData.authId);
};

export const VKImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.VK;

  public static musicService = vkMusic;

  public authenticationData: VKAuthenticationData;

  private vkApi: VKAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as VKAuthenticationData;
    this.vkApi = createVKInstance(this.authenticationData);
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.authenticationData = authenticationData as VKAuthenticationData;
    this.vkApi = createVKInstance(this.authenticationData);
  }

  async getPaginatedCollections(onBatch: (collections: VKPlaylist[]) => Promise<void>): Promise<void> {
    await this.vkApi.loadPaginatedPlaylists(onBatch);
  }

  async getCollection(collection: GenericCollection): Promise<VKPlaylist> {
    const result = (await this.vkApi.loadPlaylistItemPage(collection)).playlist;

    if (!result) {
      throw new CollectionDoesNotExistsError();
    }
    return result;
  }

  async createCollection(collection: GenericCollection): Promise<VKPlaylist> {
    let newCollection: VKPlaylist | null = null;
    try {
      newCollection = await this.vkApi.createPlaylist(collection.name);
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!newCollection) {
      throw new CouldNotCreateCollection();
    }
    return newCollection;
  }

  async addItemToCollection(collection: GenericCollection, item: GenericMatchedItem) {
    if (!item.additionalData?.searchId) {
      throw new Error(`Missing searchId in collection[${collection.rawId}] for VKImporter`);
    }

    await this.vkApi.addTracksToPlaylist(collection, [{ id: item.rawId, searchId: item.additionalData.searchId }]);
    return convertMatchedItemToCollectionItem(item);
  }

  async addManyItemsToCollection(
    collection: GenericCollection,
    data: {
      matchedItem: GenericMatchedItem;
      position?: number;
    }[]
  ) {
    const tracksData = data.reduce<{ id: string; searchId: string }[]>((results, { matchedItem }) => {
      const searchId = matchedItem.additionalData?.searchId;
      if (!searchId) {
        throw new Error(`Missing searchId in collection[${collection.rawId}] for VKImporter`);
      }
      return [...results, { id: matchedItem.rawId, searchId }];
    }, []);
    await this.vkApi.addTracksToPlaylist(collection, tracksData);
  }

  async matchItems(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const { tracks } = await this.vkApi.search(query);
    return tracks;
  }

  async reAuthenticate(_withData: GenericAuthenticationData): Promise<GenericAuthenticationData> {
    // Theoretically we could load accessToken with cookies again, but the access token is only needed for fetchMe, nothing else
    // Everything else is cookie based, so no real way to refresh tokens
    return Promise.reject(new Error('Not supported'));
  }

  doesSupportReAuth() {
    return false;
  }

  public doesSupportAlbums(): boolean {
    return false;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return false;
  }

  public doesSupportSearchByISRC(): boolean {
    return false;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return false;
  }

  public doesSupportMovingManyItems(): boolean {
    return false;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    switch (forCollection.type) {
      case CollectionType.PLAYLIST:
      case CollectionType.LIKED_PLAYLIST:
        await this.vkApi.loadPaginatedPlaylistItems(forCollection, onBatch);
        return undefined;
      default:
        return undefined;
    }
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    itemsToRemove: GenericCollectionItem[]
  ): Promise<void> {
    if (collection.type !== CollectionType.PLAYLIST || itemsToRemove.length === 0) {
      return;
    }
    const itemsIdsToRemove = itemsToRemove.map((item) => item.rawId);
    const trackIdsToLeave: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    await this.vkApi.loadPaginatedPlaylistItems(collection, async (tracks) => {
      trackIdsToLeave.push(...tracks.map((t) => t.rawId).filter((itemId) => !itemsIdsToRemove.includes(itemId)));
    });
    await this.vkApi.setTracksInPlaylist(collection, trackIdsToLeave);
  }

  async clearCollection(collection: GenericCollection) {
    if (collection.type !== CollectionType.PLAYLIST) {
      return;
    }
    await this.vkApi.setTracksInPlaylist(collection, []);
  }
};
