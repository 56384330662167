import { defaultRetryOptions, fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { Artist } from './models/TidalResponseModels';
import { mergeCookiesString } from '../../utils/cookies';
import { artistsToString } from '../../utils/artistsConverter';
import { getMetaContentByProperty } from '../../utils/htmlUtils';
import { Response } from '../../utils/fetch-types';

export function buildCoverUrl(cover: string | undefined | null) {
  if (!cover) {
    return undefined;
  }
  return `https://resources.tidal.com/images/${cover.split('-').join('/')}/640x640.jpg`;
}

export function getArtistsNamesAsString(artists: Artist[] | undefined) {
  if (!artists || artists.length === 0) {
    return '';
  }

  const artistsNames = artists.reduce((result, artist) => {
    if (artist.name) {
      result.push(artist.name);
    }
    return result;
  }, [] as string[]);

  return artistsToString(artistsNames);
}

export async function fetchRetryForTidal(
  url: string,
  options: any = {},
  retryOptions: RetryOptions = defaultRetryOptions
): Promise<Response> {
  const response = await fetchRetry(url, options, retryOptions);
  if (response.status !== 403) {
    return response;
  }
  const newCookie = response.headers.get('set-cookie');
  if (!newCookie) {
    return response;
  }
  const oldCookie = options.headers?.cookie;
  const finalCookie = oldCookie ? mergeCookiesString(oldCookie, newCookie) : newCookie;
  const { headers } = options;
  const newHeaders = headers ? { ...headers, cookie: finalCookie } : { cookie: finalCookie };
  return fetchRetry(url, { ...options, headers: newHeaders }, retryOptions);
}

export function getImageUrlFromMetaElements(metaElements: HTMLCollectionOf<HTMLMetaElement>): string | undefined {
  return getMetaContentByProperty(metaElements, 'og:image');
}

const tidalPublicResourceUrl = 'https://tidal.com/browse';

export function createTidalAlbumUrl(albumId: string) {
  return `${tidalPublicResourceUrl}/album/${albumId}`;
}

export function createTidalPlaylistUrl(playlistId: string) {
  return `${tidalPublicResourceUrl}/playlist/${playlistId}`;
}

export function createTidalTrackUrl(trackId: string) {
  return `${tidalPublicResourceUrl}/track/${trackId}`;
}
