import { CollectionItemModelData, GenericCollectionItem } from '../../../generics/models/GenericCollectionItem';
import { validateISRC } from '../../../utils/validateISRC';
import { createPandoraImageUrl, createPandoraPublicUrl } from '../utils';

export class PandoraCollectionTrack extends GenericCollectionItem {
  public index?: number;

  public itemId?: number;

  constructor(data: CollectionItemModelData, index?: number, itemId?: number) {
    super(data);
    this.index = index;
    this.itemId = itemId;
  }

  static fromData(
    data: any,
    index?: number,
    itemId?: number,
    duration?: number,
    url?: string
  ): PandoraCollectionTrack | null {
    if (!data?.pandoraId || !data.name) {
      return null;
    }
    let durationInMillis: number | undefined;
    const durationInSec = duration ?? data.duration;
    if (data.durationMillis) {
      durationInMillis = data.durationMillis;
    } else if (durationInSec) {
      durationInMillis = durationInSec * 1000;
    }
    return new PandoraCollectionTrack(
      {
        rawId: data.pandoraId,
        globalId: data.pandoraId,
        name: data.name,
        album: data.albumName,
        artist: data.artistName,
        isrc: validateISRC(data?.isrc),
        imageUrl: createPandoraImageUrl(data.icon?.artUrl),
        duration: durationInMillis,
        sourceURL: url ?? createPandoraPublicUrl(data.shareableUrlPath),
      },
      index,
      itemId
    );
  }
}
