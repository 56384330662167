import { QobuzAlbum } from './QobuzAlbum';
import { QobuzCollectionTrack } from './QobuzCollectionTrack';

export class QobuzAlbumWithTracksResponse {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get album(): QobuzAlbum | null {
    return QobuzAlbum.fromData(this.data);
  }

  get tracks(): QobuzCollectionTrack[] {
    const { album } = this;
    if (!this.data?.tracks?.items || !album) {
      return [];
    }
    return this.data.tracks.items
      .map((item: any) => QobuzCollectionTrack.fromAlbumTrackData(item, album))
      .filter((track: QobuzCollectionTrack | null) => !!track);
  }

  get tracksTotal(): number {
    return this.data.tracks.total;
  }
}
