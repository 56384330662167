export enum InternalErrorCode {
  InvalidAccountSlug,
  InvalidAccountName,
  InvalidSmartLinkSlug,
  InvalidStatsSlug,
  AccountNameIsTaken,
  SocialLinkExists,
  MainSmartLinkCouldNotBeDeleted,
  AppUpdateRequired,
  SmartLinkSlugIsTaken,
  InvalidOperation,
  EmailTaken,
  NoUserForRapidApiSecret,
  InvalidReturnUrl,
  InvalidCredentials,
  IntegrationUserNotAuthenticated,
  NotSupportedMethod,
}
