import React, { useMemo } from 'react';
import { TypedMemo } from '../../types';
import { SpacerProps } from './props';

const SpacerComponent: React.FC<React.PropsWithChildren<SpacerProps>> = ({
  marginTop = 0,
  marginBottom = 0,
  marginLeft = 0,
  marginRight = 0,
  paddingTop = 0,
  paddingBottom = 0,
  paddingLeft = 0,
  paddingRight = 0,
  flex = false,
  justifyContent,
  flexDirection,
  flexWrap,
  alignItems,
  children,
}) => {
  const styles: React.CSSProperties = useMemo(
    () => ({
      marginTop: `${marginTop}px`,
      marginBottom: `${marginBottom}px`,
      marginLeft: `${marginLeft}px`,
      marginRight: `${marginRight}px`,
      paddingTop: `${paddingTop}px`,
      paddingBottom: `${paddingBottom}px`,
      paddingLeft: `${paddingLeft}px`,
      paddingRight: `${paddingRight}px`,
      display: flex ? 'flex' : 'block',
      justifyContent,
      flexDirection,
      alignItems,
      flexWrap,
    }),
    [
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
      marginTop,
      marginBottom,
      marginLeft,
      marginRight,
      flex,
      justifyContent,
      flexDirection,
      alignItems,
      flexWrap,
    ]
  );
  return <div style={styles}>{children}</div>;
};

export const Spacer = TypedMemo(SpacerComponent);
