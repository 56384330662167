import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { v4 as uuid4 } from 'uuid';
import { TypedMemo } from '../../common/types';
import { SMLPreviewRendererProps } from './props';
import { Iframe } from '../../common/views/iframe/Iframe';
import { ClickableDiv } from '../../common/views/ClickableDiv';
import { SMLButton } from '../../common/views/SMLButton/SMLButton';
import { PostMessagableWindow, SMLPreviewMessageData } from '../smartLinkDetailsView/messageListeners/types';
import { useSendSmartLinkUpdateToPreview } from '../smartLinkDetailsView/messageListeners/useSendSmartLinkUpdateToPreview';

const SMLPreviewRendererComponent: React.FC<SMLPreviewRendererProps> = ({
  type = 'column',
  editableSmartLink,
  openPreview,
  isPreviewSidebarOpen,
  togglePreviewSidebar,
  iframeUrl,
  onPreviewMessageEvent,
}) => {
  const intl = useIntl();

  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const iframeWindow = iframeRef.current?.contentWindow;

  const update = useSendSmartLinkUpdateToPreview(iframeWindow as PostMessagableWindow, editableSmartLink);

  const randomValue = useMemo(() => uuid4(), []);
  const iframeUrlRandomized = useMemo(() => {
    if (!iframeUrl) {
      return null;
    }
    const url = new URL(iframeUrl);
    url.searchParams.set('editRandValue', randomValue);
    return url.toString();
  }, [randomValue, iframeUrl]);
  const onMessage = useCallback(
    (event: MessageEvent<SMLPreviewMessageData>) => {
      if (onPreviewMessageEvent === undefined) {
        return;
      }
      onPreviewMessageEvent(event.data);
    },
    [onPreviewMessageEvent]
  );
  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  const Preview = (
    <div className="smart-link-preview-container">
      {openPreview !== undefined && (
        <div className="smart-link-preview-container-info">
          <SMLButton leftIcon={faExternalLink} onClick={openPreview} color="link" stretch>
            {intl.formatMessage({
              id: 'app.smart_links.preview.info',
              defaultMessage: 'Preview of your Smart Link',
            })}
          </SMLButton>
        </div>
      )}
      {iframeUrlRandomized && <Iframe embedUrl={iframeUrlRandomized} adjustSize ref={iframeRef} onLoad={update} />}
    </div>
  );

  if (type === 'button') {
    return (
      <div className={classnames('d-flex d-lg-none sml-preview-collapse-container', { hidden: !isPreviewSidebarOpen })}>
        <div className="sml-preview-collapse">{Preview}</div>
        <div className="sml-preview-button-container mb-4">
          <ClickableDiv className="sml-preview-button shadow" onClick={togglePreviewSidebar}>
            <FontAwesomeIcon icon={isPreviewSidebarOpen ? faArrowLeft : faArrowRight} />
            <span>
              {intl.formatMessage({
                id: 'app.buttons.preview',
                defaultMessage: 'Preview',
              })}
            </span>
          </ClickableDiv>
        </div>
      </div>
    );
  }

  return <>{Preview}</>;
};

export const SMLPreviewRenderer = TypedMemo(SMLPreviewRendererComponent);
