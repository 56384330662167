import { BaseError } from './BaseError';

export class CollectionDoesNotExistsError extends BaseError {
  constructor(message?: string) {
    super({ message });
    // see: typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, CollectionDoesNotExistsError.prototype);
    this.name = CollectionDoesNotExistsError.name; // stack traces display correctly now
  }
}
