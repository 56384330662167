import React, { useCallback } from 'react';
import { TypedMemo } from '../../../common/types';
import { TrackersListViewRenderer } from './TrackersListViewRenderer';
import { useGetTrackersQuery } from '../../../../redux/api/fymAPI';
import { useAccountId } from '../../../../redux/slices/appInfo/hooks';
import { ErrorView } from '../../../common/views/ErrorView/ErrorView';
import { LoadingView } from '../../../common/views/LoadingView';
import { useSMLRouter } from '../../../../routing/sml/hooks';
import { SMLRoutes } from '../../../../routing/sml/SMLRoutes';

const TrackersListViewComponent: React.FC = () => {
  const router = useSMLRouter();
  const accountId = useAccountId();
  const { data, isLoading, error, refetch } = useGetTrackersQuery(accountId ?? 0, { skip: accountId === null });

  const openTrackerForm = useCallback(() => {
    if (accountId === null) {
      return;
    }
    router.push(SMLRoutes.trackerEdit, { accountId });
  }, [accountId, router]);

  if (error) {
    return <ErrorView error={error} retry={refetch} />;
  }

  if (!data || isLoading || accountId === null) {
    return <LoadingView />;
  }

  return <TrackersListViewRenderer trackers={data.trackers} openTrackerForm={openTrackerForm} accountId={accountId} />;
};

export const TrackersListView = TypedMemo(TrackersListViewComponent);
