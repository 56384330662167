import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { SerializedSmartLinkVersionTheme } from 'fym-common/src/db/serializersTypes';
import { imageSchema } from 'fym-common/src/joi/schemas';
import { FieldsName } from './consts';
import { StoreType } from '../../../redux/store';
import { getValidateSmartLinkSlugFn } from '../utils/getValidateSmartLinkSlugFn';

export function createResolver(store: StoreType, accountId: number, smartLinkId?: number, isMainSmartLink?: boolean) {
  const validateSlug = getValidateSmartLinkSlugFn(store, accountId, smartLinkId);
  const schema = Joi.object({
    [FieldsName.slug]:
      isMainSmartLink === true
        ? Joi.string().optional().allow('')
        : Joi.string().label('Slug').required().external(validateSlug),
    [FieldsName.name]: Joi.string().label('Name').required(),
    [FieldsName.image]: imageSchema.label('Background').optional(),
    [FieldsName.theme]: Joi.string()
      .label('Theme')
      .valid(...Object.values(SerializedSmartLinkVersionTheme)),
  });
  return joiResolver(schema);
}
