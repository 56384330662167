import { GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionAccess, CollectionType } from '../../../generics/models/Collection';
import { DeezerPlaylistRaw } from '../rawTypes/DeezerPlaylistRaw';
import { convertDeezerId, createDeezerPlaylistUrl } from '../utils';

function convertPublicToAccess(_public: boolean | undefined) {
  switch (_public) {
    case true:
      return CollectionAccess.public;
    case false:
      return CollectionAccess.private;
    default:
      return undefined;
  }
}

export class DeezerPlaylist extends GenericCollection {
  static fromData(data: DeezerPlaylistRaw, userId: string): DeezerPlaylist | null {
    const rawId = convertDeezerId(data.id);
    if (!rawId || !data.title) {
      return null;
    }
    let type: CollectionType;
    const isUserOwner = data.creator?.id !== undefined && userId === `${data.creator.id}`;
    if (isUserOwner) {
      type = data.is_loved_track === true ? CollectionType.MY_SONGS : CollectionType.PLAYLIST;
    } else {
      type = CollectionType.LIKED_PLAYLIST;
    }
    return new DeezerPlaylist({
      type,
      rawId,
      name: data.title,
      itemCount: data.nb_tracks,
      followers: data.fans,
      publicUrl: data.link,
      additionalData: {
        description: data.description,
        imageUrl: data.picture_big,
      },
      access: convertPublicToAccess(data.public),
    });
  }

  static fromShareUrlData(data: any, userId: string | undefined): DeezerPlaylist | null {
    if (!data?.PLAYLIST_ID || !data.TITLE) {
      return null;
    }
    const isUserOwner = !!(data.PARENT_USER_ID && userId !== undefined && userId === data.PARENT_USER_ID);
    const playlistType = isUserOwner ? CollectionType.PLAYLIST : CollectionType.LIKED_PLAYLIST;
    return new DeezerPlaylist({
      type: playlistType,
      rawId: data.PLAYLIST_ID,
      name: data.TITLE,
      itemCount: data.NB_SONG,
      followers: data.NB_FAN,
      publicUrl: createDeezerPlaylistUrl(data.PLAYLIST_ID),
    });
  }
}
