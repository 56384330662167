import { SpotifyArtist } from './SpotifyArtist';
import { SpotifyTopArtistsResponseType } from './types';

export class SpotifyTopArtistsResponse {
  private readonly data;

  constructor(data: SpotifyTopArtistsResponseType | undefined) {
    this.data = data;
  }

  get artists(): SpotifyArtist[] {
    if (!this.data?.items) {
      return [];
    }
    return this.data.items
      .map((item) => SpotifyArtist.fromData(item))
      .filter((artist): artist is SpotifyArtist => !!artist);
  }

  get next() {
    return this.data?.next;
  }
}
