import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle';
import { UserAvatarProps } from './props';
import { TypedMemo } from '../../types';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder';

const UserAvatarComponent: React.FC<UserAvatarProps & { className?: string }> = ({
  size,
  url,
  danger = false,
  className,
  color,
  avatarStyle,
}) => {
  const avatarSizeStyle = {
    width: size,
    height: size,
  };
  return (
    <div
      className={classnames('user-avatar-border rounded-circle', className, {
        'user-avatar-border-danger': danger,
        'user-avatar-border-white': !danger,
      })}
    >
      {url ? (
        <ImageWithPlaceholder
          className="ui-text rounded-circle"
          style={avatarSizeStyle}
          src={url}
          alt=""
          placeholder="https://fymstatics.imgix.net/images/avatar-placeholder.png?auto=compress%2Cformat&fit=crop&ch=Save-Data&w=400&h=400"
        />
      ) : (
        <FontAwesomeIcon
          className="user-avatar-icon"
          icon={faUserCircle}
          style={{ ...avatarSizeStyle, ...avatarStyle }}
          color={color}
        />
      )}
    </div>
  );
};

export const UserAvatar = TypedMemo(UserAvatarComponent);
