import { SpotifyImporter } from './spotify/SpotifyImporter';
import { YoutubeImporter } from './youtube/YoutubeImporter';
import { GenericImporter, GenericImporterClass } from '../generics/GenericImporter';
import { AppleMusicImporter } from './appleMusic/AppleMusicImporter';
import { AmazonMusicImporter } from './amazonMusic/AmazonMusicImporter';
import { TidalImporter } from './tidal/TidalImporter';
import { PandoraImporter } from './pandora/PandoraImporter';
import { DeezerImporter } from './deezer/DeezerImporter';
import { SoundCloudImporter } from './soundCloud/SoundCloudImporter';
import { QobuzImporter } from './qobuz/QobuzImporter';
import { QQMusicImporter } from './QQMusic/QQMusicImporter';
import { YandexImporter } from './yandex/YandexImporter';
import { AnghamiImporter } from './anghami/AnghamiImporter';
import { YouTubeMusicImporter } from './youtubeMusic/YouTubeMusicImporter';
import { NapsterImporter } from './napster/NapsterImporter';
import { VKImporter } from './vk/VKImporter';
import { ZvukImporter } from './zvuk/ZvukImporter';
import { GaanaImporter } from './gaana/GaanaImporter';
import { JioSaavnImporter } from './jioSaavn/JioSaavnImporter';
import { RessoImporter } from './resso/RessoImporter';
import { BoomplayImporter } from './boomplay/BoomplayImporter';
import { ImporterID } from './types';
import { FileImporter } from './fileImporter/FileImporter';

const musicImporters: GenericImporterClass<GenericImporter>[] = [
  SpotifyImporter,
  YoutubeImporter,
  AppleMusicImporter,
  AmazonMusicImporter,
  TidalImporter,
  PandoraImporter,
  DeezerImporter,
  SoundCloudImporter,
  QobuzImporter,
  QQMusicImporter,
  YandexImporter,
  AnghamiImporter,
  YouTubeMusicImporter,
  NapsterImporter,
  VKImporter,
  ZvukImporter,
  GaanaImporter,
  JioSaavnImporter,
  RessoImporter,
  BoomplayImporter,
];

const backupImporters: GenericImporterClass<GenericImporter>[] = [FileImporter];

const backupImportersIds = backupImporters.map(({ id }) => id);

const musicImportersById = (() => {
  return musicImporters.reduce((result, importer) => {
    result[importer.id] = importer;
    return result;
  }, {} as Record<ImporterID, GenericImporterClass<GenericImporter>>);
})();

const allImporters = [...musicImporters, ...backupImporters];

const allImportersById = (() => {
  return allImporters.reduce((result, importer) => {
    result[importer.id] = importer;
    return result;
  }, {} as Record<ImporterID, GenericImporterClass<GenericImporter>>);
})();

export function getAllImporters() {
  return allImporters;
}

export function getBackupImportersIds() {
  return backupImportersIds;
}

export function getMusicImporters() {
  return musicImporters;
}

export function getMusicImporterById(importerId: ImporterID) {
  return musicImportersById[importerId];
}

export function getImporterById(importerId: ImporterID) {
  return allImportersById[importerId];
}
