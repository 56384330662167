import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { urlSchema } from 'fym-common/src/joi/schemas';
import { FieldsName } from './consts';

const schema = Joi.object({
  [FieldsName.socialLinks]: Joi.array().items(
    Joi.object({
      socialLinkId: Joi.number().integer().optional(),
      url: urlSchema.optional().allow(''),
    })
  ),
});

export const resolver = joiResolver(schema);
