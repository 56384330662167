import React from 'react';
import { Provider } from 'react-redux';
import { singletonPromise } from 'fym-common/src/utils/singletonPromise';
import { TypedMemo } from '../../common/types';
import { createReduxStore, StoreType } from '../../../redux/store';
import { useAsync } from '../../../utils/useAsync';
import { getDeviceId } from '../../../utils/getDeviceId';
import { LoadingView } from '../../common/views/LoadingView';
import { store } from '../../../store/Store';

async function _loadReduxStore(): Promise<StoreType> {
  const deviceId = await getDeviceId();
  const license = await store.get('license');
  const userToken = await store.get('userToken');
  return createReduxStore({ deviceId, license, userToken });
}

export const loadReduxStore = singletonPromise(_loadReduxStore);

const ReduxLoaderComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { value: reduxStore } = useAsync(loadReduxStore);

  if (reduxStore === undefined) {
    return <LoadingView />;
  }
  return <Provider store={reduxStore}>{children}</Provider>;
};
export const ReduxLoader = TypedMemo(ReduxLoaderComponent);
