export interface AppInformation {
  version: string;
  platform: PlatformUniversal;
  platformVersion: string;
  buildVersion?: string;
}

export enum VerticalPosition {
  'top',
  'center',
  'bottom',
}

export enum PlatformUniversal {
  // Electron
  macos = 'darwin',
  linux = 'linux',
  windows = 'win32',

  // ReactNative + Electron
  ios = 'ios',
  android = 'android',

  // ReactNative
  windowsNative = 'windows',
  macosNative = 'macos',
  webNative = 'web',
}
