import { Buffer } from 'buffer';
import { getPlatformBasedUtils } from './platformBasedUtils/PlatformBasedUtils';

type XMLHttpRequestType = any;

export type HTTPMethods = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT' | 'CONNECT' | 'OPTIONS' | 'TRACE' | 'HEAD';

export type FetchOptions = {
  json?: boolean;
  headers?: Record<string, any>;
  stringify?: boolean;
  body?: Record<string, any> | string;
  method?: HTTPMethods;
  XHR?: XMLHttpRequestType;
  responseType?: string;
};

type PatchHeaders = {
  headers: Record<string, any>;
  json?: boolean;
  xhr?: XMLHttpRequestType;
};

export type Response = {
  ok: boolean;
  url: string;
  headers: Record<string, any>;
  status: number;
  statusText: string;
  type: string;
  json(): Promise<Record<string, any>>;
  text(): Promise<string>;
  asBuffer(): Promise<Buffer>;
};

class XhrResponse implements Response {
  public url: Response['url'];

  public headers: Response['headers'];

  private xhr: XMLHttpRequestType;

  private isBodyUsed: boolean;

  constructor(url: Response['url'], headers: Response['headers'], xhr: XMLHttpRequestType) {
    this.url = url;
    this.headers = headers;
    this.xhr = xhr;
    this.isBodyUsed = false;
  }

  get status() {
    return this.xhr.status;
  }

  get statusText() {
    return this.xhr.statusText;
  }

  get type() {
    return this.xhr.responseType;
  }

  // eslint-disable-next-line no-underscore-dangle
  get _bodyText() {
    return this.xhr.responseText;
  }

  get ok() {
    return this.xhr.status >= 200 && this.xhr.status <= 300;
  }

  json(): Promise<Record<string, any>> {
    return new Promise((resolve) => {
      this.isBodyUsed = true;
      resolve(JSON.parse(this.xhr.responseText));
    });
  }

  text(): Promise<string> {
    return Promise.resolve(this.xhr.responseText);
  }

  asBuffer(): Promise<Buffer> {
    return Promise.resolve(Buffer.from(this.xhr.response));
  }

  get bodyUsed() {
    return this.isBodyUsed;
  }
}

function applyHeaders({ headers: customHeaders = {}, xhr }: PatchHeaders): Record<string, any> {
  const headers = { ...customHeaders };

  Object.entries(headers).forEach(([header, value]) => {
    xhr.setRequestHeader(header, value);
  });

  return headers;
}

function convertBody(body: FetchOptions['body'], method: FetchOptions['method']) {
  if (body === undefined || method === 'GET' || method === 'OPTIONS') {
    // XHR2 from node would ignore it but RN will throw cryptic Error so we better check it ourselves
    return undefined;
  }
  if (typeof body === 'string') {
    return body;
  }
  return JSON.stringify(body);
}

export async function xhrFetch(
  url: string,
  { json = true, headers = {}, body: rawBody, method = 'GET', XHR, responseType }: FetchOptions = {}
): Promise<Response> {
  return new Promise((resolve, reject) => {
    const XMLHttpRequest = getPlatformBasedUtils().xhr;
    const xhr: XMLHttpRequestType = XHR ?? new XMLHttpRequest();
    const body = convertBody(rawBody, method);
    xhr.open(method, url);

    const allHeaders = {
      ...applyHeaders({ headers, json, xhr }),
    };

    xhr.onload = (): void => {
      resolve(new XhrResponse(url, allHeaders, xhr));
    };

    xhr.onerror = (error: any): void => {
      console.error(error);
      reject(new Error(`Failed to fetch. \n { uri: ${url}, method: ${method} } \n`));
    };
    console.log(`Sending ${method} to "${url}" (${JSON.stringify(allHeaders)}) with body: ${body}`);
    xhr.responseType = responseType;
    xhr.send(body);
  });
}
