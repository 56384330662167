import { ImporterID } from '../../importers/types';

export class NotAuthenticatedError extends Error {
  public importerId?: ImporterID;

  public authId?: string;

  constructor({ importerId, authId }: { importerId: ImporterID; authId?: string }, message?: string) {
    super(message);
    this.importerId = importerId;
    this.authId = authId;
    // see: typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, NotAuthenticatedError.prototype);
    this.name = NotAuthenticatedError.name; // stack traces display correctly now
  }
}
