import { BoomplayAlbumsRawResponse } from './types';
import { BoomplayAlbum } from './BoomplayAlbum';

export class BoomplayAlbumsResponse {
  private readonly data;

  constructor(data: BoomplayAlbumsRawResponse | undefined) {
    this.data = data;
  }

  get albums(): BoomplayAlbum[] {
    if (!this.data?.data) {
      return [];
    }
    return this.data.data
      .map((item) => BoomplayAlbum.fromData(item))
      .filter((album): album is BoomplayAlbum => !!album);
  }

  get next() {
    return this.data?.next;
  }
}
