import React from 'react';
import { Alert } from 'reactstrap';
import { TypedMemo } from '../../types';
import { ErrorAlertRendererProps } from './props';

const ErrorAlertRendererComponent: React.FC<ErrorAlertRendererProps> = ({ message }) => {
  return <Alert color="danger text-break">{message}</Alert>;
};

export const ErrorAlertRenderer = TypedMemo(ErrorAlertRendererComponent);
