import { CollectionItemModelData, GenericCollectionItem } from '../../../generics/models/GenericCollectionItem';
import { convertNapsterTrackData } from '../utils';

export class NapsterCollectionTrack extends GenericCollectionItem {
  constructor(
    data: CollectionItemModelData,
    public albumId: string | null = null,
    public artistId: string | null = null
  ) {
    super(data);
  }

  static fromData(data: any): NapsterCollectionTrack | null {
    const trackData = convertNapsterTrackData(data);
    if (!data || !trackData) {
      return null;
    }
    return new NapsterCollectionTrack({ ...trackData, globalId: trackData.rawId }, data.albumId, data.artistId);
  }
}
