import { YouTubeMusicSearchResponse } from './YouTubeMusicSearchResponse';
import { YouTubeMusicMatchedTrack } from './YouTubeMusicMatchedTrack';

export class YouTubeMusicSearchResponseSong extends YouTubeMusicSearchResponse<YouTubeMusicMatchedTrack> {
  parseItem(data: any): YouTubeMusicMatchedTrack | null {
    if (this.filter === 'videos') {
      return YouTubeMusicMatchedTrack.fromSearchDataVideo(data);
    }
    return YouTubeMusicMatchedTrack.fromSearchDataSong(data);
  }
}
