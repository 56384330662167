import { BaseModel } from '../../../universal/BaseModel';

export class TidalUser extends BaseModel {
  get firstName(): string {
    return this.privateData.firstName;
  }

  get lastName(): string {
    return this.privateData.lastName;
  }

  get email(): string {
    return this.privateData.email;
  }

  get picture(): string | null {
    return this.privateData.picture;
  }

  get pictureUrl(): string | undefined {
    if (!this.picture) {
      return undefined;
    }
    return `https://resources.tidal.com/images/${this.picture.split('-').join('/')}/210x210.jpg`;
  }
}
