import { QQMusicSearchResponseData } from '../types';
import { QQMusicMatchedTrack } from './QQMusicMatchedTrack';

export class QQMusicSearchResponse {
  private readonly data;

  constructor(data: QQMusicSearchResponseData | null) {
    this.data = data;
  }

  get tracks(): QQMusicMatchedTrack[] {
    if (!this.data?.song?.list) {
      return [];
    }
    return this.data?.song.list
      .map((item) => QQMusicMatchedTrack.fromData(item))
      .filter((track): track is QQMusicMatchedTrack => !!track);
  }

  get firstTrack(): QQMusicMatchedTrack | undefined {
    if (this.tracks.length === 0) {
      return undefined;
    }
    return this.tracks[0];
  }
}
