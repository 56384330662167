import React, { useCallback } from 'react';
import { useController } from 'react-hook-form';
import { TypedMemo } from '../../../common/types';
import { FormInputContainer } from '../FormInputContainer/FormInputContainer';
import { FormInputRenderer } from './FormInputRenderer';
import { FormInputProps } from './props';

const FormInputComponent: React.FC<FormInputProps> = ({
  name,
  control,
  label,
  type,
  placeholder,
  disabled,
  onRemove,
  help,
  inputTextSuffix,
  inputTextPrefix,
  inputSuffix,
  icon,
  index,
  readonly,
  returnKeyType,
  onEndEditing,
  onSubmitEditing,
  blurOnSubmit,
  autoComplete,
}) => {
  const { field, fieldState } = useController({ name, control });
  const { value, onChange, ref } = field;
  const { error, invalid, isDirty, isTouched } = fieldState;

  const handleRemove = useCallback(() => {
    if (index === undefined || !onRemove) {
      return;
    }
    onRemove(index);
  }, [onRemove, index]);

  return (
    <FormInputContainer
      label={label}
      help={help}
      name={name}
      error={error?.message}
      isDirty={isDirty}
      isTouched={isTouched}
    >
      <FormInputRenderer
        name={name}
        onChangeText={onChange}
        value={value}
        autoComplete={autoComplete}
        invalid={invalid}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        onRemove={onRemove ? handleRemove : undefined}
        inputTextSuffix={inputTextSuffix}
        inputTextPrefix={inputTextPrefix}
        inputSuffix={inputSuffix}
        icon={icon}
        readonly={readonly}
        returnKeyType={returnKeyType}
        onEndEditing={onEndEditing}
        onSubmitEditing={onSubmitEditing}
        ref={ref}
        blurOnSubmit={blurOnSubmit}
      />
    </FormInputContainer>
  );
};

export const FormInput = TypedMemo(FormInputComponent);
