import React from 'react';
import { TypedMemo } from '../../common/types';
import { useSupportEmail } from '../hooks';
import { SupportEmailProps } from './props';
import { SupportEmailRenderer } from './SupportEmailRenderer';

const SupportEmailComponent: React.FC<SupportEmailProps> = ({ color, licenceKey, email, deviceId }) => {
  const openSupportEmail = useSupportEmail(licenceKey, email, deviceId);
  return <SupportEmailRenderer email="support@freeyourmusic.com" color={color} onClick={openSupportEmail} />;
};
export const SupportEmail = TypedMemo(SupportEmailComponent);
