import { BaseError } from './BaseError';

export class FYMAPIBlockedFYMOperationalError extends BaseError {
  constructor(message?: string) {
    super({ message, isOperational: true });
    // see: http://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, FYMAPIBlockedFYMOperationalError.prototype);
    this.name = FYMAPIBlockedFYMOperationalError.name; // stack traces display correctly now
  }
}
