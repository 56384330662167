import Joi from 'joi';
import { SmartLinkWidgetType } from '../db/enums';
import { SerializedPublicAccountTrackerType } from '../db/serializersTypes';

export const urlSchema = Joi.string().label('URL');

export const imageSchema = Joi.object({
  id: Joi.number().integer(),
  url: Joi.string(),
  uuid: Joi.string(),
});

export const widgetTypeSchema = Joi.string()
  .label('Type')
  .valid(...Object.values(SmartLinkWidgetType));

export const emailSchema = Joi.string().email({ tlds: { allow: false } });

export const passwordMinimumLength = 8;
export const passwordSchema = Joi.string().min(passwordMinimumLength);

export const accountTrackerTypeSchema = Joi.string()
  .label('Type')
  .valid(...Object.values(SerializedPublicAccountTrackerType));
