import React from 'react';
import { useIntl } from 'react-intl';
import { DeletionModalProps } from './props';
import { GenericConfirmationModal } from '../genericConfirmationModal/GenericConfirmationModal';
import { TypedMemo } from '../../../types';

const DeletionModalComponent: React.FC<DeletionModalProps> = ({
  isVisible,
  description = '',
  closeModal,
  onDelete,
}) => {
  const intl = useIntl();

  return (
    <GenericConfirmationModal
      isOpen={isVisible}
      title={intl.formatMessage({
        id: 'app.modals.delete_shared_collection.sure',
        defaultMessage: 'Are you sure?',
      })}
      description={description}
      closeModal={closeModal}
      actions={[
        {
          key: 'delete',
          title: intl.formatMessage({
            id: 'app.modals.delete_shared_collection.delete',
            defaultMessage: 'DELETE',
          }),
          onClick: onDelete,
          isPrimary: true,
        },
      ]}
    />
  );
};

export const DeletionModal = TypedMemo(DeletionModalComponent);
