import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { buildArtworkUrl, getArtistsNames } from '../utils';
import { AppleMusicAlbumRawResponse } from './types';

export class AppleMusicAlbum extends GenericCollection {
  constructor(data: Omit<CollectionModelData, 'type'>) {
    super({ type: CollectionType.ALBUM, ...data });
  }

  static fromData(data: AppleMusicAlbumRawResponse | undefined, url?: string): AppleMusicAlbum | null {
    if (!data?.id || !data.attributes?.name) {
      return null;
    }
    return new AppleMusicAlbum({
      rawId: data.id,
      name: data.attributes.name,
      itemCount: data.attributes.trackCount,
      additionalData: {
        imageUrl: buildArtworkUrl(data.attributes.artwork),
        artist: data.attributes.artistName,
      },
      publicUrl: url ?? data.attributes.url,
    });
  }

  static fromShareUrlData(data: any, url: string): AppleMusicAlbum | null {
    const rawId = (data?.url ?? url)?.split('/').pop();
    const name = data?.name;
    if (!rawId || !name) {
      return null;
    }
    return new AppleMusicAlbum({
      rawId,
      name,
      itemCount: data.tracks?.length,
      additionalData: {
        imageUrl: data.image,
        artist: getArtistsNames(data.byArtist),
      },
      publicUrl: data.url ?? url,
    });
  }
}
