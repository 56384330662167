import { SerializedFile, SerializedSmartLinkVersionTheme } from 'fym-common/src/db/serializersTypes';

export enum FieldsName {
  slug = 'slug',
  name = 'name',
  image = 'image',
  theme = 'theme',
}

export type FieldsType = {
  [FieldsName.slug]: string;
  [FieldsName.name]: string;
  [FieldsName.image]?: SerializedFile;
  [FieldsName.theme]: SerializedSmartLinkVersionTheme;
};
