import { GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionAccess, CollectionType } from '../../../generics/models/Collection';
import { createAmazonPlaylistUrl, getBiggestImageUrlFromImages } from '../utils';
import { AmazonPlaylistVisibility, Playlist } from './types';

function convertVisibilityToAccess(visibility: AmazonPlaylistVisibility | undefined) {
  switch (visibility) {
    case AmazonPlaylistVisibility.public:
      return CollectionAccess.public;
    case AmazonPlaylistVisibility.private:
      return CollectionAccess.private;
    case undefined:
    default:
      return undefined;
  }
}

export class AmazonMusicPlaylist extends GenericCollection {
  public static fromData(
    data: Playlist | undefined,
    userId: string | undefined,
    collectionType?: CollectionType
  ): AmazonMusicPlaylist | null {
    const { id, title, trackCount, images, url, curator, visibility, description } = data ?? {};
    if (!id || !title) {
      return null;
    }

    let type: CollectionType;
    if (collectionType !== undefined) {
      type = collectionType;
    } else {
      type = userId && curator?.id && userId === curator?.id ? CollectionType.PLAYLIST : CollectionType.LIKED_PLAYLIST;
    }

    return new AmazonMusicPlaylist({
      type,
      rawId: id,
      name: title,
      itemCount: trackCount,
      additionalData: {
        imageUrl: getBiggestImageUrlFromImages(images),
        description: description ?? undefined,
      },
      publicUrl: url ?? createAmazonPlaylistUrl(id),
      access: convertVisibilityToAccess(visibility),
    });
  }
}
