import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { buildCoverUrl, createZvukAlbumUrl, getArtistsNamesAsString } from '../utils';

export class ZvukAlbum extends GenericCollection {
  constructor(data: Omit<CollectionModelData, 'type'>) {
    super({ type: CollectionType.ALBUM, ...data });
  }

  static fromData(data: any): ZvukAlbum | null {
    if (!data?.id || !data.title) {
      return null;
    }
    return new ZvukAlbum({
      rawId: `${data.id}`,
      name: data.title,
      itemCount: data.track_ids.length,
      additionalData: { imageUrl: buildCoverUrl(data.image), artist: getArtistsNamesAsString(data.artist_names) },
      publicUrl: createZvukAlbumUrl(data.id),
    });
  }
}
