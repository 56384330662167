import { MusicService } from './types';
import { ImporterID } from '../../importers/types';
import { MusicAPIIntegrationID } from '../types';
import { LinkType } from '../../utils/recognizeLinkTypeByUrl';

export const youtubeMusic: MusicService = {
  id: 'youtube-music',
  importerID: ImporterID.YouTubeMusic,
  linkType: LinkType.youtubemusic,
  musicAPIID: MusicAPIIntegrationID.YoutubeMusic,
  shortName: 'YouTube Music',
  name: 'YouTube Music',
  isComingSoon: false,
  areStatsSupported: true,
  areStatsComingSoon: false,
  isSupported: true,
  color: { label: '#FF0000', background: '#F8555588' },
  tracksPerPlaylistLimit: 5000,
};
