import { ReactComponent as JioSaavnLight } from '../../../../assets/images/music-services/jiosaavn-light.svg';
import { ReactComponent as JioSaavnDark } from '../../../../assets/images/music-services/jiosaavn-dark.svg';
import { ReactComponent as JioSaavnLightPadding } from '../../../../assets/images/music-services/jiosaavn-light-padding.svg';
import { ReactComponent as JioSaavnDarkPadding } from '../../../../assets/images/music-services/jiosaavn-dark-padding.svg';
import { ReactComponent as JioSaavnColorLight } from '../../../../assets/images/music-services/jiosaavn-color-light.svg';
import { ReactComponent as JioSaavnColorDark } from '../../../../assets/images/music-services/jiosaavn-color-dark.svg';
import { ReactComponent as JioSaavnColorLightPadding } from '../../../../assets/images/music-services/jiosaavn-color-light-padding.svg';
import { ReactComponent as JioSaavnColorDarkPadding } from '../../../../assets/images/music-services/jiosaavn-color-dark-padding.svg';
import { ReactComponent as JioSaavnDarkDanger } from '../../../../assets/images/music-services-danger/jiosaavn-dark-danger.svg';
import { ReactComponent as JioSaavnLightDanger } from '../../../../assets/images/music-services-danger/jiosaavn-light-danger.svg';
import { ReactComponent as JioSaavnLightDangerPadding } from '../../../../assets/images/music-services-danger/jiosaavn-light-padding-danger.svg';
import { ReactComponent as JioSaavnDarkDangerPadding } from '../../../../assets/images/music-services-danger/jiosaavn-dark-padding-danger.svg';
import { ReactComponent as JioSaavnDarkWarning } from '../../../../assets/images/music-services-warning/jiosaavn-dark-warning.svg';
import { ReactComponent as JioSaavnLightWarning } from '../../../../assets/images/music-services-warning/jiosaavn-light-warning.svg';
import { ReactComponent as JioSaavnLightWarningPadding } from '../../../../assets/images/music-services-warning/jiosaavn-light-padding-warning.svg';
import { ReactComponent as JioSaavnDarkWarningPadding } from '../../../../assets/images/music-services-warning/jiosaavn-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const jioSaavnImages: ImporterImages = {
  logo: {
    light: JioSaavnLight,
    dark: JioSaavnDark,
  },
  logoPadding: {
    light: JioSaavnLightPadding,
    dark: JioSaavnDarkPadding,
  },
  logoColor: {
    light: JioSaavnColorLight,
    dark: JioSaavnColorDark,
  },
  logoColorPadding: {
    light: JioSaavnColorLightPadding,
    dark: JioSaavnColorDarkPadding,
  },
  logoOnBackground: JioSaavnDark,
  logoDanger: {
    dark: JioSaavnDarkDanger,
    light: JioSaavnLightDanger,
  },
  logoDangerPadding: {
    dark: JioSaavnDarkDangerPadding,
    light: JioSaavnLightDangerPadding,
  },
  logoWarning: {
    dark: JioSaavnDarkWarning,
    light: JioSaavnLightWarning,
  },
  logoWarningPadding: {
    light: JioSaavnLightWarningPadding,
    dark: JioSaavnDarkWarningPadding,
  },
};
