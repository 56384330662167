import 'fym-common/src/utils/windowGlobal';
import React from 'react';
import { enableMapSet } from 'immer';
// Needs to be on top of this file
import './IntlInit';
import { RawIntlProvider } from 'react-intl';
import { TypedMemo } from '../../common/types';
import { AppInitialEntry } from './AppInitialEntry';
import { intl } from './intl';
import { ReduxLoader } from './ReduxLoader';
import { AppRender } from './AppRender';
import { GlobalErrorBoundaryHandler } from '../../errorBoundary/GlobalErrorBoundaryHandler';
import { UniversalAnalytics } from '../../analytics/universal';
import { IsOnlineProvider } from '../../../routing/offlineNotification/views/IsOnlineProvider';

enableMapSet();
UniversalAnalytics.setup().catch(console.error);

const AppSMLComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <RawIntlProvider value={intl}>
      <AppInitialEntry>
        <GlobalErrorBoundaryHandler>
          <ReduxLoader>
            <IsOnlineProvider />
            <AppRender>{children}</AppRender>
          </ReduxLoader>
        </GlobalErrorBoundaryHandler>
      </AppInitialEntry>
    </RawIntlProvider>
  );
};
export const AppSML = TypedMemo(AppSMLComponent);
