import { GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionAccess, CollectionType } from '../../../generics/models/Collection';
import { YouTubePrivacyStatus } from '../types';
import { getYouTubeImageUrl } from '../utils';

function convertVisibilityToAccess(privacyStatus: YouTubePrivacyStatus | undefined) {
  switch (privacyStatus) {
    case 'public':
      return CollectionAccess.public;
    case 'private':
      return CollectionAccess.private;
    case 'unlisted':
    case undefined:
    default:
      return undefined;
  }
}

export class YouTubePlaylist extends GenericCollection {
  static fromData(data: any): YouTubePlaylist | null {
    if (!data?.id || !data.snippet?.title) {
      return null;
    }
    return new YouTubePlaylist({
      type: CollectionType.PLAYLIST,
      rawId: data.id,
      name: data.snippet.title,
      itemCount: data.contentDetails.itemCount,
      access: convertVisibilityToAccess(data.status?.privacyStatus),
      additionalData: { description: data.snippet.description, imageUrl: getYouTubeImageUrl(data.snippet.thumbnails) },
    });
  }
}
