import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CollectionsPickersState } from './state';
import { collectionsPickersSliceName } from './constants';
import { CollectionPickerTabType } from '../../../modules/collectionsPicker/views/tabs/props';

type SetCollectionTabTypePayload = { collectionTabType: CollectionPickerTabType | null };
type SetConnectionBeingEditedPayload = {
  connection: {
    tempTransferModelIdString: string;
    sourceCollectionIdString: string;
  } | null;
};

const collectionsPickersSlice = createSlice({
  name: collectionsPickersSliceName,
  initialState: CollectionsPickersState,
  reducers: {
    resetCollectionsPickersState: () => CollectionsPickersState,
    setCollectionTabType: (state, action: PayloadAction<SetCollectionTabTypePayload>) => {
      const { collectionTabType } = action.payload;
      state.selectedCollectionTabType = collectionTabType;
    },
    setConnectionBeingEdited: (state, action: PayloadAction<SetConnectionBeingEditedPayload>) => {
      state.connectionBeingEdited = action.payload.connection;
    },
  },
});

const { actions, reducer } = collectionsPickersSlice;
export const { resetCollectionsPickersState, setCollectionTabType, setConnectionBeingEdited } = actions;
export { reducer as collectionsPickersReducer };
