import concat from 'lodash/concat';
import { BaseModel } from '../../../universal/BaseModel';
import { nav, YouTubeMusicParser } from '../youTubeMusicParser';

export class YouTubeMusicUser extends BaseModel {
  get picture(): string {
    return nav(this.privateData, concat(YouTubeMusicParser.ACTIVE_ACCOUNT, YouTubeMusicParser.ACTIVE_ACCOUNT_PHOTO));
  }

  get name(): string {
    return nav(this.privateData, concat(YouTubeMusicParser.ACTIVE_ACCOUNT, YouTubeMusicParser.ACTIVE_ACCOUNT_NAME));
  }

  get channelId(): string {
    const { sections } = nav(this.privateData, YouTubeMusicParser.ACTIVE_ACCOUNT);
    let accountBox: any;
    for (const section of sections) {
      if (!section.multiPageMenuSectionRenderer?.items) {
        continue;
      }
      for (const item of section.multiPageMenuSectionRenderer.items) {
        if (item.compactLinkRenderer?.icon?.iconType === 'ACCOUNT_BOX') {
          accountBox = item.compactLinkRenderer;
          break;
        }
      }
    }
    if (!accountBox) {
      throw new Error('Could not find channel id from YouTubeMusic');
    }
    return nav(accountBox, YouTubeMusicParser.ACTIVE_ACCOUNT_CHANNEL_ID);
  }
}
