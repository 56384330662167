import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { tryParseInt } from 'fym-common/src/utils/tryParseInt';
import { TypedMemo } from '../../common/types';
import { useDuplicateSmartLinkMutation, useGetAccountQuery, useGetSmartLinkQuery } from '../../../redux/api/fymAPI';
import { useQueryParam } from '../../../utils/useQueryParam';
import { isSuccessfulResult } from '../../../redux/api/utils/isSuccessfulResult';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { SmartLinkForm, SmartLinkFormProps } from '../../forms/SmartLinkForm/SmartLinkForm';

interface SocialLinksEditionViewProps {
  route?: { params: { accountId: number; smartLinkId: number } };
}

const SMLDuplicationViewComponent: React.FC<SocialLinksEditionViewProps> = ({ route }) => {
  const intl = useIntl();
  const router = useSMLRouter();
  const accountIdString = useQueryParam('accountId', route);
  const accountId = tryParseInt(accountIdString);
  const smartLinkIdString = useQueryParam('smartLinkId', route);
  const smartLinkId = tryParseInt(smartLinkIdString);

  if (accountId === undefined || smartLinkId === undefined) {
    throw new Error('Missing accountId or smartLinkId in SMLDuplicationView');
  }

  const [duplicateSmartLink, { isLoading: isDuplicateSmartLinkLoading, error: duplicateSmartLinkError }] =
    useDuplicateSmartLinkMutation();

  const { account, isAccountLoading, accountError } = useGetAccountQuery(
    { accountId },
    {
      selectFromResult: ({ data, isLoading, error }) => ({
        account: data?.account,
        isAccountLoading: isLoading,
        accountError: error,
      }),
    }
  );

  const { smartLink, isGetSmartLinkLoading, getSmartLinkError } = useGetSmartLinkQuery(
    { accountId, smartLinkId },
    {
      selectFromResult: ({ data, isLoading, error }) => ({
        smartLink: data?.smartLink,
        isGetSmartLinkLoading: isLoading,
        getSmartLinkError: error,
      }),
    }
  );

  const onSubmit: SmartLinkFormProps['onSubmit'] = useCallback(
    async ({ slug, name, theme, image }) => {
      const duplicateSmartLinkResult = await duplicateSmartLink({
        accountId,
        smartLinkId,
        slug,
        name,
        theme,
        backgroundFileId: image?.id,
      });
      if (isSuccessfulResult(duplicateSmartLinkResult)) {
        router.push(SMLRoutes.home);
        router.push(SMLRoutes.manage, {
          accountId,
          smartLinkId: duplicateSmartLinkResult.data.smartLink.id,
        });
      }
    },
    [duplicateSmartLink, router, smartLinkId, accountId]
  );

  return (
    <SmartLinkForm
      title={intl.formatMessage({
        id: 'app.forms.smartLinkDuplication.title',
        defaultMessage: 'Smart Link duplication',
      })}
      onSubmit={onSubmit}
      accountId={accountId}
      smartLink={smartLink}
      accountShareUrl={account?.shareUrl ?? null}
      isLoading={isDuplicateSmartLinkLoading || isGetSmartLinkLoading || isAccountLoading}
      error={duplicateSmartLinkError ?? getSmartLinkError ?? accountError}
      isDuplicationForm
    />
  );
};

export const SMLDuplicationView = TypedMemo(SMLDuplicationViewComponent);
