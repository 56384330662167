import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { RessoAlbumRawResponse } from './RessoResponseModels';
import { RessoArtist } from './RessoArtist';
import { getRessoArtistsNamesAsString } from '../utils/getRessoArtistsNamesAsString';

export class RessoAlbum extends GenericCollection {
  constructor(data: Omit<CollectionModelData, 'type'>, public artists: RessoArtist[] | null = null) {
    super({ type: CollectionType.ALBUM, ...data });
  }

  static fromData(data: RessoAlbumRawResponse | undefined): RessoAlbum | null {
    if (!data?.id || !data.name) {
      return null;
    }
    return new RessoAlbum(
      {
        rawId: data.id,
        name: data.name,
        itemCount: data.total_tracks,
        additionalData: { imageUrl: data.cover?.url, artist: getRessoArtistsNamesAsString(data.artists) },
      },
      data.artists?.map((artist) => RessoArtist.fromData(artist)).filter((a): a is NonNullable<typeof a> => a !== null)
    );
  }
}
