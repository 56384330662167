import { tryParseInt } from './tryParseInt';

export function getDelayFromRetryAfterHeader(retryAfter: string | null) {
  if (!retryAfter) {
    return undefined;
  }
  const additionalTime = 1000; // We add one second because usually after waiting 'retry-after' time, we get retry-after once again with one sec.
  const waitTimeIsSeconds = tryParseInt(retryAfter);
  if (waitTimeIsSeconds !== undefined) {
    return waitTimeIsSeconds * 1000 + additionalTime;
  }
  const limitEnd = new Date(retryAfter).getTime();
  const isInvalidDate = Number.isNaN(limitEnd);
  if (isInvalidDate) {
    return undefined;
  }
  const now = new Date().getTime();
  return limitEnd - now + additionalTime;
}
