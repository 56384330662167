import { Thumbnail } from './types';

export function getBiggestImageUrlFromThumbnails(thumbnails: Thumbnail[] | undefined) {
  if (!thumbnails) {
    return undefined;
  }
  const distSortedImages = thumbnails.sort((a, b) => b.width - a.width);
  const firstThumbnail = distSortedImages[0];
  return firstThumbnail?.url;
}

const youTubeMusicPublicResourceUrl = 'https://music.youtube.com';

export function createYouTubeMusicCollectionUrl(collectionId: string | undefined) {
  if (!collectionId) {
    return undefined;
  }
  return `${youTubeMusicPublicResourceUrl}/playlist?list=${collectionId}`;
}

export function createYouTubeMusicTrackUrl(trackId: string | undefined) {
  if (!trackId) {
    return undefined;
  }
  return `${youTubeMusicPublicResourceUrl}/watch?v=${trackId}`;
}
