import { ImporterID } from 'fym-common/src/importers/types';

export interface SearchAndFilterViewRendererProps {
  handleSearch: (value: string) => void;
  handleSortOptionChange: (value: SortOptions) => void;
  handleFilterOptionChange: (value: FilterOptions) => void;
  toggleManageMode: () => void;
  isManageMode: boolean;
  showManageMode?: boolean;
  searchQuery: string;
}

export enum SortOptions {
  TitleAz = 'Title A-Z',
  TitleZa = 'Title Z-A',
  NewestFirst = 'Newest First',
  OldestFirst = 'Oldest First',
}

export enum FilterOptionsEnum {
  All = 'All',
  RequiresAttention = 'Requires Attention',
  InProgress = 'In Progress',
  Paused = 'Paused',
  ByImporterID = 'Filter by ImporterID',
}

export type FilterOptions = {
  option: FilterOptionsEnum;
  importerId?: ImporterID;
};
