import { Artist } from './models/YandexResponseModels';
import { artistsToString } from '../../utils/artistsConverter';

export function buildCoverUrl(coverUri: string) {
  return `https://${coverUri.replace('%%', '200x200')}`;
}

export function getArtistsNamesAsString(artists: Artist[] | undefined) {
  if (!artists || artists.length === 0) {
    return '';
  }

  const artistsNames = artists.reduce((result, artist) => {
    if (artist.name) {
      result.push(artist.name);
    }
    return result;
  }, [] as string[]);

  return artistsToString(artistsNames);
}
const yandexPublicResourceUrl = 'https://music.yandex.ru';

export function createYandexAlbumUrl(albumId: string) {
  return `${yandexPublicResourceUrl}/album/${albumId}`;
}

export function createYandexPlaylistUrl(userLogin: string | undefined, kind: string | undefined) {
  if (!userLogin || !kind) {
    return undefined;
  }
  return `${yandexPublicResourceUrl}/users/${userLogin}/playlists/448708`;
}

export function createYandexTrackUrl(albumId: string | undefined, trackId: string) {
  if (!albumId) {
    return undefined;
  }
  return `${yandexPublicResourceUrl}/album/${albumId}/track/${trackId}`;
}

export function convertYandexTrackData(data: any, url?: string) {
  if (!data?.id || !data.title) {
    return null;
  }
  const album = data.albums && data.albums.length > 0 && data.albums[0];
  const imageUrl = album?.coverUri && buildCoverUrl(album.coverUri);
  return {
    rawId: `${data.id}`,
    name: data.title,
    album: album?.title,
    artist: getArtistsNamesAsString(data.artists),
    imageUrl,
    duration: data.durationMs,
    additionalData: { albumId: `${album.id}` },
    sourceURL: url ?? createYandexTrackUrl(album.id, data.id),
  };
}
