import { DeezerUserRaw } from '../rawTypes/DeezerUserRaw';

export class DeezerUser {
  private data: DeezerUserRaw & Required<Pick<DeezerUserRaw, 'id'>>;

  constructor(data: DeezerUserRaw) {
    const { id } = data;
    if (id === undefined || id === null || id === 0) {
      throw new Error(`Missing data in DeezerUser: ${JSON.stringify(data)}`);
    }
    this.data = { ...data, id };
  }

  get id() {
    return `${this.data.id}`;
  }

  get displayName() {
    return this.name || `${this.firstName ?? ''}${this.lastName ? ` ${this.lastName}` : ''}` || this.email || this.id;
  }

  get name() {
    return this.data.name;
  }

  get firstName() {
    return this.data.firstname;
  }

  get lastName() {
    return this.data.lastname;
  }

  get email() {
    return this.data.email;
  }

  get picture() {
    return this.data.picture;
  }

  get country() {
    return this.data.country;
  }
}
