import React from 'react';
import { TypedMemo } from '../../common/types';
import { ErrorAlert } from '../../common/views/ErrorAlert/ErrorAlert';
import { SMLOnboardingFormStep1Props } from './props';
import { useSMLOnboardingFormStep1 } from './useSMLOnboardingFormStep1';

const SMLOnboardingFormStep1Component: React.FC<SMLOnboardingFormStep1Props> = ({
  onSubmit,
  isLoading,
  error,
  withName,
}) => {
  const { NameInput, SlugInput, SubmitButton, submit } = useSMLOnboardingFormStep1({
    onSubmit,
    isLoading,
    withName,
  });

  return (
    <>
      {error && <ErrorAlert error={error} />}
      <form className="d-flex flex-column" onSubmit={submit}>
        {withName ? NameInput : null}
        {SlugInput}
        <div className="d-flex justify-content-end">{SubmitButton}</div>
      </form>
    </>
  );
};

export const SMLOnboardingFormStep1 = TypedMemo(SMLOnboardingFormStep1Component);
