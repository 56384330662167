import React, { useCallback } from 'react';
import { TypedMemo } from '../../types';
import { ExternalLinkProps } from './props';
import { openExternal } from '../../../../utils/openExternal';

const ExternalLinkComponent: React.FC<React.PropsWithChildren<ExternalLinkProps>> = ({ href, children }) => {
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      openExternal(href).catch(console.error);
    },
    [href]
  );

  return (
    <a href={href} onClick={onClick}>
      {children}
    </a>
  );
};

export const ExternalLink = TypedMemo(ExternalLinkComponent);
