import qs from 'qs';
import { v4 as uuid4 } from 'uuid';
import { defaultRetryOptions, fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { FetchError } from '../../generics/errors/FetchError';
import { AnghamiPlaylistsResponse } from './models/AnghamiPlaylistsResponse';
import { AnghamiAlbumsResponse } from './models/AnghamiAlbumsResponse';
import { AnghamiPlaylist } from './models/AnghamiPlaylist';
import { AnghamiSearchResponse } from './models/AnghamiSearchResponse';
import { AnghamiAuthenticationData } from './AnghamiAuthenticationData';
import { AnghamiUser } from './models/AnghamiUser';
import { AnghamiPlaylistWithTracksResponse } from './models/AnghamiPlaylistWithTracksResponse';
import { AnghamiCollectionTrack } from './models/AnghamiCollectionTrack';
import { AnghamiAlbumWithTracksResponse } from './models/AnghamiAlbumWithTracksResponse';
import { ImporterID } from '../types';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { tryParseInt } from '../../utils/tryParseInt';

type AnghamiAPIData = {
  sessionId: string;
  fingerprint: string;
  userId: string;
};

export class AnghamiAPI {
  private static API_BASE_URL = 'https://api.anghami.com/gateway.php';

  private readonly sessionId: string;

  private readonly fingerprint: string;

  public readonly userId: string;

  constructor(data: AnghamiAPIData) {
    const { sessionId, fingerprint, userId } = data;
    this.sessionId = sessionId;
    this.fingerprint = fingerprint;
    this.userId = userId;
  }

  private static requestHeaders(): Record<string, string> {
    return {
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      Referer: 'https://play.anghami.com/',
    };
  }

  private getUrlParams(type: string, params: Record<string, any> = {}) {
    return qs.stringify({
      type,
      angh_type: type,
      sid: this.sessionId,
      fingerprint: this.fingerprint,
      web2: true,
      output: 'jsonhp',
      language: 'en',
      lang: 'en',
      ...params,
    });
  }

  async fetch(
    url: string,
    options: any,
    retryOptions: RetryOptions = defaultRetryOptions
  ): Promise<{ [key: string]: any }> {
    const response = await fetchRetry(url, options, retryOptions);

    const text = await response.text();
    if (response.status === 401) {
      throw new NotAuthenticatedError({ authId: this.userId, importerId: ImporterID.Anghami }, text);
    }

    let data: any;
    try {
      data = JSON.parse(text);
    } catch (e) {
      throw new Error(`Could not parse JSON response text for ${url}: ${text}`);
    }

    if (data.error) {
      const errorCode = tryParseInt(data.error.code);
      if (errorCode === 111 || errorCode === 401) {
        throw new NotAuthenticatedError({ authId: this.userId, importerId: ImporterID.Anghami }, text);
      }
      throw new FetchError(errorCode, JSON.stringify(data.error));
    }
    return data;
  }

  async search(query: string, searchType: 'song' | 'album'): Promise<AnghamiSearchResponse> {
    if (!query) {
      return new AnghamiSearchResponse(null);
    }
    const url = `${AnghamiAPI.API_BASE_URL}?${this.getUrlParams('GETtabsearch', {
      query,
      edge: 0,
      musiclanguage: 1,
      searchtype: searchType,
      page: 0,
      count: 10,
    })}`;

    const data = await this.fetch(url, {
      method: 'GET',
      headers: AnghamiAPI.requestHeaders(),
    });

    return new AnghamiSearchResponse(data);
  }

  async loadPaginatedPlaylistsItems(
    playlistId: string,
    onBatch: (collections: AnghamiCollectionTrack[]) => Promise<void>
  ): Promise<void> {
    let tracksCounter = 0;
    const loadPlaylistItems = async (page: number): Promise<void> => {
      const itemsResponse = await this.loadPlaylistItemPage(playlistId, page);
      tracksCounter += itemsResponse.tracks.length;
      await onBatch(itemsResponse.tracks);
      if (tracksCounter < itemsResponse.totalCount && itemsResponse.tracks.length > 0) {
        await loadPlaylistItems(page + 1);
      }
    };

    await loadPlaylistItems(0);
  }

  async loadPlaylistItemPage(playlistId: string, page = 0): Promise<AnghamiPlaylistWithTracksResponse> {
    const url = `${AnghamiAPI.API_BASE_URL}?${this.getUrlParams('GETplaylistdata', {
      playlistid: playlistId,
      reverse: true,
      page,
    })}`;

    try {
      const data = await this.fetch(url, {
        method: 'GET',
        headers: AnghamiAPI.requestHeaders(),
      });

      return new AnghamiPlaylistWithTracksResponse(data);
    } catch (e) {
      if (e instanceof FetchError) {
        if (e.code === 403 || e.code === 60) {
          // 403: Not owned by user
          // 60: Deleted
          throw new CollectionDoesNotExistsError();
        }
      }
      throw e;
    }
  }

  async loadPaginatedAlbumItems(
    albumId: string,
    onBatch: (collections: AnghamiCollectionTrack[]) => Promise<void>
  ): Promise<void> {
    let tracksCounter = 0;
    const loadAlbumItems = async (page: number): Promise<void> => {
      const itemsResponse = await this.loadAlbumItemPage(albumId, page);
      tracksCounter += itemsResponse.tracks.length;
      await onBatch(itemsResponse.tracks);
      if (
        itemsResponse.totalCount !== undefined &&
        tracksCounter < itemsResponse.totalCount &&
        itemsResponse.tracks.length > 0
      ) {
        await loadAlbumItems(page + 1);
      }
    };

    await loadAlbumItems(0);
  }

  async loadAlbumItemPage(albumId: string, page = 0): Promise<AnghamiAlbumWithTracksResponse> {
    const url = `${AnghamiAPI.API_BASE_URL}?${this.getUrlParams('GETalbumdata', {
      albumId,
      page,
      sectionid: 1,
    })}`;

    const data = await this.fetch(url, {
      method: 'GET',
      headers: AnghamiAPI.requestHeaders(),
    });

    return new AnghamiAlbumWithTracksResponse(data);
  }

  async loadAllPlaylists(): Promise<AnghamiPlaylistsResponse> {
    const url = `${AnghamiAPI.API_BASE_URL}?${this.getUrlParams('GETplaylists')}`;
    const data = await this.fetch(url, {
      method: 'GET',
      headers: AnghamiAPI.requestHeaders(),
    });
    return new AnghamiPlaylistsResponse(data);
  }

  async loadAllAlbums(): Promise<AnghamiAlbumsResponse> {
    const url = `${AnghamiAPI.API_BASE_URL}?${this.getUrlParams('GETlikedalbums')}`;
    const data = await this.fetch(url, {
      method: 'GET',
      headers: AnghamiAPI.requestHeaders(),
    });
    return new AnghamiAlbumsResponse(data);
  }

  async createPlaylist(
    name: string,
    props?: { isPublic?: boolean; description?: string }
  ): Promise<AnghamiPlaylist | null> {
    const { isPublic = false, description } = props ?? {};
    try {
      const url = `${AnghamiAPI.API_BASE_URL}?${this.getUrlParams('PUTplaylist')}`;
      const data = await this.fetch(url, {
        method: 'POST',
        headers: AnghamiAPI.requestHeaders(),
        body: qs.stringify({
          type: 'PUTplaylist',
          name,
          description,
          public: isPublic,
        }),
      });
      return AnghamiPlaylist.fromData(data.playlist, description);
    } catch (e) {
      if (e instanceof FetchError) {
        if (e.code === 10) {
          // Duplicated name
          return this.createPlaylist(AnghamiAPI.newPlaylistName(name), props);
        }
      }
      throw e;
    }
  }

  async updatePlaylistNameAndDescription(
    playlistId: string,
    { name, description }: { name?: string; description?: string }
  ): Promise<void> {
    if (!name && !description) {
      return;
    }
    const url = `${AnghamiAPI.API_BASE_URL}?${this.getUrlParams('UPDATEplaylist')}`;
    await this.fetch(url, {
      method: 'POST',
      headers: AnghamiAPI.requestHeaders(),
      body: qs.stringify({
        type: 'UPDATEplaylist',
        playlistid: playlistId,
        action: 'update',
        newname: name,
        newdescription: description,
      }),
    });
  }

  async updatePlaylistAccess(playlistId: string, isPublic: boolean): Promise<void> {
    const url = `${AnghamiAPI.API_BASE_URL}?${this.getUrlParams('UPDATEplaylist')}`;
    await this.fetch(url, {
      method: 'POST',
      headers: AnghamiAPI.requestHeaders(),
      body: qs.stringify({
        type: 'UPDATEplaylist',
        playlistid: playlistId,
        action: 'share',
        pubic: isPublic,
      }),
    });
  }

  async removePlaylist(playlistId: string): Promise<void> {
    const url = `${AnghamiAPI.API_BASE_URL}?${this.getUrlParams('UPDATEplaylist')}`;
    await this.fetch(url, {
      method: 'POST',
      headers: AnghamiAPI.requestHeaders(),
      body: qs.stringify({
        type: 'UPDATEplaylist',
        playlistid: playlistId,
        action: 'delete',
      }),
    });
  }

  async addTrackToPlaylist(playlistId: string, trackId: string): Promise<void> {
    const url = `${AnghamiAPI.API_BASE_URL}?${this.getUrlParams('PUTplaylist')}`;

    await this.fetch(url, {
      method: 'POST',
      headers: AnghamiAPI.requestHeaders(),
      body: qs.stringify({
        type: 'PUTplaylist',
        playlistid: playlistId,
        action: 'append',
        songID: trackId,
      }),
    });
  }

  async setTracksInPlaylist(playlistId: string, trackIds: string[]): Promise<void> {
    const url = `${AnghamiAPI.API_BASE_URL}?${this.getUrlParams('PUTplaylist')}`;

    await this.fetch(url, {
      method: 'POST',
      headers: AnghamiAPI.requestHeaders(),
      body: qs.stringify({
        type: 'PUTplaylist',
        playlistid: playlistId,
        action: 'update',
        songid: trackIds.join(','),
      }),
    });
  }

  async addAlbumToLibrary(albumId: string): Promise<void> {
    const url = `${AnghamiAPI.API_BASE_URL}?${this.getUrlParams('LIKEalbum', {
      albumid: albumId,
      action: 'like',
    })}`;

    await this.fetch(url, {
      method: 'GET',
      headers: AnghamiAPI.requestHeaders(),
    });
  }

  getAuthData(user: AnghamiUser): AnghamiAuthenticationData {
    return {
      authId: user.id,
      userUUID: null,
      title: user.fullName || user.email || user.id,
      subTitle: user.email,
      expiresAt: null,
      imageUrl: user.picture,
      additionalData: {
        sessionId: this.sessionId,
        fingerprint: this.fingerprint,
      },
    };
  }

  static newPlaylistName(name: string) {
    return `${name} - ${uuid4().substring(0, 6)}`;
  }
}
