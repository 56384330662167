import qs from 'qs';
import { defaultRetryOptions, fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { FetchError } from '../../generics/errors/FetchError';
import { SoundCloudCollectionTrack } from './models/SoundCloudCollectionTrack';
import { SoundCloudUser } from './models/SoundCloudUser';
import { SoundCloudLikedTracksResponse } from './models/SoundCloudLikedTracksResponse';
import { ImporterID } from '../types';
import { Response } from '../../utils/fetch-types';
import { SoundCloudPlaylistsResponse } from './models/SoundCloudPlaylistsResponse';
import { SoundCloudPlaylist } from './models/SoundCloudPlaylist';
import { CollectionType } from '../../generics/models/Collection';
import { SoundCloudSearchTracksResponse } from './models/SoundCloudSearchTracksResponse';
import { SoundCloudPlaylistTracksResponse } from './models/SoundCloudPlaylistTracksResponse';

export class SoundCloudAPI {
  private static BASE_URL = 'https://api.soundcloud.com/';

  public readonly userId;

  private readonly accessToken;

  constructor(props: { accessToken: string; userId: string }) {
    this.accessToken = props.accessToken;
    this.userId = props.userId;
  }

  private get requestHeaders(): Record<string, string> {
    return {
      Accept: 'application/json; charset=utf-8',
      Authorization: `OAuth ${this.accessToken}`,
      'Content-Type': 'application/json',
    };
  }

  static async fetchMe(accessToken: string): Promise<SoundCloudUser> {
    const response = await fetchRetry(`${SoundCloudAPI.BASE_URL}me`, {
      method: 'GET',
      headers: {
        Accept: 'application/json; charset=utf-8',
        Authorization: `OAuth ${accessToken}`,
      },
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to fetch SoundCloud user got wrong response[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new SoundCloudUser(data);
  }

  async fetch(url: string, options: any, retryOptions: RetryOptions = defaultRetryOptions): Promise<Response> {
    const response = await fetchRetry(url, options, retryOptions);

    if (response.status === 401) {
      const text = await response.text();
      throw new NotAuthenticatedError({ authId: this.userId, importerId: ImporterID.SoundCloud }, text);
    }

    return response;
  }

  async getPaginatedLikedTracks(onBatch: (tracks: SoundCloudCollectionTrack[]) => Promise<void>): Promise<void> {
    const limit = 50;
    const getLikedTracks = async (_nextHref?: string): Promise<void> => {
      const { tracks, nextHref } = await this.getLikedTracksPage(limit, _nextHref);
      await onBatch(tracks);
      if (nextHref && tracks.length > 0) {
        await getLikedTracks(nextHref);
      }
    };
    await getLikedTracks();
  }

  async getLikedTracksPage(limit: number, nextHref?: string) {
    const url =
      nextHref ??
      `${SoundCloudAPI.BASE_URL}me/likes/tracks?${qs.stringify({
        limit,
        linked_partitioning: true,
        access: 'playable,preview,blocked',
      })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading liked tracks in SoundCloud[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new SoundCloudLikedTracksResponse(data);
  }

  async getPaginatedLikedPlaylists(onBatch: (playlists: SoundCloudPlaylist[]) => Promise<void>): Promise<void> {
    const limit = 50;
    const getLikedPlaylists = async (_nextHref?: string): Promise<void> => {
      const { playlists, nextHref } = await this.getLikedPlaylistsPage(limit, _nextHref);
      await onBatch(playlists);
      if (nextHref && playlists.length > 0) {
        await getLikedPlaylists(nextHref);
      }
    };
    await getLikedPlaylists();
  }

  async getLikedPlaylistsPage(limit: number, nextHref?: string) {
    const url =
      nextHref ??
      `${SoundCloudAPI.BASE_URL}me/likes/playlists?${qs.stringify({
        limit,
        linked_partitioning: true,
      })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading liked playlists in SoundCloud[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new SoundCloudPlaylistsResponse(data, this.userId, CollectionType.LIKED_PLAYLIST);
  }

  async getPaginatedUserPlaylists(onBatch: (playlists: SoundCloudPlaylist[]) => Promise<void>): Promise<void> {
    const limit = 50;
    const getUserPlaylists = async (_nextHref?: string): Promise<void> => {
      const { playlists, nextHref } = await this.getUserPlaylistsPage(limit, _nextHref);
      await onBatch(playlists);
      if (nextHref && playlists.length > 0) {
        await getUserPlaylists(nextHref);
      }
    };
    await getUserPlaylists();
  }

  async getUserPlaylistsPage(limit: number, nextHref?: string) {
    const url =
      nextHref ??
      `${SoundCloudAPI.BASE_URL}me/playlists?${qs.stringify({
        limit,
        linked_partitioning: true,
        show_tracks: false,
      })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading liked playlists in SoundCloud[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new SoundCloudPlaylistsResponse(data, this.userId, CollectionType.PLAYLIST);
  }

  async searchTracks(query: string, nextHref?: string, limit = 50) {
    const url =
      nextHref ??
      `${SoundCloudAPI.BASE_URL}tracks?${qs.stringify({
        q: query,
        limit,
        linked_partitioning: true,
        access: 'playable,preview,blocked',
      })}`;
    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to search tracks for ${query} on SoundCloud got wrong response[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new SoundCloudSearchTracksResponse(data);
  }

  async createPlaylist(title: string, props?: { isPublic?: boolean; description?: string }) {
    const { isPublic = false, description } = props ?? {};
    const response = await this.fetch(`${SoundCloudAPI.BASE_URL}playlists`, {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        playlist: {
          title,
          sharing: isPublic ? 'public' : 'private',
          description,
          // tracks: { id: string }[] -> eg. [{id: 219787221}],
        },
      }),
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when creating playlist [${title}] in SoundCloud[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return SoundCloudPlaylist.fromData(data, this.userId, CollectionType.PLAYLIST);
  }

  async getPlaylist(playlistId: string) {
    const url = `${SoundCloudAPI.BASE_URL}playlists/${playlistId}?${qs.stringify({
      show_tracks: false,
      access: 'playable,preview,blocked',
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when getting playlist [${playlistId}] in SoundCloud[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return SoundCloudPlaylist.fromData(data, this.userId);
  }

  async updatePlaylist(
    playlistId: string,
    props: { title?: string; isPublic?: boolean; tracks?: { id: string }[]; description?: string }
  ) {
    const { title, isPublic, tracks, description } = props;
    const url = `${SoundCloudAPI.BASE_URL}playlists/${playlistId}`;
    const body = JSON.stringify({
      playlist: {
        title,
        sharing: isPublic === undefined ? undefined : isPublic ? 'public' : 'private',
        tracks,
        description,
      },
    });
    const response = await this.fetch(url, {
      method: 'PUT',
      headers: this.requestHeaders,
      body,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when updating playlist [${playlistId}] with data [${body}] in SoundCloud[${response.status}]: ${text}`
      );
    }
    // This endpoint returns data of playlist, but usually this data are stale.
  }

  async removePlaylist(playlistId: string) {
    const url = `${SoundCloudAPI.BASE_URL}playlists/${playlistId}`;
    const response = await this.fetch(url, {
      method: 'DELETE',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when removing playlist [${playlistId}] in SoundCloud[${response.status}]: ${text}`
      );
    }
  }

  async getPaginatedPlaylistTracks(
    playlistId: string,
    onBatch: (playlists: SoundCloudCollectionTrack[]) => Promise<void>
  ): Promise<void> {
    const getPlaylistTracks = async (_nextHref?: string): Promise<void> => {
      const { tracks, nextHref } = await this.getPlaylistTracksPage(playlistId, _nextHref);
      await onBatch(tracks);
      if (nextHref && tracks.length > 0) {
        await getPlaylistTracks(nextHref);
      }
    };
    await getPlaylistTracks();
  }

  async getPlaylistTracksPage(playlistId: string, nextHref?: string) {
    const url =
      nextHref ??
      `${SoundCloudAPI.BASE_URL}playlists/${playlistId}/tracks?${qs.stringify({
        linked_partitioning: true,
        access: 'playable,preview,blocked',
      })}`;
    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when getting playlist tracks [${playlistId}] in SoundCloud[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new SoundCloudPlaylistTracksResponse(data);
  }
}
