import { SerializedWidget } from 'fym-common/src/db/serializersTypes';
import { useCallback } from 'react';
import { useUpdateWidgetMutation } from '../../../../../redux/api/fymAPI';

export const useWidgetUpdatePosition = (accountId: number, smartLinkId: number, numberOfWidgets: number) => {
  const [updateWidget, { isLoading }] = useUpdateWidgetMutation({ fixedCacheKey: 'update_widget_position' });

  const handleClick = useCallback(
    (position: number, widget: SerializedWidget) => {
      updateWidget({
        accountId,
        smartLinkId,
        position,
        type: widget.type,
        widgetUUID: widget.uuid,
      }).catch(console.error);
    },
    [accountId, updateWidget, smartLinkId]
  );

  const incrementPosition = useCallback(
    (widget: SerializedWidget) => {
      if (widget.position < numberOfWidgets) {
        handleClick(widget.position + 1, widget);
      }
    },
    [numberOfWidgets, handleClick]
  );

  const decrementPosition = useCallback(
    (widget: SerializedWidget) => {
      if (widget.position > 0) {
        handleClick(widget.position - 1, widget);
      }
    },
    [handleClick]
  );

  return { incrementPosition, decrementPosition, isLoading };
};
