import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../../../modules/common/types';
import { OfflineToastNotificationRenderer } from './OfflineToastNotificationRenderer';
import { useIsOnline, useIsServerUp } from '../../../redux/slices/appInfo/hooks';

const OfflineToastNotificationComponent: React.FC = () => {
  const isOnline = useIsOnline();
  const isServerUp = useIsServerUp();
  const intl = useIntl();
  const message = useMemo(() => {
    if (isOnline && isServerUp) {
      return null;
    }
    if (!isOnline) {
      return intl.formatMessage({
        id: 'app.notifications.connection.offline',
        defaultMessage: `No internet connection`,
      });
    }
    // App has internet connection but our server is down
    return intl.formatMessage({
      id: 'app.notifications.connection.offline_server',
      defaultMessage: `Cannot connect to FreeYourMusic Cloud`,
    });
  }, [isServerUp, intl, isOnline]);

  if (message === null) {
    return null;
  }

  return <OfflineToastNotificationRenderer message={message} />;
};

export const OfflineToastNotification = TypedMemo(OfflineToastNotificationComponent);
