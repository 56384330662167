import React from 'react';
import { Col, Row } from 'reactstrap';
import { TypedMemo } from '../../common/types';
import { Breadcrumbs } from './types';
import { SMLBreadcrumb } from './SMLBreadcrumb';

interface SMLBreadcrumbsProps {
  breadcrumbs: Breadcrumbs;
  suffix?: React.ReactNode;
}

const SMLBreadcrumbsComponent: React.FC<React.PropsWithChildren<SMLBreadcrumbsProps>> = ({
  breadcrumbs,
  suffix,
  children,
}) => {
  return (
    <Row className="align-items-center">
      <Col className="d-flex align-items-center">
        <h3 className="mb-0 me-2">
          {breadcrumbs.map((breadcrumb, index) => (
            <SMLBreadcrumb key={breadcrumb.name} breadcrumb={breadcrumb} isLast={index === breadcrumbs.length - 1} />
          ))}
        </h3>
        {suffix}
      </Col>
      {children !== undefined ? <Col className="d-flex justify-content-sm-end col-auto">{children}</Col> : null}
    </Row>
  );
};

export const SMLBreadcrumbs = TypedMemo(SMLBreadcrumbsComponent);
