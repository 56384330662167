import { SpotifyMatchedTrack } from './SpotifyMatchedTrack';
import { SpotifyAlbum } from './SpotifyAlbum';
import { SpotifySearchResponseType } from './types';

export class SpotifySearchResponse {
  private readonly data;

  constructor(data: SpotifySearchResponseType | null) {
    this.data = data;
  }

  get tracks(): SpotifyMatchedTrack[] {
    if (!this.data?.tracks?.items) {
      return [];
    }
    return this.data.tracks.items
      .map((item) => SpotifyMatchedTrack.fromData(item))
      .filter((item): item is SpotifyMatchedTrack => !!item);
  }

  get albums(): SpotifyAlbum[] {
    if (!this.data?.albums?.items) {
      return [];
    }
    return this.data.albums.items
      .map((item) => SpotifyAlbum.fromData(item))
      .filter((album): album is SpotifyAlbum => !!album);
  }

  get firstTrack(): SpotifyMatchedTrack | undefined {
    if (this.tracks.length === 0) {
      return undefined;
    }
    return this.tracks[0];
  }

  get firstAlbum(): SpotifyAlbum | undefined {
    if (this.albums.length === 0) {
      return undefined;
    }
    return this.albums[0];
  }

  get totalTracks(): number | undefined {
    return this.data?.tracks?.total;
  }

  get totalAlbums(): number | undefined {
    return this.data?.albums?.total;
  }
}
