import { BaseModel } from '../../../universal/BaseModel';

export class YouTubeUser extends BaseModel {
  get picture(): string {
    return this.privateData.picture;
  }

  get name(): string {
    return this.privateData.name;
  }

  get email(): string {
    return this.privateData.email;
  }

  get id(): string {
    return this.privateData.sub;
  }
}
