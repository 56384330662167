import { YandexPlaylist } from './YandexPlaylist';
import { CollectionType } from '../../../generics/models/Collection';
import { YandexCollectionTrack } from './YandexCollectionTrack';

export class YandexPlaylistWithTracksResponse {
  private readonly data: any;

  private readonly collectionType: CollectionType;

  constructor(data: any, collectionType: CollectionType) {
    this.data = data;
    this.collectionType = collectionType;
  }

  get playlist(): YandexPlaylist | null {
    return YandexPlaylist.fromData(this.data.result, this.collectionType);
  }

  get tracks(): YandexCollectionTrack[] {
    if (!this.data?.result?.tracks) {
      return [];
    }

    return this.data.result.tracks.map((item: any) => {
      return YandexCollectionTrack.fromData(item.track);
    });
  }
}
