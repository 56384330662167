import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionAccess, CollectionType } from '../../../generics/models/Collection';
import { buildAnghamiCoverUrl, buildCoverUrlFromMetaImage, createAnghamiPlaylistUrl } from '../utils';
import { getMetaContentByProperty, getScriptsContents } from '../../../utils/htmlUtils';
import { tryParseInt } from '../../../utils/tryParseInt';
import { tryParseJSON } from '../../../utils/tryParseJSON';

export class AnghamiLikedPlaylist extends GenericCollection {
  constructor(data: Omit<CollectionModelData, 'type'>) {
    super({ type: CollectionType.LIKED_PLAYLIST, ...data });
  }

  static fromData(data: any): AnghamiLikedPlaylist | null {
    if (!data?.id || !data?.name) {
      return null;
    }
    return new AnghamiLikedPlaylist({
      rawId: `${data.id}`,
      name: data.name,
      itemCount: data.PlaylistCount ?? data.count,
      followers: tryParseInt(data.followers),
      publicUrl: createAnghamiPlaylistUrl(data.id),
      access: CollectionAccess.public,
      additionalData: {
        description: data.description,
        imageUrl: buildAnghamiCoverUrl(data.coverArtID ?? data.coverArt ?? data.coverArtImage),
      },
    });
  }

  static fromShareURLData(props: {
    HTMLString: string;
    url: string;
    metaElements: HTMLCollectionOf<HTMLMetaElement>;
  }): AnghamiLikedPlaylist | null {
    const { HTMLString, url, metaElements } = props;
    const scriptsContents = getScriptsContents(HTMLString);
    const dataString = scriptsContents.find(
      (content) => content.includes('context') && content.includes('MusicPlaylist')
    );
    const data = tryParseJSON(dataString);
    const rawId = data?.url?.split('/').pop();
    const name = data?.name;
    if (!rawId || !name) {
      return null;
    }

    const followers = HTMLString.match(/followers&q;:&q;(.*?)(&q)/)?.[1];
    const metaImageUrl = getMetaContentByProperty(metaElements, 'og:image');

    return new AnghamiLikedPlaylist({
      rawId,
      name,
      itemCount: data.numtracks ?? data.track?.length,
      followers: tryParseInt(followers),
      additionalData: { imageUrl: buildCoverUrlFromMetaImage(metaImageUrl) },
      publicUrl: data.url ?? url,
    });
  }
}
