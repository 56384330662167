import { DefaultRecommendationsResponse } from './types';
import { AppleMusicCollectionTrack } from './AppleMusicCollectionTrack';
import { AppleMusicPlaylist } from './AppleMusicPlaylist';
import { AppleMusicAlbum } from './AppleMusicAlbum';

export class AppleMusicDefaultRecommendationsResponse {
  private readonly data;

  constructor(data: DefaultRecommendationsResponse | undefined) {
    this.data = data;
  }

  get recommendations() {
    const recommendations: (AppleMusicAlbum | AppleMusicPlaylist | AppleMusicCollectionTrack | null)[] = [];
    this.data?.data?.forEach((_data) =>
      _data?.relationships?.contents?.data?.forEach((relationship) => {
        if (relationship.type === 'playlists') {
          recommendations.push(AppleMusicPlaylist.fromData(relationship));
        }
        if (relationship.type === 'albums') {
          recommendations.push(AppleMusicAlbum.fromData(relationship));
        }
        if (relationship.type === 'songs') {
          recommendations.push(AppleMusicCollectionTrack.fromData(relationship));
        }
      })
    );
    return (
      recommendations.filter(
        (recommendation): recommendation is AppleMusicAlbum | AppleMusicPlaylist | AppleMusicCollectionTrack =>
          !!recommendation
      ) ?? []
    );
  }

  get hasNextPage() {
    return this.data?.next;
  }
}
