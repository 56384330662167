import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SmartLinkWidgetType } from 'fym-common/src/db/enums';
import { TypedMemo } from '../../../../common/types';
import { Option } from '../../../../common/views/optionsButton/types';
import { getWidgetIconByWidgetType } from '../../../utils/getWidgetIconByWidgetType';
import { WidgetsOptionsButtonProps } from './props';
import { WidgetsOptionsButtonRenderer } from './WidgetsOptionsButtonRenderer';
import { useSMLRouter } from '../../../../../routing/sml/hooks';
import { SMLRoutes } from '../../../../../routing/sml/SMLRoutes';
import { useLicenseInfo } from '../../../../../redux/slices/license/hooks';

const WidgetsOptionsButtonComponent: React.FC<WidgetsOptionsButtonProps> = ({
  accountId,
  smartLinkId,
  openUpselling,
}) => {
  const intl = useIntl();
  const router = useSMLRouter();

  const goToWidgetCreationView = useCallback(
    (widgetType: SmartLinkWidgetType) => {
      router.push(SMLRoutes.widgetEdit, { accountId, smartLinkId, widgetType });
    },
    [router, accountId, smartLinkId]
  );

  // const goToSCWidgetCreationView = useCallback(() => {
  //   router.push(SMLRoutes.SCWidgetSourceAccountPicker, { accountId, smartLinkId });
  // }, [router, accountId, smartLinkId]);

  const licenseInfo = useLicenseInfo();
  const options: Option[] = useMemo(() => {
    return [
      {
        name: intl.formatMessage({ id: 'app.smart_link.buttons.add_text', defaultMessage: 'Text' }),
        onClick: () => goToWidgetCreationView(SmartLinkWidgetType.info),
        icon: getWidgetIconByWidgetType(SmartLinkWidgetType.info),
      },
      {
        name: intl.formatMessage({ id: 'app.smart_link.buttons.add_image', defaultMessage: 'Image' }),
        onClick: () => goToWidgetCreationView(SmartLinkWidgetType.image),
        icon: getWidgetIconByWidgetType(SmartLinkWidgetType.image),
      },
      {
        name: intl.formatMessage({ id: 'app.smart_link.buttons.add_link', defaultMessage: 'Link' }),
        onClick: () => goToWidgetCreationView(SmartLinkWidgetType.link),
        icon: getWidgetIconByWidgetType(SmartLinkWidgetType.link),
      },
      {
        name: intl.formatMessage({ id: 'app.smart_link.buttons.add_player', defaultMessage: 'Player' }),
        onClick: licenseInfo.hasSMLMusicPlayer
          ? () => goToWidgetCreationView(SmartLinkWidgetType.player)
          : openUpselling,
        icon: getWidgetIconByWidgetType(SmartLinkWidgetType.player),
      },
      {
        name: intl.formatMessage({ id: 'app.smart_link.buttons.add_social', defaultMessage: 'Social media' }),
        onClick: () => goToWidgetCreationView(SmartLinkWidgetType.socialLinks),
        icon: getWidgetIconByWidgetType(SmartLinkWidgetType.socialLinks),
      },
      {
        name: intl.formatMessage({ id: 'app.smart_link.buttons.add_profile', defaultMessage: 'Profile' }),
        onClick: () => goToWidgetCreationView(SmartLinkWidgetType.profile),
        icon: getWidgetIconByWidgetType(SmartLinkWidgetType.profile),
      },
    ];
  }, [intl, goToWidgetCreationView, licenseInfo.hasSMLMusicPlayer, openUpselling]);

  return <WidgetsOptionsButtonRenderer options={options} />;
};

export const WidgetsOptionsButton = TypedMemo(WidgetsOptionsButtonComponent);
