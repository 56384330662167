export enum ImporterID {
  Spotify = 'spotify',
  Youtube = 'youtube',
  AppleMusic = 'apple-music',
  AmazonMusic = 'amazon-music',
  Tidal = 'tidal',
  Pandora = 'pandora',
  Deezer = 'deezer',
  SoundCloud = 'sound-cloud',
  Qobuz = 'qobuz',
  QQMusic = 'qq-music',
  Yandex = 'yandex',
  Anghami = 'anghami',
  YouTubeMusic = 'youtube-music',
  Napster = 'napster',
  VK = 'vk',
  Zvuk = 'zvuk',
  Gaana = 'gaana',
  JioSaavn = 'jiosaavn',
  Resso = 'resso',
  Boomplay = 'boomplay',
  File = 'file-backup',
}

export enum SimplifiedCalculationFor {
  source,
  target,
}

export const SimplifiedImporters = [ImporterID.Youtube];
