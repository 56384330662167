import { ReactComponent as YouTubeMusicLight } from '../../../../assets/images/music-services/youtube-music-light.svg';
import { ReactComponent as YouTubeMusicDark } from '../../../../assets/images/music-services/youtube-music-dark.svg';
import { ReactComponent as YouTubeMusicLightPadding } from '../../../../assets/images/music-services/youtube-music-light-padding.svg';
import { ReactComponent as YouTubeMusicDarkPadding } from '../../../../assets/images/music-services/youtube-music-dark-padding.svg';
import { ReactComponent as YouTubeMusicColorLight } from '../../../../assets/images/music-services/youtube-music-color-light.svg';
import { ReactComponent as YouTubeMusicColorDark } from '../../../../assets/images/music-services/youtube-music-color-dark.svg';
import { ReactComponent as YouTubeMusicColorLightPadding } from '../../../../assets/images/music-services/youtube-music-color-light-padding.svg';
import { ReactComponent as YouTubeMusicColorDarkPadding } from '../../../../assets/images/music-services/youtube-music-color-dark-padding.svg';
import { ReactComponent as YouTubeMusicDarkDanger } from '../../../../assets/images/music-services-danger/youtube-music-dark-danger.svg';
import { ReactComponent as YouTubeMusicLightDanger } from '../../../../assets/images/music-services-danger/youtube-music-light-danger.svg';
import { ReactComponent as YouTubeMusicLightDangerPadding } from '../../../../assets/images/music-services-danger/youtube-music-light-padding-danger.svg';
import { ReactComponent as YouTubeMusicDarkDangerPadding } from '../../../../assets/images/music-services-danger/youtube-music-dark-padding-danger.svg';
import { ReactComponent as YouTubeMusicDarkWarning } from '../../../../assets/images/music-services-warning/youtube-music-dark-warning.svg';
import { ReactComponent as YouTubeMusicLightWarning } from '../../../../assets/images/music-services-warning/youtube-music-light-warning.svg';
import { ReactComponent as YouTubeMusicLightWarningPadding } from '../../../../assets/images/music-services-warning/youtube-music-light-padding-warning.svg';
import { ReactComponent as YouTubeMusicDarkWarningPadding } from '../../../../assets/images/music-services-warning/youtube-music-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const youTubeMusicImages: ImporterImages = {
  logo: {
    light: YouTubeMusicLight,
    dark: YouTubeMusicDark,
  },
  logoPadding: {
    light: YouTubeMusicLightPadding,
    dark: YouTubeMusicDarkPadding,
  },
  logoColor: {
    light: YouTubeMusicColorLight,
    dark: YouTubeMusicColorDark,
  },
  logoColorPadding: {
    light: YouTubeMusicColorLightPadding,
    dark: YouTubeMusicColorDarkPadding,
  },
  logoOnBackground: YouTubeMusicColorDark,
  logoDanger: {
    dark: YouTubeMusicDarkDanger,
    light: YouTubeMusicLightDanger,
  },
  logoDangerPadding: {
    dark: YouTubeMusicDarkDangerPadding,
    light: YouTubeMusicLightDangerPadding,
  },
  logoWarning: {
    dark: YouTubeMusicDarkWarning,
    light: YouTubeMusicLightWarning,
  },
  logoWarningPadding: {
    light: YouTubeMusicLightWarningPadding,
    dark: YouTubeMusicDarkWarningPadding,
  },
};
