import { GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { CollectionAdditionalData } from '../../../generics/models/AdditionalData';
import { tryParseInt } from '../../../utils/tryParseInt';

interface JioSaavnAdditionalData extends CollectionAdditionalData {
  permaUrl?: string;
}

export class JioSaavnPlaylist extends GenericCollection {
  public additionalData!: JioSaavnAdditionalData | null;

  static fromData(data: any, userId: string): JioSaavnPlaylist | null {
    if (!data?.id || !data.title) {
      return null;
    }
    const permLinkParts = data.perma_url ? data.perma_url.split('/') : '';
    const hash = permLinkParts[permLinkParts.length - 1];
    const type = data.more_info.uid === userId ? CollectionType.PLAYLIST : CollectionType.LIKED_PLAYLIST;
    return new JioSaavnPlaylist({
      rawId: `${data.id}|${hash}`,
      name: data.title,
      type,
      additionalData: { permaUrl: data.perma_url },
      itemCount: tryParseInt(data.more_info?.song_count),
      followers: tryParseInt(data.more_info?.follower_count),
      publicUrl: data.perma_url,
    });
  }

  static fromSharedUrlData(rawData: any, url: string): JioSaavnPlaylist | null {
    let data = rawData.mix?.rawServerData;
    if (data) {
      if (!data.id || !data.title) {
        return null;
      }
      return new GenericCollection({
        type: CollectionType.LIKED_PLAYLIST,
        rawId: `${data.id}`,
        name: data.title,
        itemCount: tryParseInt(data.list_count),
        publicUrl: url,
      });
    }
    data = rawData.playlist.playlist;
    if (!data.id || !data.title?.text) {
      return null;
    }
    return new GenericCollection({
      type: CollectionType.LIKED_PLAYLIST,
      rawId: `${data.id}`,
      name: data.title?.text,
      itemCount: tryParseInt(data.list_count),
      followers: tryParseInt(data.fan_count),
      publicUrl: url,
    });
  }
}
