import { LikedTracksResponse } from './types';
import { AmazonMusicCollectionTrack } from './AmazonMusicCollectionTrack';

export class AmazonMusicLikedTracksResponse {
  private readonly data;

  constructor(data: LikedTracksResponse | undefined) {
    this.data = data;
  }

  get tracks() {
    return (
      this.data?.data?.user?.tracks?.edges
        ?.map((edge) => AmazonMusicCollectionTrack.fromData(edge?.node))
        .filter((track): track is AmazonMusicCollectionTrack => !!track) ?? []
    );
  }

  get pageInfoToken() {
    return this.data?.data?.user?.tracks?.pageInfo?.token;
  }

  get hasNextPage() {
    return this.data?.data?.user?.tracks?.pageInfo?.hasNextPage;
  }
}
