import React from 'react';
import { Label } from 'reactstrap';
import { TypedMemo } from '../../../common/types';
import { FormLabelProps } from './props';

const FormLabelComponent: React.FC<React.PropsWithChildren<FormLabelProps>> = ({ forName, children }) => {
  return (
    <Label for={forName} className="fym-form-label mb-0">
      {children}
    </Label>
  );
};

export const FormLabel = TypedMemo(FormLabelComponent);
