import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../../common/types';
import { SMLSetUpContainer } from '../smlSetUpContainer/SMLSetUpContainer';
import { SMLLogInViewRendererProps } from './props';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { useLoginForm } from './LoginForm/useLoginForm';

const SMLLogInViewRendererComponent: React.FC<SMLLogInViewRendererProps> = ({
  goToSignUpView,
  goToRemindPasswordView,
}) => {
  const intl = useIntl();
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      goToSignUpView();
    },
    [goToSignUpView]
  );
  const onClickRemindPassword = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      goToRemindPasswordView();
    },
    [goToRemindPasswordView]
  );
  const { EmailInput, PasswordInput, SubmitButton, submit, ErrorView } = useLoginForm();

  const LinkIntl = useCallback(
    (str: React.ReactNode) => (
      <a href={`/${SMLRoutes.signup}`} onClick={onClick} className="sml-sing-up-link font-weight-500">
        {str}
      </a>
    ),
    [onClick]
  );

  return (
    <SMLSetUpContainer title={intl.formatMessage({ id: 'app.sml.log_in.title', defaultMessage: 'Log in' })}>
      <div className="d-flex justify-content-center">
        <span className="sml-set-up-text ui-text text-center mb-3">
          {intl.formatMessage(
            {
              id: 'app.sml.sign_up.go_to_sign_up',
              defaultMessage: `New user? <link>Create an account</link>`,
            },
            {
              link: LinkIntl,
            }
          )}
        </span>
      </div>
      {ErrorView}
      <form onSubmit={submit}>
        {EmailInput}
        {PasswordInput}
        <a
          href={`/${SMLRoutes.remindPassword}`}
          onClick={onClickRemindPassword}
          className="sml-sing-up-link font-weight-500"
        >
          {intl.formatMessage({
            id: 'app.sml.log_in.go_to_remind_password',
            defaultMessage: `Forgot password?`,
          })}
        </a>
        <div className="mt-3">{SubmitButton}</div>
      </form>
    </SMLSetUpContainer>
  );
};

export const SMLLogInViewRenderer = TypedMemo(SMLLogInViewRendererComponent);
