import { FeatureLabel } from '../types';

export function featureLabelToTranslatableMessage(label: FeatureLabel) {
  switch (label) {
    case FeatureLabel.Premium:
      return { id: 'app.profile.features.premium', defaultMessage: 'PREMIUM' };
    case FeatureLabel.Basic:
      return { id: 'app.profile.features.basic', defaultMessage: 'BASIC' };
    case FeatureLabel.SmartLinksRookie:
      return { id: 'app.profile.features.smartlinks-rookie', defaultMessage: 'ROOKIE' };
    case FeatureLabel.SmartLinksArtist:
      return { id: 'app.profile.features.smartlinks-artist', defaultMessage: 'ARTIST' };
    case FeatureLabel.SmartLinksInfluencer:
      return { id: 'app.profile.features.smartlinks-influencer', defaultMessage: 'INFLUENCER' };
    default:
      throw new Error(`Missing translatable message for FeatureLabel: ${label}`);
  }
}

export function featureLabelToBackgroundColor(label: FeatureLabel) {
  switch (label) {
    case FeatureLabel.SmartLinksInfluencer:
      return '#A428AF';
    case FeatureLabel.SmartLinksArtist:
    case FeatureLabel.Basic:
      return '#de376a';
    default:
      return '#6417fb';
  }
}
