import { SerializedFile } from 'fym-common/src/db/serializersTypes';

export enum FieldsName {
  slug = 'slug',
  name = 'name',
  avatar = 'avatar',
  customDomain = 'customDomain',
  socialLinks = 'socialLinks',
}

export type FieldsType = {
  [FieldsName.slug]: string;
  [FieldsName.name]: string;
  [FieldsName.avatar]?: SerializedFile;
  [FieldsName.customDomain]?: string;
  // don't use FieldsName because then `useFieldArray` doesn't see the type correctly
  socialLinks: {
    socialLinkId?: number;
    url: string;
  }[];
};
