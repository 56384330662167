import React, { useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { TypedMemo } from '../../common/types';
import { SMLListChoiceSmartLinkProps } from './props';
import { useGetSmartLinksQuery } from '../../../redux/api/fymAPI';
import { ErrorView } from '../../common/views/ErrorView/ErrorView';
import { useAccountId } from '../../../redux/slices/appInfo/hooks';
import { SMLListForChoiceViewRenderer } from './SMLListForChoiceViewRenderer';

const SMLListChoiceSmartLinkViewComponent: React.FC<SMLListChoiceSmartLinkProps> = ({
  onSmartLinkSelected,
  smartLinkId,
}) => {
  const accountId = useAccountId();
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const {
    data: smartLinksResponse,
    isLoading: smartLinksLoading,
    error: smartLinksError,
    refetch: smartLinksRefetch,
  } = useGetSmartLinksQuery({ accountId: accountId ?? 0, page, search, limit: 6 }, { skip: accountId === null });

  const availableSmartLinkToSelect = useMemo(
    () => smartLinksResponse?.results.filter((smartlink) => smartlink.id !== smartLinkId) ?? [],
    [smartLinkId, smartLinksResponse?.results]
  );

  const onChangeDebounced = useMemo(() => {
    return debounce(setSearch, 500);
  }, [setSearch]);

  const onSearchChange = useCallback(
    (searched: string) => {
      if (accountId === null) {
        return;
      }
      onChangeDebounced(searched);
    },
    [accountId, onChangeDebounced]
  );

  if (smartLinksError) {
    return <ErrorView error={smartLinksError} retry={smartLinksRefetch} />;
  }

  return (
    <SMLListForChoiceViewRenderer
      smartLinksResponse={smartLinksResponse}
      smartLinksLoading={smartLinksLoading}
      onPageChange={setPage}
      onSmartLinkClick={onSmartLinkSelected}
      onSearchChange={onSearchChange}
      searchFromQuery={search}
      availableSmartLinkToSelect={availableSmartLinkToSelect}
    />
  );
};

export const SMLListChoiceSmartLinkView = TypedMemo(SMLListChoiceSmartLinkViewComponent);
