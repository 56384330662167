import React from 'react';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette } from '@fortawesome/pro-solid-svg-icons/faPalette';
import { TypedMemo } from '../../common/types';
import { SMLCreationBoxRendererProps } from './props';
import { ClickableDiv } from '../../common/views/ClickableDiv';
import { SMLButton } from '../../common/views/SMLButton/SMLButton';

const SMLCreationBoxRendererComponent: React.FC<SMLCreationBoxRendererProps> = ({ onClick }) => {
  const intl = useIntl();
  return (
    <ClickableDiv onClick={onClick} className="sml-box sml-creation-box shadow">
      <h2 className="text-white m-0 text-center">
        {intl.formatMessage({
          id: 'app.smart-links.list.new',
          defaultMessage: 'New',
        })}
      </h2>
      <SMLButton color="light" size="md">
        <span className="text-secondary">
          <FontAwesomeIcon icon={faPalette} className="me-2" />
          {intl.formatMessage({
            id: 'app.smart-links.list.create',
            defaultMessage: 'Create',
          })}
        </span>
      </SMLButton>
    </ClickableDiv>
  );
};

export const SMLCreationBoxRenderer = TypedMemo(SMLCreationBoxRendererComponent);
