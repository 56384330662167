export enum FieldsName {
  socialLinks = 'socialLinks',
}

export type FieldsType = {
  // don't use FieldsName because then `useFieldArray` doesn't see the type correctly
  socialLinks: {
    socialLinkId?: number;
    url: string;
  }[];
};
