import React from 'react';
import { TypedMemo } from '../../common/types';
import { NotSupportedUpgradeViewProps } from './types';
import { NotSupportedUpgradeViewRenderer } from './NotSupportedUpgradeViewRenderer';

const NotSupportedUpgradeViewComponent: React.FC<NotSupportedUpgradeViewProps> = ({
  isShown,
  onClose,
  licenseInfo,
}) => {
  return <NotSupportedUpgradeViewRenderer isShown={isShown} onClose={onClose} licenseInfo={licenseInfo} />;
};

export const NotSupportedUpgradeView = TypedMemo(NotSupportedUpgradeViewComponent);
