export class QobuzUser {
  private readonly data: any;

  public readonly token: string;

  public readonly qobuzSessionAws: string;

  constructor(data: any, token: string, qobuzSessionAws: string) {
    this.data = data;
    this.token = token;
    this.qobuzSessionAws = qobuzSessionAws;
  }

  get name(): string {
    return this.data.display_name;
  }

  get email(): string {
    return this.data.email;
  }

  get id(): number {
    return this.data.id;
  }

  get avatarUrl(): string {
    return this.data.avatar;
  }

  get plan(): string {
    return this.data.credential.label;
  }
}
// {
//         id: 1340021,
//         publicId: 'qobuz:user:PWim6uE71JaRj',
//         email: 'stamptesting123@gmail.com',
//         login: 'stamptesting123@gmail.com',
//         firstname: null,
//         lastname: null,
//         display_name: 'bimusiek',
//         country_code: 'GB',
//         language_code: 'en',
//         zone: 'GB',
//         store: 'GB-en',
//         country: 'GB',
//         avatar: 'https://www.gravatar.com/avatar/235bf670938d2eca65b6e89e151d9e7c?s=50&d=mm',
//         credential: {
//           id: 786250,
//           label: 'streaming-studio',
//           description: 'Subscriber Qobuz Studio',
//           parameters: [Object]
//         },
//         externals: {},
//         device: {
//           id: 4221551,
//           device_manufacturer_id: 'B5130110-8726-11EA-99F6-E36B3967686C',
//           device_model: null,
//           device_os_version: null,
//           device_platform: null
//         }
//       }
