import React from 'react';
import { TypedMemo } from '../../modules/common/types';
import { SMLAppNavigationRoutes } from './SMLAppNavigationRoutes';
import { SmartLinksContainer } from '../../modules/smartLinks/smartLinksContainer/SmartLinksContainer';
import { HomeMessages } from '../../modules/home/messages/HomeMessages';
import { SMLRootWrapper } from '../SMLRootWrapper';
import { SMLRouter } from '../smlHistory';
import { Alerts } from '../../utils/AlertServices/Alerts';

const SMLAppNavigationComponent: React.FC = () => {
  return (
    <SMLRouter>
      <HomeMessages />
      <Alerts />
      <SmartLinksContainer>
        <SMLRootWrapper>
          <SMLAppNavigationRoutes />
        </SMLRootWrapper>
      </SmartLinksContainer>
    </SMLRouter>
  );
};
export const SMLAppNavigation = TypedMemo(SMLAppNavigationComponent);
