import { useCallback, useEffect, useState } from 'react';

export const useScript = (url?: string): boolean => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const onLoaded = useCallback(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    if (!url) {
      onLoaded();
      return;
    }
    const script = document.createElement('script');

    script.src = url;
    script.defer = true;
    script.onload = onLoaded;

    document.body.appendChild(script);
  }, [url, isLoading, onLoaded]);

  return isLoading;
};
