import { LicenseStateType } from 'fym-common/src/license/types';
import { ReactGenericImporter } from '../../../modules/importers/generics/ReactGenericImporter';

export function useSetappLicenseState(): LicenseStateType | null {
  return null;
}

export function useIsSetappDistribution() {
  return false;
}
export function useFilterReactImportersForSetapp(importers: ReactGenericImporter[]): ReactGenericImporter[] {
  return importers;
}
