import { GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { CollectionType } from '../../generics/models/Collection';
import { ZvukAPI } from './ZvukAPI';
import { ZvukPlaylist } from './models/ZvukPlaylist';
import { FetchError } from '../../generics/errors/FetchError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { ZvukAuthenticationData } from './ZvukAuthenticationData';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { ZvukAlbum } from './models/ZvukAlbum';
import { ZvukLikedSongs } from './models/ZvukLikedSongs';
import { SearchQueryProperties } from '../../generics/types';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { convertQueryPropsToString } from '../services/MatchingService.helpers';
import { ImporterID } from '../types';
import { zvuk } from '../../musicServices/services/Zvuk';
import { tryParseInt } from '../../utils/tryParseInt';

const createZvukInstance = (authenticationData: ZvukAuthenticationData): ZvukAPI => {
  const userId = tryParseInt(authenticationData.authId);
  if (userId === undefined) {
    throw new Error('Could not find userId');
  }
  return new ZvukAPI(
    authenticationData.additionalData.accessToken,
    authenticationData.additionalData.sapiAccessToken,
    userId
  );
};

export const ZvukImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.Zvuk;

  public static musicService = zvuk;

  public authenticationData: ZvukAuthenticationData;

  private zvukApi: ZvukAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as ZvukAuthenticationData;
    this.zvukApi = createZvukInstance(this.authenticationData);
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.authenticationData = authenticationData as ZvukAuthenticationData;
    this.zvukApi = createZvukInstance(this.authenticationData);
  }

  async getPaginatedCollections(onBatch: (collections: (ZvukPlaylist | ZvukAlbum)[]) => Promise<void>): Promise<void> {
    const { trackIds } = await this.zvukApi.loadUserLibrary();
    const likedSongs = new ZvukLikedSongs(this.zvukApi.userId, trackIds.length);
    await onBatch([likedSongs]);
    await this.zvukApi.loadPaginatedPlaylists(onBatch);
    await this.zvukApi.loadPaginatedAlbums(onBatch);
  }

  async getCollection(collection: GenericCollection): Promise<ZvukPlaylist | ZvukAlbum> {
    let result: ZvukPlaylist | ZvukAlbum | null;
    try {
      if (collection.type === CollectionType.ALBUM) {
        result = await this.zvukApi.getAlbum(collection.rawId);
      } else {
        result = await this.zvukApi.getPlaylist(collection.rawId);
      }
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CollectionDoesNotExistsError(e.message);
      }
      throw e;
    }
    if (!result) {
      throw new CollectionDoesNotExistsError();
    }
    return result;
  }

  async createCollection(collection: GenericCollection): Promise<ZvukPlaylist> {
    let newCollection: ZvukPlaylist | null = null;
    try {
      newCollection = await this.zvukApi.createPlaylist(collection.name);
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!newCollection) {
      throw new CouldNotCreateCollection();
    }
    return newCollection;
  }

  async addItemToCollection(collection: GenericCollection, matchedItem: GenericMatchedItem) {
    await this.zvukApi.addTrackToPlaylist(collection.rawId, matchedItem.rawId);
    return convertMatchedItemToCollectionItem(matchedItem);
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    collectionItems: GenericCollectionItem[]
  ): Promise<void> {
    if (collection.type !== CollectionType.PLAYLIST || collectionItems.length === 0) {
      return;
    }
    const itemsIds = collectionItems.map((item) => item.rawId);
    const existingItemIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    await this.zvukApi.loadPaginatedPlaylistItems(collection.rawId, async (tracks) => {
      existingItemIds.push(...tracks.map((t) => t.rawId));
    });
    const indexesToRemove = existingItemIds
      .map((itemId, index) => (itemsIds.includes(itemId) ? index : null))
      .filter((index): index is number => index !== null);

    await this.zvukApi.removeTracksFromPlaylist(collection.rawId, indexesToRemove);
  }

  async clearCollection(collection: GenericCollection) {
    if (collection.type !== CollectionType.PLAYLIST) {
      return;
    }
    const existingItemIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    await this.zvukApi.loadPaginatedPlaylistItems(collection.rawId, async (tracks) => {
      existingItemIds.push(...tracks.map((t) => t.rawId));
    });
    const indexesToRemove = existingItemIds.map((_item, index) => index);
    await this.zvukApi.removeTracksFromPlaylist(collection.rawId, indexesToRemove);
  }

  async matchItems(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const { tracks } = await this.zvukApi.search(query, '(track release)');
    return tracks;
  }

  async matchAlbums(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const { releases } = await this.zvukApi.search(query, 'release');
    return releases;
  }

  async addAlbumToLibrary(album: GenericCollection): Promise<void> {
    await this.zvukApi.addAlbumToLibrary(album.rawId);
  }

  async reAuthenticate(_withData: GenericAuthenticationData): Promise<GenericAuthenticationData> {
    return Promise.reject(new Error('reauth not supported'));
  }

  doesSupportReAuth() {
    return false;
  }

  public doesSupportAlbums(): boolean {
    return true;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return false;
  }

  public doesSupportSearchByISRC(): boolean {
    return false;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return false;
  }

  public doesSupportMovingManyItems(): boolean {
    return false;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    switch (forCollection.type) {
      case CollectionType.PLAYLIST:
      case CollectionType.LIKED_PLAYLIST:
        return this.zvukApi.loadPaginatedPlaylistItems(forCollection.rawId, onBatch);
      case CollectionType.MY_SONGS:
        return this.zvukApi.loadPaginatedLikedSongsItems(onBatch);
      case CollectionType.ALBUM:
        return this.zvukApi.loadPaginatedAlbumItems(forCollection.rawId, onBatch);
      default:
        return undefined;
    }
  }
};
