import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { createVkPlayistUrl } from '../utils';

export class VKPlaylist extends GenericCollection {
  public editHash?: string;

  public description?: string;

  constructor(data: CollectionModelData, editHash?: string, description?: string) {
    super(data);
    this.editHash = editHash;
    this.description = description;
  }

  static fromData(data: any, userId: string, itemsCount?: number): VKPlaylist | null {
    if (!data?.id || !data.title) {
      return null;
    }
    const parsedOwnerId = `${data.ownerId}`;
    const isUserOwner = userId === parsedOwnerId;
    const playlistType = isUserOwner ? CollectionType.PLAYLIST : CollectionType.LIKED_PLAYLIST;
    return new VKPlaylist(
      {
        type: playlistType,
        rawId: `${data.id}`,
        name: data.title,
        itemCount: itemsCount ?? data.totalCount,
        additionalData: { ownerId: parsedOwnerId, accessHash: data.accessHash },
        publicUrl: createVkPlayistUrl(data.ownerId, data.id),
      },
      data.editHash || undefined,
      data.rawDescription
    );
  }
}
