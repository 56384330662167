import { BoomplayTrackRawResponse } from '../models/types';
import { BoomplayArtist } from '../models/BoomplayArtist';
import { BoomplayAlbum } from '../models/BoomplayAlbum';
import { validateISRC } from '../../../utils/validateISRC';
import { parseBoomplayDuration } from './parseBoomplayDuration';
import { getBoomplayArtistsNamesAsString } from './getBoomplayArtistsNamesAsString';

export function convertBoomplayTrackData(data: BoomplayTrackRawResponse | undefined) {
  if (!data || data.track_id === undefined || !data.track_title) {
    return null;
  }
  const id = `${data.track_id}`;
  const artists = data.artists
    ?.map((artist) => BoomplayArtist.fromData(artist))
    .filter((a): a is NonNullable<typeof a> => a !== null);
  const album = BoomplayAlbum.fromData({ album_id: data.album_id, album_title: data.album_title });
  const track = {
    rawId: id,
    globalId: id,
    name: data.track_title,
    album: data.album_title,
    isrc: validateISRC(data?.isrc),
    imageUrl: data.artwork?.url,
    artist: getBoomplayArtistsNamesAsString(data.artists),
    duration: parseBoomplayDuration(data.duration) ?? undefined,
    sourceURL: data.web_url,
  };
  return { track, artists, album };
}
