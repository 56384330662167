import URL from 'url-parse';
import { VideoDetails } from '../types';
import { createYoutubeVideoUrl } from '../utils';
import { tryParseDurationToMs } from '../../../utils/tryParseDurationToMs';
import { GenericMatchedItem } from '../../../generics/models/GenericMatchedItem';

export class YouTubeMatchedTrack extends GenericMatchedItem {
  static fromData(data: any): YouTubeMatchedTrack | null {
    const { thumbnails, title } = data.snippet;
    const image = thumbnails?.high ?? thumbnails?.medium ?? thumbnails?.default;
    const globalId = data.id.videoId;
    if (!title || !globalId) {
      return null;
    }
    return new YouTubeMatchedTrack({
      rawId: globalId,
      name: title,
      imageUrl: image?.url,
      sourceURL: createYoutubeVideoUrl(globalId),
    });
  }

  static fromShareURLData(data: VideoDetails): YouTubeMatchedTrack | null {
    if (!data?.videoId || !data.title) {
      return null;
    }
    const firstThumbnail = data.thumbnail.thumbnails[0];
    const imageUrl = firstThumbnail ? new URL(firstThumbnail.url) : undefined;
    return new YouTubeMatchedTrack({
      rawId: data.videoId,
      name: data.title,
      imageUrl: imageUrl ? `${imageUrl.origin}${imageUrl.pathname}` : null,
      duration: tryParseDurationToMs(data.lengthSeconds, 's'),
      sourceURL: `https://youtu.be/${data.videoId}`,
    });
  }
}
