import { YandexAlbum } from './YandexAlbum';
import { YandexCollectionTrack } from './YandexCollectionTrack';

export class YandexAlbumWithTracksResponse {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get album(): YandexAlbum | null {
    return YandexAlbum.fromData(this.data);
  }

  get tracks(): YandexCollectionTrack[] {
    return this.data.volumes[0].map((item: any) => YandexCollectionTrack.fromAlbumData(item));
  }
}
