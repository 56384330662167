import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../../common/types';
import { LoadingView } from '../../common/views/LoadingView';
import { SmartLinksPremiumViewRenderer } from './SmartLinksPremiumViewRenderer';
import { useLicenseInfo } from '../../../redux/slices/license/hooks';
import { SMLBreadcrumbs } from '../../smartLinks/smlBreadcrumbs/SMLBreadcrumbs';
import { SMLContainer } from '../../smartLinks/smlContainer/SMLContainer';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { useSMLRouter } from '../../../routing/sml/hooks';

const SmartLinksPremiumViewComponent: React.FC = () => {
  const licenseInfo = useLicenseInfo();
  const intl = useIntl();
  const router = useSMLRouter();

  const breadcrumbs = useMemo(() => {
    return [
      {
        name: intl.formatMessage({ id: 'app.sml.breadcrumbs.home', defaultMessage: 'Home' }),
        onClick: () => router.push(SMLRoutes.home),
      },
      {
        name: intl.formatMessage({ id: 'app.sml.breadcrumbs.settings', defaultMessage: 'Settings' }),
        onClick: () => router.push(SMLRoutes.settings),
      },
      {
        name: intl.formatMessage({ id: 'app.sml.breadcrumbs.premium', defaultMessage: 'Premium' }),
        onClick: () => router.push(SMLRoutes.premium),
      },
    ];
  }, [intl, router]);
  if (licenseInfo === undefined) {
    return <LoadingView />;
  }

  return (
    <SMLContainer header={<SMLBreadcrumbs breadcrumbs={breadcrumbs} />}>
      <SmartLinksPremiumViewRenderer licenseInfo={licenseInfo} />
    </SMLContainer>
  );
};

export const SmartLinksPremiumView = TypedMemo(SmartLinksPremiumViewComponent);
