import React from 'react';
import Realm from 'realm';
import { MessageDescriptor } from 'react-intl';

export enum ImporterType {
  FROM = 'FROM',
  TO = 'TO',
  STATS = 'STATS',
  NON_BACKUP = 'NON_BACKUP',
}
type NotObjectId<T> = T & (T extends Realm.BSON.ObjectId ? never : T);
export const TypedMemo: <T>(c: NotObjectId<T>, propsAreEqual?: Parameters<typeof React.memo>[1]) => T = React.memo;

export type TranslatableMessage = Required<Omit<MessageDescriptor, 'description'>>;
