import { RessoAlbum } from './RessoAlbum';
import { RessoCollectionTrack } from './RessoCollectionTrack';
import { RessoTracksRawResponse } from './RessoResponseModels';

export class RessoAlbumTracksResponse {
  private readonly data;

  private readonly album;

  constructor(data: RessoTracksRawResponse | undefined, album: RessoAlbum) {
    this.data = data;
    this.album = album;
  }

  get tracks(): RessoCollectionTrack[] {
    if (!this.data?.items) {
      return [];
    }
    return this.data.items
      .map((item) => RessoCollectionTrack.fromAlbumData(item, this.album))
      .filter((track): track is RessoCollectionTrack => !!track);
  }

  get next() {
    return this.data?.next_cursor;
  }
}
