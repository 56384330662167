import { ReactComponent as YouTubeLight } from '../../../../assets/images/music-services/youtube-light.svg';
import { ReactComponent as YouTubeDark } from '../../../../assets/images/music-services/youtube-dark.svg';
import { ReactComponent as YouTubeLightPadding } from '../../../../assets/images/music-services/youtube-light-padding.svg';
import { ReactComponent as YouTubeDarkPadding } from '../../../../assets/images/music-services/youtube-dark-padding.svg';
import { ReactComponent as YouTubeColorLight } from '../../../../assets/images/music-services/youtube-color-light.svg';
import { ReactComponent as YouTubeColorDark } from '../../../../assets/images/music-services/youtube-color-dark.svg';
import { ReactComponent as YouTubeColorLightPadding } from '../../../../assets/images/music-services/youtube-color-light-padding.svg';
import { ReactComponent as YouTubeColorDarkPadding } from '../../../../assets/images/music-services/youtube-color-dark-padding.svg';
import { ReactComponent as YouTubeDarkDanger } from '../../../../assets/images/music-services-danger/youtube-dark-danger.svg';
import { ReactComponent as YouTubeLightDanger } from '../../../../assets/images/music-services-danger/youtube-light-danger.svg';
import { ReactComponent as YouTubeLightDangerPadding } from '../../../../assets/images/music-services-danger/youtube-light-padding-danger.svg';
import { ReactComponent as YouTubeDarkDangerPadding } from '../../../../assets/images/music-services-danger/youtube-dark-padding-danger.svg';
import { ReactComponent as YouTubeDarkWarning } from '../../../../assets/images/music-services-warning/youtube-dark-warning.svg';
import { ReactComponent as YouTubeLightWarning } from '../../../../assets/images/music-services-warning/youtube-light-warning.svg';
import { ReactComponent as YouTubeLightWarningPadding } from '../../../../assets/images/music-services-warning/youtube-light-padding-warning.svg';
import { ReactComponent as YouTubeDarkWarningPadding } from '../../../../assets/images/music-services-warning/youtube-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const youTubeImages: ImporterImages = {
  logo: {
    light: YouTubeLight,
    dark: YouTubeDark,
  },
  logoPadding: {
    light: YouTubeLightPadding,
    dark: YouTubeDarkPadding,
  },
  logoColor: {
    light: YouTubeColorLight,
    dark: YouTubeColorDark,
  },
  logoColorPadding: {
    light: YouTubeColorLightPadding,
    dark: YouTubeColorDarkPadding,
  },
  logoOnBackground: YouTubeDark,
  logoDanger: {
    dark: YouTubeDarkDanger,
    light: YouTubeLightDanger,
  },
  logoDangerPadding: {
    dark: YouTubeDarkDangerPadding,
    light: YouTubeLightDangerPadding,
  },
  logoWarning: {
    dark: YouTubeDarkWarning,
    light: YouTubeLightWarning,
  },
  logoWarningPadding: {
    light: YouTubeLightWarningPadding,
    dark: YouTubeDarkWarningPadding,
  },
};
