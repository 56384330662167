import { TransferCollectionModel } from '../../../realm/models/TransferCollectionModel';
import {
  FilterOptions,
  FilterOptionsEnum,
  SortOptions,
} from '../../../modules/transfersCollections/views/transfersCollectionsView/filters/props';
import { SongFilterOptions } from '../../../modules/transfersCollections/types';
import { TransferCollectionItemModel } from '../../../realm/models/TransferCollectionItemModel';

export type TransferCollectionsStateType = {
  selectedTransferCollectionsIds: ReturnType<TransferCollectionModel['_id']['toHexString']>[];
  sortOption: SortOptions;
  filterOption: FilterOptions;
  manageMode: boolean;
  searchQuery: string;
  songSearchQuery: string;
  songFilterOption: SongFilterOptions;
  itemBeingProcessedByTransferCollection: Record<
    ReturnType<TransferCollectionModel['_id']['toHexString']>,
    ReturnType<TransferCollectionItemModel['_id']['toHexString']>
  >;
  transferCollectionBeingProcessed:
    | {
        id: ReturnType<TransferCollectionModel['_id']['toHexString']> | undefined;
        priority: number;
      }
    | undefined;
};

export const TransferCollectionsState: TransferCollectionsStateType = {
  selectedTransferCollectionsIds: [],
  sortOption: SortOptions.TitleAz,
  filterOption: {
    option: FilterOptionsEnum.All,
  },
  manageMode: false,
  searchQuery: '',
  songSearchQuery: '',
  songFilterOption: SongFilterOptions.ALL,
  itemBeingProcessedByTransferCollection: {},
  transferCollectionBeingProcessed: undefined,
};
