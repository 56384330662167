import { GaanaMatchedTrack } from './GaanaMatchedTrack';
import { GaanaAlbum } from './GaanaAlbum';

export class GaanaSearchResponse {
  private readonly data;

  constructor(data: Record<string, any> | null) {
    this.data = data;
  }

  get tracks(): GaanaMatchedTrack[] {
    const tracks = this.data?.gr?.find((t: any) => t.ty === 'Track');
    if (!tracks) {
      return [];
    }
    return tracks.gd
      .map((item: any) => GaanaMatchedTrack.fromSearchData(item))
      .filter((track: GaanaMatchedTrack | null) => !!track);
  }

  get albums(): GaanaAlbum[] {
    const albums = this.data?.gr?.find((t: any) => t.ty === 'Album');
    if (!albums) {
      return [];
    }
    return albums.gd?.map((item: any) => GaanaAlbum.fromSearchData(item)).filter((album: GaanaAlbum | null) => !!album);
  }

  get firstTrack(): GaanaMatchedTrack | undefined {
    if (this.tracks.length === 0) {
      return undefined;
    }
    return this.tracks[0];
  }

  get firstAlbum(): GaanaAlbum | undefined {
    if (this.albums.length === 0) {
      return undefined;
    }
    return this.albums[0];
  }
}
