import { ArtistsResponse } from './types';
import { AmazonMusicArtist } from './AmazonMusicArtist';

export class AmazonMusicArtistsResponse {
  private readonly data;

  constructor(data: ArtistsResponse | undefined) {
    this.data = data;
  }

  get artists() {
    return (
      this.data?.data?.artists
        ?.map((artistData) => AmazonMusicArtist.fromData(artistData))
        .filter((artist): artist is AmazonMusicArtist => !!artist) ?? []
    );
  }
}
