import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../../common/types';
import { SMLSetUpContainer } from '../smlSetUpContainer/SMLSetUpContainer';
import { SMLSignUpViewRendererProps } from './props';
import { useSignUpForm } from './SignUpForm/useSignUpForm';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';

const SMLSignUpViewRendererComponent: React.FC<SMLSignUpViewRendererProps> = ({ goToLogInView }) => {
  const intl = useIntl();
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      goToLogInView();
    },
    [goToLogInView]
  );
  const { EmailInput, PasswordInput, SubmitButton, submit, ErrorView, NameInput } = useSignUpForm();
  const LinkIntl = useCallback(
    (str: React.ReactNode) => (
      <a href={`/${SMLRoutes.login}`} onClick={onClick} className="sml-sing-up-link font-weight-500">
        {str}
      </a>
    ),
    [onClick]
  );

  return (
    <SMLSetUpContainer title={intl.formatMessage({ id: 'app.sml.sign_up.title', defaultMessage: 'Create Account' })}>
      <div className="d-flex justify-content-center">
        <span className="sml-set-up-text ui-text text-center mb-3">
          {intl.formatMessage(
            {
              id: 'app.sml.sign_up.go_to_log_in',
              defaultMessage: `Already have an account? <link>Log In</link>`,
            },
            {
              link: LinkIntl,
            }
          )}
        </span>
      </div>
      {ErrorView}
      <form onSubmit={submit}>
        {NameInput}
        {EmailInput}
        {PasswordInput}
        <div className="mt-3">{SubmitButton}</div>
      </form>
    </SMLSetUpContainer>
  );
};

export const SMLSignUpViewRenderer = TypedMemo(SMLSignUpViewRendererComponent);
