import URL from 'url-parse';
import {
  getFlexColumnItem,
  getItemText,
  nav,
  parseSearchArtistsNames,
  YouTubeMusicParser,
} from '../youTubeMusicParser';
import { YouTubeMusicTrackType, VideoDetails, MusicVideoType } from '../types';
import { tryParseInt } from '../../../utils/tryParseInt';
import { createYouTubeMusicTrackUrl, getBiggestImageUrlFromThumbnails } from '../utils';
import { tryParseDurationToMs } from '../../../utils/tryParseDurationToMs';
import { GenericMatchedItem, MatchedItemModelData } from '../../../generics/models/GenericMatchedItem';

export class YouTubeMusicMatchedTrack extends GenericMatchedItem {
  public type;

  constructor(data: MatchedItemModelData, type: YouTubeMusicTrackType) {
    super(data);
    this.type = type;
  }

  private static parseSearchAlbumData(data: any) {
    const flexItem = getFlexColumnItem(data, 1);
    if (!flexItem) {
      return undefined;
    }
    const textRuns = flexItem?.text?.runs;
    if (!textRuns || textRuns.length < 5) {
      return undefined;
    }
    return {
      name: textRuns[textRuns.length - 3]?.text,
    };
  }

  private static parseSearchDuration(data: any) {
    const flexItem = getFlexColumnItem(data, 1);
    if (!flexItem) {
      return undefined;
    }
    const textRuns = flexItem?.text?.runs;
    if (!textRuns) {
      return undefined;
    }
    const duration = textRuns[textRuns.length - 1]?.text;
    if (!duration) {
      return undefined;
    }
    try {
      const [minutes, seconds] = duration.split(':');
      return ((tryParseInt(minutes) ?? 0) * 60 + (tryParseInt(seconds) ?? 0)) * 1000;
    } catch (e) {
      return undefined;
    }
  }

  static fromSearchDataSong(data: any): YouTubeMusicMatchedTrack | null {
    const videoId = nav(data, YouTubeMusicParser.PLAY_BUTTON)?.playNavigationEndpoint?.watchEndpoint?.videoId;
    const title = getItemText(data, 0);
    if (!videoId || !title) {
      return null;
    }
    const album = YouTubeMusicMatchedTrack.parseSearchAlbumData(data);
    const artist = parseSearchArtistsNames(data);
    const duration = YouTubeMusicMatchedTrack.parseSearchDuration(data);
    const thumbnails = nav(data, YouTubeMusicParser.THUMBNAILS);
    const thumbnail = thumbnails.length > 0 ? thumbnails[thumbnails.length - 1].url : undefined;
    return new YouTubeMusicMatchedTrack(
      {
        rawId: videoId,
        name: title,
        album: album?.name,
        artist,
        imageUrl: thumbnail,
        duration,
      },
      YouTubeMusicTrackType.Song
    );
  }

  static fromSearchDataVideo(data: any): YouTubeMusicMatchedTrack | null {
    const videoId = nav(data, YouTubeMusicParser.PLAY_BUTTON)?.playNavigationEndpoint?.watchEndpoint?.videoId;
    const title = getItemText(data, 0);
    if (!videoId || !title) {
      return null;
    }
    const duration = YouTubeMusicMatchedTrack.parseSearchDuration(data);
    const thumbnails = nav(data, YouTubeMusicParser.THUMBNAILS);
    const thumbnail = thumbnails.length > 0 ? thumbnails[thumbnails.length - 1].url : undefined;
    return new YouTubeMusicMatchedTrack(
      { rawId: videoId, name: title, imageUrl: thumbnail, duration, sourceURL: createYouTubeMusicTrackUrl(videoId) },
      YouTubeMusicTrackType.Video
    );
  }

  static fromShareURLData(data: VideoDetails, album: string | undefined, url: string): YouTubeMusicMatchedTrack | null {
    if (!data?.videoId || !data.title) {
      return null;
    }
    const duration = tryParseDurationToMs(data?.lengthSeconds, 's');
    const type = data.musicVideoType === MusicVideoType.Song ? YouTubeMusicTrackType.Song : YouTubeMusicTrackType.Video;
    const thumbnails = data.thumbnail?.thumbnails;
    const thumbnail = getBiggestImageUrlFromThumbnails(thumbnails);
    const thumbnailUrl = thumbnail ? new URL(thumbnail) : undefined;
    const imageUrl = thumbnailUrl ? `${thumbnailUrl.origin}${thumbnailUrl.pathname}` : undefined;
    return new YouTubeMusicMatchedTrack(
      { rawId: data.videoId, name: data.title, album, artist: data.author, imageUrl, duration, sourceURL: url },
      type
    );
  }
}
