import { SerializedError } from '@reduxjs/toolkit';
import { FYMAPIError } from 'fym-common/src/generics/errors/FYMAPIError';

type SuccessfulAPIResult<T> = {
  data: T;
};

export type ErrorAPIResult = {
  error: Error | FYMAPIError | SerializedError;
};

export function isSuccessfulResult<T>(
  result: SuccessfulAPIResult<T> | ErrorAPIResult
): result is SuccessfulAPIResult<T> {
  return Object.prototype.hasOwnProperty.call(result, 'data');
}
