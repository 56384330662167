import { SerializedSmartLink } from 'fym-common/src/db/serializersTypes';

export interface PostMessagableWindow {
  postMessage: (message: string, domain?: string) => void; // domain needed for browsers
}

export type SendMessageToPreviewFunc = (
  domain: string
) => (childWindow: PostMessagableWindow, message: SMLAppMessage) => void;
export enum SMLPreviewMessages {
  up = 'SMLPreview.up',
  down = 'SMLPreview.down',
  edit = 'SMLPreview.edit',
}
export interface SMLPreviewMessageData {
  type: SMLPreviewMessages;
  widgetUUID: string;
}
export enum SMLAppMessages {
  smartLinkUpdate = 'SMLApp.smartLinkUpdate',
}
interface SMLAppMessageData<Type extends SMLAppMessages, Data> {
  type: Type;
  data: Data;
}

export type SMLAppMessageSmartLinkUpdate = SMLAppMessageData<
  SMLAppMessages.smartLinkUpdate,
  { smartLink: SerializedSmartLink }
>;

export type SMLAppMessage = SMLAppMessageSmartLinkUpdate;
