import { CollectionItemAdditionalData } from './AdditionalData';

export type CollectionItemModelData = {
  rawId: string;
  globalId: string | undefined | null;
  name: string;
  album?: string;
  artist?: string;
  isrc?: string | null;
  imageUrl?: string | null;
  duration?: number | null;
  previewUrl?: string | null;
  sourceURL?: string | null;
  additionalData?: CollectionItemAdditionalData | null;
  position?: number | null;
};

export class GenericCollectionItem {
  public rawId;

  public globalId;

  public name;

  public album;

  public artist;

  public isrc;

  public additionalData;

  public imageUrl;

  // In millis
  public duration;

  public previewUrl;

  public sourceURL;

  public position;

  constructor(data: CollectionItemModelData) {
    const {
      rawId,
      globalId,
      name,
      album,
      artist,
      isrc,
      additionalData,
      imageUrl,
      duration,
      previewUrl,
      sourceURL,
      position,
    } = data;
    this.rawId = Number.isInteger(rawId) ? `${rawId}` : rawId;
    this.globalId = Number.isInteger(globalId) ? `${globalId}` : globalId || null;
    this.name = name;
    this.album = album ?? '';
    this.artist = artist ?? '';
    this.isrc = isrc ?? '';
    this.additionalData = additionalData ?? null;
    this.imageUrl = imageUrl ?? null;
    this.duration = duration ?? null;
    this.previewUrl = previewUrl ?? null;
    this.sourceURL = sourceURL ?? null;
    this.position = position ?? null;
  }
}
