import React, { useCallback } from 'react';
import { Button, Input, InputGroup, InputGroupText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons/faEyeSlash';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { TypedMemo } from '../../../common/types';
import { FormInputRendererProps } from './props';
import { InputType } from '../../types';
import { useTogglePasswordVisibility } from './hooks';

const FormInputRendererComponent = React.forwardRef<any, FormInputRendererProps>(
  (
    {
      value,
      onChangeText,
      invalid,
      name,
      type,
      placeholder,
      onRemove,
      disabled,
      inputTextPrefix,
      inputTextSuffix,
      inputSuffix,
      icon,
      readonly = false,
      autoComplete,
    },
    ref
  ) => {
    const { showPassword, togglePasswordVisibility } = useTogglePasswordVisibility();

    const onChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeText(event.target.value);
      },
      [onChangeText]
    );

    if (readonly) {
      if (!value) {
        return null;
      }
      return (
        <div className="fym-form-readonly-input d-flex align-items-center">
          {icon !== undefined && <FontAwesomeIcon className="me-2" icon={icon} />}
          <span className="ui-text text-1-line">
            {inputTextPrefix}
            <span className="font-weight-500">{value}</span>
            {inputTextSuffix}
          </span>
        </div>
      );
    }

    return (
      <InputGroup>
        {icon !== undefined && (
          <InputGroupText className="ui-text">
            <FontAwesomeIcon icon={icon} />
          </InputGroupText>
        )}
        {inputTextPrefix ? (
          <InputGroupText className="ui-text fym-form-input-blue-container">{inputTextPrefix}</InputGroupText>
        ) : null}
        <Input
          name={name}
          rows={type === InputType.textarea ? 8 : undefined}
          value={value}
          onChange={onChange}
          invalid={invalid}
          type={showPassword ? InputType.text : type}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete={autoComplete}
          innerRef={ref}
        />
        {inputTextSuffix ? (
          <InputGroupText className="ui-text fym-form-input-blue-container">{inputTextSuffix}</InputGroupText>
        ) : null}
        {inputSuffix}
        {type === InputType.password && (
          <InputGroupText className="ui-text cursor-pointer" onClick={togglePasswordVisibility}>
            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
          </InputGroupText>
        )}
        {onRemove && (
          <Button onClick={onRemove}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        )}
      </InputGroup>
    );
  }
);

export const FormInputRenderer = TypedMemo(FormInputRendererComponent);
