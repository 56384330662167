import { BaseObject } from '../../../universal/BaseObject';

export class NapsterUser extends BaseObject {
  get id(): string {
    return this.privateData.account.id;
  }

  get userName(): string {
    return this.privateData.account.userName;
  }

  get email(): string {
    return this.privateData.account.email;
  }

  get country(): string {
    return this.privateData.account.country;
  }

  get screenName(): string {
    return this.privateData.account.screenName;
  }
}

// {
//   "account": {
//     "id": "A72A1C07401E954AE050960A550333BB",
//     "type": "account",
//     "href": "https://api.napster.com/v2.2/me/account",
//     "created": "2020-06-03T14:06:42.000Z",
//     "cobrand": "60201",
//     "cocat": "60201:104:en_GB",
//     "originCode": "organicweb",
//     "email": "stamptesting123@gmail.com",
//     "country": "GB",
//     "zip": null,
//     "lang": "en-GB",
//     "userName": "stamptesting123@gmail.com",
//     "firstName": null,
//     "lastName": null,
//     "screenName": "Colossal26428",
//     "nonDmcaRadioUser": true,
//     "parentalControlEnabled": false,
//     "isPublic": true,
//     "isCurrentSubscriptionPayable": true,
//     "scrobblingEnabled": false,
//     "facebookApp": "421592347916719",
//     "facebookId": "3264449763573719",
//     "preferredLanguage": "en_GB",
//     "screenNameAutoCreated": "true",
//     "familyPlan": {},
//     "subscription": {
//       "id": "91600066",
//       "billingPartnerCode": "Rhapsody",
//       "catalog": "GB",
//       "createDate": "2020-06-03T14:07:32.000Z",
//       "isSuspended": false,
//       "tierCode": "PREMIER-EXTRA",
//       "tierName": "",
//       "productCode": "PREMIER_EXTRA_NONUS",
//       "productName": "Napster Unlimited plus Mobile",
//       "productServiceTerm": "MTH_30DAY",
//       "expirationDate": "2020-07-03T07:00:00.000Z",
//       "trialLengthDays": 30,
//       "isTrial": true,
//       "state": "TRIAL",
//       "billingPartner": "Rhapsody",
//       "createdWithSocialProvider": null
//     },
//     "entitlements": {
//       "canStreamOnWeb": true,
//       "canStreamOnMobile": true,
//       "canStreamOnHomeDevice": true,
//       "canStreamOnPC": true,
//       "canUpgradeStreams": false,
//       "canPlayPremiumRadio": true,
//       "maxStreamCount": 1,
//       "isPlayBasedTier": false,
//       "isMonthlyPlayBasedTier": false,
//       "isOneTimePlayBasedTier": false,
//       "totalPlays": null,
//       "playsRemaining": null,
//       "skipLimit": null,
//       "skipLimitMinutes": null,
//       "canStreamOffline": true,
//       "maxDeviceCount": 0,
//       "canStreamRadio": true,
//       "canStreamOnDemand": true,
//       "canStreamHiRes": false,
//       "maxStreamBitrate": 320,
//       "maxDownloadBitrate": 320,
//       "maxPcCount": 3
//     },
//     "state": null,
//     "city": null,
//     "nickName": null,
//     "middleName": null,
//     "birthday": null
//   }
// }
