import React from 'react';
import { SerializedImageWidget } from 'fym-common/src/db/serializersTypes';
import { TypedMemo } from '../../../../../common/types';
import { WidgetListItemProps } from '../../props';
import { FYMImage } from '../../../../../common/views/FYMImage/FYMImage';

const ImageWidgetListItemComponent: React.FC<WidgetListItemProps<SerializedImageWidget>> = ({ widget }) => {
  const { data } = widget;
  if (!data.imageProcessedUrl) {
    return null;
  }
  return <FYMImage source={data.imageProcessedUrl} width="100%" height="100%" alt="image" />;
};

export const ImageWidgetListItem = TypedMemo(ImageWidgetListItemComponent);
