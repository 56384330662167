import { BoomplayArtistRawResponse } from '../models/types';
import { artistsToString } from '../../../utils/artistsConverter';

export function getBoomplayArtistsNamesAsString(artists: BoomplayArtistRawResponse[] | undefined) {
  if (!artists || artists.length === 0) {
    return '';
  }

  const artistsNames = artists.reduce((result, artist) => {
    if (artist.artist_name) {
      result.push(artist.artist_name);
    }
    return result;
  }, [] as string[]);

  return artistsToString(artistsNames);
}
