import React, { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { FieldsName, FieldsType } from './consts';
import { resolver } from './validators';
import { FormInput } from '../../../forms/components/FormInput/FormInput';
import { InputType } from '../../../forms/types';
import { useRemindPasswordMutation } from '../../../../redux/api/fymAPI';
import { isSuccessfulResult } from '../../../../redux/api/utils/isSuccessfulResult';
import { ErrorAlert } from '../../../common/views/ErrorAlert/ErrorAlert';
import { SMLButton } from '../../../common/views/SMLButton/SMLButton';

export function useRemindPasswordForm() {
  const intl = useIntl();
  const { control, handleSubmit, reset } = useForm<FieldsType>({
    resolver,
    defaultValues: {
      [FieldsName.email]: '',
    },
  });

  const [remindPassword, { isLoading, error, data }] = useRemindPasswordMutation({ fixedCacheKey: 'remind-password' });
  const isSuccessful = !isLoading && !error && data !== undefined;

  const onSubmit: SubmitHandler<FieldsType> = useCallback(
    async ({ email }) => {
      const results = await remindPassword({
        email,
      });
      if (isSuccessfulResult(results)) {
        reset();
      }
    },
    [remindPassword, reset]
  );
  const submit = useCallback(
    (event?: React.BaseSyntheticEvent) => {
      handleSubmit(onSubmit)(event).catch(console.error);
    },
    [handleSubmit, onSubmit]
  );

  return {
    submit,
    EmailInput: (
      <FormInput
        name={FieldsName.email}
        control={control}
        type={InputType.email}
        disabled={isLoading}
        icon={faEnvelope}
        placeholder={intl.formatMessage({
          id: 'app.forms.login.email.placeholder',
          defaultMessage: 'Enter your email',
        })}
        label={intl.formatMessage({
          id: 'app.forms.login.email.label',
          defaultMessage: 'Email',
        })}
      />
    ),
    SubmitButton: (
      <SMLButton
        color="secondary"
        onClick={submit}
        disabled={isLoading}
        isLoading={isLoading}
        stretch
        omitCap
        size="lg"
        submit
      >
        {intl.formatMessage({ id: 'app.forms.buttons.remind-password.label', defaultMessage: 'Remind Password' })}
      </SMLButton>
    ),
    isSuccessful,
    ErrorView: <>{error && <ErrorAlert error={error} />}</>,
  };
}
