import { v4 as uuid4 } from 'uuid';
import { ListenerCallback, Store, Store as StoreType } from './StoreInterface';

export abstract class StoreListener {
  private listeners: Map<keyof Store, Map<string, ListenerCallback<keyof Store>>> = new Map();

  public addListener<K extends keyof Store>(key: K, callback: ListenerCallback<K>): () => void {
    const uniqueId = uuid4();
    const keyListeners = this.listeners.get(key) ?? new Map<string, ListenerCallback<keyof Store>>();
    this.listeners.set(key, keyListeners);
    keyListeners.set(uniqueId, callback as unknown as ListenerCallback<keyof Store>);
    return () => {
      keyListeners.delete(uniqueId);
    };
  }

  private triggerCallbacksForKey<K extends keyof Store>(key: K, value: StoreType[K] | null) {
    this.listeners.get(key)?.forEach((callback) => {
      callback(value);
    });
  }

  private triggerAllCallbacks<K extends keyof Store>(value: StoreType[K] | null) {
    this.listeners.forEach((keyListeners) => {
      keyListeners.forEach((callback) => {
        callback(value);
      });
    });
  }

  protected set<K extends keyof StoreType>(key: K, value: StoreType[K]): void {
    this.triggerCallbacksForKey(key, value);
  }

  protected remove<K extends keyof StoreType>(key: K): void {
    this.triggerCallbacksForKey(key, null);
  }

  protected clear(): void {
    this.triggerAllCallbacks(null);
  }
}
