import { TidalCollectionTrack } from './TidalCollectionTrack';

export class TidalPlaylistTracksResponse {
  private readonly data: any;

  public readonly etag: string | null;

  constructor(data: any, etag: string | null) {
    this.data = data;
    this.etag = etag;
  }

  get tracks(): TidalCollectionTrack[] {
    if (!this.data?.items) {
      return [];
    }

    return this.data.items
      .map((item: any) => TidalCollectionTrack.fromData(item.item))
      .filter((track: TidalCollectionTrack | null) => !!track);
  }

  get totalNumberOfItems(): number {
    return this.data.totalNumberOfItems;
  }
}
