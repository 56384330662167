import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SmartLinkWidgetType } from 'fym-common/src/db/enums';
import { SerializedImageWidget } from 'fym-common/src/db/serializersTypes';
import { TypedMemo } from '../../../../../common/types';
import { WidgetEditionProps } from '../../props';
import { ImageWidgetForm, ImageWidgetFormProps } from '../../../../../forms/ImageWidgetForm/ImageWidgetForm';

const ImageWidgetEditionViewComponent: React.FC<WidgetEditionProps<SerializedImageWidget>> = ({
  widget,
  accountId,
  saveWidget,
  deleteWidget,
  isLoading,
  error,
  smartLinkId,
}) => {
  const intl = useIntl();
  const onSubmit: ImageWidgetFormProps['onSubmit'] = useCallback(
    async (data) => {
      const { image } = data;
      await saveWidget({ imageId: image.id }, SmartLinkWidgetType.image);
    },
    [saveWidget]
  );

  return (
    <ImageWidgetForm
      title={intl.formatMessage({ id: 'app.smart-links.widgets.image.title', defaultMessage: 'Image widget' })}
      onSubmit={onSubmit}
      widget={widget}
      accountId={accountId}
      onDelete={deleteWidget}
      isLoading={isLoading}
      error={error}
      smartLinkId={smartLinkId}
    />
  );
};

export const ImageWidgetEditionView = TypedMemo(ImageWidgetEditionViewComponent);
