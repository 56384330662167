import { FYMTabNames } from '../../../routing/fym/FYMRoutes';
import { RouteLocation, TabsHistory } from './types';

const tabHistoryEntries = Object.values(FYMTabNames).map((tabName): [FYMTabNames, RouteLocation[]] => [tabName, []]);
const initialTabsHistory = Object.fromEntries(tabHistoryEntries) as TabsHistory;

export interface RoutingStateType {
  tabsHistory: TabsHistory;
}

export const RoutingState: RoutingStateType = {
  tabsHistory: initialTabsHistory,
};
