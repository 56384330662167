import { PandoraPlaylist } from './PandoraPlaylist';

export class PandoraPlaylistsResponse {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get playlists(): PandoraPlaylist[] {
    if (!this.data?.items) {
      return [];
    }
    return this.data.items
      .map((item: any) => PandoraPlaylist.fromData(this.annotations[item.pandoraId]))
      .filter((playlist: PandoraPlaylist | null) => !!playlist);
  }

  get annotations(): { [key: string]: any } {
    return this.data.annotations;
  }

  get totalCount(): number {
    return this.data.totalCount;
  }
}
