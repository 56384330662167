import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SupportViewRendererProps } from './props';
import { TypedMemo } from '../common/types';
import { SupportEmail } from './supportEmail/SupportEmail';
import { appInformation } from '../../utils/appInformation';

const SupportViewRendererComponent: React.FC<SupportViewRendererProps> = ({ deviceId, licenseKey, email }) => {
  return (
    <>
      <h3 className="text-center ui-text">
        <FormattedMessage id="app.support.title" defaultMessage="Support" />
      </h3>
      <p className="text-muted text-center">
        <SupportEmail email={email} licenceKey={licenseKey} deviceId={deviceId} />
        <br />
        <FormattedMessage
          id="app.support.version"
          defaultMessage="App Version: {appVersion}"
          values={{ appVersion: appInformation.version }}
        />
        <br />
        {licenseKey && (
          <FormattedMessage
            id="app.support.passcode"
            defaultMessage="Passcode: {licenseId}"
            values={{ licenseId: licenseKey }}
            description={
              "This is supposed to mean 'license code' but Apple forbids this pharsing, so the word needs to be somewhere around password code, in english passcode sound okeish"
            }
          />
        )}
        <br />
        <FormattedMessage
          id="app.support.deviceid"
          defaultMessage="Device ID: {realmId}"
          values={{ realmId: deviceId }}
        />
      </p>
    </>
  );
};
export const SupportViewRenderer = TypedMemo(SupportViewRendererComponent);
