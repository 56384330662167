import { GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { CollectionAccess, CollectionType } from '../../generics/models/Collection';
import { TidalAuthenticationData } from './TidalAuthenticationData';
import { TidalAPI } from './TidalAPI';
import { TidalPlaylist } from './models/TidalPlaylist';
import { TidalAlbum } from './models/TidalAlbum';
import { FetchError } from '../../generics/errors/FetchError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { SearchQueryProperties } from '../../generics/types';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { convertQueryPropsToString, convertQueryPropsToStringWithoutAlbum } from '../services/MatchingService.helpers';
import { ImporterID } from '../types';
import { tidal } from '../../musicServices/services/Tidal';
import { TidalLikedTracks } from './models/TidalLikedTracks';
import { refreshAuthData } from '../../musicApi/utils/refreshAuthData';
import { MusicAPIIntegrationID } from '../../musicServices/types';

const createTidalInstance = (authenticationData: TidalAuthenticationData): TidalAPI => {
  return new TidalAPI(
    authenticationData.additionalData.accessToken,
    authenticationData.authId,
    authenticationData.additionalData.countryCode
  );
};

export const TidalImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.Tidal;

  public static musicService = tidal;

  public authenticationData: TidalAuthenticationData;

  private tidalApi: TidalAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as TidalAuthenticationData;
    this.tidalApi = createTidalInstance(this.authenticationData);
  }

  setAuthenticationData(authenticationData: TidalAuthenticationData): void {
    this.authenticationData = authenticationData;
    this.tidalApi = createTidalInstance(this.authenticationData);
  }

  async getPaginatedCollections(
    onBatch: (collections: (TidalPlaylist | TidalAlbum)[]) => Promise<void>
  ): Promise<void> {
    const { totalNumberOfItems } = await this.tidalApi.loadFavouriteTracksPage(0, 1);
    const mySongs = new TidalLikedTracks(this.authenticationData.authId, totalNumberOfItems);
    await onBatch([mySongs]);
    await this.tidalApi.loadPaginatedPlaylists(onBatch);
    await this.tidalApi.loadPaginatedAlbums(onBatch);
  }

  async getCollection(collection: GenericCollection): Promise<TidalPlaylist | TidalAlbum> {
    let result: TidalPlaylist | TidalAlbum | null;
    try {
      if (collection.type === CollectionType.ALBUM) {
        result = (await this.tidalApi.loadAlbum(collection.rawId)).album;
      } else if (collection.type === CollectionType.MY_SONGS) {
        const { totalNumberOfItems } = await this.tidalApi.loadFavouriteTracksPage(0, 1);
        result = new TidalLikedTracks(this.authenticationData.authId, totalNumberOfItems);
      } else {
        result = await this.tidalApi.loadPlaylist(collection.rawId);
      }
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CollectionDoesNotExistsError(e.message);
      }
      throw e;
    }
    if (!result) {
      throw new CollectionDoesNotExistsError();
    }
    return result;
  }

  async createCollection(collection: GenericCollection, description?: string): Promise<TidalPlaylist> {
    let newCollection: TidalPlaylist | null = null;
    try {
      newCollection = await this.tidalApi.createPlaylist(collection.name, description);
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!newCollection) {
      throw new CouldNotCreateCollection();
    }
    return newCollection;
  }

  async addItemToCollection(collection: GenericCollection, matchedItem: GenericMatchedItem, trackPosition?: number) {
    if (collection.type === CollectionType.MY_SONGS) {
      await this.tidalApi.addTracksToLibrary([matchedItem.rawId]);
    } else {
      await this.tidalApi.addTracksToPlaylist({
        playlistId: collection.rawId,
        trackIds: [matchedItem.rawId],
        trackPosition,
      });
    }
    return convertMatchedItemToCollectionItem(matchedItem);
  }

  async addManyItemsToCollection(
    collection: GenericCollection,
    data: {
      matchedItem: GenericMatchedItem;
      position?: number;
    }[]
  ) {
    const tracksIds = data.map(({ matchedItem }) => matchedItem.rawId);
    if (collection.type === CollectionType.MY_SONGS) {
      await this.tidalApi.addTracksToLibrary(tracksIds);
    } else {
      const firstPosition = data[0]?.position;
      await this.tidalApi.addTracksToPlaylist({
        playlistId: collection.rawId,
        trackIds: tracksIds,
        trackPosition: firstPosition,
      });
    }
  }

  async moveManyItems(props: Parameters<NonNullable<GenericImporter['moveManyItems']>>[0]) {
    const { itemsToMove, collection, offset } = props;
    itemsToMove.sort((a, b) => a.position - b.position);

    const existingItemsIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    const etag = await this.tidalApi.loadPaginatedPlaylistItems(collection.rawId, async (tracks) => {
      existingItemsIds.push(...tracks.map((i) => i.rawId));
    });

    const firstItem = itemsToMove[0];
    if (firstItem === undefined) {
      throw new Error('Cannot find item to move');
    }

    const indexOfFirstTrackToMove = existingItemsIds.findIndex((trackId) => trackId === firstItem.rawId);
    if (indexOfFirstTrackToMove === -1) {
      throw new Error('Cannot find item to move');
    }
    const indexesToMove = Array(itemsToMove.length)
      .fill(0)
      .map((_value, index) => index + indexOfFirstTrackToMove);
    const newPosition = indexOfFirstTrackToMove + offset;
    await this.tidalApi.moveTrackInPlaylist(collection.rawId, indexesToMove, newPosition, etag);
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    collectionItems: GenericCollectionItem[]
  ): Promise<void> {
    if (collection.type !== CollectionType.PLAYLIST || collectionItems.length === 0) {
      return;
    }
    const itemsIds = collectionItems.map((item) => item.rawId);
    await this.tidalApi.removeTracksFromPlaylist(collection.rawId, itemsIds);
  }

  async clearCollection(collection: GenericCollection) {
    if (collection.type !== CollectionType.PLAYLIST) {
      return;
    }
    const existingItemsIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    const etag = await this.tidalApi.loadPaginatedPlaylistItems(collection.rawId, async (tracks) => {
      existingItemsIds.push(...tracks.map((i) => i.rawId));
    });
    const indexesToRemove = existingItemsIds.map((_item, index) => index);
    await this.tidalApi.removeTracksByIndicesFromPlaylist(collection.rawId, indexesToRemove, etag);
  }

  async matchItems(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToStringWithoutAlbum(queryProps);
    const { tracks } = await this.tidalApi.search({ query, searchType: 'TRACKS' });
    return tracks;
  }

  async matchAlbums(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const { albums } = await this.tidalApi.search({ query, searchType: 'ALBUMS' });
    return albums;
  }

  async addAlbumToLibrary(album: GenericCollection): Promise<void> {
    await this.tidalApi.addAlbumToLibrary(album.rawId);
  }

  async updateCollection(
    collection: GenericCollection,
    props: { name?: string; access?: CollectionAccess; description?: string }
  ) {
    await this.tidalApi.updatePlaylist(collection.rawId, { title: props.name, description: props.description });
  }

  async removeCollection(collection: GenericCollection): Promise<void> {
    await this.tidalApi.removePlaylist(collection.rawId);
  }

  async reAuthenticate(
    withData: GenericAuthenticationData,
    forceFetchRefreshTokens?: boolean
  ): Promise<GenericAuthenticationData> {
    return refreshAuthData({
      authId: withData.authId,
      userUUID: withData.additionalData?.userUUID ?? null,
      integrationId: MusicAPIIntegrationID.Tidal,
      forceFetchRefreshTokens,
    });
  }

  doesSupportReAuth(): boolean {
    return true;
  }

  public doesSupportAlbums(): boolean {
    return true;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return true;
  }

  public doesSupportSearchByISRC(): boolean {
    return false;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return true;
  }

  public doesSupportMovingManyItems(): boolean {
    return true;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }

  public doesSupportAddingItemsToLikedSongs(): boolean {
    return true;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    switch (forCollection.type) {
      case CollectionType.MY_SONGS:
        return this.tidalApi.loadPaginatedFavouriteTracks(onBatch);
      case CollectionType.PLAYLIST:
      case CollectionType.LIKED_PLAYLIST:
        await this.tidalApi.loadPaginatedPlaylistItems(forCollection.rawId, onBatch);
        return undefined;
      case CollectionType.ALBUM:
        await onBatch((await this.tidalApi.loadAlbum(forCollection.rawId)).tracks);
        return undefined;
      default:
        return undefined;
    }
  }

  async getCollectionPublicUrl(collection: GenericCollection) {
    return Promise.resolve(`https://tidal.com/browse/playlist/${collection.rawId}`);
  }
};
