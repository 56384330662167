import { YouTubeCollectionTrack } from './YouTubeCollectionTrack';

export class YouTubeTracksResponse {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get tracks(): YouTubeCollectionTrack[] {
    if (!this.data?.items) {
      return [];
    }
    return this.data.items
      .map((item: any) => YouTubeCollectionTrack.fromData(item))
      .filter((track: YouTubeCollectionTrack | null) => !!track);
  }

  get nextPageToken(): string | undefined {
    return this.data?.nextPageToken;
  }

  get total(): number | undefined {
    return this.data?.pageInfo.totalResults;
  }
}
