import { CollectionsPickersState } from '../slices/collectionsPickers/state';
import { collectionsPickersSliceName } from '../slices/collectionsPickers/constants';
import { appInfoSliceName } from '../slices/appInfo/constants';
import { AppInfoState } from '../slices/appInfo/state';
import { statsSliceName } from '../slices/stats/constants';
import { StatsState } from '../slices/stats/state';
import { licenseSliceName } from '../slices/license/constants';
import { LicenseState } from '../slices/license/state';
import { alertSliceName } from '../slices/alerts/constants';
import { AlertState } from '../slices/alerts/state';
import { transferCollectionsSliceName } from '../slices/transferCollections/constants';
import { TransferCollectionsState } from '../slices/transferCollections/state';
import { routingSliceName } from '../slices/routing/constants';
import { RoutingState } from '../slices/routing/state';

export const RootState = {
  [appInfoSliceName]: AppInfoState,
  [collectionsPickersSliceName]: CollectionsPickersState,
  [statsSliceName]: StatsState,
  [licenseSliceName]: LicenseState,
  [alertSliceName]: AlertState,
  [transferCollectionsSliceName]: TransferCollectionsState,
  [routingSliceName]: RoutingState,
};
