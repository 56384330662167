import { VKMatchedTrack } from './VKMatchedTrack';

export class VKSearchResponse {
  private readonly data;

  constructor(data: Record<string, any> | null) {
    this.data = data;
  }

  get tracks(): VKMatchedTrack[] {
    return this.data?.payload[1][0].list.map((trackData: any) => {
      return VKMatchedTrack.fromData(trackData);
    });
  }

  get firstTrack(): VKMatchedTrack | undefined {
    if (this.tracks.length < 1) {
      return undefined;
    }
    return this.tracks[0];
  }
}
