import { RecentlyAddedResourcesResponse } from './types';
import { AppleMusicCollectionTrack } from './AppleMusicCollectionTrack';
import { AppleMusicPlaylist } from './AppleMusicPlaylist';
import { AppleMusicAlbum } from './AppleMusicAlbum';

export class AppleMusicRecentlyAddedResourcesResponse {
  private readonly data;

  constructor(data: RecentlyAddedResourcesResponse | undefined) {
    this.data = data;
  }

  get resources() {
    return (
      this.data?.data
        ?.map((_data) => {
          if (_data.type === 'library-albums') {
            return AppleMusicAlbum.fromData(_data);
          }
          if (_data.type === 'library-playlists') {
            return AppleMusicPlaylist.fromData(_data);
          }
          return AppleMusicCollectionTrack.fromData(_data);
        })
        .filter(
          (resource): resource is AppleMusicCollectionTrack | AppleMusicAlbum | AppleMusicPlaylist => !!resource
        ) ?? []
    );
  }
}
