import { CollectionType } from '../../../generics/models/Collection';
import { YandexPlaylist } from './YandexPlaylist';

export class YandexPlaylistsResponse {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get playlists(): YandexPlaylist[] {
    return this.data.result.map((item: any) => YandexPlaylist.fromData(item, CollectionType.PLAYLIST));
  }

  get likedPlaylists(): YandexPlaylist[] {
    return this.data.result.map((item: any) => YandexPlaylist.fromData(item.playlist, CollectionType.LIKED_PLAYLIST));
  }
}
