import messagesDE from './locales/de.json';
import messagesEN from './locales/en.json';
import messagesES from './locales/es.json';
import messagesFR from './locales/fr.json';
import messagesIT from './locales/it.json';
import messagesNL from './locales/nl.json';
import messagesPL from './locales/pl.json';
import messagesPT from './locales/pt.json';
import messagesSV from './locales/sv.json';

interface Translation {
  [key: string]: string;
}

interface Translations {
  [locale: string]: Translation;
}

export const translations: Translations = {
  de: messagesDE,
  en: messagesEN,
  es: messagesES,
  fr: messagesFR,
  it: messagesIT,
  nl: messagesNL,
  pl: messagesPL,
  pt: messagesPT,
  sv: messagesSV,
};
