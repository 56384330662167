import { CollectionItemModelData, GenericCollectionItem } from '../../../generics/models/GenericCollectionItem';
import { RessoAlbum } from './RessoAlbum';
import { RessoTrackRawResponse } from './RessoResponseModels';
import { validateISRC } from '../../../utils/validateISRC';
import { RessoArtist } from './RessoArtist';
import { getRessoArtistsNamesAsString } from '../utils/getRessoArtistsNamesAsString';
import { convertRessoTrackData } from '../utils/convertRessoTrackData';

export class RessoCollectionTrack extends GenericCollectionItem {
  constructor(
    data: CollectionItemModelData,
    public albumExtended: RessoAlbum | null = null,
    public artists: RessoArtist[] | null = null
  ) {
    super(data);
  }

  static fromData(data: RessoTrackRawResponse | undefined): RessoCollectionTrack | null {
    const trackData = convertRessoTrackData(data);
    if (!trackData) {
      return null;
    }
    const { track, album, artists } = trackData;
    return new RessoCollectionTrack({ ...track, globalId: track.rawId }, album, artists);
  }

  static fromAlbumData(data: RessoTrackRawResponse | undefined, album: RessoAlbum): RessoCollectionTrack | null {
    if (!data?.id || !data.name) {
      return null;
    }
    return new RessoCollectionTrack({
      rawId: data.id,
      globalId: data.id,
      name: data.name,
      album: album.name,
      artist: getRessoArtistsNamesAsString(data.artists),
      imageUrl: album.additionalData?.imageUrl,
      isrc: validateISRC(data?.isrc),
    });
  }
}
