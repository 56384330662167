import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal } from 'reactstrap';
import { TypedMemo } from '../../common/types';
import { PaddleUpgradeViewRendererProps } from './types';
import { LoadingIcon } from '../../common/views/LoadingIcon';

const PaddleUpgradeViewRendererComponent: React.FC<PaddleUpgradeViewRendererProps> = ({
  isLoading,
  isShown,
  isError,
  onClose,
  onUpgrade,
}) => {
  const intl = useIntl();
  return (
    <Modal isOpen={isShown} toggle={onClose} className="confirmation-modal-container" centered>
      <div className="confirmation-modal-content py-5">
        <div className="mb-5">
          <h3 className="text-center px-3">
            {intl.formatMessage({
              defaultMessage: 'Almost there...',
              id: 'app.payments.paddle-upgrade.title',
            })}
          </h3>
          <p className="text-center px-2">
            {intl.formatMessage({
              defaultMessage:
                'We will charge your card the difference between what you paid for the current plan and the selected one.',
              id: 'app.payments.paddle-upgrade.description',
            })}
          </p>
        </div>
        <div className="d-flex justify-content-around">
          <Button color="primary" onClick={onUpgrade} disabled={isLoading}>
            <FormattedMessage id="app.payments.paddle-upgrade.change-plan" defaultMessage="CHANGE MY PLAN" />
          </Button>
        </div>
        {isLoading && (
          <div className="text-center mt-5">
            <LoadingIcon />
          </div>
        )}
        {isError && (
          <div className="text-center text-danger mt-5">
            {intl.formatMessage({
              id: 'app.stats.details.had-some-problems',
              defaultMessage: 'We had some problems fetching your data, please try again',
            })}
          </div>
        )}
      </div>
    </Modal>
  );
};

export const PaddleUpgradeViewRenderer = TypedMemo(PaddleUpgradeViewRendererComponent);
