import { NapsterAlbum } from './NapsterAlbum';

export class NapsterAlbumsResponse {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get albums(): NapsterAlbum[] {
    if (!this.data?.albums) {
      return [];
    }
    return this.data.albums
      .map((item: any) => NapsterAlbum.fromData(item))
      .filter((album: NapsterAlbum | null) => !!album);
  }

  get totalCount(): number {
    return this.data.meta.totalCount;
  }
}
