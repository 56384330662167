import { ReactComponent as RessoLight } from '../../../../assets/images/music-services/resso-light.svg';
import { ReactComponent as RessoDark } from '../../../../assets/images/music-services/resso-dark.svg';
import { ReactComponent as RessoLightPadding } from '../../../../assets/images/music-services/resso-light-padding.svg';
import { ReactComponent as RessoDarkPadding } from '../../../../assets/images/music-services/resso-dark-padding.svg';
import { ReactComponent as RessoColorLight } from '../../../../assets/images/music-services/resso-color-light.svg';
import { ReactComponent as RessoColorDark } from '../../../../assets/images/music-services/resso-color-dark.svg';
import { ReactComponent as RessoColorLightPadding } from '../../../../assets/images/music-services/resso-color-light-padding.svg';
import { ReactComponent as RessoColorDarkPadding } from '../../../../assets/images/music-services/resso-color-dark-padding.svg';
import { ReactComponent as RessoDarkDanger } from '../../../../assets/images/music-services-danger/resso-dark-danger.svg';
import { ReactComponent as RessoLightDanger } from '../../../../assets/images/music-services-danger/resso-light-danger.svg';
import { ReactComponent as RessoLightDangerPadding } from '../../../../assets/images/music-services-danger/resso-light-padding-danger.svg';
import { ReactComponent as RessoDarkDangerPadding } from '../../../../assets/images/music-services-danger/resso-dark-padding-danger.svg';
import { ReactComponent as RessoDarkWarning } from '../../../../assets/images/music-services-warning/resso-dark-warning.svg';
import { ReactComponent as RessoLightWarning } from '../../../../assets/images/music-services-warning/resso-light-warning.svg';
import { ReactComponent as RessoLightWarningPadding } from '../../../../assets/images/music-services-warning/resso-light-padding-warning.svg';
import { ReactComponent as RessoDarkWarningPadding } from '../../../../assets/images/music-services-warning/resso-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const ressoImages: ImporterImages = {
  logo: {
    light: RessoLight,
    dark: RessoDark,
  },
  logoPadding: {
    light: RessoLightPadding,
    dark: RessoDarkPadding,
  },
  logoColor: {
    light: RessoColorLight,
    dark: RessoColorDark,
  },
  logoColorPadding: {
    light: RessoColorLightPadding,
    dark: RessoColorDarkPadding,
  },
  logoOnBackground: RessoDark,
  logoDanger: {
    dark: RessoDarkDanger,
    light: RessoLightDanger,
  },
  logoDangerPadding: {
    dark: RessoDarkDangerPadding,
    light: RessoLightDangerPadding,
  },
  logoWarning: {
    dark: RessoDarkWarning,
    light: RessoLightWarning,
  },
  logoWarningPadding: {
    light: RessoLightWarningPadding,
    dark: RessoDarkWarningPadding,
  },
};
