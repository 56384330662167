import { GenericAuthor } from '../../../generics/models/GenericAuthor';
import { SpotifyArtistResponseType } from './types';

export class SpotifyArtist extends GenericAuthor {
  static fromData(data: SpotifyArtistResponseType | undefined): SpotifyArtist | null {
    const { name, id, uri, followers, images, external_urls } = data ?? {};
    const rawId = id ?? uri?.split('spotify:artist:')[1];
    if (!name || !rawId) {
      return null;
    }
    return new SpotifyArtist({
      rawId,
      name,
      followers: followers?.total,
      imageUrl: images?.[0]?.url,
      publicUrl: external_urls?.spotify,
    });
  }

  static fromShareUrlData(data: any, artistId: string): SpotifyArtist | null {
    const name = data?.profile?.name;
    if (!name) {
      return null;
    }
    return new SpotifyArtist({
      rawId: data.id ?? artistId,
      name,
      followers: data.stats?.followers,
      imageUrl: data.visuals?.avatarImage?.sources?.[0]?.url,
      publicUrl: data.sharingInfo?.shareUrl?.split('?')[0],
    });
  }
}
