import { RecentlyPlayedTracksResponse } from './types';
import { AmazonMusicCollectionTrack } from './AmazonMusicCollectionTrack';

export class AmazonMusicRecentlyPlayedTracksResponse {
  private readonly data;

  constructor(data: RecentlyPlayedTracksResponse | undefined) {
    this.data = data;
  }

  get tracks() {
    return (
      this.data?.data?.user?.recentTrackPlayback?.edges
        ?.map((edge) =>
          AmazonMusicCollectionTrack.fromData(edge?.node, { cursor: edge?.cursor, playedAt: edge?.playedAt })
        )
        .filter((track): track is AmazonMusicCollectionTrack => !!track) ?? []
    );
  }

  get pageInfoToken() {
    return this.data?.data?.user?.recentTrackPlayback?.pageInfo?.token;
  }

  get hasNextPage() {
    return this.data?.data?.user?.recentTrackPlayback?.pageInfo?.hasNextPage;
  }
}
