import React from 'react';
import { TypedMemo } from '../../common/types';
import { ErrorAlert } from '../../common/views/ErrorAlert/ErrorAlert';
import { SMLOnboardingFormStep2Props } from './props';
import { useSMLOnboardingFormStep2Component } from './useSMLOnboardingFormStep2';

const SMLOnboardingFormStep2Component: React.FC<SMLOnboardingFormStep2Props> = ({
  account,
  onSubmit,
  isLoading,
  error,
  onSkip,
}) => {
  const { AvatarInput, SkipButton, SubmitButton, submit } = useSMLOnboardingFormStep2Component({
    account,
    onSubmit,
    isLoading,
    onSkip,
  });

  return (
    <>
      {error && <ErrorAlert error={error} />}
      <form className="d-flex flex-column" onSubmit={submit}>
        {AvatarInput}
        <div className="d-flex justify-content-between">
          {SkipButton}
          {SubmitButton}
        </div>
      </form>
    </>
  );
};

export const SMLOnboardingFormStep2 = TypedMemo(SMLOnboardingFormStep2Component);
