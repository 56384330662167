import { RecentlyPlayedTracksResponse } from './types';
import { AppleMusicMatchedTrack } from './AppleMusicMatchedTrack';

export class AppleMusicRecentlyPlayedTracksResponse {
  private readonly data;

  constructor(data: RecentlyPlayedTracksResponse | undefined) {
    this.data = data;
  }

  get tracks() {
    return (
      this.data?.data
        ?.map((_data) => AppleMusicMatchedTrack.fromData(_data))
        .filter((track): track is AppleMusicMatchedTrack => !!track) ?? []
    );
  }

  get hasNextPage() {
    return this.data?.next;
  }
}
