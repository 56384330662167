import { GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { tryParseInt } from '../../../utils/tryParseInt';
import { createGaanaPlaylistUrl } from '../utils';

export class GaanaPlaylist extends GenericCollection {
  static fromData(data: any, type: CollectionType): GaanaPlaylist | null {
    if ((!data.playlist_id && !data.seokey) || !data.title) {
      return null;
    }
    return new GaanaPlaylist({
      type,
      rawId: `${data.playlist_id}|${data.seokey}`,
      name: data.title,
      itemCount: tryParseInt(data.trackcount),
      followers: tryParseInt(data.favorite_count),
      publicUrl: createGaanaPlaylistUrl(data.seokey),
    });
  }

  static fromShareURLData(data: any, url: string): GaanaPlaylist | null {
    if ((!data?.playlist?.playlist_id && !data.playlist.seokey) || !data.playlist?.title) {
      return null;
    }
    return new GaanaPlaylist({
      type: CollectionType.LIKED_PLAYLIST,
      rawId: `${data.playlist.playlist_id}|${data.playlist.seokey}`,
      name: data.playlist.title,
      itemCount: tryParseInt(data.count) ?? data.tracks?.length,
      followers: tryParseInt(data.favorite_count),
      publicUrl: url ?? createGaanaPlaylistUrl(data.playlist.seokey),
    });
  }
}
