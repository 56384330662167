import qs from 'qs';
import { SearchQueryProperties } from '../../generics/types';
import { convertQueryPropsToString } from '../services/MatchingService.helpers';
import { artistsToString } from '../../utils/artistsConverter';
import { DeezerAlbumRaw } from './rawTypes/DeezerAlbumRaw';
import { DeezerArtistRaw } from './rawTypes/DeezerArtistRaw';
// eslint-disable-next-line import/no-cycle
import { DeezerArtist } from './models/DeezerArtist';
import { phpURLEncode } from '../../utils/phpURLEncode';
import { DeezerTrackRaw } from './rawTypes/DeezerTrackRaw';

export function buildCoverUrl(albumPicture: string) {
  return `https://e-cdns-images.dzcdn.net/images/cover/${albumPicture}/528x528-000000-80-0-0.jpg`;
}

export function constructSearchQuery(queryProps: SearchQueryProperties, advancedSearch: boolean) {
  if (!advancedSearch) {
    return encodeURIComponent(convertQueryPropsToString(queryProps));
  }

  const { track, artist, album } = queryProps;
  const queryParts: string[] = [];

  if (track) {
    queryParts.push(`track:"${track}"`);
  }
  if (artist) {
    queryParts.push(`artist:"${artist}"`);
  }
  if (album) {
    queryParts.push(`album:"${album}"`);
  }
  return queryParts.join(' ');
}

export function getArtistsNamesAsString(contributors: DeezerAlbumRaw['contributors']) {
  if (!contributors || contributors.length === 0) {
    return undefined;
  }
  const artistsNames = contributors.reduce<string[]>((res, contributor) => {
    return contributor.name ? [...res, contributor.name] : res;
  }, []);
  return artistsToString(artistsNames);
}

type Artist = {
  ART_ID: string;
  ART_NAME?: string;
  ROLE_ID?: string;
  ARTISTS_SONGS_ORDER?: string;
  ARTIST_IS_DUMMY?: false;
  ART_PICTURE?: string;
  RANK?: string;
  LOCALES?: any;
  SMARTRADIO?: number;
  __TYPE__?: string;
};

export function getArtistsNamesAsStringFromSharedUrl(artists: Artist[] | undefined, defaultValue: string) {
  if (!artists || artists.length === 0) {
    return defaultValue;
  }

  const artistsNames = artists.reduce((result, artist) => {
    if (artist.ART_NAME) {
      result.push(artist.ART_NAME);
    }
    return result;
  }, [] as string[]);

  return artistsToString(artistsNames);
}

const deezerPublicResourceUrl = 'https://www.deezer.com';

export function createDeezerAlbumUrl(albumId: string) {
  return `${deezerPublicResourceUrl}/album/${albumId}`;
}

export function createDeezerPlaylistUrl(playlistId: string) {
  return `${deezerPublicResourceUrl}/playlist/${playlistId}`;
}

export function createDeezerArtistUrl(artistId: string) {
  return `${deezerPublicResourceUrl}/artist/${artistId}`;
}

export function createDeezerTrackUrl(trackId: string) {
  return `${deezerPublicResourceUrl}/track/${trackId}`;
}

export function getArtistsForAlbum(
  contributors: DeezerAlbumRaw['contributors'],
  artistRaw: DeezerArtistRaw | undefined
): DeezerArtist[] | null {
  const artistsFromContributors =
    contributors?.reduce<DeezerArtist[]>((results, contributor) => {
      const artist = DeezerArtist.fromData(contributor);
      return artist ? [...results, artist] : results;
    }, []) ?? null;

  if ((!artistsFromContributors || artistsFromContributors.length === 0) && artistRaw) {
    const artistFromRawData = DeezerArtist.fromData(artistRaw);
    return artistFromRawData ? [artistFromRawData] : null;
  }
  return artistsFromContributors;
}

export function convertDeezerId(id: number | undefined | null) {
  return id === 0 || id === undefined || id === null ? null : `${id}`;
}

export function customStringify(obj: Record<string, unknown>) {
  return qs.stringify(obj, { encoder: phpURLEncode });
}

export function convertDeezerTrackData(data: DeezerTrackRaw) {
  const rawId = convertDeezerId(data.id);
  const name = `${data.title}` || `${data.title_short}`;
  if (!rawId || !name) {
    return null;
  }
  return {
    rawId,
    name,
    album: data.album?.title,
    artist: data.artist?.name,
    imageUrl: data.album?.cover,
    duration: data.duration !== undefined ? data.duration * 1000 : undefined,
    previewUrl: data.preview,
    sourceURL: data.link,
    position: data.track_position,
  };
}
