import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { TypedMemo } from '../../common/types';
import { AccountStatsViewRendererProps } from './props';
import { LoadingIcon } from '../../common/views/LoadingIcon';
import { SMLStats } from '../SMLStats/SMLStats';

const AccountStatsViewRendererComponent: React.FC<AccountStatsViewRendererProps> = ({
  stats,
  onMainStatsClick,
  isLoading,
}) => {
  const intl = useIntl();
  return (
    <>
      <Row>
        <Col>
          <h3>{intl.formatMessage({ id: 'app.smart_link.account.stats.title', defaultMessage: 'Stats' })}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="ui-text">
            {intl.formatMessage({
              id: 'app.smart_link.account.stats.description',
              defaultMessage: 'See how popular your Smart Links are',
            })}
          </p>
        </Col>
      </Row>
      {!isLoading && stats ? (
        <SMLStats onStatsClick={onMainStatsClick} pageViews={stats.stats.pageViews} uniques={stats.stats.uniques} />
      ) : (
        <Row className="justify-content-center">
          <LoadingIcon />
        </Row>
      )}
    </>
  );
};
export const AccountStatsViewRenderer = TypedMemo(AccountStatsViewRendererComponent);
