import { SpotifyPlaylist } from './SpotifyPlaylist';
import { SpotifyPlaylistsResponseType } from './types';

export class SpotifyPlaylistsResponse {
  private readonly data;

  private readonly userId;

  constructor(data: SpotifyPlaylistsResponseType | undefined, userId: string | undefined) {
    this.data = data;
    this.userId = userId;
  }

  get playlists(): SpotifyPlaylist[] {
    if (!this.data?.items) {
      return [];
    }
    return this.data.items
      .map((item) => SpotifyPlaylist.fromData(item, this.userId))
      .filter((playlist): playlist is SpotifyPlaylist => !!playlist);
  }

  get next() {
    return this.data?.next;
  }

  get total(): number | undefined {
    return this.data?.total;
  }
}
