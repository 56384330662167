import { AppleMusicAlbum } from './AppleMusicAlbum';
import { AppleMusicAlbumsRawResponse } from './types';

export class AppleMusicAlbumsResponse {
  private readonly data;

  constructor(data: AppleMusicAlbumsRawResponse | undefined) {
    this.data = data;
  }

  get albums(): AppleMusicAlbum[] {
    if (!this.data?.data) {
      return [];
    }
    return this.data.data
      .map((item) => AppleMusicAlbum.fromData(item))
      .filter((album): album is AppleMusicAlbum => !!album);
  }

  get next(): string | undefined {
    return this.data?.next;
  }
}
