import { GaanaPlaylist } from './GaanaPlaylist';
import { tryParseInt } from '../../../utils/tryParseInt';
import { CollectionType } from '../../../generics/models/Collection';

export class GaanaPlaylistsResponse {
  private readonly data;

  public readonly geoLocation;

  private readonly type;

  constructor(data: any, type: string, geoLocation: string) {
    this.data = data;
    this.geoLocation = geoLocation;
    this.type = type;
  }

  get playlists(): GaanaPlaylist[] {
    if (!this.data?.playlist) {
      return [];
    }
    const pType = this.type === 'myFavPlaylists' ? CollectionType.LIKED_PLAYLIST : CollectionType.PLAYLIST;
    return this.data.playlist
      .map((item: any) => GaanaPlaylist.fromData(item, pType))
      .filter((playlist: GaanaPlaylist | null) => !!playlist);
  }

  get total(): number {
    return tryParseInt(this.data.count) ?? 0;
  }
}
