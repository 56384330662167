import { Badge } from 'reactstrap';
import React from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { TypedMemo } from '../../common/types';
import { SmartLinksDraftBadgeProps } from './props';

const SmartLinksDraftBadgeComponent: React.FC<SmartLinksDraftBadgeProps> = ({ size = 'sm' }) => {
  const intl = useIntl();
  return (
    <div className="sml-draft-badge-container">
      <Badge className={classnames('sml-draft-badge', { 'sml-draft-badge-lg': size === 'lg' })} color="transparent">
        {intl.formatMessage({ id: 'app.smart_link.badge.draft', defaultMessage: 'DRAFT' })}
      </Badge>
    </div>
  );
};

export const SmartLinksDraftBadge = TypedMemo(SmartLinksDraftBadgeComponent);
