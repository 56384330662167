import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useStore } from 'react-redux';
import { useForm } from 'react-hook-form';
import { SMLSlugTakenModalFormProps } from './props';
import { TypedMemo } from '../../../types';
import { RootStateType } from '../../../../../redux/store';
import { SMLRoutes } from '../../../../../routing/sml/SMLRoutes';
import { FormInput } from '../../../../forms/components/FormInput/FormInput';
import { InputType } from '../../../../forms/types';
import { removeHttpAndSlash } from '../../../../smartLinks/utils/removeHttpRegex';
import { SMLFormContainer } from '../../../../smartLinks/SMLFormContainer/SMLFormContainer';
import { Spacer } from '../../spacer/Spacer';
import { createResolver } from './validators';
import { FieldsName, FieldsType } from './consts';
import { SMLModal } from '../SMLModal/SMLModal';
import { boldIntlText } from '../../bold/boldIntlText';

const SMLSlugTakenModalFormComponent: React.FC<SMLSlugTakenModalFormProps> = ({
  isOpen,
  closeModal,
  accountId,
  smartLink,
  onSubmit,
  error,
  isLoading,
}) => {
  const intl = useIntl();
  const store = useStore<RootStateType>();
  const resolver = useMemo(
    () => createResolver(store, accountId, smartLink?.id, smartLink?.isMainSmartLink),
    [store, accountId, smartLink?.id, smartLink?.isMainSmartLink]
  );
  const { control, handleSubmit, watch, trigger } = useForm<FieldsType>({
    resolver,
    defaultValues: {
      [FieldsName.slug]: smartLink?.recentSmartLinkVersion?.slug ?? '',
    },
  });

  useEffect(() => {
    trigger().catch(console.error);
  }, [trigger]);

  const slugWatch = watch(FieldsName.slug);

  const submit = useCallback(
    (event?: React.BaseSyntheticEvent) => {
      handleSubmit(onSubmit)(event).catch(console.error);
    },
    [handleSubmit, onSubmit]
  );

  return (
    <SMLModal isOpen={isOpen} onClosed={closeModal} size="md" customModal>
      <SMLFormContainer
        backRoute={SMLRoutes.details}
        submitButtonTitle={intl.formatMessage({
          id: 'app.forms.buttons.change-save',
          defaultMessage: 'Change and Save',
        })}
        title={intl.formatMessage({
          id: 'app.forms.title.slug-taken',
          defaultMessage: 'This slug is already taken',
        })}
        onSubmit={submit}
        error={error}
        isLoading={isLoading}
        submitDisabled={isLoading}
        closeModal={closeModal}
      >
        <Spacer flex flexDirection="column">
          <FormInput
            name={FieldsName.slug}
            control={control}
            type={InputType.text}
            readonly={smartLink?.isMainSmartLink === true}
            label={intl.formatMessage({
              id: 'app.forms.smartLink.slug',
              defaultMessage: 'Slug',
            })}
            help={intl.formatMessage(
              {
                id: 'app.forms.smartLink.help.slug',
                defaultMessage: 'The short name that will be used as part of the url <bold>{accountSlug}/{slug}</bold>',
              },
              {
                accountSlug: removeHttpAndSlash('slug.fym.fm'),
                slug: slugWatch !== '' ? slugWatch : '<slug>',
                bold: boldIntlText,
              }
            )}
          />
        </Spacer>
      </SMLFormContainer>
    </SMLModal>
  );
};

export const SMLSlugTakenModalForm = TypedMemo(SMLSlugTakenModalFormComponent);
