import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { faArrowUpFromArc } from '@fortawesome/pro-solid-svg-icons/faArrowUpFromArc';
import { FYMAPIError } from 'fym-common/src/generics/errors/FYMAPIError';
import { InternalErrorCode } from 'fym-common/src/errors/internalErrorCodes';
import { TypedMemo } from '../../common/types';
import { usePublishSmartLinkMutation } from '../../../redux/api/fymAPI';
import { SMLButton } from '../../common/views/SMLButton/SMLButton';
import { isSuccessfulResult } from '../../../redux/api/utils/isSuccessfulResult';

interface SmartLinkDetailsPublishButtonProps {
  accountId: number;
  smartLinkId: number;
  openModal: () => void;
  openSlugTakenModal: () => void;
  numberOfWidgets: number;
}

const SmartLinkDetailsPublishButtonComponent: React.FC<SmartLinkDetailsPublishButtonProps> = ({
  accountId,
  smartLinkId,
  openModal,
  openSlugTakenModal,
  numberOfWidgets,
}) => {
  const intl = useIntl();
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(true);
  const [publishSmartLink, { isLoading, error }] = usePublishSmartLinkMutation();
  const prevNumberOfWidgets = useRef(numberOfWidgets);
  const [tooltipText, setTooltipText] = useState<string | undefined>();

  const onPublish = useCallback(() => {
    publishSmartLink({ smartLinkId, accountId })
      .then((result) => {
        if (!isSuccessfulResult(result)) {
          const { internalCode } = result.error as FYMAPIError;
          if (internalCode === InternalErrorCode.SmartLinkSlugIsTaken) {
            setIsErrorVisible(false);
            openSlugTakenModal();
          } else {
            setIsErrorVisible(true);
          }
        } else {
          openModal();
        }
      })
      .catch(console.error);
  }, [publishSmartLink, smartLinkId, accountId, openModal, openSlugTakenModal]);

  useEffect(() => {
    if (prevNumberOfWidgets.current === 0 && numberOfWidgets > 0) {
      setTooltipText(
        intl.formatMessage({
          id: 'app.forms.tooltip.publish',
          defaultMessage: `If you're ready - you can publish your Smart Link now`,
        })
      );
    }
    prevNumberOfWidgets.current = numberOfWidgets;
  }, [intl, numberOfWidgets]);

  return (
    <SMLButton
      leftIcon={faArrowUpFromArc}
      color="primary"
      onClick={onPublish}
      isLoading={isLoading}
      error={isErrorVisible ? error : undefined}
      tooltip={tooltipText}
    >
      {intl.formatMessage({
        id: 'app.forms.buttons.publish',
        defaultMessage: 'publish',
      })}
    </SMLButton>
  );
};

export const SmartLinkDetailsPublishButton = TypedMemo(SmartLinkDetailsPublishButtonComponent);
