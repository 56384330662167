import { NapsterPlaylist } from './NapsterPlaylist';
import { CollectionType } from '../../../generics/models/Collection';

export class NapsterPlaylistsResponse {
  private readonly data: {
    playlists: any[];
    meta: {
      totalCount: number;
    };
  };

  constructor(data: any) {
    this.data = data;
  }

  get playlists(): NapsterPlaylist[] {
    return this.data.playlists
      .map((item: any) => NapsterPlaylist.fromData(item, CollectionType.PLAYLIST))
      .filter((item: any): item is NapsterPlaylist => !!item);
  }

  get totalCount(): number {
    return this.data.meta.totalCount;
  }
}
