import qs from 'qs';
import { v4 as uuid4 } from 'uuid';
import { defaultRetryOptions, fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { FetchError } from '../../generics/errors/FetchError';
import { QQMusicPlaylistsResponse } from './models/QQMusicPlaylistsResponse';
import { QQMusicSearchResponse } from './models/QQMusicSearchResponse';
import { QQMusicAuthenticationData } from './QQMusicAuthenticationData';
import { QQMusicUser } from './models/QQMusicUser';
import { QQMusicRefreshTokenResponse } from './models/QQMusicRefreshTokenResponse';
import { QQMusicLikedPlaylistsResponse } from './models/QQMusicLikedPlaylistsResponse';
import { QQMusicPlaylistWithTracksResponse } from './models/QQMusicPlaylistWithTracksResponse';
import { QQMusicPlaylist } from './models/QQMusicPlaylist';
import { CollectionType } from '../../generics/models/Collection';
import { ImporterID } from '../types';
import { tryParseInt } from '../../utils/tryParseInt';

export class QQMusicAPI {
  public static LOGIN_URL = 'https://y.qq.com/portal/profile.html';

  private static BASE_URL = 'https://c.y.qq.com/';

  private readonly cookies: { [key: string]: string };

  public readonly userId: string;

  private readonly acsrfToken: string;

  constructor(cookies: { [key: string]: string }, userId: string, acsrfToken: string) {
    this.cookies = cookies;
    this.acsrfToken = acsrfToken;
    this.userId = userId;
  }

  private get requestHeaders(): { [key: string]: string } {
    return {
      Accept: 'application/json; charset=utf-8',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Cookie: Object.keys(this.cookies)
        .map((key) => {
          return `${key}=${this.cookies[key]}`;
        })
        .join('; '),
      Referer: 'https://y.qq.com/',
    };
  }

  private getUrlParams(params: { [key: string]: any } = {}) {
    return qs.stringify({
      loginUin: this.userId,
      uin: this.userId,
      hostUin: 0,
      inCharset: 'utf8',
      outCharset: 'utf8',
      format: 'json',
      g_tk: this.acsrfToken,
      g_tk_new_20200303: this.acsrfToken,
      ...params,
    });
  }

  async fetch(url: string, options: any, retryOptions: RetryOptions = defaultRetryOptions): Promise<any> {
    const response = await fetchRetry(url, options, retryOptions);

    const text = (await response.text()).replace(/:([0-9]{1,}\.{0,1}[0-9]{0,})/g, ':"$1"');
    const data = JSON.parse(text);
    if (['4', '1', '1000', '1001'].indexOf(data.code) !== -1) {
      throw new NotAuthenticatedError({ authId: this.userId, importerId: ImporterID.QQMusic }, text);
    }
    if (data.code !== '0') {
      throw new FetchError(tryParseInt(data.code), data.message ?? data.msg);
    }
    return data;
  }

  async fetchMe(): Promise<QQMusicUser> {
    return this.fetchUser('0');
  }

  async fetchUser(userId: string): Promise<QQMusicUser> {
    const jsonBody = await this.fetch(
      `${QQMusicAPI.BASE_URL}rsc/fcgi-bin/fcg_get_profile_homepage.fcg?${this.getUrlParams({
        reqfrom: '1',
        reqtype: '0',
        cid: 205360838,
        userid: userId,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders,
      }
    );

    return new QQMusicUser(jsonBody);
  }

  async search(query: string): Promise<QQMusicSearchResponse> {
    if (!query) {
      return new QQMusicSearchResponse(null);
    }
    const jsonBody = await this.fetch(
      `${QQMusicAPI.BASE_URL}soso/fcgi-bin/client_search_cp?${this.getUrlParams({
        w: query,
        ct: 24,
        new_json: 1,
        flag_qc: 0,
        p: 1,
        n: 10,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders,
      }
    );

    return new QQMusicSearchResponse(jsonBody?.data);
  }

  async getPlaylist(playlistId: string): Promise<QQMusicPlaylistWithTracksResponse> {
    const [uin, dirid] = playlistId.split('-');
    const url = `${QQMusicAPI.BASE_URL}splcloud/fcgi-bin/fcg_musiclist_getinfo_cp.fcg?${this.getUrlParams({
      uin,
      dirid,
    })}`;
    const jsonBody = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });
    const collectionType = uin === this.userId ? CollectionType.PLAYLIST : CollectionType.LIKED_PLAYLIST;
    return new QQMusicPlaylistWithTracksResponse(jsonBody, collectionType);
  }

  async loadPaginatedPlaylists(onBatch: (collections: QQMusicPlaylist[]) => Promise<void>): Promise<void> {
    const limit = 40;
    const loadPlaylists = async (offset: number): Promise<void> => {
      const playlistResponse = await this.loadPlaylistPage(offset, limit);
      await onBatch(playlistResponse.playlists);
      const newOffset = offset + limit;
      if (playlistResponse.total !== undefined && newOffset < playlistResponse.total) {
        await loadPlaylists(newOffset);
      }
    };

    await loadPlaylists(0);
  }

  async loadPlaylistPage(offset = 0, limit = 40): Promise<QQMusicPlaylistsResponse> {
    const jsonBody = await this.fetch(
      `${QQMusicAPI.BASE_URL}rsc/fcgi-bin/fcg_user_created_diss?${this.getUrlParams({
        hostuin: this.userId,
        sin: offset,
        size: limit,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders,
      }
    );
    return new QQMusicPlaylistsResponse(jsonBody, this.userId);
  }

  async loadPaginatedLikedPlaylists(onBatch: (collections: QQMusicPlaylist[]) => Promise<void>): Promise<void> {
    const limit = 40;
    const loadLikedPlaylists = async (offset: number): Promise<void> => {
      const likedPlaylistResponse = await this.loadLikedPlaylistPage(offset, limit);
      await onBatch(likedPlaylistResponse.likedPlaylists);
      const newOffset = offset + limit;
      if (likedPlaylistResponse.total !== undefined && newOffset < likedPlaylistResponse.total) {
        await loadLikedPlaylists(newOffset);
      }
    };

    await loadLikedPlaylists(0);
  }

  async loadLikedPlaylistPage(offset = 0, limit = 40): Promise<QQMusicLikedPlaylistsResponse> {
    const url = `${QQMusicAPI.BASE_URL}fav/fcgi-bin/fcg_get_profile_order_asset.fcg?${this.getUrlParams({
      loginUin: this.userId,
      hostUin: this.userId,
      format: 'json',
      inCharset: 'utf8',
      outCharset: 'utf-8',
      notice: 0,
      platform: 'yqq.json',
      needNewCode: 0,
      ct: 20,
      cid: 205360956,
      userid: this.userId,
      reqtype: 3,
      sin: offset,
      ein: limit,
    })}`;
    const jsonBody = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });
    return new QQMusicLikedPlaylistsResponse(jsonBody);
  }

  async createPlaylist(name: string): Promise<QQMusicPlaylist> {
    try {
      const jsonBody = await this.fetch(`${QQMusicAPI.BASE_URL}splcloud/fcgi-bin/create_playlist.fcg`, {
        method: 'POST',
        headers: this.requestHeaders,
        body: this.getUrlParams({
          uin: this.userId,
          name,
        }),
      });

      return new QQMusicPlaylist({
        type: CollectionType.PLAYLIST,
        rawId: `${this.userId}-${jsonBody.dirid}`,
        name,
        itemCount: 0,
      });
    } catch (e) {
      if (e instanceof FetchError) {
        if (e.code === 21) {
          // Duplicated name
          return this.createPlaylist(QQMusicAPI.newPlaylistName(name));
        }
      }
      throw e;
    }
  }

  static newPlaylistName(name: string) {
    return `${name} - ${uuid4().substring(0, 6)}`;
  }

  async addTracksToPlaylist(playlistId: string, trackMids: string[]): Promise<void> {
    const [uin, dirid] = playlistId.split('-');
    await this.fetch(`${QQMusicAPI.BASE_URL}splcloud/fcgi-bin/fcg_music_add2songdir.fcg`, {
      method: 'POST',
      headers: this.requestHeaders,
      body: this.getUrlParams({
        uin,
        dirid,
        midlist: trackMids.join(','),
        typelist: trackMids.map(() => '13').join(','),
        addtype: true,
        formsender: 4,
        r2: 0,
        r3: 1,
      }),
    });
  }

  async removeTracksFromPlaylist(playlistId: string, tracksIds: string[]): Promise<void> {
    if (tracksIds.length === 0) {
      return;
    }
    const [uin, dirid] = playlistId.split('-');
    await this.fetch(`${QQMusicAPI.BASE_URL}qzone/fcg-bin/fcg_music_delbatchsong.fcg`, {
      method: 'POST',
      headers: this.requestHeaders,
      body: this.getUrlParams({
        uin,
        dirid,
        ids: tracksIds.join(','),
        types: tracksIds.map(() => '3').join(','),
        addtype: true,
        formsender: 4,
        flag: 2,
        from: 3,
      }),
    });
  }

  async refreshToken(): Promise<QQMusicRefreshTokenResponse> {
    const jsonBody = await this.fetch(
      `${QQMusicAPI.BASE_URL}base/fcgi-bin/login_get_musickey.fcg?${this.getUrlParams({
        from: 1,
        force_access: 1,
        wxopenid: this.cookies.wxopenid,
        wxrefresh_token: this.cookies.wxrefresh_token,
        musickey: this.cookies.qqmusic_key ?? this.cookies.qm_keyst,
        musicuin: this.userId,
        get_access_token: 1,
        ct: 1001,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders,
      }
    );
    return new QQMusicRefreshTokenResponse(jsonBody);
  }

  getAuthData(user: QQMusicUser): QQMusicAuthenticationData {
    return {
      authId: this.userId,
      userUUID: null,
      title: user.name,
      subTitle: this.userId,
      imageUrl: user.avatarUrl,
      expiresAt: null,
      additionalData: {
        cookies: this.cookies,
        acsrfToken: this.acsrfToken,
      },
    };
  }
}
