import SetCookieParser, { parse as cookieParse, splitCookiesString } from 'set-cookie-parser';
import flatten from 'lodash/flatten';

export function getAllCookies(headers: { get: (name: string) => string | null }): SetCookieParser.Cookie[] {
  const setCookieHeader = headers.get('set-cookie');
  if (!setCookieHeader) {
    return [];
  }
  const setCookies = splitCookiesString(setCookieHeader);
  return flatten(setCookies.map((c) => cookieParse(c)));
}

export function getCookie(
  headers: { get: (name: string) => string | null },
  cookieName: string,
  path?: string
): string | undefined {
  const allCookies = getAllCookies(headers);

  if (allCookies.length === 0) {
    return undefined;
  }
  const foundCookie = allCookies.find((c) => c.name === cookieName && (!path || c.path === path));
  if (!foundCookie) {
    return undefined;
  }
  return foundCookie.value;
}
