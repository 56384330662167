import {
  ClearingIterationCallback,
  GenericImporter,
  GenericImporterClass,
  MatchItemsOptions,
} from '../../generics/GenericImporter';
import { YouTubeAPI } from './YouTubeAPI';
import { CollectionAccess, CollectionType } from '../../generics/models/Collection';
import { YouTubePlaylist } from './models/YouTubePlaylist';
import { FetchError } from '../../generics/errors/FetchError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { SearchQueryProperties } from '../../generics/types';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { convertQueryPropsToString } from '../services/MatchingService.helpers';
import { ImporterID } from '../types';
import { youtube } from '../../musicServices/services/YouTube';
import { refreshAuthData } from '../../musicApi/utils/refreshAuthData';
import { MusicAPIIntegrationID } from '../../musicServices/types';

export const YoutubeImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.Youtube;

  public static musicService = youtube;

  public static shouldIgnoreLevenshteinWhenMatchingAndTakeFirstResult = true;

  public static areTrackEntryIdAndGlobalIdDifferent = true;

  public authenticationData: GenericAuthenticationData;

  private youtubeApi: YouTubeAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData;
    this.youtubeApi = new YouTubeAPI(
      authenticationData.additionalData?.accessToken as string,
      authenticationData.authId
    );
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.youtubeApi = new YouTubeAPI(
      authenticationData.additionalData?.accessToken as string,
      authenticationData.authId
    );
  }

  async getPaginatedCollections(onBatch: (collections: YouTubePlaylist[]) => Promise<void>): Promise<void> {
    await this.youtubeApi.loadPaginatedPlaylists(onBatch);
  }

  async getCollection(collection: GenericCollection): Promise<YouTubePlaylist> {
    const existingPlaylists = await this.youtubeApi.loadPlaylistPage([collection.rawId]);

    const firstPlaylist = existingPlaylists.playlists[0];
    if (firstPlaylist === undefined) {
      throw new CollectionDoesNotExistsError(`Could not get collection [${collection.rawId}] from YouTube`);
    }

    return firstPlaylist;
  }

  async createCollection(collection: GenericCollection, description?: string): Promise<YouTubePlaylist> {
    let newCollection: YouTubePlaylist | null = null;
    try {
      newCollection = await this.youtubeApi.createPlaylist(collection.name, { description });
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!newCollection) {
      throw new CouldNotCreateCollection();
    }
    return newCollection;
  }

  async addItemToCollection(collection: GenericCollection, matchedItem: GenericMatchedItem) {
    const track = await this.youtubeApi.addItemToPlaylist(collection.rawId, matchedItem.rawId);
    if (!track) {
      throw new CollectionDoesNotExistsError();
    }
    return track;
  }

  async moveItem(props: Parameters<NonNullable<GenericImporter['moveItem']>>[0]) {
    const { itemToMove, collection, offset } = props;
    if (!itemToMove.globalId) {
      throw new Error(`Missing globalId`);
    }
    await this.youtubeApi.moveTrackInPlaylist(
      collection.rawId,
      itemToMove.rawId,
      itemToMove.position + offset,
      itemToMove.globalId
    );
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    collectionItems: GenericCollectionItem[],
    callback?: ClearingIterationCallback
  ): Promise<void> {
    if (collection.type !== CollectionType.PLAYLIST || collectionItems.length === 0) {
      return;
    }
    const itemsIds = collectionItems.map((item) => item.rawId);
    let itemIndex = 0;
    for (const itemId of itemsIds) {
      await this.youtubeApi.removeItemFromPlaylist(itemId);
      if (callback) {
        callback(itemIndex, itemsIds.length);
      }
      itemIndex += 1;
    }
  }

  async clearCollection(collection: GenericCollection, callback?: ClearingIterationCallback) {
    const itemsIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    await this.getPaginatedItems(collection, async (items) => {
      itemsIds.push(...items.map((item) => item.rawId));
    });
    let itemIndex = 0;
    for (const itemId of itemsIds) {
      await this.youtubeApi.removeItemFromPlaylist(itemId);
      if (callback) {
        callback(itemIndex, itemsIds.length);
      }
      itemIndex += 1;
    }
  }

  async matchItems(queryProps: SearchQueryProperties, options?: MatchItemsOptions) {
    const query = convertQueryPropsToString({ track: queryProps.track, artist: queryProps.artist });
    const limit = options?.isReMatch === true ? 10 : undefined;
    const { items } = await this.youtubeApi.search({ query, limit });
    return items;
  }

  async reAuthenticate(
    withData: GenericAuthenticationData,
    forceFetchRefreshTokens?: boolean
  ): Promise<GenericAuthenticationData> {
    return refreshAuthData({
      authId: withData.authId,
      userUUID: withData.additionalData?.userUUID ?? null,
      integrationId: MusicAPIIntegrationID.Youtube,
      forceFetchRefreshTokens,
    });
  }

  doesSupportReAuth(): boolean {
    return true;
  }

  public doesSupportAlbums(): boolean {
    return false;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return true;
  }

  public doesSupportSearchByISRC(): boolean {
    return false;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return false;
  }

  public doesSupportMovingManyItems(): boolean {
    return false;
  }

  public doesSupportMovingItem(): boolean {
    return true;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    if (forCollection.type === CollectionType.PLAYLIST) {
      await this.youtubeApi.loadPaginatedPlaylistsItems(forCollection.rawId, onBatch);
    }
  }

  async getCollectionPublicUrl(collection: GenericCollection) {
    const playlistResponse = await this.youtubeApi.updatePlaylist(collection.rawId, {
      title: collection.name,
      isPublic: true,
    });
    if (!playlistResponse) {
      return null;
    }
    return `https://www.youtube.com/playlist?list=${playlistResponse.rawId}`;
  }

  async updateCollection(
    collection: GenericCollection,
    props: { name?: string; access?: CollectionAccess; description?: string }
  ) {
    const { name, access, description } = props;
    const isPublic = access === undefined ? undefined : access === CollectionAccess.public;
    await this.youtubeApi.updatePlaylist(collection.rawId, { title: name, isPublic, description });
  }

  async removeCollection(collection: GenericCollection): Promise<void> {
    await this.youtubeApi.removePlaylist(collection.rawId);
  }
};
