import { faText } from '@fortawesome/pro-solid-svg-icons/faText';
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage';
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons/faGlobe';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { SmartLinkWidgetType } from 'fym-common/src/db/enums';

export function getWidgetIconByWidgetType(type: SmartLinkWidgetType) {
  switch (type) {
    case SmartLinkWidgetType.info:
      return faText;
    case SmartLinkWidgetType.link:
      return faLink;
    case SmartLinkWidgetType.image:
      return faImage;
    case SmartLinkWidgetType.socialLinks:
      return faGlobe;
    case SmartLinkWidgetType.player:
      return faPlay;
    case SmartLinkWidgetType.profile:
      return faUser;
    default:
      throw new Error('Invalid widget type');
  }
}
