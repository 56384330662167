import { MusicService } from './types';
import { ImporterID } from '../../importers/types';
import { MusicAPIIntegrationID } from '../types';
import { LinkType } from '../../utils/recognizeLinkTypeByUrl';

export const anghami: MusicService = {
  id: 'anghami',
  importerID: ImporterID.Anghami,
  linkType: LinkType.anghami,
  musicAPIID: MusicAPIIntegrationID.Anghami,
  shortName: 'Anghami',
  name: 'Anghami',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: true,
  matchingTracksLimit: 1,
  addingTracksLimit: 1,
  color: { label: '#8D00F2', background: '#C483F1' },
};
