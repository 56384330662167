import React, { useCallback, useState, Fragment } from 'react';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { TypedMemo } from '../common/types';
import { FYMButton } from '../common/views/FYMButton/FYMButton';
import { Title } from '../common/views/title/Title';
import { ReactComponent as RocketLaunchGradient } from '../../assets/images/icons/rocket-launch-gradient.svg';
import { FYMText } from '../common/views/FYMText/FYMText';
import { Spacer } from '../common/views/spacer/Spacer';
import { Margin } from '../common/views/margin/Margin';
import { ButtonColor } from '../common/views/FYMButton/props';

const buttonColors: ButtonColor[] = ['primary', 'gold', 'secondary', 'accent', 'gradient', 'dark', 'light', 'danger'];

const FYMButtonsExamplesComponent: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const onIsLoadingClick = useCallback(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  const onErrorClick = useCallback(() => {
    setError(new Error('Test error'));
    setTimeout(() => {
      setError(undefined);
    }, 2000);
  }, []);

  return (
    <>
      <Title>FYM Buttons</Title>

      <FYMText>Sizes rounded</FYMText>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton size="sm">Small</FYMButton>
      </Margin>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton size="md">Medium</FYMButton>
      </Margin>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton size="lg">Large</FYMButton>
      </Margin>

      <Spacer flex marginBottom={8} marginTop={48}>
        <FYMText>Sizes with icon</FYMText>
      </Spacer>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton size="sm" leftIcon={faTrash}>
          Small
        </FYMButton>
      </Margin>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton size="md" leftIcon={faTrash}>
          Medium
        </FYMButton>
      </Margin>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton size="lg" leftIcon={faTrash}>
          Large
        </FYMButton>
      </Margin>

      {buttonColors.map((color) => {
        return (
          <Fragment key={color}>
            <Spacer flex marginBottom={8} marginTop={48}>
              <FYMText>{color}</FYMText>
            </Spacer>
            <Margin marginRight={8} marginBottom={8}>
              <FYMButton color={color}>{color}</FYMButton>
            </Margin>
            <Margin marginRight={8} marginBottom={8}>
              <FYMButton color={color} disabled>
                {`${color} Disabled`}
              </FYMButton>
            </Margin>
            <Margin marginRight={8} marginBottom={8}>
              <FYMButton color={color} outline>
                {`${color} Outline`}
              </FYMButton>
            </Margin>
            <Margin marginRight={8} marginBottom={8}>
              <FYMButton color={color} outline disabled>
                {`${color} Outline Disabled`}
              </FYMButton>
            </Margin>
          </Fragment>
        );
      })}

      <Spacer flex marginBottom={8} marginTop={48}>
        <FYMText>Primary with icons</FYMText>
      </Spacer>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton color="primary" leftIcon={faTrash}>
          Primary
        </FYMButton>
      </Margin>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton color="primary" leftIcon={faTrash} disabled>
          Primary Disabled
        </FYMButton>
      </Margin>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton color="primary" leftIcon={faTrash} outline>
          Primary Outline
        </FYMButton>
      </Margin>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton color="primary" leftIcon={faTrash} outline disabled>
          Primary Outline Disabled
        </FYMButton>
      </Margin>

      <Spacer flex marginBottom={8} marginTop={48}>
        <FYMText>Gradient with icons</FYMText>
      </Spacer>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton color="gradient" leftIcon={faTrash}>
          Primary
        </FYMButton>
      </Margin>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton color="gradient" leftIcon={faTrash} disabled>
          Primary Disabled
        </FYMButton>
      </Margin>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton color="gradient" leftIcon={faTrash} outline>
          Primary Outline
        </FYMButton>
      </Margin>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton color="gradient" leftIcon={faTrash} outline disabled>
          Primary Outline Disabled
        </FYMButton>
      </Margin>

      <Spacer flex marginBottom={8} marginTop={48}>
        <FYMText>With images</FYMText>
      </Spacer>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton color="primary">
          <>
            <RocketLaunchGradient />
            <Spacer marginLeft={10}>
              <FYMText>Button With Image</FYMText>
            </Spacer>
          </>
        </FYMButton>
      </Margin>
      <Spacer flex marginBottom={8} marginTop={48}>
        <FYMText>Async (tap for isLoading)</FYMText>
      </Spacer>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton color="primary" leftIcon={faTrash} isLoading={isLoading} onClick={onIsLoadingClick} size="sm">
          Small Async
        </FYMButton>
      </Margin>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton color="primary" leftIcon={faTrash} isLoading={isLoading} onClick={onIsLoadingClick} size="md">
          Medium Async
        </FYMButton>
      </Margin>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton color="primary" leftIcon={faTrash} isLoading={isLoading} onClick={onIsLoadingClick} size="lg">
          Large Async
        </FYMButton>
      </Margin>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton color="primary" leftIcon={faTrash} isLoading={isLoading} disabled>
          Async Disabled
        </FYMButton>
      </Margin>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton
          color="primary"
          leftIcon={faTrash}
          isLoading={isLoading}
          error={error}
          outline
          onClick={onErrorClick}
        >
          Async Outline (tap for error)
        </FYMButton>
      </Margin>
      <Margin marginRight={8} marginBottom={8}>
        <FYMButton color="primary" leftIcon={faTrash} isLoading={isLoading} outline disabled onClick={onErrorClick}>
          Async Outline Disabled
        </FYMButton>
      </Margin>
    </>
  );
};

export const FYMButtonsExamples = TypedMemo(FYMButtonsExamplesComponent);
