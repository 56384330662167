import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { tryParseInt } from 'fym-common/src/utils/tryParseInt';
import { LicenseType } from 'fym-common/src/license/consts';
import { TypedMemo } from '../../common/types';
import { LoadingView } from '../../common/views/LoadingView';
import { useAccountId, useUserToken } from '../../../redux/slices/appInfo/hooks';
import { useGetAccountsQuery, useGetSocialLinksQuery } from '../../../redux/api/fymAPI';
import { ErrorView } from '../../common/views/ErrorView/ErrorView';
import { setAccountId } from '../../../redux/slices/appInfo/slice';
import { useQueryParam } from '../../../utils/useQueryParam';
import { SmartLinksContainerRenderer } from './SmartLinksContainerRenderer';
import { useLocalStore } from '../../common/storeHooks';
import { useSMLRoute, useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { useUser } from '../../users/hooks/useUser';
import { useLicenseInfo } from '../../../redux/slices/license/hooks';
import { SmartLinkUpsell } from '../upselling/SmartLinkUpsell';

const SmartLinksContainerComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  // TODO: make it work not be used on web
  // useListenForNavigation(); // It needs to be located in component which has access to router

  const router = useSMLRouter();
  const userToken = useUserToken();
  const accountId = useAccountId();
  const route = useSMLRoute();
  const dispatch = useDispatch();
  const licenseInfo = useLicenseInfo();
  const routeAccountIdString = useQueryParam('accountId', undefined, '');
  const routeAccountId = tryParseInt(routeAccountIdString);
  const {
    value: closeTrialPopup,
    setValue: setCloseTrialPopup,
    isLoading: isCloseTrialPopupLoading,
  } = useLocalStore('smlTrialInfoShown', undefined);

  const showPopup =
    licenseInfo.currentPlan === LicenseType.Trial &&
    !licenseInfo.isSubscriptionValid() &&
    closeTrialPopup === undefined;
  const [isOpen, setIsOpen] = useState<boolean>(showPopup);
  const {
    accounts,
    hasSlug,
    hasAccount,
    hasAvatar,
    isAccountsLoading,
    accountsError,
    refetch: accountsRefetch,
  } = useGetAccountsQuery(undefined, {
    skip: !userToken,
    selectFromResult: ({ data, isLoading, error }) => {
      const _accounts = data?.accounts;
      const currentAccount = accountId !== null ? _accounts?.find((account) => account.id === accountId) : undefined;
      return {
        accounts: _accounts,
        hasSlug: currentAccount && currentAccount.slug !== null && currentAccount.slug !== undefined,
        hasAccount: _accounts && _accounts.length > 0,
        hasAvatar: currentAccount && !!currentAccount.avatarFile,
        isAccountsLoading: isLoading,
        accountsError: error,
      };
    },
  });
  const {
    hasSocialLinks,
    isGetSocialLinksLoading,
    getSocialLinksError,
    refetch: socialLinksRefetch,
  } = useGetSocialLinksQuery(accountId ?? 0, {
    skip: accountId === null,
    selectFromResult: ({ data, isLoading, error }) => ({
      hasSocialLinks: data && data.socialLinks.length > 0,
      isGetSocialLinksLoading: isLoading,
      getSocialLinksError: error,
    }),
  });
  const { isLoading: isUserLoading, error: userError, refetch: userRefetch } = useUser();
  const { value: onboardingSMLFinishedStep2, isLoading: isOnboardingSMLFinishedStep2Loading } = useLocalStore(
    'onboardingSMLFinishedStep2',
    false
  );
  const { value: onboardingSMLFinishedStep3, isLoading: isOnboardingSMLFinishedStep3Loading } = useLocalStore(
    'onboardingSMLFinishedStep3',
    false
  );
  const anyOnboardingStepLoading =
    isOnboardingSMLFinishedStep2Loading || isOnboardingSMLFinishedStep3Loading || isCloseTrialPopupLoading;
  const isLoggedOutUser = userToken === null;

  const onClose = useCallback(() => {
    setIsOpen(false);
    setCloseTrialPopup(true).catch(console.error);
  }, [setCloseTrialPopup]);

  const refetch = useCallback(() => {
    accountsRefetch();
    userRefetch();
    socialLinksRefetch();
  }, [accountsRefetch, userRefetch, socialLinksRefetch]);

  useEffect(() => {
    setIsOpen(showPopup);
  }, [showPopup]);

  useEffect(() => {
    const newAccountId = routeAccountId ?? accounts?.[0]?.id;
    if (accountId !== null || newAccountId === undefined || accountId === newAccountId) {
      return;
    }
    dispatch(setAccountId({ accountId: newAccountId }));
  }, [accountId, dispatch, routeAccountId, accounts]);

  useEffect(() => {
    if (isLoggedOutUser || anyOnboardingStepLoading || !route || isGetSocialLinksLoading) {
      return;
    }
    if (hasSlug === false) {
      if (route !== SMLRoutes.onboarding1) {
        router.replace(SMLRoutes.onboarding1, { accountId: accountId ?? undefined });
      }
      return;
    }
    if (hasAccount === true && onboardingSMLFinishedStep2 === false && hasAvatar === false) {
      if (route !== SMLRoutes.onboarding2) {
        router.replace(SMLRoutes.onboarding2);
      }
      return;
    }
    if (
      hasAccount === true &&
      onboardingSMLFinishedStep3 === false &&
      hasSocialLinks === false &&
      route !== SMLRoutes.onboarding3
    ) {
      router.replace(SMLRoutes.onboarding3);
    }
  }, [
    anyOnboardingStepLoading,
    hasAccount,
    hasAvatar,
    hasSocialLinks,
    isLoggedOutUser,
    route,
    onboardingSMLFinishedStep2,
    onboardingSMLFinishedStep3,
    isGetSocialLinksLoading,
    router,
    hasSlug,
    accountId,
  ]);

  if (accountsError) {
    return <ErrorView error={accountsError} retry={refetch} />;
  }
  if (userError) {
    return <ErrorView error={userError} retry={refetch} />;
  }
  if (getSocialLinksError) {
    return <ErrorView error={getSocialLinksError} retry={refetch} />;
  }
  if (isAccountsLoading || anyOnboardingStepLoading || isUserLoading || isGetSocialLinksLoading) {
    return <LoadingView />;
  }

  return (
    <SmartLinksContainerRenderer>
      <>
        {showPopup && <SmartLinkUpsell isTrialModal isOpen={isOpen} onClose={onClose} />}
        {children}
      </>
    </SmartLinksContainerRenderer>
  );
};

export const SmartLinksContainer = TypedMemo(SmartLinksContainerComponent);
