export class ZvukUser {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get name(): string {
    return this.data.profile.name;
  }

  get username(): string {
    return this.data.profile.username;
  }

  get avatar(): string | undefined {
    if (!this.data.profile?.image) {
      return undefined;
    }
    return `https://zvuk.com${this.data.profile.image.src.replace(
      '{size}',
      `${this.data.profile.image.w}x${this.data.profile.image.h}`
    )}`;
  }

  get id(): number {
    return this.data.profile.id;
  }
}
