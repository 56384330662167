import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { CompositeNavigationProp, RouteProp } from '@react-navigation/native';
import { StackNavigationOptions, StackNavigationProp } from '@react-navigation/stack';
import type { ImporterID } from 'fym-common/src/importers//types';
import type { ImporterAuthenticationModel } from '../../realm/models/ImporterAuthenticationModel';
import type { TransferCollectionModel } from '../../realm/models/TransferCollectionModel';
import type { StatsModel } from '../../realm/models/StatsModel';

export enum FYMTabNames {
  Transfer = 'Transfer',
  Profile = 'Profile',
  Stats = 'Stats',
}

export enum FYMRouteNames {
  // ---------------------------- Transfer tab ----------------------------
  TransfersCollections = 'TransfersCollections',
  TransferCollectionDetails = 'TransferCollectionDetails',
  TransferCollectionDetailsSub = 'TransferCollectionDetailsSub',
  PickerFrom = 'PickerFrom',
  PickerTo = 'PickerTo',
  AuthenticationFrom = 'AuthenticationFrom',
  AuthenticationTo = 'AuthenticationTo',
  ImporterReauthentication = 'ImporterReauthentication',
  TransferCollectionsPicker = 'TransferCollectionsPicker',
  FileColumnsPicker = 'FileColumnsPicker',
  // ---------------------------- Stats tab ----------------------------
  StatsHome = 'StatsHome',
  StatsAccountPicker = 'StatsAccountPicker',
  StatsImporterAuthenticationView = 'StatsImporterAuthenticationView',
  StatsDetails = 'StatsDetails',
  StatsCollectionsEditPicker = 'StatsCollectionsEditPicker',
  StatsCollectionsPicker = 'StatsCollectionsPicker',
  // ---------------------------- Profile tab ----------------------------
  Settings = 'Settings',
  Premium = 'Premium',
  ConnectedAccounts = 'ConnectedAccounts',
  NewAccountInProfileTab = 'NewAccountInProfileTab',
  Debug = 'Debug',
  AccountSelector = 'AccountSelector',
  NativeOnlyWebsite = 'NativeOnlyWebsite',
}

export const DefaultRouteNameForFYMTab: Record<keyof typeof FYMTabNames, keyof typeof FYMRouteNames> = {
  [FYMTabNames.Transfer]: FYMRouteNames.TransfersCollections,
  [FYMTabNames.Profile]: FYMRouteNames.Settings,
  [FYMTabNames.Stats]: FYMRouteNames.StatsHome,
};

export type FYMRouteParts = {
  tabName: FYMTabNames;
  routeNames: FYMRouteNames[];
};

export function isValidRoute(route: string): route is keyof typeof FYMRouteNames {
  return !!(FYMRouteNames as any)[route];
}

type FYMRoutesParts = { [key in keyof typeof FYMRouteNames]: FYMRouteParts };

export const FYMRoutes: FYMRoutesParts = {
  [FYMRouteNames.PickerFrom]: { tabName: FYMTabNames.Transfer, routeNames: [FYMRouteNames.PickerFrom] },
  [FYMRouteNames.PickerTo]: { tabName: FYMTabNames.Transfer, routeNames: [FYMRouteNames.PickerTo] },
  [FYMRouteNames.AuthenticationFrom]: { tabName: FYMTabNames.Transfer, routeNames: [FYMRouteNames.AuthenticationFrom] },
  [FYMRouteNames.AuthenticationTo]: { tabName: FYMTabNames.Transfer, routeNames: [FYMRouteNames.AuthenticationTo] },
  [FYMRouteNames.TransferCollectionsPicker]: {
    tabName: FYMTabNames.Transfer,
    routeNames: [FYMRouteNames.TransferCollectionsPicker],
  },
  [FYMRouteNames.TransfersCollections]: {
    tabName: FYMTabNames.Transfer,
    routeNames: [FYMRouteNames.TransfersCollections],
  },
  [FYMRouteNames.TransferCollectionDetails]: {
    tabName: FYMTabNames.Transfer,
    routeNames: [FYMRouteNames.TransferCollectionDetailsSub, FYMRouteNames.TransferCollectionDetails],
  },
  [FYMRouteNames.TransferCollectionDetailsSub]: {
    tabName: FYMTabNames.Transfer,
    routeNames: [FYMRouteNames.TransferCollectionDetailsSub],
  },
  [FYMRouteNames.ImporterReauthentication]: {
    tabName: FYMTabNames.Transfer,
    routeNames: [FYMRouteNames.ImporterReauthentication],
  },
  [FYMRouteNames.FileColumnsPicker]: { tabName: FYMTabNames.Transfer, routeNames: [FYMRouteNames.FileColumnsPicker] },
  [FYMRouteNames.Settings]: { tabName: FYMTabNames.Profile, routeNames: [FYMRouteNames.Settings] },
  [FYMRouteNames.Premium]: { tabName: FYMTabNames.Profile, routeNames: [FYMRouteNames.Premium] },
  [FYMRouteNames.ConnectedAccounts]: { tabName: FYMTabNames.Profile, routeNames: [FYMRouteNames.ConnectedAccounts] },
  [FYMRouteNames.NewAccountInProfileTab]: {
    tabName: FYMTabNames.Profile,
    routeNames: [FYMRouteNames.NewAccountInProfileTab],
  },
  [FYMRouteNames.NativeOnlyWebsite]: { tabName: FYMTabNames.Profile, routeNames: [FYMRouteNames.NativeOnlyWebsite] },
  [FYMRouteNames.Debug]: { tabName: FYMTabNames.Profile, routeNames: [FYMRouteNames.Debug] },
  [FYMRouteNames.StatsHome]: { tabName: FYMTabNames.Stats, routeNames: [FYMRouteNames.StatsHome] },
  [FYMRouteNames.StatsAccountPicker]: { tabName: FYMTabNames.Stats, routeNames: [FYMRouteNames.StatsAccountPicker] },
  [FYMRouteNames.StatsCollectionsPicker]: {
    tabName: FYMTabNames.Stats,
    routeNames: [FYMRouteNames.StatsCollectionsPicker],
  },
  [FYMRouteNames.StatsImporterAuthenticationView]: {
    tabName: FYMTabNames.Stats,
    routeNames: [FYMRouteNames.StatsImporterAuthenticationView],
  },
  [FYMRouteNames.StatsDetails]: { tabName: FYMTabNames.Stats, routeNames: [FYMRouteNames.StatsDetails] },
  [FYMRouteNames.StatsCollectionsEditPicker]: {
    tabName: FYMTabNames.Stats,
    routeNames: [FYMRouteNames.StatsCollectionsEditPicker],
  },
  [FYMRouteNames.AccountSelector]: {
    tabName: FYMTabNames.Profile,
    routeNames: [FYMRouteNames.AccountSelector],
  },
};

export type TabParamList = {
  [FYMTabNames.Transfer]: { screenOptions: StackNavigationOptions };
  [FYMTabNames.Profile]: { screenOptions: StackNavigationOptions };
  [FYMTabNames.Stats]: { screenOptions: StackNavigationOptions };
};

export type TransferStackParamList = {
  [FYMRouteNames.TransfersCollections]: undefined;
  [FYMRouteNames.TransferCollectionDetails]: {
    transferCollectionIdString: ReturnType<TransferCollectionModel['_id']['toHexString']>;
  };
  [FYMRouteNames.TransferCollectionDetailsSub]: {
    transferCollectionIdString: ReturnType<TransferCollectionModel['_id']['toHexString']>;
  };
  [FYMRouteNames.ImporterReauthentication]: {
    importerId: ImporterID;
    authModelIdString: ReturnType<ImporterAuthenticationModel['_id']['toHexString']>;
  };
  [FYMRouteNames.PickerFrom]: undefined;
  [FYMRouteNames.PickerTo]: undefined;
  [FYMRouteNames.AuthenticationFrom]: undefined;
  [FYMRouteNames.AuthenticationTo]: undefined;
  [FYMRouteNames.TransferCollectionsPicker]: undefined;
  [FYMRouteNames.FileColumnsPicker]: undefined;
};

export type ProfileStackParamList = {
  [FYMRouteNames.Settings]: undefined;
  [FYMRouteNames.Premium]: undefined;
  [FYMRouteNames.ConnectedAccounts]: { importerId: ImporterID };
  [FYMRouteNames.NewAccountInProfileTab]: undefined;
  [FYMRouteNames.NativeOnlyWebsite]: { url: string };
  [FYMRouteNames.Debug]: undefined;
  [FYMRouteNames.AccountSelector]: undefined;
};

export type StatsStackParamList = {
  [FYMRouteNames.StatsHome]: undefined;
  [FYMRouteNames.StatsAccountPicker]: undefined;
  [FYMRouteNames.StatsCollectionsPicker]: undefined;
  [FYMRouteNames.StatsImporterAuthenticationView]: undefined;
  [FYMRouteNames.StatsDetails]: { statsModelIdString: ReturnType<StatsModel['_id']['toHexString']> };
  [FYMRouteNames.StatsCollectionsEditPicker]: { statsModelIdString: ReturnType<StatsModel['_id']['toHexString']> };
};

export type FYMRootParamList = TransferStackParamList & ProfileStackParamList & StatsStackParamList;

type TransferScreenNavigationProp = CompositeNavigationProp<
  StackNavigationProp<TransferStackParamList>,
  BottomTabNavigationProp<TabParamList, FYMTabNames.Transfer>
>;

type ProfileScreenNavigationProp = CompositeNavigationProp<
  StackNavigationProp<ProfileStackParamList>,
  BottomTabNavigationProp<TabParamList, FYMTabNames.Profile>
>;

type StatsScreenNavigationProp = CompositeNavigationProp<
  StackNavigationProp<StatsStackParamList>,
  BottomTabNavigationProp<TabParamList, FYMTabNames.Stats>
>;

type TransferScreenRouteProp = RouteProp<TabParamList, FYMTabNames.Transfer>;
type ProfileScreenRouteProp = RouteProp<TabParamList, FYMTabNames.Profile>;
type StatsScreenRouteProp = RouteProp<TabParamList, FYMTabNames.Stats>;

export type TransferStackScreenProps = {
  navigation: TransferScreenNavigationProp;
  route: TransferScreenRouteProp;
};
export type ProfileStackScreenProps = {
  navigation: ProfileScreenNavigationProp;
  route: ProfileScreenRouteProp;
};
export type StatsStackScreenProps = {
  navigation: StatsScreenNavigationProp;
  route: StatsScreenRouteProp;
};

export type ComplexNavigationProp = CompositeNavigationProp<
  StackNavigationProp<TransferStackParamList | ProfileStackParamList | StatsStackParamList>,
  BottomTabNavigationProp<any>
>;
