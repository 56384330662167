import { InfoWidgetType, PlayerWidgetType, SmartLinkWidgetType } from './enums';
import { LinkType } from '../utils/recognizeLinkTypeByUrl';
import { JWTUser } from './userTypes';

type YoutubeItem = { videoId: string };

export type YouTubeData = Record<string, YoutubeItem>;

export type SerializedFile = {
  id: number;
  uuid: string;
  url: string;
};

export enum SerializedSmartLinkVersionTheme {
  chill = 'chill',
  deep = 'deep',
  dark = 'dark',
  loli = 'loli',
}

export enum SerializedPublicAccountTrackerType {
  facebookPixel = 'facebookPixel',
  googleAnalyticsLegacy = 'googleAnalyticsLegacy',
  googleAnalytics = 'googleAnalytics',
  googleTagManager = 'googleTagManager',
  googleGlobalSite = 'googleGlobalSite',
  plausible = 'plausible',
}

export type SerializedAccountTrackerData = unknown;

export type SerializedPublicAccountTracker = {
  type: SerializedPublicAccountTrackerType;
  data: SerializedAccountTrackerData;
};

export type SerializedAccountTracker = {
  id: number;
  type: SerializedPublicAccountTrackerType;
  data: SerializedAccountTrackerData;
  snippet: string | null;
  trackerId: string;
  privateData: Record<string, string>;
};

export type SerializedPublicAccount = {
  id: number;
  name: string | null;
  avatarFile: SerializedFile | null;
  avatarProcessedUrl: string;
  slug: string | null;
  customDomain: string | null;
  shareUrl: string | null;
  mainSmartLinkId: number;
};

export type SerializedUser = JWTUser;

export interface SerializedAccount extends SerializedPublicAccount {
  plausibleStatsUrl: string | null;
}

export type SerializedAccountForList = {
  id: number;
  name: string | null;
  avatarFile: SerializedFile | null;
  avatarProcessedUrl: string | null;
  shareUrl: string | null;
  slug: string | null | undefined;
};

export type SerializedSocialLink = {
  id: number;
  url: string;
  type: LinkType;
};

export type SerializedWidgetData = {
  [SmartLinkWidgetType.info]: { text: string; type: InfoWidgetType };
  [SmartLinkWidgetType.image]: {
    image: SerializedFile;
    imageProcessedUrl: string | null;
  };
  [SmartLinkWidgetType.link]: {
    url: string;
    label: string | null;
    type: LinkType;
    isMusicService: boolean;
    smartLinkId: number | undefined;
  };
  [SmartLinkWidgetType.socialLinks]: { socialLinks: SerializedSocialLink[] };
  [SmartLinkWidgetType.profile]: Record<string, never>;
  [SmartLinkWidgetType.player]: {
    url: string;
    embedUrl?: string;
    type?: PlayerWidgetType;
    musicServiceType: LinkType;
    version?: number;
    height?: number;
  };
};

type GenericSerializedWidget<T extends SmartLinkWidgetType> = {
  uuid: string;
  position: number;
  type: T;
  data: SerializedWidgetData[T];
};

export type SerializedInfoWidget = GenericSerializedWidget<SmartLinkWidgetType.info>;

export type SerializedImageWidget = GenericSerializedWidget<SmartLinkWidgetType.image>;

export type SerializedLinkWidget = GenericSerializedWidget<SmartLinkWidgetType.link>;

export type SerializedSocialLinksWidget = GenericSerializedWidget<SmartLinkWidgetType.socialLinks>;

export type SerializedPlayerWidget = GenericSerializedWidget<SmartLinkWidgetType.player>;
export type SerializedProfileWidget = GenericSerializedWidget<SmartLinkWidgetType.profile>;

export type SerializedWidget =
  | SerializedInfoWidget
  | SerializedImageWidget
  | SerializedLinkWidget
  | SerializedSocialLinksWidget
  | SerializedProfileWidget
  | SerializedPlayerWidget;

export type SerializedPublicSmartLinkVersion = {
  name: string;
  metaTitle: string | null;
  metaDescription: string | null;
  slug: string;
  backgroundFile: SerializedFile | null;
  backgroundProcessedUrl: string;
  widgets: SerializedWidget[] | null;
  theme: SerializedSmartLinkVersionTheme;
};

type SerializedPublicSmartLinkBase = {
  id: number;
  accountId: number;
  shareUrl: string | null;
  embedUrl: string | null;
};
export interface SerializedPublicSmartLinkPublished extends SerializedPublicSmartLinkBase {
  publishedSmartLinkVersion: SerializedPublicSmartLinkVersion;
  recentSmartLinkVersion: null;
  accountId: number;
  shareUrl: string | null;
  embedUrl: string | null;
}
export interface SerializedPublicSmartLinkPreview extends SerializedPublicSmartLinkBase {
  recentSmartLinkVersion: SerializedPublicSmartLinkVersion;
  publishedSmartLinkVersion: null;
  accountId: number;
  shareUrl: string | null;
  embedUrl: string | null;
}

export type SerializedPublicSmartLink = SerializedPublicSmartLinkPublished | SerializedPublicSmartLinkPreview;

export enum SerializedSmartLinkStatus {
  draft = 'draft',
  published = 'published',
}

export type SerializedSmartLink = {
  id: number;
  recentSmartLinkVersion: {
    id: number;
    name: string;
    slug: string;
    backgroundFile: SerializedFile | null;
    backgroundProcessedUrl: string;
    widgets: SerializedWidget[] | null;
    previewUrl: string | null;
    editUrl: string | null;
    status: SerializedSmartLinkStatus;
    theme: SerializedSmartLinkVersionTheme;
  };
  publishedSmartLinkVersion: {
    id: number;
    name: string;
    slug: string;
    backgroundFile: SerializedFile | null;
    backgroundProcessedUrl: string;
    widgets: SerializedWidget[] | null;
    status: SerializedSmartLinkStatus;
    publishedAt: number | null;
    theme: SerializedSmartLinkVersionTheme;
  } | null;
  accountId: number;
  shareUrl: string | null;
  embedUrl: string | null;
  plausibleEmbedStatsUrl: string | null;
  plausibleStatsUrl: string | null;
  isMainSmartLink: boolean;
};

export type SerializedSmartLinksList = {
  id: number;
  recentSmartLinkVersion: {
    id: number;
    name: string;
    slug: string;
    status: SerializedSmartLinkStatus;
    imagePreviewURL: string | null;
  };
  publishedSmartLinkVersion: {
    id: number;
    name: string;
    slug: string;
    status: SerializedSmartLinkStatus;
  } | null;
}[];

export type SerializedSmartLinksListResponse = {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
  results: SerializedSmartLinksList;
};

export type SerializedAccountsListResponse = {
  accounts: SerializedAccountForList[];
};
