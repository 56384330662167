import { MusicService } from './types';
import { ImporterID } from '../../importers/types';
import { MusicAPIIntegrationID } from '../types';
import { LinkType } from '../../utils/recognizeLinkTypeByUrl';

export const deezer: MusicService = {
  id: 'deezer',
  importerID: ImporterID.Deezer,
  linkType: LinkType.deezer,
  musicAPIID: MusicAPIIntegrationID.Deezer,
  shortName: 'Deezer',
  name: 'Deezer',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: true,
  color: { label: '#222250', background: '#6363AF' },
  tracksPerPlaylistLimit: 2000, // https://support.deezer.com/hc/en-gb/articles/115004522449-Content-limits
};
