import React from 'react';
import { config as FAConfig } from '@fortawesome/fontawesome-svg-core';
import { TypedMemo } from '../../common/types';

// Styles imports for Electron
import '@fortawesome/fontawesome-svg-core/styles';

FAConfig.autoAddCss = false;

const AppInitialEntryComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <>{children}</>;
};
export const AppInitialEntry = TypedMemo(AppInitialEntryComponent);
