const youTubePublicResourceUrl = 'https://youtu.be';

export function createYoutubeVideoUrl(videoId: string | undefined) {
  if (!videoId) {
    return undefined;
  }
  return `${youTubePublicResourceUrl}/${videoId}`;
}

export function getYouTubeImageUrl(
  thumbnails: Record<string, { url?: string; width?: number; height?: number }> | undefined
) {
  if (!thumbnails) {
    return undefined;
  }
  return Object.values(thumbnails).sort((a, b) => (b.width ?? 0) - (a.width ?? 0))[0]?.url;
}
