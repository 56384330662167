import { useIntl } from 'react-intl';
import { useStore } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { faSave } from '@fortawesome/pro-solid-svg-icons/faSave';
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle';
import { RootStateType } from '../../../redux/store';
import { createResolver } from './validators';
import { FieldsName, FieldsType } from './consts';
import { extractChangedSocialLinks, generateIconForUrl } from '../SMLOnboardingFormStep3/utils';
import { FormHelp } from '../components/FormHelp/FormHelp';
import { ExternalLink } from '../../common/views/externalLink/ExternalLink';
import { FormInput } from '../components/FormInput/FormInput';
import { InputType } from '../types';
import { FileLoader } from '../components/FileLoader/FileLoader';
import { AccountFormProps } from './props';
import { SMLButton } from '../../common/views/SMLButton/SMLButton';

export function useAccountForm({
  account,
  socialLinks,
  onSubmit: onSubmitFromProps,
  isLoading = false,
}: Pick<AccountFormProps, 'account' | 'socialLinks' | 'onSubmit' | 'isLoading'>) {
  const intl = useIntl();
  const store = useStore<RootStateType>();
  const resolver = useMemo(() => createResolver(store, account?.id), [store, account?.id]);
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const defaultValues = useMemo(() => {
    return {
      [FieldsName.name]: account?.name ?? '',
      [FieldsName.slug]: account?.slug ?? '',
      [FieldsName.customDomain]: account?.customDomain ?? '',
      [FieldsName.avatar]: account?.avatarFile ?? undefined,
      [FieldsName.socialLinks]:
        socialLinks && socialLinks.length > 0
          ? socialLinks.map(({ id, url }) => ({ socialLinkId: id, url }))
          : [{ url: '' }],
    };
  }, [account?.avatarFile, account?.customDomain, account?.name, account?.slug, socialLinks]);

  const {
    control,
    handleSubmit,
    resetField,
    reset,
    formState: { isDirty },
  } = useForm<FieldsType>({
    resolver,
    defaultValues,
  });
  const { fields, append, remove } = useFieldArray({ name: 'socialLinks', control });

  const removeAvatar = useCallback(() => {
    resetField(FieldsName.avatar, { defaultValue: undefined });
  }, [resetField]);

  const removeSocialLink = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  const addSocialLink = useCallback(() => {
    append({ url: '' });
  }, [append]);

  const onValid: SubmitHandler<FieldsType> = useCallback(
    (form) => {
      const data = extractChangedSocialLinks(form.socialLinks, socialLinks);
      onSubmitFromProps(form, data).catch(console.error);
    },
    [socialLinks, onSubmitFromProps]
  );

  const submit = useCallback(
    (event?: React.BaseSyntheticEvent) => {
      handleSubmit(onValid)(event).catch(console.error);
    },
    [handleSubmit, onValid]
  );

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  return {
    SaveButton: isDirty ? (
      <SMLButton
        color="primary"
        onClick={submit}
        disabled={isLoading || isFileLoading}
        isLoading={isLoading}
        leftIcon={faSave}
      >
        {intl.formatMessage({
          id: 'app.buttons.save',
          defaultMessage: 'Save',
        })}
      </SMLButton>
    ) : null,
    CustomDomainInfo: !(account && account.customDomain) ? (
      <FormHelp>
        {intl.formatMessage(
          {
            id: 'app.forms.account.help.custom-domain-setup',
            defaultMessage:
              'If you wish to use custom domain, please check <a>our FAQ page</a> to learn how to set it up.',
          },
          {
            a: (chunks: React.ReactNode[]) => (
              <ExternalLink href="https://support.freeyourmusic.com/helpdesk/KB/View/48715959-custom-domain">
                {chunks}
              </ExternalLink>
            ),
          }
        )}
      </FormHelp>
    ) : null,
    NameInput: (
      <FormInput
        name={FieldsName.name}
        control={control}
        type={InputType.text}
        disabled={isLoading}
        icon={faUserCircle}
        label={intl.formatMessage({
          id: 'app.forms.account.name',
          defaultMessage: 'Name',
        })}
        placeholder={intl.formatMessage({
          id: 'app.forms.account.name.placeholder',
          defaultMessage: 'Enter name',
        })}
        help={intl.formatMessage({
          id: 'app.forms.account.name.help',
          defaultMessage: 'The name will be displayed on your Bio Link',
        })}
      />
    ),
    SocialLinksInputs: fields.map((field, index) => {
      const isFirstInput = index === 0;
      const icon = generateIconForUrl(field.url);
      return (
        <FormInput
          key={field.id}
          name={`socialLinks.${index}.url`}
          control={control}
          type={InputType.text}
          disabled={isLoading}
          icon={icon}
          index={index}
          label={
            isFirstInput
              ? intl.formatMessage({
                  id: 'app.forms.social_links.social_link_url.label',
                  defaultMessage: 'Social media',
                })
              : undefined
          }
          placeholder={intl.formatMessage(
            {
              id: 'app.forms.social_links.social_link_url.placeholder',
              defaultMessage: 'eg. {link}',
            },
            { link: 'https://twitter.com/freeyourmusic' }
          )}
          help={
            isFirstInput
              ? intl.formatMessage({
                  id: 'app.forms.social_links.social_link_url.help',
                  defaultMessage:
                    'All the platforms you use to promote your art. You’ll be able to add them all at once as a widget later.',
                })
              : undefined
          }
          onRemove={fields.length > 1 ? removeSocialLink : undefined}
        />
      );
    }),
    SocialLinkAdditionButton: (
      <SMLButton color="primary" onClick={addSocialLink} disabled={isLoading} size="sm">
        {intl.formatMessage({
          id: 'app.forms.social_links.add_social_link',
          defaultMessage: 'Add more',
        })}
      </SMLButton>
    ),
    SlugInput: !account?.customDomain ? (
      <FormInput
        name={FieldsName.slug}
        control={control}
        type={InputType.text}
        disabled={isLoading}
        label={intl.formatMessage({
          id: 'app.forms.account.slug',
          defaultMessage: 'Slug',
        })}
        placeholder={intl.formatMessage({
          id: 'app.forms.account.slug.placeholder',
          defaultMessage: 'Enter slug',
        })}
        help={intl.formatMessage({
          id: 'app.forms.account.help.slug',
          defaultMessage: 'The short name that is your custom subdomain for all Smart Links',
        })}
        inputTextPrefix="https://"
        inputTextSuffix=".fym.fm"
      />
    ) : null,
    CustomDomainInput: account?.customDomain ? (
      <FormInput
        name={FieldsName.customDomain}
        control={control}
        type={InputType.text}
        disabled
        label={intl.formatMessage({
          id: 'app.forms.account.custom-domain',
          defaultMessage: 'Custom Domain',
        })}
        help={intl.formatMessage(
          {
            id: 'app.forms.account.help.custom-domain-contact-support',
            defaultMessage: 'Your account has custom domain set to "{domain}". To change this please contact support.',
          },
          { domain: account.customDomain }
        )}
      />
    ) : null,
    AvatarInput: account ? (
      <FileLoader
        name={FieldsName.avatar}
        accountId={account.id}
        control={control}
        removeImage={removeAvatar}
        disabled={isLoading}
        roundedCircle
        onLoadingStateChange={setIsFileLoading}
        label={intl.formatMessage({
          id: 'app.forms.account.avatar',
          defaultMessage: 'Avatar',
        })}
        help={intl.formatMessage({
          id: 'app.forms.account.help.avatar',
          defaultMessage:
            'Use your logo, your favourite avatar, or photo of yourself. It will be displayed on your Bio Link.',
        })}
      />
    ) : null,
  };
}
