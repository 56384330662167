import { BoomplaySearchRawResponse } from './types';
import { BoomplayMatchedTrack } from './BoomplayMatchedTrack';
import { BoomplayAlbum } from './BoomplayAlbum';

export class BoomplaySearchResponse {
  readonly data;

  constructor(data: BoomplaySearchRawResponse | null) {
    this.data = data;
  }

  get tracks(): BoomplayMatchedTrack[] {
    if (!this.data?.data?.tracks) {
      return [];
    }
    return this.data.data.tracks
      .map((item) => BoomplayMatchedTrack.fromData(item))
      .filter((track): track is BoomplayMatchedTrack => !!track);
  }

  get albums(): BoomplayAlbum[] {
    if (!this.data?.data?.albums) {
      return [];
    }
    return this.data.data.albums
      .map((item) => BoomplayAlbum.fromData(item))
      .filter((album): album is BoomplayAlbum => !!album);
  }
}
