import { wait } from './wait';
import { FetchError } from '../generics/errors/FetchError';
import { fetchLoader } from './fetch';
import { Response } from './fetch-types';
import { HttpStatusCode } from '../errors/HttpStatusCode';
import { getDelayFromRetryAfterHeader } from './getDelayFromRetryAfterHeader';
import { HTTP429Error } from '../errors/HTTP429Error';
import { isServer } from '../config/isServer';

export interface RetryOptions {
  delay: number;
  statusCodes: number[];
  attempts: number;
  shouldWait: boolean;
}

export interface FetchRequestInit {
  method?: string;
  body?: string;
  headers?: Record<string, string>;
  redirect?: 'error' | 'follow' | 'manual';
  signal?: AbortSignal | null;
}

export const defaultRetryOptions: RetryOptions = {
  delay: 1000,
  statusCodes: [
    HttpStatusCode.TooManyRequests,
    HttpStatusCode.InternalServerError,
    HttpStatusCode.NotImplemented,
    HttpStatusCode.BadGateway,
    HttpStatusCode.ServiceUnavailable,
    HttpStatusCode.GatewayTimeout,
  ],
  attempts: 5,
  shouldWait: !isServer,
};

async function logFetchDetails(url: string, options: FetchRequestInit, response: Response) {
  const clone = (response as any).clone();
  const data = JSON.stringify({
    request: {
      URL: url,
      method: options.method,
      headers: options.headers,
      body: options.body,
    },
    response: {
      status: clone.status,
      headers: Object.fromEntries(Array.from(clone.headers.entries()) as any),
      body: await clone.json(),
    },
  });
  console.log(`REQUEST AND RESPONSE DETAILS:\n${data}`);
}

export async function fetchRetry(
  url: string,
  options: FetchRequestInit = {},
  retryOptions: RetryOptions = defaultRetryOptions,
  logDetails = false
): Promise<Response> {
  const { attempts, delay, statusCodes, shouldWait } = retryOptions;
  const waitAndRetry = async (_delay: number, _attempt?: number): Promise<Response> => {
    if (_attempt !== undefined) {
      console.log(`Waiting ${_delay} before retrying`);
      await wait(_delay);
      console.warn(`Retrying ${attempts - _attempt} times request to ${url} with options ${JSON.stringify(options)}`);
    }
    const attempt = _attempt ?? attempts;
    const canMakeNextAttempt = attempt > 1;
    let response: Response;
    try {
      response = await fetchLoader.load(url, options);
      if (logDetails) {
        await logFetchDetails(url, options, response);
      }
    } catch (error) {
      if (!canMakeNextAttempt) {
        throw error;
      }
      if (`${error}`.includes('Nock:')) {
        throw error;
      }
      return waitAndRetry(_delay, attempt - 1);
    }

    if (!statusCodes.includes(response.status)) {
      return response;
    }

    const retryAfter = response.headers.get('retry-after');
    const waitTime = getDelayFromRetryAfterHeader(retryAfter);
    if (waitTime !== undefined && !shouldWait) {
      throw new HTTP429Error(waitTime);
    }
    if (!canMakeNextAttempt) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Wrong response status code (${response.status}): ${text}\n${JSON.stringify(response.headers)}`,
        text,
        response
      );
    }
    return waitAndRetry(waitTime ?? _delay, attempt - 1);
  };

  return waitAndRetry(delay);
}
