import { LinkType } from 'fym-common/src/utils/recognizeLinkTypeByUrl';
import { MusicService } from 'fym-common/src/musicServices/services/types';
import { amazonMusic } from 'fym-common/src/musicServices/services/AmazonMusic';
import { appleMusic } from 'fym-common/src/musicServices/services/AppleMusic';
import { deezer } from 'fym-common/src/musicServices/services/Deezer';
import { pandora } from 'fym-common/src/musicServices/services/Pandora';
import { soundcloud } from 'fym-common/src/musicServices/services/SoundCloud';
import { spotify } from 'fym-common/src/musicServices/services/Spotify';
import { tidal } from 'fym-common/src/musicServices/services/Tidal';
import { youtube } from 'fym-common/src/musicServices/services/YouTube';
import { youtubeMusic } from 'fym-common/src/musicServices/services/YouTubeMusic';
import { napster } from 'fym-common/src/musicServices/services/Napster';
import { qobuz } from 'fym-common/src/musicServices/services/Qobuz';
import { qqMusic } from 'fym-common/src/musicServices/services/QQMusic';
import { yandexMusic } from 'fym-common/src/musicServices/services/YandexMusic';
import { vkMusic } from 'fym-common/src/musicServices/services/VKMusic';
import { anghami } from 'fym-common/src/musicServices/services/Anghami';
import { zvuk } from 'fym-common/src/musicServices/services/Zvuk';
import { gaana } from 'fym-common/src/musicServices/services/Gaana';
import { jiosaavn } from 'fym-common/src/musicServices/services/JioSaavn';
import { resso } from 'fym-common/src/musicServices/services/Resso';
import { boomplay } from 'fym-common/src/musicServices/services/Boomplay';

export function musicServiceForLink(type: LinkType): MusicService | null {
  switch (type) {
    case LinkType.amazonmusic:
      return amazonMusic;
    case LinkType.applemusic:
      return appleMusic;
    case LinkType.deezer:
      return deezer;
    case LinkType.pandora:
      return pandora;
    case LinkType.soundcloud:
      return soundcloud;
    case LinkType.spotify:
      return spotify;
    case LinkType.tidal:
      return tidal;
    case LinkType.youtube:
      return youtube;
    case LinkType.youtubemusic:
      return youtubeMusic;
    case LinkType.napster:
      return napster;
    case LinkType.qobuz:
      return qobuz;
    case LinkType.qqmusic:
      return qqMusic;
    case LinkType.yandexmusic:
      return yandexMusic;
    case LinkType.vkmusic:
      return vkMusic;
    case LinkType.anghami:
      return anghami;
    case LinkType.zvuk:
      return zvuk;
    case LinkType.gaana:
      return gaana;
    case LinkType.jiosaavn:
      return jiosaavn;
    case LinkType.resso:
      return resso;
    case LinkType.boomplay:
      return boomplay;
    default:
      return null;
  }
}
