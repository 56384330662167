import { tryParseDurationToMs } from '../../utils/tryParseDurationToMs';

export function buildCoverUrl(coverArt: string) {
  return `https://artworkbus.angham.me/?id=${coverArt}&size=1024`;
}
const anghamiPublicResourceUrl = 'https://play.anghami.com';

export function createAnghamiAlbumUrl(albumId: string) {
  return `${anghamiPublicResourceUrl}/album/${albumId}`;
}

export function createAnghamiPlaylistUrl(playlistId: string) {
  return `${anghamiPublicResourceUrl}/playlist/${playlistId}`;
}

export function createAnghamiTrackUrl(trackId: string) {
  return `${anghamiPublicResourceUrl}/song/${trackId}`;
}

export function buildCoverUrlFromMetaImage(metaImageUrl: string | undefined) {
  if (!metaImageUrl) {
    return undefined;
  }
  const parsedImageUrl = new URL(metaImageUrl.replace(/&amp;/g, '&'));
  const coverArtId = parsedImageUrl.searchParams.get('coverartid');
  if (!coverArtId) {
    return undefined;
  }
  return buildCoverUrl(coverArtId);
}

export function convertAnghamiTrackData(data: any) {
  if (!data?.id || !data?.title) {
    return null;
  }
  return {
    rawId: `${data.id}`,
    name: data.title,
    album: data.album,
    artist: data.artist,
    imageUrl: data.coverArt && buildCoverUrl(data.coverArt),
    duration: tryParseDurationToMs(data.duration, 's'),
    sourceURL: createAnghamiTrackUrl(data.id),
  };
}

export function buildAnghamiCoverUrl(coverArtID: string, size = 512) {
  return coverArtID ? `https://artwork.anghcdn.co/webp/?id=${coverArtID}&size=${size}` : undefined;
}
