import { AmazonMusicCollectionTrack } from '../models/AmazonMusicCollectionTrack';
import { AmazonMusicAlbum } from '../models/AmazonMusicAlbum';
import { AmazonMusicArtist } from '../models/AmazonMusicArtist';
import { isNumber } from '../../../utils/isNumber';
import {
  AlbumStats,
  AmazonMusicSerialisedStats,
  ArtistStats,
  BasicTrackStats,
  IdsPerYear,
  RawId,
  TrackStats,
  Year,
} from './types';

type Props = {
  groupedRecentlyPlayedTracks: Record<Year, BasicTrackStats[]>;
  albumsIdsPerYear: IdsPerYear;
  artistsIdsPerYear: IdsPerYear;
  tracksMap: Map<RawId, AmazonMusicCollectionTrack>;
  albums: AmazonMusicAlbum[];
  artists: AmazonMusicArtist[];
  limitPerYear: number;
};

export function generateStats({
  groupedRecentlyPlayedTracks,
  albumsIdsPerYear,
  artistsIdsPerYear,
  tracksMap,
  albums,
  artists,
  limitPerYear,
}: Props): AmazonMusicSerialisedStats {
  const albumsMap = new Map(albums.map((album) => [album.rawId, album]));
  const artistsMap = new Map(artists.map((artist) => [artist.rawId, artist]));

  const albumsStatsMap = new Map<RawId, AlbumStats>();
  const artistsStatsMap = new Map<RawId, ArtistStats>();

  const serialisedStats: AmazonMusicSerialisedStats = {
    tracks: {},
    albums: {},
    artists: {},
  };

  serialisedStats.tracks = Object.entries(groupedRecentlyPlayedTracks).reduce<AmazonMusicSerialisedStats['tracks']>(
    (resultsPerYear, [year, tracksStats]) => {
      resultsPerYear[year] = tracksStats
        .reduce<TrackStats[]>((results, singleTrackStats) => {
          const track = tracksMap.get(singleTrackStats.rawId);
          if (!track) {
            return results;
          }

          const albumId = track.additionalData?.albumId;
          if (albumId) {
            const album = albumsMap.get(albumId);
            if (album) {
              const existingAlbumStats = albumsStatsMap.get(albumId);
              if (!existingAlbumStats) {
                albumsStatsMap.set(album.rawId, {
                  ...album,
                  playCount: 1,
                  lastPlayed: singleTrackStats.lastPlayed,
                  listenTime: track.duration,
                });
              } else {
                albumsStatsMap.set(album.rawId, {
                  ...existingAlbumStats,
                  playCount: existingAlbumStats.playCount + 1,
                  listenTime:
                    isNumber(existingAlbumStats.listenTime) && isNumber(track.duration)
                      ? existingAlbumStats.listenTime + track.duration
                      : existingAlbumStats.listenTime,
                });
              }
            }
          }

          const artistsIds = track.additionalData?.artistsIds;
          if (artistsIds && artistsIds.length > 0) {
            artistsIds.forEach((artistId) => {
              const artist = artistsMap.get(artistId);
              if (artist) {
                const existingArtistStats = artistsStatsMap.get(artist.rawId);
                if (!existingArtistStats) {
                  artistsStatsMap.set(artist.rawId, {
                    ...artist,
                    playCount: 1,
                    lastPlayed: singleTrackStats.lastPlayed,
                    listenTime: track.duration,
                  });
                } else {
                  artistsStatsMap.set(artist.rawId, {
                    ...existingArtistStats,
                    playCount: existingArtistStats.playCount + 1,
                    listenTime:
                      isNumber(existingArtistStats.listenTime) && isNumber(track.duration)
                        ? existingArtistStats.listenTime + track.duration
                        : existingArtistStats.listenTime,
                  });
                }
              }
            });
          }

          if (results.length < limitPerYear) {
            const newTrackStats = {
              rawId: track.rawId,
              name: track.name,
              album: track.album,
              artist: track.artist,
              imageUrl: track.imageUrl,
              duration: track.duration,
              url: track.sourceURL,
              previewUrl: track.previewUrl,
              playCount: singleTrackStats.playCount,
              lastPlayed: singleTrackStats.lastPlayed,
            };
            return [...results, newTrackStats];
          }
          return results;
        }, [])
        .sort((a, b) => b.playCount - a.playCount);
      return resultsPerYear;
    },
    {}
  );

  albumsIdsPerYear.forEach((rawIdsSet, year) => {
    serialisedStats.albums[year] = Array.from(rawIdsSet.values())
      .reduce<AlbumStats[]>((results, rawId) => {
        const albumStats = albumsStatsMap.get(rawId);
        return albumStats ? [...results, albumStats] : results;
      }, [])
      .sort((a, b) => b.playCount - a.playCount)
      .slice(0, 30);
  });

  artistsIdsPerYear.forEach((rawIdsSet, year) => {
    serialisedStats.artists[year] = Array.from(rawIdsSet.values())
      .reduce<ArtistStats[]>((results, rawId) => {
        const artistStats = artistsStatsMap.get(rawId);
        return artistStats ? [...results, artistStats] : results;
      }, [])
      .sort((a, b) => b.playCount - a.playCount)
      .slice(0, 30);
  });

  return serialisedStats;
}
