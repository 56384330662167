import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { buildCoverUrl, createYandexAlbumUrl, getArtistsNamesAsString } from '../utils';

export class YandexAlbum extends GenericCollection {
  constructor(data: Omit<CollectionModelData, 'type'>) {
    super({ type: CollectionType.ALBUM, ...data });
  }

  static fromData(data: any, url?: string): YandexAlbum | null {
    if (!data?.id || !data.title) {
      return null;
    }
    return new YandexAlbum({
      rawId: `${data.id}`,
      name: data.title,
      itemCount: data.trackCount,
      additionalData: { imageUrl: buildCoverUrl(data.coverUri), artist: getArtistsNamesAsString(data.artists) },
      publicUrl: url ?? createYandexAlbumUrl(data.id),
    });
  }
}
