/* eslint-disable class-methods-use-this */
import { GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { SearchQueryProperties } from '../../generics/types';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { FileAPI } from './FileAPI';
import { FileAuthenticationData } from './types';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { fileBackup } from '../../musicServices/services/FileBackup';
import { ImporterID } from '../types';

export const FileImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.File;

  public static musicService = fileBackup;

  public static isBackupImporter = true;

  public authenticationData: FileAuthenticationData;

  private fileAPI: FileAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as FileAuthenticationData;
    this.fileAPI = new FileAPI(this.authenticationData.additionalData);
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as FileAuthenticationData;
    this.fileAPI = new FileAPI(this.authenticationData.additionalData);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async getPaginatedCollections(_onBatch: (collections: GenericCollection[]) => Promise<void>) {}

  async getCollection(collection: GenericCollection) {
    return Promise.resolve(collection);
  }

  async createCollection(collection: GenericCollection) {
    return Promise.resolve(collection);
  }

  async addItemToCollection(collection: GenericCollection, matchedItem: GenericMatchedItem) {
    return Promise.resolve(convertMatchedItemToCollectionItem(matchedItem));
  }

  async moveManyItems() {
    return Promise.resolve();
  }

  async removeItemsFromCollection(): Promise<void> {
    return Promise.resolve();
  }

  async matchItems(_queryProps: SearchQueryProperties) {
    return Promise.resolve([]);
  }

  async matchAlbums(_queryProps: SearchQueryProperties) {
    return Promise.resolve([]);
  }

  async reAuthenticate(_withData: GenericAuthenticationData) {
    return Promise.resolve({} as GenericAuthenticationData);
  }

  doesSupportReAuth() {
    return true;
  }

  public doesSupportAlbums(): boolean {
    return false;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return true;
  }

  async getPaginatedItems(
    _forCollection: GenericCollection,
    _onBatch: (items: GenericCollectionItem[]) => Promise<void>
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) {}

  public doesSupportSearchByISRC(): boolean {
    return false;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return true;
  }

  public doesSupportMovingManyItems(): boolean {
    return true;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }
};
