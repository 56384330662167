import React, { useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { tryParseInt } from 'fym-common/src/utils/tryParseInt';
import { TypedMemo } from '../../common/types';
import { SMLListViewProps } from './props';
import { useGetSmartLinksQuery } from '../../../redux/api/fymAPI';
import { SMLListViewRenderer } from './SMLListViewRenderer';
import { ErrorView } from '../../common/views/ErrorView/ErrorView';
import { useAccountId } from '../../../redux/slices/appInfo/hooks';
import { useQueryParam } from '../../../utils/useQueryParam';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';

const SMLListViewComponent: React.FC<SMLListViewProps> = ({ route }) => {
  const accountId = useAccountId();
  const pageString = useQueryParam('page', route, '0');
  const searchString = useQueryParam('search', route, '');
  const page = tryParseInt(pageString) ?? 0;
  const [inputSearchValue, setInputSearchValue] = useState<string>(searchString);
  const router = useSMLRouter();
  const {
    data: smartLinksResponse,
    isLoading: smartLinksLoading,
    error: smartLinksError,
    refetch: smartLinksRefetch,
  } = useGetSmartLinksQuery(
    { accountId: accountId ?? 0, page, search: searchString, limit: 16 },
    { skip: accountId === null }
  );

  const goToSmartLinksManageView = useCallback(
    (smartLinkId: number) => {
      if (accountId === null) {
        return;
      }
      router.push(SMLRoutes.manage, { accountId, smartLinkId });
    },
    [router, accountId]
  );

  const onPageChange = useCallback(
    (newPage: number) => {
      if (accountId === null) {
        return;
      }
      router.setParams({ accountId, page: newPage, search: searchString });
    },
    [accountId, router, searchString]
  );

  const onSearchSmartLink = useCallback(
    (newSearch: string) => {
      if (accountId === null) {
        return;
      }
      router.setParams({ accountId, page: 0, search: newSearch });
    },
    [accountId, router]
  );

  const onChangeDebounced = useMemo(() => {
    return debounce(onSearchSmartLink, 500);
  }, [onSearchSmartLink]);

  const onSearchChange = useCallback(
    (searched: string) => {
      setInputSearchValue(searched);
      onChangeDebounced(searched);
    },
    [onChangeDebounced]
  );

  if (smartLinksError) {
    return <ErrorView error={smartLinksError} retry={smartLinksRefetch} />;
  }

  return (
    <SMLListViewRenderer
      smartLinksResponse={smartLinksResponse}
      smartLinksLoading={smartLinksLoading}
      onPageChange={onPageChange}
      onSmartLinkClick={goToSmartLinksManageView}
      onSearchChange={onSearchChange}
      searchFromQuery={inputSearchValue}
    />
  );
};

export const SMLListView = TypedMemo(SMLListViewComponent);
