import { ReactComponent as NapsterLight } from '../../../../assets/images/music-services/napster-light.svg';
import { ReactComponent as NapsterDark } from '../../../../assets/images/music-services/napster-dark.svg';
import { ReactComponent as NapsterLightPadding } from '../../../../assets/images/music-services/napster-light-padding.svg';
import { ReactComponent as NapsterDarkPadding } from '../../../../assets/images/music-services/napster-dark-padding.svg';
import { ReactComponent as NapsterColorLight } from '../../../../assets/images/music-services/napster-color-light.svg';
import { ReactComponent as NapsterColorDark } from '../../../../assets/images/music-services/napster-color-dark.svg';
import { ReactComponent as NapsterColorLightPadding } from '../../../../assets/images/music-services/napster-color-light-padding.svg';
import { ReactComponent as NapsterColorDarkPadding } from '../../../../assets/images/music-services/napster-color-dark-padding.svg';
import { ReactComponent as NapsterDarkDanger } from '../../../../assets/images/music-services-danger/napster-dark-danger.svg';
import { ReactComponent as NapsterLightDanger } from '../../../../assets/images/music-services-danger/napster-light-danger.svg';
import { ReactComponent as NapsterLightDangerPadding } from '../../../../assets/images/music-services-danger/napster-light-padding-danger.svg';
import { ReactComponent as NapsterDarkDangerPadding } from '../../../../assets/images/music-services-danger/napster-dark-padding-danger.svg';
import { ReactComponent as NapsterDarkWarning } from '../../../../assets/images/music-services-warning/napster-dark-warning.svg';
import { ReactComponent as NapsterLightWarning } from '../../../../assets/images/music-services-warning/napster-light-warning.svg';
import { ReactComponent as NapsterLightWarningPadding } from '../../../../assets/images/music-services-warning/napster-light-padding-warning.svg';
import { ReactComponent as NapsterDarkWarningPadding } from '../../../../assets/images/music-services-warning/napster-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const napsterImages: ImporterImages = {
  logo: {
    light: NapsterLight,
    dark: NapsterDark,
  },
  logoPadding: {
    light: NapsterLightPadding,
    dark: NapsterDarkPadding,
  },
  logoColor: {
    light: NapsterColorLight,
    dark: NapsterColorDark,
  },
  logoColorPadding: {
    light: NapsterColorLightPadding,
    dark: NapsterColorDarkPadding,
  },
  logoOnBackground: NapsterDark,
  logoDanger: {
    dark: NapsterDarkDanger,
    light: NapsterLightDanger,
  },
  logoDangerPadding: {
    dark: NapsterDarkDangerPadding,
    light: NapsterLightDangerPadding,
  },
  logoWarning: {
    dark: NapsterDarkWarning,
    light: NapsterLightWarning,
  },
  logoWarningPadding: {
    light: NapsterLightWarningPadding,
    dark: NapsterDarkWarningPadding,
  },
};
