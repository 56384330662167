import React, { useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { faKey } from '@fortawesome/pro-solid-svg-icons/faKey';
import { faLockA } from '@fortawesome/pro-solid-svg-icons/faLockA';
import { passwordMinimumLength } from 'fym-common/src/joi/schemas';
import { FieldsName, FieldsType } from './consts';
import { createResolver } from './validators';
import { FormInput } from '../../../forms/components/FormInput/FormInput';
import { InputType } from '../../../forms/types';
import { useChangePasswordMutation } from '../../../../redux/api/fymAPI';
import { isSuccessfulResult } from '../../../../redux/api/utils/isSuccessfulResult';
import { ErrorAlert } from '../../../common/views/ErrorAlert/ErrorAlert';
import { SMLButton } from '../../../common/views/SMLButton/SMLButton';

export function useChangePasswordForm({
  authToken,
  userId,
}: {
  authToken: string | undefined;
  userId: number | undefined;
}) {
  const intl = useIntl();

  const isLoggedUser = !authToken;
  const resolver = useMemo(() => createResolver(isLoggedUser), [isLoggedUser]);
  const { control, handleSubmit, reset } = useForm<FieldsType>({
    resolver,
    defaultValues: {
      [FieldsName.currentPassword]: '',
      [FieldsName.newPassword]: '',
    },
  });

  const [changePassword, { isLoading, error, data }] = useChangePasswordMutation();
  const isSuccessful = !isLoading && !error && data !== undefined;
  const onSubmit: SubmitHandler<FieldsType> = useCallback(
    async ({ newPassword, currentPassword }) => {
      const results = await changePassword({
        newPassword,
        currentPassword,
        authToken,
        userId,
      });
      if (isSuccessfulResult(results)) {
        reset();
      }
    },
    [authToken, changePassword, reset, userId]
  );

  const submit = useCallback(
    (event?: React.BaseSyntheticEvent) => {
      handleSubmit(onSubmit)(event).catch(console.error);
    },
    [handleSubmit, onSubmit]
  );

  return {
    submit,
    CurrentPasswordInput: isLoggedUser ? (
      <FormInput
        name={FieldsName.currentPassword}
        control={control}
        type={InputType.password}
        disabled={isLoading}
        icon={faKey}
        placeholder={intl.formatMessage({
          id: 'app.forms.change-password.currentPassword.placeholder',
          defaultMessage: 'Your Current Password',
        })}
        label={intl.formatMessage({
          id: 'app.forms.change-password.currentPassword.label',
          defaultMessage: 'Current Password',
        })}
      />
    ) : null,
    NewPasswordInput: (
      <FormInput
        name={FieldsName.newPassword}
        control={control}
        type={InputType.password}
        disabled={isLoading}
        icon={faLockA}
        placeholder={intl.formatMessage({
          id: 'app.forms.change-password.newPassword.placeholder',
          defaultMessage: 'Best-Passwords-Are-Long-But-Easy-To-Remember-1!',
        })}
        label={intl.formatMessage({
          id: 'app.forms.change-password.newPassword.label',
          defaultMessage: 'New Password',
        })}
        help={intl.formatMessage(
          {
            id: 'app.forms.change-password.newPassword.help',
            defaultMessage: 'Minimum {length} characters',
          },
          {
            length: passwordMinimumLength,
          }
        )}
      />
    ),
    SubmitButton: (
      <SMLButton
        color="secondary"
        onClick={submit}
        disabled={isLoading}
        isLoading={isLoading}
        stretch
        omitCap
        size="lg"
        submit
      >
        {intl.formatMessage({ id: 'app.forms.buttons.change-password.label', defaultMessage: 'Change Password' })}
      </SMLButton>
    ),
    isSuccessful,
    ErrorView: <>{error && <ErrorAlert error={error} />}</>,
  };
}
