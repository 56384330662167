import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { decodeAPITokenJWT } from 'fym-common/src/utils/decodeAPITokenJWT';
import { TranslatedProgressMessageType } from 'fym-common/src/types/intl';
import { appInfoSliceName } from './constants';
import { AppInfoState } from './state';

type UpdateMessagePayload = { progress?: TranslatedProgressMessageType; id: string };
type SetRealmDataPayload = { realmPartition: string | undefined; realmJWT: string | undefined };
type SetUserTokenPayload = { token: string | null };
type SetDeviceIdPayload = { deviceId: string };
type SetAccountIdPayload = { accountId: number | null };
type SetIsOnlinePayload = { isOnline: boolean };
type SetIsServerUpPayload = { isServerUp: boolean };
type ToggleLicenseActivationModalPayload = { shouldShow: boolean };

const appInfoSlice = createSlice({
  name: appInfoSliceName,
  initialState: AppInfoState,
  reducers: {
    resetAppInfoSlice: () => AppInfoState,
    appUpdateMessage: (state, action: PayloadAction<UpdateMessagePayload>) => {
      state.progress[action.payload.id] = action.payload.progress;
    },
    setRealmData: (state, action: PayloadAction<SetRealmDataPayload>) => {
      state.realmPartition = action.payload.realmPartition;
      state.realmJWT = action.payload.realmJWT;
    },
    setUserToken: (state, action: PayloadAction<SetUserTokenPayload>) => {
      state.userToken = action.payload.token;
      state.userTokenDecoded = decodeAPITokenJWT(action.payload.token);
    },
    setDeviceId: (state, action: PayloadAction<SetDeviceIdPayload>) => {
      state.deviceId = action.payload.deviceId;
    },
    setAccountId: (state, action: PayloadAction<SetAccountIdPayload>) => {
      state.accountId = action.payload.accountId;
    },
    setIsOnline: (state, action: PayloadAction<SetIsOnlinePayload>) => {
      state.isOnline = action.payload.isOnline;
    },
    setIsServerUp: (state, action: PayloadAction<SetIsServerUpPayload>) => {
      state.isServerUp = action.payload.isServerUp;
    },
    toggleLicenseActivationModal: (state, action: PayloadAction<ToggleLicenseActivationModalPayload>) => {
      state.showLicenseActivationModal = action.payload.shouldShow;
    },
  },
});

const { actions, reducer } = appInfoSlice;
export const {
  resetAppInfoSlice,
  appUpdateMessage,
  setRealmData,
  setUserToken,
  setDeviceId,
  toggleLicenseActivationModal,
  setAccountId,
  setIsOnline,
  setIsServerUp,
} = actions;
export { reducer as appInfoReducer };
