import React, { useCallback } from 'react';
import { TypedMemo } from '../../../common/types';
import { ReleaseLinkItemProps } from './props';
import { ReleaseLinkItemRenderer } from './ReleaseLinkItemRenderer';
import { useExtractInfoFromReleaseData } from './useExtractInfoFromReleaseData';

const ReleaseLinkItemComponent: React.FC<ReleaseLinkItemProps> = ({ releaseLinkData, onDelete }) => {
  const { id, data } = releaseLinkData;
  const { title, text1, text2, ImporterLogo, importerColor } = useExtractInfoFromReleaseData(releaseLinkData);

  const handleDelete = useCallback(() => {
    onDelete?.(id);
  }, [onDelete, id]);

  if (!title) {
    return null;
  }

  return (
    <ReleaseLinkItemRenderer
      title={title}
      text1={text1}
      text2={text2}
      importerColor={importerColor ? importerColor.label : ''}
      imageURL={data.imageUrl}
      ImporterLogo={ImporterLogo}
      onDelete={onDelete && handleDelete}
    />
  );
};

export const ReleaseLinkItem = TypedMemo(ReleaseLinkItemComponent);
