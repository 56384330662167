import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LicenseStateType } from 'fym-common/src/license/types';
import { licenseSliceName } from './constants';
import { LicenseState } from './state';

const licenseSlice = createSlice({
  name: licenseSliceName,
  initialState: LicenseState,
  reducers: {
    resetLicenseState: () => LicenseState,
    setLicenseInfoResult: (state, action: PayloadAction<LicenseStateType>) => {
      return action.payload;
    },
  },
});

const { actions, reducer } = licenseSlice;
export const { setLicenseInfoResult, resetLicenseState } = actions;
export { reducer as licenseReducer };
