import qs from 'qs';
import concat from 'lodash/concat';
import { YouTubeMusicPlaylist } from './models/YouTubeMusicPlaylist';
import { YouTubeMusicPlaylistTrack } from './models/YouTubeMusicPlaylistTrack';
import { YouTubeMusicAlbumWithTracksResponse } from './models/YouTubeMusicAlbumWithTracksResponse';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { defaultRetryOptions, FetchRequestInit, fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { FetchError } from '../../generics/errors/FetchError';
import { YouTubeMusicAuthenticationData } from './YouTubeMusicAuthenticationData';
import { YouTubeMusicPlaylistsResponse } from './models/YouTubeMusicPlaylistsResponse';
import { YouTubeMusicAlbumsResponse } from './models/YouTubeMusicAlbumsResponse';
import {
  findObjectByKey,
  getContinuationResults,
  getShareID,
  nav,
  prepareBrowseData,
  YouTubeMusicParser,
} from './youTubeMusicParser';
import { YouTubeMusicPlaylistTracksResponse } from './models/YouTubeMusicPlaylistTracksResponse';
import { getUserAgent } from '../../utils/getUserAgent';
import { YouTubeMusicUser } from './models/YouTubeMusicUser';
import { YouTubeMusicSearchResponseSong } from './models/YouTubeMusicSearchResponseSong';
import { YouTubeMusicSearchResponseAlbum } from './models/YouTubeMusicSearchResponseAlbum';
import { wait } from '../../utils/wait';
import { ImporterID } from '../types';
import { getScriptsContents } from '../../utils/htmlUtils';
import { convertCookiesObjectToString } from '../../utils/cookies';
import { decodeUnicodeEntity } from '../../utils/htmlEntities';
import { CollectionType } from '../../generics/models/Collection';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
// @ts-ignore
import { SHA1 } from './sha1';

export class YouTubeMusicAPI {
  private static BASE_URL = 'https://music.youtube.com/';

  public static X_ORIGIN = 'https://music.youtube.com';

  public static LOGIN_URL =
    'https://accounts.google.com/ServiceLogin?continue=https%3A//www.youtube.com/signin?next%3Dhttps%253A%252F%252Fmusic.youtube.com%252Flibrary%252Fplaylists';

  private readonly cookies: Record<string, string> | undefined;

  private readonly userId: string;

  private channelId?: string;

  private readonly apiKey: string;

  private readonly visitorId: string;

  private readonly context: Record<string, any>;

  private readonly identityToken: string;

  constructor(
    cookies: Record<string, string>,
    apiKey: string,
    visitorId: string,
    context: Record<string, any>,
    identityToken: string,
    userId: string,
    channelId?: string
  ) {
    this.cookies = cookies;
    this.apiKey = apiKey;
    this.visitorId = visitorId;
    this.context = context;
    this.identityToken = identityToken;
    this.userId = userId;
    this.channelId = channelId;
  }

  public static async getYTMConfig(url: string, cookies?: Record<string, string>) {
    const options: FetchRequestInit & Required<Pick<FetchRequestInit, 'headers'>> = {
      method: 'GET',
      headers: {
        'User-Agent': getUserAgent(),
      },
    };
    if (cookies) {
      options.headers.Cookie = convertCookiesObjectToString(cookies);
    }
    const response = await fetchRetry(url, options);

    const HTMLString = await response.text();
    const scriptsContents = getScriptsContents(HTMLString);
    const ytmConfigString = scriptsContents
      .find((content) => content.includes('INNERTUBE_API_KEY'))
      ?.replace(/(\n)/g, '')
      ?.match(/ytcfg.set\((.*?)\);/)?.[1];

    if (!ytmConfigString) {
      throw new Error('Could not get YouTubeMusic ytmConfigString in getYTMConfig');
    }

    return JSON.parse(ytmConfigString);
  }

  public static async getAuthDataFromCookies(cookies: Record<string, string>) {
    const ytcfg = await YouTubeMusicAPI.getYTMConfig('https://music.youtube.com/library/playlists', cookies);
    const apiKey = ytcfg.INNERTUBE_API_KEY;
    const visitorId = ytcfg.VISITOR_DATA;
    const context = ytcfg.INNERTUBE_CONTEXT;
    const userId = (ytcfg.DATASYNC_ID ?? '').split('||')[0];
    const idToken = ytcfg.ID_TOKEN;

    const youTubeMusicAPI = new YouTubeMusicAPI(cookies, apiKey, visitorId, context, idToken, userId);
    const me = await youTubeMusicAPI.fetchMe();
    const email = await youTubeMusicAPI.fetchEmail();
    return youTubeMusicAPI.getAuthData(me, email);
  }

  private get requestHeaders() {
    const { cookies } = this;
    return {
      'Content-Type': 'application/json',
      Authorization: this.getAuthToken(),
      Cookie: cookies
        ? Object.keys(cookies)
            .map((key) => {
              return `${key}=${cookies[key]}`;
            })
            .join('; ')
        : '',
      'X-Goog-Visitor-Id': this.visitorId,
      Origin: YouTubeMusicAPI.X_ORIGIN,
      'X-Origin': YouTubeMusicAPI.X_ORIGIN,
      Referer: 'https://music.youtube.com/',
    };
  }

  checkIfAuthenticated() {
    if (this.cookies) {
      return;
    }
    throw new NotAuthenticatedError(
      {
        authId: this.userId ? this.userId : undefined,
        importerId: ImporterID.YouTubeMusic,
      },
      'Missing cookies'
    );
  }

  getAuthToken() {
    this.checkIfAuthenticated();
    const sapiSID = this.cookies?.SAPISID ?? this.cookies?.['__Secure-3PAPISID'];
    const time = Math.floor(Date.now() / 1e3);
    const value = [time, sapiSID, YouTubeMusicAPI.X_ORIGIN].join(' ');
    const sha1 = SHA1();
    sha1.update(value);
    const hash = sha1.v5().toLowerCase();
    const timeWithHash = [time, hash].join('_');
    return ['SAPISIDHASH', timeWithHash].join(' ');
  }

  async fetch(
    url: string,
    options: any,
    retryOptions: RetryOptions = defaultRetryOptions,
    authRetryCount = 0
  ): Promise<any> {
    const response = await fetchRetry(url, options, retryOptions);
    if (response.status === 403) {
      const text = await response.text();
      if (!this.userId) {
        throw new NotAuthenticatedError({ importerId: ImporterID.YouTubeMusic }, text);
      }
      throw new NotAuthenticatedError({ authId: this.userId, importerId: ImporterID.YouTubeMusic }, text);
    }
    const text = await response.text();
    let data: Record<string, any> | undefined;
    try {
      data = JSON.parse(text);
    } catch (e) {
      try {
        const secondLine = text.split('\n')[1];
        if (secondLine) {
          data = JSON.parse(secondLine);
        }
      } catch (err) {
        console.error(err);
        throw new FetchError(response.status, `Got wrong response when parsing JSON for ${url}`);
      }
    }
    if (data?.error) {
      if (data.error.code === 401) {
        if (authRetryCount > 4) {
          throw new NotAuthenticatedError(
            { authId: this.userId, importerId: ImporterID.YouTubeMusic },
            JSON.stringify(data)
          );
        }
        await wait(500);
        return this.fetch(url, options, retryOptions, authRetryCount + 1);
      }
      throw new FetchError(data.error.code, JSON.stringify(data));
    }
    return data;
  }

  async loadPaginatedPlaylistsItems(
    playlistId: string,
    onBatch: (tracks: YouTubeMusicPlaylistTrack[]) => Promise<void>
  ): Promise<void> {
    const loadPlaylistsItems = async (previousResponse?: YouTubeMusicPlaylistTracksResponse): Promise<void> => {
      const trackResponse = await this.loadPlaylistItemPage(playlistId, previousResponse);
      await onBatch(trackResponse.tracks);
      if (trackResponse.continuationToken) {
        await loadPlaylistsItems(trackResponse);
      }
    };

    await loadPlaylistsItems();
  }

  async loadPlaylistItemPage(
    playlistId: string,
    previousResponse?: YouTubeMusicPlaylistTracksResponse
  ): Promise<YouTubeMusicPlaylistTracksResponse> {
    const browseId = playlistId.startsWith('VL') ? playlistId : `VL${playlistId}`;
    const params: Record<string, any> = {
      alt: 'json',
      key: this.apiKey,
    };
    if (previousResponse && previousResponse.continuationToken) {
      params.ctoken = previousResponse.continuationToken;
      params.continuation = previousResponse.continuationToken;
    }
    const url = `${YouTubeMusicAPI.BASE_URL}youtubei/v1/browse?${qs.stringify(params)}`;
    try {
      const data = await this.fetch(url, {
        method: 'POST',
        headers: this.requestHeaders,
        body: JSON.stringify({
          context: this.context,
          ...prepareBrowseData('PLAYLIST', browseId),
        }),
      });
      if (previousResponse) {
        const { results, items } = getContinuationResults(data, 'musicPlaylistShelfContinuation');
        return new YouTubeMusicPlaylistTracksResponse(results, items, previousResponse.isOwnPlaylist, undefined);
      }

      const results = nav(data, concat(YouTubeMusicParser.SINGLE_COLUMN_TAB, YouTubeMusicParser.SECTION_LIST))[0]
        .musicPlaylistShelfRenderer;
      const isOwnPlaylist =
        !!data.header.musicEditablePlaylistDetailHeaderRenderer?.header?.musicDetailHeaderRenderer?.menu?.menuRenderer?.items?.find(
          (item: any) => {
            return item.menuNavigationItemRenderer?.icon?.iconType === 'DELETE';
          }
        );
      return new YouTubeMusicPlaylistTracksResponse(results, results.contents, isOwnPlaylist, data.header);
    } catch (error) {
      if (error instanceof FetchError) {
        if (error.code === 404) {
          throw new CollectionDoesNotExistsError();
        }
      }
      throw error;
    }
  }

  async loadAlbum(browseId: string): Promise<YouTubeMusicAlbumWithTracksResponse> {
    const url = `${YouTubeMusicAPI.BASE_URL}youtubei/v1/browse?${qs.stringify({
      alt: 'json',
      key: this.apiKey,
    })}`;
    const data = await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        context: this.context,
        ...prepareBrowseData('ALBUM', browseId),
      }),
    });

    return new YouTubeMusicAlbumWithTracksResponse(data, browseId);
  }

  async loadPaginatedPlaylists(onBatch: (collections: YouTubeMusicPlaylist[]) => Promise<void>): Promise<void> {
    const loadPlaylists = async (continuationToken?: string): Promise<void> => {
      const playlistResponse = await this.loadPlaylistPage(continuationToken);
      await onBatch(playlistResponse.playlists);
      if (playlistResponse.continuationToken) {
        await loadPlaylists(playlistResponse.continuationToken);
      }
    };

    await loadPlaylists();
  }

  async loadPlaylistPage(continuationToken?: string): Promise<YouTubeMusicPlaylistsResponse> {
    const params: Record<string, any> = {
      alt: 'json',
      key: this.apiKey,
    };
    if (continuationToken) {
      params.ctoken = continuationToken;
      params.continuation = continuationToken;
    }
    const url = `${YouTubeMusicAPI.BASE_URL}youtubei/v1/browse?${qs.stringify(params)}`;
    const data = await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        context: this.context,
        browseId: 'FEmusic_liked_playlists',
      }),
    });
    if (continuationToken) {
      const { results, items } = getContinuationResults(data, 'gridContinuation');
      return new YouTubeMusicPlaylistsResponse(results, items, this.channelId);
    }

    const objectList = nav(data, concat(YouTubeMusicParser.SINGLE_COLUMN_TAB, YouTubeMusicParser.SECTION_LIST));
    let results = objectList.find(findObjectByKey('itemSectionRenderer'));
    if (results) {
      results = nav(results, YouTubeMusicParser.ITEM_SECTION).gridRenderer;
    } else {
      results = objectList.find(findObjectByKey('gridRenderer'))?.gridRenderer;
    }
    if (!results) {
      throw new Error('Could not located list of playlists in YouTube Music response');
    }

    return new YouTubeMusicPlaylistsResponse(results, results.items.slice(1), this.channelId);
  }

  async loadPaginatedAlbums(onBatch: (collections: YouTubeMusicPlaylist[]) => Promise<void>): Promise<void> {
    const loadAlbums = async (continuationToken?: string): Promise<void> => {
      const albumPageResponse = await this.loadAlbumPage(continuationToken);
      await onBatch(albumPageResponse.albums);
      if (albumPageResponse.continuationToken) {
        await loadAlbums(albumPageResponse.continuationToken);
      }
    };

    await loadAlbums();
  }

  async loadAlbumPage(continuationToken?: string): Promise<YouTubeMusicAlbumsResponse> {
    const params: Record<string, any> = {
      alt: 'json',
      key: this.apiKey,
    };
    if (continuationToken) {
      params.ctoken = continuationToken;
      params.continuation = continuationToken;
    }
    const url = `${YouTubeMusicAPI.BASE_URL}youtubei/v1/browse?${qs.stringify(params)}`;
    const data = await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        context: this.context,
        browseId: 'FEmusic_liked_albums',
      }),
    });
    if (continuationToken) {
      const { results, items } = getContinuationResults(data, 'gridContinuation');
      return new YouTubeMusicAlbumsResponse(results, items);
    }

    const objectList = nav(data, concat(YouTubeMusicParser.SINGLE_COLUMN_TAB, YouTubeMusicParser.SECTION_LIST));

    let results = objectList.find(findObjectByKey('itemSectionRenderer'));
    if (results) {
      const patch = nav(results, YouTubeMusicParser.ITEM_SECTION);
      results = patch.messageRenderer || patch.gridRenderer;
    } else {
      results = objectList.find(findObjectByKey('gridRenderer'))?.gridRenderer;
    }
    if (!results) {
      console.error('Could not located list of albums in YouTube Music response');
      return new YouTubeMusicAlbumsResponse(undefined, undefined);
    }
    return new YouTubeMusicAlbumsResponse(results, results.items);
  }

  async createPlaylist(
    name: string,
    props?: { isPublic?: boolean; description?: string }
  ): Promise<string | undefined> {
    const { isPublic = false, description } = props ?? {};
    const url = `${YouTubeMusicAPI.BASE_URL}youtubei/v1/playlist/create?${qs.stringify({
      alt: 'json',
      key: this.apiKey,
    })}`;
    const data = await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        context: this.context,
        title: name,
        description,
        privacyStatus: isPublic ? 'PUBLIC' : 'PRIVATE',
        user: { onBehalfOfUser: this.userId, enableSafetyMode: false },
      }),
    });
    return data.playlistId;
  }

  async updatePlaylist(
    playlistId: string,
    { name, isPublic, description }: { name?: string; isPublic?: boolean; description?: string }
  ): Promise<void> {
    const url = `${YouTubeMusicAPI.BASE_URL}youtubei/v1/browse/edit_playlist?${qs.stringify({
      prettyPrint: false,
    })}`;
    const actions = [];
    if (name) {
      actions.push({
        action: 'ACTION_SET_PLAYLIST_NAME',
        playlistName: name,
      });
    }
    if (description) {
      actions.push({
        action: 'ACTION_SET_PLAYLIST_DESCRIPTION',
        playlistDescription: description,
      });
    }
    if (isPublic !== undefined) {
      actions.push({
        action: 'ACTION_SET_PLAYLIST_PRIVACY',
        playlistPrivacy: isPublic ? 'PUBLIC' : 'PRIVATE',
      });
    }
    if (actions.length === 0) {
      return;
    }
    await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        context: this.context,
        playlistId,
        actions,
      }),
    });
  }

  async removePlaylist(playlistId: string): Promise<void> {
    const url = `${YouTubeMusicAPI.BASE_URL}youtubei/v1/playlist/delete?${qs.stringify({
      prettyPrint: false,
    })}`;
    await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        context: this.context,
        playlistId,
      }),
    });
  }

  async getPlaylist(playlistId: string) {
    const url = `${YouTubeMusicAPI.BASE_URL}playlist?${qs.stringify({
      list: playlistId,
    })}`;
    const response = await fetchRetry(url, {
      method: 'GET',
      headers: {
        ...this.requestHeaders,
        'User-Agent':
          'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36',
      },
    });
    const HTMLString = await response.text();
    const scriptsContents = getScriptsContents(HTMLString);
    const encodedDataString = scriptsContents
      .find((content) => content.includes('const initialData = [];'))
      ?.replace(/(\n)/g, '')
      ?.match(/\/browse', params: JSON\.parse(.*?)data: '(.*?)'}\);ytcfg.set/)?.[2];
    if (!encodedDataString) {
      throw new Error('Could not get YouTubeMusic playlist data');
    }
    const decodedString = decodeUnicodeEntity(encodedDataString).replace(/\\"/g, '').replace(/\\/g, '');
    const data = JSON.parse(decodedString);
    return YouTubeMusicPlaylist.fromHTMLData(data);
  }

  async baseSearch(query: string, filter: 'albums' | 'songs' | 'videos'): Promise<any> {
    const url = `${YouTubeMusicAPI.BASE_URL}youtubei/v1/search?${qs.stringify({
      alt: 'json',
      key: this.apiKey,
    })}`;
    const body: Record<string, any> = {
      context: this.context,
      query,
    };
    const params = YouTubeMusicAPI.getSearchParam(filter);
    if (params) {
      body.params = params;
    }
    return this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify(body),
    });
  }

  async search(query: string, filter: 'songs' | 'videos'): Promise<YouTubeMusicSearchResponseSong> {
    if (!query) {
      return new YouTubeMusicSearchResponseSong(null, filter);
    }
    const data = await this.baseSearch(query, filter);
    return new YouTubeMusicSearchResponseSong(data, filter);
  }

  async searchAlbum(query: string): Promise<YouTubeMusicSearchResponseAlbum> {
    if (!query) {
      return new YouTubeMusicSearchResponseAlbum(null, 'albums');
    }
    const data = await this.baseSearch(query, 'albums');
    return new YouTubeMusicSearchResponseAlbum(data, 'albums');
  }

  static getSearchParam(filter?: 'albums' | 'songs' | 'videos') {
    switch (filter) {
      case 'songs':
        return 'EgWKAQIIAWoKEAkQAxAEEAoQBQ%3D%3D';
      case 'albums':
        return 'EgWKAQIYAWoKEAUQAxAEEAkQCg%3D%3D';
      case 'videos':
        return 'EgWKAQIQAWoKEAMQBBAKEAUQCQ%3D%3D';
      default:
        return undefined;
    }
  }

  async addTrackToPlaylist(playlistId: string, videoId: string): Promise<string> {
    const url = `${YouTubeMusicAPI.BASE_URL}youtubei/v1/browse/edit_playlist?${qs.stringify({
      alt: 'json',
      key: this.apiKey,
    })}`;
    const options = {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        context: this.context,
        playlistId,
        actions: [
          {
            action: 'ACTION_ADD_VIDEO',
            addedVideoId: videoId,
          },
        ],
      }),
    };
    const data = await this.fetch(url, options);
    return data?.playlistEditResults?.[0]?.playlistEditVideoAddedResultData?.setVideoId;
  }

  async addAlbumToLibrary(playlistId: string): Promise<void> {
    const url = `${YouTubeMusicAPI.BASE_URL}youtubei/v1/like/like?${qs.stringify({
      alt: 'json',
      key: this.apiKey,
    })}`;
    await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        context: this.context,
        target: {
          playlistId,
        },
      }),
    });
  }

  async removeTracksFromPlaylist(playlistId: string, trackIds: string[]): Promise<void> {
    if (trackIds.length === 0) {
      return;
    }
    const url = `${YouTubeMusicAPI.BASE_URL}youtubei/v1/browse/edit_playlist?${qs.stringify({
      key: this.apiKey,
      prettyPrint: false,
    })}`;
    await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        context: this.context,
        playlistId,
        actions: trackIds.map((tId) => ({
          setVideoId: tId,
          action: 'ACTION_REMOVE_VIDEO',
        })),
      }),
    });
  }

  async moveTrackInPlaylist(playlistId: string, trackId: string, idOfNextTrack: string | null): Promise<void> {
    const url = `${YouTubeMusicAPI.BASE_URL}youtubei/v1/browse/edit_playlist?${qs.stringify({
      alt: 'json',
      key: this.apiKey,
    })}`;
    await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        context: this.context,
        playlistId,
        actions: {
          setVideoId: trackId,
          movedSetVideoIdSuccessor: idOfNextTrack,
          action: 'ACTION_MOVE_VIDEO_BEFORE',
        },
      }),
    });
  }

  async getShareUrl(playlistId: string): Promise<string> {
    const playlistData = await this.fetch(
      `${YouTubeMusicAPI.BASE_URL}youtubei/v1/browse/edit_playlist?${qs.stringify({
        alt: 'json',
        key: this.apiKey,
      })}`,
      {
        method: 'POST',
        headers: this.requestHeaders,
        body: JSON.stringify({
          context: this.context,
          playlistId,
          actions: [
            {
              action: 'ACTION_SET_PLAYLIST_PRIVACY',
              playlistPrivacy: 'PUBLIC',
            },
          ],
        }),
      }
    );
    const shareId = getShareID(playlistData.newHeader);
    const url = `${YouTubeMusicAPI.BASE_URL}youtubei/v1/share/get_share_panel?${qs.stringify({
      alt: 'json',
      key: this.apiKey,
    })}`;
    const data = await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        context: this.context,
        serializedSharedEntity: shareId,
      }),
    });
    return data.actions[0].openPopupAction.popup.unifiedSharePanelRenderer.contents[0].thirdPartyNetworkSection
      .copyLinkContainer.copyLinkRenderer.shortUrl;
  }

  getAuthData(user: YouTubeMusicUser, email: string | undefined): YouTubeMusicAuthenticationData {
    return {
      authId: this.userId,
      userUUID: null,
      title: user.name,
      subTitle: email ?? null,
      imageUrl: user.picture,
      additionalData: {
        cookies: this.cookies ?? {},
        apiKey: this.apiKey,
        visitorId: this.visitorId,
        context: this.context,
        identityToken: this.identityToken,
        channelId: user.channelId,
      },
      expiresAt: null,
    };
  }

  async fetchMe(): Promise<YouTubeMusicUser> {
    const url = `${YouTubeMusicAPI.BASE_URL}youtubei/v1/account/account_menu?${qs.stringify({
      alt: 'json',
      key: this.apiKey,
    })}`;
    const options = {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        context: this.context,
      }),
    };
    const data = await this.fetch(url, options);
    const user = new YouTubeMusicUser(data);
    this.channelId = user.channelId;
    return user;
  }

  async fetchEmail(): Promise<string | undefined> {
    const url = `${YouTubeMusicAPI.BASE_URL}getAccountSwitcherEndpoint?${qs.stringify({
      alt: 'json',
      key: this.apiKey,
    })}`;
    const options = {
      method: 'GET',
      headers: this.requestHeaders,
    };
    const data = await this.fetch(url, options);
    return data.data.actions?.[0]?.getMultiPageMenuAction?.menu?.multiPageMenuRenderer?.sections?.[0]
      ?.accountSectionListRenderer?.header?.googleAccountHeaderRenderer?.email?.simpleText;
  }

  async fetchRecap(): Promise<YouTubeMusicPlaylist[] | undefined> {
    const response = await fetchRetry(`${YouTubeMusicAPI.BASE_URL}recap`, {
      method: 'GET',
      headers: { ...this.requestHeaders, 'User-Agent': getUserAgent() },
    });
    const HTMLString = await response.text();
    const scriptsContents = getScriptsContents(HTMLString);
    const initialDataMatches = scriptsContents
      .find((content) => content.includes('initialData.push'))
      ?.replace(/(\n)/g, '')
      ?.match(/initialData.push\((.*?)\);/g);
    const browseInitialData = initialDataMatches
      ?.find((jsString) => jsString.includes(`'\\/browse'`))
      ?.match(/data: '(.+)'}\);/)?.[1];
    if (!browseInitialData) {
      return undefined;
    }
    const jsonInitialData = JSON.parse(decodeUnicodeEntity(browseInitialData));
    const isLoggedIn =
      jsonInitialData.responseContext.serviceTrackingParams
        .find((el: any) => el.service === 'GFEEDBACK')
        ?.params.find((el: any) => el.key === 'logged_in')?.value === '1';
    if (!isLoggedIn) {
      if (!this.userId) {
        throw new NotAuthenticatedError({ importerId: ImporterID.YouTubeMusic }, 'Not logged in');
      }
      throw new NotAuthenticatedError(
        {
          authId: this.userId,
          importerId: ImporterID.YouTubeMusic,
        },
        'Not logged in'
      );
    }
    const rowsData = jsonInitialData.contents?.singleColumnBrowseResultsRenderer?.tabs?.[0]?.tabRenderer?.content
      ?.sectionListRenderer?.contents as any[] | undefined;
    const recapData: any = rowsData?.find((rowElement) => {
      const contents = rowElement?.musicCarouselShelfRenderer?.contents;
      return contents?.find((contentElement: any) => {
        const thumbnails = contentElement?.musicTwoRowItemRenderer?.thumbnailRenderer?.musicThumbnailRenderer?.thumbnail
          ?.thumbnails as any[];
        return thumbnails?.find((thumbnailElement: any) => {
          return thumbnailElement?.url?.search('listening_review');
        });
      });
    });
    return recapData?.musicCarouselShelfRenderer?.contents
      ?.map((item: any) => YouTubeMusicPlaylist.fromData(item, undefined, CollectionType.PLAYLIST))
      .filter((playlist: YouTubeMusicPlaylist | null) => !!playlist);
  }
}
