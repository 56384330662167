import { useCallback } from 'react';
import { useRemoveAccountDataMutation } from '../../../../../redux/api/fymAPI';
import { reloadApp } from '../../../../../utils/reloadApp';

export const useRemoveAccountData = () => {
  const [removeAccount, { isLoading, error }] = useRemoveAccountDataMutation();

  const handleRemoveAccountData = useCallback(() => {
    removeAccount()
      .then(() => reloadApp())
      .catch(console.error);
  }, [removeAccount]);

  return {
    handleRemoveAccountData,
    isLoading,
    error,
  };
};
