import React from 'react';
import { useIntl } from 'react-intl';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'reactstrap';
import { TypedMemo } from '../../common/types';
import { SMLHomeViewRendererProps } from './props';
import { SmartLinkInfoBox } from '../smartLinkInfoBox/SmartLinkInfoBox';
import { SMLListItemBox } from '../smlListItemBox/SMLListItemBox';
import { SMLCreationBox } from '../smlCreationBox/SMLCreationBox';
import { SMLBioLinkBox } from '../smlBioLinkBox/SMLBioLinkBox';
import { ClickableDiv } from '../../common/views/ClickableDiv';
import { AccountStatsView } from '../accountStatsView/AccountStatsView';
import { SMLContainer } from '../smlContainer/SMLContainer';

const SMLHomeViewRendererComponent: React.FC<SMLHomeViewRendererProps> = ({
  account,
  smartLinksResponse,
  smartLinksLoading,
  onSmartLinkClick,
  onSeeAllClick,
  hasMoreSmartLinks,
}) => {
  const intl = useIntl();
  return (
    <SMLContainer header={null}>
      <Row className="justify-content-between mb-3">
        <Col xs={9}>
          <h3>
            {intl.formatMessage({ id: 'app.smart-links.home.your_smartlinks', defaultMessage: 'Your Smart Links' })}
          </h3>
        </Col>
        {hasMoreSmartLinks && (
          <Col xs={3} className="d-flex justify-content-end">
            <ClickableDiv onClick={onSeeAllClick} className="sml-home-link d-flex align-items-center">
              <span>{intl.formatMessage({ id: 'app.smart-links.home.see_more', defaultMessage: 'See more' })}</span>
              <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
            </ClickableDiv>
          </Col>
        )}
      </Row>

      {smartLinksLoading || !smartLinksResponse ? (
        <Row className="justify-content-center align-items-center h-100 mb-5">
          <SmartLinkInfoBox
            text={intl.formatMessage({
              id: 'app.smart-links.loading',
              defaultMessage: `Loading... Please wait`,
            })}
          />
        </Row>
      ) : (
        <Row className="align-items-center mb-5">
          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
            <SMLCreationBox accountId={account.id} />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
            <SMLBioLinkBox accountId={account.id} mainSmartLinkId={account.mainSmartLinkId} />
          </Col>

          {smartLinksResponse.results.map((smartLink) => {
            return (
              <Col key={smartLink.id} xs={12} sm={6} md={4} lg={3} className="mb-3">
                <SMLListItemBox smartLink={smartLink} onClick={onSmartLinkClick} />
              </Col>
            );
          })}
        </Row>
      )}
      <AccountStatsView account={account} />
    </SMLContainer>
  );
};

export const SMLHomeViewRenderer = TypedMemo(SMLHomeViewRendererComponent);
