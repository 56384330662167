import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { urlSchema } from 'fym-common/src/joi/schemas';
import { FieldsName } from './consts';

const schema = Joi.object({
  [FieldsName.url]: urlSchema.required(),
  [FieldsName.label]: Joi.string().label('Label').optional().allow(''),
  [FieldsName.smartLinkId]: Joi.number().label('SmartLinkId').optional(),
});

export const resolver = joiResolver(schema);
