import { GaanaCollectionTrack } from './GaanaCollectionTrack';
import { tryParseInt } from '../../../utils/tryParseInt';

export class GaanaLikedTracksResponse {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get tracks(): GaanaCollectionTrack[] {
    if (!this.data?.tracks) {
      return [];
    }

    return this.data.tracks
      .map((item: any) => GaanaCollectionTrack.fromData(item))
      .filter((track: GaanaCollectionTrack | null) => !!track);
  }

  get tracksTotal(): number {
    return tryParseInt(this.data.count) ?? 0;
  }
}
