import React, { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize, words } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { VerticalPosition } from 'fym-common/src/utils/types';
import { TypedMemo } from '../../types';
import { CenterFixedContainer } from '../centerFixedContainer/CenterFixedContainer';
import { FYMButtonProps } from './props';
import { LoadingIcon } from '../LoadingIcon';

const FYMButtonComponent: React.FC<React.PropsWithChildren<FYMButtonProps>> = ({
  children,
  color = 'primary',
  position = VerticalPosition.bottom,
  disabled = false,
  fixed = false,
  onClick,
  testID = '',
  doNotPassEvent = false,
  size = 'md',
  outline = false,
  leftIcon,
  rightIcon,
  isLoading = false,
  error,
  stretch = false,
  omitCap = false,
  submit = false,
}) => {
  const isGradient = color === 'gradient';
  const uniqueId = useMemo(() => `fymbutton-${uuidv4()}`, []);
  const [shouldHideTooltip, setShouldHideTooltip] = useState(false);

  const handleOnClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      if (!onClick || submit) {
        return;
      }
      onClick(doNotPassEvent ? undefined : event);
    },
    [doNotPassEvent, onClick, submit]
  );

  const onTooltipClick = useCallback(() => {
    setShouldHideTooltip(true);
  }, []);

  useEffect(() => {
    if (error) {
      setShouldHideTooltip(false);
    }
  }, [error]);

  const CustomButton = (
    <>
      <div
        id={uniqueId}
        className={classnames('fym-button-container', {
          'fym-button-outline-gradient-container': isGradient && outline,
          disabled,
          'w-100': stretch,
        })}
      >
        <Button
          disabled={disabled || isLoading}
          onClick={handleOnClick}
          color={color}
          size={size}
          outline={outline}
          type={submit ? 'submit' : 'button'}
          className={classnames('fym-button', testID, {
            outline,
            'fym-button-gradient': isGradient,
            'w-100': stretch,
          })}
        >
          {!isLoading && leftIcon !== undefined && <FontAwesomeIcon icon={leftIcon} className="me-2" />}
          {isLoading && (
            <div className="me-2">
              <LoadingIcon small />
            </div>
          )}
          {typeof children === 'string' && !omitCap ? words(children).map(capitalize).join(' ') : children}
          {!isLoading && rightIcon !== undefined && <FontAwesomeIcon icon={rightIcon} className="ms-2" />}
        </Button>
      </div>
      <Tooltip
        target={uniqueId}
        isOpen={!!error && !shouldHideTooltip}
        className="fym-button-tooltip-error"
        onClick={onTooltipClick}
      >
        {`${error}`}
      </Tooltip>
    </>
  );

  if (!fixed) {
    return CustomButton;
  }

  return (
    <CenterFixedContainer position={position}>
      <div className="my-3">{CustomButton}</div>
    </CenterFixedContainer>
  );
};

export const FYMButton = TypedMemo(FYMButtonComponent);
