import { SoundClodTrackRawResponse } from './responseTypes';
import { convertSoundCloudTrackData } from '../utils/convertSoundCloudTrackData';
import { GenericMatchedItem } from '../../../generics/models/GenericMatchedItem';

export class SoundCloudMatchedTrack extends GenericMatchedItem {
  static fromData(data: SoundClodTrackRawResponse | undefined, url?: string): SoundCloudMatchedTrack | null {
    const trackData = convertSoundCloudTrackData(data, url);
    return trackData ? new SoundCloudMatchedTrack(trackData) : null;
  }
}
