import React from 'react';
import { LicenseType } from 'fym-common/src/license/consts';
import { TypedMemo } from '../../../../common/types';
import { FeatureBoxProps } from './props';
import { FeatureBoxRenderer } from './FeatureBoxRenderer';
import { FeatureLabel } from '../types';

const SmartLinksFeatureBoxComponent: React.FC<FeatureBoxProps> = ({ feature, color, licenseInfo }) => {
  const currentPlan = licenseInfo.currentPlan ?? -1;

  const isSmartLinksInfluencerDisabled =
    feature.labels !== undefined &&
    feature.labels.includes(FeatureLabel.SmartLinksInfluencer) &&
    !feature.labels.includes(FeatureLabel.SmartLinksRookie) &&
    (currentPlan < LicenseType.SmartLinksInfluencer || currentPlan === LicenseType.SmartLinksRookie);
  const isSmartLinksArtistDisabled =
    feature.labels !== undefined &&
    feature.labels.includes(FeatureLabel.SmartLinksArtist) &&
    !feature.labels.includes(FeatureLabel.SmartLinksRookie) &&
    !feature.labels.includes(FeatureLabel.SmartLinksInfluencer) &&
    (currentPlan < LicenseType.SmartLinksInfluencer || currentPlan === LicenseType.SmartLinksRookie);
  return (
    <FeatureBoxRenderer
      feature={feature}
      color={color}
      isDisabled={isSmartLinksArtistDisabled || isSmartLinksInfluencerDisabled}
    />
  );
};

export const SmartLinksFeatureBox = TypedMemo(SmartLinksFeatureBoxComponent);
