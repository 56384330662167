import React from 'react';
import { Button, Modal } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { TypedMemo } from '../../common/types';
import { NotSupportedUpgradeViewProps } from './types';
import { transactionVendorToPlatformString, transactionVendorToString } from '../../../realm/LicenseInfo';

const NotSupportedUpgradeViewRendererComponent: React.FC<NotSupportedUpgradeViewProps> = ({
  isShown,
  onClose,
  licenseInfo,
}) => {
  const intl = useIntl();
  return (
    <Modal isOpen={isShown} toggle={onClose} className="confirmation-modal-container" centered>
      <div className="confirmation-modal-content py-5">
        <div className="mb-5">
          <h3 className="text-center px-3">
            {intl.formatMessage({
              defaultMessage: 'Almost there...',
              id: 'app.payments.not-supported.title',
            })}
          </h3>
          <p className="text-center px-2">
            {intl.formatMessage(
              {
                defaultMessage: 'You are subscribed via {vendor}, and unfortunately we cannot upgrade your plan here.',
                id: 'app.payments.not-supported.description',
              },
              {
                vendor: transactionVendorToString(licenseInfo.transactionVendor),
              }
            )}
          </p>
          <p className="text-center px-2">
            {intl.formatMessage(
              {
                defaultMessage: 'Please use FreeYourMusic for {platform} to make a purchase.',
                id: 'app.payments.not-supported.explanation',
              },
              {
                platform: transactionVendorToPlatformString(licenseInfo.transactionVendor),
              }
            )}
          </p>
        </div>
        <div className="d-flex justify-content-around">
          <Button color="light" onClick={onClose}>
            <FormattedMessage id="app.modals.unlink_account.close" defaultMessage="CLOSE" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const NotSupportedUpgradeViewRenderer = TypedMemo(NotSupportedUpgradeViewRendererComponent);
