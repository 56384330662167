import { useSMLRoute } from '../../../../routing/sml/hooks';
import { useUserToken } from '../../../../redux/slices/appInfo/hooks';
import { SMLRoutes } from '../../../../routing/sml/SMLRoutes';

export function useShouldShowNavbar(): boolean {
  const route = useSMLRoute();
  const userToken = useUserToken();
  return !!(
    route &&
    ![SMLRoutes.onboarding1, SMLRoutes.onboarding2, SMLRoutes.onboarding3].includes(route) &&
    userToken
  );
}
