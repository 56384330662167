export class QQMusicRefreshTokenResponse {
  private readonly data;

  constructor(data: any) {
    this.data = data;
  }

  get refreshToken() {
    return this.data.wxrefresh_token;
  }

  get accessToken() {
    return this.data.wxaccess_token;
  }

  get musicUin() {
    return this.data.music_uin;
  }

  get musicKey() {
    return this.data.music_key;
  }

  get openId() {
    return this.data.wxopenid;
  }
}
