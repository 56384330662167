import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons/faCircleNotch';
import { LoadingViewProps } from './LoadingViewProps';
import { HorizontalVerticalCenterContainer } from './HorizontalVerticalCenterContainer';
import { TypedMemo } from '../types';

const LoadingViewComponent: React.FC<React.PropsWithChildren<LoadingViewProps>> = ({
  label,
  sublabel,
  small = false,
  children,
}) => {
  return (
    <HorizontalVerticalCenterContainer>
      <div className="text-center">
        <FontAwesomeIcon icon={faCircleNotch} size={small ? '1x' : '2x'} spin />
        {label && <h4 className="pt-1 text-center ui-text">{label}</h4>}
        {sublabel && <span className="pt-1 text-center ui-text">{sublabel}</span>}
      </div>
      {children}
    </HorizontalVerticalCenterContainer>
  );
};

export const LoadingView = TypedMemo(LoadingViewComponent);
