import { TidalAlbum } from './TidalAlbum';

export class TidalAlbumsResponse {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get albums(): TidalAlbum[] {
    if (!this.data?.items) {
      return [];
    }
    return this.data.items
      .map((item: any) => TidalAlbum.fromData(item.item))
      .filter((album: TidalAlbum | null) => !!album);
  }

  get totalNumberOfItems(): number {
    return this.data.totalNumberOfItems;
  }
}
