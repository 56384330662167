export enum MusicAPIIntegrationID {
  Spotify = 'spotify',
  Youtube = 'youtube',
  AppleMusic = 'appleMusic',
  AmazonMusic = 'amazonMusic',
  Tidal = 'tidal',
  Resso = 'resso',
  Boomplay = 'boomplay',
  Deezer = 'deezer',
  Pandora = 'pandora',
  SoundCloud = 'soundCloud',
  YoutubeMusic = 'youtubeMusic',
  Napster = 'napster',
  Qobuz = 'qobuz',
  QQMusic = 'qqMusic',
  YandexMusic = 'yandexMusic',
  VKMusic = 'vkMusic',
  Anghami = 'anghami',
  Zvuk = 'zvuk',
  Gaana = 'gaana',
  JioSaavn = 'jiosaavn',
}
