import { nav, YouTubeMusicParser } from '../youTubeMusicParser';

export abstract class YouTubeMusicSearchResponse<T> {
  private readonly data;

  readonly filter: 'albums' | 'songs' | 'videos';

  public readonly items: T[];

  constructor(data: Record<string, any> | null, filter: 'albums' | 'songs' | 'videos') {
    this.data = data;
    this.filter = filter;
    this.items = this.parseSearchResults();
  }

  parseSearchResults(): T[] {
    if (!this.data?.contents) {
      // No results
      return [];
    }

    let results = this.data.contents;
    if (results.tabbedSearchResultsRenderer) {
      results = results.tabbedSearchResultsRenderer.tabs[0].tabRenderer.content;
    }
    results = nav(results, YouTubeMusicParser.SECTION_LIST);
    if (!results) {
      return [];
    }
    const hasElementForMissingResults = results.find(
      (res: any) => res.itemSectionRenderer?.contents?.[0]?.messageRenderer?.icon?.iconType === 'SEARCH'
    );
    if (hasElementForMissingResults) {
      // This means, there is an element "Try other query as Google did not find anything" etc.
      return [];
    }

    const arrayOfResult: T[] = [];
    results.forEach((res: any) => {
      let resLet = res;
      if (resLet.musicShelfRenderer) {
        resLet = resLet.musicShelfRenderer.contents;
      }
      if (resLet.itemSectionRenderer) {
        return;
      }
      resLet.forEach((anotherResult: any) => {
        const data = anotherResult.musicResponsiveListItemRenderer;
        const searchResult = this.parseItem(data);
        if (searchResult !== undefined && searchResult !== null) {
          arrayOfResult.push(searchResult);
        }
      });
    });
    return arrayOfResult;
  }

  abstract parseItem(data: any): T | null;

  get firstItem(): T | undefined {
    if (this.items.length === 0) {
      return undefined;
    }
    return this.items[0];
  }
}
