import { TidalMatchedTrack } from './TidalMatchedTrack';
import { TidalAlbum } from './TidalAlbum';

export class TidalSearchResponse {
  private readonly data;

  constructor(data: Record<string, any> | null) {
    this.data = data;
  }

  get tracks(): TidalMatchedTrack[] {
    if (!this.data?.tracks?.items) {
      return [];
    }
    return this.data.tracks.items
      .map((item: any) => TidalMatchedTrack.fromData(item))
      .filter((track: TidalMatchedTrack | null) => !!track);
  }

  get albums(): TidalAlbum[] {
    if (!this.data?.albums?.items) {
      return [];
    }
    return this.data.albums.items
      .map((item: any) => TidalAlbum.fromData(item))
      .filter((album: TidalAlbum | null) => !!album);
  }

  get firstTrack(): TidalMatchedTrack | undefined {
    if (this.tracks.length === 0) {
      return undefined;
    }
    return this.tracks[0];
  }

  get firstAlbum(): TidalAlbum | undefined {
    if (this.albums.length === 0) {
      return undefined;
    }
    return this.albums[0];
  }

  get totalTracks(): number | undefined {
    return this.data?.tracks?.totalNumberOfItems;
  }

  get totalAlbums(): number | undefined {
    return this.data?.albums?.totalNumberOfItems;
  }
}
