import React from 'react';
import { TypedMemo } from '../../common/types';
import { Title } from '../../common/views/title/Title';
import { PaymentPlanHeaderProps } from './props';

const PaymentPlanHeaderComponent: React.FC<PaymentPlanHeaderProps> = ({ title, subtitle }) => {
  return (
    <div className="mb-3 ui-text">
      <Title center bold>
        {title}
      </Title>
      <div className="text-center fw-light">{subtitle}</div>
    </div>
  );
};

export const PaymentPlanHeader = TypedMemo(PaymentPlanHeaderComponent);
