import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { emailSchema } from 'fym-common/src/joi/schemas';
import { FieldsName } from './consts';
import { StoreType } from '../../../../redux/store';
import { getValidateEmailFn } from '../../../forms/utils/getValidateEmailFn';

export function createResolver(store: StoreType) {
  const validateEmail = getValidateEmailFn(store);
  const schema = Joi.object({
    [FieldsName.email]: emailSchema.label('Email').required().external(validateEmail),
    [FieldsName.password]: Joi.string().label('Password').required(),
    [FieldsName.name]: Joi.string().label('Name').required(),
  });
  return joiResolver(schema);
}
