import React, { useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { SerializedInfoWidget } from 'fym-common/src/db/serializersTypes';
import { InfoWidgetType } from 'fym-common/src/db/enums';
import { TypedMemo } from '../../common/types';
import { FieldsName, FieldsType } from './consts';
import { FormInput } from '../components/FormInput/FormInput';
import { Spacer } from '../../common/views/spacer/Spacer';
import { resolver } from './validators';
import { SMLFormContainer } from '../../smartLinks/SMLFormContainer/SMLFormContainer';
import { FormRadioButtons } from '../components/FormRadioButton/FormRadioButtons';
import { InputType } from '../types';
import { ErrorAPIResult } from '../../../redux/api/utils/isSuccessfulResult';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';

export interface InfoWidgetFormProps {
  widget?: SerializedInfoWidget | null;
  onSubmit: SubmitHandler<FieldsType>;
  onDelete: () => void;
  title: string;
  isLoading?: boolean;
  error?: ErrorAPIResult['error'];
  accountId: number;
  smartLinkId: number;
}

const InfoWidgetFormComponent: React.FC<InfoWidgetFormProps> = ({
  widget,
  onSubmit,
  onDelete,
  title,
  isLoading,
  error,
  accountId,
  smartLinkId,
}) => {
  const intl = useIntl();
  const { control, handleSubmit } = useForm<FieldsType>({
    resolver,
    defaultValues: {
      [FieldsName.type]: widget?.data.type ?? InfoWidgetType.title,
      [FieldsName.text]: widget?.data.text ?? '',
    },
  });

  const submit = useCallback(
    (event?: React.BaseSyntheticEvent) => {
      handleSubmit(onSubmit)(event).catch(console.error);
    },
    [handleSubmit, onSubmit]
  );

  const backRouteParams = useMemo(() => ({ accountId, smartLinkId }), [accountId, smartLinkId]);
  return (
    <SMLFormContainer
      backRoute={SMLRoutes.details}
      backRouteParams={backRouteParams}
      title={title}
      onSubmit={submit}
      onDelete={widget ? onDelete : undefined}
      isLoading={isLoading}
      error={error}
    >
      <Spacer flex flexDirection="column">
        <Spacer flex flexDirection="row">
          <FormRadioButtons
            options={[
              {
                displayLabel: intl.formatMessage({
                  id: 'app.forms.widget.type-option.title',
                  defaultMessage: 'Title',
                }),
                value: InfoWidgetType.title,
              },
              {
                displayLabel: intl.formatMessage({
                  id: 'app.forms.widget.type-option.normal',
                  defaultMessage: 'Normal',
                }),
                value: InfoWidgetType.normal,
              },
              {
                displayLabel: intl.formatMessage({
                  id: 'app.forms.widget.type-option.Small',
                  defaultMessage: 'Small',
                }),
                value: InfoWidgetType.small,
              },
            ]}
            control={control}
            name={FieldsName.type}
            disabled={isLoading}
            label={intl.formatMessage({
              id: 'app.forms.widget.type',
              defaultMessage: 'Type',
            })}
          />
        </Spacer>
        <Spacer marginRight={15} />
        <FormInput
          name={FieldsName.text}
          control={control}
          type={InputType.textarea}
          disabled={isLoading}
          label={intl.formatMessage({
            id: 'app.forms.widget.text',
            defaultMessage: 'Text',
          })}
        />
      </Spacer>
    </SMLFormContainer>
  );
};

export const InfoWidgetForm = TypedMemo(InfoWidgetFormComponent);
