import { SpotifyCollectionTrack } from './SpotifyCollectionTrack';
import { SpotifyPlaylistTracksResponseType } from './types';

export class SpotifyPlaylistTracksResponse {
  private readonly data;

  constructor(data: SpotifyPlaylistTracksResponseType | undefined) {
    this.data = data;
  }

  get tracks(): SpotifyCollectionTrack[] {
    if (!this.data?.items) {
      return [];
    }
    return this.data.items
      .map((item) => SpotifyCollectionTrack.fromData(item.track))
      .filter((item): item is SpotifyCollectionTrack => !!item);
  }

  get next() {
    return this.data?.next;
  }

  get total() {
    return this.data?.total;
  }
}
