import { InfoWidgetType } from 'fym-common/src/db/enums';

export enum FieldsName {
  text = 'text',
  type = 'type',
}

export type FieldsType = {
  [FieldsName.text]: string;
  [FieldsName.type]: InfoWidgetType;
};
