import { BASE_SONGS_LIMIT, LicenseModelTransactionVendor } from './consts';

export const noLicense = {
  isValid: false,
  licenseKey: '',
  email: null,
  subscriptionExpiresAt: null,
  transactionVendor: LicenseModelTransactionVendor.Unknown,
  features: {
    basicNotIncluded: false,
    hasAlbumsTransferEnabled: false,
    hasAutoSyncEnabled: false,
    hasSharingEnabled: true,
    hasSharingAnalyticsAccess: false,
    hasSharingEmbedAccess: false,
    disallowLikedPlaylists: false,
    hasSMLRetargeting: false,
    hasSMLMusicPlayer: false,
    hasSMLCustomization: true,
    hasSMLWhiteLabel: false,
    hasReMatchEnabled: true,
  },
  limits: {
    // NULL means no limits
    sharingServicesLimit: 0,
    songsLimit: BASE_SONGS_LIMIT,
    availableMusicServicesSource: null,
    availableMusicServicesDestination: null,
    smlAccountsLimit: 1,
    smlSmartLinksLimit: 10,
    smlUsersLimit: 1,
  },
  featureFlags: null,
  paddleManagementURLs: null,
};
