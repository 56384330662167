/* eslint-disable */

export const SHA1 = function () {
  function reset() {
    e[0] = 1732584193;
    e[1] = 4023233417;
    e[2] = 2562383102;
    e[3] = 271733878;
    e[4] = 3285377520;
    p = n = 0;
  }

  function b(q) {
    for (var r = h, t = 0; t < 64; t += 4) r[t / 4] = (q[t] << 24) | (q[t + 1] << 16) | (q[t + 2] << 8) | q[t + 3];
    for (t = 16; t < 80; t++)
      (q = r[t - 3] ^ r[t - 8] ^ r[t - 14] ^ r[t - 16]), (r[t] = ((q << 1) | (q >>> 31)) & 4294967295);
    q = e[0];
    let u = e[1];
    let x = e[2];
    let B = e[3];
    let F = e[4];
    for (t = 0; t < 80; t++) {
      if (t < 40)
        if (t < 20) {
          var G = B ^ (u & (x ^ B));
          var H = 1518500249;
        } else (G = u ^ x ^ B), (H = 1859775393);
      else t < 60 ? ((G = (u & x) | (B & (u | x))), (H = 2400959708)) : ((G = u ^ x ^ B), (H = 3395469782));
      G = ((((q << 5) | (q >>> 27)) & 4294967295) + G + F + H + r[t]) & 4294967295;
      F = B;
      B = x;
      x = ((u << 30) | (u >>> 2)) & 4294967295;
      u = q;
      q = G;
    }
    e[0] = (e[0] + q) & 4294967295;
    e[1] = (e[1] + u) & 4294967295;
    e[2] = (e[2] + x) & 4294967295;
    e[3] = (e[3] + B) & 4294967295;
    e[4] = (e[4] + F) & 4294967295;
  }

  function update(q, r) {
    if (typeof q === 'string') {
      q = decodeURIComponent(encodeURIComponent(q));
      for (var t = [], u = 0, x = q.length; u < x; ++u) t.push(q.charCodeAt(u));
      q = t;
    }
    r || (r = q.length);
    t = 0;
    if (n == 0) for (; t + 64 < r; ) b(q.slice(t, t + 64)), (t += 64), (p += 64);
    for (; t < r; )
      if (((f[n++] = q[t++]), p++, n == 64))
        for (n = 0, b(f); t + 64 < r; ) b(q.slice(t, t + 64)), (t += 64), (p += 64);
  }

  function digest() {
    const q = [];
    let r = 8 * p;
    n < 56 ? update(l, 56 - n) : update(l, 64 - (n - 56));
    for (var t = 63; t >= 56; t--) (f[t] = r & 255), (r >>>= 8);
    b(f);
    for (t = r = 0; t < 5; t++) for (let u = 24; u >= 0; u -= 8) q[r++] = (e[t] >> u) & 255;
    return q;
  }

  for (var e = [], f = [], h = [], l = [128], m = 1; m < 64; ++m) l[m] = 0;
  let n;
  let p;
  reset();

  return {
    reset,
    update,
    digest,
    v5() {
      for (var q = digest(), r = '', t = 0; t < q.length; t++)
        r += '0123456789ABCDEF'.charAt(Math.floor(q[t] / 16)) + '0123456789ABCDEF'.charAt(q[t] % 16);
      return r;
    },
  };
};
