import { RessoTrackRawResponse } from '../models/RessoResponseModels';
import { getRessoArtistsNamesAsString } from './getRessoArtistsNamesAsString';
import { validateISRC } from '../../../utils/validateISRC';
import { RessoAlbum } from '../models/RessoAlbum';
import { RessoArtist } from '../models/RessoArtist';

export function convertRessoTrackData(data: RessoTrackRawResponse | undefined) {
  if (!data?.id || !data.name) {
    return null;
  }
  return {
    track: {
      rawId: data.id,
      name: data.name,
      album: data.album?.name,
      artist: getRessoArtistsNamesAsString(data.artists),
      isrc: validateISRC(data?.isrc),
      imageUrl: data.album?.cover?.url,
      duration: data.duration,
    },
    album: data.album ? RessoAlbum.fromData(data.album) : null,
    artists: data.artists
      ? data.artists.map((artist) => RessoArtist.fromData(artist)).filter((a): a is NonNullable<typeof a> => a !== null)
      : null,
  };
}
