import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import { SerializedLinkWidget } from 'fym-common/src/db/serializersTypes';
import { parseUrl } from 'fym-common/src/utils/parseUrl';
import { TypedMemo } from '../../../../../common/types';
import { WidgetListItemProps } from '../../props';
import { iconForLink, logosForLink } from '../../../../socialLinksList/utils';

const LinkWidgetListItemComponent: React.FC<WidgetListItemProps<SerializedLinkWidget>> = ({ widget }) => {
  const { data } = widget;
  const icon = iconForLink(data.type);
  const url = parseUrl(data.url);
  const intl = useIntl();
  const images = logosForLink(data.type);
  const Logo = images?.logoColor?.light ?? null;

  return (
    <div className="widget-list-item-text-container">
      {intl.formatMessage(
        {
          defaultMessage: 'Link to {url}',
          id: 'app.sml.widgets.link.link-to',
        },
        {
          url: Logo ? (
            <Logo className="ms-2" />
          ) : (
            <span>
              <FontAwesomeIcon icon={icon} className="ms-2 me-1" />
              {url.host}
            </span>
          ),
        }
      )}
    </div>
  );
};

export const LinkWidgetListItem = TypedMemo(LinkWidgetListItemComponent);
