import { ReactComponent as QQMusicLight } from '../../../../assets/images/music-services/qq-light.svg';
import { ReactComponent as QQMusicDark } from '../../../../assets/images/music-services/qq-dark.svg';
import { ReactComponent as QQMusicLightPadding } from '../../../../assets/images/music-services/qq-light-padding.svg';
import { ReactComponent as QQMusicDarkPadding } from '../../../../assets/images/music-services/qq-dark-padding.svg';
import { ReactComponent as QQMusicColorLight } from '../../../../assets/images/music-services/qq-color-light.svg';
import { ReactComponent as QQMusicColorDark } from '../../../../assets/images/music-services/qq-color-dark.svg';
import { ReactComponent as QQMusicColorLightPadding } from '../../../../assets/images/music-services/qq-color-light-padding.svg';
import { ReactComponent as QQMusicColorDarkPadding } from '../../../../assets/images/music-services/qq-color-dark-padding.svg';
import { ReactComponent as QQMusicDarkDanger } from '../../../../assets/images/music-services-danger/qq-dark-danger.svg';
import { ReactComponent as QQMusicLightDanger } from '../../../../assets/images/music-services-danger/qq-light-danger.svg';
import { ReactComponent as QQMusicLightDangerPadding } from '../../../../assets/images/music-services-danger/qq-light-padding-danger.svg';
import { ReactComponent as QQMusicDarkDangerPadding } from '../../../../assets/images/music-services-danger/qq-dark-padding-danger.svg';
import { ReactComponent as QQMusicDarkWarning } from '../../../../assets/images/music-services-warning/qq-dark-warning.svg';
import { ReactComponent as QQMusicLightWarning } from '../../../../assets/images/music-services-warning/qq-light-warning.svg';
import { ReactComponent as QQMusicLightWarningPadding } from '../../../../assets/images/music-services-warning/qq-light-padding-warning.svg';
import { ReactComponent as QQMusicDarkWarningPadding } from '../../../../assets/images/music-services-warning/qq-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const qqMusicImages: ImporterImages = {
  logo: {
    light: QQMusicLight,
    dark: QQMusicDark,
  },
  logoPadding: {
    light: QQMusicLightPadding,
    dark: QQMusicDarkPadding,
  },
  logoColor: {
    light: QQMusicColorLight,
    dark: QQMusicColorDark,
  },
  logoColorPadding: {
    light: QQMusicColorLightPadding,
    dark: QQMusicColorDarkPadding,
  },
  logoOnBackground: QQMusicColorDark,
  logoDanger: {
    dark: QQMusicDarkDanger,
    light: QQMusicLightDanger,
  },
  logoDangerPadding: {
    dark: QQMusicDarkDangerPadding,
    light: QQMusicLightDangerPadding,
  },
  logoWarning: {
    dark: QQMusicDarkWarning,
    light: QQMusicLightWarning,
  },
  logoWarningPadding: {
    light: QQMusicLightWarningPadding,
    dark: QQMusicDarkWarningPadding,
  },
};
