import { browserName, fullBrowserVersion, osName, osVersion } from 'react-device-detect';
import { AppInformation, PlatformUniversal } from 'fym-common/src/utils/types';
import appPackage from '../../package.json';

export const appInformation: AppInformation = {
  version: appPackage.version,
  buildVersion: undefined,
  platform: PlatformUniversal.webNative,
  platformVersion: `${osName}[${osVersion}]; ${browserName}[${fullBrowserVersion}]`,
};
