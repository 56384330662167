type BaseErrorProps = {
  message?: string;
  details?: string;
  isOperational?: boolean;
};

export abstract class BaseError extends Error {
  public readonly isOperational: boolean;

  public readonly details?: string;

  protected constructor({ message, details, isOperational = false }: BaseErrorProps) {
    super(message);
    this.isOperational = isOperational;
    this.details = details;
  }
}

export function isBaseError(error: Error | BaseError): error is BaseError {
  return Object.prototype.hasOwnProperty.call(error, 'isOperational');
}
