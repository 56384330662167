import { createApi } from '@reduxjs/toolkit/query/react';
import { batch } from 'react-redux';
import qs from 'qs';
import {
  SerializedAccountsListResponse,
  SerializedFile,
  SerializedSmartLinksListResponse,
} from 'fym-common/src/db/serializersTypes';
import { LicenseStateType } from 'fym-common/src/license/types';
import { config } from '../../config/config';
import {
  AccountResponse,
  AppleJWTResult,
  ChangeEmailOptions,
  ChangeEmailResponse,
  ChangeLicenseOptions,
  ChangePasswordOptions,
  ChangePasswordResponse,
  CreateAccountOptions,
  CreateSmartLinkOptions,
  CreateSMLTrialLicenseOptions,
  CreateSMLTrialLicenseResponse,
  CreateTrackerOptions,
  CreateWidgetOptions,
  CreateWizardPlaylistLinkOptions,
  CreateWizardReleaseLinkOptions,
  DeleteSmartLinkOptions,
  DeleteTrackerOptions,
  DeleteWidgetOptions,
  DuplicateSmartLinkOptions,
  FetchAccountStatsOptions,
  FetchAccountStatsResponse,
  FetchSmartLinkStatsOptions,
  FetchSmartLinkStatsResponse,
  GetAccountOptions,
  GetAppsLinksOptions,
  GetAppsLinksResult,
  GetOrCreateLostBasketLicenseOptions,
  GetOrCreateLostBasketLicenseResult,
  GetSmartLinkOptions,
  GetSmartLinksOptions,
  GetSocialLinksResponse,
  GetStatsExistsOptions,
  GetStatsExistsResponse,
  GetTrackersResponse,
  GetUserOptions,
  GetUserResult,
  PatchSocialLinksOptions,
  PostImageOptions,
  PostStatsOptions,
  PostStatsResponse,
  PublishSmartLinkOptions,
  RemindPasswordOptions,
  RemindPasswordResponse,
  RemoveAccountDataOptions,
  RemoveAccountDataResponse,
  RestoreLicenseOptions,
  SmartLinkResponse,
  TrackerResponse,
  UpdateAccountOptions,
  UpdateSmartLinkOptions,
  UpdateTrackerOptions,
  UpdateWidgetOptions,
  UserLoginOptions,
  UserLoginResponse,
  ValidateLicenseOptions,
  ValidatePaddleOrderOptions,
  VerifyAccountEmailOptions,
  VerifyAccountNameSlugOptions,
  VerifyEmailResult,
  VerifySlugResult,
  VerifySmartLinkSlugOptions,
  WidgetResponse,
} from './fymAPI.types';
import { customBaseQuery } from './utils/customBaseQuery';
import { setLicenseInfoResult } from '../slices/license/slice';
import { setAccountId, setUserToken } from '../slices/appInfo/slice';
import { store } from '../../store/Store';
import { APIType } from './types';
import { appInformation } from '../../utils/appInformation';

export const baseUrl = config.fymApiURL(true);
console.log('Using redux fymAPI base url:', baseUrl);
const tagTypes = [
  'License',
  'RefCode',
  'User',
  'Account',
  'SocialLink',
  'SmartLink',
  'SmartLinkForList',
  'AccountForList',
];
export const fymAPI = createApi({
  reducerPath: 'fymAPI',
  tagTypes,
  baseQuery: customBaseQuery(baseUrl, APIType.fym),
  endpoints: (builder) => ({
    getUser: builder.query<GetUserResult, GetUserOptions>({
      query: ({ userId }) => ({
        url: `users/${userId}`,
        method: 'GET',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setLicenseInfoResult(data.license));
        } catch (err) {}
      },
      providesTags: ['User', 'License'],
    }),
    getAppleJWT: builder.query<AppleJWTResult, void>({
      query: () => ({
        url: `authorization/apple-jwt/`,
        method: 'POST',
        headers: { 'content-type': 'application/json' },
      }),
    }),
    getAppsLinks: builder.query<GetAppsLinksResult, GetAppsLinksOptions>({
      query: ({ app, channel }) => ({
        url: `/server-info/apps?app=${app}&channel=${channel}`,
        method: 'GET',
      }),
    }),
    getLicenseInfoByDeviceId: builder.query<LicenseStateType, void>({
      query: () => ({
        url: `licenses/byDeviceId`,
        method: 'GET',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setLicenseInfoResult(data));
        } catch (err) {}
      },
      providesTags: ['License'],
    }),
    validateLicense: builder.mutation<LicenseStateType, ValidateLicenseOptions>({
      query: (data) => ({
        url: `licenses/validate`,
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: ['License'],
    }),
    restoreLicense: builder.mutation<void, RestoreLicenseOptions>({
      query: (data) => ({
        url: `licenses/restore`,
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'content-type': 'application/json' },
      }),
    }),
    validatePaddleOrder: builder.mutation<{ license: LicenseStateType }, ValidatePaddleOrderOptions>({
      query: (data) => ({
        url: `paddle/order/${data.orderId}`,
        method: 'GET',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setLicenseInfoResult(data.license));
        } catch (err) {}
      },
      invalidatesTags: ['License'],
    }),
    changeLicense: builder.mutation<void, ChangeLicenseOptions>({
      query: (data) => ({
        url: `licenses/change`,
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: ['License'],
    }),
    deleteStats: builder.mutation<void, string>({
      query: (statsId) => ({
        url: `stats/delete`,
        method: 'POST',
        body: JSON.stringify({ statsId }),
        headers: { 'content-type': 'application/json' },
      }),
    }),
    sendFeedback: builder.mutation<void, string>({
      query: (feedback) => {
        const { version, platform, platformVersion, buildVersion } = appInformation;
        return {
          url: `common/feedback`,
          method: 'POST',
          body: JSON.stringify({
            feedback,
            appVersion: version,
            platform,
            platformVersion,
            buildVersion,
          }),
          headers: { 'content-type': 'application/json' },
        };
      },
    }),
    getOrCreateLostBasketLicense: builder.mutation<
      GetOrCreateLostBasketLicenseResult,
      GetOrCreateLostBasketLicenseOptions
    >({
      query: ({ email, language }) => ({
        url: `licenses/lostBasket`,
        method: 'POST',
        body: JSON.stringify({ email, language, songsBrewShouldSubscribe: true }),
        headers: { 'content-type': 'application/json' },
      }),
    }),
    getAccounts: builder.query<SerializedAccountsListResponse, void>({
      query: () => ({
        url: `accounts`,
        method: 'GET',
      }),
      providesTags: (response) =>
        response
          ? [...response.accounts.map(({ id }) => ({ type: 'AccountForList' as const, id })), 'AccountForList']
          : ['AccountForList'],
    }),
    getAccount: builder.query<AccountResponse, GetAccountOptions>({
      query: ({ accountId }) => ({
        url: `accounts/${accountId}`,
        method: 'GET',
      }),
      providesTags: (response) => (response ? [{ type: 'Account', id: response.account.id }] : ['Account']),
    }),
    createAccount: builder.mutation<AccountResponse, CreateAccountOptions>({
      query: ({ name, slug }) => ({
        url: `accounts`,
        method: 'POST',
        body: JSON.stringify({ name, accountSlug: slug }),
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: () => ['License', 'User'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          batch(() => {
            dispatch(setAccountId({ accountId: data.account.id }));
            dispatch(
              fymAPI.util.updateQueryData('getAccount', { accountId: data.account.id }, (draft) => {
                Object.assign(draft, data);
              })
            );
            dispatch(
              fymAPI.util.updateQueryData('getAccounts', undefined, (draft) => {
                const existingAccountIndex = draft.accounts.findIndex((account) => account.id === data.account.id);
                if (existingAccountIndex === -1) {
                  draft.accounts.push(data.account);
                } else {
                  draft.accounts.splice(existingAccountIndex, 0, data.account);
                }
              })
            );
          });
        } catch (err) {}
      },
    }),
    updateAccount: builder.mutation<AccountResponse, UpdateAccountOptions>({
      query: ({ id, name, slug, avatarFileId }) => ({
        url: `accounts/${id}`,
        method: 'PATCH',
        body: JSON.stringify({
          name,
          accountSlug: slug,
          avatarFileId: avatarFileId ?? null,
        }),
        headers: { 'content-type': 'application/json' },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            fymAPI.util.updateQueryData('getAccount', { accountId: data.account.id }, (draft) => {
              Object.assign(draft, data);
            })
          );
          dispatch(
            fymAPI.util.updateQueryData('getAccounts', undefined, (draft) => {
              const existingAccountIndex = draft.accounts.findIndex((account) => account.id === data.account.id);
              if (existingAccountIndex !== -1) {
                draft.accounts[existingAccountIndex] = data.account;
              }
            })
          );
        } catch (err) {}
      },
    }),
    postFile: builder.mutation<SerializedFile, PostImageOptions>({
      query: ({ accountId, formData }) => ({
        url: `accounts/${accountId}/files`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: () => ['Account'],
    }),
    verifyAccountNameSlug: builder.mutation<VerifySlugResult, VerifyAccountNameSlugOptions>({
      query: (data) => ({
        url: `accountNames/verifySlug`,
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'content-type': 'application/json' },
      }),
    }),
    verifyAccountEmail: builder.mutation<VerifyEmailResult, VerifyAccountEmailOptions>({
      query: (data) => ({
        url: `accounts/verifyEmail`,
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'content-type': 'application/json' },
      }),
    }),
    getSocialLinks: builder.query<GetSocialLinksResponse, number | string>({
      query: (accountId) => ({
        url: `accounts/${accountId}/socialLinks`,
        method: 'GET',
      }),
      providesTags: (response) =>
        response
          ? [...response.socialLinks.map(({ id }) => ({ type: 'SocialLink' as const, id })), 'SocialLink']
          : ['SocialLink'],
    }),
    patchSocialLinks: builder.mutation<void, PatchSocialLinksOptions>({
      query: ({ accountId, create, update, remove }) => ({
        url: `accounts/${accountId}/socialLinks`,
        method: 'PATCH',
        body: JSON.stringify({ create, update, remove }),
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: ['SocialLink', 'SmartLink'],
    }),
    getSmartLinks: builder.query<SerializedSmartLinksListResponse, GetSmartLinksOptions>({
      query: ({ accountId, page, search, limit }) => ({
        url: `accounts/${accountId}/smartLinks?${qs.stringify({ page, limit, search })}`,
        method: 'GET',
      }),
      providesTags: (response) =>
        response
          ? [...response.results.map(({ id }) => ({ type: 'SmartLinkForList' as const, id })), 'SmartLinkForList']
          : ['SmartLinkForList'],
    }),
    getSmartLink: builder.query<SmartLinkResponse, GetSmartLinkOptions>({
      query: ({ accountId, smartLinkId }) => ({
        url: `accounts/${accountId}/smartLinks/${smartLinkId}`,
        method: 'GET',
      }),
      providesTags: (smartLink) =>
        smartLink ? ['SmartLink', { type: 'SmartLink' as const, id: smartLink.smartLink.id }] : ['SmartLink'],
    }),
    updateSmartLink: builder.mutation<SmartLinkResponse, UpdateSmartLinkOptions>({
      query: ({ accountId, smartLinkId, name, slug, backgroundFileId, theme }) => ({
        url: `accounts/${accountId}/smartLinks/${smartLinkId}`,
        method: 'PATCH',
        body: JSON.stringify({ name, slug, backgroundFileId: backgroundFileId ?? null, theme }),
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: ['SmartLinkForList'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            fymAPI.util.updateQueryData(
              'getSmartLink',
              { smartLinkId: data.smartLink.id, accountId: data.smartLink.accountId },
              (draft) => {
                Object.assign(draft, data);
              }
            )
          );
        } catch (err) {}
      },
    }),
    publishSmartLink: builder.mutation<SmartLinkResponse, PublishSmartLinkOptions>({
      query: ({ accountId, smartLinkId }) => ({
        url: `accounts/${accountId}/smartLinks/${smartLinkId}/publish`,
        method: 'POST',
        body: JSON.stringify({}),
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: ['SmartLinkForList'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            fymAPI.util.updateQueryData(
              'getSmartLink',
              { smartLinkId: data.smartLink.id, accountId: data.smartLink.accountId },
              (draft) => {
                Object.assign(draft, data);
              }
            )
          );
        } catch (err) {}
      },
    }),
    createSmartLink: builder.mutation<SmartLinkResponse, CreateSmartLinkOptions>({
      query: ({ accountId, name, slug, backgroundFileId, theme }) => ({
        url: `accounts/${accountId}/smartLinks`,
        method: 'POST',
        body: JSON.stringify({ name, slug, backgroundFileId, theme }),
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: ['SmartLinkForList'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            fymAPI.util.updateQueryData(
              'getSmartLink',
              { smartLinkId: data.smartLink.id, accountId: data.smartLink.accountId },
              (draft) => {
                Object.assign(draft, data);
              }
            )
          );
        } catch (err) {}
      },
    }),
    deleteSmartLink: builder.mutation<void, DeleteSmartLinkOptions>({
      query: ({ accountId, smartLinkId }) => ({
        url: `accounts/${accountId}/smartLinks/${smartLinkId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SmartLinkForList'],
    }),
    verifySmartLinkSlug: builder.mutation<VerifySlugResult, VerifySmartLinkSlugOptions>({
      query: ({ accountId, slug, smartLinkId }) => ({
        url: `accounts/${accountId}/smartLinks/verifySlug`,
        method: 'POST',
        body: JSON.stringify({ slug, smartLinkId }),
        headers: { 'content-type': 'application/json' },
      }),
    }),
    updateWidget: builder.mutation<WidgetResponse, UpdateWidgetOptions>({
      query: ({ accountId, smartLinkId, widgetUUID, position, type, data }) => ({
        url: `accounts/${accountId}/smartLinks/${smartLinkId}/widgets/${widgetUUID}`,
        method: 'PATCH',
        body: JSON.stringify({ position, type, data }),
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: ['SmartLinkForList'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            fymAPI.util.updateQueryData(
              'getSmartLink',
              { smartLinkId: data.smartLink.id, accountId: data.smartLink.accountId },
              (draft) => {
                Object.assign(draft, data);
              }
            )
          );
        } catch (err) {}
      },
    }),
    createWidget: builder.mutation<WidgetResponse, CreateWidgetOptions>({
      query: ({ accountId, smartLinkId, type, data }) => ({
        url: `accounts/${accountId}/smartLinks/${smartLinkId}/widgets`,
        method: 'POST',
        body: JSON.stringify({ type, data }),
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: ['SmartLinkForList'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            fymAPI.util.updateQueryData(
              'getSmartLink',
              { smartLinkId: data.smartLink.id, accountId: data.smartLink.accountId },
              (draft) => {
                Object.assign(draft, data);
              }
            )
          );
        } catch (err) {}
      },
    }),
    deleteWidget: builder.mutation<WidgetResponse, DeleteWidgetOptions>({
      query: ({ accountId, smartLinkId, widgetUUID }) => ({
        url: `accounts/${accountId}/smartLinks/${smartLinkId}/widgets/${widgetUUID}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SmartLinkForList'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            fymAPI.util.updateQueryData(
              'getSmartLink',
              { smartLinkId: data.smartLink.id, accountId: data.smartLink.accountId },
              (draft) => {
                Object.assign(draft, data);
              }
            )
          );
        } catch (err) {}
      },
    }),
    createWizardReleaseLink: builder.mutation<SmartLinkResponse, CreateWizardReleaseLinkOptions>({
      query: ({ accountId, title, subtitle, extra, imageId, imageURL, urls }) => ({
        url: `accounts/${accountId}/wizards/releaseLink`,
        method: 'POST',
        body: JSON.stringify({ title, subtitle, extra, imageId, imageURL, urls }),
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: ['SmartLinkForList'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            fymAPI.util.updateQueryData(
              'getSmartLink',
              { smartLinkId: data.smartLink.id, accountId: data.smartLink.accountId },
              (draft) => {
                Object.assign(draft, data);
              }
            )
          );
        } catch (err) {}
      },
    }),
    createWizardPlaylistLink: builder.mutation<SmartLinkResponse, CreateWizardPlaylistLinkOptions>({
      query: ({ accountId, url }) => ({
        url: `accounts/${accountId}/wizards/playlistLink`,
        method: 'POST',
        body: JSON.stringify({ url }),
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: ['SmartLinkForList'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            fymAPI.util.updateQueryData(
              'getSmartLink',
              { smartLinkId: data.smartLink.id, accountId: data.smartLink.accountId },
              (draft) => {
                Object.assign(draft, data);
              }
            )
          );
        } catch (err) {}
      },
    }),
    duplicateSmartLink: builder.mutation<SmartLinkResponse, DuplicateSmartLinkOptions>({
      query: ({ accountId, smartLinkId, name, slug, theme, backgroundFileId }) => ({
        url: `accounts/${accountId}/smartLinks/${smartLinkId}/duplicate`,
        method: 'POST',
        body: JSON.stringify({ name, slug, theme, backgroundFileId }),
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: ['SmartLinkForList'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            fymAPI.util.updateQueryData(
              'getSmartLink',
              { smartLinkId: data.smartLink.id, accountId: data.smartLink.accountId },
              (draft) => {
                Object.assign(draft, data);
              }
            )
          );
        } catch (err) {}
      },
    }),
    createSMLTrialLicense: builder.mutation<CreateSMLTrialLicenseResponse, CreateSMLTrialLicenseOptions>({
      query: (data) => ({
        url: `licenses/smlTrial`,
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: () => ['User', 'License'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          await store.set('userToken', data.token);
          batch(() => {
            dispatch(setLicenseInfoResult(data.licenseInfo));
            dispatch(setUserToken({ token: data.token }));
          });
        } catch (err) {}
      },
    }),
    removeAccountData: builder.mutation<RemoveAccountDataResponse, RemoveAccountDataOptions>({
      query: (data) => ({
        url: `licenses/removePersonalData`,
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: () => tagTypes,
    }),
    fetchSmartLinkStats: builder.query<FetchSmartLinkStatsResponse, FetchSmartLinkStatsOptions>({
      query: ({ accountId, smartLinkId }) => ({
        url: `accounts/${accountId}/smartLinks/${smartLinkId}/stats`,
        method: 'GET',
      }),
    }),
    fetchAccountStats: builder.query<FetchAccountStatsResponse, FetchAccountStatsOptions>({
      query: ({ accountId }) => ({
        url: `accounts/${accountId}/stats`,
        method: 'GET',
      }),
    }),
    userLogin: builder.mutation<UserLoginResponse, UserLoginOptions>({
      query: (data) => ({
        url: `authorization/login`,
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'content-type': 'application/json' },
      }),
      invalidatesTags: () => tagTypes,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          await store.set('userToken', data.token);
          dispatch(setUserToken({ token: data.token }));
        } catch (err) {}
      },
    }),
    remindPassword: builder.mutation<RemindPasswordResponse, RemindPasswordOptions>({
      query: (data) => ({
        url: `users/remind-password`,
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'content-type': 'application/json' },
      }),
    }),
    changePassword: builder.mutation<ChangePasswordResponse, ChangePasswordOptions>({
      query: ({ userId, authToken, newPassword, currentPassword }) => {
        const data = currentPassword ? { newPassword, currentPassword } : { newPassword };
        const headers: Record<string, string> = { 'content-type': 'application/json' };
        if (authToken) {
          headers.Authorization = `Token ${authToken}`;
        }
        return {
          url:
            userId !== undefined && authToken === undefined
              ? `users/${userId}/change-password`
              : `users/change-password`,
          method: 'POST',
          headers,
          body: JSON.stringify(data),
        };
      },
      invalidatesTags: () => tagTypes,
    }),
    getTrackers: builder.query<GetTrackersResponse, number | string>({
      query: (accountId) => ({
        url: `accounts/${accountId}/trackers`,
        method: 'GET',
      }),
    }),
    createTracker: builder.mutation<TrackerResponse, CreateTrackerOptions>({
      query: ({ accountId, snippet }) => ({
        url: `accounts/${accountId}/trackers`,
        method: 'POST',
        body: JSON.stringify({ snippet }),
        headers: { 'content-type': 'application/json' },
      }),
      async onQueryStarted({ accountId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            fymAPI.util.updateQueryData('getTrackers', accountId, (draft) => {
              draft.trackers.push(data.tracker);
              draft.trackers.sort((a, b) => a.id - b.id);
            })
          );
        } catch (err) {}
      },
    }),
    updateTracker: builder.mutation<TrackerResponse, UpdateTrackerOptions>({
      query: ({ accountId, trackerId, snippet }) => ({
        url: `accounts/${accountId}/trackers/${trackerId}`,
        method: 'PATCH',
        body: JSON.stringify({ snippet }),
        headers: { 'content-type': 'application/json' },
      }),
      async onQueryStarted({ accountId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            fymAPI.util.updateQueryData('getTrackers', accountId, (draft) => {
              const updatedTrackerIndex = draft.trackers.findIndex((tracker) => tracker.id === data.tracker.id);
              if (updatedTrackerIndex !== -1) {
                draft.trackers[updatedTrackerIndex] = data.tracker;
              }
            })
          );
        } catch (err) {}
      },
    }),
    deleteTracker: builder.mutation<void, DeleteTrackerOptions>({
      query: ({ accountId, trackerId }) => ({
        url: `accounts/${accountId}/trackers/${trackerId}`,
        method: 'DELETE',
      }),
      onQueryStarted({ accountId, trackerId }, { dispatch }) {
        try {
          dispatch(
            fymAPI.util.updateQueryData('getTrackers', accountId, (draft) => {
              const deletedTrackerIndex = draft.trackers.findIndex((tracker) => tracker.id === trackerId);
              if (deletedTrackerIndex !== -1) {
                draft.trackers.splice(deletedTrackerIndex, 1);
              }
            })
          );
        } catch (err) {}
      },
    }),
    postStats: builder.mutation<PostStatsResponse, PostStatsOptions>({
      query: (data) => ({
        url: `stats`,
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'content-type': 'application/json' },
      }),
    }),
    getStatsExists: builder.mutation<GetStatsExistsResponse, GetStatsExistsOptions>({
      query: ({ statsId }) => {
        return {
          url: `stats/${statsId}/exists`,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
        };
      },
    }),
    changeEmail: builder.mutation<ChangeEmailResponse, ChangeEmailOptions>({
      query: ({ email }) => {
        return {
          url: `licenses/changeEmail`,
          method: 'POST',
          body: JSON.stringify({ email }),
          headers: { 'content-type': 'application/json' },
        };
      },
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetAppleJWTQuery,
  useValidateLicenseMutation,
  useRestoreLicenseMutation,
  useValidatePaddleOrderMutation,
  useGetLicenseInfoByDeviceIdQuery,
  useChangeLicenseMutation,
  useDeleteStatsMutation,
  useSendFeedbackMutation,
  useGetOrCreateLostBasketLicenseMutation,
  useGetAccountQuery,
  useCreateAccountMutation,
  useUpdateAccountMutation,
  useGetSocialLinksQuery,
  usePatchSocialLinksMutation,
  useGetSmartLinksQuery,
  useGetSmartLinkQuery,
  useUpdateSmartLinkMutation,
  usePublishSmartLinkMutation,
  useCreateSmartLinkMutation,
  useDeleteSmartLinkMutation,
  useUpdateWidgetMutation,
  useCreateWidgetMutation,
  useDeleteWidgetMutation,
  useUserLoginMutation,
  useCreateWizardReleaseLinkMutation,
  useCreateWizardPlaylistLinkMutation,
  useCreateSMLTrialLicenseMutation,
  useRemoveAccountDataMutation,
  useFetchSmartLinkStatsQuery,
  useGetAccountsQuery,
  useGetAppsLinksQuery,
  useFetchAccountStatsQuery,
  useRemindPasswordMutation,
  useChangePasswordMutation,
  useGetTrackersQuery,
  useCreateTrackerMutation,
  useUpdateTrackerMutation,
  useDeleteTrackerMutation,
  useDuplicateSmartLinkMutation,
  usePostFileMutation,
  useGetStatsExistsMutation,
  useChangeEmailMutation,
} = fymAPI;
