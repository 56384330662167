import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { TypedMemo } from '../../common/types';
import { SignUpContainerProps } from './props';
import { ReactComponent as SMLLogo } from '../../../assets/images/smartLinks/smartlinks-logo.svg';
import { ReactComponent as SmartLinksLogoLight } from '../../../assets/logos_sml/logo-full-light.svg';

const SMLSetUpContainerComponent: React.FC<React.PropsWithChildren<SignUpContainerProps>> = ({ children, title }) => {
  return (
    <Container fluid className="sml-setup-container">
      <Row className="d-block d-sm-none position-absolute ">
        <SmartLinksLogoLight width={200} />
      </Row>
      <Row className="justify-content-center h-100">
        <Col className="sml-set-up-box align-self-center p-5 w-100">
          <SMLLogo className="sml-set-up-logo d-none d-sm-block position-absolute" />
          <div className="p-0 p-sm-4">
            {title && <h1 className="text-center ui-text">{title}</h1>}
            {children}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export const SMLSetUpContainer = TypedMemo(SMLSetUpContainerComponent);
