import { GenericAuthor } from '../../../generics/models/GenericAuthor';
import { DeezerArtistRaw } from '../rawTypes/DeezerArtistRaw';
// eslint-disable-next-line import/no-cycle
import { convertDeezerId, createDeezerArtistUrl } from '../utils';
import { domParser, getMetaContentByProperty, getScriptsContents } from '../../../utils/htmlUtils';
import { tryParseJSON } from '../../../utils/tryParseJSON';

export class DeezerArtist extends GenericAuthor {
  static fromData(data: DeezerArtistRaw): DeezerArtist | null {
    const rawId = convertDeezerId(data.id);
    const name = `${data.name}`;
    if (!rawId || !name) {
      return null;
    }
    return new DeezerArtist({
      rawId,
      name,
      imageUrl: data.picture,
      publicUrl: data.link,
    });
  }

  static fromShareUrlData(HTMLString: string): DeezerArtist | null {
    const scriptsContents = getScriptsContents(HTMLString);
    const artistDataString = scriptsContents
      .find((content) => content.includes('__DZR_APP_STATE__'))
      ?.match(/window.__DZR_APP_STATE__ = (.*?)$/)?.[1];
    const data = tryParseJSON(artistDataString)?.DATA;

    if (!data?.ART_ID || !data.ART_NAME) {
      return null;
    }

    const htmlDoc = domParser.parseFromString(HTMLString);
    const metaElements = htmlDoc.getElementsByTagName('meta');
    const imageUrl = getMetaContentByProperty(metaElements, 'og:image');

    return new DeezerArtist({
      rawId: data.ART_ID,
      name: data.ART_NAME,
      followers: data.NB_FAN,
      publicUrl: createDeezerArtistUrl(data.ART_ID),
      imageUrl,
    });
  }
}
