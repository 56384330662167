import { QobuzCollectionTrack } from './QobuzCollectionTrack';
import { QobuzPlaylist } from './QobuzPlaylist';

export class QobuzPlaylistWithTracksResponse {
  private readonly data: any;

  private readonly userId: number;

  constructor(data: any, userId: number) {
    this.data = data;
    this.userId = userId;
  }

  get playlist(): QobuzPlaylist | null {
    return QobuzPlaylist.fromData(this.data, this.userId);
  }

  get tracks(): QobuzCollectionTrack[] {
    if (!this.data?.tracks?.items) {
      return [];
    }

    return this.data.tracks.items
      .map((item: any) => QobuzCollectionTrack.fromData(item))
      .filter((track: QobuzCollectionTrack | null) => !!track);
  }

  get tracksTotal(): number {
    return this.data.tracks.total;
  }
}
