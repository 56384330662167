import URL from 'url-parse';
import qs from 'qs';
import { getTimestampFromMMSS, getArtistsNamesAsString, getBiggestImageUrlFromImages, getArtistsIds } from '../utils';
import { Album, Artist, Track } from './types';
import { GenericMatchedItem, MatchedItemModelData } from '../../../generics/models/GenericMatchedItem';

function getSearchIdForTrack(data: any): string {
  if (!data?.primaryLink?.deeplink) {
    throw new Error(`Missing deeplink param for Amazon track: ${data.primaryLink}`);
  }
  const parsedDeepLink = new URL(data.primaryLink.deeplink, (query) => qs.parse(query, { ignoreQueryPrefix: true }));

  if (!parsedDeepLink.query?.trackAsin) {
    throw new Error(`Missing trackAsin param in deeplink for Amazon track: ${data.primaryLink.deeplink}`);
  }
  return parsedDeepLink.query.trackAsin;
}

export class AmazonMusicMatchedTrack extends GenericMatchedItem {
  constructor(
    data: MatchedItemModelData,
    public albumExtended: Pick<Album, 'id' | 'title' | 'url'> | null = null,
    public artists: Pick<Artist, 'id' | 'name' | 'url'>[] | null = null
  ) {
    super(data);
  }

  public static fromData(track: Track | undefined) {
    const { id, shortTitle, title, album, artists, images, duration, previewUrl, isrc, url } = track ?? {};
    const name = shortTitle || title;
    if (!id || !name) {
      return undefined;
    }
    return new AmazonMusicMatchedTrack(
      {
        rawId: id,
        name,
        album: album?.title,
        artist: getArtistsNamesAsString(artists),
        imageUrl: getBiggestImageUrlFromImages(images),
        duration: duration !== undefined ? duration * 1000 : undefined,
        previewUrl,
        isrc,
        sourceURL: url,
        additionalData: {
          albumId: album?.id,
          artistsIds: getArtistsIds(artists),
        },
      },
      album ? { id: album.id, title: album.title, url: album.url } : undefined,
      artists
        ? artists
            .filter((artist): artist is NonNullable<typeof artist> => !!artist)
            .map((artist) => ({ id: artist.id, name: artist.name, url: artist.url }))
        : undefined
    );
  }

  public static fromShareURLData(data: any, methodTemplate?: any, url?: string): AmazonMusicMatchedTrack | null {
    if (!data) {
      return null;
    }
    return new AmazonMusicMatchedTrack({
      rawId: data.onCheckboxSelected?.defaultValue?.[0]?.value || getSearchIdForTrack(data),
      name: data.primaryText,
      imageUrl: data.image || methodTemplate?.headerImage,
      duration: data.secondaryText3 ? getTimestampFromMMSS(data.secondaryText3) : undefined,
      album: data.secondaryText1 || methodTemplate?.headerImageAltText,
      artist: data.secondaryText2 || methodTemplate?.headerPrimaryText,
      sourceURL: url,
      additionalData: {
        albumId: data.primaryLink?.deeplink?.split('?')?.[0]?.split('/').pop(),
      },
    });
  }
}
