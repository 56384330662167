import React, { useCallback } from 'react';
import classnames from 'classnames';
import { TypedMemo } from '../../common/types';
import { Breadcrumb } from './types';

interface SMLBreadcrumbProps {
  breadcrumb: Breadcrumb;
  isLast: boolean;
}

const SMLBreadcrumbComponent: React.FC<React.PropsWithChildren<SMLBreadcrumbProps>> = ({ breadcrumb, isLast }) => {
  const { name, onClick } = breadcrumb;

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    (event) => {
      event.preventDefault();
      onClick?.();
    },
    [onClick]
  );

  return (
    <span className={classnames({ 'fw-light opacity-5': !isLast })}>
      <a href="/#" onClick={handleClick} className="text-body text-decoration-none">
        {name}
      </a>
      {isLast ? '' : ' / '}
    </span>
  );
};

export const SMLBreadcrumb = TypedMemo(SMLBreadcrumbComponent);
