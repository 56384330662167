import { RessoCollectionTrack } from './RessoCollectionTrack';
import { RessoTracksRawResponse } from './RessoResponseModels';

export class RessoMySongsResponse {
  private readonly data;

  constructor(data: RessoTracksRawResponse | undefined) {
    this.data = data;
  }

  get tracks(): RessoCollectionTrack[] {
    if (!this.data?.items) {
      return [];
    }
    return this.data.items
      .map((item) => RessoCollectionTrack.fromData(item))
      .filter((track): track is RessoCollectionTrack => !!track);
  }

  get nextPage() {
    return this.data?.next_cursor;
  }

  get total() {
    return this.data?.total;
  }
}
