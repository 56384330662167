interface UserResponse {
  data?: {
    user?: {
      id?: string;
      name?: string;
      images?: ({ url: string } | null)[];
      url?: string;
    };
  };
}

export class AmazonMusicUserResponse {
  private readonly data;

  constructor(data: UserResponse | undefined) {
    this.data = data;
  }

  get id() {
    const id = this.data?.data?.user?.id;
    if (!id) {
      throw new Error('Missing id for Amazon User');
    }
    return id;
  }

  get name() {
    return this.data?.data?.user?.name;
  }

  get imageUrl(): string | undefined {
    return this.data?.data?.user?.images?.[0]?.url;
  }
}
