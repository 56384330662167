export enum DeezerErrorCodes {
  quota = 4,
  itemsLimitExceeded = 100,
  permission = 200,
  tokenInvalid = 300,
  parameter = 500,
  parameterMissing = 501,
  queryInvalid = 600,
  serviceBusy = 700,
  dataNotFound = 800,
  individualAccountNotAllowed = 901,
}

export enum DeezerPermissions {
  basicAccess = 'basic_access', // Access users basic information	incl. name, firstname, profile picture only.
  email = 'email', //	Get the user's email
  offlineAccess = 'offline_access', // Access user data any time
  manageLibrary = 'manage_library', //	Manage users' library (Add/rename a playlist. Add/order songs in the playlist.)
  manageCommunity = 'manage_community', // Manage users' friends (Add/remove a following/follower.)
  deleteLibrary = 'delete_library', // Delete library items (Allow the application to delete items in the user's library)
  listeningHistory = 'listening_history', // Allow the application to access the user's listening history
}
