import qs from 'qs';
import { defaultRetryOptions, fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { FetchError } from '../../generics/errors/FetchError';
import { PandoraBillingInfo } from './models/PandoraBillingInfo';
import { PandoraSearchResponse } from './models/PandoraSearchResponse';
import { PandoraPlaylistsResponse } from './models/PandoraPlaylistsResponse';
import { PandoraPlaylist } from './models/PandoraPlaylist';
import { PandoraCollectionTrack } from './models/PandoraCollectionTrack';
import { PandoraTracksResponse } from './models/PandoraTracksResponse';
import { getUserAgent } from '../../utils/getUserAgent';
import { getCookie } from '../../utils/getCookie';
import { ImporterID } from '../types';
// @ts-ignore
import { blowfish } from './blowfish';
import { PandoraAuthenticationData } from './PandoraAuthenticationData';
import { tryParseInt } from '../../utils/tryParseInt';
import { RequestHeaders, Response } from '../../utils/fetch-types';

export class PandoraAPI {
  public static BASE_URL = 'https://www.pandora.com/api/';

  static LOGIN_URL = 'https://www.pandora.com/account/sign-in';

  public static csrfToken: string | undefined;

  public readonly authToken?: string;

  public readonly userName?: string;

  public playlistsVersions: Record<string, number> = {};

  constructor(userName: string, authToken?: string) {
    this.authToken = authToken;
    this.userName = userName;
  }

  private async requestHeaders(skipAuthToken = false): Promise<RequestHeaders> {
    const csrfToken = await PandoraAPI.getCsrfToken(this.userName);
    const cookies = [`http_referrer=https://www.pandora.com/account/sign-in`, `csrftoken=${csrfToken}`];

    const headers: RequestHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CsrfToken': csrfToken,
      Cookie: cookies.join('; '),
      'User-Agent': getUserAgent(),
    };
    if (!skipAuthToken && this.authToken) {
      headers['X-AuthToken'] = this.authToken;
    }
    return headers;
  }

  async fetch(url: string, options: any, retryOptions: RetryOptions = defaultRetryOptions): Promise<Response> {
    const response = await fetchRetry(url, options, retryOptions);

    if (response.status === 401) {
      const text = await response.text();
      throw new NotAuthenticatedError({ authId: this.userName, importerId: ImporterID.Pandora }, text);
    }

    return response;
  }

  static validatePandoraResponse(data: any) {
    if ('errorCode' in data) {
      throw new FetchError(data.errorCode, data.message);
    }
  }

  static async isAvailable(): Promise<boolean> {
    const response = await fetchRetry(`https://www.pandora.com/`, {
      method: 'GET',
    });

    // We need to read body for NOCK in tests to register it ...
    await response.text();

    return !response.url.endsWith('restricted');
  }

  async fetchBillingInfo(): Promise<PandoraBillingInfo> {
    const response = await this.fetch(`${PandoraAPI.BASE_URL}v1/billing/infoV2`, {
      method: 'POST',
      headers: await this.requestHeaders(),
      body: JSON.stringify({}),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to fetch billing info on Pandora got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    PandoraAPI.validatePandoraResponse(jsonBody);
    return new PandoraBillingInfo(jsonBody);
  }

  async loadPaginatedPlaylists(onBatch: (collections: PandoraPlaylist[]) => Promise<void>): Promise<void> {
    const limit = 100;
    const loadPlaylists = async (offset: number): Promise<void> => {
      const playlistResponse = await this.loadPlaylistPage(offset, limit);
      await onBatch(playlistResponse.playlists);
      const newOffset = offset + limit;
      if (newOffset < playlistResponse.totalCount) {
        await loadPlaylists(newOffset);
      }
    };

    await loadPlaylists(0);
  }

  async loadPlaylistPage(offset = 0, limit = 100): Promise<PandoraPlaylistsResponse> {
    const response = await this.fetch(`${PandoraAPI.BASE_URL}v6/collections/getSortedPlaylists`, {
      method: 'POST',
      headers: await this.requestHeaders(),
      body: JSON.stringify({
        request: { sortOrder: 'MOST_RECENT_MODIFIED', limit, annotationLimit: limit, offset },
        isRecentModifiedPlaylists: false,
        allowedTypes: ['TR', 'AM'],
      }),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to fetch playlists[${offset}, ${limit}] on Pandora got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    PandoraAPI.validatePandoraResponse(jsonBody);
    return new PandoraPlaylistsResponse(jsonBody);
  }

  async loadPaginatedPlaylistItems(
    playlistId: string,
    onBatch: (tracks: PandoraCollectionTrack[]) => Promise<void>
  ): Promise<number> {
    const limit = 100;
    const loadItems = async (offset: number, version = 0): Promise<number> => {
      const itemsResponse = await this.loadPlaylistItemPage(playlistId, offset, limit, version);
      await onBatch(
        itemsResponse.tracks.filter((t): t is PandoraCollectionTrack => {
          return t !== undefined;
        })
      );
      const newOffset = offset + limit;
      if (newOffset < itemsResponse.totalTracks) {
        return loadItems(newOffset, itemsResponse.version);
      }
      return itemsResponse.version;
    };

    return loadItems(0);
  }

  async loadPlaylistItemPage(playlistId: string, offset = 0, limit = 100, version = 0): Promise<PandoraTracksResponse> {
    const response = await this.fetch(`${PandoraAPI.BASE_URL}v7/playlists/getTracks`, {
      method: 'POST',
      headers: await this.requestHeaders(),
      body: JSON.stringify({
        request: {
          pandoraId: playlistId,
          playlistVersion: version,
          offset,
          limit,
          annotationLimit: limit,
          allowedTypes: ['TR'],
        },
      }),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to fetch playlist items[${playlistId}, ${offset}, ${limit}] on Pandora got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    PandoraAPI.validatePandoraResponse(jsonBody);
    return new PandoraTracksResponse(jsonBody);
  }

  public static async getCsrfToken(username?: string): Promise<string> {
    if (PandoraAPI.csrfToken) {
      return PandoraAPI.csrfToken;
    }
    const response = await fetchRetry(`https://www.pandora.com/account/sign-in`, {
      method: 'GET',
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to get csrft token on Pandora got wrong response[${response.status}]: ${text}`
      );
    }

    // Needed so NOCK in tests will save the request!
    await response.text();

    const { headers } = response;
    const csrfToken = getCookie(headers, 'csrftoken');
    if (!csrfToken) {
      if (username) {
        throw new NotAuthenticatedError(
          { authId: username, importerId: ImporterID.Pandora },
          'Could not find csrfToken in cookies'
        );
      } else {
        throw new Error('Could not find csrfToken in cookies');
      }
    }
    PandoraAPI.csrfToken = csrfToken;
    return csrfToken;
  }

  async search(query: string, types = ['TR']): Promise<PandoraSearchResponse> {
    if (!query) {
      return new PandoraSearchResponse(null);
    }
    const response = await this.fetch(`${PandoraAPI.BASE_URL}v3/sod/search`, {
      method: 'POST',
      headers: await this.requestHeaders(),
      body: JSON.stringify({
        query,
        types,
        listener: null,
        start: 0,
        count: 10,
        annotate: true,
        searchTime: PandoraAPI.getCurrentTime(),
        annotationRecipe: 'CLASS_OF_2019',
      }),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to search [${query}] for [${JSON.stringify(types)}] on Pandora got wrong response[${
          response.status
        }]: ${text}`
      );
    }

    const jsonBody = await response.json();
    PandoraAPI.validatePandoraResponse(jsonBody);
    return new PandoraSearchResponse(jsonBody);
  }

  async createPlaylist(name: string): Promise<PandoraPlaylist | null> {
    const response = await this.fetch(`${PandoraAPI.BASE_URL}v6/playlists/create`, {
      method: 'POST',
      headers: await this.requestHeaders(),
      body: JSON.stringify({ request: { details: { name } } }),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to create playlist [${name}] on Pandora got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    PandoraAPI.validatePandoraResponse(jsonBody);
    return PandoraPlaylist.fromData(jsonBody);
  }

  private async getPlaylistVersion(playlistId: string): Promise<number> {
    const existingVersion = this.playlistsVersions[playlistId];
    if (existingVersion !== undefined) {
      return existingVersion;
    }
    const pItemsResponse = await this.loadPlaylistItemPage(playlistId);
    this.playlistsVersions[playlistId] = pItemsResponse.version;
    return pItemsResponse.version;
  }

  async addTracksToPlaylist(playlistId: string, trackIds: string[]): Promise<PandoraPlaylist | null> {
    const version = await this.getPlaylistVersion(playlistId);

    const response = await this.fetch(`${PandoraAPI.BASE_URL}v6/playlists/editTracks`, {
      method: 'POST',
      headers: await this.requestHeaders(),
      body: JSON.stringify({
        request: {
          pandoraId: playlistId,
          playlistVersion: version,
          inserts: trackIds.map((t, index) => {
            return { trackPandoraId: t, newIndex: index };
          }),
        },
      }),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to add tracks [${JSON.stringify(
          trackIds
        )}] to playlist [${playlistId}, ${version}] on Pandora got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    try {
      PandoraAPI.validatePandoraResponse(jsonBody);
    } catch (e) {
      if (e instanceof FetchError && e.code === 99000) {
        delete this.playlistsVersions[playlistId];
        return this.addTracksToPlaylist(playlistId, trackIds);
      }
      throw e;
    }
    const playlist = PandoraPlaylist.fromData(jsonBody);
    if (!playlist || playlist.additionalData?.version === undefined) {
      return null;
    }
    this.playlistsVersions[playlistId] = playlist.additionalData?.version;
    return playlist;
  }

  async removeTracksFromPlaylist(playlistId: string, trackIds: string[]): Promise<PandoraPlaylist | null> {
    const itemIdsToBeRemoved: (number | undefined)[] = [];
    const indicesToBeRemoved: (number | undefined)[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    const version = await this.loadPaginatedPlaylistItems(playlistId, async (items) => {
      itemIdsToBeRemoved.push(...items.filter((item) => trackIds.indexOf(item.rawId) !== -1).map((t) => t.itemId));
      indicesToBeRemoved.push(...items.filter((item) => trackIds.indexOf(item.rawId) !== -1).map((t) => t.index));
    });

    const response = await this.fetch(`${PandoraAPI.BASE_URL}v6/playlists/deleteTracks`, {
      method: 'POST',
      headers: await this.requestHeaders(),
      body: JSON.stringify({
        request: {
          pandoraId: playlistId,
          playlistVersion: version,
          trackItemIds: itemIdsToBeRemoved,
        },
        trackIndexes: indicesToBeRemoved,
      }),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to remove tracks [${JSON.stringify(
          trackIds
        )}] to playlist [${playlistId}, ${version}] on Pandora got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    try {
      PandoraAPI.validatePandoraResponse(jsonBody);
    } catch (e) {
      if (e instanceof FetchError && e.code === 99000) {
        delete this.playlistsVersions[playlistId];
        return this.removeTracksFromPlaylist(playlistId, trackIds);
      }
      throw e;
    }
    const playlist = PandoraPlaylist.fromData(jsonBody);
    if (playlist && playlist.additionalData?.version !== undefined) {
      this.playlistsVersions[playlistId] = playlist.additionalData.version;
    }
    return playlist;
  }

  async removeAllTracksFromPlaylist(playlistId: string): Promise<PandoraPlaylist | null> {
    const itemIdsToBeRemoved: (number | undefined)[] = [];
    const indicesToBeRemoved: (number | undefined)[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    const version = await this.loadPaginatedPlaylistItems(playlistId, async (items) => {
      itemIdsToBeRemoved.push(...items.map((t) => t.itemId));
      indicesToBeRemoved.push(...items.map((t) => t.index));
    });
    const response = await this.fetch(`${PandoraAPI.BASE_URL}v6/playlists/deleteTracks`, {
      method: 'POST',
      headers: await this.requestHeaders(),
      body: JSON.stringify({
        request: {
          pandoraId: playlistId,
          playlistVersion: version,
          trackItemIds: itemIdsToBeRemoved,
        },
        trackIndexes: indicesToBeRemoved,
      }),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to remove all tracks to playlist [${playlistId}, ${version}] on Pandora got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    try {
      PandoraAPI.validatePandoraResponse(jsonBody);
    } catch (e) {
      if (e instanceof FetchError && e.code === 99000) {
        delete this.playlistsVersions[playlistId];
        return this.removeAllTracksFromPlaylist(playlistId);
      }
      throw e;
    }
    const playlist = PandoraPlaylist.fromData(jsonBody);
    if (playlist && playlist.additionalData?.version !== undefined) {
      this.playlistsVersions[playlistId] = playlist.additionalData.version;
    }
    return playlist;
  }

  async moveTrackInPlaylist(
    playlistId: string,
    version: number,
    moves: {
      itemId: number;
      oldIndex: number;
      newIndex: number;
    }[]
  ): Promise<number> {
    const response = await this.fetch(`${PandoraAPI.BASE_URL}v7/playlists/editTracks`, {
      method: 'POST',
      headers: await this.requestHeaders(),
      body: JSON.stringify({
        request: {
          pandoraId: playlistId,
          playlistVersion: version,
          moves,
        },
      }),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to move  track to playlist [${playlistId}, ${version}] on Pandora got wrong response[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return data.version;
  }

  static getCurrentTime(): number {
    return new Date().getTime() / 1000;
  }

  static async login(username: string, password: string): Promise<PandoraAuthenticationData> {
    const client = {
      deviceModel: 'android-generic',
      username: 'android',
      password: 'AC7IBG09A3DTSYM4R41UJWL07VLN8JI7',
      rpcUrl: 'https://tuner.pandora.com/services/json/',
      encryptKey: '6#26FRL$ZWD',
      decryptKey: 'R=U!LH$O2B#',
      version: '5',
    };
    const partnerResponse = await fetchRetry(
      `${client.rpcUrl}?${qs.stringify({
        method: 'auth.partnerLogin',
      })}`,
      {
        method: 'POST',
        body: JSON.stringify({
          deviceModel: client.deviceModel,
          username: client.username,
          password: client.password,
          version: client.version,
        }),
      }
    );
    if (partnerResponse.status !== 200) {
      const text = await partnerResponse.text();
      throw new FetchError(partnerResponse.status, `Could not fetch partner data from Pandora: ${text}`);
    }
    const partnerData = await partnerResponse.json();
    if (partnerData.stat !== 'ok') {
      throw new FetchError(
        partnerResponse.status,
        `Could not fetch partner data from Pandora: ${JSON.stringify(partnerData)}`
      );
    }
    const {
      result: { partnerId, partnerAuthToken, syncTime },
    } = partnerData;

    const decryptedSyncTime = PandoraAPI.decrypt(client.decryptKey, syncTime);
    const syncTimeOffset = PandoraAPI.getNow() - decryptedSyncTime;
    const authResponse = await fetchRetry(
      `${client.rpcUrl}?${qs.stringify({
        method: 'auth.userLogin',
        partner_id: partnerId,
        auth_token: partnerAuthToken,
      })}`,
      {
        method: 'POST',
        body: PandoraAPI.encrypt(
          client.encryptKey,
          JSON.stringify({
            username,
            password,
            loginType: 'user',
            returnIsSubscriber: true,
            syncTime: PandoraAPI.getNow() + syncTimeOffset,
            partnerAuthToken,
            includeUserWebname: true,
            returnUserstate: true,
          })
        ),
      }
    );
    if (authResponse.status !== 200) {
      const text = await partnerResponse.text();
      throw new FetchError(partnerResponse.status, `Could not authenticate to Pandora: ${text}`);
    }
    const authData = await authResponse.json();
    const {
      result: { username: pandoraUsername, userId, webname, userAuthToken },
    } = authData;

    const pandoraApi = new PandoraAPI(pandoraUsername, userAuthToken);
    const billingInfo = await pandoraApi.fetchBillingInfo();

    return {
      authId: pandoraUsername,
      userUUID: null,
      title: pandoraUsername,
      subTitle: webname ?? null,
      imageUrl: null,
      expiresAt: null,
      additionalData: {
        authToken: userAuthToken,
        userId,
        activeProductTier: billingInfo.activeProductTier,
      },
    };
  }

  static decrypt(key: string, data: string) {
    let syncTime = blowfish.decrypt(data, key, {
      cipherMode: 0,
      outputType: 1,
    });
    syncTime = syncTime.slice(4);
    // which we parse into a number
    syncTime = tryParseInt(syncTime);
    return syncTime;
  }

  static encrypt(key: string, data: string) {
    return blowfish.encrypt(data, key, {
      cipherMode: 0,
      outputType: 1,
    });
  }

  static getNow() {
    return Math.floor(Date.now() / 1000);
  }
}
