import React, { useMemo } from 'react';
import { TypedMemo } from '../../types';
import { MarginProps } from './props';

const MarginComponent: React.FC<React.PropsWithChildren<MarginProps>> = ({
  marginTop = 0,
  marginBottom = 0,
  marginLeft = 0,
  marginRight = 0,
  children,
}) => {
  const styles: React.CSSProperties = useMemo(
    () => ({
      marginTop: `${marginTop}px`,
      marginBottom: `${marginBottom}px`,
      marginLeft: `${marginLeft}px`,
      marginRight: `${marginRight}px`,
    }),
    [marginTop, marginBottom, marginLeft, marginRight]
  );
  return <span style={styles}>{children}</span>;
};

export const Margin = TypedMemo(MarginComponent);
