import { GenericCollectionItem } from '../../../generics/models/GenericCollectionItem';
import { QQMusicTrackData } from '../types';
import { getCoverUrlFromAlbumMid } from '../utils';

export class QQMusicCollectionTrack extends GenericCollectionItem {
  static fromData(data: QQMusicTrackData | undefined): QQMusicCollectionTrack | null {
    if (!data?.id || !data.songname) {
      return null;
    }
    const id = `${data.id}`;
    return new GenericCollectionItem({
      rawId: id,
      globalId: id,
      name: data.songname,
      album: data.diskname,
      artist: data.singername,
      imageUrl: getCoverUrlFromAlbumMid(data.album_mid),
    });
  }
}
