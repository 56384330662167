import React from 'react';
import { TextLinkProps } from './props';
import { TypedMemo } from '../../types';
import { ClickableDiv } from '../ClickableDiv';

const TextLinkComponent: React.FC<React.PropsWithChildren<TextLinkProps>> = ({ children, onClick }) => {
  return (
    <ClickableDiv onClick={onClick} className="my-2 ui-text">
      <span className="text-primary">{children}</span>
    </ClickableDiv>
  );
};

export const TextLink = TypedMemo(TextLinkComponent);
