import { AppleMusicPlaylistRawResponse } from './models/types';
import { tryParseInt } from '../../utils/tryParseInt';
import { artistsToString } from '../../utils/artistsConverter';

export function buildArtworkUrl(artwork: Record<string, any> | undefined): string | undefined {
  const defaultCoverSize = 400;
  if (!artwork?.url) {
    return undefined;
  }
  return artwork.url
    .replace('{w}', artwork.width ?? defaultCoverSize)
    .replace('{h}', artwork.height ?? defaultCoverSize)
    .replace('{f}', 'jpg');
}

const appleMusicPublicResourceUrl = 'https://music.apple.com';

export function createAppleMusicPlaylistUrl(data: AppleMusicPlaylistRawResponse) {
  const globalId = data.attributes?.playParams?.globalId;
  if (!globalId) {
    return undefined;
  }
  return `${appleMusicPublicResourceUrl}/playlist/${globalId}`;
}

export function convertDurationToMs(duration: string | undefined) {
  const durationParts = duration?.match(/\d+/g);
  if (!durationParts || durationParts.length !== 2) {
    return undefined;
  }
  const [minutesString, secondsString] = durationParts;
  const minutes = tryParseInt(minutesString);
  const seconds = tryParseInt(secondsString);
  if (minutes === undefined) {
    return undefined;
  }
  return (minutes * 60 + (seconds ?? 0)) * 1000;
}

export function getArtistsNames(artists: { '@type'?: string; name?: string; url?: string }[] | undefined) {
  if (!artists || artists.length === 0) {
    return undefined;
  }

  const artistsNames = artists.reduce<string[]>((result, artist) => {
    if (artist.name) {
      result.push(artist.name);
    }
    return result;
  }, []);

  return artistsToString(artistsNames);
}
