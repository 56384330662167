import { MusicService } from './types';
import { ImporterID } from '../../importers/types';

export const fileBackup: MusicService = {
  id: 'file-backup-excel-csv',
  importerID: ImporterID.File,
  linkType: undefined,
  musicAPIID: undefined,
  shortName: 'File Backup',
  name: 'File Backup (Excel)',
  isComingSoon: true,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: true,
  color: { label: '#6417FB', background: '#A479FA' },
};
