import URL from 'url-parse';
import { LinkType, recognizeLinkTypeByUrl } from 'fym-common/src/utils/recognizeLinkTypeByUrl';
import { normalizeUrl } from 'fym-common/src/utils/normalizeUrl';
import { SerializedSocialLink } from 'fym-common/src/db/serializersTypes';
import { FieldsType } from './consts';
import { iconForLink } from '../../smartLinks/socialLinksList/utils';

export function extractChangedSocialLinks(
  currentSocialLinks: FieldsType['socialLinks'],
  oldSocialLinks?: SerializedSocialLink[]
) {
  const updatedSocialLinks: { id: number; url: string }[] = [];
  const newUrls: string[] = [];
  const nonDeletedIds: number[] = [];
  currentSocialLinks.forEach(({ socialLinkId, url }) => {
    if (url.length === 0) {
      return;
    }
    const isOldLink = socialLinkId !== undefined;
    if (isOldLink) {
      nonDeletedIds.push(socialLinkId);
      if (oldSocialLinks) {
        const oldSocialLink = oldSocialLinks.find((socialLink) => socialLink.id === socialLinkId);
        const hasChanged = !!oldSocialLink && oldSocialLink.url !== url;
        if (hasChanged) {
          updatedSocialLinks.push({ id: socialLinkId, url });
        }
      }
    } else {
      newUrls.push(url);
    }
  });
  const deletedIds = oldSocialLinks?.filter(({ id }) => !nonDeletedIds.includes(id)).map(({ id }) => id);
  return { create: newUrls, update: updatedSocialLinks, remove: deletedIds ?? [] };
}

export function generateIconForUrl(url: string) {
  try {
    const link = recognizeLinkTypeByUrl(new URL(normalizeUrl(url)));
    return iconForLink(link);
  } catch {
    return iconForLink(LinkType.generic);
  }
}
