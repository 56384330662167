import { TranslatedProgressMessageType } from 'fym-common/src/types/intl';
import { JWTUser } from 'fym-common/src/db/userTypes';

export interface AppInfoStateType {
  progress: Record<string, TranslatedProgressMessageType | undefined>;
  deviceId: string;
  realmPartition?: string;
  realmJWT?: string;
  userToken: string | null;
  userTokenDecoded: JWTUser | null;
  showLicenseActivationModal: boolean;
  accountId: number | null;
  isOnline: boolean;
  isServerUp: boolean;
}

export const AppInfoState: AppInfoStateType = {
  progress: {},
  deviceId: '',
  realmPartition: undefined,
  realmJWT: undefined,
  userToken: null,
  userTokenDecoded: null,
  showLicenseActivationModal: false,
  accountId: null,
  isOnline: true,
  isServerUp: true,
};
