import { CollectionPickerTabType } from '../../../modules/collectionsPicker/views/tabs/props';

export type CollectionsPickersStateType = {
  selectedCollectionTabType: CollectionPickerTabType | null;
  connectionBeingEdited: {
    tempTransferModelIdString: string;
    sourceCollectionIdString: string;
  } | null;
};

export const CollectionsPickersState: CollectionsPickersStateType = {
  selectedCollectionTabType: null,
  connectionBeingEdited: null,
};
