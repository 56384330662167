export enum FieldsName {
  email = 'email',
  password = 'password',
  name = 'name',
}

export type FieldsType = {
  [FieldsName.email]: string;
  [FieldsName.password]: string;
  [FieldsName.name]: string;
};
