import { GenericAnalyticsType } from './genericAnalyticsTypes';
import { config } from '../../../config/config';
import { reactPixel } from './reactPixel';
import { Sentry } from '../../errorBoundary/sentry';

export const GenericAnalytics: GenericAnalyticsType = {
  async screen(name) {
    await window.analytics?.page(name);

    window.gtag('config', config.gTagID, { page_path: name });
    reactPixel.pageView();
  },
  async track(event, data) {
    await window.analytics?.track(event, data);

    window.gtag('event', event, data);
    reactPixel.trackCustom(event, data);
  },
  async identify(user, traits) {
    await window.analytics?.identify(user, traits);

    window.gtag('config', config.gTagID, {
      user_id: user,
      ...traits,
    });
  },
  sentryIdentity(email, realmId) {
    Sentry.configureScope((scope) => {
      scope.setUser({ email, realmId });
    });
  },
  setup(): Promise<void> {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${config.gTagID}`;

    window.dataLayer = window.dataLayer || [];

    function gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    }

    window.gtag = gtag;
    window.gtag('js', new Date());

    // Insert our script next to the first script element.
    const first = document.getElementsByTagName('script')[0];
    first?.parentNode?.insertBefore(script, first);

    // FB PIXEL
    reactPixel.init(config.fbPixelID);

    /* eslint-disable no-multi-assign,@typescript-eslint/strict-boolean-expressions,prefer-rest-params,func-names,no-plusplus */
    // SEGMENT
    // Create a queue, but don't obliterate an existing one!
    const analytics = (window.analytics = window.analytics || []);

    // If the snippet was invoked already show an error.
    if (analytics.invoked) {
      if (window.console && console.error) {
        console.error('Segment snippet included twice.');
      }
      return Promise.resolve();
    }

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true;

    // A list of the methods in Analytics.js to stub.
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
    ];

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    analytics.factory = function (method: any) {
      return function () {
        const args = Array.prototype.slice.call(arguments);
        args.unshift(method);
        analytics.push(args);
        return analytics;
      };
    };

    // For each of our methods, generate a queueing stub.
    for (let i = 0; i < analytics.methods.length; i++) {
      const key = analytics.methods[i];
      analytics[key] = analytics.factory(key);
    }

    // Define a method to load Analytics.js from our CDN,
    // and that will be sure to only ever load it once.
    analytics.load = function (key: any, options?: any) {
      // Create an async script element based on your key.
      const _script = document.createElement('script');
      _script.type = 'text/javascript';
      _script.async = true;
      _script.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;

      // Insert our script next to the first script element.
      const _first = document.getElementsByTagName('script')[0];
      _first?.parentNode?.insertBefore(_script, _first);
      analytics._loadOptions = options;
    };

    // Add a version to keep track of what's in the wild.
    analytics.SNIPPET_VERSION = '4.1.0';

    // Load Analytics.js with your key, which will automatically
    // load the tools you've enabled for your account. Boosh!
    analytics.load(config.segmentId);
    return Promise.resolve();
  },
};
