export enum PaddleProductSku {
  OldiOSLicense = 510652,
  OldAndroidLicense = 510653,
  OldBundleLicense = 510699,
  OldSingleLicense = 504694,
  SingleLicense = 584857,
  Lifetime = 633565,
}

export enum PaddleSubscriptionSku {
  Quarterly = 585869,
  Yearly = 586830,
  SmartLinksInfluencer = 634310,
  SmartLinksArtist = 634311,
  SmartLinksRookie = 776739,
}

export const allPaddleSkus = [
  PaddleProductSku.SingleLicense,
  PaddleProductSku.Lifetime,
  PaddleSubscriptionSku.Quarterly,
  PaddleSubscriptionSku.Yearly,
  PaddleSubscriptionSku.SmartLinksInfluencer,
  PaddleSubscriptionSku.SmartLinksArtist,
  PaddleSubscriptionSku.SmartLinksRookie,
];
