import React from 'react';
import { Button } from 'reactstrap';
import { TypedMemo } from '../../common/types';
import { SupportEmailRendererProps } from './props';

const SupportEmailRendererComponent: React.FC<SupportEmailRendererProps> = ({ email, color = 'link', onClick }) => {
  return (
    <Button color={color} onClick={onClick}>
      {email}
    </Button>
  );
};
export const SupportEmailRenderer = TypedMemo(SupportEmailRendererComponent);
