import { GenericAuthor } from '../../../generics/models/GenericAuthor';
import { Artist } from './types';
import { getBiggestImageUrlFromImages } from '../utils';

export class AmazonMusicArtist extends GenericAuthor {
  static fromData(data: Artist | undefined | null): AmazonMusicArtist | null {
    const { id, images, name, url } = data ?? {};
    if (!name || !id) {
      return null;
    }
    return new AmazonMusicArtist({
      rawId: id,
      name,
      imageUrl: getBiggestImageUrlFromImages(images),
      publicUrl: url,
    });
  }

  static fromShareURLData(data: any, rawId: string, url: string): AmazonMusicArtist | null {
    const name = data?.headerText?.text;
    if (!name) {
      return null;
    }
    return new AmazonMusicArtist({
      rawId,
      name,
      imageUrl: data.backgroundImage,
      publicUrl: url,
    });
  }
}
