import { validateISRC } from '../../utils/validateISRC';

export function getCoverUrlFromResourceId(id: string) {
  return `https://direct.rhapsody.com/imageserver/images/${id}/500x500.jpeg`;
}

const napsterPublicResourceUrl = 'https://play.napster.com';

export function createNapsterAlbumUrl(albumId: string) {
  return `${napsterPublicResourceUrl}/album/${albumId}`;
}

export function createNapsterPlaylistUrl(playlistId: string) {
  return `${napsterPublicResourceUrl}/playlist/${playlistId}`;
}

export function createNapsterTrackUrl(trackId: string) {
  return `${napsterPublicResourceUrl}/track/${trackId}`;
}

export function createNapsterArtistUrl(artistId: string) {
  return `${napsterPublicResourceUrl}/artist/${artistId}`;
}

export function convertNapsterTrackData(data: any) {
  if (!data?.id || !data.name) {
    return null;
  }
  return {
    rawId: data.id,
    name: data.name,
    album: data.albumName,
    artist: data.artistName,
    isrc: validateISRC(data?.isrc),
    imageUrl: data.albumId ? getCoverUrlFromResourceId(data.albumId) : undefined,
    duration: data.playbackSeconds ? data.playbackSeconds * 1000 : undefined,
    previewUrl: data.previewURL,
    sourceURL: createNapsterTrackUrl(data.id),
    additionalData: {
      albumId: data.albumId,
    },
  };
}

export function getBiggestImage(images: { width?: number; height?: number; url?: string }[] | undefined) {
  if (!images) {
    return undefined;
  }
  return images.sort((a, b) => (b.width ?? 0) - (a.width ?? 0))[0]?.url;
}
