import React, { useCallback } from 'react';
import { tryParseInt } from 'fym-common/src/utils/tryParseInt';
import { TypedMemo } from '../../../common/types';
import { useSMLRouter } from '../../../../routing/sml/hooks';
import { TrackerForm, TrackersFormProps } from '../TrackersForm/TrackerForm';
import { useQueryParam } from '../../../../utils/useQueryParam';
import { useCreateTrackerMutation, useGetTrackersQuery, useUpdateTrackerMutation } from '../../../../redux/api/fymAPI';
import { isSuccessfulResult } from '../../../../redux/api/utils/isSuccessfulResult';
import { SMLRoutes } from '../../../../routing/sml/SMLRoutes';
import { useDeleteTrackerFlow } from '../../hooks';

interface TrackerEditionViewProps {
  route?: { params: { accountId: number } };
}

const TrackerEditionViewComponent: React.FC<TrackerEditionViewProps> = ({ route }) => {
  const router = useSMLRouter();
  const accountIdString = useQueryParam('accountId', route, null);
  const accountId = tryParseInt(accountIdString);
  const trackerIdString = useQueryParam('trackerId', route, null);
  const trackerId = tryParseInt(trackerIdString);

  if (accountId === undefined) {
    throw new Error('Missing accountId in TrackerEditionView');
  }

  const { openTrackerDeletionModal, isDeleteTrackerLoading, deleteTrackerError, TrackerDeletionModal } =
    useDeleteTrackerFlow(accountId, trackerId);

  const { tracker, isTrackerLoading, getTrackerError } = useGetTrackersQuery(accountId ?? 0, {
    selectFromResult: ({ data, isLoading, error }) => ({
      getTrackerError: error,
      isTrackerLoading: isLoading,
      tracker: trackerId !== undefined ? data?.trackers.find(({ id }) => id === trackerId) : undefined,
    }),
  });

  const [createTracker, { isLoading: isCreateTrackerLoading, error: createTrackerError }] = useCreateTrackerMutation();

  const [updateTracker, { isLoading: isUpdateTrackerLoading, error: updateTrackerError }] = useUpdateTrackerMutation();

  const onSubmit: TrackersFormProps['onSubmit'] = useCallback(
    async ({ snippet }) => {
      let isSuccessfulCall: boolean;
      if (tracker) {
        const updateSmartLinkResult = await updateTracker({ accountId, trackerId: tracker.id, snippet });
        isSuccessfulCall = isSuccessfulResult(updateSmartLinkResult);
      } else {
        const createTrackerResult = await createTracker({ accountId, snippet });
        isSuccessfulCall = isSuccessfulResult(createTrackerResult);
      }
      if (isSuccessfulCall) {
        router.goBack(SMLRoutes.trackers);
      }
    },
    [router, accountId, tracker, createTracker, updateTracker]
  );

  return (
    <>
      {TrackerDeletionModal}
      <TrackerForm
        onSubmit={onSubmit}
        tracker={tracker}
        onDelete={tracker ? openTrackerDeletionModal : undefined}
        isLoading={isTrackerLoading || isCreateTrackerLoading || isUpdateTrackerLoading || isDeleteTrackerLoading}
        error={getTrackerError ?? createTrackerError ?? updateTrackerError ?? deleteTrackerError}
      />
    </>
  );
};

export const TrackerEditionView = TypedMemo(TrackerEditionViewComponent);
