import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { FieldsName } from './consts';
import { StoreType } from '../../../../../redux/store';
import { getValidateSmartLinkSlugFn } from '../../../../forms/utils/getValidateSmartLinkSlugFn';

export function createResolver(store: StoreType, accountId: number, smartLinkId?: number, isMainSmartLink?: boolean) {
  const validateSlug = getValidateSmartLinkSlugFn(store, accountId, smartLinkId);
  const baseSlugSchema = Joi.string().label('Slug');
  const schema = Joi.object({
    [FieldsName.slug]:
      isMainSmartLink === true ? baseSlugSchema.optional().allow('') : baseSlugSchema.required().external(validateSlug),
  });
  return joiResolver(schema);
}
