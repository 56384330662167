import concat from 'lodash/concat';
import URL from 'url-parse';
import { GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionAccess, CollectionType } from '../../../generics/models/Collection';
import { nav, YouTubeMusicParser } from '../youTubeMusicParser';
import { createYouTubeMusicCollectionUrl, getBiggestImageUrlFromThumbnails } from '../utils';
import { tryParseInt } from '../../../utils/tryParseInt';

function convertYouTubeMusicPrivacyToAccess(privacy: 'PRIVATE' | 'PUBLIC' | 'UNLISTED' | undefined) {
  switch (privacy) {
    case 'PRIVATE':
      return CollectionAccess.private;
    case 'PUBLIC':
    case 'UNLISTED':
    default:
      return CollectionAccess.public;
  }
}

export class YouTubeMusicPlaylist extends GenericCollection {
  static fromData(data: any, channelId?: string, type?: CollectionType): YouTubeMusicPlaylist | null {
    const pData = data?.musicTwoRowItemRenderer;
    if (!pData?.title || Object.keys(pData.title).length === 0) {
      return null;
    }
    const rawId = nav(pData, concat(YouTubeMusicParser.TITLE, YouTubeMusicParser.NAVIGATION_BROWSE_ID)).slice(2);
    const name = nav(pData, YouTubeMusicParser.TITLE_TEXT);
    if (!rawId || !name) {
      return null;
    }
    let itemCount;
    if (pData.subtitle.runs.length >= 5) {
      const subtitleElements = nav(pData, YouTubeMusicParser.SUBTITLE);
      const lastSubtitleText = subtitleElements[subtitleElements.length - 1].text;
      const parsedCount = tryParseInt(lastSubtitleText.split(' ')[0]);
      itemCount = Number.isNaN(parsedCount) ? itemCount : parsedCount;
    }
    const playlistChannelId = nav(pData, YouTubeMusicParser.CHANNEL_ID);
    const isUserOwner = !!(channelId && channelId === playlistChannelId);
    const playlistType = isUserOwner ? CollectionType.PLAYLIST : CollectionType.LIKED_PLAYLIST;
    const finalType = type ?? playlistType;
    return new YouTubeMusicPlaylist({
      type: finalType,
      rawId,
      name,
      itemCount,
      access: isUserOwner ? undefined : CollectionAccess.public,
      publicUrl: createYouTubeMusicCollectionUrl(nav(pData, YouTubeMusicParser.PLAYLIST_ID)),
      additionalData: {
        imageUrl: getBiggestImageUrlFromThumbnails(
          pData.thumbnailRenderer?.musicThumbnailRenderer?.thumbnail?.thumbnails
        ),
      },
    });
  }

  static fromHTMLData(data: any): YouTubeMusicPlaylist | null {
    const musicPlaylistShelfRenderer = nav(
      data,
      concat(YouTubeMusicParser.SINGLE_COLUMN_TAB, YouTubeMusicParser.SECTION_LIST)
    )[0]?.musicPlaylistShelfRenderer;
    const rawId = musicPlaylistShelfRenderer.playlistId;
    const musicDetailHeaderRenderer =
      data.header?.musicEditablePlaylistDetailHeaderRenderer?.header?.musicDetailHeaderRenderer ??
      data.header?.musicDetailHeaderRenderer;
    const name = musicDetailHeaderRenderer?.title?.runs?.[0]?.text;
    if (!rawId || !name) {
      return null;
    }
    const description = musicDetailHeaderRenderer?.description?.runs?.[0]?.text;
    const isOwnPlaylist = !!musicDetailHeaderRenderer?.menu?.menuRenderer?.items?.find(
      (item: any) => item.menuNavigationItemRenderer?.icon?.iconType === 'DELETE'
    );
    const itemCount = musicPlaylistShelfRenderer.contents?.length ?? musicPlaylistShelfRenderer.collapsedItemCount;
    const playlistType = isOwnPlaylist ? CollectionType.PLAYLIST : CollectionType.LIKED_PLAYLIST;
    const privacy =
      data.header?.musicEditablePlaylistDetailHeaderRenderer?.editHeader?.musicPlaylistEditHeaderRenderer?.privacy;
    return new YouTubeMusicPlaylist({
      type: playlistType,
      rawId,
      name,
      itemCount,
      publicUrl: createYouTubeMusicCollectionUrl(rawId),
      access: convertYouTubeMusicPrivacyToAccess(privacy),
      additionalData: {
        description,
        imageUrl: getBiggestImageUrlFromThumbnails(
          musicDetailHeaderRenderer?.thumbnail?.croppedSquareThumbnailRenderer?.thumbnail?.thumbnails
        ),
      },
    });
  }

  static fromShareURLData(data: any, rawId: string, url: string): YouTubeMusicPlaylist | null {
    const name = data?.header?.musicDetailHeaderRenderer?.title?.runs?.[0]?.text;
    if (!rawId || !name) {
      return null;
    }
    const type = JSON.stringify(data.responseContext?.serviceTrackingParams)?.includes('GetBrowseAlbumDetailPage')
      ? CollectionType.ALBUM
      : CollectionType.LIKED_PLAYLIST;
    const thumbnails =
      data?.header?.musicDetailHeaderRenderer?.thumbnail?.croppedSquareThumbnailRenderer?.thumbnail?.thumbnails;
    const thumbnail = getBiggestImageUrlFromThumbnails(thumbnails);
    const thumbnailUrl = thumbnail ? new URL(thumbnail) : undefined;
    const imageUrl = thumbnailUrl ? `${thumbnailUrl.origin}${thumbnailUrl.pathname}` : undefined;
    const itemCount = data?.header?.musicDetailHeaderRenderer?.secondSubtitle?.runs?.[0]?.text;
    return new YouTubeMusicPlaylist({
      type,
      rawId,
      name,
      itemCount: tryParseInt(itemCount),
      additionalData: { artist: data?.header?.musicDetailHeaderRenderer?.subtitle?.runs?.[2]?.text, imageUrl },
      publicUrl: url,
    });
  }
}
