import { DOMParser } from '@xmldom/xmldom';

export const domParser = new DOMParser({
  errorHandler: {
    warning: () => undefined,
    error: () => undefined,
    fatalError: () => undefined,
  },
});

export function getScriptsFromHTML(HTMLString: string): HTMLCollectionOf<HTMLScriptElement> {
  const htmlDoc = domParser.parseFromString(HTMLString, 'text/html');
  return htmlDoc.getElementsByTagName('script');
}

export function getScriptsObjectsFromHTML(HTMLString: string) {
  const scriptsObjects: {
    id: string | null;
    type: string | null;
    content: string | null;
    src: string | null;
  }[] = [];
  const scripts = getScriptsFromHTML(HTMLString);

  for (let i = 0; i < scripts.length; i += 1) {
    const script = scripts[i];
    if (script) {
      scriptsObjects.push({
        content: script.firstChild?.nodeValue ?? null,
        id: script.getAttribute('id'),
        type: script.getAttribute('type'),
        src: script.getAttribute('src'),
      });
    }
  }

  return scriptsObjects;
}

export function getScriptsContents(HTMLString: string) {
  const scriptsContents: string[] = [];
  const scripts = getScriptsFromHTML(HTMLString);

  for (let i = 0; i < scripts.length; i += 1) {
    const script = scripts[i];
    if (script) {
      const content = script.firstChild?.nodeValue;
      if (content) {
        scriptsContents.push(content);
      }
    }
  }

  return scriptsContents;
}

export function getScriptsSources(HTMLString: string) {
  const scriptsSources: string[] = [];
  const scripts = getScriptsFromHTML(HTMLString);

  for (let i = 0; i < scripts.length; i += 1) {
    const script = scripts[i];
    if (script) {
      const srcAttr = script.getAttribute('src');
      if (srcAttr) {
        scriptsSources.push(srcAttr);
      }
    }
  }

  return scriptsSources;
}

export function getMetaContentBy(
  metaElements: HTMLCollectionOf<HTMLMetaElement>,
  key: string,
  value: string
): string | undefined {
  let content: string | undefined;
  for (let i = 0; i < metaElements.length; i += 1) {
    const meta = metaElements[i];
    if (meta && meta.getAttribute(key) === value) {
      content = meta.getAttribute('content') ?? undefined;
      break;
    }
  }
  return content;
}

export function getMetaContentByProperty(
  metaElements: HTMLCollectionOf<HTMLMetaElement>,
  value: string
): string | undefined {
  return getMetaContentBy(metaElements, 'property', value);
}

export function getMetaContentByName(
  metaElements: HTMLCollectionOf<HTMLMetaElement>,
  value: string
): string | undefined {
  return getMetaContentBy(metaElements, 'name', value);
}
