import React, { Fragment } from 'react';
import { TypedMemo } from '../../common/types';
import { SMLSettingsViewRendererProps } from './SettingsProps';
import { SupportView } from '../../support/SupportView';
import { SettingsListItem } from './SettingsListItem';
import { ItemSeparator } from '../../common/views/itemSeparator/ItemSeparator';

const SMLSettingsViewRendererComponent: React.FC<SMLSettingsViewRendererProps> = ({ sectionListData }) => {
  return (
    <div className="sml-settings mt-5">
      {sectionListData.map((section) => (
        <Fragment key={section.title}>
          <h2 className="settings-title ui-text">{section.title}</h2>
          <div className="settings-items-container">
            {section.data.map((item, index, items) => {
              const isLast = index === items.length - 1;
              if (item.isHidden ?? false) {
                return null;
              }
              return (
                <Fragment key={item.id}>
                  {item.customElement ? (
                    item.customElement
                  ) : (
                    <>
                      <ItemSeparator />
                      <SettingsListItem item={item} />
                      {isLast && <ItemSeparator />}
                    </>
                  )}
                </Fragment>
              );
            })}
          </div>
        </Fragment>
      ))}
      <SupportView />
    </div>
  );
};

export const SMLSettingsViewRenderer = TypedMemo(SMLSettingsViewRendererComponent);
