import { GenericMatchedItem, MatchedItemModelData } from '../../../generics/models/GenericMatchedItem';
import { convertNapsterTrackData } from '../utils';

export class NapsterMatchedTrack extends GenericMatchedItem {
  constructor(data: MatchedItemModelData, public albumId: string | null = null, public artistId: string | null = null) {
    super(data);
  }

  static fromData(data: any): NapsterMatchedTrack | null {
    const trackData = convertNapsterTrackData(data);
    if (!data || !trackData) {
      return null;
    }
    return new NapsterMatchedTrack(trackData, data.albumId, data.artistId);
  }
}
