import React, { useCallback, useMemo, useState } from 'react';
import { Container } from 'reactstrap';
import { useIntl } from 'react-intl';
import { LicenseModelProductType, LicenseModelTransactionVendor } from 'fym-common/src/license/consts';
import { TypedMemo } from '../../common/types';
import { PaddlePaymentViewProps } from './props';
import { SmartLinkLicenseStatus } from '../licenseStatus/SmartLinkLicenseStatus';
import { PaymentPlanHeader } from '../paymentPlanHeader/PaymentPlanHeader';
import { PaddleSmartLinksPremiumLicensePlans } from './PaddleSmartLinksPremiumLicensePlans';
import { PaddleUpgradeView } from '../upgrades/PaddleUpgradeView';
import { NotSupportedUpgradeView } from '../upgrades/NotSupportedUpgradeView';
import { preparePaddlePricing } from './utils/preparePaddlePricing';
import { openSmartLinksPaddlePayment } from './utils/openSmartLinksPaddlePayment';
import { useDeviceId } from '../../../redux/slices/appInfo/hooks';
import { usePaddle } from './hooks/usePaddle';
import { LoadingView } from '../../common/views/LoadingView';
import { ErrorAlert } from '../../common/views/ErrorAlert/ErrorAlert';
import { useTriggerPaddle } from './hooks/useTriggerPaddle';
import { useValidatePaddleOrder } from '../../licenses/hooks/useValidatePaddleOrder';
import { AnalyticsPurchasePlan } from '../../analytics/types';

const PaddleSmartLinksPaymentViewComponent: React.FC<PaddlePaymentViewProps> = ({ licenseInfo, paddlePricing }) => {
  const isLoading = usePaddle();
  useTriggerPaddle(isLoading);
  const intl = useIntl();
  const deviceId = useDeviceId();
  const [paddleModalSelectedProductType, setPaddleModalSelectedProductType] = useState<
    LicenseModelProductType | undefined
  >();
  const [notSupportedModalIsShown, setNotSupportedModalIsShown] = useState(false);

  const onNotSupportedClose = useCallback(() => {
    setNotSupportedModalIsShown(false);
  }, []);
  const onPaddleModalClose = useCallback(() => {
    setPaddleModalSelectedProductType(undefined);
  }, []);

  const { smartLinksRookiePriceData, smartLinksInfluencerPriceData, smartLinksArtistPriceData } = useMemo(() => {
    return preparePaddlePricing(paddlePricing);
  }, [paddlePricing]);

  const [validatePaddleOrder, { isLoading: isPaddleOrderLoading, error }] = useValidatePaddleOrder();
  const openPayment = useCallback(
    (plan: AnalyticsPurchasePlan, productType: LicenseModelProductType) => {
      if (licenseInfo.isSubscriptionValid() && licenseInfo.transactionVendor !== LicenseModelTransactionVendor.Trial) {
        if (licenseInfo.transactionVendor === LicenseModelTransactionVendor.Paddle) {
          setPaddleModalSelectedProductType(productType);
        } else {
          setNotSupportedModalIsShown(true);
        }
      } else {
        openSmartLinksPaddlePayment({
          plan,
          email: licenseInfo.license?.email ?? '',
          licenseKey: licenseInfo.existingLicenseKey,
          deviceId,
          coupon: '',
          locale: intl.locale,
          onSuccess: (checkoutId) => {
            if (checkoutId) {
              validatePaddleOrder({ orderId: checkoutId }).catch(console.error);
            }
          },
        }).catch(console.error);
      }
    },
    [licenseInfo, deviceId, intl.locale, validatePaddleOrder]
  );

  const onSmartLinksRookieClick = useCallback(() => {
    openPayment(AnalyticsPurchasePlan.SmartlinksRookie, LicenseModelProductType.SmartLinksRookie);
  }, [openPayment]);

  const onSmartLinksInfluencerClicked = useCallback(() => {
    openPayment(AnalyticsPurchasePlan.SmartlinksInfluencer, LicenseModelProductType.SmartLinksInfluencer);
  }, [openPayment]);

  const onSmartLinksArtistClicked = useCallback(() => {
    openPayment(AnalyticsPurchasePlan.SmartlinksArtist, LicenseModelProductType.SmartLinksArtist);
  }, [openPayment]);

  if (isLoading || isPaddleOrderLoading) {
    return <LoadingView />;
  }
  if (error) {
    return (
      <ErrorAlert
        error={error}
        customMessage={intl.formatMessage(
          {
            id: 'app.paddle.order_validation.error',
            defaultMessage: 'Could not fetch license info from Paddle order, please contact {email}',
          },
          {
            email: 'support@music24.com',
          }
        )}
      />
    );
  }
  return (
    <Container>
      <NotSupportedUpgradeView
        isShown={notSupportedModalIsShown}
        onClose={onNotSupportedClose}
        licenseInfo={licenseInfo}
      />
      <PaddleUpgradeView productType={paddleModalSelectedProductType} onClose={onPaddleModalClose} />
      <PaymentPlanHeader
        title={intl.formatMessage({ id: 'app.payment_plans.smartlinks.title', defaultMessage: 'SmartLinks' })}
        subtitle={intl.formatMessage({
          id: 'app.payment_plans.smartlinks.subtitle',
          defaultMessage:
            'Share your music on Spotify, Apple Music, and other platforms at once 👊. Track their performance. Attract a new audience and delight your listeners.',
        })}
      />
      <SmartLinkLicenseStatus licenseInfo={licenseInfo} />

      <PaddleSmartLinksPremiumLicensePlans
        smartLinksRookiePriceData={smartLinksRookiePriceData}
        smartLinksInfluencerPriceData={smartLinksInfluencerPriceData}
        smartLinksArtistPriceData={smartLinksArtistPriceData}
        onSmartLinksRookieClick={onSmartLinksRookieClick}
        onSmartLinksInfluencerClick={onSmartLinksInfluencerClicked}
        onSmartLinksArtistClick={onSmartLinksArtistClicked}
        currentPlan={licenseInfo?.currentPlan}
      />
    </Container>
  );
};

export const PaddleSmartLinksPaymentView = TypedMemo(PaddleSmartLinksPaymentViewComponent);
