import { GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { DeezerAPI } from './DeezerAPI';
import { DeezerPlaylist } from './models/DeezerPlaylist';
import { DeezerAlbum } from './models/DeezerAlbum';
import { CollectionAccess, CollectionType } from '../../generics/models/Collection';
import { FetchError } from '../../generics/errors/FetchError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { DeezerAuthenticationData } from './DeezerAuthenticationData';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { SearchQueryProperties } from '../../generics/types';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { ImporterID } from '../types';
import { deezer } from '../../musicServices/services/Deezer';
import { moveItemsByOffset } from '../../utils/moveItemsByOffset';

const createDeezerInstance = (authenticationData: DeezerAuthenticationData): DeezerAPI => {
  return new DeezerAPI({
    accessToken: authenticationData.additionalData.accessToken,
    userId: authenticationData.authId,
  });
};

export const DeezerImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.Deezer;

  public static musicService = deezer;

  public authenticationData: DeezerAuthenticationData;

  private deezerApi: DeezerAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as DeezerAuthenticationData;
    this.deezerApi = createDeezerInstance(this.authenticationData);
  }

  public setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.authenticationData = authenticationData as DeezerAuthenticationData;
    this.deezerApi = createDeezerInstance(this.authenticationData);
  }

  async getPaginatedCollections(
    onBatch: (collections: (DeezerPlaylist | DeezerAlbum)[]) => Promise<void>
  ): Promise<void> {
    await this.deezerApi.loadPaginatedPlaylists(onBatch);
    await this.deezerApi.loadPaginatedAlbums(onBatch);
  }

  async getCollection(collection: GenericCollection): Promise<DeezerAlbum | DeezerPlaylist> {
    let result: DeezerAlbum | DeezerPlaylist | null;
    if (collection.type === CollectionType.ALBUM) {
      result = (await this.deezerApi.loadAlbum(collection.rawId)).album;
    } else {
      result = await this.deezerApi.loadPlaylist(collection.rawId);
    }
    if (!result) {
      throw new CollectionDoesNotExistsError();
    }
    return result;
  }

  async createCollection(collection: GenericCollection, description?: string): Promise<DeezerPlaylist> {
    let rawId: string | undefined;
    try {
      rawId = await this.deezerApi.createPlaylist(collection.name, description);
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!rawId) {
      throw new CouldNotCreateCollection();
    }
    return new DeezerPlaylist({ type: CollectionType.PLAYLIST, rawId, name: collection.name, itemCount: 0 });
  }

  public async addItemToCollection(
    collection: GenericCollection,
    matchedItem: GenericMatchedItem
  ): Promise<GenericCollectionItem> {
    await this.deezerApi.addTracksToPlaylist(collection.rawId, [matchedItem.rawId]);
    return convertMatchedItemToCollectionItem(matchedItem);
  }

  async addManyItemsToCollection(
    collection: GenericCollection,
    data: {
      matchedItem: GenericMatchedItem;
      position?: number;
    }[]
  ) {
    const tracksIds = data.map(({ matchedItem }) => matchedItem.rawId);
    await this.deezerApi.addTracksToPlaylist(collection.rawId, tracksIds);
  }

  async moveManyItems(props: Parameters<NonNullable<GenericImporter['moveManyItems']>>[0]) {
    const { collection, allItems, itemsToMove, offset } = props;
    const movedItems = moveItemsByOffset({ allItems, itemsToMove, offset });
    const movedItemsRawIds = movedItems.map(({ rawId }) => rawId);
    await this.deezerApi.moveTracksInPlaylist(collection.rawId, movedItemsRawIds);
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    collectionItems: GenericCollectionItem[]
  ): Promise<void> {
    await this.deezerApi.removeTracksFromPlaylist(
      collection.rawId,
      collectionItems.map((item) => item.rawId)
    );
  }

  async clearCollection(collection: GenericCollection): Promise<void> {
    const itemIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    await this.getPaginatedItems(collection, async (items) => {
      itemIds.push(...items.map((item) => item.rawId));
    });
    await this.deezerApi.removeTracksFromPlaylist(collection.rawId, itemIds);
  }

  public async matchItems(queryProps: SearchQueryProperties) {
    let { tracks } = await this.deezerApi.search({ queryProps, advancedSearch: true, type: 'track' });
    if (tracks.length === 0) {
      ({ tracks } = await this.deezerApi.search({ queryProps, advancedSearch: false, type: 'track' }));
    }
    return tracks;
  }

  async matchAlbums(queryProps: SearchQueryProperties) {
    let { albums } = await this.deezerApi.search({ queryProps, advancedSearch: true, type: 'album' });
    if (albums.length === 0) {
      ({ albums } = await this.deezerApi.search({ queryProps, advancedSearch: false, type: 'album' }));
    }
    return albums;
  }

  async addAlbumToLibrary(album: GenericCollection): Promise<void> {
    await this.deezerApi.addAlbumToLibrary(album.rawId);
  }

  async updateCollection(
    collection: GenericCollection,
    props: { name?: string; access?: CollectionAccess; description?: string }
  ) {
    const { name, description } = props;
    await this.deezerApi.updatePlaylist(collection.rawId, { title: name, description });
  }

  async removeCollection(collection: GenericCollection): Promise<void> {
    await this.deezerApi.removePlaylist(collection.rawId);
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async reAuthenticate(): Promise<GenericAuthenticationData> {
    throw new Error(`Deezer does not support reauthentication`);
  }

  public doesSupportReAuth(): boolean {
    return false;
  }

  public doesSupportAlbums(): boolean {
    return true;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return false;
  }

  public doesSupportSearchByISRC(): boolean {
    return false;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return false;
  }

  public doesSupportMovingManyItems(): boolean {
    return true;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    switch (forCollection.type) {
      case CollectionType.MY_SONGS:
        return this.deezerApi.loadPaginatedMySongs(onBatch);
      case CollectionType.PLAYLIST:
      case CollectionType.LIKED_PLAYLIST:
        return this.deezerApi.loadPaginatedPlaylistItems(forCollection.rawId, onBatch);
      case CollectionType.ALBUM:
        await onBatch((await this.deezerApi.loadAlbum(forCollection.rawId)).tracks);
        return undefined;
      default:
        return undefined;
    }
  }
};
