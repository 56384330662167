import React, { useCallback, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useStore } from 'react-redux';
import { SerializedSmartLink, SerializedSmartLinkVersionTheme } from 'fym-common/src/db/serializersTypes';
import { TypedMemo } from '../../common/types';
import { FieldsName, FieldsType } from './consts';
import { FormInput } from '../components/FormInput/FormInput';
import { Spacer } from '../../common/views/spacer/Spacer';
import { createResolver } from './validators';
import { FileLoader } from '../components/FileLoader/FileLoader';
import { RootStateType } from '../../../redux/store';
import { SMLFormContainer } from '../../smartLinks/SMLFormContainer/SMLFormContainer';
import { InputType } from '../types';
import { ErrorAPIResult } from '../../../redux/api/utils/isSuccessfulResult';
import { removeHttpAndSlash } from '../../smartLinks/utils/removeHttpRegex';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { FormThemeSelection } from '../components/FormThemeSelection/FormThemeSelection';
import { boldIntlText } from '../../common/views/bold/boldIntlText';

export interface SmartLinkFormProps {
  accountId: number;
  title: string;
  smartLink?: SerializedSmartLink | null;
  onSubmit: SubmitHandler<FieldsType>;
  onDelete?: () => void;
  isLoading?: boolean;
  error?: ErrorAPIResult['error'];
  accountShareUrl: string | null;
  isDuplicationForm?: boolean;
}

const SmartLinkFormComponent: React.FC<SmartLinkFormProps> = ({
  accountId,
  smartLink,
  onSubmit,
  onDelete,
  title,
  isLoading,
  error,
  accountShareUrl,
  isDuplicationForm = false,
}) => {
  const intl = useIntl();
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const store = useStore<RootStateType>();
  const resolver = useMemo(
    () => createResolver(store, accountId, smartLink?.id, smartLink?.isMainSmartLink),
    [store, accountId, smartLink?.id, smartLink?.isMainSmartLink]
  );
  const { control, handleSubmit, resetField, watch } = useForm<FieldsType>({
    resolver,
    defaultValues: {
      [FieldsName.name]: smartLink?.recentSmartLinkVersion?.name ?? '',
      [FieldsName.slug]: smartLink?.recentSmartLinkVersion?.slug ?? '',
      [FieldsName.theme]: smartLink?.recentSmartLinkVersion?.theme ?? SerializedSmartLinkVersionTheme.dark,
      [FieldsName.image]: smartLink?.recentSmartLinkVersion?.backgroundFile ?? undefined,
    },
  });

  const slugWatch = watch(FieldsName.slug);

  const submit = useCallback(
    (event?: React.BaseSyntheticEvent) => {
      handleSubmit(onSubmit)(event).catch(console.error);
    },
    [handleSubmit, onSubmit]
  );

  const removeImage = useCallback(() => {
    resetField(FieldsName.image, { defaultValue: undefined });
  }, [resetField]);

  return (
    <SMLFormContainer
      backRoute={SMLRoutes.home}
      submitButtonTitle={
        smartLink === undefined
          ? intl.formatMessage({
              id: 'app.forms.buttons.create',
              defaultMessage: 'Create',
            })
          : undefined
      }
      title={title}
      onSubmit={submit}
      onDelete={smartLink ? onDelete : undefined}
      error={error}
      isLoading={isLoading}
      submitDisabled={isFileLoading}
    >
      <Spacer flex flexDirection="column">
        {(!smartLink || !smartLink.isMainSmartLink || isDuplicationForm) && (
          <>
            <FormInput
              name={FieldsName.name}
              control={control}
              type={InputType.text}
              disabled={isLoading}
              label={intl.formatMessage({
                id: 'app.forms.smartLink.name',
                defaultMessage: 'Name',
              })}
              help={intl.formatMessage({
                id: 'app.forms.smartLink.help.name',
                defaultMessage: 'The title of the Smart Link that will be used as title on the web',
              })}
            />
            <FormInput
              name={FieldsName.slug}
              control={control}
              type={InputType.text}
              disabled={isLoading}
              label={intl.formatMessage({
                id: 'app.forms.smartLink.slug',
                defaultMessage: 'Slug',
              })}
              help={intl.formatMessage(
                {
                  id: 'app.forms.smartLink.help.slug',
                  defaultMessage:
                    'The short name that will be used as part of the url <bold>{accountSlug}/{slug}</bold>',
                },
                {
                  accountSlug: removeHttpAndSlash(accountShareUrl ?? 'slug.fym.fm'),
                  slug: slugWatch !== '' ? slugWatch : '<slug>',
                  bold: boldIntlText,
                }
              )}
              inputTextPrefix={accountShareUrl ?? 'https://slug.fym.fm/'}
            />
          </>
        )}
        <FormThemeSelection
          control={control}
          disabled={isLoading}
          label={intl.formatMessage({
            id: 'app.forms.smartLink.theme',
            defaultMessage: 'Theme',
          })}
        />
        <FileLoader
          name={FieldsName.image}
          accountId={accountId}
          control={control}
          removeImage={removeImage}
          disabled={isLoading}
          onLoadingStateChange={setIsFileLoading}
          label={intl.formatMessage({
            id: 'app.forms.smartLink.background',
            defaultMessage: 'Background',
          })}
        />
      </Spacer>
    </SMLFormContainer>
  );
};

export const SmartLinkForm = TypedMemo(SmartLinkFormComponent);
