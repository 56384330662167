import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/pro-solid-svg-icons/faAdd';
import { Row } from 'reactstrap';
import { TypedMemo } from '../../../common/types';
import { SMLRoutes } from '../../../../routing/sml/SMLRoutes';
import { useSMLRouter } from '../../../../routing/sml/hooks';
import { SMLContainer } from '../../smlContainer/SMLContainer';
import { SMLBreadcrumbs } from '../../smlBreadcrumbs/SMLBreadcrumbs';
import { SmartLinkInfoBox } from '../../smartLinkInfoBox/SmartLinkInfoBox';
import { TrackersListViewRendererProps } from './props';
import { ClickableDiv } from '../../../common/views/ClickableDiv';
import { TrackersListItem } from '../listItem/TrackersListItem';

const TrackersListViewRendererComponent: React.FC<TrackersListViewRendererProps> = ({
  trackers,
  openTrackerForm,
  accountId,
}) => {
  const intl = useIntl();
  const router = useSMLRouter();
  const breadcrumbs = useMemo(() => {
    return [
      {
        name: intl.formatMessage({ id: 'app.sml.breadcrumbs.home', defaultMessage: 'Home' }),
        onClick: () => router.push(SMLRoutes.home),
      },
      {
        name: intl.formatMessage({ id: 'app.sml.breadcrumbs.trackers', defaultMessage: 'Trackers' }),
      },
    ];
  }, [intl, router]);

  return (
    <SMLContainer header={<SMLBreadcrumbs breadcrumbs={breadcrumbs} />}>
      {trackers.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center h-100 w-100">
          <SmartLinkInfoBox
            text={intl.formatMessage({
              id: 'app.sml.trackers.info_box',
              defaultMessage: 'Here you can add trackers like Facebook Pixel or Google Analytics to your whole account',
            })}
          />
        </div>
      ) : (
        <Row className="justify-content-center">
          {trackers.map((tracker) => {
            return <TrackersListItem key={tracker.id} tracker={tracker} accountId={accountId} />;
          })}
        </Row>
      )}
      <div className="sml-creation-button-container">
        <ClickableDiv className="sml-creation-button rounded-circle" onClick={openTrackerForm}>
          <FontAwesomeIcon icon={faAdd} size="lg" />
        </ClickableDiv>
      </div>
    </SMLContainer>
  );
};

export const TrackersListViewRenderer = TypedMemo(TrackersListViewRendererComponent);
