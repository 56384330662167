import React from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { TypedMemo } from '../../../common/types';
import { ReleaseLinkItem } from './ReleaseLinkItem';
import { FormHelp } from '../../../forms/components/FormHelp/FormHelp';
import { ReleaseLinkWizardViewRendererProps } from './props';
import { SMLFormContainer } from '../../SMLFormContainer/SMLFormContainer';
import { SMLRoutes } from '../../../../routing/sml/SMLRoutes';
import { useExtractInfoFromReleaseData } from './useExtractInfoFromReleaseData';
import { ReactComponent as CoverBoardPlaceholder } from '../../../../assets/images/cover-board-placeholder.svg';
import { ErrorAlert } from '../../../common/views/ErrorAlert/ErrorAlert';
import { useReleaseLinkForm } from '../forms/ReleaseLinkForm/useReleaseLinkForm';

const ReleaseLinkWizardViewRendererComponent: React.FC<ReleaseLinkWizardViewRendererProps> = ({
  mainItem,
  error,
  isLoading,
  releaseLinks,
  onContinue,
  onClose,
  onDelete,
  onCreate,
}) => {
  const intl = useIntl();
  const { error: createNewReleaseLinkError, MediaUrlInput } = useReleaseLinkForm({
    onSuccess: onCreate,
    useMinimalistic: true,
  });

  const {
    title: mainItemTitle,
    text1: mainItemText1,
    text2: mainItemText2,
    importerColor: mainItemImporterColor,
    ImporterLogo: MainItemImporterLogo,
    imageUrl: mainItemImageUrl,
  } = useExtractInfoFromReleaseData(mainItem);
  return (
    <SMLFormContainer
      closeModal={onClose}
      backRoute={SMLRoutes.releaseLink}
      title={intl.formatMessage({
        id: 'app.smart_links.release_smart_link.form_title',
        defaultMessage: 'Create Release Link',
      })}
      error={error}
      isLoading={isLoading}
      cancelButtonTitle={intl.formatMessage({
        id: 'app.forms.single_track.buttons.back',
        defaultMessage: 'Back',
      })}
      submitButtonColor="secondary"
      submitButtonTitle={intl.formatMessage({
        id: 'app.forms.single_track.buttons.preview',
        defaultMessage: 'Preview',
      })}
      centerFooterContent={
        <FormHelp>
          {intl.formatMessage(
            {
              id: 'app.forms.single_track.steps.confirm',
              defaultMessage: '{step} Confirm',
            },
            {
              step: '2/2',
            }
          )}
        </FormHelp>
      }
      onSubmit={onContinue}
    >
      <div className="d-flex">
        <div className="me-3" style={{ maxWidth: 160 }}>
          {MainItemImporterLogo && <MainItemImporterLogo className="mx-auto d-block" width={160} />}
          <div
            className="rounded flex-grow-1"
            style={{
              borderStyle: 'solid',
              borderWidth: 1,
              borderColor: mainItemImporterColor ? mainItemImporterColor.label : '',
              position: 'relative',
              padding: 10,
            }}
          >
            <div className="pb-2">
              {mainItemImageUrl && (
                <img className="img-fluid rounded" src={mainItemImageUrl} alt={mainItemTitle ?? ''} />
              )}
              {!mainItemImageUrl && <CoverBoardPlaceholder className="img-fluid rounded" />}
            </div>

            <strong>{mainItemTitle}</strong>
            <br />
            <i>{mainItemText1}</i>
            <br />
            <i>{mainItemText2}</i>
            <br />
          </div>
        </div>
        <div className="flex-grow-1">
          <div className="mb-3 mt-4" style={{ marginTop: 46 }}>
            {createNewReleaseLinkError && <ErrorAlert error={createNewReleaseLinkError} />}
            {MediaUrlInput}
          </div>
          <div className={classnames({ 'disable-mouse-events': isLoading })}>
            {releaseLinks.map((releaseLinkData) => (
              <ReleaseLinkItem key={releaseLinkData.id} releaseLinkData={releaseLinkData} onDelete={onDelete} />
            ))}
          </div>
        </div>
      </div>
    </SMLFormContainer>
  );
};

export const ReleaseLinkWizardViewRenderer = TypedMemo(ReleaseLinkWizardViewRendererComponent);
