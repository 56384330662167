import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SmartLinkWidgetType } from 'fym-common/src/db/enums';
import { SerializedSocialLinksWidget } from 'fym-common/src/db/serializersTypes';
import { TypedMemo } from '../../../../../common/types';
import { WidgetEditionProps } from '../../props';
import { SMLFormContainer } from '../../../../SMLFormContainer/SMLFormContainer';
import { useSMLRouter } from '../../../../../../routing/sml/hooks';
import { SMLRoutes } from '../../../../../../routing/sml/SMLRoutes';
import { SMLButton } from '../../../../../common/views/SMLButton/SMLButton';

const SocialWidgetEditionViewComponent: React.FC<WidgetEditionProps<SerializedSocialLinksWidget>> = ({
  widget,
  saveWidget,
  deleteWidget,
  isLoading,
  error,
  accountId,
  smartLinkId,
}) => {
  const intl = useIntl();
  const onSave = useCallback(() => {
    saveWidget({}, SmartLinkWidgetType.socialLinks).catch(console.error);
  }, [saveWidget]);
  const isEdition = !!widget;
  const router = useSMLRouter();

  const goToAccountSettings = useCallback(() => {
    router.popToRoot();
    router.push(SMLRoutes.account, { accountId });
  }, [accountId, router]);

  const backRouteParams = useMemo(() => ({ accountId, smartLinkId }), [accountId, smartLinkId]);
  return (
    <SMLFormContainer
      backRoute={SMLRoutes.details}
      backRouteParams={backRouteParams}
      title={intl.formatMessage({
        id: 'app.smart_links.widgets.social_links.title',
        defaultMessage: 'Social links widget',
      })}
      help={intl.formatMessage({
        id: 'app.smart-links.widgets.social.subtitle',
        defaultMessage: "This widget displays your account's social links",
      })}
      onSubmit={isEdition ? undefined : onSave}
      onDelete={!isEdition ? undefined : deleteWidget}
      isLoading={isLoading}
      error={error}
    >
      {isEdition && (
        <SMLButton onClick={goToAccountSettings}>
          {intl.formatMessage({
            id: 'app.smart-links.widgets.social.go_to_settings',
            defaultMessage: 'Go to Edit Profile',
          })}
        </SMLButton>
      )}
    </SMLFormContainer>
  );
};

export const SocialWidgetEditionView = TypedMemo(SocialWidgetEditionViewComponent);
