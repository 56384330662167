import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { imageSchema } from 'fym-common/src/joi/schemas';
import { FieldsName } from './consts';

const schema = Joi.object({
  [FieldsName.image]: imageSchema.label('Image').keys().min(3).required(),
});

export const resolver = joiResolver(schema);
