import { useCallback, useState } from 'react';

type LoadingButton = 'submit' | 'delete';

type UseLoadingFormButtonsOptions = {
  isLoading?: boolean;
  onSubmit?: () => void;
  onDelete?: () => void;
};

export function useLoadingFormButtons({ isLoading = false, onSubmit, onDelete }: UseLoadingFormButtonsOptions) {
  const [loadingButton, setLoadingButton] = useState<LoadingButton | null>(null);

  const handleSubmit = useCallback(() => {
    if (!onSubmit) {
      return;
    }
    setLoadingButton('submit');
    onSubmit();
  }, [onSubmit]);

  const handleDelete = useCallback(() => {
    if (!onDelete) {
      return;
    }
    setLoadingButton('delete');
    onDelete();
  }, [onDelete]);

  return {
    loadingButton: isLoading ? loadingButton : null,
    handleSubmit: onSubmit ? handleSubmit : null,
    handleDelete: onDelete ? handleDelete : null,
  };
}
