import { PaddlePricesResponse } from '../models/PaddlePricesResponse';
import { config } from '../../../../config/config';

export async function fetchPrices(productIds: number[], coupons: string[] = []): Promise<PaddlePricesResponse> {
  const couponsString = coupons.join(',');
  const productIdsString = productIds.join(',');
  const url = `${config.fymApiURL(true)}paddle/prices?coupons=${couponsString}&product_ids=${productIdsString}`;
  const response = await window.fetch(url);
  return new PaddlePricesResponse(await response.json());
}
