import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle';
import { TypedMemo } from '../../../common/types';
import { FileLoaderRendererProps } from './props';
import { LoadingView } from '../../../common/views/LoadingView';
import { ClickableDiv } from '../../../common/views/ClickableDiv';

/* eslint-disable react/jsx-props-no-spreading */

const FileLoaderRendererComponent: React.FC<FileLoaderRendererProps> = ({
  onFileSelected,
  previewUrl,
  isLoading,
  removeImage,
  invalid,
  disabled = false,
  readonly = false,
  roundedCircle = false,
}) => {
  const intl = useIntl();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (file === undefined) {
        return;
      }
      const formData = new FormData();
      formData.append('file', file, file.name);
      onFileSelected(formData);
    },
    [onFileSelected]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ['.png', '.jpeg', '.jpg'],
    disabled,
  });

  return (
    <div className="fym-form-dropzone-container">
      {!readonly && (
        <div
          className={classnames('form-control fym-form-dropzone fym-form-input-blue-container', {
            'is-invalid': invalid,
            'disable-mouse-events': disabled,
          })}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <small className="form-text text-center">
            {isDragActive
              ? intl.formatMessage({
                  id: 'app.dropzone.dropping',
                  defaultMessage: `Drop the files here...`,
                })
              : intl.formatMessage({
                  id: 'app.dropzone.drop',
                  defaultMessage: `Drag and drop an image or click to select it`,
                })}
          </small>
        </div>
      )}
      <div className="fym-form-dropzone-preview">
        {isLoading && <LoadingView />}
        {!isLoading && previewUrl && (
          <div
            className={classnames('fym-form-hovered-img', {
              disabled: disabled || readonly,
            })}
          >
            <div
              className={classnames('fym-form-hovered-img-img', {
                'rounded-circle': roundedCircle,
                'rounded-3': !roundedCircle,
              })}
              style={{ backgroundImage: `url(${previewUrl})` }}
            />
            {!readonly && (
              <ClickableDiv
                className="fym-form-deletion-icon-container rounded-circle"
                onClick={removeImage}
                disabled={disabled}
              >
                <FontAwesomeIcon icon={faTrash} />
              </ClickableDiv>
            )}
          </div>
        )}
        {!isLoading && !previewUrl && readonly && (
          <FontAwesomeIcon icon={faUserCircle} size="10x" className="fym-form-readonly-placeholder-icon" />
        )}
      </div>
    </div>
  );
};

export const FileLoaderRenderer = TypedMemo(FileLoaderRendererComponent);
