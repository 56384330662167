import React, { useCallback } from 'react';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { TypedMemo } from '../../common/types';
import { SMLFormContainerProps } from './props';
import { Spacer } from '../../common/views/spacer/Spacer';
import { ErrorAlert } from '../../common/views/ErrorAlert/ErrorAlert';
import { useLoadingFormButtons } from './hooks';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLButton } from '../../common/views/SMLButton/SMLButton';

const SMLFormContainerComponent: React.FC<React.PropsWithChildren<SMLFormContainerProps>> = ({
  children,
  title,
  help,
  error,
  isLoading = false,
  onSubmit,
  onDelete,
  submitButtonTitle,
  cancelButtonTitle,
  submitButtonIcon,
  submitButtonColor = 'primary',
  submitDisabled,
  backRoute,
  backRouteParams,
  closeModal,
  centerFooterContent,
}) => {
  const intl = useIntl();
  const router = useSMLRouter();
  const { loadingButton, handleSubmit, handleDelete } = useLoadingFormButtons({ isLoading, onSubmit, onDelete });
  const formHandleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      handleSubmit?.();
    },
    [handleSubmit]
  );
  const goBack = useCallback(() => {
    if (closeModal) {
      closeModal();
      return;
    }
    router.goBack(backRoute, backRouteParams);
  }, [backRoute, backRouteParams, closeModal, router]);

  return (
    <form onSubmit={formHandleSubmit ?? undefined} className="d-contents">
      <ModalHeader
        close={
          !closeModal && (
            <Button onClick={goBack} color="link" disabled={isLoading}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </Button>
          )
        }
      >
        {title}
      </ModalHeader>
      <ModalBody>
        {error && <ErrorAlert error={error} />}
        <div className={classnames({ 'disable-mouse-events': isLoading })}>
          {help !== undefined && <div className="fym-form-help text-muted">{help}</div>}
          {children}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex">
          {handleDelete && (
            <SMLButton
              color="secondary"
              onClick={handleDelete}
              doNotPassEvent
              disabled={isLoading}
              isLoading={loadingButton === 'delete'}
            >
              {intl.formatMessage({
                id: 'app.forms.buttons.delete',
                defaultMessage: 'Delete',
              })}
            </SMLButton>
          )}

          <SMLButton color="dark" onClick={goBack} disabled={isLoading} outline>
            {cancelButtonTitle ??
              intl.formatMessage({
                id: 'app.forms.buttons.cancel',
                defaultMessage: 'Cancel',
              })}
          </SMLButton>
        </div>
        {centerFooterContent !== undefined && <div className="d-flex">{centerFooterContent}</div>}
        <div className="d-flex">
          {handleSubmit && (
            <Spacer marginLeft={20}>
              <SMLButton
                color={submitButtonColor}
                disabled={isLoading || submitDisabled}
                isLoading={loadingButton === 'submit'}
                leftIcon={submitButtonIcon}
                submit
              >
                {submitButtonTitle ??
                  intl.formatMessage({
                    id: 'app.forms.buttons.save',
                    defaultMessage: 'Save',
                  })}
              </SMLButton>
            </Spacer>
          )}
        </div>
      </ModalFooter>
    </form>
  );
};

export const SMLFormContainer = TypedMemo(SMLFormContainerComponent);
