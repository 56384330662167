import { useLocation, useNavigate } from 'react-router-dom';
import { AppTarget } from 'fym-common/src/config/types';
import { useCallback, useMemo } from 'react';
import { SMLRouterType } from './SMLRouterType';
import { extractLocationFromPath } from './extractLocationFromPath';
import { config } from '../../config/config';
import { Analytics } from '../../modules/analytics/fym/analytics';
import { SMLRootParamList, SMLRouteNames, SMLRouteParts, SMLRoutes } from './SMLRoutes';
import { buildQuery } from '../utils/buildQuery';

if (config.target === AppTarget.freeyourmusic) {
  throw new Error('Cannot use "useSMLRouter" in FYM app');
}

type SMLNavigateMethod = 'push' | 'replace';

export function useSMLRouter(): SMLRouterType {
  const navigate = useNavigate();
  const { pathname, key } = useLocation();

  // https://github.com/remix-run/history/blob/main/docs/api-reference.md#locationkey
  const canGoBack = useMemo(() => key !== 'default', [key]);

  const popToRoot = useCallback(() => {
    navigate(`${SMLRoutes.home}`);
  }, [navigate]);

  const _navigate = useCallback(
    (props: { routeParts: SMLRouteParts; params?: SMLRootParamList[SMLRouteNames]; method: SMLNavigateMethod }) => {
      const { routeParts, params, method } = props;
      const query = buildQuery(params);
      const path = `/${routeParts.join('/')}${query}`;
      navigate(path, { replace: method === 'replace' });
      Analytics.screen(path).catch(console.error);
    },
    [navigate]
  );

  const push = useCallback(
    (routeParts: SMLRouteParts, params?: SMLRootParamList[SMLRouteNames]) => {
      _navigate({ routeParts, params, method: 'push' });
    },
    [_navigate]
  );

  const replace = useCallback(
    (routeParts: SMLRouteParts, params?: SMLRootParamList[SMLRouteNames]) => {
      _navigate({ routeParts, params, method: 'replace' });
    },
    [_navigate]
  );

  const setParams = useCallback(
    (params: SMLRootParamList[SMLRouteNames]) => {
      if (!params) {
        return;
      }
      const query = buildQuery(params);
      const path = `${pathname}${query}`;
      navigate(path, { replace: true });
    },
    [pathname, navigate]
  );

  const goBack = useCallback(
    (expectedRoute: SMLRouteParts | undefined, params?: SMLRootParamList[SMLRouteNames]) => {
      /*
       * WEB: if you click in the email link someone sent you, which opens smart link edition, then after
       * saving the smart link you will land back in email instead of smart links details. To avoid that
       * we push the expected route instead
       */
      if (canGoBack) {
        navigate(-1);
      } else {
        push(expectedRoute ?? SMLRoutes.home, params);
      }
    },
    [canGoBack, navigate, push]
  );

  return useMemo(
    () => ({
      push,
      replace,
      setParams,
      popToRoot,
      goBack,
      canGoBack,
    }),
    [push, replace, setParams, popToRoot, goBack, canGoBack]
  );
}

export function useSMLRoute(): SMLRouteParts | undefined {
  const { pathname } = useLocation();
  return useMemo(() => extractLocationFromPath(pathname), [pathname]);
}
