import React from 'react';
import { TypedMemo } from '../../common/types';
import { LoadingView } from '../../common/views/LoadingView';
import { SMLHomeView } from './SMLHomeView';
import { useAccountId } from '../../../redux/slices/appInfo/hooks';

const SmartLinksMainViewComponent: React.FC = () => {
  const accountId = useAccountId();

  if (accountId === null) {
    return <LoadingView />;
  }

  return <SMLHomeView accountId={accountId} />;
};

export const SmartLinksMainView = TypedMemo(SmartLinksMainViewComponent);
