export class QQMusicUser {
  private readonly data;

  constructor(data: any) {
    this.data = data;
  }

  get name(): string {
    return this.data.data.creator.nick;
  }

  get avatarUrl(): string {
    return this.data.data.creator.headpic;
  }
}
