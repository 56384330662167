import { LicenseModelProductType, LicenseRequestType } from 'fym-common/src/license/consts';
import { useCallback } from 'react';
import { useChangeLicenseMutation } from '../../../redux/api/fymAPI';
import { useLicenseInfo } from '../../../redux/slices/license/hooks';
import { useValidateLicense } from '../../licenses/hooks/useValidateLicense';

export function useLicenseChange(onSuccess: () => void) {
  const licenseInfo = useLicenseInfo();
  const [changeLicenseCall, { isLoading, isSuccess, isError }] = useChangeLicenseMutation();
  const { validateLicense } = useValidateLicense();

  const changeLicense = useCallback(
    ({ productType }: { productType: LicenseModelProductType }) => {
      if (!licenseInfo?.validLicense) {
        return;
      }
      const { validLicense } = licenseInfo;
      if (validLicense === undefined) {
        return;
      }
      changeLicenseCall({ licenseKey: validLicense.licenseKey, productType })
        .then(() => {
          return validateLicense({
            licenseKey: validLicense.licenseKey,
            email: validLicense.email ?? '',
            type: LicenseRequestType.WEB,
          });
        })
        .then(() => {
          onSuccess();
        })
        .catch(console.error);
    },
    [licenseInfo, changeLicenseCall, validateLicense, onSuccess]
  );

  return { isLoading, isError, isSuccess, changeLicense };
}
