import React, { CSSProperties, useCallback } from 'react';
import classnames from 'classnames';
import { TypedMemo } from '../types';

interface ClickableDivProps {
  onClick: (() => void) | undefined;
  disabled?: boolean;
  style?: CSSProperties;
  className?: string;
  id?: string;
  stopPropagation?: boolean;
  testID?: string;
}

const ClickableDivComponent: React.FC<React.PropsWithChildren<ClickableDivProps>> = ({
  id,
  children,
  disabled = false,
  style = {},
  className,
  stopPropagation = false,
  onClick,
  testID,
}) => {
  const isClickable = !!onClick && !disabled;
  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>) => {
      if (!onClick || disabled) {
        return;
      }
      onClick();
      if (stopPropagation) {
        event.stopPropagation();
      }
    },
    [disabled, onClick, stopPropagation]
  );

  return (
    <div
      onClick={handleOnClick}
      onKeyDown={handleOnClick}
      role="button"
      aria-hidden
      style={style}
      className={classnames(className, testID, { 'cursor-pointer': isClickable, 'cursor-auto': !isClickable })}
      id={id}
    >
      {children}
    </div>
  );
};

export const ClickableDiv = TypedMemo(ClickableDivComponent);
