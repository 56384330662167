import { CollectionItemModelData, GenericCollectionItem } from '../../../generics/models/GenericCollectionItem';
import { getArtistsNamesAsString, getBiggestImageUrlFromImages, getArtistsIds, convertPlayedAt } from '../utils';
import { Album, Artist, Track } from './types';

type AmazonMusicTrackAdditionalInfo = {
  cursor?: string | null;
  entryId?: string | null;
  playedAt?: string | number;
};

export class AmazonMusicCollectionTrack extends GenericCollectionItem {
  constructor(
    data: CollectionItemModelData,
    public albumExtended: Pick<Album, 'id' | 'title' | 'url'> | null = null,
    public artists: Pick<Artist, 'id' | 'name' | 'url'>[] | null = null
  ) {
    super(data);
  }

  public static convertCursorToEntryId(cursor: string | null | undefined) {
    return cursor?.split(':')[1];
  }

  public static fromData(track: Track | undefined, info?: AmazonMusicTrackAdditionalInfo) {
    /**
     * `cursor` === [track index on playlist][colon][entryId] (i.e.: `12:7dca32a4-2bca-439a-83c2-8073670540fc`)
     * Tracks from 'liked tracks' and 'search songs' doesn't return cursor
     * */
    const { id, shortTitle, title, album, artists, images, duration, previewUrl, isrc, url, globalAsin } = track ?? {};
    const { cursor, entryId, playedAt } = info ?? {};
    const name = shortTitle || title;
    const finalEntryId = entryId ?? AmazonMusicCollectionTrack.convertCursorToEntryId(cursor);
    const rawId = finalEntryId ?? id;
    if (!rawId || !name) {
      return undefined;
    }
    return new AmazonMusicCollectionTrack(
      {
        rawId,
        globalId: globalAsin,
        name,
        album: album?.title ?? '',
        artist: getArtistsNamesAsString(artists),
        imageUrl: getBiggestImageUrlFromImages(images),
        duration: duration !== undefined ? duration * 1000 : undefined,
        previewUrl,
        isrc,
        sourceURL: url,
        additionalData: {
          playedAt: convertPlayedAt(playedAt),
          albumId: album?.id,
          artistsIds: getArtistsIds(artists),
        },
      },
      album ? { id: album.id, title: album.title, url: album.url } : undefined,
      artists
        ? artists
            .filter((artist): artist is NonNullable<typeof artist> => !!artist)
            .map((artist) => ({ id: artist.id, name: artist.name, url: artist.url }))
        : undefined
    );
  }
}
