import { GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { QQMusicLikedPlaylistData, QQMusicPlaylistData } from '../types';
import { tryParseInt } from '../../../utils/tryParseInt';

export class QQMusicPlaylist extends GenericCollection {
  static fromPlaylistData(data: QQMusicPlaylistData, hostuin: string): QQMusicPlaylist | null {
    if (!data?.diss_name || (!hostuin && data.dirid === undefined)) {
      return null;
    }
    return new QQMusicPlaylist({
      type: CollectionType.PLAYLIST,
      rawId: `${hostuin}-${data.dirid}`,
      name: data.diss_name,
      itemCount: tryParseInt(data.song_cnt),
      additionalData: { imageUrl: data.diss_cover },
    });
  }

  static fromLikedPlaylistData(data: QQMusicLikedPlaylistData): QQMusicPlaylist | null {
    if (!data?.dissname || (!data.uin && data.dirid === undefined)) {
      return null;
    }
    return new QQMusicPlaylist({
      type: CollectionType.LIKED_PLAYLIST,
      rawId: `${data.uin}-${data.dirid}`,
      name: data.dissname,
      itemCount: tryParseInt(data.songnum),
      additionalData: { imageUrl: data.logo },
    });
  }
}
