import React, { useCallback, useState } from 'react';
import URL from 'url-parse';
import qs from 'qs';
import { v4 as uuid4 } from 'uuid';
import { TypedMemo } from '../../common/types';
import { SMLPreviewProps } from './props';
import { openExternal } from '../../../utils/openExternal';
import { SMLPreviewRenderer } from './SMLPreviewRenderer';

const SMLPreviewComponent: React.FC<SMLPreviewProps> = ({
  type = 'column',
  smartLink,
  onPreviewMessageEvent,
  showPublished,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const togglePreviewSidebar = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  let { previewUrl, editUrl } = smartLink.recentSmartLinkVersion;
  if (showPublished === true && smartLink.publishedSmartLinkVersion) {
    previewUrl = smartLink.shareUrl;
    editUrl = smartLink.shareUrl;
  }
  const openPreview = useCallback(() => {
    if (!previewUrl) {
      return;
    }
    const url = new URL(previewUrl, (query) => qs.parse(query, { ignoreQueryPrefix: true }));
    url.set('query', { ...url.query, rand: uuid4() });
    openExternal(url.toString()).catch(console.error);
  }, [previewUrl]);

  return (
    <SMLPreviewRenderer
      editableSmartLink={showPublished === true ? undefined : smartLink}
      iframeUrl={editUrl}
      type={type}
      openPreview={showPublished === true ? undefined : openPreview}
      togglePreviewSidebar={togglePreviewSidebar}
      isPreviewSidebarOpen={isOpen}
      onPreviewMessageEvent={onPreviewMessageEvent}
    />
  );
};

export const SMLPreview = TypedMemo(SMLPreviewComponent);
