import React, { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { SerializedPlayerWidget } from 'fym-common/src/db/serializersTypes';
import { TypedMemo } from '../../../../../common/types';
import { ErrorAPIResult } from '../../../../../../redux/api/utils/isSuccessfulResult';
import { FieldsName, FieldsType } from './consts';
import { resolver } from './validators';
import { SMLFormContainer } from '../../../../SMLFormContainer/SMLFormContainer';
import { Spacer } from '../../../../../common/views/spacer/Spacer';
import { FormInput } from '../../../../../forms/components/FormInput/FormInput';
import { InputType } from '../../../../../forms/types';
import { FormHelp } from '../../../../../forms/components/FormHelp/FormHelp';
import { SMLRoutes } from '../../../../../../routing/sml/SMLRoutes';
import { LogoServices } from './LogoServices';

export interface PlayerWidgetFormProps {
  widget?: SerializedPlayerWidget | null;
  onSubmit: SubmitHandler<FieldsType>;
  onDelete: () => void;
  title: string;
  isLoading?: boolean;
  error?: ErrorAPIResult['error'];
  accountId: number;
  smartLinkId: number;
}

const PlayerWidgetFormComponent: React.FC<PlayerWidgetFormProps> = ({
  widget,
  onSubmit,
  onDelete,
  title,
  isLoading,
  error,
  accountId,
  smartLinkId,
}) => {
  const intl = useIntl();
  const { control, handleSubmit, reset } = useForm<FieldsType>({
    resolver,
    defaultValues: {
      [FieldsName.url]: widget?.data.url ?? '',
    },
  });

  const submit = useCallback(
    (event?: React.BaseSyntheticEvent) => {
      handleSubmit(onSubmit)(event).catch(console.error);
    },
    [handleSubmit, onSubmit]
  );

  useEffect(() => {
    reset({
      [FieldsName.url]: widget?.data.url ?? '',
    });
  }, [reset, widget?.data.url]);

  const backRouteParams = useMemo(() => ({ accountId, smartLinkId }), [accountId, smartLinkId]);

  return (
    <SMLFormContainer
      backRoute={SMLRoutes.details}
      backRouteParams={backRouteParams}
      title={title}
      onSubmit={submit}
      onDelete={widget ? onDelete : undefined}
      isLoading={isLoading}
      error={error}
    >
      <Spacer flex flexDirection="column">
        <FormInput
          name={FieldsName.url}
          control={control}
          type={InputType.text}
          disabled={isLoading}
          label={intl.formatMessage({
            id: 'app.forms.player_widget.url',
            defaultMessage: 'URL',
          })}
        />
      </Spacer>
      <Spacer marginRight={10} />
      <FormHelp>
        {intl.formatMessage({
          id: 'app.forms.player_widget.supported_services',
          defaultMessage:
            'Player Widget will display music player and allow your users to hear the sample. We currently support following services:',
        })}
      </FormHelp>
      <Spacer>
        <LogoServices />
      </Spacer>
      <Spacer />
    </SMLFormContainer>
  );
};

export const PlayerWidgetForm = TypedMemo(PlayerWidgetFormComponent);
