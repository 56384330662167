import { BaseModel } from '../../../universal/BaseModel';

export class SpotifyUser extends BaseModel {
  get id(): string {
    return this.privateData.id;
  }

  get displayName(): string {
    return this.privateData.display_name;
  }

  get email(): string {
    return this.privateData.email;
  }

  get country(): string {
    return this.privateData.country;
  }

  get imageUrl(): string | null {
    const { images } = this.privateData;
    if (!images || !images.length) {
      return null;
    }
    return images[0].url;
  }
}
