import { ReactComponent as DeezerLight } from '../../../../assets/images/music-services/deezer-light.svg';
import { ReactComponent as DeezerDark } from '../../../../assets/images/music-services/deezer-dark.svg';
import { ReactComponent as DeezerLightPadding } from '../../../../assets/images/music-services/deezer-light-padding.svg';
import { ReactComponent as DeezerDarkPadding } from '../../../../assets/images/music-services/deezer-dark-padding.svg';
import { ReactComponent as DeezerColorLight } from '../../../../assets/images/music-services/deezer-color-light.svg';
import { ReactComponent as DeezerColorDark } from '../../../../assets/images/music-services/deezer-color-dark.svg';
import { ReactComponent as DeezerColorLightPadding } from '../../../../assets/images/music-services/deezer-color-light-padding.svg';
import { ReactComponent as DeezerColorDarkPadding } from '../../../../assets/images/music-services/deezer-color-dark-padding.svg';
import { ReactComponent as DeezerDarkDanger } from '../../../../assets/images/music-services-danger/deezer-dark-danger.svg';
import { ReactComponent as DeezerLightDanger } from '../../../../assets/images/music-services-danger/deezer-light-danger.svg';
import { ReactComponent as DeezerLightDangerPadding } from '../../../../assets/images/music-services-danger/deezer-light-padding-danger.svg';
import { ReactComponent as DeezerDarkDangerPadding } from '../../../../assets/images/music-services-danger/deezer-dark-padding-danger.svg';
import { ReactComponent as DeezerDarkWarning } from '../../../../assets/images/music-services-warning/deezer-dark-warning.svg';
import { ReactComponent as DeezerLightWarning } from '../../../../assets/images/music-services-warning/deezer-light-warning.svg';
import { ReactComponent as DeezerLightWarningPadding } from '../../../../assets/images/music-services-warning/deezer-light-padding-warning.svg';
import { ReactComponent as DeezerDarkWarningPadding } from '../../../../assets/images/music-services-warning/deezer-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const deezerImages: ImporterImages = {
  logo: {
    light: DeezerLight,
    dark: DeezerDark,
  },
  logoPadding: {
    light: DeezerLightPadding,
    dark: DeezerDarkPadding,
  },
  logoColor: {
    light: DeezerColorLight,
    dark: DeezerColorDark,
  },
  logoColorPadding: {
    light: DeezerColorLightPadding,
    dark: DeezerColorDarkPadding,
  },
  logoOnBackground: DeezerColorDark,
  logoDanger: {
    dark: DeezerDarkDanger,
    light: DeezerLightDanger,
  },
  logoDangerPadding: {
    dark: DeezerDarkDangerPadding,
    light: DeezerLightDangerPadding,
  },
  logoWarning: {
    dark: DeezerDarkWarning,
    light: DeezerLightWarning,
  },
  logoWarningPadding: {
    light: DeezerLightWarningPadding,
    dark: DeezerDarkWarningPadding,
  },
};
