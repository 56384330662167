import { useCallback } from 'react';
import { SMLPreviewMessageData, SMLPreviewMessages } from './types';

export const useOnPreviewMessageDoAction = (messageId: SMLPreviewMessages, callback: (widgetUUID: string) => void) => {
  return useCallback(
    (event: SMLPreviewMessageData) => {
      if (event.type !== messageId) {
        return;
      }
      console.log('Handled Editor event', event);
      callback(event.widgetUUID);
    },
    [callback, messageId]
  );
};
