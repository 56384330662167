export enum FieldsName {
  url = 'url',
  label = 'label',
  smartLinkId = 'smartLinkId',
}

export type FieldsType = {
  [FieldsName.url]: string;
  [FieldsName.label]: string;
  [FieldsName.smartLinkId]?: number;
};
