export class PaddlePrice {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get gross() {
    return this.data.gross;
  }

  get net() {
    return this.data.net;
  }

  get tax() {
    return this.data.tax;
  }
}
