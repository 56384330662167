import { MusicService } from './types';
import { ImporterID } from '../../importers/types';
import { MusicAPIIntegrationID } from '../types';
import { LinkType } from '../../utils/recognizeLinkTypeByUrl';

export const jiosaavn: MusicService = {
  id: 'jiosaavn',
  importerID: ImporterID.JioSaavn,
  linkType: LinkType.jiosaavn,
  musicAPIID: MusicAPIIntegrationID.JioSaavn,
  shortName: 'JioSaavn',
  name: 'JioSaavn',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: true,
  color: { label: '#20B4A3', background: '#20B4A366' },
};
