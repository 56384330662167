import React, { useCallback, useMemo, useState } from 'react';
import { tryParseInt } from 'fym-common/src/utils/tryParseInt';
import { ValueOf } from 'fym-common/src/types/utilityTypes';
import { RawWidgetData, SmartLinkWidgetType } from 'fym-common/src/db/enums';
import {
  useCreateWidgetMutation,
  useDeleteWidgetMutation,
  useGetSmartLinkQuery,
  useUpdateWidgetMutation,
} from '../../../../../redux/api/fymAPI';
import { useQueryParam } from '../../../../../utils/useQueryParam';
import { getWidgetManager } from '../../widgetManagers';
import { isSuccessfulResult } from '../../../../../redux/api/utils/isSuccessfulResult';
import { DeletionModal } from '../../../../common/views/modals/deletionModal/DeletionModal';
import { useSMLRouter } from '../../../../../routing/sml/hooks';
import { SMLRootParamList, SMLRouteNames, SMLRoutes } from '../../../../../routing/sml/SMLRoutes';
import { TypedMemo } from '../../../../common/types';

interface SocialLinksEditionViewProps {
  route?: { params: SMLRootParamList[SMLRouteNames.widgetEdit] };
}

const WidgetEditionViewComponent: React.FC<SocialLinksEditionViewProps> = ({ route }) => {
  const router = useSMLRouter();
  const accountIdString = useQueryParam('accountId', route);
  const accountId = tryParseInt(accountIdString);
  const smartLinkIdString = useQueryParam('smartLinkId', route);
  const smartLinkId = tryParseInt(smartLinkIdString);
  const widgetType = useQueryParam('widgetType', route) as SmartLinkWidgetType;
  const widgetUUID = useQueryParam('widgetUUID', route, null);

  if (accountId === undefined || smartLinkId === undefined || widgetType === undefined) {
    throw new Error(
      `Missing accountId: [${accountId}], smartLinkId: [${smartLinkId}] or widgetType: [${widgetType}] in SmartLinkEditionView`
    );
  }

  const [isDeletionModalOpen, setDeletionModalOpen] = useState<boolean>(false);
  const [deletionCallback, setDeletionCallback] = useState<(() => void) | undefined>(undefined);
  const { widget, isGetWidgetLoading, getWidgetError } = useGetSmartLinkQuery(
    { accountId, smartLinkId },
    {
      selectFromResult: ({ data, isLoading, error }) => ({
        widget:
          widgetUUID && data?.smartLink?.recentSmartLinkVersion?.widgets
            ? data.smartLink.recentSmartLinkVersion.widgets.find(({ uuid }) => uuid === widgetUUID)
            : undefined,
        isGetWidgetLoading: isLoading,
        getWidgetError: error,
      }),
    }
  );

  const widgetManager = useMemo(() => getWidgetManager(widgetType), [widgetType]);
  const [createWidget, { isLoading: isCreateWidgetLoading, error: createWidgetError }] = useCreateWidgetMutation();
  const [updateWidget, { isLoading: isUpdateWidgetLoading, error: updateWidgetError }] = useUpdateWidgetMutation();
  const [deleteWidget, { isLoading: isDeleteWidgetLoading, error: deleteWidgetError }] = useDeleteWidgetMutation();
  const error = getWidgetError ?? updateWidgetError ?? createWidgetError ?? deleteWidgetError;
  const isLoading = isGetWidgetLoading || isUpdateWidgetLoading || isCreateWidgetLoading || isDeleteWidgetLoading;

  const closeDeletionModal = useCallback(() => setDeletionModalOpen(false), []);

  const saveWidget = useCallback(
    async (data: ValueOf<RawWidgetData>, type: SmartLinkWidgetType) => {
      const commonProps = {
        accountId,
        smartLinkId,
        type,
        data,
      };
      if (widget) {
        const updateWidgetResult = await updateWidget({
          ...commonProps,
          widgetUUID: widget.uuid,
          position: widget.position,
        });
        if (!isSuccessfulResult(updateWidgetResult)) {
          return;
        }
      } else {
        const createWidgetResult = await createWidget(commonProps);
        if (!isSuccessfulResult(createWidgetResult)) {
          return;
        }
      }
      router.goBack(SMLRoutes.details, { accountId, smartLinkId });
    },
    [updateWidget, router, createWidget, widget, accountId, smartLinkId]
  );

  const handleDeleteWidget = useCallback(() => {
    if (!widgetUUID) {
      return;
    }
    closeDeletionModal();
    deleteWidget({ accountId, smartLinkId, widgetUUID })
      .then((result) => {
        if (!isSuccessfulResult(result)) {
          return;
        }
        if (deletionCallback) {
          deletionCallback();
        }
        router.goBack(SMLRoutes.details, { accountId, smartLinkId });
      })
      .catch(console.error);
  }, [deleteWidget, accountId, smartLinkId, widgetUUID, router, deletionCallback, closeDeletionModal]);

  const triggerDeletion = useCallback((callback?: () => void) => {
    if (callback) {
      setDeletionCallback(() => callback);
    }
    setDeletionModalOpen(true);
  }, []);

  const EditionView = widgetManager.editionView;
  return (
    <>
      {widget && (
        <DeletionModal isVisible={isDeletionModalOpen} closeModal={closeDeletionModal} onDelete={handleDeleteWidget} />
      )}
      <EditionView
        accountId={accountId}
        smartLinkId={smartLinkId}
        saveWidget={saveWidget}
        deleteWidget={triggerDeletion}
        isLoading={isLoading}
        error={error}
        widget={widget}
      />
    </>
  );
};

export const WidgetEditionView = TypedMemo(WidgetEditionViewComponent);
