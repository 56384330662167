import { MusicService } from './types';
import { ImporterID } from '../../importers/types';
import { MusicAPIIntegrationID } from '../types';
import { LinkType } from '../../utils/recognizeLinkTypeByUrl';

export const soundcloud: MusicService = {
  id: 'soundcloud',
  importerID: ImporterID.SoundCloud,
  linkType: LinkType.soundcloud,
  musicAPIID: MusicAPIIntegrationID.SoundCloud,
  shortName: 'SoundCloud',
  name: 'SoundCloud',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: true,
  color: { label: '#FF5500', background: '#FAAB84' },
  tracksPerPlaylistLimit: 500, // https://help.soundcloud.com/hc/en-us/articles/360005673974-Playlist-Limits
};
