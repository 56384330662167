import { AnghamiLikedPlaylist } from './AnghamiLikedPlaylist';
import { AnghamiPlaylist } from './AnghamiPlaylist';

export class AnghamiPlaylistsResponse {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get playlists(): AnghamiPlaylist[] {
    const section = this.data.sections.find((s: any) => s.name === 'playlists');
    return section.data
      .map((item: any) => AnghamiPlaylist.fromData(item))
      .filter((playlist: AnghamiPlaylist | null) => !!playlist);
  }

  get likedPlaylists(): AnghamiLikedPlaylist[] {
    const section = this.data.sections.find((s: any) => s.name === 'subscribed');
    return section.data
      .map((item: any) => AnghamiLikedPlaylist.fromData(item))
      .filter((playlist: AnghamiLikedPlaylist | null) => !!playlist);
  }
}
