import { PaddleProductSku, PaddleSubscriptionSku } from 'fym-common/src/utils/paddleSkus';
import { AnalyticsPurchasePlan } from '../../../analytics/types';

export const paddleSkuToAnalyticsPurchasePlan: Record<PaddleProductSku | PaddleSubscriptionSku, AnalyticsPurchasePlan> =
  {
    [PaddleProductSku.OldiOSLicense]: AnalyticsPurchasePlan.LifetimeBasic,
    [PaddleProductSku.OldAndroidLicense]: AnalyticsPurchasePlan.LifetimeBasic,
    [PaddleProductSku.OldBundleLicense]: AnalyticsPurchasePlan.LifetimeBasic,
    [PaddleProductSku.OldSingleLicense]: AnalyticsPurchasePlan.LifetimeBasic,
    [PaddleProductSku.SingleLicense]: AnalyticsPurchasePlan.LifetimeBasic,
    [PaddleProductSku.Lifetime]: AnalyticsPurchasePlan.LifetimePremium,
    [PaddleSubscriptionSku.Quarterly]: AnalyticsPurchasePlan.QuarterlyPremium,
    [PaddleSubscriptionSku.Yearly]: AnalyticsPurchasePlan.AnnualPremium,
    [PaddleSubscriptionSku.SmartLinksInfluencer]: AnalyticsPurchasePlan.SmartlinksInfluencer,
    [PaddleSubscriptionSku.SmartLinksRookie]: AnalyticsPurchasePlan.SmartlinksRookie,
    [PaddleSubscriptionSku.SmartLinksArtist]: AnalyticsPurchasePlan.SmartlinksArtist,
  };

export const analyticsPurchasePlanToPaddleSku: Record<AnalyticsPurchasePlan, PaddleProductSku | PaddleSubscriptionSku> =
  (() => {
    const dict: any = {};
    Object.keys(paddleSkuToAnalyticsPurchasePlan).forEach((paddleId) => {
      const analyticsPlan = (paddleSkuToAnalyticsPurchasePlan as any)[paddleId] as AnalyticsPurchasePlan;
      dict[analyticsPlan] = paddleId;
    });
    return dict;
  })();
