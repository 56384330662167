import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { emailSchema } from 'fym-common/src/joi/schemas';
import { FieldsName } from './consts';

export const resolver = joiResolver(
  Joi.object({
    [FieldsName.email]: emailSchema.label('Email').required(),
    [FieldsName.password]: Joi.string().label('Password').required(),
  })
);
