import qs from 'qs';
import { defaultRetryOptions, fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { FetchError } from '../../generics/errors/FetchError';
import { NapsterUser } from './models/NapsterUser';
import { NapsterSearchResponse } from './models/NapsterSearchResponse';
import { NapsterPlaylistsResponse } from './models/NapsterPlaylistsResponse';
import { NapsterPlaylist } from './models/NapsterPlaylist';
import { NapsterTracksResponse } from './models/NapsterTracksResponse';
import { NapsterCollectionTrack } from './models/NapsterCollectionTrack';
import { NapsterAlbumsResponse } from './models/NapsterAlbumsResponse';
import { NapsterAlbum } from './models/NapsterAlbum';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { ImporterID } from '../types';
import { convertQueryPropsToString } from '../services/MatchingService.helpers';
import { SearchQueryProperties } from '../../generics/types';
import { CollectionType } from '../../generics/models/Collection';
import { RequestHeaders } from '../../utils/fetch-types';
import { wait } from '../../utils/wait';
import { NapsterTracksByISRCResponse } from './models/NapsterTracksByISRCResponse';

export class NapsterAPI {
  private static BASE_URL = 'https://napi-v2-2-cloud-run-b3gtd5nmxq-uw.a.run.app/';

  public static BASE_API_URL = `${NapsterAPI.BASE_URL}v2.2/`;

  public readonly accessToken: string;

  public readonly userId: string;

  public readonly clientId: string;

  constructor(accessToken: string, userId: string, clientId: string) {
    this.accessToken = accessToken;
    this.userId = userId;
    this.clientId = clientId;
  }

  private get requestHeaders(): RequestHeaders {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.accessToken}`,
      apikey: this.clientId,
    };
  }

  async fetch(
    url: string,
    options: any,
    retryOptions: RetryOptions = defaultRetryOptions,
    retryTimes = 0
  ): Promise<{ [key: string]: any }> {
    const response = await fetchRetry(url, options, retryOptions);

    if (response.status === 401 || response.status === 403) {
      const text = await response.text();
      throw new NotAuthenticatedError({ authId: this.userId, importerId: ImporterID.Napster }, text);
    }
    if (response.status >= 400) {
      const text = await response.text();
      try {
        const data = JSON.parse(text);
        if (data.code === 'BadRequestError' && retryTimes < 5) {
          await wait(1000);
          return this.fetch(url, options, retryOptions, retryTimes + 1);
        }
      } catch (e) {}
      throw new FetchError(response.status, text);
    }
    if (response.status === 204) {
      // Needed for NOCK
      await response.text();
      return {};
    }
    const data = await response.json();
    if (data.meta && data.meta.results && data.meta.results.length > 0) {
      const isSuccess = data.meta.results.every((result: any) => result.success);

      if (!isSuccess) {
        const messages = data.meta.results.map((r: any) => r.message).filter((m: any) => !!m);
        throw new FetchError(400, messages.join('\n'));
      }
    }
    return data;
  }

  async fetchAccount(): Promise<{ user: NapsterUser; avatarUrl: string }> {
    const data = await this.fetch(`${NapsterAPI.BASE_API_URL}me/account`, {
      method: 'GET',
      headers: this.requestHeaders,
    });
    const dataAvatar = await this.fetch(
      `${NapsterAPI.BASE_API_URL}members/${data.account.id}/avatar?apikey=${this.clientId}`,
      {
        method: 'GET',
        headers: this.requestHeaders,
      }
    );

    return { user: new NapsterUser(data), avatarUrl: dataAvatar.url };
  }

  async loadPaginatedPlaylists(onBatch: (playlists: NapsterPlaylist[]) => Promise<void>): Promise<void> {
    const limit = 100;
    const loadPlaylists = async (offset: number): Promise<void> => {
      const playlistResponse = await this.loadPlaylistPage(offset, limit);
      await onBatch(playlistResponse.playlists);
      const newOffset = offset + limit;
      if (newOffset < playlistResponse.totalCount) {
        await loadPlaylists(newOffset);
      }
    };

    await loadPlaylists(0);
  }

  async loadPlaylistPage(offset = 0, limit = 100): Promise<NapsterPlaylistsResponse> {
    const data = await this.fetch(
      `${NapsterAPI.BASE_API_URL}me/library/playlists?${qs.stringify({
        offset,
        limit,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders,
      }
    );

    return new NapsterPlaylistsResponse(data);
  }

  async loadPaginatedAlbums(onBatch: (albums: NapsterAlbum[]) => Promise<void>): Promise<void> {
    const limit = 100;
    const loadAlbums = async (offset: number): Promise<void> => {
      const albumResponse = await this.loadAlbumPage(offset, limit);
      await onBatch(albumResponse.albums);
      const newOffset = offset + limit;
      if (newOffset < albumResponse.totalCount) {
        await loadAlbums(newOffset);
      }
    };

    await loadAlbums(0);
  }

  async loadAlbumPage(offset = 0, limit = 100): Promise<NapsterAlbumsResponse> {
    const data = await this.fetch(
      `${NapsterAPI.BASE_API_URL}me/library/albums?${qs.stringify({
        offset,
        limit,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders,
      }
    );

    return new NapsterAlbumsResponse(data);
  }

  async loadPaginatedPlaylistItems(
    playlistId: string,
    onBatch: (tracks: NapsterCollectionTrack[]) => Promise<void>
  ): Promise<void> {
    const limit = 100;
    const loadItems = async (offset: number): Promise<void> => {
      const itemsResponse = await this.loadPlaylistItemPage(playlistId, offset, limit);
      await onBatch(itemsResponse.tracks);
      const newOffset = offset + limit;
      if (itemsResponse.totalTracks !== undefined && newOffset < itemsResponse.totalTracks) {
        await loadItems(newOffset);
      }
    };

    await loadItems(0);
  }

  async loadPlaylistItemPage(playlistId: string, offset = 0, limit = 100): Promise<NapsterTracksResponse> {
    const data = await this.fetch(
      `${NapsterAPI.BASE_API_URL}me/library/playlists/${playlistId}/tracks?${qs.stringify({
        offset,
        limit,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders,
      }
    );
    return new NapsterTracksResponse(data);
  }

  async loadPaginatedLikedTracks(onBatch: (tracks: NapsterCollectionTrack[]) => Promise<void>): Promise<void> {
    const limit = 100;
    const loadItems = async (offset: number): Promise<void> => {
      const itemsResponse = await this.loadLikedTracksItemPage(offset, limit);
      await onBatch(itemsResponse.tracks);
      const newOffset = offset + limit;
      if (itemsResponse.totalTracks !== undefined && newOffset < itemsResponse.totalTracks) {
        await loadItems(newOffset);
      }
    };

    await loadItems(0);
  }

  async loadLikedTracksItemPage(offset = 0, limit = 100): Promise<NapsterTracksResponse> {
    const data = await this.fetch(
      `${NapsterAPI.BASE_API_URL}me/library/tracks?${qs.stringify({
        offset,
        limit,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders,
      }
    );
    return new NapsterTracksResponse(data);
  }

  async loadPaginatedAlbumItems(
    albumId: string,
    onBatch: (tracks: NapsterCollectionTrack[]) => Promise<void>
  ): Promise<void> {
    const limit = 100;
    const loadItems = async (offset: number): Promise<void> => {
      const itemsResponse = await this.loadAlbumItemsPage(albumId, offset, limit);
      await onBatch(itemsResponse.tracks);
      const newOffset = offset + limit;
      if (itemsResponse.totalTracks !== undefined && newOffset < itemsResponse.totalTracks) {
        await loadItems(newOffset);
      }
    };

    await loadItems(0);
  }

  async loadAlbumItemsPage(albumId: string, offset = 0, limit = 100): Promise<NapsterTracksResponse> {
    const url = `${NapsterAPI.BASE_API_URL}albums/${albumId}/tracks?${qs.stringify({
      offset,
      limit,
    })}`;
    const data = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    return new NapsterTracksResponse(data);
  }

  async search(props: {
    queryProps: SearchQueryProperties;
    type: 'track' | 'album';
    limit?: number;
    offset?: number;
  }): Promise<NapsterSearchResponse> {
    const { queryProps, type, limit = 10, offset = 0 } = props;
    const query = convertQueryPropsToString(queryProps);
    if (!query) {
      return new NapsterSearchResponse(null);
    }
    const data = await this.fetch(
      `${NapsterAPI.BASE_API_URL}search?${qs.stringify({
        offset,
        per_type_limit: limit,
        query,
        type,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders,
      }
    );

    return new NapsterSearchResponse(data);
  }

  async searchByISRC(props: { isrc: string; limit?: number; offset?: number }): Promise<NapsterTracksByISRCResponse> {
    const { isrc, limit = 10, offset = 0 } = props;
    if (!isrc) {
      return new NapsterTracksByISRCResponse(null);
    }
    const data = await this.fetch(
      `${NapsterAPI.BASE_API_URL}tracks/isrc/${isrc}?${qs.stringify({
        offset,
        limit,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders,
      }
    );
    return new NapsterTracksByISRCResponse(data);
  }

  async getPlaylist(playlistId: string): Promise<NapsterPlaylist | null> {
    try {
      const data = await this.fetch(`${NapsterAPI.BASE_API_URL}me/library/playlists/${playlistId}`, {
        method: 'GET',
        headers: this.requestHeaders,
      });
      if (!data?.playlists?.[0]) {
        throw new CollectionDoesNotExistsError('Napster did not return playlist');
      }
      return NapsterPlaylist.fromData(data.playlists[0], CollectionType.PLAYLIST);
    } catch (error) {
      if (error instanceof FetchError) {
        if (error.message.includes('Not a valid playlist id')) {
          throw new CollectionDoesNotExistsError('Napster did not return playlist');
        }
      }
      throw error;
    }
  }

  async getAlbum(albumId: string): Promise<NapsterAlbum | null> {
    const data = await this.fetch(`${NapsterAPI.BASE_API_URL}albums/${albumId}`, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    return NapsterAlbum.fromData(data.albums[0]);
  }

  async createPlaylist(
    name: string,
    props?: { isPublic?: boolean; description?: string }
  ): Promise<NapsterPlaylist | null> {
    const { isPublic = false, description } = props ?? {};
    const data = await this.fetch(`${NapsterAPI.BASE_API_URL}me/library/playlists`, {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        playlists: {
          name,
          description,
          privacy: isPublic ? 'public' : 'private',
          tracks: [],
          tags: [],
        },
      }),
    });
    return NapsterPlaylist.fromData(data.playlists[0], CollectionType.PLAYLIST);
  }

  async publishPlaylist(playlistId: string): Promise<void> {
    await this.fetch(
      `${NapsterAPI.BASE_URL}me/library/playlists/${playlistId}?${qs.stringify({
        rights: 2,
      })}`,
      {
        method: 'PUT',
        headers: this.requestHeaders,
        body: JSON.stringify({ playlists: { privacy: 'public' } }),
      }
    );
  }

  async addTracksToPlaylist(playlistId: string, trackIds: string[]): Promise<NapsterPlaylist> {
    const previousTrackIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    await this.loadPaginatedPlaylistItems(playlistId, async (tracks) => {
      previousTrackIds.push(...tracks.map((t) => t.rawId));
    });
    return this.updatePlaylist(playlistId, { trackIds: [...previousTrackIds, ...trackIds] });
  }

  async updatePlaylist(
    playlistId: string,
    props: { trackIds?: string[]; name?: string; isPublic?: boolean; description?: string }
  ): Promise<NapsterPlaylist> {
    const { trackIds, name, isPublic, description } = props ?? {};
    const data = await this.fetch(`${NapsterAPI.BASE_API_URL}me/library/playlists/${playlistId}`, {
      method: 'PUT',
      headers: this.requestHeaders,
      body: JSON.stringify({
        playlists: {
          tracks: trackIds?.map((tId) => ({ id: tId })),
          name,
          description,
          privacy: isPublic === undefined ? undefined : isPublic ? 'public' : 'privacy',
        },
      }),
    });
    const playlist = NapsterPlaylist.fromData(data.playlists[0], CollectionType.PLAYLIST);
    if (!playlist) {
      throw new CollectionDoesNotExistsError('Napster did not return playlist');
    }
    return playlist;
  }

  async removePlaylist(playlistId: string): Promise<void> {
    await this.fetch(`${NapsterAPI.BASE_API_URL}me/library/playlists/${playlistId}`, {
      method: 'DELETE',
      headers: this.requestHeaders,
    });
  }

  async removeTracksFromPlaylist(playlistId: string, trackIds: string[]): Promise<void> {
    const previousTrackIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    await this.loadPaginatedPlaylistItems(playlistId, async (tracks) => {
      previousTrackIds.push(...tracks.map((track) => track.rawId));
    });
    const trackIdsToLeave = previousTrackIds.filter((itemId) => !trackIds.includes(itemId));
    await this.updatePlaylist(playlistId, { trackIds: trackIdsToLeave });
  }

  async addAlbumToLibrary(albumId: string): Promise<void> {
    const albumTracksIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    await this.loadPaginatedAlbumItems(albumId, async (tracks) => {
      albumTracksIds.push(...tracks.map((t) => t.rawId));
    });

    const response = await fetchRetry(
      `${NapsterAPI.BASE_API_URL}me/library/tracks?${qs.stringify({
        id: albumTracksIds.join(','),
      })}`,
      {
        method: 'POST',
        headers: this.requestHeaders,
        body: JSON.stringify({
          favorites: [{ id: albumId }],
        }),
      },
      defaultRetryOptions
    );

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to add album[${albumId}] to library on Napster got wrong response[${response.status}]: ${text}`
      );
    }
  }

  async moveTrackInPlaylist(playlistId: string, trackIds: string[]): Promise<void> {
    await this.fetch(
      `${NapsterAPI.BASE_API_URL}me/library/playlists/${playlistId}/tracks?${qs.stringify({
        rights: 2,
      })}`,
      {
        method: 'PUT',
        headers: this.requestHeaders,
        body: JSON.stringify({
          tracks: trackIds.map((tId) => ({
            id: tId,
          })),
        }),
      }
    );
  }
}
