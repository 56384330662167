import { MusicService } from './types';
import { MusicAPIIntegrationID } from '../types';
import { ImporterID } from '../../importers/types';
import { LinkType } from '../../utils/recognizeLinkTypeByUrl';

export const appleMusic: MusicService = {
  id: 'apple-music',
  importerID: ImporterID.AppleMusic,
  linkType: LinkType.applemusic,
  musicAPIID: MusicAPIIntegrationID.AppleMusic,
  shortName: 'Apple Music',
  name: 'Apple Music',
  isComingSoon: false,
  areStatsSupported: true,
  areStatsComingSoon: false,
  isSupported: true,
  color: { label: '#231F20', background: '#656565' },
};
