import React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { TypedMemo } from '../../../common/types';
import { ClickableDiv } from '../../../common/views/ClickableDiv';
import { FormThemeSelectionPreviewRendererProps } from './props';

const FormThemeSelectionPreviewRendererComponent: React.FC<FormThemeSelectionPreviewRendererProps> = ({
  onClick,
  checked,
  option,
  disabled,
  previewURL,
}) => {
  const intl = useIntl();
  return (
    <ClickableDiv
      onClick={onClick}
      disabled={disabled}
      className={classNames('form-theme-selection-preview', {
        'form-theme-selection-preview-checked': checked,
      })}
    >
      <img
        src={previewURL}
        alt={intl.formatMessage(
          {
            id: 'app.forms.smartLink.themeSelectionPreview.image-alt',
            defaultMessage: 'Preview of Theme {theme}',
          },
          {
            theme: option,
          }
        )}
      />
      <div>{option}</div>
    </ClickableDiv>
  );
};

export const FormThemeSelectionPreviewRenderer = TypedMemo(FormThemeSelectionPreviewRendererComponent);
