import { GenericAuthor } from '../../../generics/models/GenericAuthor';
import { buildCoverUrl } from '../utils';
import { Artist } from './TidalResponseModels';
import { getScriptsContents } from '../../../utils/htmlUtils';

export class TidalArtist extends GenericAuthor {
  static fromData(data: any): TidalArtist | null {
    if (!data) {
      return null;
    }
    const { id, name, picture } = data ?? {};
    if (!name || !id) {
      return null;
    }
    return new TidalArtist({
      rawId: id,
      name,
      imageUrl: picture ? buildCoverUrl(picture) : undefined,
    });
  }

  static fromShareURLData(HTMLString: string, url: string): TidalArtist | null {
    const scriptsContents = getScriptsContents(HTMLString);
    const artistDataString = scriptsContents.find((content) => content.includes('context'));
    if (!artistDataString) {
      return null;
    }
    let artistData: any;
    try {
      artistData = JSON.parse(artistDataString);
    } catch (error) {
      console.error(error);
      return null;
    }
    if (!artistData) {
      return null;
    }
    const urlParts = artistData.url.split('/');
    const rawId = urlParts[urlParts.length - 1];
    if (!rawId || !artistData.name) {
      return null;
    }
    return new TidalArtist({
      rawId,
      name: artistData.name,
      imageUrl: artistData.image,
      publicUrl: artistData.url ?? url,
    });
  }
}

export function convertTidalRawArtists(artists: Artist[] | undefined) {
  return artists?.reduce<TidalArtist[]>((res, artist) => {
    const parsedArtist = TidalArtist.fromData(artist);
    return parsedArtist ? [...res, parsedArtist] : res;
  }, []);
}
