import { HttpStatusCode } from './HttpStatusCode';
import { InternalErrorCode } from './internalErrorCodes';
import { BaseError } from '../generics/errors/BaseError';

type APIErrorProps = {
  message: string;
  httpStatusCode: HttpStatusCode;
  internalCode?: InternalErrorCode;
  isOperational: boolean;
  headers?: Record<string, string>;
};

export abstract class APIError extends BaseError {
  public readonly httpStatusCode;

  public readonly internalCode;

  public readonly headers;

  protected constructor({ message, httpStatusCode, internalCode, isOperational, headers }: APIErrorProps) {
    super({ message, isOperational });
    this.httpStatusCode = httpStatusCode;
    this.internalCode = internalCode;
    this.headers = headers;
  }
}
