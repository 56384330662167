import { AppleMusicAlbum } from './AppleMusicAlbum';
import { AppleMusicSearchRawResponse } from './types';
import { AppleMusicMatchedTrack } from './AppleMusicMatchedTrack';

export class AppleMusicSearchResponse {
  private readonly data;

  constructor(data: AppleMusicSearchRawResponse | null) {
    this.data = data;
  }

  get tracks(): AppleMusicMatchedTrack[] {
    if (!this.data?.results?.songs?.data) {
      return [];
    }
    return this.data?.results.songs.data
      .map((item) => AppleMusicMatchedTrack.fromData(item))
      .filter((track): track is AppleMusicMatchedTrack => !!track);
  }

  get albums(): AppleMusicAlbum[] {
    if (!this.data?.results?.albums?.data) {
      return [];
    }
    return this.data.results.albums.data
      .map((item) => AppleMusicAlbum.fromData(item))
      .filter((album): album is AppleMusicAlbum => !!album);
  }

  get firstTrack(): AppleMusicMatchedTrack | undefined {
    return this.tracks[0];
  }

  get firstAlbum(): AppleMusicAlbum | undefined {
    return this.albums[0];
  }
}
