export class ZvukLibrary {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get playlistIds(): string {
    return this.data.result.playlists?.map((p: any) => p.id);
  }

  get releaseIds(): string {
    return this.data.result.releases?.map((p: any) => p.id);
  }

  get trackIds(): string {
    return this.data.result.tracks?.map((p: any) => p.id);
  }
}
