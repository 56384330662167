import { BaseError } from './BaseError';
import { Response } from '../../utils/fetch-types';

export class FetchError extends BaseError {
  public code?: number;

  public response?: Response;

  public responseText?: string;

  constructor(code?: number, message?: string, responseText?: string, response?: Response) {
    super({ message });
    this.code = code;
    this.response = response;
    this.responseText = responseText;
    // see: http://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, FetchError.prototype);
    this.name = FetchError.name; // stack traces display correctly now
  }
}
