import { GenericMatchedItem } from './models/GenericMatchedItem';
import { GenericCollectionItem } from './models/GenericCollectionItem';

export function convertMatchedItemToCollectionItem(
  matchedItem: GenericMatchedItem,
  areTrackEntryIdAndGlobalIdDifferent?: boolean
) {
  const { rawId, name, album, artist, isrc, additionalData, imageUrl, duration, previewUrl, sourceURL } = matchedItem;
  return new GenericCollectionItem({
    // Some services don't return info about newly added tracks, so we do not know the entryId
    rawId: areTrackEntryIdAndGlobalIdDifferent === true ? '' : rawId,
    globalId: rawId,
    name,
    album,
    artist,
    isrc,
    additionalData,
    imageUrl,
    duration,
    previewUrl,
    sourceURL,
  });
}
