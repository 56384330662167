import { convertJioSaavnTrackData, getArtistsNamesAsString } from '../utils';
import { GenericMatchedItem } from '../../../generics/models/GenericMatchedItem';

export class JioSaavnMatchedTrack extends GenericMatchedItem {
  static fromData(data: any): JioSaavnMatchedTrack | null {
    const trackData = convertJioSaavnTrackData(data);
    return trackData ? new JioSaavnMatchedTrack(trackData) : null;
  }

  static fromShareURLData(data: any, url: string): JioSaavnMatchedTrack | null {
    if (!data?.id || !data.title?.text) {
      return null;
    }
    return new JioSaavnMatchedTrack({
      rawId: `${data.id}`,
      name: data.title.text,
      album: data.album?.text,
      artist: getArtistsNamesAsString(data.artists),
      imageUrl: data.image?.[0],
      duration: data.duration !== undefined ? data.duration * 1000 : undefined,
      previewUrl: data.more_info?.vlink,
      sourceURL: url,
    });
  }
}
