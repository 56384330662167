import React from 'react';
import { TypedMemo } from '../../common/types';
import { OfflineToastNotification } from '../../../routing/offlineNotification/views/OfflineToastNotification';

const AppRenderComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      <OfflineToastNotification />
      {children}
    </>
  );
};
export const AppRender = TypedMemo(AppRenderComponent);
