import React from 'react';
import { TypedMemo } from '../../../common/types';
import { FormThemeSelectionPreview } from './FormThemeSelectionPreview';
import { FormThemeSelectionRendererProps } from './props';

const FormThemeSelectionRendererComponent: React.FC<FormThemeSelectionRendererProps> = ({
  value,
  onClick,
  themes,
  disabled,
}) => {
  return (
    <div className="form-theme-selection-preview-container">
      {themes.map((option) => (
        <FormThemeSelectionPreview
          key={option}
          option={option}
          checked={value === option}
          onClick={onClick}
          disabled={disabled}
        />
      ))}
    </div>
  );
};
export const FormThemeSelectionRenderer = TypedMemo(FormThemeSelectionRendererComponent);
