import { GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionAccess, CollectionType } from '../../../generics/models/Collection';
import { AppleMusicPlaylistRawResponse } from './types';
import { buildArtworkUrl, createAppleMusicPlaylistUrl } from '../utils';

function convertIsPublicToAccess(isPublic: boolean | undefined | null) {
  switch (isPublic) {
    case true:
      return CollectionAccess.public;
    case false:
      return CollectionAccess.private;
    case undefined:
    case null:
    default:
      return undefined;
  }
}

export class AppleMusicPlaylist extends GenericCollection {
  static fromData(data: AppleMusicPlaylistRawResponse | undefined, url?: string): AppleMusicPlaylist | null {
    if (!data?.id || !data.attributes?.name) {
      return null;
    }
    const type = data.attributes.canEdit === true ? CollectionType.PLAYLIST : CollectionType.LIKED_PLAYLIST;
    const publicUrl = url ?? createAppleMusicPlaylistUrl(data);
    return new AppleMusicPlaylist({
      type,
      rawId: data.id,
      name: data.attributes.name,
      itemCount: data.attributes.trackCount,
      additionalData: {
        imageUrl: buildArtworkUrl(data.attributes.artwork),
        dateAdded: data?.attributes.dateAdded,
        description: data.attributes.description?.standard,
      },
      publicUrl,
      access: convertIsPublicToAccess(data.attributes.isPublic),
    });
  }

  static fromShareUrlData(data: any, url: string): AppleMusicPlaylist | null {
    const rawId = (data?.url ?? url)?.split('/').pop();
    const name = data?.name;
    if (!rawId || !name) {
      return null;
    }
    return new AppleMusicPlaylist({
      type: CollectionType.LIKED_PLAYLIST,
      rawId,
      name,
      itemCount: data.numTracks ?? data.track?.length,
      additionalData: {
        dateAdded: data?.datePublished,
      },
      publicUrl: data.url ?? url,
    });
  }
}
