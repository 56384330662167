import { useSearchParams } from 'react-router-dom';

export function useQueryParam(name: string, _route: any): string;
export function useQueryParam<T>(name: string, _route: any, defaultValue: T): string | T;
export function useQueryParam<T>(name: string, _route: any, defaultValue?: T): string | T {
  const [searchParams] = useSearchParams();
  const value = searchParams.get(name);
  if (value === null) {
    if (defaultValue !== undefined) {
      return defaultValue;
    }
    throw new Error(`Missing value [${name}] in useQueryParam`);
  }
  return value;
}
