import React, { useMemo } from 'react';
import classnames from 'classnames';
import { TypedMemo } from '../../types';
import { TitleProps } from './props';

const TitleComponent: React.FC<React.PropsWithChildren<TitleProps>> = ({
  children,
  center = false,
  noMargin = false,
  color,
  bold = false,
  manyLines = false,
}) => {
  const classes = classnames('ui-text', {
    'text-center': center,
    'mb-3': !noMargin,
    'm-0': noMargin,
    'fw-light': !bold,
    'fw-bold': bold,
    'text-1-line': !manyLines,
  });
  const styles: React.CSSProperties = useMemo(
    () => ({
      color: color ?? 'inherit',
      minHeight: !manyLines ? '2rem' : undefined,
    }),
    [color, manyLines]
  );
  return (
    <h2 className={classes} style={styles}>
      {children}
    </h2>
  );
};

export const Title = TypedMemo(TitleComponent);
