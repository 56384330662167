import { QobuzAlbum } from './QobuzAlbum';

export class QobuzAlbumsResponse {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get albums(): QobuzAlbum[] {
    if (!this.data?.albums?.items) {
      return [];
    }
    return this.data.albums.items
      .map((item: any) => QobuzAlbum.fromData(item))
      .filter((album: QobuzAlbum | null) => !!album);
  }

  get total(): number {
    return this.data.albums.total;
  }
}
