import { AppTarget, Config, Environment, Platform } from './types';
import { ReleaseChannel, ReleaseChannelApp } from '../types/releaseChannel';
import { getLocalIP } from './ipAddress';

export const appConfig: Config = (platform, target, environment) => {
  const localIp = getLocalIP(environment);
  const myIpAddressOrLocalhost =
    localIp !== undefined && (platform === Platform.ios || platform === Platform.android) ? localIp : 'localhost';
  console.log(`%cYour Local IP Address: ${myIpAddressOrLocalhost}`, 'background: #6417fb; color: white;');
  console.log(`Preparing common config... platform: ${platform}, target: ${target}, env: ${environment}`);
  const themePreviewURL = (theme: string, forDesktop: boolean) =>
    `https://fymstatics.imgix.net/images/smartlinks/v1/theme-previews/${theme}-${
      forDesktop ? 'desktop' : 'mobile'
    }.jpeg`;
  const appleSubscriptionManagementSupportURL = 'https://support.apple.com/en-us/HT202039';
  const googlePlaySubscriptionManagementSupportURL = 'https://support.google.com/googleplay/answer/7018481';

  const canUseRelativeAPIUrl =
    platform !== Platform.ios &&
    platform !== Platform.android &&
    platform !== Platform.server &&
    (platform !== Platform.electron ||
      environment === Environment.local ||
      environment === Environment.localProduction); // on Electron for local development, we use devProxy server, to avoid CORS issues

  if (environment === Environment.production || environment === Environment.localProduction) {
    const relativeApiURL = environment === Environment.localProduction ? 'api_productionapi' : 'api_api';
    const protocolSchemaFYM = environment === Environment.localProduction ? 'fymlocal' : 'fym';
    return {
      fymApiURL: (supportsCORS) =>
        supportsCORS && canUseRelativeAPIUrl ? `/${relativeApiURL}/` : 'https://api.freeyourmusic.com/',
      musicApiURL: 'https://api.musicapi.com/',
      musicAppURL: 'https://app.musicapi.com/',
      musicApiToken: '7dadaf8f-6297-4e35-9661-d007ea65696c',
      fymWebURL: 'https://freeyourmusic.com/',
      smartLinksWebURL: 'https://music24.com/',
      boomplayAppId: 'freeyourmusic',
      amazonSecurityProfileId: 'amzn1.application.976844aef49644b5b495f6563f5ea1a5',
      loadLocalRealm: false,
      appDomains: target === AppTarget.smartlinks ? ['music24.com'] : ['fym.fm', 'freeyourmusic.com'],
      protocolSchema: target === AppTarget.smartlinks ? 'smartlinks' : protocolSchemaFYM,
      smartLinkAppUrl: `https://app.music24.com`,
      smartLinkCookieDomain: `music24.com`,
      appReleaseLinks: {
        channel: ReleaseChannel.production,
        app: target === AppTarget.smartlinks ? ReleaseChannelApp.smartlinks : ReleaseChannelApp.freeyourmusic,
      },
      environment,
      target,
      platform,
      themePreviewURL,
      appleSubscriptionManagementSupportURL,
      googlePlaySubscriptionManagementSupportURL,
    };
  }
  if (environment === Environment.staging) {
    return {
      fymApiURL: (supportsCORS) => (supportsCORS && canUseRelativeAPIUrl ? '/api_api/' : 'https://api.stampapp.io/'),
      musicApiURL: 'https://apirc.musicapi.com/',
      musicAppURL: 'https://rcapp.musicapi.com/',
      musicApiToken: '8d883178-9585-482e-bb4b-0c85736edc2d',
      fymWebURL: 'https://site.stampapp.io/',
      smartLinksWebURL: 'https://smartlinks.stampapp.io/',
      boomplayAppId: 'freeyourmusic',
      amazonSecurityProfileId: 'amzn1.application.976844aef49644b5b495f6563f5ea1a5',
      loadLocalRealm: false,
      appDomains:
        target === AppTarget.smartlinks ? ['smartlinks.stampapp.io'] : ['site.stampapp.io', 'site2.stampapp.io'],
      protocolSchema: target === AppTarget.smartlinks ? 'smartlinksrc' : 'fymrc',
      appReleaseLinks: {
        channel: ReleaseChannel.staging,
        app: target === AppTarget.smartlinks ? ReleaseChannelApp.smartlinks : ReleaseChannelApp.freeyourmusic,
      },
      smartLinkAppUrl: `https://app.stampapp.io`,
      smartLinkCookieDomain: `stampapp.io`,
      environment,
      target,
      platform,
      themePreviewURL,
      appleSubscriptionManagementSupportURL,
      googlePlaySubscriptionManagementSupportURL,
    };
  }
  return {
    fymApiURL: (supportsCORS) =>
      supportsCORS && canUseRelativeAPIUrl ? '/api_api/' : `http://${myIpAddressOrLocalhost}:3223/`,
    musicApiURL: `http://${myIpAddressOrLocalhost}:4223/`,
    musicAppURL: `http://${myIpAddressOrLocalhost}:4020/`,
    musicApiToken: '8d883178-9585-482e-bb4b-0c85736edc2d',
    fymWebURL: `http://${myIpAddressOrLocalhost}:3010/`,
    smartLinksWebURL: `http://${myIpAddressOrLocalhost}:3012/`,
    boomplayAppId: 'freeyourmusic',
    amazonSecurityProfileId: 'amzn1.application.976844aef49644b5b495f6563f5ea1a5',
    loadLocalRealm: false,
    appDomains:
      target === AppTarget.smartlinks ? ['smartlinks.stampapp.io'] : ['site.stampapp.io', 'site2.stampapp.io'],
    protocolSchema: target === AppTarget.smartlinks ? 'smartlinkslocal' : 'fymlocal',
    smartLinkAppUrl: `http://${myIpAddressOrLocalhost}:3002`,
    smartLinkCookieDomain: myIpAddressOrLocalhost,
    appReleaseLinks: {
      channel: ReleaseChannel.staging,
      app: target === AppTarget.smartlinks ? ReleaseChannelApp.smartlinks : ReleaseChannelApp.freeyourmusic,
    },
    environment,
    target,
    platform,
    themePreviewURL,
    appleSubscriptionManagementSupportURL,
    googlePlaySubscriptionManagementSupportURL,
  };
};
