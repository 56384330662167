import { openExternal } from './openExternal';

export const openSupportEmail = (
  platform: string,
  appVersion: string,
  realmPartition: string | undefined,
  email?: string,
  licenseKey?: string,
  issueId?: string
) => {
  const debugInfos = [`Platform: ${platform}`, `App: ${appVersion}`, `Device ID: ${realmPartition}`];

  if (email) {
    debugInfos.push(`License Email: ${email}`);
  }
  if (licenseKey) {
    debugInfos.push(`Passcode: ${licenseKey}`);
  }

  if (issueId) {
    debugInfos.push(`Issue Identifier: ${issueId}`);
  }

  const emailBody = `Hey, I have a problem with the app \n\n\n\n--\nDebug info:\n${debugInfos.join(`\n`)}`;
  const link = `mailto:support@freeyourmusic.com?subject=${encodeURIComponent(
    'Mobile Support'
  )}&body=${encodeURIComponent(emailBody)}`;
  return openExternal(link);
};
