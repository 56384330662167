import { GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { RessoAPI } from './RessoAPI';
import { CollectionType } from '../../generics/models/Collection';
import { FetchError } from '../../generics/errors/FetchError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { SearchQueryProperties } from '../../generics/types';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { ImporterID } from '../types';
import { RessoPlayList } from './models/RessoPlaylist';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { RessoAlbum } from './models/RessoAlbum';
import { RessoMySongs } from './models/RessoMySongs';
import { resso } from '../../musicServices/services/Resso';
import { refreshAuthData } from '../../musicApi/utils/refreshAuthData';
import { MusicAPIIntegrationID } from '../../musicServices/types';

export const RessoImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.Resso;

  public static musicService = resso;

  public authenticationData: GenericAuthenticationData;

  private ressoApi: RessoAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData;
    this.ressoApi = new RessoAPI(authenticationData.additionalData?.accessToken as string, authenticationData.authId);
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.ressoApi = new RessoAPI(authenticationData.additionalData?.accessToken as string, authenticationData.authId);
  }

  async getPaginatedCollections(
    onBatch: (collections: (RessoMySongs | RessoPlayList | RessoAlbum)[]) => Promise<void>
  ): Promise<void> {
    const mySongs = new RessoMySongs(this.authenticationData.authId);
    await onBatch([mySongs]);
    await this.ressoApi.loadPaginatedPlaylists(onBatch);
    await this.ressoApi.loadPaginatedAlbums(onBatch);
  }

  async getCollection(collection: GenericCollection): Promise<RessoPlayList | RessoAlbum> {
    let result: RessoPlayList | RessoAlbum | null;
    if (collection.type === CollectionType.ALBUM) {
      result = await this.ressoApi.loadAlbum(collection.rawId);
    } else {
      result = await this.ressoApi.loadPlaylist(collection.rawId);
    }

    if (!result) {
      throw new CollectionDoesNotExistsError();
    }
    return result;
  }

  async createCollection(collection: GenericCollection): Promise<RessoPlayList> {
    let newCollection: RessoPlayList | null = null;
    try {
      newCollection = await this.ressoApi.createPlaylist(collection.name);
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!newCollection) {
      throw new CouldNotCreateCollection();
    }
    return newCollection;
  }

  async addItemToCollection(collection: GenericCollection, matchedItem: GenericMatchedItem) {
    await this.ressoApi.addTracksToPlaylist(collection.rawId, [matchedItem.rawId]);
    return convertMatchedItemToCollectionItem(matchedItem);
  }

  async addManyItemsToCollection(
    collection: GenericCollection,
    data: {
      matchedItem: GenericMatchedItem;
      position?: number;
    }[]
  ) {
    const tracksIds = data.map(({ matchedItem }) => matchedItem.rawId);
    await this.ressoApi.addTracksToPlaylist(collection.rawId, tracksIds);
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    collectionItems: GenericCollectionItem[]
  ): Promise<void> {
    if (collection.type !== CollectionType.PLAYLIST || collectionItems.length === 0) {
      return;
    }
    const itemsIds = collectionItems.map((item) => item.rawId);
    await this.ressoApi.removeTracksFromPlaylist(collection.rawId, itemsIds);
  }

  async clearCollection(collection: GenericCollection) {
    const itemsIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    await this.getPaginatedItems(collection, async (items) => {
      itemsIds.push(...items.map((item) => item.rawId));
    });
    await this.ressoApi.removeTracksFromPlaylist(collection.rawId, itemsIds);
  }

  async matchItems(queryProps: SearchQueryProperties): Promise<GenericMatchedItem[]> {
    let { tracks } = await this.ressoApi.search({ queryProps, searchType: 'track', advancedSearch: true });
    if (tracks.length === 0) {
      ({ tracks } = await this.ressoApi.search({ queryProps, searchType: 'track', advancedSearch: false }));
    }
    return tracks;
  }

  async matchAlbums(queryProps: SearchQueryProperties) {
    let { albums } = await this.ressoApi.search({ queryProps, searchType: 'album', advancedSearch: true });
    if (albums.length === 0) {
      ({ albums } = await this.ressoApi.search({ queryProps, searchType: 'album', advancedSearch: false }));
    }
    return albums;
  }

  async addAlbumToLibrary(album: GenericCollection): Promise<void> {
    await this.ressoApi.addAlbumToLibrary(album.rawId);
  }

  async reAuthenticate(
    withData: GenericAuthenticationData,
    forceFetchRefreshTokens?: boolean
  ): Promise<GenericAuthenticationData> {
    return refreshAuthData({
      authId: withData.authId,
      userUUID: withData.additionalData?.userUUID ?? null,
      integrationId: MusicAPIIntegrationID.Resso,
      forceFetchRefreshTokens,
    });
  }

  doesSupportReAuth() {
    return true;
  }

  public doesSupportSearchByISRC(): boolean {
    return true;
  }

  public doesSupportAlbums(): boolean {
    return true;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return false;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return false;
  }

  public doesSupportMovingManyItems(): boolean {
    return false;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    switch (forCollection.type) {
      case CollectionType.PLAYLIST:
      case CollectionType.LIKED_PLAYLIST:
        return this.ressoApi.loadPaginatedPlaylistsItems(forCollection.rawId, onBatch);
      case CollectionType.MY_SONGS:
        return this.ressoApi.loadPaginatedMySongs(onBatch);
      case CollectionType.ALBUM:
        return this.ressoApi.loadPaginatedAlbumItems(forCollection as RessoAlbum, onBatch);
      default:
        return undefined;
    }
  }
};
