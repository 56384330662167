import React, { Key } from 'react';
import { Control, useController } from 'react-hook-form';
import { TypedMemo } from '../../../common/types';
import { FormRadioButton } from './FormRadioButton';
import { Spacer } from '../../../common/views/spacer/Spacer';
import { FormInputContainer } from '../FormInputContainer/FormInputContainer';

interface FormRadioButtonsProps {
  options: {
    value: Key;
    displayLabel: string;
  }[];
  control: Control<any>;
  name: string;
  label: string;
  disabled?: boolean;
}

const FormRadioButtonComponent: React.FC<FormRadioButtonsProps> = ({ options, control, name, label, disabled }) => {
  const { field, fieldState } = useController({ control, name });
  const { value, onChange } = field;
  const { error, isDirty, isTouched } = fieldState;

  return (
    <FormInputContainer label={label} name={name} error={error?.message} isDirty={isDirty} isTouched={isTouched}>
      <Spacer flex flexDirection="row">
        {options.map((option) => (
          <FormRadioButton
            key={option.value}
            option={option}
            checked={value === option.value}
            onClick={onChange}
            disabled={disabled}
          />
        ))}
      </Spacer>
    </FormInputContainer>
  );
};
export const FormRadioButtons = TypedMemo(FormRadioButtonComponent);
