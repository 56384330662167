import React, { useCallback, useEffect } from 'react';
import { TypedMemo } from '../../common/types';
import { useGetAccountQuery, useUpdateAccountMutation } from '../../../redux/api/fymAPI';
import { isSuccessfulResult } from '../../../redux/api/utils/isSuccessfulResult';
import { useAccountId } from '../../../redux/slices/appInfo/hooks';
import { useLocalStore } from '../../common/storeHooks';
import { SMLOnboardingFormStep2 } from '../../forms/SMLOnboardingFormStep2/SMLOnboardingFormStep2';
import { LoadingView } from '../../common/views/LoadingView';
import { SMLSetUpContainer } from '../smlSetUpContainer/SMLSetUpContainer';
import { SMLOnboardingFormStep2Props } from '../../forms/SMLOnboardingFormStep2/props';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLAnalytics } from '../../analytics/sml/analytics';

const SMLOnboarding2ViewComponent: React.FC = () => {
  const router = useSMLRouter();
  const accountId = useAccountId();

  if (accountId === null) {
    throw new Error('Missing accountId');
  }

  const {
    data: accountResponse,
    isLoading: isGetAccountLoading,
    error: getAccountError,
  } = useGetAccountQuery({ accountId });
  const [updateAccount, { isLoading: isUpdateAccountLoading, error: updateAccountError }] = useUpdateAccountMutation();
  const { setValue: setOnboardingSMLFinishedStep2 } = useLocalStore('onboardingSMLFinishedStep2', false);

  const goToNextStep = useCallback(() => {
    setOnboardingSMLFinishedStep2(true)
      .then(() => {
        router.replace(SMLRoutes.onboarding3);
        SMLAnalytics.trackOnboardingStep2Finished().catch(console.error);
      })
      .catch(console.error);
  }, [router, setOnboardingSMLFinishedStep2]);

  const handleAddAvatar: SMLOnboardingFormStep2Props['onSubmit'] = useCallback(
    async ({ avatar }) => {
      const result = await updateAccount({
        id: accountId,
        avatarFileId: avatar.id,
      });
      if (!isSuccessfulResult(result)) {
        return;
      }
      goToNextStep();
    },
    [updateAccount, goToNextStep, accountId]
  );

  useEffect(() => {
    SMLAnalytics.trackOnboardingStep2Started().catch(console.error);
  });
  if (!accountResponse) {
    return <LoadingView />;
  }

  return (
    <SMLSetUpContainer>
      <SMLOnboardingFormStep2
        onSubmit={handleAddAvatar}
        onSkip={goToNextStep}
        isLoading={isGetAccountLoading || isUpdateAccountLoading}
        error={getAccountError ?? updateAccountError}
        account={accountResponse.account}
      />
    </SMLSetUpContainer>
  );
};

export const SMLOnboarding2View = TypedMemo(SMLOnboarding2ViewComponent);
