import { v4 as uuid4 } from 'uuid';
import {
  SearchResponseCollection,
  SearchResponseStatus,
  SearchResponseTrack,
  SearchResult,
  SearchType,
} from 'fym-common/src/types/searchService';
import { ReleaseLinkData } from './types';

export function parseUrlToReleaseLinkData(url: string): ReleaseLinkData {
  return { id: uuid4(), data: { url }, type: SearchType.unknown };
}

function isTrackSearchResponse(data: any): data is SearchResult<SearchResponseTrack> {
  return data.type === SearchType.track;
}

export function parseSearchResultToReleaseLinkData(
  result: SearchResult<SearchResponseTrack | SearchResponseCollection>
): ReleaseLinkData | undefined {
  if (!result.data) {
    return undefined;
  }
  const artist = result.data.artistNames?.join(' ');
  return {
    id: uuid4(),
    type: result.type,
    source: result.source,
    data: isTrackSearchResponse(result)
      ? {
          track: result.data.name,
          album: result.data.albumName,
          artist,
          imageUrl: result.data.imageUrl,
          url: result.data.url,
        }
      : {
          album: result.data.name,
          artist,
          imageUrl: result.data.imageUrl,
          url: result.data.url,
        },
  };
}

export function parseSearchResults(
  items: SearchResult<SearchResponseTrack | SearchResponseCollection>[]
): ReleaseLinkData[] {
  return items.reduce((results, item) => {
    if (item.status !== SearchResponseStatus.success) {
      return results;
    }
    const parsedResult = parseSearchResultToReleaseLinkData(item);
    if (!parsedResult || !parsedResult.data.url) {
      return results;
    }
    results.push(parsedResult);
    return results;
  }, [] as ReleaseLinkData[]);
}
