import React, { useCallback, useState } from 'react';
import { useDeleteSmartLinkMutation, useDeleteTrackerMutation } from '../../redux/api/fymAPI';
import { DeletionModal } from '../common/views/modals/deletionModal/DeletionModal';
import { isSuccessfulResult } from '../../redux/api/utils/isSuccessfulResult';
import { useSMLRouter } from '../../routing/sml/hooks';
import { SMLRoutes } from '../../routing/sml/SMLRoutes';

export function useDeleteSmartLinkFlow(accountId: number, smartLinkId: number | undefined) {
  const router = useSMLRouter();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [deleteSmartLink, { isLoading: isDeleteSmartLinkLoading, error: deleteSmartLinkError }] =
    useDeleteSmartLinkMutation();

  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  const onDelete = useCallback(() => {
    if (smartLinkId === undefined) {
      return;
    }
    closeModal();
    deleteSmartLink({ accountId, smartLinkId })
      .then((result) => {
        if (!isSuccessfulResult(result)) {
          return;
        }
        router.push(SMLRoutes.home);
      })
      .catch(console.error);
  }, [deleteSmartLink, accountId, smartLinkId, router, closeModal]);

  return {
    openSmartLinkDeletionModal: openModal,
    isDeleteSmartLinkLoading,
    deleteSmartLinkError,
    SmartLinkDeletionModal: <DeletionModal isVisible={isModalOpen} closeModal={closeModal} onDelete={onDelete} />,
  };
}

export function useDeleteTrackerFlow(accountId: number, trackerId: number | undefined) {
  const router = useSMLRouter();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [deleteTracker, { isLoading: isDeleteTrackerLoading, error: deleteTrackerError }] = useDeleteTrackerMutation();

  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  const onDelete = useCallback(() => {
    if (trackerId === undefined) {
      return;
    }
    closeModal();
    deleteTracker({ accountId, trackerId })
      .then((result) => {
        if (!isSuccessfulResult(result)) {
          return;
        }
        router.goBack(undefined);
      })
      .catch(console.error);
  }, [deleteTracker, accountId, trackerId, router, closeModal]);

  return {
    openTrackerDeletionModal: openModal,
    isDeleteTrackerLoading,
    deleteTrackerError,
    TrackerDeletionModal: <DeletionModal isVisible={isModalOpen} closeModal={closeModal} onDelete={onDelete} />,
  };
}
