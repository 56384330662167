import React from 'react';
import { VerticalPosition } from 'fym-common/src/utils/types';
import { CenterFixedContainerProps } from './props';
import { TypedMemo } from '../../types';

function getTopAndBottom(position?: CenterFixedContainerProps['position']) {
  let top: number | undefined;
  let bottom: number | undefined;

  switch (position) {
    case VerticalPosition.top:
      top = 0;
      break;
    case VerticalPosition.center:
      top = 50;
      break;
    case VerticalPosition.bottom:
    default:
      bottom = 0;
      break;
  }

  return {
    top: top !== undefined ? `${top}%` : '',
    bottom: bottom !== undefined ? `${bottom}%` : '',
  };
}

const CenterFixedContainerComponent: React.FC<React.PropsWithChildren<CenterFixedContainerProps>> = ({
  children,
  position,
  className,
}) => {
  const { top, bottom } = getTopAndBottom(position);
  const transformY = position === VerticalPosition.center ? -50 : 0;
  return (
    <div
      className={className}
      style={{
        position: 'absolute',
        left: '50%',
        transform: `translate(-50%, ${transformY}%)`,
        top,
        bottom,
      }}
    >
      {children}
    </div>
  );
};

export const CenterFixedContainer = TypedMemo(CenterFixedContainerComponent);
