import React from 'react';
import { TypedMemo } from '../../../../common/types';
import { WidgetsOptionsButtonRendererProps } from './props';
import { SMLButton } from '../../../../common/views/SMLButton/SMLButton';

const WidgetsOptionsButtonRendererComponent: React.FC<WidgetsOptionsButtonRendererProps> = ({ options }) => {
  return (
    <>
      {options.map((opt) => (
        <div key={opt.name}>
          <SMLButton
            color="dark"
            outline
            onClick={opt.onClick}
            leftIcon={opt.icon}
            className="mt-2 sml-edition-new-widget-button"
          >
            {opt.name}
          </SMLButton>
        </div>
      ))}
    </>
  );
};

export const WidgetsOptionsButtonRenderer = TypedMemo(WidgetsOptionsButtonRendererComponent);
