import { MusicService } from './types';
import { ImporterID } from '../../importers/types';
import { MusicAPIIntegrationID } from '../types';
import { LinkType } from '../../utils/recognizeLinkTypeByUrl';

export const pandora: MusicService = {
  id: 'pandora',
  importerID: ImporterID.Pandora,
  linkType: LinkType.pandora,
  musicAPIID: MusicAPIIntegrationID.Pandora,
  shortName: 'Pandora',
  name: 'Pandora',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: true,
  isSupported: true,
  color: { label: '#0463B1', background: '#5CB4FC' },
  tracksPerPlaylistLimit: 5000,
};
