import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TypedMemo } from '../../../common/types';
import { ReactComponent as BlueWave } from '../../../../assets/images/smartLinks/wave-blue-stats.svg';
import { ReactComponent as PinkWave } from '../../../../assets/images/smartLinks/wave-pink-stats.svg';
import { ClickableDiv } from '../../../common/views/ClickableDiv';
import { SMLStatsBoxProps } from './props';

const SMLStatsBoxComponent: React.FC<React.PropsWithChildren<SMLStatsBoxProps>> = ({
  count,
  onClick,
  waveColor,
  children,
  icon,
}) => {
  const Wave = waveColor === 'pink' ? PinkWave : BlueWave;
  return (
    <ClickableDiv onClick={onClick} className="sml-box position-relative d-flex flex-column shadow">
      <div className="sml-stats-box-waves d-flex justify-content-center flex-column">
        <div className="sml-stats-box-content d-flex flex-column justify-content-center h-100">
          <h1 className="text-center">{count}</h1>
          <div className="d-flex justify-content-center align-items-center">
            <FontAwesomeIcon icon={icon} className="me-2" />
            <span>{children}</span>
          </div>
        </div>
        <Wave className="sml-stats-box-wave" />
      </div>
    </ClickableDiv>
  );
};

export const SMLStatsBox = TypedMemo(SMLStatsBoxComponent);
