import { getCookie, setCookie } from 'typescript-cookie';
import { v4 as uuidv4 } from 'uuid';
import { config } from '../../../config/config';

const cookieKey = 'anonymousIdentifierV1';
// eslint-disable-next-line @typescript-eslint/require-await
export const anonymousIdentifier = async () => {
  const existingID = getCookie(cookieKey);
  if (existingID) {
    return existingID;
  }
  const newID = uuidv4().toString();
  setCookie(cookieKey, newID, { domain: config.smartLinkCookieDomain });
  return newID;
};
