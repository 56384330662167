import { OnlineStatusListener } from './types';

export const onlineStatusListener: OnlineStatusListener = (setOnline, setOffline) => {
  if (navigator.onLine) {
    setOnline();
  } else {
    setOffline();
  }
  window.addEventListener('offline', setOffline);
  window.addEventListener('online', setOnline);

  return () => {
    window.removeEventListener('offline', setOffline);
    window.removeEventListener('online', setOnline);
  };
};
