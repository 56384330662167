import { getContinuationToken, getShareID } from '../youTubeMusicParser';
import { YouTubeMusicPlaylistTrack } from './YouTubeMusicPlaylistTrack';

export class YouTubeMusicPlaylistTracksResponse {
  private readonly data: any;

  private readonly items: any;

  private readonly headerData: any;

  public readonly isOwnPlaylist: boolean;

  constructor(data: any, items: any, isOwnPlaylist: any, headerData: any) {
    this.data = data;
    this.items = items;
    this.headerData = headerData;
    this.isOwnPlaylist = isOwnPlaylist;
  }

  get tracks(): YouTubeMusicPlaylistTrack[] {
    if (!this.items) {
      return [];
    }
    return this.items
      .map((item: any) => YouTubeMusicPlaylistTrack.fromData(item, this.isOwnPlaylist))
      .filter((track: YouTubeMusicPlaylistTrack | null) => !!track);
  }

  get continuationToken() {
    return getContinuationToken(this.data);
  }

  get shareId(): string | undefined {
    if (!this.headerData) {
      return undefined;
    }
    return getShareID(this.headerData);
  }
}
