import React from 'react';
import { Modal } from 'reactstrap';
import { useIntl } from 'react-intl';
import { SMLPublishingModalRendererProps } from './props';
import { TypedMemo } from '../../../types';
import { ReactComponent as CustomSmartLinkLogo } from '../../../../../assets/images/smartLinks/custom-smart-link-logo.svg';
import { boldIntlText } from '../../bold/boldIntlText';
import { SMLButton } from '../../SMLButton/SMLButton';

const SMLPublishingModalRendererComponent: React.FC<SMLPublishingModalRendererProps> = ({
  isOpen,
  closeModal,
  smartLinkName,
  openSmartLink,
}) => {
  const intl = useIntl();

  return (
    <Modal isOpen={isOpen} toggle={closeModal} centered className="sml-modal sml-modal-small">
      <div className="sml-modal-content px-4 py-5">
        <div className="d-flex flex-column align-items-center mb-3">
          <div className="custom-sml-logo-container p-3 mb-3">
            <CustomSmartLinkLogo />
          </div>
        </div>
        <h4 className="text-center pt-2 pb-3 ui-text text-primary">
          {intl.formatMessage({
            id: 'app.sml.modals.sml_publishing.title',
            defaultMessage: 'Your Smart Link is live now!',
          })}
        </h4>
        <p className="text-center ui-text mb-4 lead">
          {intl.formatMessage(
            {
              id: 'app.sml.modals.sml_publishing.subtitle',
              defaultMessage: '<bold>{smartLinkName}</bold> was published successfully. You can check it out!',
            },
            {
              smartLinkName,
              bold: boldIntlText,
            }
          )}
        </p>
        <div className="d-flex justify-content-around">
          <SMLButton color="dark" onClick={closeModal} outline size="lg">
            {intl.formatMessage({
              id: 'app.sml.modals.sml_publishing.not_now',
              defaultMessage: `Not now`,
            })}
          </SMLButton>
          <SMLButton color="primary" onClick={openSmartLink} size="lg">
            {intl.formatMessage({
              id: 'app.sml.modals.sml_publishing.go_to_smartlink',
              defaultMessage: `Go to Smart Link`,
            })}
          </SMLButton>
        </div>
      </div>
    </Modal>
  );
};

export const SMLPublishingModalRenderer = TypedMemo(SMLPublishingModalRendererComponent);
