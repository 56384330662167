export class JioSaavnUser {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get name(): string {
    return [this.firstName, this.lastName].filter((n) => !!n).join(' ') || this.uid;
  }

  get firstName(): string {
    return this.data.user.firstname;
  }

  get lastName(): string {
    return this.data.user.lastname;
  }

  get uid(): string {
    return this.data.user.uid;
  }

  get image(): string {
    return this.data.user.image;
  }

  get songIds(): string[] {
    return this.data.song;
  }
}
