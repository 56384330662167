import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../../common/types';
import { SMLChangePasswordViewRenderer } from './SMLChangePasswordViewRenderer';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { useQueryParam } from '../../../utils/useQueryParam';
import { useUserTokenDecoded } from '../../../redux/slices/appInfo/hooks';
import { SMLSetUpContainer } from '../smlSetUpContainer/SMLSetUpContainer';

const SMLChangePasswordViewComponent: React.FC = () => {
  const intl = useIntl();
  const router = useSMLRouter();
  const routeTokenString = useQueryParam('token', undefined, '');
  const authToken = routeTokenString !== '' ? routeTokenString : undefined;
  const userTokenDecoded = useUserTokenDecoded();
  const userId = userTokenDecoded?.id ?? undefined;

  const goToLogIn = useCallback(() => {
    router.replace(SMLRoutes.login);
  }, [router]);

  return (
    <SMLSetUpContainer
      title={intl.formatMessage({ id: 'app.sml.change_password.title', defaultMessage: 'Password Change' })}
    >
      <SMLChangePasswordViewRenderer authToken={authToken} userId={userId} goToLogIn={goToLogIn} />
    </SMLSetUpContainer>
  );
};

export const SMLChangePasswordView = TypedMemo(SMLChangePasswordViewComponent);
