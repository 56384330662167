import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { tryParseInt } from '../../../utils/tryParseInt';
import { buildCoverUrl, buildCoverUrlFromMetaImage, createAnghamiAlbumUrl } from '../utils';
import { getMetaContentByProperty, getScriptsContents } from '../../../utils/htmlUtils';
import { tryParseJSON } from '../../../utils/tryParseJSON';

export class AnghamiAlbum extends GenericCollection {
  constructor(data: Omit<CollectionModelData, 'type'>) {
    super({ type: CollectionType.ALBUM, ...data });
  }

  static fromData(data: any): AnghamiAlbum | null {
    if (!data?.id || !data?.title) {
      return null;
    }
    return new AnghamiAlbum({
      rawId: data.id,
      name: data.title,
      itemCount: tryParseInt(data.nbrsongs),
      additionalData: { imageUrl: buildCoverUrl(data.coverArt), artist: data.artistname },
      publicUrl: createAnghamiAlbumUrl(data.id),
    });
  }

  static fromShareURLData(props: {
    HTMLString: string;
    url: string;
    metaElements: HTMLCollectionOf<HTMLMetaElement>;
  }): AnghamiAlbum | null {
    const { HTMLString, url, metaElements } = props;
    const scriptsContents = getScriptsContents(HTMLString);
    const albumDataString = scriptsContents.find(
      (content) => content.includes('context') && content.includes('MusicAlbum')
    );
    const data = tryParseJSON(albumDataString);
    const rawId = data?.url?.split('/').pop();
    const name = data?.name;
    if (!rawId || !name) {
      return null;
    }

    const metaImageUrl = getMetaContentByProperty(metaElements, 'og:image');
    const followers = HTMLString.match(/followers&q;:&q;(.*?)(&q)/)?.[1];

    return new AnghamiAlbum({
      rawId,
      name,
      itemCount: data.numtracks ?? data.track?.length,
      additionalData: {
        imageUrl: buildCoverUrlFromMetaImage(metaImageUrl),
        artist: data.byArtist?.name,
      },
      followers: tryParseInt(followers),
      publicUrl: data.url ?? url,
    });
  }
}
