import { MusicService } from './types';
import { ImporterID } from '../../importers/types';
import { MusicAPIIntegrationID } from '../types';
import { LinkType } from '../../utils/recognizeLinkTypeByUrl';

export const napster: MusicService = {
  id: 'napster',
  importerID: ImporterID.Napster,
  linkType: LinkType.napster,
  musicAPIID: MusicAPIIntegrationID.Napster,
  shortName: 'Napster',
  name: 'Napster',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: true,
  isSupported: true,
  color: { label: '#2190C4', background: '#2190C466' },
};
