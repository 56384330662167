import { RessoPlayList } from './RessoPlaylist';
import { RessoPlaylistsRawResponse } from './RessoResponseModels';

export class RessoPlaylistsResponse {
  private readonly data;

  constructor(data: RessoPlaylistsRawResponse | undefined) {
    this.data = data;
  }

  get playlists(): RessoPlayList[] {
    if (!this.data?.items) {
      return [];
    }
    return this.data.items
      .map((item) => RessoPlayList.fromData(item))
      .filter((playlist): playlist is RessoPlayList => !!playlist);
  }

  get nextCursor() {
    return this.data?.next_cursor;
  }

  get total() {
    return this.data?.total;
  }
}
