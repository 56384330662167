import React, { PropsWithChildren } from 'react';
import { Col, Row } from 'reactstrap';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../../../common/types';
import { SMLFormContainer } from '../../SMLFormContainer/SMLFormContainer';
import { SMLRoutes } from '../../../../routing/sml/SMLRoutes';
import ImageExampleMrEaziBestOf from '../../../../assets/images/smartLinks/playlistlink-example-form.png';
import { PlaylistLinkWizardFormViewRendererProps } from './props';

const PlaylistLinkWizardFormViewRendererComponent: React.FC<
  PropsWithChildren<PlaylistLinkWizardFormViewRendererProps>
> = ({ children, onSubmit, error, isLoading }) => {
  const intl = useIntl();

  return (
    <SMLFormContainer
      backRoute={SMLRoutes.home}
      title={intl.formatMessage({
        id: 'app.smart_links.playlist_smart_link.form_title',
        defaultMessage: 'Create Playlist Link',
      })}
      error={error}
      isLoading={isLoading}
      submitButtonColor="secondary"
      submitButtonTitle={intl.formatMessage({
        id: 'app.forms.playlist_link.buttons.preview',
        defaultMessage: 'Preview',
      })}
      onSubmit={onSubmit}
    >
      <Row>
        <Col md={8}>{children}</Col>
        <Col md={4} className="d-none d-md-flex">
          <img src={ImageExampleMrEaziBestOf} className="img-fluid" alt="mr eazi example" />
        </Col>
      </Row>
    </SMLFormContainer>
  );
};

export const PlaylistLinkWizardFormViewRenderer = TypedMemo(PlaylistLinkWizardFormViewRendererComponent);
