import { debounce, DebounceSettings } from 'lodash';

export function asyncDebounce<T, P extends Array<A>, A>(
  func: (...args: P) => Promise<T>,
  wait?: number,
  options?: DebounceSettings
): (...args: P) => Promise<T> {
  let resolve: (result: T) => void | undefined;
  const finalFunc = async (...args: P) => {
    const result = await func(...args);
    resolve?.(result);
  };
  const debouncedFunc = debounce(finalFunc, wait, options);
  return (...args: P) => {
    return new Promise<T>((res) => {
      resolve = res;
      debouncedFunc(...args)?.catch(console.error);
    });
  };
}
