import { AlbumsResponse } from './types';
import { AmazonMusicAlbum } from './AmazonMusicAlbum';

export class AmazonMusicAlbumsResponse {
  private readonly data;

  constructor(data: AlbumsResponse | undefined) {
    this.data = data;
  }

  get albums() {
    return (
      this.data?.data?.albums
        ?.map((albumData) => AmazonMusicAlbum.fromData(albumData))
        .filter((album): album is AmazonMusicAlbum => !!album) ?? []
    );
  }
}
