import { BaseObject } from '../../../universal/BaseObject';

export enum PandoraTier {
  AdSupported = 'AD_SUPPORTED',
  Plus = 'PANDORA_PLUS',
  PremiumFamilyPlan = 'PREMIUM_FAMILY_PLAN',
  Premium = 'PANDORA_PREMIUM',
}

export class PandoraBillingInfo extends BaseObject {
  get id(): string {
    return this.privateData.data[0].id;
  }

  get activeProductTier(): PandoraTier {
    return this.privateData.activeProduct.productTier;
  }
}

// const exampleOfNonSubscription = {
//   subscriber: false,
//   giftee: false,
//   inPaymentBackedTrial: false,
//   activeProduct: {
//     billingTerritory: 'US',
//     productTier: 'AD_SUPPORTED',
//     productType: 'PERSISTENT',
//     discountType: 'NONE',
//     durationType: 'NONE',
//     durationTime: 0,
//     price: 0,
//     acceptedCurrency: 'USD',
//     paymentProviderType: 'NONE',
//     paymentProvider: 'NONE',
//     displayablePaymentProvider: '',
//     productDetails: { ipg: 'false', familyPlanType: 'NONE', productDescription: '' }
//   },
//   pendingProducts: [],
//   autoRenew: true,
//   paymentProvider: 'NONE',
//   displayablePaymentProvider: '',
//   paymentProviderType: 'NONE',
//   ipgEligible: false
// };

// const examplePandoraPlus = {
//   subscriber: true,
//   giftee: false,
//   inPaymentBackedTrial: true,
//   activeProduct: {
//     billingTerritory: 'US',
//     productTier: 'PANDORA_PLUS',
//     productType: 'TRIAL_SUBSCRIPTION',
//     discountType: 'NONE',
//     durationType: 'DAILY',
//     durationTime: 30,
//     expiresIfPurchasedNow: '2020-03-29T06:42:30.913-07:00',
//     price: 0,
//     acceptedCurrency: 'USD',
//     paymentProviderType: 'DIRECT_BILLED',
//     paymentProvider: 'CREDIT_CARD',
//     displayablePaymentProvider: 'credit card billing by Pandora',
//     timesRenewable: 0,
//     productDetails: { ipg: 'false', familyPlanType: 'NONE', productDescription: '30 free days of Pandora Plus' }
//   },
//   pendingProducts: [
//     {
//       billingTerritory: 'US',
//       productTier: 'PANDORA_PLUS',
//       productType: 'SUBSCRIPTION',
//       discountType: 'NONE',
//       durationType: 'MONTHLY',
//       durationTime: 1,
//       expiresIfPurchasedNow: '2020-03-28T06:42:30.913-07:00',
//       price: 499,
//       acceptedCurrency: 'USD',
//       paymentProviderType: 'DIRECT_BILLED',
//       paymentProvider: 'CREDIT_CARD',
//       displayablePaymentProvider: 'credit card billing by Pandora',
//       productDetails: { ipg: 'false', familyPlanType: 'NONE', productDescription: 'Pandora Plus monthly subscription' }
//     }
//   ],
//   autoRenew: true,
//   renewalsRemaining: 0,
//   nextBillingDate: '2020-03-29T06:42:28.912-07:00',
//   daysUntilNextBillingDate: 29,
//   paymentProvider: 'CREDIT_CARD',
//   displayablePaymentProvider: 'credit card billing by Pandora',
//   paymentProviderType: 'DIRECT_BILLED',
//   trialEndDate: '2020-03-29T06:42:29.342-07:00',
//   ipgEligible: false
// };

// const examplePandoraPremium = {
//   subscriber: true,
//   giftee: false,
//   inPaymentBackedTrial: true,
//   activeProduct: {
//     billingTerritory: 'US',
//     productTier: 'PANDORA_PREMIUM',
//     productType: 'TRIAL_SUBSCRIPTION',
//     discountType: 'NONE',
//     durationType: 'DAILY',
//     durationTime: 30,
//     expiresIfPurchasedNow: '2020-03-29T06:43:22.083-07:00',
//     price: 0,
//     acceptedCurrency: 'USD',
//     paymentProviderType: 'DIRECT_BILLED',
//     paymentProvider: 'CREDIT_CARD',
//     displayablePaymentProvider: 'credit card billing by Pandora',
//     timesRenewable: 0,
//     productDetails: { ipg: 'false', familyPlanType: 'NONE', productDescription: '30 free days of Pandora Premium' }
//   },
//   pendingProducts: [
//     {
//       billingTerritory: 'US',
//       productTier: 'PANDORA_PREMIUM',
//       productType: 'SUBSCRIPTION',
//       discountType: 'NONE',
//       durationType: 'MONTHLY',
//       durationTime: 1,
//       expiresIfPurchasedNow: '2020-03-28T06:43:22.083-07:00',
//       price: 999,
//       acceptedCurrency: 'USD',
//       paymentProviderType: 'DIRECT_BILLED',
//       paymentProvider: 'CREDIT_CARD',
//       displayablePaymentProvider: 'credit card billing by Pandora',
//       productDetails: {
//         ipg: 'false',
//         familyPlanType: 'NONE',
//         productDescription: 'Pandora Premium monthly subscription'
//       }
//     }
//   ],
//   autoRenew: true,
//   renewalsRemaining: 0,
//   nextBillingDate: '2020-03-29T06:43:20.082-07:00',
//   daysUntilNextBillingDate: 29,
//   paymentProvider: 'CREDIT_CARD',
//   displayablePaymentProvider: 'credit card billing by Pandora',
//   paymentProviderType: 'DIRECT_BILLED',
//   trialEndDate: '2020-03-29T06:43:20.184-07:00',
//   ipgEligible: false
// };
