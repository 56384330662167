import { ImporterID } from 'fym-common/src/importers/types';
import { getAllImporters } from 'fym-common/src/importers';
import { amazonImages } from './modules/amazonMusic/importerImages';
import { anghamiImages } from './modules/anghami/importerImages';
import { appleMusicImages } from './modules/appleMusic/importerImages';
import { deezerImages } from './modules/deezer/importerImages';
import { fileImages } from './modules/file/importerImages';
import { gaanaImages } from './modules/gaana/importerImages';
import { jioSaavnImages } from './modules/jioSaavn/importerImages';
import { napsterImages } from './modules/napster/importerImages';
import { pandoraImages } from './modules/pandora/importerImages';
import { qobuzImages } from './modules/qobuz/importerImages';
import { qqMusicImages } from './modules/QQMusic/importerImages';
import { ressoImages } from './modules/resso/importerImages';
import { soundCloudImages } from './modules/soundCloud/importerImages';
import { spotifyImages } from './modules/spotify/importerImages';
import { tidalImages } from './modules/tidal/importerImages';
import { vkImages } from './modules/vk/importerImages';
import { yandexImages } from './modules/yandex/importerImages';
import { youTubeImages } from './modules/youtube/importerImages';
import { youTubeMusicImages } from './modules/youtubeMusic/importerImages';
import { zvukImages } from './modules/zvuk/importerImages';
import { boomplayImages } from './modules/boomplay/importerImages';
import { ImporterImages } from './generics/ImporterImages';

export const allImportersImagesById: Record<ImporterID, ImporterImages> = {
  [ImporterID.Spotify]: spotifyImages,
  [ImporterID.Youtube]: youTubeImages,
  [ImporterID.YouTubeMusic]: youTubeMusicImages,
  [ImporterID.AppleMusic]: appleMusicImages,
  [ImporterID.AmazonMusic]: amazonImages,
  [ImporterID.Tidal]: tidalImages,
  [ImporterID.Pandora]: pandoraImages,
  [ImporterID.Deezer]: deezerImages,
  [ImporterID.SoundCloud]: soundCloudImages,
  [ImporterID.Qobuz]: qobuzImages,
  [ImporterID.QQMusic]: qqMusicImages,
  [ImporterID.Yandex]: yandexImages,
  [ImporterID.Anghami]: anghamiImages,
  [ImporterID.Napster]: napsterImages,
  [ImporterID.VK]: vkImages,
  [ImporterID.Zvuk]: zvukImages,
  [ImporterID.Gaana]: gaanaImages,
  [ImporterID.JioSaavn]: jioSaavnImages,
  [ImporterID.Resso]: ressoImages,
  [ImporterID.Boomplay]: boomplayImages,
  [ImporterID.File]: fileImages,
};

export const allImportersConstsById = (() => {
  return getAllImporters().reduce((result, importer) => {
    result[importer.id] = {
      name: importer.name,
      color: importer.musicService.color,
      images: allImportersImagesById[importer.id],
    };
    return result;
  }, {} as Record<ImporterID, { name: string; color: { label: string; background: string }; images: ImporterImages }>);
})();
