const pandoraPublicResourceUrl = 'https://www.pandora.com';

export function createPandoraPublicUrl(shareableUrlPath: string | undefined) {
  if (!shareableUrlPath) {
    return undefined;
  }
  return `${pandoraPublicResourceUrl}${shareableUrlPath}`;
}

export function createPandoraImageUrl(artUrl: string | undefined) {
  if (!artUrl) {
    return undefined;
  }
  return `https://content-images.p-cdn.com/${artUrl}`;
}
