import React, { useCallback, useMemo, useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, UncontrolledDropdown } from 'reactstrap';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SerializedAccountForList } from 'fym-common/src/db/serializersTypes';
import { TypedMemo } from '../../common/types';
import { useGetAccountsQuery } from '../../../redux/api/fymAPI';
import { useAccountId, useUserToken } from '../../../redux/slices/appInfo/hooks';
import { setAccountId } from '../../../redux/slices/appInfo/slice';
import { UserAvatar } from '../../common/views/userAvatar/UserAvatar';
import { useOnLogout } from '../../../redux/slices/appInfo/useOnLogout';
import { DownloadLinks } from '../downloadLinks/DownloadLinks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { useSMLRouter } from '../../../routing/sml/hooks';

interface AccountSelectorButtonProps {
  closeNavbar: () => void;
}

interface AccountSelectorButtonItemProps {
  account: SerializedAccountForList;
  selected: boolean;
  closeNavbar: AccountSelectorButtonProps['closeNavbar'];
}

const AccountSelectorButtonItem: React.FC<AccountSelectorButtonItemProps> = ({ account, selected, closeNavbar }) => {
  const dispatch = useDispatch();
  const router = useSMLRouter();

  const onClick = useCallback(() => {
    closeNavbar();
    router.push(SMLRoutes.home);
    dispatch(setAccountId({ accountId: account.id }));
  }, [account.id, dispatch, router, closeNavbar]);

  return (
    <DropdownItem disabled={selected} onClick={onClick}>
      <div className="d-flex align-items-center">
        <UserAvatar size={25} url={account.avatarProcessedUrl} className="me-2" />
        {account.name}
      </div>
    </DropdownItem>
  );
};

const AccountSelectorButtonComponent: React.FC<AccountSelectorButtonProps> = ({ closeNavbar }) => {
  const intl = useIntl();
  const router = useSMLRouter();
  const userToken = useUserToken();
  const accountId = useAccountId();
  const onLogout = useOnLogout();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const onCloseModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const onOpenModal = useCallback(() => {
    closeNavbar();
    setModalOpen(true);
  }, [setModalOpen, closeNavbar]);

  const { accounts, isLoading: isAccountsLoading } = useGetAccountsQuery(undefined, {
    skip: !userToken,
    selectFromResult: ({ data, isLoading, error }) => {
      return {
        accounts: data?.accounts,
        isLoading,
        error,
      };
    },
  });

  const handleCreateAccount = () => {
    router.replace(SMLRoutes.onboarding1);
  };

  const selectedAccount = useMemo(
    () => (accountId !== null ? accounts?.find((a) => a.id === accountId) : undefined),
    [accountId, accounts]
  );
  const accountsWithoutCurrentOne = useMemo(() => accounts?.filter((a) => a.id !== accountId), [accountId, accounts]);

  const goToAccountSettingsView = useCallback(() => {
    closeNavbar();
    router.push(SMLRoutes.account);
  }, [router, closeNavbar]);
  const goToAccountTrackersView = useCallback(() => {
    closeNavbar();
    router.push(SMLRoutes.trackers);
  }, [router, closeNavbar]);

  if (isAccountsLoading) {
    return null;
  }

  return (
    <>
      <Modal isOpen={isModalOpen} toggle={onCloseModal} centered>
        <ModalBody>
          <DownloadLinks />
        </ModalBody>
      </Modal>
      <UncontrolledDropdown nav className="ms-2">
        <DropdownToggle color="primary" outline>
          {selectedAccount ? (
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faUserCircle} className="me-2" />
              {selectedAccount.name}
            </div>
          ) : (
            intl.formatMessage({
              id: 'web.nav.smartlinks.accountSelector',
              defaultMessage: 'Account',
            })
          )}
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem onClick={goToAccountSettingsView}>
            {intl.formatMessage({
              id: 'web.nav.smart-links.navbar.edit-profile',
              defaultMessage: 'Edit Profile',
            })}
          </DropdownItem>
          <DropdownItem onClick={goToAccountTrackersView}>
            {intl.formatMessage({
              id: 'web.nav.smart-links.navbar.account-trackers',
              defaultMessage: 'Manage Trackers',
            })}
          </DropdownItem>
          <DropdownItem divider />
          {accountsWithoutCurrentOne && accountsWithoutCurrentOne.length > 0 && (
            <DropdownItem header>
              {intl.formatMessage({
                id: 'web.nav.smartlinks.switch-profile',
                defaultMessage: 'Switch Profile',
              })}
              :
            </DropdownItem>
          )}
          {accountsWithoutCurrentOne?.map((account) => {
            return (
              <AccountSelectorButtonItem
                key={account.id}
                account={account}
                selected={account.id === accountId}
                closeNavbar={closeNavbar}
              />
            );
          })}
          {accountsWithoutCurrentOne && accountsWithoutCurrentOne.length > 0 && <DropdownItem divider />}
          <DropdownItem onClick={handleCreateAccount}>
            <div className="d-flex align-items-center">
              {intl.formatMessage({
                id: 'web.nav.smartlinks.profile-account',
                defaultMessage: 'Create New Profile',
              })}
            </div>
          </DropdownItem>
          <DropdownItem onClick={onOpenModal}>
            {intl.formatMessage({
              id: 'web.nav.smartlinks.download-apps',
              defaultMessage: 'Download Apps',
            })}
          </DropdownItem>
          <DropdownItem onClick={onLogout}>
            {intl.formatMessage({
              id: 'web.nav.smartlinks.logout',
              defaultMessage: 'Log Out',
            })}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export const AccountSelectorButton = TypedMemo(AccountSelectorButtonComponent);
