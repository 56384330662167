import { SpotifyAlbum } from './SpotifyAlbum';
import { SpotifyCollectionTrack } from './SpotifyCollectionTrack';
import { SpotifyAlbumTracksResponseType } from './types';

export class SpotifyAlbumTracksResponse {
  private readonly data;

  private readonly album;

  constructor(data: SpotifyAlbumTracksResponseType | undefined, album: SpotifyAlbum) {
    this.data = data;
    this.album = album;
  }

  get tracks(): SpotifyCollectionTrack[] {
    if (!this.data?.items) {
      return [];
    }
    return this.data.items
      .map((item) => SpotifyCollectionTrack.fromAlbumData(item, this.album))
      .filter((track): track is SpotifyCollectionTrack => !!track);
  }

  get next() {
    return this.data?.next;
  }
}
