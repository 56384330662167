import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { createYandexPlaylistUrl } from '../utils';

export class YandexPlaylist extends GenericCollection {
  public revision;

  constructor(data: CollectionModelData, revision: number) {
    super(data);
    this.revision = revision;
  }

  static fromData(data: any, playlistType: CollectionType, url?: string): YandexPlaylist | null {
    if ((!data?.uid && !data.kind) || !data.title) {
      return null;
    }
    return new YandexPlaylist(
      {
        type: playlistType,
        rawId: data.uid ? `${data.uid}:${data.kind}` : `${data.kind}`,
        name: data.title,
        itemCount: data.trackCount,
        followers: data.likesCount,
        publicUrl: url ?? createYandexPlaylistUrl(data.owner?.login, data.kind),
      },
      data.revision
    );
  }
}
