import qs from 'qs';
import { defaultRetryOptions, fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { SpotifyUser } from './models/SpotifyUser';
import { SpotifyPlaylist } from './models/SpotifyPlaylist';
import { SpotifyPlaylistsResponse } from './models/SpotifyPlaylistsResponse';
import { SpotifyAlbumsResponse } from './models/SpotifyAlbumsResponse';
import { SpotifyAlbum } from './models/SpotifyAlbum';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { SpotifyCollectionTrack } from './models/SpotifyCollectionTrack';
import { SpotifyPlaylistTracksResponse } from './models/SpotifyPlaylistTracksResponse';
import { SpotifyAlbumTracksResponse } from './models/SpotifyAlbumTracksResponse';
import { SpotifySearchResponse } from './models/SpotifySearchResponse';
import { FetchError } from '../../generics/errors/FetchError';
import { SpotifyMySongsResponse } from './models/SpotifyMySongsResponse';
import { SearchQueryProperties } from '../../generics/types';
import { constructSearchQuery } from './utils';
import { SpotifyTopSongsResponse } from './models/SpotifyTopSongsResponse';
import { SpotifyTopArtistsResponse } from './models/SpotifyTopArtistsResponse';
import { SpotifyArtist } from './models/SpotifyArtist';
import { ImporterID } from '../types';
import { getScriptsContents } from '../../utils/htmlUtils';
import { Response } from '../../utils/fetch-types';
import { MusicServiceError, MusicServiceErrorType } from '../../errors/musicServiceError/MusicServiceError';

export enum SpotifyAPITimeRange {
  OneMonth = 'short_term',
  HalfYear = 'medium_term',
  AllTime = 'long_term',
}

export class SpotifyAPI {
  public static BASE_URL = 'https://api.spotify.com/v1/';

  public readonly userId: string;

  private readonly accessToken: string;

  constructor(props: { accessToken: string; userId: string }) {
    this.accessToken = props.accessToken;
    this.userId = props.userId;
  }

  private get requestHeaders(): Record<string, string> {
    return {
      Accept: 'application/json; charset=utf-8',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.accessToken}`,
    };
  }

  public static async getPublicAccessToken(url = 'https://open.spotify.com/'): Promise<string> {
    const response = await fetchRetry(url, {
      method: 'GET',
      headers: {
        pragma: 'no-cache',
        'cache-control': 'no-cache',
        accept:
          'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
      },
    });

    const HTMLString = await response.text();
    const scriptsContents = getScriptsContents(HTMLString);
    const config = scriptsContents.find((content) => content.includes('accessToken'));

    if (!config) {
      throw new Error('Could not get config from Spotify in getPublicAccessToken');
    }

    let accessToken: string | undefined;
    try {
      ({ accessToken } = JSON.parse(config));
    } catch (error) {
      console.error(error);
    }

    if (!accessToken) {
      throw new Error('Could not get Spotify accessToken in getPublicAccessToken');
    }

    return accessToken;
  }

  async loadPaginatedPlaylistItems(
    id: string,
    onBatch: (tracks: SpotifyCollectionTrack[]) => Promise<void>
  ): Promise<void> {
    const limit = 30;
    const loadPlaylistsItems = async (offset: number): Promise<void> => {
      const trackResponse = await this.loadPlaylistItemsPage(id, offset, limit);
      await onBatch(trackResponse.tracks);
      if (trackResponse.next) {
        await loadPlaylistsItems(offset + limit);
      }
    };

    await loadPlaylistsItems(0);
  }

  async loadPlaylistItemsPage(id: string, offset: number, limit: number): Promise<SpotifyPlaylistTracksResponse> {
    const url = `${SpotifyAPI.BASE_URL}playlists/${id}/tracks?${qs.stringify({
      offset,
      additional_types: 'track',
      market: 'from_token',
      limit,
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading tracks for ${id} in Spotify[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new SpotifyPlaylistTracksResponse(data);
  }

  async loadAllPlaylists(): Promise<SpotifyPlaylist[]> {
    const limit = 30;
    let playlists: SpotifyPlaylist[] = [];
    const loadPlaylists = async (offset: number): Promise<void> => {
      const playlistResponse = await this.loadPlaylistPage(offset, limit);
      playlists = [...playlists, ...playlistResponse.playlists];
      if (playlistResponse.next) {
        await loadPlaylists(offset + limit);
      }
    };

    await loadPlaylists(0);
    return playlists;
  }

  async loadPaginatedPlaylists(onBatch: (playlists: SpotifyPlaylist[]) => Promise<void>): Promise<void> {
    const limit = 30;
    const loadPlaylists = async (offset: number): Promise<void> => {
      const playlistResponse = await this.loadPlaylistPage(offset, limit);
      await onBatch(playlistResponse.playlists);
      if (playlistResponse.next) {
        await loadPlaylists(offset + limit);
      }
    };

    await loadPlaylists(0);
  }

  async loadPlaylistPage(offset: number, limit: number): Promise<SpotifyPlaylistsResponse> {
    const url = `${SpotifyAPI.BASE_URL}users/${this.userId}/playlists?${qs.stringify({
      offset,
      limit,
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading playlists in Spotify[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new SpotifyPlaylistsResponse(data, this.userId);
  }

  async loadPaginatedMySongs(onBatch: (tracks: SpotifyCollectionTrack[]) => Promise<void>): Promise<void> {
    const limit = 30;
    const loadMySongs = async (offset: number): Promise<void> => {
      const playlistResponse = await this.loadMySongsPage(offset, limit);
      await onBatch(playlistResponse.tracks);
      if (playlistResponse.next) {
        await loadMySongs(offset + limit);
      }
    };

    await loadMySongs(0);
  }

  async loadMySongsPage(offset: number, limit: number): Promise<SpotifyMySongsResponse> {
    const url = `${SpotifyAPI.BASE_URL}me/tracks?${qs.stringify({
      offset,
      additional_types: 'track',
      market: 'from_token',
      limit,
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading my songs in Spotify[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new SpotifyMySongsResponse(data);
  }

  async loadAllTopSongs(timeRange: SpotifyAPITimeRange): Promise<SpotifyCollectionTrack[]> {
    const limit = 30;
    let tracks: SpotifyCollectionTrack[] = [];
    const loadMySongs = async (offset: number): Promise<void> => {
      const playlistResponse = await this.loadTopSongsPage(timeRange, offset, limit);
      tracks = [...tracks, ...playlistResponse.tracks];
      if (playlistResponse.next) {
        await loadMySongs(offset + limit);
      }
    };

    await loadMySongs(0);
    return tracks;
  }

  async loadTopSongsPage(
    timeRange: SpotifyAPITimeRange,
    offset: number,
    limit: number
  ): Promise<SpotifyTopSongsResponse> {
    const url = `${SpotifyAPI.BASE_URL}me/top/tracks?${qs.stringify({
      offset,
      time_range: timeRange,
      limit,
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading top songs in Spotify[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new SpotifyTopSongsResponse(data);
  }

  async loadAllTopArtists(timeRange: SpotifyAPITimeRange): Promise<SpotifyArtist[]> {
    const limit = 30;
    let artists: SpotifyArtist[] = [];
    const loadTopArtists = async (offset: number): Promise<void> => {
      const playlistResponse = await this.loadTopArtistsPage(timeRange, offset, limit);
      artists = [...artists, ...playlistResponse.artists];
      if (playlistResponse.next) {
        await loadTopArtists(offset + limit);
      }
    };

    await loadTopArtists(0);
    return artists;
  }

  async loadTopArtistsPage(
    timeRange: SpotifyAPITimeRange,
    offset: number,
    limit: number
  ): Promise<SpotifyTopArtistsResponse> {
    const url = `${SpotifyAPI.BASE_URL}me/top/artists?${qs.stringify({
      offset,
      time_range: timeRange,
      limit,
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading top artists in Spotify[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new SpotifyTopArtistsResponse(data);
  }

  async loadAllAlbums(): Promise<SpotifyAlbum[]> {
    const limit = 30;
    let albums: SpotifyAlbum[] = [];
    const loadAlbums = async (offset: number): Promise<void> => {
      const albumResponse = await this.loadAlbumPage(offset, limit);
      albums = [...albums, ...albumResponse.albums];
      if (albumResponse.next) {
        await loadAlbums(offset + limit);
      }
    };

    await loadAlbums(0);
    return albums;
  }

  async loadPaginatedAlbums(onBatch: (albums: SpotifyAlbum[]) => Promise<void>): Promise<void> {
    const limit = 30;
    const loadAlbums = async (offset: number): Promise<void> => {
      const albumResponse = await this.loadAlbumPage(offset, limit);
      await onBatch(albumResponse.albums);
      if (albumResponse.next) {
        await loadAlbums(offset + limit);
      }
    };

    await loadAlbums(0);
  }

  async loadAlbumPage(offset: number, limit: number) {
    const url = `${SpotifyAPI.BASE_URL}me/albums?${qs.stringify({
      offset,
      limit,
      market: 'from_token',
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading albums in Spotify[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new SpotifyAlbumsResponse(data);
  }

  async loadPaginatedAlbumItems(
    album: SpotifyAlbum,
    onBatch: (tracks: SpotifyCollectionTrack[]) => Promise<void>
  ): Promise<void> {
    const limit = 30;
    const loadAlbumItems = async (offset: number): Promise<void> => {
      const trackResponse = await this.loadAlbumItemsPage(album, offset, limit);
      await onBatch(trackResponse.tracks);
      if (trackResponse.next) {
        await loadAlbumItems(offset + limit);
      }
    };

    await loadAlbumItems(0);
  }

  async loadAlbumItemsPage(album: SpotifyAlbum, offset: number, limit: number): Promise<SpotifyAlbumTracksResponse> {
    if (!album.additionalData?.href) {
      throw new Error('Spotify: Trying to load album items without href');
    }
    const url = `${album.additionalData.href}/tracks?${qs.stringify({
      offset,
      limit,
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading tracks for album ${album.additionalData?.href} in Spotify[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new SpotifyAlbumTracksResponse(data, album);
  }

  async fetch(url: string, options: any, retryOptions: RetryOptions = defaultRetryOptions): Promise<Response> {
    const response = await fetchRetry(url, options, retryOptions);

    if (response.status === 401) {
      const text = await response.text();
      throw new NotAuthenticatedError({ authId: this.userId, importerId: ImporterID.Spotify }, text);
    }

    return response;
  }

  static async fetchMe(
    accessToken: string,
    forceWaitForRateLimits: boolean = defaultRetryOptions.shouldWait
  ): Promise<SpotifyUser> {
    const response = await fetchRetry(
      `${SpotifyAPI.BASE_URL}me/`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json; charset=utf-8',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
      { ...defaultRetryOptions, shouldWait: forceWaitForRateLimits }
    );

    if (!response.ok) {
      const text = await response.text();
      if (text.includes('unavailable in this country')) {
        throw new MusicServiceError({
          message: text,
          errorType: MusicServiceErrorType.notAllowedInYourCountry,
        });
      }
      throw new FetchError(
        response.status,
        `When trying to fetch Spotify user got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    return new SpotifyUser(jsonBody);
  }

  async search(props: {
    queryProps: SearchQueryProperties;
    searchType: 'track' | 'album';
    advancedSearch?: boolean;
    limit?: number;
    offset?: number;
  }): Promise<SpotifySearchResponse> {
    const { queryProps, searchType, advancedSearch = true, limit = 10, offset = 0 } = props;
    const encodedQuery = constructSearchQuery(queryProps, advancedSearch);
    if (!encodedQuery) {
      return new SpotifySearchResponse(null);
    }
    const url = `${SpotifyAPI.BASE_URL}search?${qs.stringify({
      market: 'from_token',
      type: searchType,
      limit,
      offset,
    })}&q=${encodedQuery}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to search Spotify for "${encodedQuery}" got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    return new SpotifySearchResponse(jsonBody);
  }

  async addTracksToPlaylist(playlistId: string, trackIds: string[], trackPosition?: number): Promise<void> {
    const body = JSON.stringify({
      uris: trackIds.map((trackId) => `spotify:track:${trackId}`),
      position: trackPosition,
    });
    const response = await this.fetch(`${SpotifyAPI.BASE_URL}users/${this.userId}/playlists/${playlistId}/tracks`, {
      method: 'POST',
      headers: this.requestHeaders,
      body,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to add tracks (${body}) to Spotify for playlist [${playlistId}] got wrong response[${response.status}]: ${text}`
      );
    }
  }

  async addTracksToLibrary(trackIds: string[]): Promise<void> {
    const body = JSON.stringify({ ids: trackIds });
    const response = await this.fetch(`${SpotifyAPI.BASE_URL}me/tracks`, {
      method: 'PUT',
      headers: this.requestHeaders,
      body,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to add tracks (${body}) to Spotify's library got wrong response[${response.status}]: ${text}`
      );
    }
  }

  async addAlbumToLibrary(albumId: string): Promise<void> {
    const url = `${SpotifyAPI.BASE_URL}users/me/albums?${qs.stringify({
      ids: albumId,
    })}`;
    const response = await this.fetch(url, {
      method: 'PUT',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to add album [${albumId}] to Spotify library got wrong response[${response.status}]: ${text}`
      );
    }
  }

  async removeTracksFromPlaylist(playlistId: string, trackIds: string[]): Promise<void> {
    if (trackIds.length === 0) {
      return;
    }
    const body = JSON.stringify({
      tracks: trackIds.map((trackId) => ({ uri: `spotify:track:${trackId}` })),
    });
    const response = await this.fetch(`${SpotifyAPI.BASE_URL}users/${this.userId}/playlists/${playlistId}/tracks`, {
      method: 'DELETE',
      headers: this.requestHeaders,
      body,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to remove tracks (${body}) from Spotify for playlist [${playlistId}] got wrong response[${response.status}]: ${text}`
      );
    }
  }

  async moveTracksInPlaylist(
    playlistId: string,
    rangeStart: number,
    insertBefore: number,
    rangeLength: number
  ): Promise<void> {
    const body = JSON.stringify({
      range_start: rangeStart,
      insert_before: insertBefore,
      range_length: rangeLength,
    });
    const response = await this.fetch(`${SpotifyAPI.BASE_URL}playlists/${playlistId}/tracks`, {
      method: 'PUT',
      headers: this.requestHeaders,
      body,
    });
    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to move tracks (${body}) from Spotify for playlist [${playlistId}] got wrong response[${response.status}]: ${text}`
      );
    }
  }

  async createPlaylist(
    name: string,
    props?: { isPublic?: boolean; description?: string }
  ): Promise<SpotifyPlaylist | null> {
    const { isPublic = false, description } = props ?? {};
    const body = JSON.stringify({
      name,
      public: isPublic,
      description,
    });
    const response = await this.fetch(`${SpotifyAPI.BASE_URL}users/${this.userId}/playlists`, {
      method: 'POST',
      headers: this.requestHeaders,
      body,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to create playlist (${body}) on Spotify got wrong response[${response.status}]: ${text}`
      );
    }
    const data = await response.json();
    return SpotifyPlaylist.fromPlaylistCreationData(data);
  }

  async loadPlaylist(id: string): Promise<SpotifyPlaylist | null> {
    const response = await this.fetch(`${SpotifyAPI.BASE_URL}users/${this.userId}/playlists/${id}`, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to get playlist (${id}) on Spotify got wrong response[${response.status}]: ${text}`
      );
    }
    const data = await response.json();
    return SpotifyPlaylist.fromData(data, this.userId);
  }

  async doesUserFollowPlaylist(playlistId: string, userId: string): Promise<boolean> {
    const response = await this.fetch(
      `${SpotifyAPI.BASE_URL}playlists/${playlistId}/followers/contains?${qs.stringify({
        ids: userId,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders,
      }
    );

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to check if playlist ${playlistId} is followed by ${userId} on Spotify got wrong response[${response.status}]: ${text}`
      );
    }
    const data = await response.json();
    return data[0];
  }

  async doesMeAddedAlbum(albumId: string): Promise<boolean> {
    const response = await this.fetch(
      `${SpotifyAPI.BASE_URL}me/albums/contains?${qs.stringify({
        ids: albumId,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders,
      }
    );

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to check if album ${albumId} is followed by me on Spotify got wrong response[${response.status}]: ${text}`
      );
    }
    const data = await response.json();
    return data[0];
  }

  async loadAlbum(href: string): Promise<SpotifyAlbum | null> {
    const response = await this.fetch(href, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading tracks for album ${href} in Spotify[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return SpotifyAlbum.fromData(data);
  }

  async removePlaylist(playlistId: string): Promise<void> {
    const response = await this.fetch(`${SpotifyAPI.BASE_URL}playlists/${playlistId}/followers`, {
      method: 'DELETE',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to remove playlist (${playlistId}) on Spotify got wrong response[${response.status}]: ${text}`
      );
    }
  }

  async updatePlaylist(
    playlistId: string,
    { name, isPublic, description }: { name?: string; isPublic?: boolean; description?: string }
  ): Promise<void> {
    const body = JSON.stringify({ name, public: isPublic, description });
    const response = await this.fetch(`${SpotifyAPI.BASE_URL}playlists/${playlistId}`, {
      method: 'PUT',
      headers: this.requestHeaders,
      body,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to update playlist id:${playlistId}, body:(${body}) on Spotify got wrong response[${response.status}]: ${text}`
      );
    }
  }
}
