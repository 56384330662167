import { Alert as AlertRS } from 'reactstrap';
import React, { useCallback } from 'react';
import { TypedMemo } from '../../modules/common/types';
import { AlertRendererProps } from './props';

const AlertRendererComponent: React.FC<AlertRendererProps> = ({ alert, deleteAlert }) => {
  const { message, type } = alert;

  const removeAlert = useCallback(() => {
    deleteAlert(alert);
  }, [alert, deleteAlert]);

  return (
    <div className="position-absolute alert-container">
      <AlertRS color={type} className="border-0 text-body" onClick={removeAlert}>
        {message}
      </AlertRS>
    </div>
  );
};
export const AlertRenderer = TypedMemo(AlertRendererComponent);
