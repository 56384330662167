import type { ImporterID } from 'fym-common/src/importers//types';
import type { SmartLinkWidgetType } from 'fym-common/src/db/enums';

export enum SMLRouteNames {
  home = 'home',
  signup = 'signup',
  remindPassword = 'remindPassword',
  changePassword = 'changePassword',
  changePasswordLoggedIn = 'changePasswordLoggedIn',
  login = 'login',
  onboarding1 = 'onboarding1',
  onboarding2 = 'onboarding2',
  onboarding3 = 'onboarding3',
  all = 'all',
  account = 'account',
  create = 'create',
  edit = 'edit',
  duplicate = 'duplicate',
  details = 'details',
  manage = 'manage',
  widgetEdit = 'widgetEdit',
  releaseLink = 'releaseLink',
  playlistLink = 'playlistLink',
  trackers = 'trackers',
  trackerEdit = 'trackerEdit',
  // ---------------------------- Profile ----------------------------
  settings = 'settings',
  premium = 'premium',
  connectedAccounts = 'connectedAccounts',
  debug = 'debug',
  selectAccount = 'selectAccount',
  NativeOnlyWebsite = 'NativeOnlyWebsite',
}

export type SMLRouteParts = SMLRouteNames[];

type SMLRoutesParts = { [key in keyof typeof SMLRouteNames]: SMLRouteParts };

export const SMLRoutes: SMLRoutesParts = {
  [SMLRouteNames.settings]: [SMLRouteNames.settings],
  [SMLRouteNames.premium]: [SMLRouteNames.premium],
  [SMLRouteNames.connectedAccounts]: [SMLRouteNames.connectedAccounts],
  [SMLRouteNames.NativeOnlyWebsite]: [SMLRouteNames.NativeOnlyWebsite],
  [SMLRouteNames.debug]: [SMLRouteNames.debug],
  [SMLRouteNames.home]: [SMLRouteNames.home],
  [SMLRouteNames.signup]: [SMLRouteNames.signup],
  [SMLRouteNames.login]: [SMLRouteNames.login],
  [SMLRouteNames.remindPassword]: [SMLRouteNames.remindPassword],
  [SMLRouteNames.changePassword]: [SMLRouteNames.changePassword],
  [SMLRouteNames.changePasswordLoggedIn]: [SMLRouteNames.changePasswordLoggedIn],
  [SMLRouteNames.create]: [SMLRouteNames.home, SMLRouteNames.create],
  [SMLRouteNames.edit]: [SMLRouteNames.details, SMLRouteNames.edit],
  [SMLRouteNames.duplicate]: [SMLRouteNames.manage, SMLRouteNames.duplicate],
  [SMLRouteNames.details]: [SMLRouteNames.details],
  [SMLRouteNames.manage]: [SMLRouteNames.manage],
  [SMLRouteNames.widgetEdit]: [SMLRouteNames.details, SMLRouteNames.widgetEdit],
  [SMLRouteNames.releaseLink]: [SMLRouteNames.home, SMLRouteNames.releaseLink],
  [SMLRouteNames.playlistLink]: [SMLRouteNames.home, SMLRouteNames.playlistLink],
  [SMLRouteNames.trackers]: [SMLRouteNames.trackers],
  [SMLRouteNames.trackerEdit]: [SMLRouteNames.trackers, SMLRouteNames.trackerEdit],
  [SMLRouteNames.account]: [SMLRouteNames.account],
  [SMLRouteNames.onboarding1]: [SMLRouteNames.onboarding1],
  [SMLRouteNames.onboarding2]: [SMLRouteNames.onboarding2],
  [SMLRouteNames.onboarding3]: [SMLRouteNames.onboarding3],
  [SMLRouteNames.all]: [SMLRouteNames.all],
  [SMLRouteNames.selectAccount]: [SMLRouteNames.selectAccount],
};

export type SMLRootParamList = {
  [SMLRouteNames.home]: undefined;
  [SMLRouteNames.signup]: undefined;
  [SMLRouteNames.login]: undefined;
  [SMLRouteNames.remindPassword]: undefined;
  [SMLRouteNames.changePassword]: undefined;
  [SMLRouteNames.changePasswordLoggedIn]: undefined;
  [SMLRouteNames.onboarding1]: { accountId?: number };
  [SMLRouteNames.onboarding2]: undefined;
  [SMLRouteNames.onboarding3]: undefined;
  [SMLRouteNames.account]: undefined;
  [SMLRouteNames.all]: { page?: number; search?: string };
  [SMLRouteNames.create]: { accountId: number };
  [SMLRouteNames.edit]: { accountId: number; smartLinkId?: number };
  [SMLRouteNames.duplicate]: { accountId: number; smartLinkId: number };
  [SMLRouteNames.details]: { accountId: number; smartLinkId: number };
  [SMLRouteNames.manage]: { accountId: number; smartLinkId: number };
  [SMLRouteNames.widgetEdit]: {
    accountId: number;
    smartLinkId: number;
    widgetType: SmartLinkWidgetType;
    widgetUUID?: string;
  };
  [SMLRouteNames.releaseLink]: { accountId: number };
  [SMLRouteNames.playlistLink]: { accountId: number };
  [SMLRouteNames.trackers]: undefined;
  [SMLRouteNames.trackerEdit]: { accountId: number; trackerId?: number };
  // ---------------------------- Profile ----------------------------
  [SMLRouteNames.settings]: undefined;
  [SMLRouteNames.premium]: undefined;
  [SMLRouteNames.connectedAccounts]: { importerId: ImporterID };
  [SMLRouteNames.NativeOnlyWebsite]: { url: string };
  [SMLRouteNames.debug]: undefined;
  [SMLRouteNames.selectAccount]: undefined;
};
