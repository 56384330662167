import { FileAuthenticationData } from './types';

type FileAPIData = {
  sourceFilePath?: string;
  targetDirectory?: string;
};

export class FileAPI {
  private readonly sourceFilePath;

  private readonly targetDirectory;

  constructor({ sourceFilePath, targetDirectory }: FileAPIData = {}) {
    this.sourceFilePath = sourceFilePath;
    this.targetDirectory = targetDirectory;
  }

  getAuthData(): FileAuthenticationData {
    return {
      authId: `file-auth-id|${this.sourceFilePath ?? ''}|${this.targetDirectory ?? ''}`,
      userUUID: null,
      title: 'Backup',
      subTitle: null,
      imageUrl: null,
      expiresAt: null,
      additionalData: {
        sourceFilePath: this.sourceFilePath,
        targetDirectory: this.targetDirectory,
      },
    };
  }
}
