import React from 'react';
import classNames from 'classnames';
import { TypedMemo } from '../../types';
import { FYMTextProps } from './props';

const FYMTextComponent: React.FC<React.PropsWithChildren<FYMTextProps>> = ({ children, center, style }) => {
  return (
    <span className={classNames({ 'text-center': center }, 'w-100', 'text-white')} style={style}>
      {children}
    </span>
  );
};

export const FYMText = TypedMemo(FYMTextComponent);
