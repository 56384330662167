import React, { useCallback, useEffect, useMemo } from 'react';
import { useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../../../common/types';
import { FormInputContainer } from '../FormInputContainer/FormInputContainer';
import { getFullAPIFileURL } from '../../../common/getFullAPIFileURL';
import { FileLoaderRenderer } from './FileLoaderRenderer';
import { FileLoaderProps } from './props';
import { ErrorAlert } from '../../../common/views/ErrorAlert/ErrorAlert';
import { usePostFileMutation } from '../../../../redux/api/fymAPI';
import { isSuccessfulResult } from '../../../../redux/api/utils/isSuccessfulResult';

const FileLoaderComponent: React.FC<FileLoaderProps> = ({
  accountId,
  name,
  control,
  label,
  help,
  removeImage,
  disabled = false,
  onLoadingStateChange,
  readonly = false,
  roundedCircle = false,
}) => {
  const intl = useIntl();
  const { field, fieldState } = useController({ name, control });
  const { value, onChange } = field;
  const { isDirty, isTouched, invalid: error } = fieldState;
  const [postImage, { isLoading, error: postImageError }] = usePostFileMutation();

  const previewURL = useMemo(() => {
    if (!value?.url) {
      return undefined;
    }
    return value.isAbsolutePath ? value.url : getFullAPIFileURL(value.url);
  }, [value]);

  const onFileSelected = useCallback(
    (formData: FormData) => {
      postImage({ accountId, formData })
        .then((result) => {
          if (isSuccessfulResult(result)) {
            onChange(result.data);
          }
        })
        .catch(console.error);
    },
    [accountId, postImage, onChange]
  );

  useEffect(() => {
    onLoadingStateChange(isLoading);
  }, [isLoading, onLoadingStateChange]);

  return (
    <FormInputContainer
      label={label}
      help={help}
      name={name}
      error={
        error
          ? intl.formatMessage({
              id: 'app.forms.errors.empty_file',
              defaultMessage: 'File is required',
            })
          : undefined
      }
      isDirty={isDirty}
      isTouched={isTouched}
    >
      {postImageError && (
        <ErrorAlert
          customMessage={intl.formatMessage({
            id: 'app.forms.errors.file_upload',
            defaultMessage: 'Something went wrong with file upload',
          })}
        />
      )}
      <FileLoaderRenderer
        onFileSelected={onFileSelected}
        previewUrl={previewURL}
        isLoading={isLoading}
        invalid={error}
        removeImage={removeImage}
        disabled={disabled || isLoading}
        readonly={readonly}
        roundedCircle={roundedCircle}
      />
    </FormInputContainer>
  );
};

export const FileLoader = TypedMemo(FileLoaderComponent);
