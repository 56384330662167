import React from 'react';
import { TypedMemo } from '../../../../../common/types';
import { ProfileWidgetListItemProps } from './types';
import { UserAvatar } from '../../../../../common/views/userAvatar/UserAvatar';

const ProfileWidgetListItemRendererComponent: React.FC<ProfileWidgetListItemProps> = ({ account }) => {
  return (
    <div className="widget-list-item-text-container">
      <div className="smart-links-image me-2">
        <UserAvatar size={40} url={account.avatarProcessedUrl} />
      </div>
      <div className="smart-links-profile">{account.name}</div>
    </div>
  );
};

export const ProfileWidgetListItemRenderer = TypedMemo(ProfileWidgetListItemRendererComponent);
