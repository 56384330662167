import React from 'react';
import { SerializedProfileWidget } from 'fym-common/src/db/serializersTypes';
import { TypedMemo } from '../../../../../common/types';
import { WidgetListItemProps } from '../../props';
import { useAccountId } from '../../../../../../redux/slices/appInfo/hooks';
import { useGetAccountQuery } from '../../../../../../redux/api/fymAPI';
import { ProfileWidgetListItemRenderer } from './ProfileWidgetListItemRenderer';
import { LoadingIcon } from '../../../../../common/views/LoadingIcon';

const ProfileWidgetListItemComponent: React.FC<WidgetListItemProps<SerializedProfileWidget>> = () => {
  const accountId = useAccountId();
  const { data: accountResponse, isLoading: getAccountLoading } = useGetAccountQuery(
    { accountId: accountId ?? 0 },
    { skip: accountId === null }
  );

  if (getAccountLoading || accountResponse === undefined) {
    return <LoadingIcon />;
  }
  return <ProfileWidgetListItemRenderer account={accountResponse.account} />;
};

export const ProfileWidgetListItem = TypedMemo(ProfileWidgetListItemComponent);
