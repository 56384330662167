import { useCallback } from 'react';
import { generateNewDeviceId } from '../../../utils/getDeviceId';
import { store } from '../../../store/Store';
import { reloadApp } from '../../../utils/reloadApp';

export const useOnLogout = () => {
  return useCallback(() => {
    store
      .clear()
      .then(() => generateNewDeviceId())
      .then(() => reloadApp())
      .catch(console.error);
    reloadApp();
  }, []);
};
