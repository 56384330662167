import { BaseObject } from '../../../universal/BaseObject';

export class RessoUser extends BaseObject {
  get id(): string {
    return this.privateData.user_id;
  }

  get avatar(): string {
    return this.privateData.avatar.url;
  }

  get name(): string {
    return this.privateData.nickname;
  }
}
