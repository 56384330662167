import { PaddleProduct } from './PaddleProduct';

export class PaddlePricesResponse {
  readonly data: any;

  readonly products: PaddleProduct[];

  readonly productsById: { [key: number]: PaddleProduct };

  constructor(data: any) {
    this.data = data;
    this.products = this.data.response.products.map((p: any) => new PaddleProduct(p));
    this.productsById = {};
    this.products.forEach((p) => {
      this.productsById[p.id] = p;
    });
  }
}
