import { getContinuationToken } from '../youTubeMusicParser';
import { YouTubeMusicPlaylist } from './YouTubeMusicPlaylist';

export class YouTubeMusicPlaylistsResponse {
  private readonly data: any;

  private readonly items: any;

  private readonly channelId?: string;

  constructor(data: any, items: any, channelId?: string) {
    this.data = data;
    this.items = items;
    this.channelId = channelId;
  }

  get playlists(): YouTubeMusicPlaylist[] {
    if (!this.items) {
      return [];
    }
    return this.items
      .map((r: any) => YouTubeMusicPlaylist.fromData(r, this.channelId))
      .filter((playlist: YouTubeMusicPlaylist | null) => !!playlist);
  }

  get continuationToken() {
    return getContinuationToken(this.data);
  }
}
