import React, { useCallback } from 'react';
import URL from 'url-parse';
import { v4 as uuid4 } from 'uuid';
import qs from 'qs';
import { SMLPublishingModalProps } from './props';
import { TypedMemo } from '../../../types';
import { openExternal } from '../../../../../utils/openExternal';
import { SMLPublishingModalRenderer } from './SMLPublishingModalRenderer';

const SMLPublishingModalComponent: React.FC<SMLPublishingModalProps> = ({
  isOpen,
  closeModal,
  smartLinkName,
  shareUrl,
}) => {
  const openSmartLink = useCallback(() => {
    if (!shareUrl) {
      return;
    }
    const url = new URL(shareUrl, (query) => qs.parse(query, { ignoreQueryPrefix: true }));
    url.set('query', { ...url.query, rand: uuid4() });
    openExternal(url.toString()).catch(console.error);
    closeModal();
  }, [shareUrl, closeModal]);

  return (
    <SMLPublishingModalRenderer
      openSmartLink={openSmartLink}
      isOpen={isOpen}
      closeModal={closeModal}
      smartLinkName={smartLinkName}
    />
  );
};

export const SMLPublishingModal = TypedMemo(SMLPublishingModalComponent);
