import trim from 'lodash/trim';
import unescape from 'lodash/unescape';
import { decodeHtmlEntity } from '../../utils/htmlEntities';

const vkPublicResourceUrl = 'https://vk.com';

export function createVkPlayistUrl(ownerId: string | undefined, playlistId: string) {
  if (!ownerId) {
    return undefined;
  }
  return `${vkPublicResourceUrl}/music/playlist/${ownerId}_${playlistId}`;
}

export function createVkTrackUrl(trackId: string) {
  return `${vkPublicResourceUrl}/audio${trackId}`;
}

const decodeString = (s: string | undefined) => {
  if (!s) {
    return undefined;
  }
  return trim(decodeHtmlEntity(unescape(s)));
};

export function convertVKTrackData(data: any) {
  const rawId = data?.[15]?.content_id;
  const name = decodeString(data[3]);
  if (!rawId || !name) {
    return null;
  }
  const images = data[14] && data[14].split(',');
  return {
    rawId,
    name,
    artist: decodeString(data[4]),
    additionalData: {
      searchId: data[24],
    },
    imageUrl: images && images[0] ? images[0] : undefined,
    duration: data[5] ? data[5] * 1000 : undefined,
    sourceURL: createVkTrackUrl(rawId),
  };
}
