import { CollectionItemAdditionalData } from '../../generics/models/AdditionalData';

export enum YouTubeMusicTrackType {
  Video = 'video',
  Song = 'song',
}

export interface YouTubeMusicPlaylistTrackAdditionalData extends CollectionItemAdditionalData {
  entryId?: string;
  videoId?: string;
}

export type Thumbnail = {
  url: string;
  width: number;
  height: number;
};

export type YouTubeMusicAlbumAdditionalData = {
  artist: string;
  imageUrl: string;
  playlistId?: string;
};

export enum MusicVideoType {
  Song = 'MUSIC_VIDEO_TYPE_ATV',
  Video = 'MUSIC_VIDEO_TYPE_UGC',
}

export type VideoDetails = {
  videoId: string;
  title: string;
  lengthSeconds: string;
  channelId: string;
  isOwnerViewing: boolean;
  isCrawlable: boolean;
  thumbnail: {
    thumbnails: Thumbnail[];
  };
  allowRatings: boolean;
  viewCount: string;
  author: string;
  isPrivate: boolean;
  isUnpluggedCorpus: boolean;
  musicVideoType: MusicVideoType;
  isLiveContent: boolean;
};
