import { fetchRetry } from '../../utils/fetch-retry';
import { config } from '../../config/config';
import { FymApiRealmJWTResponse } from './models/FymApiRealmJWTResponse';
import { FYMAPIBlockedFYMOperationalError } from '../../generics/errors/FYMAPIBlockedFYMOperationalError';
import { FYMAPIError } from '../../generics/errors/FYMAPIError';

import { RequestHeaders } from '../../utils/fetch-types';
import { getPlatformBasedUtils } from '../../utils/platformBasedUtils/PlatformBasedUtils';

export class FymAPI {
  public deviceId?: string;

  private readonly baseUrl: string;

  private readonly accessToken?: string;

  constructor(baseUrl: string, accessToken?: string) {
    this.baseUrl = baseUrl;
    this.accessToken = accessToken;
  }

  private get requestHeaders(): RequestHeaders {
    const headers: RequestHeaders = {
      Accept: 'application/json; charset=utf-8',
      'content-type': 'application/json',
      'x-appinfo-target': config.target,
      'x-appinfo-platform': config.platform,
      'x-appinfo-environment': config.environment,
    };
    if (this.deviceId) {
      headers['x-device-id'] = this.deviceId;
    }
    if (this.accessToken) {
      headers.Authorization = `JWT ${this.accessToken}`;
    }

    const { version, platform, platformVersion, buildVersion } = getPlatformBasedUtils().appInformation ?? {};
    if (version) {
      headers['x-appinfo-version'] = version;
    }
    if (platform) {
      headers['x-appinfo-platform'] = platform;
    }
    if (platformVersion) {
      headers['x-appinfo-platformVersion'] = platformVersion;
    }
    if (buildVersion) {
      headers['x-appinfo-buildVersion'] = buildVersion;
    }

    return headers;
  }

  async exchangeJWT(deviceId: string): Promise<FymApiRealmJWTResponse> {
    this.deviceId = deviceId;
    const response = await fetchRetry(`${this.baseUrl}authorization/realm-jwt`, {
      method: 'POST',
      headers: this.requestHeaders,
    });

    if (response.status === 403) {
      const data = await response.json();
      throw new FYMAPIBlockedFYMOperationalError(data.message);
    }

    if (response.status !== 200) {
      const text = await response.text();
      throw new FYMAPIError({
        message: `When trying to exchange JWT token for Realm (authorization/realm-jwt) got wrong response[${response.status}]: ${text}`,
        statusCode: response.status,
      });
    }

    return response.json();
  }

  async finishRealmMigrations(deviceId: string | undefined): Promise<void> {
    this.deviceId = deviceId;
    const response = await fetchRetry(`${this.baseUrl}authorization/realm-migration-finished`, {
      method: 'POST',
      headers: this.requestHeaders,
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FYMAPIError({
        message: `When trying to finish Realm Migrations (authorization/realm-migration) got wrong response[${response.status}]: ${text}`,
        statusCode: response.status,
      });
    }
  }
}

export const fymApi = new FymAPI(config.fymApiURL(false));
