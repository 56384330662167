import { MusicService } from './types';
import { MusicAPIIntegrationID } from '../types';
import { ImporterID } from '../../importers/types';
import { LinkType } from '../../utils/recognizeLinkTypeByUrl';

export const tidal: MusicService = {
  id: 'tidal',
  importerID: ImporterID.Tidal,
  linkType: LinkType.tidal,
  musicAPIID: MusicAPIIntegrationID.Tidal,
  shortName: 'Tidal',
  name: 'Tidal',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: true,
  isSupported: true,
  color: { label: '#2424FF', background: '#0000FF66' },
};
