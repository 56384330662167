import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { tryParseInt } from 'fym-common/src/utils/tryParseInt';
import { TypedMemo } from '../../common/types';
import {
  useCreateSmartLinkMutation,
  useGetAccountQuery,
  useGetSmartLinkQuery,
  useUpdateSmartLinkMutation,
} from '../../../redux/api/fymAPI';
import { useQueryParam } from '../../../utils/useQueryParam';
import { SmartLinkForm, SmartLinkFormProps } from '../../forms/SmartLinkForm/SmartLinkForm';
import { useDeleteSmartLinkFlow } from '../hooks';
import { isSuccessfulResult } from '../../../redux/api/utils/isSuccessfulResult';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { SMLAnalytics } from '../../analytics/sml/analytics';

interface SocialLinksEditionViewProps {
  route?: { params: { accountId: number } };
}

const SmartLinkEditionViewComponent: React.FC<SocialLinksEditionViewProps> = ({ route }) => {
  const router = useSMLRouter();
  const intl = useIntl();
  const accountIdString = useQueryParam('accountId', route);
  const accountId = tryParseInt(accountIdString);
  const smartLinkIdString = useQueryParam('smartLinkId', route, null);
  const smartLinkId = tryParseInt(smartLinkIdString);

  if (accountId === undefined) {
    throw new Error('Missing accountId in SmartLinkEditionView');
  }

  const { openSmartLinkDeletionModal, isDeleteSmartLinkLoading, deleteSmartLinkError, SmartLinkDeletionModal } =
    useDeleteSmartLinkFlow(accountId, smartLinkId);

  const { data: accountResponse, isLoading: isAccountLoading, error: accountError } = useGetAccountQuery({ accountId });

  const { smartLink, isGetSmartLinkLoading, getSmartLinkError } = useGetSmartLinkQuery(
    { accountId, smartLinkId: smartLinkId ?? 0 },
    {
      skip: smartLinkId === undefined,
      selectFromResult: ({ data, isLoading, error }) => ({
        smartLink: data?.smartLink,
        isGetSmartLinkLoading: isLoading,
        getSmartLinkError: error,
      }),
    }
  );

  const [updateSmartLink, { isLoading: isUpdateSmartLinkLoading, error: updateSmartLinkError }] =
    useUpdateSmartLinkMutation();

  const [createSmartLink, { isLoading: isCreateSmartLinkLoading, error: createSmartLinkError }] =
    useCreateSmartLinkMutation();

  const onSubmit: SmartLinkFormProps['onSubmit'] = useCallback(
    async (data) => {
      const { slug, name, image, theme } = data;
      const commonProps = {
        accountId,
        slug,
        name,
        theme,
        backgroundFileId: image?.id,
      };
      if (smartLink) {
        const updateSmartLinkResult = await updateSmartLink({
          ...commonProps,
          smartLinkId: smartLink.id,
        });
        if (isSuccessfulResult(updateSmartLinkResult)) {
          router.goBack(SMLRoutes.details, { accountId, smartLinkId: smartLink.id });
          SMLAnalytics.trackSmartLinkCustomizeChanged().catch(console.error);
        }
      } else {
        const createSmartLinkResult = await createSmartLink(commonProps);
        if (isSuccessfulResult(createSmartLinkResult)) {
          router.replace(SMLRoutes.manage, {
            accountId,
            smartLinkId: createSmartLinkResult.data.smartLink.id,
          });
          router.push(SMLRoutes.details, { accountId, smartLinkId: createSmartLinkResult.data.smartLink.id });

          SMLAnalytics.trackCustomSmartLinkCreated().catch(console.error);
        }
      }
    },
    [updateSmartLink, router, createSmartLink, smartLink, accountId]
  );

  return (
    <>
      {SmartLinkDeletionModal}
      <SmartLinkForm
        title={
          !smartLink || !smartLink.isMainSmartLink
            ? intl.formatMessage({ id: 'app.forms.smartLinkEdition.title', defaultMessage: 'Smart Link' })
            : intl.formatMessage({ id: 'app.forms.smartLinkEdition.bio-link', defaultMessage: 'Your Bio Link' })
        }
        onSubmit={onSubmit}
        accountId={accountId}
        smartLink={smartLink}
        onDelete={smartLink && !smartLink.isMainSmartLink ? openSmartLinkDeletionModal : undefined}
        accountShareUrl={accountResponse?.account.shareUrl ?? null}
        isLoading={
          isGetSmartLinkLoading ||
          isUpdateSmartLinkLoading ||
          isCreateSmartLinkLoading ||
          isDeleteSmartLinkLoading ||
          isAccountLoading
        }
        error={
          getSmartLinkError ?? updateSmartLinkError ?? createSmartLinkError ?? deleteSmartLinkError ?? accountError
        }
      />
    </>
  );
};

export const SmartLinkEditionView = TypedMemo(SmartLinkEditionViewComponent);
