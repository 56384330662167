import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SmartLinkWidgetType } from 'fym-common/src/db/enums';
import { SerializedInfoWidget } from 'fym-common/src/db/serializersTypes';
import { InfoWidgetForm, InfoWidgetFormProps } from '../../../../../forms/InfoWidgetForm/InfoWidgetForm';
import { WidgetEditionProps } from '../../props';
import { TypedMemo } from '../../../../../common/types';

const InfoWidgetEditionViewComponent: React.FC<WidgetEditionProps<SerializedInfoWidget>> = ({
  widget,
  saveWidget,
  deleteWidget,
  isLoading,
  error,
  accountId,
  smartLinkId,
}) => {
  const intl = useIntl();
  const onSubmit: InfoWidgetFormProps['onSubmit'] = useCallback(
    async (data) => {
      const { text, type } = data;
      await saveWidget({ text, type }, SmartLinkWidgetType.info);
    },
    [saveWidget]
  );

  return (
    <InfoWidgetForm
      title={intl.formatMessage({ id: 'app.smart-links.widgets.info.title', defaultMessage: 'Text widget' })}
      onSubmit={onSubmit}
      widget={widget}
      onDelete={deleteWidget}
      isLoading={isLoading}
      error={error}
      accountId={accountId}
      smartLinkId={smartLinkId}
    />
  );
};

export const InfoWidgetEditionView = TypedMemo(InfoWidgetEditionViewComponent);
