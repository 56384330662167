import { GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { YouTubeMusicAPI } from './YouTubeMusicAPI';
import { CollectionAccess, CollectionType } from '../../generics/models/Collection';
import { FetchError } from '../../generics/errors/FetchError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { YouTubeMusicAuthenticationData } from './YouTubeMusicAuthenticationData';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { SearchQueryProperties } from '../../generics/types';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { convertQueryPropsToString } from '../services/MatchingService.helpers';
import { ImporterID } from '../types';
import { YouTubeMusicPlaylistTrack } from './models/YouTubeMusicPlaylistTrack';
import { YouTubeMusicPlaylist } from './models/YouTubeMusicPlaylist';
import { youtubeMusic } from '../../musicServices/services/YouTubeMusic';

export type YouTubeMusicRecapPlaylist = { playlist: YouTubeMusicPlaylist; songs: YouTubeMusicPlaylistTrack[] };

export const YouTubeMusicImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.YouTubeMusic;

  public static musicService = youtubeMusic;

  public static areTrackEntryIdAndGlobalIdDifferent = true;

  public authenticationData: YouTubeMusicAuthenticationData;

  private youTubeMusicApi: YouTubeMusicAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as YouTubeMusicAuthenticationData;
    this.youTubeMusicApi = new YouTubeMusicAPI(
      this.authenticationData.additionalData.cookies,
      this.authenticationData.additionalData.apiKey,
      this.authenticationData.additionalData.visitorId,
      this.authenticationData.additionalData.context,
      this.authenticationData.additionalData.identityToken,
      this.authenticationData.authId,
      this.authenticationData.additionalData.channelId
    );
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.authenticationData = authenticationData as YouTubeMusicAuthenticationData;
    this.youTubeMusicApi = new YouTubeMusicAPI(
      this.authenticationData.additionalData.cookies,
      this.authenticationData.additionalData.apiKey,
      this.authenticationData.additionalData.visitorId,
      this.authenticationData.additionalData.context,
      this.authenticationData.additionalData.identityToken,
      this.authenticationData.authId,
      this.authenticationData.additionalData.channelId
    );
  }

  async getPaginatedCollections(onBatch: (collections: YouTubeMusicPlaylist[]) => Promise<void>): Promise<void> {
    await this.youTubeMusicApi.loadPaginatedPlaylists(onBatch);
    await this.youTubeMusicApi.loadPaginatedAlbums(onBatch);
  }

  async getCollection(collection: GenericCollection): Promise<GenericCollection> {
    let result: GenericCollection | null;
    if (collection.type === CollectionType.ALBUM) {
      result = (await this.youTubeMusicApi.loadAlbum(collection.rawId)).album;
    } else if (collection.type === CollectionType.MY_SONGS) {
      await this.youTubeMusicApi.loadPlaylistItemPage(collection.rawId);
      result = new GenericCollection({
        type: CollectionType.MY_SONGS,
        rawId: collection.rawId,
        name: collection.name,
      });
    } else {
      try {
        result = await this.youTubeMusicApi.getPlaylist(collection.rawId);
      } catch (err) {
        console.error(err);
        await this.youTubeMusicApi.loadPlaylistItemPage(collection.rawId);
        result = new GenericCollection({
          type: CollectionType.PLAYLIST,
          rawId: collection.rawId,
          name: collection.name,
        });
      }
    }

    if (!result) {
      throw new CollectionDoesNotExistsError();
    }
    return result;
  }

  async createCollection({ name }: GenericCollection, description?: string): Promise<YouTubeMusicPlaylist> {
    let rawId: string | undefined;
    try {
      rawId = await this.youTubeMusicApi.createPlaylist(name, { description });
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!rawId) {
      throw new CouldNotCreateCollection();
    }
    return new YouTubeMusicPlaylist({ type: CollectionType.PLAYLIST, rawId, name });
  }

  async addItemToCollection(collection: GenericCollection, matchedItem: GenericMatchedItem) {
    const entryId = await this.youTubeMusicApi.addTrackToPlaylist(collection.rawId, matchedItem.rawId);
    const collectionItem = convertMatchedItemToCollectionItem(
      matchedItem,
      YouTubeMusicImporter.areTrackEntryIdAndGlobalIdDifferent
    );
    collectionItem.rawId = entryId;
    return collectionItem;
  }

  async moveItem(props: Parameters<NonNullable<GenericImporter['moveItem']>>[0]) {
    const { itemToMove, collection, offset, allItems } = props;
    const additionalOffset = offset > 0 ? 1 : 0;
    const indexOfNextItem = itemToMove.position + offset + additionalOffset;
    const sortedItems = allItems.sort((a, b) => a.position - b.position);
    const nextItem = sortedItems[indexOfNextItem];
    await this.youTubeMusicApi.moveTrackInPlaylist(collection.rawId, itemToMove.rawId, nextItem?.rawId ?? null);
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    collectionItems: GenericCollectionItem[]
  ): Promise<void> {
    if (collection.type !== CollectionType.PLAYLIST || collectionItems.length === 0) {
      return;
    }
    const itemsIds = collectionItems.map((item) => item.rawId);
    await this.youTubeMusicApi.removeTracksFromPlaylist(collection.rawId, itemsIds);
  }

  async clearCollection(collection: GenericCollection) {
    const itemsIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    await this.getPaginatedItems(collection, async (items) => {
      itemsIds.push(...items.map((item) => item.rawId));
    });
    await this.youTubeMusicApi.removeTracksFromPlaylist(collection.rawId, itemsIds);
  }

  async matchItems(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const songs = (await this.youTubeMusicApi.search(query, 'songs')).items;
    const videos = (await this.youTubeMusicApi.search(query, 'videos')).items;
    return [...songs, ...videos];
  }

  async matchAlbums(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const { items } = await this.youTubeMusicApi.searchAlbum(query);
    return items;
  }

  async addAlbumToLibrary(album: GenericCollection): Promise<void> {
    if (!album.additionalData?.playlistId) {
      throw new Error(`Missing playlistId in 'addAlbumToLibrary' method for album ${album.rawId}`);
    }
    await this.youTubeMusicApi.addAlbumToLibrary(album.additionalData.playlistId);
  }

  async updateCollection(
    collection: GenericCollection,
    props: { name?: string; access?: CollectionAccess; description?: string }
  ) {
    const { name, access, description } = props;
    const isPublic = access === undefined ? undefined : access === CollectionAccess.public;
    await this.youTubeMusicApi.updatePlaylist(collection.rawId, { name, isPublic, description });
  }

  async removeCollection(collection: GenericCollection): Promise<void> {
    await this.youTubeMusicApi.removePlaylist(collection.rawId);
  }

  async reAuthenticate(): Promise<GenericAuthenticationData> {
    return Promise.reject(new Error('Not supported'));
  }

  doesSupportReAuth() {
    return false;
  }

  doesSupportAlbums() {
    return true;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return true;
  }

  public doesSupportSearchByISRC(): boolean {
    return false;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return false;
  }

  public doesSupportMovingManyItems(): boolean {
    return false;
  }

  public doesSupportMovingItem(): boolean {
    return true;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    switch (forCollection.type) {
      case CollectionType.PLAYLIST:
      case CollectionType.LIKED_PLAYLIST:
        return this.youTubeMusicApi.loadPaginatedPlaylistsItems(forCollection.rawId, onBatch);
      case CollectionType.ALBUM:
        await onBatch((await this.youTubeMusicApi.loadAlbum(forCollection.rawId)).tracks);
        return undefined;
      default:
        return undefined;
    }
  }

  async getCollectionPublicUrl(collection: GenericCollection) {
    return this.youTubeMusicApi.getShareUrl(collection.rawId);
  }

  async getStatsData(): Promise<{ recapPlaylists: YouTubeMusicRecapPlaylist[] } | undefined> {
    const recapPlaylists = await this.youTubeMusicApi.fetchRecap();
    if (!recapPlaylists) {
      return undefined;
    }
    const recapPlaylistsWithSongs: YouTubeMusicRecapPlaylist[] = [];

    for (const playlist of recapPlaylists) {
      const songs: YouTubeMusicPlaylistTrack[] = [];
      try {
        // eslint-disable-next-line @typescript-eslint/require-await
        await this.youTubeMusicApi.loadPaginatedPlaylistsItems(playlist.rawId, async (tracks) => {
          songs.push(...tracks);
        });
        recapPlaylistsWithSongs.push({ playlist, songs });
      } catch (err) {
        console.error(err);
      }
    }

    return {
      recapPlaylists: recapPlaylistsWithSongs,
    };
  }
};
