import React from 'react';
import { createRoot } from 'react-dom/client';
import { config } from './config/config';
import { SMLAppNavigation } from './routing/sml/SMLAppNavigation';
import { Sentry } from './modules/errorBoundary/sentry';
import './assets/styles/sml/appStyle.scss';
import { AppSML } from './modules/app/views/AppSML';

console.log(`%c -- SmartLinks App --`, 'background: #6417fb; color: white;');
if (config.sentryDNS !== '') {
  Sentry.init({
    dsn: config.sentryDNS,
    maxBreadcrumbs: 50,
    ignoreErrors: [
      'FYMOperationalError', // these are for us
      'UserCancelledError',
    ],
  });
}

declare global {
  interface Window {
    analytics: any;
    clarity: any;
    dataLayer: any;
    gtag: any;
    Realm: any;
    require: any;
    isOnServer: boolean;
    Paddle: any;
  }
}

document.documentElement.setAttribute('data-bs-theme', 'light');
const container = document.getElementById('root');
if (!container) {
  throw new Error('Cannot find root element');
}
const root = createRoot(container);
root.render(
  <AppSML>
    <SMLAppNavigation />
  </AppSML>
);

console.info(`Environment: ${config.environment}`);
