import React from 'react';
import { SupportViewRenderer } from './SupportViewRenderer';
import { TypedMemo } from '../common/types';
import { useLicenseInfo } from '../../redux/slices/license/hooks';
import { useDeviceId } from '../../redux/slices/appInfo/hooks';

const SupportViewComponent: React.FC = () => {
  const deviceId = useDeviceId();
  const licenseInfo = useLicenseInfo();
  const { licenseKey, email } = licenseInfo?.validLicense ?? {};

  return <SupportViewRenderer deviceId={deviceId} licenseKey={licenseKey} email={email ?? ''} />;
};
export const SupportView = TypedMemo(SupportViewComponent);
