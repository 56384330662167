import React, { useMemo } from 'react';
import { TypedMemo } from '../../common/types';
import { ClickableDiv } from '../../common/views/ClickableDiv';
import { SmartLinksDraftBadge } from '../smartLinksDraftBadge/SmartLinksDraftBadge';
import { SMLListItemBoxRendererProps } from './props';

const SMLListItemBoxRendererComponent: React.FC<SMLListItemBoxRendererProps> = ({
  onClick,
  isDraft,
  name,
  backgroundColor,
  imagePreviewURL,
}) => {
  const style = useMemo(
    () => ({
      backgroundColor,
      backgroundImage: imagePreviewURL !== null ? `url('${imagePreviewURL}')` : undefined,
    }),
    [backgroundColor, imagePreviewURL]
  );
  return (
    <ClickableDiv onClick={onClick} className="sml-box d-flex justify-content-between flex-column shadow">
      {isDraft && (
        <div className="sml-list-item-box-badges-container d-flex p-3">
          <SmartLinksDraftBadge />
        </div>
      )}
      <div className="sml-list-item-box-image-container" style={style} />
      <div className="sml-list-item-box-title-container p-3">
        <h6 className="text-1-line sml-list-item-box-title m-0">{name}</h6>
      </div>
    </ClickableDiv>
  );
};

export const SMLListItemBoxRenderer = TypedMemo(SMLListItemBoxRendererComponent);
