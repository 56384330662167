import { useIntl } from 'react-intl';
import React, { useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { SerializedAccountTracker } from 'fym-common/src/db/serializersTypes';
import { TypedMemo } from '../../../common/types';
import { resolver } from './validators';
import { FieldsName, FieldsType } from './consts';
import { FormInput } from '../../../forms/components/FormInput/FormInput';
import { InputType } from '../../../forms/types';
import { SMLRoutes } from '../../../../routing/sml/SMLRoutes';
import { SMLFormContainer } from '../../SMLFormContainer/SMLFormContainer';
import { Spacer } from '../../../common/views/spacer/Spacer';
import { ErrorAPIResult } from '../../../../redux/api/utils/isSuccessfulResult';
import { Paragraph } from '../../../common/views/Paragraph/Paragraph';

export interface TrackersFormProps {
  tracker: SerializedAccountTracker | undefined;
  onSubmit: SubmitHandler<FieldsType>;
  onDelete?: () => void;
  isLoading?: boolean;
  error?: ErrorAPIResult['error'];
}

const TrackerFormComponent: React.FC<TrackersFormProps> = ({ tracker, onDelete, error, isLoading, onSubmit }) => {
  const intl = useIntl();
  const defaultValues = useMemo(() => ({ [FieldsName.snippet]: tracker?.snippet ?? '' }), [tracker]);
  const { control, handleSubmit } = useForm<FieldsType>({ resolver, defaultValues });

  const submit = useCallback(
    (event?: React.BaseSyntheticEvent) => {
      handleSubmit(onSubmit)(event).catch(console.error);
    },
    [handleSubmit, onSubmit]
  );

  return (
    <SMLFormContainer
      backRoute={SMLRoutes.trackers}
      submitButtonTitle={
        tracker === undefined
          ? intl.formatMessage({
              id: 'app.forms.buttons.create',
              defaultMessage: 'Create',
            })
          : undefined
      }
      title={intl.formatMessage({
        id: 'app.forms.tracker.title',
        defaultMessage: 'Tracker',
      })}
      onSubmit={submit}
      onDelete={tracker ? onDelete : undefined}
      error={error}
      isLoading={isLoading}
    >
      <Spacer flex flexDirection="column">
        <FormInput
          name={FieldsName.snippet}
          control={control}
          type={InputType.textarea}
          disabled={isLoading}
          label={intl.formatMessage({
            id: 'app.forms.tracker.snippet',
            defaultMessage: 'Snippet',
          })}
          help={intl.formatMessage({
            id: 'app.forms.tracker.help.name',
            defaultMessage: 'Please paste the snippet of code from your tracking provider',
          })}
        />
        <Paragraph>
          {intl.formatMessage(
            {
              id: 'app.forms.tracker.supported_info',
              defaultMessage: 'Currently supported trackers are: {trackers}',
            },
            {
              trackers: 'Facebook Pixel, Google Analytics, Plausible',
            }
          )}
        </Paragraph>
      </Spacer>
    </SMLFormContainer>
  );
};
export const TrackerForm = TypedMemo(TrackerFormComponent);
