import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { TypedMemo } from '../../common/types';
import { SmartLinkStatsViewRendererProps } from './props';
import { LoadingIcon } from '../../common/views/LoadingIcon';
import { SMLStats } from '../SMLStats/SMLStats';

const SmartLinkStatsViewRendererComponent: React.FC<SmartLinkStatsViewRendererProps> = ({
  stats,
  onMainStatsClick,
  isLoading,
}) => {
  const intl = useIntl();
  return (
    <>
      <Row className="mt-4">
        <Col>
          <h5 className="ui-text">
            {intl.formatMessage({ id: 'app.smart_link.manage.stats', defaultMessage: 'Stats' })}
          </h5>
        </Col>
      </Row>
      {!isLoading && stats ? (
        <SMLStats
          onStatsClick={onMainStatsClick}
          pageViews={stats.main.pageViews}
          uniques={stats.main.uniques}
          isSmall
        />
      ) : (
        <Row className="justify-content-center">
          <LoadingIcon />
        </Row>
      )}
    </>
  );
};
export const SmartLinkStatsViewRenderer = TypedMemo(SmartLinkStatsViewRendererComponent);
