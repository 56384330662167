import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Col, Input, Row } from 'reactstrap';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TypedMemo } from '../../common/types';
import { SMLListViewRendererProps } from './props';
import { SmartLinkInfoBox } from '../smartLinkInfoBox/SmartLinkInfoBox';
import { APIPagination } from '../../common/views/pagination/APIPagination';
import { SMLListItemBox } from '../smlListItemBox/SMLListItemBox';
import { SMLContainer } from '../smlContainer/SMLContainer';
import { SMLBreadcrumbs } from '../smlBreadcrumbs/SMLBreadcrumbs';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';

const SMLListViewRendererComponent: React.FC<SMLListViewRendererProps> = ({
  smartLinksResponse,
  smartLinksLoading,
  onSmartLinkClick,
  onPageChange,
  onSearchChange,
  searchFromQuery,
}) => {
  const intl = useIntl();
  const router = useSMLRouter();
  const breadcrumbs = useMemo(() => {
    return [
      {
        name: intl.formatMessage({ id: 'app.sml.breadcrumbs.home', defaultMessage: 'Home' }),
        onClick: () => router.push(SMLRoutes.home),
      },
      {
        name: intl.formatMessage({
          id: 'app.sml.breadcrumbs.your_smart_links',
          defaultMessage: 'Your Smart Links',
        }),
      },
    ];
  }, [intl, router]);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      onSearchChange(e.target.value);
    },
    [onSearchChange]
  );

  return (
    <SMLContainer header={<SMLBreadcrumbs breadcrumbs={breadcrumbs} />}>
      <Row className="mb-4 d-flex align-items-center">
        <Col xs={12} md={8} className="sml-search-bar d-flex align-items-center">
          <FontAwesomeIcon icon={faSearch} className="position-absolute search-icon" />
          <Input className="search-input" value={searchFromQuery} onChange={handleSearch} />
          {smartLinksResponse && (
            <span className="ps-4 w-100 text-muted">
              {intl.formatMessage(
                {
                  id: 'app.smart-links.search.result',
                  defaultMessage: `{items} results found`,
                },
                { items: smartLinksResponse.totalItems }
              )}
            </span>
          )}
        </Col>
      </Row>
      {smartLinksLoading || !smartLinksResponse ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <SmartLinkInfoBox
            text={intl.formatMessage({
              id: 'app.smart-links.loading',
              defaultMessage: `Loading... Please wait`,
            })}
          />
        </div>
      ) : (
        <>
          <Row className="align-items-center">
            {smartLinksResponse.results.map((smartLink) => {
              return (
                <Col key={smartLink.id} xs={12} sm={6} md={4} lg={3} className="mb-3">
                  <SMLListItemBox smartLink={smartLink} onClick={onSmartLinkClick} />
                </Col>
              );
            })}
          </Row>
          <Row className="d-flex justify-content-center">
            <APIPagination
              total={smartLinksResponse.totalItems}
              limit={smartLinksResponse.itemsPerPage}
              page={smartLinksResponse.currentPage}
              onPageChange={onPageChange}
            />
          </Row>
        </>
      )}
    </SMLContainer>
  );
};

export const SMLListViewRenderer = TypedMemo(SMLListViewRendererComponent);
