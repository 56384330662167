import { config as commonConfig } from 'fym-common/src/config/config';
import { AppTarget, Environment, Platform } from 'fym-common/src/config/types';
import { Config } from './types';

export const appConfig: Config = (platform, target, environment) => {
  console.log(`Preparing renderer config... platform: ${platform}, target: ${target}, env: ${environment}`);

  if (environment === Environment.production || environment === Environment.localProduction) {
    return {
      ...commonConfig,
      segmentId:
        target === AppTarget.smartlinks ? '8iH5tifl5CbtdfQH4Fx6UoU5G8nbCQmI' : '0SMNiBridVg1kms32qxwjrvlR3RmEwYJ',
      gTagID: target === AppTarget.smartlinks ? 'G-QSGVRGFN28' : 'G-JWSB0WM7KX',
      fbPixelID: target === AppTarget.smartlinks ? '677372367056988' : '663710120462145',
      sentryDNS:
        target === AppTarget.smartlinks
          ? 'https://13335fd4e2ad422a919177a73f3b7366@o33089.ingest.sentry.io/6750864'
          : 'https://b5286c962e3343bba7b308ef63f148b9@o33089.ingest.sentry.io/1859212',
      iapDisabled: false,
    };
  }
  if (environment === Environment.staging) {
    return {
      ...commonConfig,
      segmentId: '',
      gTagID: 'G-T6RE3BR9L8',
      fbPixelID: '',
      sentryDNS:
        platform === Platform.electron
          ? 'https://2e87e6bb453c4c0387397724c7f2027a@o568006.ingest.sentry.io/5712657'
          : 'https://1b3e51be829941e9a03f38bb277d794d@o568006.ingest.sentry.io/5712604',
      iapDisabled: false,
    };
  }
  return {
    ...commonConfig,
    segmentId: '',
    gTagID: '',
    fbPixelID: '',
    sentryDNS: '',
    iapDisabled: false,
  };
};
