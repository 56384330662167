import { RessoArtistRawResponse } from '../models/RessoResponseModels';
import { artistsToString } from '../../../utils/artistsConverter';

export function getRessoArtistsNamesAsString(artists: RessoArtistRawResponse[] | undefined) {
  if (!artists || artists.length === 0) {
    return '';
  }

  const artistsNames = artists.reduce((result, artist) => {
    if (artist.name) {
      result.push(artist.name);
    }
    return result;
  }, [] as string[]);

  return artistsToString(artistsNames);
}
