import { MusicService } from './types';
import { ImporterID } from '../../importers/types';
import { MusicAPIIntegrationID } from '../types';
import { LinkType } from '../../utils/recognizeLinkTypeByUrl';

export const qobuz: MusicService = {
  id: 'qobuz',
  importerID: ImporterID.Qobuz,
  linkType: LinkType.qobuz,
  musicAPIID: MusicAPIIntegrationID.Qobuz,
  shortName: 'Qobuz',
  name: 'Qobuz',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: true,
  color: { label: '#0070EF', background: '#6FADF3' },
  tracksPerPlaylistLimit: 1000,
};
