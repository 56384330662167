import React from 'react';
import { SerializedInfoWidget } from 'fym-common/src/db/serializersTypes';
import { TypedMemo } from '../../../../../common/types';
import { WidgetListItemProps } from '../../props';
import { convertWidgetFontSize } from '../utils';

const InfoWidgetListItemComponent: React.FC<WidgetListItemProps<SerializedInfoWidget>> = ({ widget }) => {
  const { data } = widget;
  return (
    <div className="widget-list-item-text-container">
      <span className={`${convertWidgetFontSize(data.type)} text-1-line`}>{data.text}</span>
    </div>
  );
};

export const InfoWidgetListItem = TypedMemo(InfoWidgetListItemComponent);
