import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import {
  createSpotifyAlbumUrl,
  getArtistsNamesAsString,
  getArtistsNamesFromPublicSearchAsString,
  getBiggestCovertArtFromPublicSearch,
} from '../utils';
import { AlbumFromPublicSearch, SpotifyAlbumResponseType } from './types';
import { SpotifyArtist } from './SpotifyArtist';

export class SpotifyAlbum extends GenericCollection {
  constructor(data: Omit<CollectionModelData, 'type'>, public artists: SpotifyArtist[] | null = null) {
    super({ type: CollectionType.ALBUM, ...data });
  }

  static fromData(data: SpotifyAlbumResponseType | undefined): SpotifyAlbum | null {
    const { id, name, tracks, total_tracks, images, artists, href, external_urls, uri } = data ?? {};
    const rawId = id ?? uri?.split('spotify:album:')[1];
    if (!rawId || !name) {
      return null;
    }
    return new SpotifyAlbum(
      {
        rawId,
        name,
        itemCount: total_tracks ?? tracks?.total,
        additionalData: {
          imageUrl: images?.[0]?.url,
          artist: getArtistsNamesAsString(artists),
          href,
        },
        publicUrl: external_urls?.spotify ?? createSpotifyAlbumUrl(rawId),
      },
      artists?.map((a) => SpotifyArtist.fromData(a)).filter((a): a is NonNullable<typeof a> => a !== null) ?? null
    );
  }

  static fromPublicSearchData(data: AlbumFromPublicSearch | undefined, url?: string): SpotifyAlbum | null {
    const id = data?.uri?.split('spotify:album:')?.[1];
    if (!data?.name || !id) {
      return null;
    }
    return new SpotifyAlbum({
      rawId: id,
      name: data.name,
      additionalData: {
        imageUrl: getBiggestCovertArtFromPublicSearch(data.coverArt),
        artist: getArtistsNamesFromPublicSearchAsString(data.artists),
        href: `https://api.spotify.com/v1/albums/${id}`,
      },
      publicUrl: url?.split('?')[0] ?? createSpotifyAlbumUrl(id),
    });
  }
}
