import { GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { CollectionType } from '../../generics/models/Collection';
import { QQMusicAPI } from './QQMusicAPI';
import { QQMusicPlaylist } from './models/QQMusicPlaylist';
import { FetchError } from '../../generics/errors/FetchError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { QQMusicAuthenticationData } from './QQMusicAuthenticationData';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { SearchQueryProperties } from '../../generics/types';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { convertQueryPropsToString } from '../services/MatchingService.helpers';
import { ImporterID } from '../types';
import { qqMusic } from '../../musicServices/services/QQMusic';

const createQQMusicInstance = (authenticationData: QQMusicAuthenticationData): QQMusicAPI => {
  return new QQMusicAPI(
    authenticationData.additionalData.cookies,
    authenticationData.authId,
    authenticationData.additionalData.acsrfToken
  );
};

export const QQMusicImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.QQMusic;

  public static musicService = qqMusic;

  public authenticationData: QQMusicAuthenticationData;

  private qqMusicApi: QQMusicAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as QQMusicAuthenticationData;
    this.qqMusicApi = createQQMusicInstance(this.authenticationData);
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.authenticationData = authenticationData as QQMusicAuthenticationData;
    this.qqMusicApi = createQQMusicInstance(this.authenticationData);
  }

  async getPaginatedCollections(onBatch: (collections: QQMusicPlaylist[]) => Promise<void>): Promise<void> {
    await this.qqMusicApi.loadPaginatedPlaylists(onBatch);
    await this.qqMusicApi.loadPaginatedLikedPlaylists(onBatch);
  }

  async getCollection(collection: GenericCollection): Promise<QQMusicPlaylist> {
    let result: QQMusicPlaylist | null = null;
    try {
      result = (await this.qqMusicApi.getPlaylist(collection.rawId)).playlist;
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CollectionDoesNotExistsError(e.message);
      }
      throw e;
    }
    if (!result) {
      throw new CollectionDoesNotExistsError();
    }
    return result;
  }

  async createCollection(collection: GenericCollection): Promise<QQMusicPlaylist> {
    try {
      return await this.qqMusicApi.createPlaylist(collection.name);
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
  }

  async addItemToCollection(collection: GenericCollection, matchItem: GenericMatchedItem) {
    if (!matchItem.additionalData?.mid) {
      throw new Error(`Got item[${matchItem.rawId}] for QQMusicImporter with missing 'mid' in additionalData`);
    }
    await this.qqMusicApi.addTracksToPlaylist(collection.rawId, [matchItem.additionalData.mid]);
    return convertMatchedItemToCollectionItem(matchItem);
  }

  async addManyItemsToCollection(
    collection: GenericCollection,
    data: {
      matchedItem: GenericMatchedItem;
      position?: number;
    }[]
  ) {
    const tracksMids = data.reduce<string[]>((results, { matchedItem }) => {
      const mid = matchedItem.additionalData?.mid;
      if (!mid) {
        throw new Error(`Got item[${matchedItem.rawId}] for QQMusicImporter with missing 'mid' in additionalData`);
      }
      return [...results, mid];
    }, []);
    await this.qqMusicApi.addTracksToPlaylist(collection.rawId, tracksMids);
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    collectionItems: GenericCollectionItem[]
  ): Promise<void> {
    if (collection.type !== CollectionType.PLAYLIST || collectionItems.length === 0) {
      return;
    }
    const itemsIds = collectionItems.map((item) => item.rawId);
    await this.qqMusicApi.removeTracksFromPlaylist(collection.rawId, itemsIds);
  }

  async clearCollection(collection: GenericCollection) {
    const itemsIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    await this.getPaginatedItems(collection, async (items) => {
      itemsIds.push(...items.map((item) => item.rawId));
    });
    await this.qqMusicApi.removeTracksFromPlaylist(collection.rawId, itemsIds);
  }

  async matchItems(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const { tracks } = await this.qqMusicApi.search(query);
    return tracks;
  }

  async reAuthenticate(_withData: GenericAuthenticationData): Promise<GenericAuthenticationData> {
    return Promise.reject(new Error('QQMusic does not support reauthentication'));

    // It needs to be checked, for now reauthentication doesn't work, also web app require relogin after some time (around 24h)

    // const refreshTokenResponse = await this.qqMusicApi.refreshToken();
    // console.log("implements -> refreshTokenResponse", refreshTokenResponse)
    // const newAuthData = {
    //   ...withData,
    //   expiresAt: null,
    //   additionalData: {
    //     ...withData.additionalData,
    //     cookies: {
    //       ...withData.additionalData.cookies,
    //       wxrefresh_token: refreshTokenResponse.refreshToken,
    //       qqmusic_key: refreshTokenResponse.musicKey,
    //       qm_keyst: refreshTokenResponse.musicKey,
    //       wxopenid: refreshTokenResponse.openId
    //     }
    //   }
    // };
    // return newAuthData;
  }

  doesSupportReAuth() {
    return false;
  }

  public doesSupportAlbums(): boolean {
    return false;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return false;
  }

  public doesSupportSearchByISRC(): boolean {
    return false;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return false;
  }

  public doesSupportMovingManyItems(): boolean {
    return false;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    const items = await (async () => {
      switch (forCollection.type) {
        case CollectionType.PLAYLIST:
        case CollectionType.LIKED_PLAYLIST:
          return (await this.qqMusicApi.getPlaylist(forCollection.rawId)).tracks;
        default:
          return [];
      }
    })();
    await onBatch(items);
  }
};
