import { useCallback } from 'react';
import { PostMessagableWindow, SendMessageToPreviewFunc, SMLAppMessage } from './types';

export const useSendMessageToPreview: SendMessageToPreviewFunc = (domain) => {
  return useCallback(
    (childWindow: PostMessagableWindow, message: SMLAppMessage) => {
      try {
        childWindow?.postMessage(JSON.stringify(message), domain);
      } catch (e) {
        console.error(e);
      }
    },
    [domain]
  );
};
