import { PandoraMatchedTrack } from './PandoraMatchedTrack';

export class PandoraSearchResponse {
  private readonly data;

  constructor(data: Record<string, any> | null) {
    this.data = data;
  }

  get annotations(): Record<string, any> | null {
    return this.data?.annotations;
  }

  get tracks(): PandoraMatchedTrack[] {
    if (!this.data?.results || !Array.isArray(this.data?.results)) {
      return [];
    }
    return this.data.results.reduce<PandoraMatchedTrack[]>((results, id) => {
      const annotation = this.annotations?.[id];
      if (typeof id !== 'string' || !id.startsWith('TR') || !annotation) {
        return results;
      }
      const track = PandoraMatchedTrack.fromData(annotation);
      return track !== null ? [...results, track] : results;
    }, []);
  }

  get firstTrack(): PandoraMatchedTrack | undefined {
    if (this.tracks.length < 1) {
      return undefined;
    }
    return this.tracks[0];
  }
}
