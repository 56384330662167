import { YouTubeMatchedTrack } from './YouTubeMatchedTrack';

export class YouTubeSearchResponse {
  private readonly data;

  constructor(data: Record<string, any> | null) {
    this.data = data;
  }

  get items(): YouTubeMatchedTrack[] {
    if (!this.data?.items) {
      return [];
    }
    return this.data.items
      .map((item: any) => YouTubeMatchedTrack.fromData(item))
      .filter((track: YouTubeMatchedTrack | null) => !!track);
  }

  get firstItem(): YouTubeMatchedTrack | undefined {
    if (this.items.length === 0) {
      return undefined;
    }
    return this.items[0];
  }

  get nextPageToken(): string | undefined {
    return this.data?.nextPageToken;
  }

  get totalResults(): number | undefined {
    return this.data?.pageInfo?.totalResults;
  }
}
