import has from 'lodash/has';

type FinishableFunction<T, A extends Array<unknown>> = {
  finished: boolean;
  lastValue: T | undefined;
  error: unknown;
  promise: Promise<T> | undefined;
  reset: () => void;
  (...args: A): Promise<T>;
};

export function isFinishableFunction<T, A extends Array<unknown>>(
  call: (...args: A) => Promise<T>
): call is FinishableFunction<T, A> {
  return has(call, 'finished') && has(call, 'lastValue') && has(call, 'promise') && has(call, 'reset');
}

export function singletonPromise<T, A extends Array<unknown>>(
  call: (...args: A) => Promise<T>
): FinishableFunction<T, A> {
  const func: FinishableFunction<T, A> = (...args: A) => {
    const existingPromise = func.promise;
    if (existingPromise !== undefined) {
      return existingPromise;
    }
    const promise = (async () => {
      try {
        const returnValue = await call(...args);
        func.lastValue = returnValue;
        return returnValue;
      } catch (e) {
        func.error = e;
        throw e;
      } finally {
        func.finished = true;
      }
    })();
    func.promise = promise;
    return promise;
  };
  func.finished = false;
  func.error = undefined;
  func.promise = undefined;
  func.lastValue = undefined;
  func.reset = () => {
    if (func.finished) {
      func.finished = false;
      func.error = undefined;
      func.promise = undefined;
      func.lastValue = undefined;
    }
  };
  return func;
}
