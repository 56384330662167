import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { FieldsName } from './consts';
import { StoreType } from '../../../redux/store';
import { getValidateAccountSlugFn } from '../utils/getValidateAccountSlugFn';

export function createResolver(store: StoreType, withName: boolean) {
  const validateSlug = getValidateAccountSlugFn(store, FieldsName.slug);
  const baseNameSchema = Joi.string().label('Name');
  const schema = Joi.object({
    [FieldsName.name]: withName ? baseNameSchema.required() : baseNameSchema.optional().allow(''),
    [FieldsName.slug]: Joi.string().label('Slug').required().external(validateSlug),
  });
  return joiResolver(schema);
}
