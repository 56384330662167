import qs from 'qs';
import { defaultRetryOptions, RetryOptions } from '../../utils/fetch-retry';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { FetchError } from '../../generics/errors/FetchError';
import { TidalUser } from './models/TidalUser';
import { TidalPlaylist } from './models/TidalPlaylist';
import { TidalPlaylistsResponse } from './models/TidalPlaylistsResponse';
import { TidalAlbumWithTracksResponse } from './models/TidalAlbumWithTracksResponse';
import { TidalAlbumsResponse } from './models/TidalAlbumsResponse';
import { TidalPlaylistTracksResponse } from './models/TidalPlaylistTracksResponse';
import { TidalCollectionTrack } from './models/TidalCollectionTrack';
import { TidalSearchResponse } from './models/TidalSearchResponse';
import { ImporterID } from '../types';
import { fetchRetryForTidal } from './utils';
import { TidalSessions } from './models/TidalSessions';
import { Response } from '../../utils/fetch-types';
import { CollectionAccess } from '../../generics/models/Collection';

export class TidalAPI {
  private static readonly baseUrl = 'https://api.tidal.com/v1/';

  private static readonly onArtifactNotFound = {
    fail: 'FAIL', // request will fail if a track, album, artist or video is not found
    skip: 'SKIP', // allow missing artefacts
  };

  private static readonly orderDirection = {
    asc: 'ASC',
    desc: 'DESC',
  };

  private readonly accessToken;

  public readonly countryCode?;

  private playlistsETags;

  public userId?;

  constructor(accessToken: string, userId?: string, countryCode?: string) {
    this.accessToken = accessToken;
    this.userId = userId;
    this.countryCode = countryCode?.toUpperCase();
    this.playlistsETags = new Map<string, string>();
  }

  static fromConfig(accessToken: string, userId?: string, countryCode?: string) {
    return new TidalAPI(accessToken, userId, countryCode);
  }

  private get requestHeaders(): Record<string, string> {
    return {
      Accept: 'application/json; charset=utf-8',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.accessToken}`,
    };
  }

  private get requestHeadersUrlEncoded(): Record<string, string> {
    return {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: `Bearer ${this.accessToken}`,
    };
  }

  async fetch(url: string, options: any, retryOptions: RetryOptions = defaultRetryOptions): Promise<Response> {
    const response = await fetchRetryForTidal(url, options, retryOptions);
    if (response.status === 401) {
      const text = await response.text();
      throw new NotAuthenticatedError({ authId: this.userId, importerId: ImporterID.Tidal }, text);
    }
    return response;
  }

  async fetchMe(): Promise<TidalUser> {
    const response = await this.fetch(`${TidalAPI.baseUrl}users/${this.userId}?countryCode=${this.countryCode}`, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to fetch me from Tidal got wrong response[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new TidalUser(data);
  }

  async fetchSessions(): Promise<TidalSessions> {
    const response = await this.fetch(`${TidalAPI.baseUrl}sessions`, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to fetch sessions from Tidal got wrong response[${response.status}]: ${text}`
      );
    }
    const data = await response.json();
    return new TidalSessions(data);
  }

  async loadPaginatedPlaylists(onBatch: (collections: TidalPlaylist[]) => Promise<void>): Promise<void> {
    const limit = 50;
    const loadPlaylists = async (offset: number): Promise<void> => {
      const playlistResponse = await this.loadPlaylistPage(offset, limit);
      await onBatch(playlistResponse.playlists);
      const newOffset = offset + limit;
      if (newOffset < playlistResponse.totalNumberOfItems) {
        await loadPlaylists(newOffset);
      }
    };

    await loadPlaylists(0);
  }

  async loadPlaylistPage(offset = 0, limit = 50): Promise<TidalPlaylistsResponse> {
    const response = await this.fetch(
      `${TidalAPI.baseUrl}users/${this.userId}/playlistsAndFavoritePlaylists?${qs.stringify({
        offset,
        limit,
        order: 'DATE',
        orderDirection: TidalAPI.orderDirection.desc,
        countryCode: this.countryCode,
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders,
      }
    );
    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to fetch playlist page from Tidal got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    return new TidalPlaylistsResponse(jsonBody, this.userId);
  }

  async loadPaginatedPlaylistItems(
    playlistId: string,
    onBatch: (tracks: TidalCollectionTrack[]) => Promise<void>
  ): Promise<string | null> {
    const limit = 50;
    const loadPlaylists = async (offset: number): Promise<string | null> => {
      const playlistResponse = await this.loadPlaylistItemsPage(playlistId, offset, limit);
      await onBatch(playlistResponse.tracks);
      const newOffset = offset + limit;
      if (newOffset < playlistResponse.totalNumberOfItems) {
        return loadPlaylists(newOffset);
      }
      return playlistResponse.etag;
    };

    return loadPlaylists(0);
  }

  async loadPlaylistItemsPage(playlistId: string, offset = 0, limit = 50): Promise<TidalPlaylistTracksResponse> {
    const url = `${TidalAPI.baseUrl}playlists/${playlistId}/items?${qs.stringify({
      offset,
      limit,
      countryCode: this.countryCode,
    })}`;
    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to fetch playlist items page from Tidal got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    return new TidalPlaylistTracksResponse(jsonBody, response.headers.get('etag'));
  }

  async loadPaginatedFavouriteTracks(onBatch: (tracks: TidalCollectionTrack[]) => Promise<void>): Promise<void> {
    const limit = 50;
    const loadTracks = async (offset: number): Promise<void> => {
      const playlistResponse = await this.loadFavouriteTracksPage(offset, limit);
      await onBatch(playlistResponse.tracks);
      const newOffset = offset + limit;
      if (newOffset < playlistResponse.totalNumberOfItems) {
        return loadTracks(newOffset);
      }
      return undefined;
    };

    await loadTracks(0);
  }

  async loadFavouriteTracksPage(offset = 0, limit = 50): Promise<TidalPlaylistTracksResponse> {
    const url = `${TidalAPI.baseUrl}users/${this.userId}/favorites/tracks?${qs.stringify({
      offset,
      limit,
      countryCode: this.countryCode,
      // order: 'NAME', // Possible values: NAME, ARTIST, DATE, ALBUM, LENGTH
      // orderDirection: TidalAPI.orderDirection.asc,
    })}`;
    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to fetch playlist items page from Tidal got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    return new TidalPlaylistTracksResponse(jsonBody, response.headers.get('etag'));
  }

  async loadPaginatedAlbums(onBatch: (collections: TidalPlaylist[]) => Promise<void>): Promise<void> {
    const limit = 50;
    const loadAlbums = async (offset: number): Promise<void> => {
      const albumResponse = await this.loadAlbumPage(offset, limit);
      await onBatch(albumResponse.albums);
      const newOffset = offset + limit;
      if (newOffset < albumResponse.totalNumberOfItems) {
        await loadAlbums(newOffset);
      }
    };

    await loadAlbums(0);
  }

  async loadAlbumPage(offset = 0, limit = 50) {
    const response = await this.fetch(
      `${TidalAPI.baseUrl}users/${this.userId}/favorites/albums?${qs.stringify({
        countryCode: this.countryCode,
        offset,
        limit,
        order: 'DATE',
        orderDirection: TidalAPI.orderDirection.desc,
        deviceType: 'BROWSER',
      })}`,
      {
        method: 'GET',
        headers: this.requestHeaders,
      }
    );

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to fetch album page from Tidal got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    return new TidalAlbumsResponse(jsonBody);
  }

  async loadPlaylist(playlistId: string): Promise<TidalPlaylist | null> {
    const url = `${TidalAPI.baseUrl}playlists/${playlistId}?${qs.stringify({
      countryCode: this.countryCode,
    })}`;
    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to fetch playlist items page from Tidal got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    return TidalPlaylist.fromData(jsonBody, this.userId);
  }

  async loadAlbum(albumId: string): Promise<TidalAlbumWithTracksResponse> {
    const url = `${TidalAPI.baseUrl}pages/album?${qs.stringify({
      albumId,
      countryCode: this.countryCode,
      deviceType: 'BROWSER',
    })}`;
    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to fetch album [${albumId}] from Tidal got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    return new TidalAlbumWithTracksResponse(jsonBody);
  }

  async createPlaylist(title: string, description?: string): Promise<TidalPlaylist | null> {
    const url = `${TidalAPI.baseUrl}users/${this.userId}/playlists`;
    const response = await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeadersUrlEncoded,
      body: qs.stringify({ title, description }),
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to create playlist[${title}][${description}] on Tidal got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    return TidalPlaylist.fromData(jsonBody, this.userId, CollectionAccess.private);
  }

  async search(props: {
    query: string;
    searchType: 'TRACKS' | 'ALBUMS';
    limit?: number;
    offset?: number;
  }): Promise<TidalSearchResponse> {
    const { query, searchType, limit = 10, offset = 0 } = props;
    if (!query) {
      return new TidalSearchResponse(null);
    }
    const url = `${TidalAPI.baseUrl}search?${qs.stringify({
      countryCode: this.countryCode,
      query,
      limit,
      offset,
      types: searchType,
      includeContributors: true,
    })}`;

    const response = await this.fetch(
      url,
      {
        method: 'GET',
        headers: this.requestHeaders,
      },
      { ...defaultRetryOptions, shouldWait: true }
    );

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to search[${query}] on Tidal got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    return new TidalSearchResponse(jsonBody);
  }

  async fetchETag(playlistId: string) {
    const { etag } = await this.loadPlaylistItemsPage(playlistId, 0, 1);
    if (!etag) {
      return null;
    }
    this.playlistsETags.set(playlistId, etag);
    return etag;
  }

  async addTracksToPlaylist({
    playlistId,
    trackIds,
    trackPosition,
    secondAttempt = false,
  }: {
    playlistId: string;
    trackIds: string[];
    trackPosition?: number;
    secondAttempt?: boolean;
  }): Promise<void> {
    let etag = this.playlistsETags.get(playlistId) ?? null;
    if (!etag || secondAttempt) {
      etag = await this.fetchETag(playlistId);
    }
    const url = `https://listen.tidal.com/v1/playlists/${playlistId}/items?${qs.stringify({
      countryCode: this.countryCode,
    })}`;
    const trackIdsString = trackIds.join(',');
    const headers = etag
      ? { ...this.requestHeadersUrlEncoded, 'if-none-match': `${etag}` }
      : this.requestHeadersUrlEncoded;
    const response = await this.fetch(url, {
      method: 'POST',
      headers,
      body: qs.stringify({
        onArtifactNotFound: TidalAPI.onArtifactNotFound.fail,
        onDupes: 'FAIL',
        trackIds: trackIdsString,
        toIndex: trackPosition,
      }),
    });

    if (response.status === 412 && !secondAttempt) {
      return this.addTracksToPlaylist({ playlistId, trackIds, trackPosition, secondAttempt: true });
    }

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to add items[${trackIdsString}] to playlist[${playlistId}] on Tidal got wrong response[${response.status}]: ${text}`
      );
    }

    const { lastUpdated } = await response.json();
    if (lastUpdated) {
      this.playlistsETags.set(playlistId, `${lastUpdated}`);
    }
    return undefined;
  }

  async addTracksToLibrary(trackIds: string[]): Promise<void> {
    const url = `${TidalAPI.baseUrl}users/${this.userId}/favorites/tracks?${qs.stringify({
      countryCode: this.countryCode,
    })}`;
    const trackIdsString = trackIds.join(',');
    const response = await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeadersUrlEncoded,
      body: qs.stringify({
        onArtifactNotFound: TidalAPI.onArtifactNotFound.fail,
        trackIds: trackIdsString,
      }),
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to add items[${trackIdsString}] to library on Tidal got wrong response[${response.status}]: ${text}`
      );
    }
  }

  async addAlbumToLibrary(albumId: string): Promise<void> {
    const url = `${TidalAPI.baseUrl}users/${this.userId}/favorites/albums?${qs.stringify({
      countryCode: this.countryCode,
    })}`;

    const response = await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeadersUrlEncoded,
      body: qs.stringify({
        onArtifactNotFound: TidalAPI.onArtifactNotFound.fail,
        albumIds: albumId,
      }),
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to add album [${albumId}] to library on Tidal got wrong response[${response.status}]: ${text}`
      );
    }
  }

  async removeTracksByIndicesFromPlaylist(
    playlistId: string,
    indicesToRemove: (string | number)[],
    etag: string | null
  ): Promise<void> {
    if (indicesToRemove.length === 0) {
      return;
    }
    const indicesString = indicesToRemove.join(',');
    const url = `${TidalAPI.baseUrl}playlists/${playlistId}/items/${indicesString}?${qs.stringify({
      order: 'INDEX',
      orderDirection: TidalAPI.orderDirection.asc,
      countryCode: this.countryCode,
    })}`;

    const response = await this.fetch(url, {
      method: 'DELETE',
      headers: { ...this.requestHeaders, 'if-none-match': etag },
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to remove items positions: [${indicesString}] from playlist[${playlistId}] on Tidal got wrong response[${response.status}]: ${text}`
      );
    }

    await response.text();
  }

  async removeTracksFromPlaylist(playlistId: string, trackIds: string[]): Promise<void> {
    const existingItemsIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    const etag = await this.loadPaginatedPlaylistItems(playlistId, async (tracks) => {
      existingItemsIds.push(...tracks.map((i) => i.rawId));
    });
    const indicesToRemove = existingItemsIds.reduce<number[]>(
      (results, item, index) => (trackIds.includes(`${item}`) ? [...results, index] : results),
      []
    );
    await this.removeTracksByIndicesFromPlaylist(playlistId, indicesToRemove, etag);
  }

  async moveTrackInPlaylist(
    playlistId: string,
    indexesToMove: (string | number)[],
    position: number,
    etag: string | null
  ): Promise<string | null> {
    const indexesString = indexesToMove.join(',');
    const url = `${TidalAPI.baseUrl}playlists/${playlistId}/items/${indexesString}?${qs.stringify({
      countryCode: this.countryCode,
    })}`;

    const response = await this.fetch(url, {
      method: 'POST',
      headers: { ...this.requestHeadersUrlEncoded, 'if-none-match': `${etag}` },
      body: qs.stringify({
        toIndex: position,
      }),
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to move items positions: [${indexesString}] from playlist[${playlistId}] on Tidal got wrong response[${response.status}]: ${text}`
      );
    }
    await response.text();
    return response.headers.get('etag');
  }

  async removePlaylist(playlistId: string): Promise<void> {
    const url = `${TidalAPI.baseUrl}playlists/${playlistId}`;
    const response = await this.fetch(url, {
      method: 'DELETE',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to remove playlistId[${playlistId}] from Tidal got wrong response[${response.status}]: ${text}`
      );
    }
  }

  async updatePlaylist(playlistId: string, props: { title?: string; description?: string }): Promise<void> {
    const { title, description } = props;
    const url = `${TidalAPI.baseUrl}playlists/${playlistId}`;
    const response = await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeadersUrlEncoded,
      body: qs.stringify({ title, description }),
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to update playlistId[${playlistId}] on Tidal got wrong response[${response.status}]: ${text}`
      );
    }
  }
}
