import React, { useCallback } from 'react';
import { TypedMemo } from '../../common/types';
import { SMLRemindPasswordViewRenderer } from './SMLRemindPasswordViewRenderer';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';

const SMLRemindPasswordViewComponent: React.FC = () => {
  const router = useSMLRouter();

  const goToLogInView = useCallback(() => {
    router.replace(SMLRoutes.login);
  }, [router]);

  return <SMLRemindPasswordViewRenderer goToLogInView={goToLogInView} />;
};

export const SMLRemindPasswordView = TypedMemo(SMLRemindPasswordViewComponent);
