import { RessoMatchedTrack } from './RessoMatchedTrack';
import { RessoAlbum } from './RessoAlbum';
import { RessoSearchRawResponse } from './RessoResponseModels';

export class RessoSearchResponse {
  private readonly data;

  constructor(data: RessoSearchRawResponse | null) {
    this.data = data;
  }

  get tracks(): RessoMatchedTrack[] {
    if (!this.data?.tracks?.items) {
      return [];
    }
    return this.data.tracks.items
      .map((item) => RessoMatchedTrack.fromData(item))
      .filter((track): track is RessoMatchedTrack => !!track);
  }

  get albums(): RessoAlbum[] {
    if (!this.data?.albums?.items) {
      return [];
    }
    return this.data.albums.items
      .map((item) => RessoAlbum.fromData(item))
      .filter((album): album is RessoAlbum => !!album);
  }

  get totalTracks(): number | undefined {
    return this.data?.tracks?.total;
  }

  get totalAlbums(): number | undefined {
    return this.data?.albums?.total;
  }
}
