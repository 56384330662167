import { DeezerPlaylist } from './DeezerPlaylist';
import { DeezerListRaw } from '../rawTypes/DeezerListRaw';
import { DeezerPlaylistRaw } from '../rawTypes/DeezerPlaylistRaw';

export class DeezerPlaylistsResponse {
  private readonly data;

  private readonly userId;

  constructor(data: DeezerListRaw<DeezerPlaylistRaw>, userId: string) {
    this.data = data;
    this.userId = userId;
  }

  get playlists(): DeezerPlaylist[] {
    if (!this.data.data) {
      return [];
    }
    return this.data.data.reduce<DeezerPlaylist[]>((results, playlistData) => {
      const playlist = DeezerPlaylist.fromData(playlistData, this.userId);
      return playlist ? [...results, playlist] : results;
    }, []);
  }

  get total() {
    return this.data.total;
  }

  get next() {
    return this.data.next;
  }
}
