import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../../common/types';
import { SMLChangePasswordViewRendererProps } from './props';
import { useChangePasswordForm } from './ChangePasswordForm/useChangePasswordForm';
import { Alert } from '../../forms/components/Alert/Alert';
import { AlertLevel } from '../../forms/components/Alert/props';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { boldIntlText } from '../../common/views/bold/boldIntlText';

const SMLChangePasswordViewRendererComponent: React.FC<React.PropsWithChildren<SMLChangePasswordViewRendererProps>> = ({
  authToken,
  userId,
  goToLogIn,
}) => {
  const intl = useIntl();
  const { CurrentPasswordInput, NewPasswordInput, SubmitButton, submit, ErrorView, isSuccessful } =
    useChangePasswordForm({
      authToken,
      userId,
    });
  const goToLogInClicked = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      goToLogIn();
    },
    [goToLogIn]
  );

  const LinkIntl = useCallback(
    (str: React.ReactNode) => (
      <a href={`/${SMLRoutes.login}`} onClick={goToLogInClicked}>
        {str}
      </a>
    ),
    [goToLogInClicked]
  );

  return (
    <>
      <div className="d-flex justify-content-center">
        <span className="sml-set-up-text ui-text text-center mb-3">
          {intl.formatMessage({
            id: 'app.sml.change_password.description',
            defaultMessage: `Keep passwords secure!`,
          })}
        </span>
      </div>
      {isSuccessful && (
        <Alert level={AlertLevel.success}>
          <p className="mb-0">
            {intl.formatMessage(
              {
                id: 'app.sml.change_password.success',
                defaultMessage: `<b>Success!</b>{br}The password has been changed. Click <link>here</link> to log in.`,
              },
              {
                b: boldIntlText,
                link: LinkIntl,
                br: <br />,
              }
            )}
          </p>
        </Alert>
      )}
      {ErrorView}
      <form onSubmit={submit}>
        {CurrentPasswordInput}
        {NewPasswordInput}
        <div className="mt-5">{SubmitButton}</div>
      </form>
    </>
  );
};

export const SMLChangePasswordViewRenderer = TypedMemo(SMLChangePasswordViewRendererComponent);
