import { isNumber } from './isNumber';

export function tryParseFloat(value: string | number | undefined | null): number | undefined {
  if (isNumber(value)) {
    return value;
  }
  if (!value) {
    return undefined;
  }
  const parsedValue = parseFloat(value);
  return Number.isNaN(parsedValue) ? undefined : parsedValue;
}
