import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsOnline, setIsServerUp } from '../../../redux/slices/appInfo/slice';
import { serverStatusListener } from '../serverStatusListener';
import { onlineStatusListener } from '../onlineStatusListener';
import { useIsOnline, useIsServerUp } from '../../../redux/slices/appInfo/hooks';

const _useListenForIsOnline = () => {
  const dispatch = useDispatch();
  const isOnline = useIsOnline();
  const isServerUp = useIsServerUp();

  const setOnline = useCallback(() => dispatch(setIsOnline({ isOnline: true })), [dispatch]);
  const setOffline = useCallback(() => dispatch(setIsOnline({ isOnline: false })), [dispatch]);
  const setIsServerUpDispatch = useCallback(
    (isUp: boolean) => dispatch(setIsServerUp({ isServerUp: isUp })),
    [dispatch]
  );

  useEffect(() => {
    const clearServerListener = serverStatusListener(setIsServerUpDispatch);
    const clearOnlineListener = onlineStatusListener(setOnline, setOffline);

    return () => {
      clearServerListener();
      clearOnlineListener();
    };
  }, [setIsServerUpDispatch, setOffline, setOnline]);

  return { isOnline, isServerUp };
};
export const IsOnlineProvider: React.FC = () => {
  _useListenForIsOnline();
  return null;
};
