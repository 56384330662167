import { AmazonMusicPlaylist } from './AmazonMusicPlaylist';
import { Playlist } from './types';
import { CollectionType } from '../../../generics/models/Collection';

interface PlaylistCreationResponse {
  data?: {
    createPlaylist?: Playlist;
  };
}

export class AmazonMusicPlaylistCreationResponse {
  private readonly data;

  private readonly userId;

  constructor(data: PlaylistCreationResponse | undefined, userId: string | undefined) {
    this.data = data;
    this.userId = userId;
  }

  get playlist() {
    return AmazonMusicPlaylist.fromData(this.data?.data?.createPlaylist, this.userId, CollectionType.PLAYLIST);
  }
}
