import { ReactComponent as QobuzLight } from '../../../../assets/images/music-services/qobuz-light.svg';
import { ReactComponent as QobuzDark } from '../../../../assets/images/music-services/qobuz-dark.svg';
import { ReactComponent as QobuzLightPadding } from '../../../../assets/images/music-services/qobuz-light-padding.svg';
import { ReactComponent as QobuzDarkPadding } from '../../../../assets/images/music-services/qobuz-dark-padding.svg';
import { ReactComponent as QobuzColorLight } from '../../../../assets/images/music-services/qobuz-color-light.svg';
import { ReactComponent as QobuzColorDark } from '../../../../assets/images/music-services/qobuz-color-dark.svg';
import { ReactComponent as QobuzColorLightPadding } from '../../../../assets/images/music-services/qobuz-color-light-padding.svg';
import { ReactComponent as QobuzColorDarkPadding } from '../../../../assets/images/music-services/qobuz-color-dark-padding.svg';
import { ReactComponent as QobuzDarkDanger } from '../../../../assets/images/music-services-danger/qobuz-dark-danger.svg';
import { ReactComponent as QobuzLightDanger } from '../../../../assets/images/music-services-danger/qobuz-light-danger.svg';
import { ReactComponent as QobuzLightDangerPadding } from '../../../../assets/images/music-services-danger/qobuz-light-padding-danger.svg';
import { ReactComponent as QobuzDarkDangerPadding } from '../../../../assets/images/music-services-danger/qobuz-dark-padding-danger.svg';
import { ReactComponent as QobuzDarkWarning } from '../../../../assets/images/music-services-warning/qobuz-dark-warning.svg';
import { ReactComponent as QobuzLightWarning } from '../../../../assets/images/music-services-warning/qobuz-light-warning.svg';
import { ReactComponent as QobuzLightWarningPadding } from '../../../../assets/images/music-services-warning/qobuz-light-padding-warning.svg';
import { ReactComponent as QobuzDarkWarningPadding } from '../../../../assets/images/music-services-warning/qobuz-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const qobuzImages: ImporterImages = {
  logo: {
    light: QobuzLight,
    dark: QobuzDark,
  },
  logoPadding: {
    light: QobuzLightPadding,
    dark: QobuzDarkPadding,
  },
  logoColor: {
    light: QobuzColorLight,
    dark: QobuzColorDark,
  },
  logoColorPadding: {
    light: QobuzColorLightPadding,
    dark: QobuzColorDarkPadding,
  },
  logoOnBackground: QobuzDark,
  logoDanger: {
    dark: QobuzDarkDanger,
    light: QobuzLightDanger,
  },
  logoDangerPadding: {
    dark: QobuzDarkDangerPadding,
    light: QobuzLightDangerPadding,
  },
  logoWarning: {
    dark: QobuzDarkWarning,
    light: QobuzLightWarning,
  },
  logoWarningPadding: {
    light: QobuzLightWarningPadding,
    dark: QobuzDarkWarningPadding,
  },
};
