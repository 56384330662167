import { ReactComponent as GaanaLight } from '../../../../assets/images/music-services/gaana-light.svg';
import { ReactComponent as GaanaDark } from '../../../../assets/images/music-services/gaana-dark.svg';
import { ReactComponent as GaanaLightPadding } from '../../../../assets/images/music-services/gaana-light-padding.svg';
import { ReactComponent as GaanaDarkPadding } from '../../../../assets/images/music-services/gaana-dark-padding.svg';
import { ReactComponent as GaanaColorLight } from '../../../../assets/images/music-services/gaana-color-light.svg';
import { ReactComponent as GaanaColorDark } from '../../../../assets/images/music-services/gaana-color-dark.svg';
import { ReactComponent as GaanaColorLightPadding } from '../../../../assets/images/music-services/gaana-color-light-padding.svg';
import { ReactComponent as GaanaColorDarkPadding } from '../../../../assets/images/music-services/gaana-color-dark-padding.svg';
import { ReactComponent as GaanaDarkDanger } from '../../../../assets/images/music-services-danger/gaana-dark-danger.svg';
import { ReactComponent as GaanaLightDanger } from '../../../../assets/images/music-services-danger/gaana-light-danger.svg';
import { ReactComponent as GaanaLightDangerPadding } from '../../../../assets/images/music-services-danger/gaana-light-padding-danger.svg';
import { ReactComponent as GaanaDarkDangerPadding } from '../../../../assets/images/music-services-danger/gaana-dark-padding-danger.svg';
import { ReactComponent as GaanaDarkWarning } from '../../../../assets/images/music-services-warning/gaana-dark-warning.svg';
import { ReactComponent as GaanaLightWarning } from '../../../../assets/images/music-services-warning/gaana-light-warning.svg';
import { ReactComponent as GaanaLightWarningPadding } from '../../../../assets/images/music-services-warning/gaana-light-padding-warning.svg';
import { ReactComponent as GaanaDarkWarningPadding } from '../../../../assets/images/music-services-warning/gaana-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const gaanaImages: ImporterImages = {
  logo: {
    light: GaanaLight,
    dark: GaanaDark,
  },
  logoPadding: {
    light: GaanaLightPadding,
    dark: GaanaDarkPadding,
  },
  logoColor: {
    light: GaanaColorLight,
    dark: GaanaColorDark,
  },
  logoColorPadding: {
    light: GaanaColorLightPadding,
    dark: GaanaColorDarkPadding,
  },
  logoOnBackground: GaanaDark,
  logoDanger: {
    dark: GaanaDarkDanger,
    light: GaanaLightDanger,
  },
  logoDangerPadding: {
    dark: GaanaDarkDangerPadding,
    light: GaanaLightDangerPadding,
  },
  logoWarning: {
    dark: GaanaDarkWarning,
    light: GaanaLightWarning,
  },
  logoWarningPadding: {
    light: GaanaLightWarningPadding,
    dark: GaanaDarkWarningPadding,
  },
};
