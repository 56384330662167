import React, { useCallback, useEffect } from 'react';
import { TypedMemo } from '../../common/types';
import { usePatchSocialLinksMutation } from '../../../redux/api/fymAPI';
import { isSuccessfulResult } from '../../../redux/api/utils/isSuccessfulResult';
import { useAccountId } from '../../../redux/slices/appInfo/hooks';
import { useLocalStore } from '../../common/storeHooks';
import { SMLOnboardingFormStep3 } from '../../forms/SMLOnboardingFormStep3/SMLOnboardingFormStep3';
import { SMLSetUpContainer } from '../smlSetUpContainer/SMLSetUpContainer';
import { SMLOnboardingFormStep3Props } from '../../forms/SMLOnboardingFormStep3/props';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { SMLAnalytics } from '../../analytics/sml/analytics';

const SMLOnboarding3ViewComponent: React.FC = () => {
  const router = useSMLRouter();
  const accountId = useAccountId();

  if (accountId === null) {
    throw new Error('Missing account id in Onboarding3View');
  }

  const { setValue: setOnboardingSMLFinishedStep3 } = useLocalStore('onboardingSMLFinishedStep3', false);

  const [patchSocialLinks, { isLoading: isPatchSocialLinksLoading, error: patchSocialLinksError }] =
    usePatchSocialLinksMutation();

  const finishOnboarding = useCallback(() => {
    setOnboardingSMLFinishedStep3(true)
      .then(() => {
        router.replace(SMLRoutes.home);
        SMLAnalytics.trackOnboardingStep3Finished().catch(console.error);
      })
      .catch(console.error);
  }, [router, setOnboardingSMLFinishedStep3]);

  const handlePatchSocialLinks: SMLOnboardingFormStep3Props['onSubmit'] = useCallback(
    async ({ create, update, remove }) => {
      const doesContainAnyChanges = create.length > 0 || update.length > 0 || remove.length > 0;
      if (!doesContainAnyChanges) {
        finishOnboarding();
        return;
      }
      const result = await patchSocialLinks({ create, update, remove, accountId });
      if (!isSuccessfulResult(result)) {
        return;
      }
      finishOnboarding();
    },
    [patchSocialLinks, accountId, finishOnboarding]
  );

  useEffect(() => {
    SMLAnalytics.trackOnboardingStep3Started().catch(console.error);
  });
  return (
    <SMLSetUpContainer>
      <SMLOnboardingFormStep3
        onSubmit={handlePatchSocialLinks}
        onSkip={finishOnboarding}
        isLoading={isPatchSocialLinksLoading}
        error={patchSocialLinksError}
      />
    </SMLSetUpContainer>
  );
};

export const SMLOnboarding3View = TypedMemo(SMLOnboarding3ViewComponent);
