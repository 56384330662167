import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons/faCircleNotch';
import { LoadingIconProps } from './LoadingIconProps';
import { TypedMemo } from '../types';

const LoadingIconComponent: React.FC<LoadingIconProps> = ({ small = false }) => {
  return <FontAwesomeIcon icon={faCircleNotch} size={small ? '1x' : '2x'} spin />;
};

export const LoadingIcon = TypedMemo(LoadingIconComponent);
