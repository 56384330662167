import React, { useCallback } from 'react';
import { TypedMemo } from '../../common/types';
import { SMLHomeViewProps } from './props';
import { useGetAccountQuery, useGetSmartLinksQuery } from '../../../redux/api/fymAPI';
import { LoadingView } from '../../common/views/LoadingView';
import { SMLHomeViewRenderer } from './SMLHomeViewRenderer';
import { ErrorView } from '../../common/views/ErrorView/ErrorView';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';

const SMLHomeViewComponent: React.FC<SMLHomeViewProps> = ({ accountId }) => {
  const router = useSMLRouter();
  const {
    data: accountResponse,
    isLoading: isLoadingAccount,
    error: accountError,
    refetch: accountRefetch,
  } = useGetAccountQuery({ accountId });
  const {
    data: smartLinksResponse,
    isLoading: smartLinksLoading,
    error: smartLinksError,
    refetch: smartLinksRefetch,
  } = useGetSmartLinksQuery({ accountId, page: 0, limit: 2 });
  const hasMoreSmartLinks = !!smartLinksResponse && smartLinksResponse.currentPage + 1 < smartLinksResponse.totalPages;

  const goToSMLListView = useCallback(() => {
    router.push(SMLRoutes.all);
  }, [router]);

  const goToSmartLinksManageView = useCallback(
    (smartLinkId: number) => {
      router.push(SMLRoutes.manage, { accountId, smartLinkId });
    },
    [router, accountId]
  );

  if (smartLinksError) {
    return <ErrorView error={smartLinksError} retry={smartLinksRefetch} />;
  }
  if (accountError) {
    return <ErrorView error={accountError} retry={accountRefetch} />;
  }
  if (!accountResponse || isLoadingAccount) {
    return <LoadingView />;
  }

  return (
    <SMLHomeViewRenderer
      account={accountResponse.account}
      smartLinksResponse={smartLinksResponse}
      smartLinksLoading={smartLinksLoading}
      onSmartLinkClick={goToSmartLinksManageView}
      onSeeAllClick={goToSMLListView}
      hasMoreSmartLinks={hasMoreSmartLinks}
    />
  );
};

export const SMLHomeView = TypedMemo(SMLHomeViewComponent);
