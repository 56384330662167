import { GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { CollectionAccess, CollectionType } from '../../generics/models/Collection';
import { NapsterAPI } from './NapsterAPI';
import { NapsterPlaylist } from './models/NapsterPlaylist';
import { NapsterAuthenticationData } from './NapsterAuthenticationData';
import { FetchError } from '../../generics/errors/FetchError';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { NapsterAlbum } from './models/NapsterAlbum';
import { SearchQueryProperties } from '../../generics/types';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { ImporterID } from '../types';
import { napster } from '../../musicServices/services/Napster';
import { moveItemsByOffset } from '../../utils/moveItemsByOffset';
import { refreshAuthData } from '../../musicApi/utils/refreshAuthData';
import { MusicAPIIntegrationID } from '../../musicServices/types';
import { NapsterMySongs } from './models/NapsterMySongs';
import { NapsterMatchedTrack } from './models/NapsterMatchedTrack';

const ClientId = 'MTJlY2QyZTMtYTJiNS00MDM4LTg3YTgtZmZjZWZhNWJlMmNk';

const createNapsterInstance = (authenticationData: NapsterAuthenticationData): NapsterAPI => {
  return new NapsterAPI(authenticationData.additionalData.accessToken, authenticationData.authId, ClientId);
};

export const NapsterImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.Napster;

  public static musicService = napster;

  public authenticationData: NapsterAuthenticationData;

  private napsterApi: NapsterAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as NapsterAuthenticationData;
    this.napsterApi = createNapsterInstance(this.authenticationData);
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.authenticationData = authenticationData as NapsterAuthenticationData;
    this.napsterApi = createNapsterInstance(this.authenticationData);
  }

  async getPaginatedCollections(
    onBatch: (collections: (NapsterPlaylist | NapsterAlbum)[]) => Promise<void>
  ): Promise<void> {
    const { totalTracks } = await this.napsterApi.loadLikedTracksItemPage(0, 1);
    const likedTracks = new NapsterMySongs(this.napsterApi.userId, totalTracks);
    await onBatch([likedTracks]);
    await this.napsterApi.loadPaginatedPlaylists(onBatch);
    await this.napsterApi.loadPaginatedAlbums(onBatch);
  }

  async getCollection(collection: GenericCollection): Promise<NapsterPlaylist | NapsterAlbum> {
    let result: NapsterPlaylist | NapsterAlbum | null;
    if (collection.type === CollectionType.ALBUM) {
      result = await this.napsterApi.getAlbum(collection.rawId);
    } else {
      result = await this.napsterApi.getPlaylist(collection.rawId);
    }

    if (!result) {
      throw new CollectionDoesNotExistsError();
    }
    return result;
  }

  async createCollection(collection: GenericCollection, description?: string): Promise<NapsterPlaylist> {
    let newCollection: NapsterPlaylist | null = null;
    try {
      newCollection = await this.napsterApi.createPlaylist(collection.name, { description });
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!newCollection) {
      throw new CouldNotCreateCollection();
    }
    return newCollection;
  }

  async addItemToCollection(collection: GenericCollection, item: GenericMatchedItem) {
    await this.napsterApi.addTracksToPlaylist(collection.rawId, [item.rawId]);
    return convertMatchedItemToCollectionItem(item);
  }

  async addManyItemsToCollection(
    collection: GenericCollection,
    data: {
      matchedItem: GenericMatchedItem;
      position?: number;
    }[]
  ) {
    const tracksIds = data.map(({ matchedItem }) => matchedItem.rawId);
    await this.napsterApi.addTracksToPlaylist(collection.rawId, tracksIds);
  }

  async matchItems(queryProps: SearchQueryProperties): Promise<GenericMatchedItem[]> {
    let tracks: NapsterMatchedTrack[];
    if (queryProps.isrc) {
      ({ tracks } = await this.napsterApi.searchByISRC({ isrc: queryProps.isrc }));
    } else {
      ({ tracks } = await this.napsterApi.search({ queryProps, type: 'track' }));
    }
    return tracks;
  }

  async matchAlbums(queryProps: SearchQueryProperties) {
    const { albums } = await this.napsterApi.search({ queryProps, type: 'album' });
    return albums;
  }

  async addAlbumToLibrary(album: GenericCollection): Promise<void> {
    await this.napsterApi.addAlbumToLibrary(album.rawId);
  }

  async moveManyItems(props: Parameters<NonNullable<GenericImporter['moveManyItems']>>[0]) {
    const { collection, allItems, itemsToMove, offset } = props;
    const movedItems = moveItemsByOffset({ allItems, itemsToMove, offset });
    const movedItemsRawIds = movedItems.map(({ rawId }) => rawId);
    await this.napsterApi.moveTrackInPlaylist(collection.rawId, movedItemsRawIds);
  }

  async updateCollection(
    collection: GenericCollection,
    props: { name?: string; access?: CollectionAccess; description?: string }
  ) {
    const { name, access, description } = props;
    const isPublic = access === undefined ? undefined : access === CollectionAccess.public;
    await this.napsterApi.updatePlaylist(collection.rawId, { name, isPublic, description });
  }

  async removeCollection(collection: GenericCollection): Promise<void> {
    await this.napsterApi.removePlaylist(collection.rawId);
  }

  async reAuthenticate(
    _withData: GenericAuthenticationData,
    forceFetchRefreshTokens?: boolean
  ): Promise<GenericAuthenticationData> {
    return refreshAuthData({
      authId: _withData.authId,
      userUUID: _withData.additionalData?.userUUID ?? null,
      integrationId: MusicAPIIntegrationID.Napster,
      forceFetchRefreshTokens,
    });
  }

  doesSupportReAuth() {
    return true;
  }

  public doesSupportAlbums(): boolean {
    return true;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return true;
  }

  public doesSupportSearchByISRC(): boolean {
    return true;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return false;
  }

  public doesSupportMovingManyItems(): boolean {
    return true;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    switch (forCollection.type) {
      case CollectionType.MY_SONGS:
        return this.napsterApi.loadPaginatedLikedTracks(onBatch);
      case CollectionType.PLAYLIST:
      case CollectionType.LIKED_PLAYLIST:
        return this.napsterApi.loadPaginatedPlaylistItems(forCollection.rawId, onBatch);
      case CollectionType.ALBUM:
        return this.napsterApi.loadPaginatedAlbumItems(forCollection.rawId, onBatch);
      default:
        return undefined;
    }
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    itemsToRemove: GenericCollectionItem[]
  ): Promise<void> {
    if (collection.type !== CollectionType.PLAYLIST || itemsToRemove.length === 0) {
      return;
    }
    const itemsIdsToRemove = itemsToRemove.map((item) => item.rawId);
    await this.napsterApi.removeTracksFromPlaylist(collection.rawId, itemsIdsToRemove);
  }

  async clearCollection(collection: GenericCollection) {
    if (collection.type !== CollectionType.PLAYLIST) {
      return;
    }
    await this.napsterApi.updatePlaylist(collection.rawId, { trackIds: [] });
  }

  async getCollectionPublicUrl(collection: GenericCollection) {
    if (collection.type !== CollectionType.PLAYLIST) {
      return null;
    }
    await this.napsterApi.publishPlaylist(collection.rawId);
    return `https://play.napster.com/playlist/${collection.rawId}?ocode=social_user&pcode=social_user&cpath=Link&rsrc=playlist`;
  }
};
