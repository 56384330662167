import { GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { CollectionType } from '../../generics/models/Collection';
import { AppleMusicAPI } from './AppleMusicAPI';
import { AppleMusicPlaylist } from './models/AppleMusicPlaylist';
import { FetchError } from '../../generics/errors/FetchError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { AppleMusicMySongs } from './models/AppleMusicMySongs';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { AppleMusicAuthenticationData } from './AppleMusicAuthenticationData';
import { AppleMusicAlbum } from './models/AppleMusicAlbum';
import { SearchQueryProperties } from '../../generics/types';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { convertQueryPropsToString } from '../services/MatchingService.helpers';
import { ImporterID } from '../types';
import { appleMusic } from '../../musicServices/services/AppleMusic';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';

const createAppleMusicInstance = (authenticationData: AppleMusicAuthenticationData): AppleMusicAPI => {
  return new AppleMusicAPI(
    authenticationData.additionalData.musicUserToken,
    authenticationData.additionalData.jwtToken,
    authenticationData.authId
  );
};

export const AppleMusicImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.AppleMusic;

  public static musicService = appleMusic;

  public static areTrackEntryIdAndGlobalIdDifferent = true;

  public authenticationData: AppleMusicAuthenticationData;

  private appleMusicApi: AppleMusicAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as AppleMusicAuthenticationData;
    this.appleMusicApi = createAppleMusicInstance(this.authenticationData);
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.authenticationData = authenticationData as AppleMusicAuthenticationData;
    this.appleMusicApi = createAppleMusicInstance(this.authenticationData);
  }

  private async getMySongsCollection() {
    const { total } = await this.appleMusicApi.loadAllSongsPage(0, 1);
    return new AppleMusicMySongs(this.appleMusicApi.userId, total);
  }

  async getPaginatedCollections(
    onBatch: (collections: (AppleMusicPlaylist | AppleMusicMySongs)[]) => Promise<void>
  ): Promise<void> {
    const mySongs = await this.getMySongsCollection();
    await onBatch([mySongs]);
    await this.appleMusicApi.loadPaginatedPlaylists(onBatch);
    await this.appleMusicApi.loadPaginatedAlbums(onBatch);
  }

  async getCollection(collection: GenericCollection): Promise<AppleMusicPlaylist | AppleMusicAlbum> {
    let result: AppleMusicPlaylist | AppleMusicAlbum | AppleMusicMySongs | null;
    if (collection.type === CollectionType.ALBUM) {
      result = await this.appleMusicApi.getAlbum(collection.rawId);
    } else if (collection.type === CollectionType.MY_SONGS) {
      result = await this.getMySongsCollection();
    } else {
      result = await this.appleMusicApi.getPlaylist(collection.rawId);
    }
    if (!result) {
      throw new CollectionDoesNotExistsError();
    }
    return result;
  }

  async createCollection(collection: GenericCollection, description?: string): Promise<AppleMusicPlaylist> {
    let newCollection: AppleMusicPlaylist | null = null;
    try {
      newCollection = await this.appleMusicApi.createPlaylist(collection.name, description);
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!newCollection) {
      throw new CouldNotCreateCollection();
    }
    return newCollection;
  }

  async addItemToCollection(collection: GenericCollection, matchedItem: GenericMatchedItem) {
    if (collection.type === CollectionType.MY_SONGS) {
      await this.appleMusicApi.addResourcesToLibrary({ tracksIds: [matchedItem.rawId] });
    } else {
      await this.appleMusicApi.addTracksToPlaylist(collection.rawId, [matchedItem.rawId]);
    }
    return convertMatchedItemToCollectionItem(matchedItem, AppleMusicImporter.areTrackEntryIdAndGlobalIdDifferent);
  }

  async addManyItemsToCollection(
    collection: GenericCollection,
    data: {
      matchedItem: GenericMatchedItem;
      position?: number;
    }[]
  ) {
    const tracksIds = data.map(({ matchedItem }) => matchedItem.rawId);
    if (collection.type === CollectionType.MY_SONGS) {
      await this.appleMusicApi.addResourcesToLibrary({ tracksIds });
    } else {
      await this.appleMusicApi.addTracksToPlaylist(collection.rawId, tracksIds);
    }
  }

  async matchItems(queryProps: SearchQueryProperties) {
    if (queryProps.isrc) {
      return (await this.appleMusicApi.getTracksByISRC(queryProps.isrc)).tracks;
    }
    const query = convertQueryPropsToString(queryProps);
    const { tracks } = await this.appleMusicApi.search({ query, searchType: 'songs' });
    return tracks;
  }

  async matchAlbums(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const { albums } = await this.appleMusicApi.search({ query, searchType: 'albums' });
    return albums;
  }

  async addAlbumToLibrary(album: GenericCollection) {
    await this.appleMusicApi.addResourcesToLibrary({ albumsIds: [album.rawId] });
  }

  async reAuthenticate(_withData: GenericAuthenticationData): Promise<GenericAuthenticationData> {
    return Promise.reject(new Error('Apple Music does not support reauthentication for public api'));
    // Renewing token should be done when it is still valid...
    // if (!this.doesSupportReAuth()) {
    //   throw new Error('Apple Music does not support reauthentication for public api');
    // }
    // const newMusicUserToken = await AppleMusicAPI.renewMusicToken(
    //   withData.additionalData.jwtToken,
    //   withData.additionalData.musicUserToken
    // );
    // return {
    //   ...withData,
    //   additionalData: {
    //     musicUserToken: newMusicUserToken
    //   }
    // };
  }

  doesSupportReAuth(): boolean {
    return false;
  }

  public doesSupportAlbums(): boolean {
    return true;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return false;
  }

  public doesSupportMovingManyItems(): boolean {
    return false;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }

  public doesSupportRemovingTracks(): boolean {
    return false;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return false;
  }

  public doesSupportSearchByISRC(): boolean {
    return true;
  }

  public doesSupportAddingItemsToLikedSongs(): boolean {
    return true;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    switch (forCollection.type) {
      case CollectionType.PLAYLIST:
      case CollectionType.LIKED_PLAYLIST:
        return this.appleMusicApi.loadPaginatedPlaylistsItems(forCollection.rawId, onBatch);
      case CollectionType.MY_SONGS:
        return this.appleMusicApi.loadPaginatedSongs(onBatch);
      case CollectionType.ALBUM:
        return this.appleMusicApi.loadPaginatedAlbumItems(forCollection.rawId, onBatch);
      default:
        return undefined;
    }
  }

  async getStatsData() {
    const recentlyPlayedTracks = await this.appleMusicApi.getAllRecentlyPlayedTracks();
    const recentlyAddedResources = await this.appleMusicApi.getAllAddedResources();
    const recommendations = await this.appleMusicApi.getDefaultRecommendations();
    return {
      recentlyPlayedTracks,
      recentlyAddedResources,
      recommendations,
    };
  }

  updateCollection: undefined;

  removeCollection: undefined;
};
