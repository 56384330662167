import { CollectionItemModelData, GenericCollectionItem } from '../../../generics/models/GenericCollectionItem';
import { buildCoverUrl, createTidalTrackUrl, getArtistsNamesAsString } from '../utils';
import { TidalAlbum } from './TidalAlbum';
import { TidalArtist } from './TidalArtist';
import { convertTidalTrackData } from '../utils/convertTidalTrackData';

export class TidalCollectionTrack extends GenericCollectionItem {
  constructor(
    data: CollectionItemModelData,
    public albumExtended: TidalAlbum | null = null,
    public artists: TidalArtist[] | null = null
  ) {
    super(data);
  }

  static fromData(data: any): TidalCollectionTrack | null {
    const convertedData = convertTidalTrackData(data);
    if (!convertedData) {
      return null;
    }
    const { trackData, album, artists } = convertedData;
    return new TidalCollectionTrack({ ...trackData, globalId: trackData.rawId }, album, artists);
  }

  static fromAlbumData(data: any): TidalCollectionTrack | null {
    if (!data?.id || !data.title) {
      return null;
    }
    const id = `${data.id}`;
    return new TidalCollectionTrack({
      rawId: id,
      globalId: id,
      name: data.title,
      album: data.album.title,
      artist: getArtistsNamesAsString(data.artists),
      additionalData: { href: data.url },
      imageUrl: buildCoverUrl(data.album.cover),
      sourceURL: createTidalTrackUrl(data.id),
    });
  }
}
