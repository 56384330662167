export function convertCookiesStringToObject(cookies: string): Record<string, string> {
  const cookiesArray = cookies.split('; ');
  const cookiesObject: Record<string, string> = {};
  cookiesArray.forEach((cookie) => {
    const cookieParts = cookie.split('=');
    const key = cookieParts.shift();
    if (key) {
      const value = cookieParts.join('=');
      cookiesObject[key] = value;
    }
  });
  return cookiesObject;
}

export function convertCookiesObjectToString(cookiesObject: Record<string, string>): string {
  return Object.entries(cookiesObject)
    .map(([key, value]) => `${key}=${value}`)
    .join('; ');
}

export function mergeCookiesString(cookie1: string, cookie2: string) {
  const cookieObject1 = convertCookiesStringToObject(cookie1);
  const cookieObject2 = convertCookiesStringToObject(cookie2);
  const finalCookies = {
    ...cookieObject1,
    ...cookieObject2,
  };
  return convertCookiesObjectToString(finalCookies);
}
