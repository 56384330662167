import { getContinuationToken } from '../youTubeMusicParser';
import { YouTubeMusicAlbum } from './YouTubeMusicAlbum';

export class YouTubeMusicAlbumsResponse {
  private readonly data: any;

  private readonly items: any;

  constructor(data: any, items: any) {
    this.data = data;
    this.items = items;
  }

  get albums(): YouTubeMusicAlbum[] {
    if (!this.items) {
      return [];
    }
    return this.items
      .filter((item: any) => !!item.musicTwoRowItemRenderer.title)
      .map((item: any) => YouTubeMusicAlbum.fromData(item.musicTwoRowItemRenderer))
      .filter((album: YouTubeMusicAlbum | null) => !!album);
  }

  get continuationToken() {
    return getContinuationToken(this.data);
  }
}
