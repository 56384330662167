import { TidalPlaylist } from './TidalPlaylist';

export class TidalPlaylistsResponse {
  private readonly data: any;

  private readonly userId?: string;

  constructor(data: any, userId?: string) {
    this.data = data;
    this.userId = userId;
  }

  get playlists(): TidalPlaylist[] {
    if (!this.data?.items) {
      return [];
    }
    return this.data.items
      .map((item: any) => TidalPlaylist.fromData(item.playlist, this.userId))
      .filter((playlist: TidalPlaylist | null) => !!playlist);
  }

  get totalNumberOfItems(): number {
    return this.data.totalNumberOfItems;
  }
}
