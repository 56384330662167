import uniq from 'lodash/uniq';
import { Artist } from './models/JioSaavnResponseModels';
import { artistsToString } from '../../utils/artistsConverter';
import { tryParseDurationToMs } from '../../utils/tryParseDurationToMs';

export function getArtistsNamesAsString(artists: Artist[] | undefined) {
  if (!artists || artists.length === 0) {
    return '';
  }

  const artistsNames = artists.reduce((result, artist) => {
    if (artist.name) {
      result.push(artist.name);
    }
    return result;
  }, [] as string[]);

  const uniqueArtistsNames = uniq(artistsNames);

  return artistsToString(uniqueArtistsNames);
}

export function convertJioSaavnTrackData(data: any) {
  if (!data?.id || !data.title) {
    return null;
  }
  return {
    rawId: `${data.id}`,
    name: data.title,
    album: data.more_info?.album,
    artist: data.more_info?.music,
    imageUrl: data.image,
    duration: tryParseDurationToMs(data.more_info?.duration, 's'),
    previewUrl: data.more_info?.vlink,
    sourceURL: data.perma_url,
  };
}
