import { GenericCollectionItem } from '../../../generics/models/GenericCollectionItem';
import { QobuzAlbum } from './QobuzAlbum';
import { convertQobuzTrackData, createQobuzTrackUrl } from '../utils';

export class QobuzCollectionTrack extends GenericCollectionItem {
  static fromData(data: any): QobuzCollectionTrack | null {
    const trackData = convertQobuzTrackData(data);
    return trackData ? new QobuzCollectionTrack({ ...trackData, globalId: trackData.rawId }) : null;
  }

  static fromAlbumTrackData(data: any, album: QobuzAlbum): QobuzCollectionTrack | null {
    if (!data?.id || !data.title) {
      return null;
    }
    const id = `${data.id}`;
    return new QobuzCollectionTrack({
      rawId: id,
      globalId: id,
      name: data.title,
      album: album.name,
      artist: album.additionalData?.artist,
      imageUrl: album.additionalData?.imageUrl,
      sourceURL: createQobuzTrackUrl(data.id),
    });
  }
}
