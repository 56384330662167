import React, { useCallback, useState } from 'react';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { TypedMemo } from '../../common/types';
import { ReactComponent as SmartLinksLogo } from '../../../assets/logos_sml/logo-no-padding.svg';
import { AccountSelectorButton } from '../accountSelectorButton/AcccountSelectorButton';
import { useShouldShowNavbar } from './hooks/useShouldShowNavbar';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { useSMLRouter } from '../../../routing/sml/hooks';

export const SmartLinksNavbarComponent: React.FC = React.memo(() => {
  const intl = useIntl();
  const router = useSMLRouter();
  const shouldShowNavbar = useShouldShowNavbar();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleNavbar = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  const closeNavbar = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onHomeClick = useCallback(() => {
    closeNavbar();
    router.push(SMLRoutes.home);
  }, [router, closeNavbar]);

  const onSettingsClick = useCallback(() => {
    closeNavbar();
    router.push(SMLRoutes.settings);
  }, [router, closeNavbar]);

  if (!shouldShowNavbar) {
    return null;
  }

  return (
    <Navbar expand="md" className="sml-navbar" sticky="top" container>
      <NavbarBrand onClick={onHomeClick} className="cursor-pointer">
        <SmartLinksLogo width={50} />
      </NavbarBrand>
      <NavbarToggler onClick={toggleNavbar}>
        <FontAwesomeIcon icon={faBars} color="#000000" />
      </NavbarToggler>
      <Collapse navbar isOpen={isOpen}>
        <Nav navbar className="ms-auto align-items-end align-items-md-center">
          <NavItem onClick={onHomeClick} className="cursor-pointer">
            <NavLink>
              {intl.formatMessage({
                id: 'web.nav.smart-links.navbar.home',
                defaultMessage: 'Home',
              })}
            </NavLink>
          </NavItem>
          <NavItem onClick={onSettingsClick} className="cursor-pointer">
            <NavLink>
              {intl.formatMessage({
                id: 'web.nav.smart-links.navbar.settings',
                defaultMessage: 'Settings',
              })}
            </NavLink>
          </NavItem>
          <AccountSelectorButton closeNavbar={closeNavbar} />
        </Nav>
      </Collapse>
    </Navbar>
  );
});

export const SmartLinksNavbar = TypedMemo(SmartLinksNavbarComponent);
