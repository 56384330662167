import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { store } from '../../../store/Store';
import { LicenseInfo } from '../../../realm/LicenseInfo';
import { selectLicense } from './selectors';
import { useSetappLicenseState } from './hookSetapp';

export const useLicense = () => useSelector(selectLicense);

export function useLicenseInfo() {
  const setappLicenseState = useSetappLicenseState();
  const licenseState = useLicense();
  return useMemo(() => new LicenseInfo(setappLicenseState || licenseState), [setappLicenseState, licenseState]);
}

export function usePersistLicenseState() {
  const setappLicenseState = useSetappLicenseState();
  const licenseState = useLicense();
  useEffect(() => {
    store.set('license', setappLicenseState || licenseState).catch(console.error);
  }, [setappLicenseState, licenseState]);
}
