import { GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionAccess, CollectionType } from '../../../generics/models/Collection';
import { createNapsterPlaylistUrl, getBiggestImage, getCoverUrlFromResourceId } from '../utils';

function convertPrivacyToAccess(privacy: 'private' | 'public' | undefined) {
  switch (privacy) {
    case 'private':
      return CollectionAccess.private;
    case 'public':
      return CollectionAccess.public;
    default:
      return undefined;
  }
}

export class NapsterPlaylist extends GenericCollection {
  static fromData(data: any, type: CollectionType): NapsterPlaylist | null {
    if (!data?.id || !data.name) {
      return null;
    }
    return new NapsterPlaylist({
      type,
      rawId: data.id,
      name: data.name,
      itemCount: data.trackCount,
      followers: data.favoriteCount,
      publicUrl: createNapsterPlaylistUrl(data.id),
      additionalData: {
        imageUrl: getBiggestImage(data.images) ?? getCoverUrlFromResourceId(data.id),
        description: data.description,
      },
      access: convertPrivacyToAccess(data.privacy),
    });
  }
}
