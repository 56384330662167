import { AmazonMusicPlaylist } from './AmazonMusicPlaylist';
import { Playlist, PlaylistTrackConnection } from './types';
import { AmazonMusicCollectionTrack } from './AmazonMusicCollectionTrack';

interface PlaylistWithTracksResponse {
  data?: {
    playlist?: Playlist & { tracks?: PlaylistTrackConnection };
  };
}

export class AmazonMusicPlaylistWithTracksResponse {
  private readonly data;

  private readonly userId;

  constructor(data: PlaylistWithTracksResponse | undefined, userId: string | undefined) {
    this.data = data;
    this.userId = userId;
  }

  get playlist() {
    return AmazonMusicPlaylist.fromData(this.data?.data?.playlist, this.userId);
  }

  get tracks() {
    return (
      this.data?.data?.playlist?.tracks?.edges
        ?.map((edge) => AmazonMusicCollectionTrack.fromData(edge?.node, { cursor: edge?.cursor }))
        .filter((track): track is AmazonMusicCollectionTrack => !!track) ?? []
    );
  }

  get pageInfoToken() {
    return this.data?.data?.playlist?.tracks?.pageInfo?.token;
  }

  get hasNextPage() {
    return this.data?.data?.playlist?.tracks?.pageInfo?.hasNextPage;
  }

  get total() {
    return this.data?.data?.playlist?.trackCount;
  }
}
