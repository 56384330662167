import { QQMusicPlaylistWithTracksData } from '../types';
import { QQMusicPlaylist } from './QQMusicPlaylist';
import { QQMusicCollectionTrack } from './QQMusicCollectionTrack';
import { CollectionType } from '../../../generics/models/Collection';
import { tryParseInt } from '../../../utils/tryParseInt';

export class QQMusicPlaylistWithTracksResponse {
  private readonly data;

  private readonly collectionType;

  constructor(data: QQMusicPlaylistWithTracksData | undefined, collectionType: CollectionType) {
    this.data = data;
    this.collectionType = collectionType;
  }

  get playlist(): QQMusicPlaylist | null {
    if (!this.data?.Title || (!this.data.uin && !this.data.DirID)) {
      return null;
    }
    return new QQMusicPlaylist({
      type: this.collectionType,
      rawId: `${this.data.uin}-${this.data.DirID}`,
      name: this.data.Title,
      itemCount: tryParseInt(this.data.SongCount),
      additionalData: { imageUrl: this.data.PicUrl },
    });
  }

  get tracks(): QQMusicCollectionTrack[] {
    if (!this.data?.SongList) {
      return [];
    }
    return this.data.SongList.map((track) => QQMusicCollectionTrack.fromData(track)).filter(
      (track): track is QQMusicCollectionTrack => !!track
    );
  }
}
