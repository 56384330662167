import { ZvukMatchedTrack } from './ZvukMatchedTrack';
import { ZvukAlbum } from './ZvukAlbum';

export class ZvukSearchResponse {
  private readonly data;

  constructor(data: Record<string, any> | null) {
    this.data = data;
  }

  get tracks(): ZvukMatchedTrack[] {
    if (!this.data?.search?.tracks?.items) {
      return [];
    }
    return this.data.search.tracks.items
      .map((item: any) => ZvukMatchedTrack.fromData(this.data?.tracks[item.id]))
      .filter((track: ZvukMatchedTrack | null) => !!track);
  }

  get releases(): ZvukAlbum[] {
    if (!this.data?.search?.releases?.items) {
      return [];
    }
    return this.data.search.releases.items
      .map((item: any) => ZvukAlbum.fromData(this.data?.releases[item.id]))
      .filter((album: ZvukAlbum | null) => !!album);
  }

  get firstTrack(): ZvukMatchedTrack | undefined {
    if (this.tracks.length === 0) {
      return undefined;
    }
    return this.tracks[0];
  }

  get firstRelease(): ZvukAlbum | undefined {
    if (this.releases.length === 0) {
      return undefined;
    }
    return this.releases[0];
  }
}
