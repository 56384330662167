import React, { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize, words } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { TypedMemo } from '../../types';
import { SMLButtonProps } from './props';
import { LoadingIcon } from '../LoadingIcon';

const SMLButtonComponent: React.FC<React.PropsWithChildren<SMLButtonProps>> = ({
  children,
  color = 'primary',
  className,
  disabled = false,
  onClick,
  testID = '',
  doNotPassEvent = false,
  size = 'md',
  outline = false,
  leftIcon,
  rightIcon,
  isLoading = false,
  error,
  stretch = false,
  omitCap = false,
  submit = false,
  tooltip,
}) => {
  const uniqueId = useMemo(() => `smlbutton-${uuidv4()}`, []);
  const [tooltipText, setTooltipText] = useState<string | undefined>();

  const handleOnClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      if (!onClick || submit) {
        return;
      }
      onClick(doNotPassEvent ? undefined : event);
    },
    [doNotPassEvent, onClick, submit]
  );

  const onTooltipClick = useCallback(() => {
    setTooltipText(undefined);
  }, []);

  useEffect(() => {
    if (!error && !tooltip) {
      return;
    }
    setTooltipText(error?.message ?? tooltip);
  }, [error, tooltip]);

  const hasChildren = React.Children.count(children) !== 0;

  return (
    <>
      <Button
        id={uniqueId}
        disabled={disabled || isLoading}
        onClick={handleOnClick}
        color={color}
        size={size}
        outline={outline}
        type={submit ? 'submit' : 'button'}
        className={classnames(className, testID, {
          outline,
          'w-100': stretch,
        })}
      >
        {!isLoading && leftIcon !== undefined && (
          <FontAwesomeIcon icon={leftIcon} className={classnames({ 'me-2': hasChildren })} />
        )}
        {isLoading && (
          <>
            <LoadingIcon small />
            {hasChildren ? ' ' : null}
          </>
        )}
        {typeof children === 'string' && !omitCap ? words(children).map(capitalize).join(' ') : children}
        {!isLoading && rightIcon !== undefined && (
          <FontAwesomeIcon icon={rightIcon} className={classnames({ 'ms-2': hasChildren })} />
        )}
      </Button>
      <Tooltip
        target={uniqueId}
        isOpen={tooltipText !== undefined}
        className={classnames({ 'sml-button-tooltip-error': !!error })}
        onClick={onTooltipClick}
      >
        {tooltipText}
      </Tooltip>
    </>
  );
};

export const SMLButton = TypedMemo(SMLButtonComponent);
