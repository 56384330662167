import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { tryParseInt } from 'fym-common/src/utils/tryParseInt';
import { TypedMemo } from '../../common/types';
import { useCreateAccountMutation, useUpdateAccountMutation } from '../../../redux/api/fymAPI';
import { isSuccessfulResult } from '../../../redux/api/utils/isSuccessfulResult';
import { SMLOnboardingFormStep1 } from '../../forms/SMLOnboardingFormStep1/SMLOnboardingFormStep1';
import { SMLSetUpContainer } from '../smlSetUpContainer/SMLSetUpContainer';
import { useLocalStore } from '../../common/storeHooks';
import { SMLOnboardingFormStep1Props } from '../../forms/SMLOnboardingFormStep1/props';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { useQueryParam } from '../../../utils/useQueryParam';
import { SMLAnalytics } from '../../analytics/sml/analytics';

interface SMLOnboarding1ViewProps {
  route?: { params: { accountId?: string } };
}

const SMLOnboarding1ViewComponent: React.FC<SMLOnboarding1ViewProps> = ({ route }) => {
  const [createAccount, { isLoading, error }] = useCreateAccountMutation();
  const [updateAccount, { isLoading: isLoadingUpdateAccount, error: isLoadingErrorAccount }] =
    useUpdateAccountMutation();
  const accountIdString = useQueryParam('accountId', route, '');
  const accountId = tryParseInt(accountIdString);
  const withName = accountId === undefined;
  const intl = useIntl();
  const router = useSMLRouter();
  const { setValue: setOnboardingSMLFinishedStep1 } = useLocalStore('onboardingSMLFinishedStep1', false);

  const handleCreateAccount: SMLOnboardingFormStep1Props['onSubmit'] = useCallback(
    async ({ slug, name }) => {
      const createOrUpdateAccount =
        accountId === undefined ? await createAccount({ name, slug }) : await updateAccount({ id: accountId, slug });

      if (!isSuccessfulResult(createOrUpdateAccount)) {
        return;
      }
      setOnboardingSMLFinishedStep1(true)
        .then(() => {
          router.replace(SMLRoutes.onboarding2);
          SMLAnalytics.trackOnboardingStep1Finished().catch(console.error);
        })
        .catch(console.error);
    },
    [accountId, createAccount, router, setOnboardingSMLFinishedStep1, updateAccount]
  );

  useEffect(() => {
    SMLAnalytics.trackOnboardingStep1Started().catch(console.error);
  });

  return (
    <SMLSetUpContainer
      title={intl.formatMessage({
        id: 'app.sml.onboarding.title',
        defaultMessage: 'Hello! Just a few things before we start',
      })}
    >
      <SMLOnboardingFormStep1
        withName={withName}
        onSubmit={handleCreateAccount}
        isLoading={isLoading || isLoadingUpdateAccount}
        error={error || isLoadingErrorAccount}
      />
    </SMLSetUpContainer>
  );
};

export const SMLOnboarding1View = TypedMemo(SMLOnboarding1ViewComponent);
