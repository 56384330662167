import React, { CSSProperties } from 'react';
import { TypedMemo } from '../../types';
import { FYMImageProps } from './props';

const FYMImageComponent: React.FC<FYMImageProps> = ({ source, alt = '', width, height }) => {
  const styles: CSSProperties = { maxWidth: '100%', maxHeight: '100%', width, height, objectFit: 'contain' };
  return <img src={source as any} alt={alt} style={styles} />;
};

export const FYMImage = TypedMemo(FYMImageComponent);
