import { VKCollectionTrack } from './VKCollectionTrack';
import { VKPlaylist } from './VKPlaylist';

export class VKPlaylistWithTracksResponse {
  private readonly data: any;

  private readonly userId: string;

  constructor(data: any, userId: string) {
    this.data = data;
    this.userId = userId;
  }

  get playlist(): VKPlaylist | null {
    return VKPlaylist.fromData(this.data.payload[1][0], this.userId);
  }

  get tracks(): VKCollectionTrack[] {
    return this.data.payload[1][0].list
      .map((item: any) => {
        return VKCollectionTrack.fromData(item);
      })
      .filter((t: any): t is VKCollectionTrack => {
        return t !== undefined;
      });
  }

  get nextOffset(): number {
    return this.data.payload[1][0].nextOffset;
  }

  get hasMore(): boolean {
    return this.data.payload[1][0] && this.data.payload[1][0].hasMore !== 0;
  }
}
