import React from 'react';
import { TypedMemo } from '../../common/types';
import { DownloadLinksRenderer } from './DownloadLinksRenderer';
import { useGetAppsLinksQuery } from '../../../redux/api/fymAPI';
import { config } from '../../../config/config';
import { ErrorView } from '../../common/views/ErrorView/ErrorView';
import { LoadingView } from '../../common/views/LoadingView';

const DownloadLinksComponent: React.FC = () => {
  const { data, isLoading, error, refetch } = useGetAppsLinksQuery({
    app: config.appReleaseLinks.app,
    channel: config.appReleaseLinks.channel,
  });

  if (error) {
    return <ErrorView error={error} retry={refetch} />;
  }

  if (!data || isLoading) {
    return <LoadingView />;
  }

  return <DownloadLinksRenderer data={data} />;
};

export const DownloadLinks = TypedMemo(DownloadLinksComponent);
