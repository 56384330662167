import { artistsToString } from '../../utils/artistsConverter';

export function buildCoverUrl(image: { src?: string } | undefined) {
  return image?.src ? `${image.src.replace('{size}', '800x800')}` : undefined;
}

export function getArtistsNamesAsString(artists: string[] | undefined) {
  if (!artists || artists.length === 0) {
    return '';
  }
  return artistsToString(artists);
}

const zvukPublicResourceUrl = 'https://zvuk.com';

export function createZvukAlbumUrl(albumId: string) {
  return `${zvukPublicResourceUrl}/release/${albumId}`;
}

export function createZvukPlaylistUrl(playlistId: string) {
  return `${zvukPublicResourceUrl}/playlist/${playlistId}`;
}

export function createZvukTrackUrl(trackId: string) {
  return `${zvukPublicResourceUrl}/track/${trackId}`;
}

export function createZvukArtistUrl(artistId: string) {
  return `${zvukPublicResourceUrl}/artist/${artistId}`;
}

export function convertZvukTrackData(data: any) {
  if (!data?.id || !data.title) {
    return null;
  }
  return {
    rawId: `${data.id}`,
    name: data.title,
    album: data.release_title,
    artist: getArtistsNamesAsString(data.artist_names),
    imageUrl: buildCoverUrl(data.image),
    duration: data.duration ? data.duration * 1000 : undefined,
    sourceURL: createZvukTrackUrl(data.id),
  };
}
