import { GenericCollectionItem } from '../../../generics/models/GenericCollectionItem';
import { SoundClodTrackRawResponse } from './responseTypes';
import { convertSoundCloudTrackData } from '../utils/convertSoundCloudTrackData';

export class SoundCloudCollectionTrack extends GenericCollectionItem {
  static fromData(data: SoundClodTrackRawResponse | undefined, url?: string): SoundCloudCollectionTrack | null {
    const trackData = convertSoundCloudTrackData(data, url);
    return trackData ? new SoundCloudCollectionTrack({ ...trackData, globalId: trackData.rawId }) : null;
  }
}
