import { GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { CollectionType } from '../../generics/models/Collection';
import { GaanaPlaylist } from './models/GaanaPlaylist';
import { FetchError } from '../../generics/errors/FetchError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { GaanaAuthenticationData } from './GaanaAuthenticationData';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { GaanaAlbum } from './models/GaanaAlbum';
import { GaanaAPI } from './GaanaAPI';
import { GaanaLikedTracks } from './models/GaanaLikedTracks';
import { SearchQueryProperties } from '../../generics/types';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { convertQueryPropsToString } from '../services/MatchingService.helpers';
import { ImporterID } from '../types';
import { gaana } from '../../musicServices/services/Gaana';

const createGaanaInstance = (authenticationData: GaanaAuthenticationData): GaanaAPI => {
  return new GaanaAPI(authenticationData.additionalData.cookies, authenticationData.authId);
};

export const GaanaImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.Gaana;

  public static musicService = gaana;

  public authenticationData: GaanaAuthenticationData;

  private gaanaApi: GaanaAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as GaanaAuthenticationData;
    this.gaanaApi = createGaanaInstance(this.authenticationData);
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.authenticationData = authenticationData as GaanaAuthenticationData;
    this.gaanaApi = createGaanaInstance(this.authenticationData);
  }

  async getPaginatedCollections(
    onBatch: (collections: (GaanaPlaylist | GaanaAlbum)[]) => Promise<void>
  ): Promise<void> {
    const likedTracks = new GaanaLikedTracks(this.gaanaApi.userId);
    await onBatch([likedTracks]);

    await this.gaanaApi.loadPaginatedPlaylists(onBatch);
    await this.gaanaApi.loadPaginatedLikedPlaylists(onBatch);
    await this.gaanaApi.loadPaginatedAlbums(onBatch);
  }

  async getCollection(collection: GenericCollection): Promise<GaanaPlaylist | GaanaAlbum> {
    let result: GaanaPlaylist | GaanaAlbum | null;
    if (collection.type === CollectionType.ALBUM) {
      result = (await this.gaanaApi.loadAllAlbumItems(collection.rawId)).album;
    } else {
      result = (await this.gaanaApi.loadAllPlaylistsItems(collection.rawId)).playlist;
    }

    if (!result) {
      throw new CollectionDoesNotExistsError();
    }
    return result;
  }

  async createCollection(collection: GenericCollection): Promise<GaanaPlaylist> {
    let newCollection: GaanaPlaylist | undefined;
    try {
      newCollection = await this.gaanaApi.createPlaylist(collection.name);
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!newCollection) {
      throw new CouldNotCreateCollection();
    }
    return newCollection;
  }

  async addItemToCollection(collection: GenericCollection, matchedItem: GenericMatchedItem) {
    await this.gaanaApi.addTracksToPlaylist(collection.rawId, [matchedItem.rawId]);
    return convertMatchedItemToCollectionItem(matchedItem);
  }

  async addManyItemsToCollection(
    collection: GenericCollection,
    data: {
      matchedItem: GenericMatchedItem;
      position?: number;
    }[]
  ) {
    const tracksIds = data.map(({ matchedItem }) => matchedItem.rawId);
    await this.gaanaApi.addTracksToPlaylist(collection.rawId, tracksIds);
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    collectionItems: GenericCollectionItem[]
  ): Promise<void> {
    if (collection.type !== CollectionType.PLAYLIST || collectionItems.length === 0) {
      return;
    }
    const itemsIdsToRemove = collectionItems.map((item) => item.rawId);
    await this.gaanaApi.removeTracksFromPlaylist(collection.rawId, itemsIdsToRemove);
  }

  async clearCollection(collection: GenericCollection) {
    const rawIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    await this.getPaginatedItems(collection, async (items) => {
      rawIds.push(...items.map((item) => item.rawId));
    });
    if (rawIds.length === 0) {
      return;
    }
    await this.gaanaApi.removeTracksFromPlaylist(collection.rawId, rawIds);
  }

  async matchItems(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const { tracks } = await this.gaanaApi.search(query, 'track');
    return tracks;
  }

  async matchAlbums(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const { albums } = await this.gaanaApi.search(query, 'album');
    return albums;
  }

  async addAlbumToLibrary(album: GenericCollection): Promise<void> {
    await this.gaanaApi.addAlbumToLibrary(album.rawId);
  }

  async reAuthenticate(_withData: GenericAuthenticationData): Promise<GenericAuthenticationData> {
    return Promise.reject(new Error('Not supported'));
  }

  doesSupportReAuth() {
    return false;
  }

  public doesSupportAlbums(): boolean {
    return true;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return true;
  }

  public doesSupportSearchByISRC(): boolean {
    return false;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return false;
  }

  public doesSupportMovingManyItems(): boolean {
    return false;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    switch (forCollection.type) {
      case CollectionType.PLAYLIST:
      case CollectionType.LIKED_PLAYLIST:
        await onBatch((await this.gaanaApi.loadAllPlaylistsItems(forCollection.rawId)).tracks);
        return undefined;
      case CollectionType.ALBUM:
        await onBatch((await this.gaanaApi.loadAllAlbumItems(forCollection.rawId)).tracks);
        return undefined;
      default:
        return undefined;
    }
  }

  async getCollectionPublicUrl(collection: GenericCollection) {
    const [, seokey] = collection.rawId.split('|');
    return Promise.resolve(`https://gaana.com/playlist/${seokey}`);
  }
};
