import { BoomplayTracksRawResponse } from './types';
import { BoomplayCollectionTrack } from './BoomplayCollectionTrack';

export class BoomplayTracksResponse {
  private readonly data;

  constructor(data: BoomplayTracksRawResponse | null) {
    this.data = data;
  }

  get tracks(): BoomplayCollectionTrack[] {
    if (!this.data?.data) {
      return [];
    }
    return this.data.data
      .map((item) => BoomplayCollectionTrack.fromData(item))
      .filter((track): track is BoomplayCollectionTrack => !!track);
  }
}
