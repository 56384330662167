import { SMLRouteNames, SMLRoutes } from './SMLRoutes';

export function extractLocationFromPath(path: string) {
  if (path === '') {
    return undefined;
  }
  const strippedPath = path[0] === '/' ? path.slice(1) : path;
  const pathParts = strippedPath.split('/');
  // We find route by last route name (due to issues with navigating in native, we can have only last route path)
  const routeName = pathParts[pathParts.length - 1];
  return SMLRoutes[routeName as keyof typeof SMLRouteNames];
}
