import { SoundCloudUserRawResponse } from './responseTypes';

export class SoundCloudUser {
  private readonly data;

  constructor(data?: SoundCloudUserRawResponse) {
    this.data = data;
  }

  get id(): string {
    if (!this.data || this.data.id === 0 || this.data.id === undefined) {
      throw new Error('Missing SoundCloud user id');
    }
    return `${this.data.id}`;
  }

  get avatarUrl() {
    return this.data?.avatar_url ?? null;
  }

  get name() {
    return this.data?.full_name || this.data?.username || this.id;
  }

  get country() {
    return this.data?.country ?? null;
  }
}
