import React from 'react';
import { Toast, ToastBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation';
import { TypedMemo } from '../../../modules/common/types';
import { OfflineToastNotificationRendererProps } from './props';

const OfflineToastNotificationRendererComponent: React.FC<OfflineToastNotificationRendererProps> = ({ message }) => {
  return (
    <div
      style={{
        zIndex: 1001,
        position: 'absolute',
        left: '50%',
        transform: ' translateX(-50%)',
        top: '-10px',
      }}
    >
      <div className="p-3 my-2 rounded">
        <Toast className="fym-toast-container">
          <ToastBody className="w-100 d-flex flex-row justify-content-center align-items-center">
            <FontAwesomeIcon icon={faTriangleExclamation} />
            <span className="ui-text ms-2">{message}</span>
          </ToastBody>
        </Toast>
      </div>
    </div>
  );
};

export const OfflineToastNotificationRenderer = TypedMemo(OfflineToastNotificationRendererComponent);
