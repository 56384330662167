import { GenericCollectionItem } from '../../../generics/models/GenericCollectionItem';
import { convertAnghamiTrackData } from '../utils';

export class AnghamiCollectionTrack extends GenericCollectionItem {
  static fromData(data: any): AnghamiCollectionTrack | null {
    const trackData = convertAnghamiTrackData(data);
    if (!trackData) {
      return null;
    }
    return new AnghamiCollectionTrack({ ...trackData, globalId: trackData.rawId });
  }
}
