import { QQMusicPlaylistsResponseData } from '../types';
import { QQMusicPlaylist } from './QQMusicPlaylist';
import { tryParseInt } from '../../../utils/tryParseInt';

export class QQMusicPlaylistsResponse {
  private readonly data: QQMusicPlaylistsResponseData['data'] | undefined;

  private readonly hostuin;

  constructor(data: QQMusicPlaylistsResponseData | undefined, hostuin: string) {
    this.data = data?.data;
    this.hostuin = hostuin;
  }

  get playlists(): QQMusicPlaylist[] {
    if (!this.data?.disslist) {
      return [];
    }
    return this.data.disslist
      .filter((item) => item.dir_show === '1')
      .map((item) => QQMusicPlaylist.fromPlaylistData(item, this.hostuin))
      .filter((playlist): playlist is QQMusicPlaylist => !!playlist);
  }

  get total() {
    return tryParseInt(this.data?.totoal);
  }
}
