import { PandoraCollectionTrack } from './PandoraCollectionTrack';
import { PandoraPlaylist } from './PandoraPlaylist';

export class PandoraTracksResponse {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get tracks(): PandoraCollectionTrack[] {
    return this.data.tracks
      .map((item: any, index: number) => {
        if (!this.annotations[item.trackPandoraId]) {
          return null;
        }
        return PandoraCollectionTrack.fromData(
          this.annotations[item.trackPandoraId],
          index + this.offset,
          item.itemId,
          item.duration
        );
      })
      .filter((track: PandoraCollectionTrack | null) => !!track);
  }

  get annotations(): { [key: string]: any } {
    return this.data.annotations;
  }

  get totalTracks(): number {
    return this.data.totalTracks;
  }

  get offset(): number {
    return this.data.offset;
  }

  get version(): number {
    return this.data.version;
  }

  get playlist(): PandoraPlaylist | null {
    return PandoraPlaylist.fromData(this.data);
  }
}
