import React from 'react';
import classNames from 'classnames';
import { TypedMemo } from '../../common/types';
import { SmartLinkInfoBoxProps } from './props';

const SmartLinkInfoBoxComponent: React.FC<React.PropsWithChildren<SmartLinkInfoBoxProps>> = ({
  text,
  className,
  children,
}) => {
  return (
    <div className={classNames('smart-link-info-box opacity-bg d-flex flex-column', className)}>
      <span className="ui-text">{text}</span>
      {children !== undefined && <div className="mt-3">{children}</div>}
    </div>
  );
};

export const SmartLinkInfoBox = TypedMemo(SmartLinkInfoBoxComponent);
