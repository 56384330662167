import type { RootStateType } from '../../store';

export function selectAppInfoState(state: RootStateType) {
  return state.appInfo;
}

export function selectDeviceId(state: RootStateType) {
  return state.appInfo.deviceId;
}

export function selectRealmPartition(state: RootStateType) {
  return state.appInfo.realmPartition;
}

export function selectRealmJWT(state: RootStateType) {
  return state.appInfo.realmJWT;
}

export function selectUserToken(state: RootStateType) {
  return state.appInfo.userToken;
}

export function selectUserTokenDecoded(state: RootStateType) {
  return state.appInfo.userTokenDecoded;
}

export function selectAccountId(state: RootStateType) {
  return state.appInfo.accountId;
}

export function selectIsOnline(state: RootStateType) {
  return state.appInfo.isOnline;
}

export function selectIsServerUp(state: RootStateType) {
  return state.appInfo.isServerUp;
}
