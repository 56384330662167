import React from 'react';
import { FYMButton } from '../common/views/FYMButton/FYMButton';
import { Margin } from '../common/views/margin/Margin';

export const SentryCrashDebugButtons: React.FC = () => {
  return (
    <Margin marginRight={8} marginBottom={8}>
      <FYMButton
        color="danger"
        onClick={() => {
          throw new Error('Sentry renderer crash');
        }}
      >
        Sentry rendered Crash
      </FYMButton>
    </Margin>
  );
};
