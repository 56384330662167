import { useCallback, useEffect, useState } from 'react';
import { store } from '../../store/Store';
import { ListenerCallback, Store } from '../../store/StoreInterface';

export function useLocalStore<T extends keyof Store>(
  storeKey: T,
  defaultValue: Store[T] | undefined
): {
  value: Store[T] | undefined | null;
  setValue: (newValue: Store[T]) => Promise<void>;
  isLoading: boolean;
} {
  const [value, setValue] = useState<Store[T] | undefined | null>(defaultValue);
  const [loading, setLoading] = useState<boolean>(true);

  const callback: ListenerCallback<T> = useCallback((v) => {
    setValue(v);
  }, []);

  useEffect(() => {
    setLoading(true);
    const unregister = store.addListener(storeKey, callback);
    store
      .get(storeKey)
      .then((v) => {
        if (v !== null && v !== undefined) {
          setValue(v);
          setLoading(false);
        } else if (defaultValue !== undefined) {
          // Only update store value when it did not exist, and we have defaultValue defined
          store.set(storeKey, defaultValue).catch(console.error);
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));

    return () => {
      unregister();
    };
    // Typescript cannot infer the proper type because we patched React to use DependencyList that is generic, so we can exclude ObjectID from usage
    // That's why we need to do ts-ignore here
    // @ts-ignore
  }, [storeKey, callback, defaultValue]);

  const setStoreValue = useCallback(
    async (newValue: Store[T]) => {
      setLoading(true);
      setValue(newValue);
      try {
        await store.set(storeKey, newValue);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    // Typescript cannot infer the proper type because we patched React to use DependencyList that is generic, so we can exclude ObjectID from usage
    // That's why we need to do ts-ignore here
    // @ts-ignore
    [storeKey]
  );

  return { value, isLoading: loading, setValue: setStoreValue };
}
