import { GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';

export class AppleMusicMySongs extends GenericCollection {
  constructor(authModelId: string, itemCount: number | undefined) {
    super({
      type: CollectionType.MY_SONGS,
      rawId: `apple-music-my-songs-${authModelId}`,
      name: 'Library Songs',
      itemCount,
    });
  }
}
