import { tryParseInt } from '../../../utils/tryParseInt';

export function parseBoomplayDuration(duration: string | undefined) {
  //       "duration": "00:05:16",
  if (!duration) {
    return null;
  }
  const splitDuration = duration.split(':');
  if (splitDuration.length !== 3) {
    return null;
  }
  const [hours, minutes, seconds] = splitDuration;
  const hoursInt = tryParseInt(hours) ?? 0;
  const minutesInt = tryParseInt(minutes) ?? 0;
  const secondsInt = tryParseInt(seconds) ?? 0;
  const total = hoursInt * 3600 + minutesInt * 60 + secondsInt;
  if (total <= 0) {
    return null;
  }
  return total * 1000;
}
