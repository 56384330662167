export function normalizeUrl(url: string): string;
export function normalizeUrl(url: string | null): string | null;
export function normalizeUrl(url: string | undefined): string | undefined;
export function normalizeUrl(url: string | null | undefined): string | null | undefined {
  if (!url) {
    return url;
  }
  if (url.startsWith('http://') || url.startsWith('https://') || url.startsWith('mailto:') || url.startsWith('tel:')) {
    return url;
  }
  return `https://${url}`;
}
