import { AnghamiMatchedTrack } from './AnghamiMatchedTrack';
import { AnghamiAlbum } from './AnghamiAlbum';

export class AnghamiSearchResponse {
  private readonly data;

  constructor(data: Record<string, any> | null) {
    this.data = data;
  }

  get tracks(): AnghamiMatchedTrack[] {
    if (!this.data?.sections) {
      return [];
    }
    const section = this.data.sections.find((item: any) => item.group === 'songs');
    if (!section) {
      return [];
    }
    return section.data.map((item: any) => AnghamiMatchedTrack.fromData(item));
  }

  get albums(): AnghamiAlbum[] {
    if (!this.data?.sections) {
      return [];
    }
    const section = this.data.sections.find((item: any) => item.group === 'albums');
    if (!section) {
      return [];
    }
    return section.data.map((item: any) => AnghamiAlbum.fromData(item)).filter((album: AnghamiAlbum | null) => !!album);
  }

  get firstTrack(): AnghamiMatchedTrack | undefined {
    if (this.tracks.length === 0) {
      return undefined;
    }
    return this.tracks[0];
  }

  get firstAlbum(): AnghamiAlbum | undefined {
    if (this.albums.length === 0) {
      return undefined;
    }
    return this.albums[0];
  }
}
