import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import date from 'date-and-time';
import { LicenseType } from 'fym-common/src/license/consts';
import { TypedMemo } from '../../common/types';
import { LicenseStatusBox } from './LicenseStatusBox';
import { SmartLinksLicenseStatusProps } from './props';

const SmartLinkLicenseStatusComponent: React.FC<SmartLinksLicenseStatusProps> = ({ licenseInfo }) => {
  const intl = useIntl();

  const subtitleText = useMemo(() => {
    switch (licenseInfo.currentPlan) {
      case LicenseType.Premium:
        return 'PREMIUM';
      case LicenseType.SmartLinksInfluencer:
        return 'INFLUENCER';
      case LicenseType.SmartLinksRookie:
        return 'ROOKIE';
      case LicenseType.SmartLinksArtist:
        return 'ARTIST';
      case LicenseType.Trial:
        return 'TRIAL';
      default:
        return 'NONE';
    }
  }, [licenseInfo.currentPlan]);
  const expirationDate = licenseInfo.subscriptionExpiresAt;
  const { hasSharingEnabled } = licenseInfo;
  const statusText = useMemo(() => {
    if (hasSharingEnabled && expirationDate) {
      return intl.formatMessage(
        { id: 'app.payment_plans.premium.valid_until', defaultMessage: 'Valid until {expirationDate}' },
        { expirationDate: date.format(expirationDate, 'YYYY-MM-DD') }
      );
    }
    if (!hasSharingEnabled && expirationDate) {
      return intl.formatMessage(
        { id: 'app.payment_plans.premium.expired_at', defaultMessage: 'Expired at {expirationDate}' },
        { expirationDate: date.format(expirationDate, 'YYYY-MM-DD') }
      );
    }
    return intl.formatMessage({ id: 'app.payment_plans.premium.inactive', defaultMessage: 'Inactive' });
  }, [hasSharingEnabled, expirationDate, intl]);
  return (
    <LicenseStatusBox
      title={intl
        .formatMessage({
          id: 'app.payment_plans.smartlinks.current-plan',
          defaultMessage: 'Current Plan',
        })
        .toUpperCase()}
      subtitle={subtitleText}
      statusText={statusText}
      inactive={!hasSharingEnabled}
    />
  );
};

export const SmartLinkLicenseStatus = TypedMemo(SmartLinkLicenseStatusComponent);
