import React, { useCallback, useEffect } from 'react';
import { TypedMemo } from '../../common/types';
import { SMLSignUpViewRenderer } from './SMLSignUpViewRenderer';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { SMLAnalytics } from '../../analytics/sml/analytics';

const SMLSignUpViewComponent: React.FC = () => {
  const router = useSMLRouter();

  const goToLogInView = useCallback(() => {
    router.replace(SMLRoutes.login);
  }, [router]);

  useEffect(() => {
    SMLAnalytics.trackSignUpStarted().catch(console.error);
  });
  return <SMLSignUpViewRenderer goToLogInView={goToLogInView} />;
};

export const SMLSignUpView = TypedMemo(SMLSignUpViewComponent);
