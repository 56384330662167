import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { imageSchema, urlSchema } from 'fym-common/src/joi/schemas';
import { FieldsName } from './consts';
import { StoreType } from '../../../redux/store';
import { getValidateAccountSlugFn } from '../utils/getValidateAccountSlugFn';

export function createResolver(store: StoreType, accountId?: number) {
  const validateSlug = getValidateAccountSlugFn(store, FieldsName.slug, accountId);
  const schema = Joi.object({
    [FieldsName.slug]: Joi.string().label('Slug').required().external(validateSlug),
    [FieldsName.name]: Joi.string().label('Name').required(),
    [FieldsName.avatar]: imageSchema.label('Avatar').optional(),
    [FieldsName.customDomain]: Joi.string().label('Custom Domain').optional().allow(''),
    [FieldsName.socialLinks]: Joi.array().items(
      Joi.object({
        socialLinkId: Joi.number().integer().optional(),
        url: urlSchema.optional().allow(''),
      })
    ),
  });
  return joiResolver(schema);
}
