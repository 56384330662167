import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { config } from 'fym-common/src/config/config';
import { TypedMemo } from '../../common/types';
import { TextLink } from '../../common/views/textLink/TextLink';
import { useSMLNavigateToWebsitePage } from '../../settings/useSMLNavigateToWebsitePage';

const SMLLinksComponent: React.FC = () => {
  const intl = useIntl();
  const navigateToWebsitePage = useSMLNavigateToWebsitePage();

  const goToTermsOfServices = useCallback(() => {
    navigateToWebsitePage(`${config.smartLinksWebURL}en/terms-of-service`).catch(console.error);
  }, [navigateToWebsitePage]);

  const goToPrivacyPolicy = useCallback(() => {
    navigateToWebsitePage(`${config.smartLinksWebURL}en/privacy-policy`).catch(console.error);
  }, [navigateToWebsitePage]);

  return (
    <>
      <TextLink onClick={goToTermsOfServices}>
        {intl.formatMessage({
          id: 'app.profile_links.terms_of_services',
          defaultMessage: 'Terms of services',
        })}
      </TextLink>
      <TextLink onClick={goToPrivacyPolicy}>
        {intl.formatMessage({
          id: 'app.profile_links.privacy_policy',
          defaultMessage: 'Privacy policy',
        })}
      </TextLink>
    </>
  );
};

export const SMLLinks = TypedMemo(SMLLinksComponent);
