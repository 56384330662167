export enum PlayerWidgetType {
  normal = 'normal',
  compact = 'compact',
}

export enum InfoWidgetType {
  title = 'title',
  normal = 'normal',
  small = 'small',
}

export enum SmartLinkWidgetType {
  info = 'info',
  image = 'image',
  link = 'link',
  socialLinks = 'socialLinks',
  profile = 'profile',
  player = 'player',
}

export type RawWidgetData = {
  [SmartLinkWidgetType.info]: { text: string; type: InfoWidgetType };
  [SmartLinkWidgetType.image]: { imageId: number };
  [SmartLinkWidgetType.link]: { url: string; label: string | null; smartLinkId: number | undefined };
  [SmartLinkWidgetType.socialLinks]: Record<string, never>;
  [SmartLinkWidgetType.profile]: Record<string, never>;
  [SmartLinkWidgetType.player]: {
    url: string;
    embedUrl?: string;
    type?: PlayerWidgetType;
    height?: number;
    version?: number;
  };
};
