export enum BoomplayPlaylistPrivacy {
  public = 'Public',
  private = 'Private',
}

export type BoomplayArtistRawResponse = {
  artist_id?: number;
  artist_name?: string;
};

export type BoomplayTrackListRawResponse = {
  track_id?: number;
  track_title?: string;
  track_number?: number;
};

export type BoomplayTrackRawResponse = {
  track_id?: number;
  track_title?: string;
  track_number?: number;
  isrc?: string;
  duration?: string;
  album_id?: number;
  album_title?: string;
  genres?: string;
  available_markets?: string;
  web_url?: string;
  mobile_url?: string;
  artwork?: {
    width?: number;
    height?: number;
    url?: string;
  };
  artists?: BoomplayArtistRawResponse[];
};

export type BoomplayTracksRawResponse = {
  desc?: string | null;
  code?: number;
  counts?: number;
  next?: string;
  data?: BoomplayTrackRawResponse[];
};

export type BoomplayAlbumRawResponse = {
  album_id?: number;
  album_title?: string;
  album_type?: string;
  upc?: string;
  track_count?: number;
  release_date?: string;
  label?: string;
  genres?: string;
  available_markets?: string;
  web_url?: string;
  mobile_url?: string;
  artwork?: {
    width?: number;
    height?: number;
    url?: string;
  };
  artists?: BoomplayArtistRawResponse[];
  tracks?: BoomplayTrackRawResponse[];
};

export type BoomplayAlbumsRawResponse = {
  desc?: string;
  code?: number;
  next?: string;
  data?: BoomplayAlbumRawResponse[];
};

export type BoomplayPlaylistRawResponse = {
  playlist_id?: number;
  playlist_title?: string;
  track_count?: number;
  web_url?: string;
  mobile_url?: string;
  tracks?: BoomplayTrackListRawResponse[];
  artwork?: {
    width?: number;
    height?: number;
    url?: string;
  };
  privacy?: BoomplayPlaylistPrivacy;
  description?: string;
};

export type BoomplayPlaylistsRawResponse = {
  desc?: string | null;
  code?: number;
  counts?: number;
  data?: BoomplayPlaylistRawResponse[];
};

export type BoomplaySearchRawResponse = {
  data?: {
    tracks?: BoomplayTrackRawResponse[];
    albums?: BoomplayAlbumRawResponse[];
  };
};

export type BoomplayUserRawResponse = {
  desc?: string | null;
  code?: number;
  data?: {
    user_id?: string;
    user_name?: string;
    avatar?: {
      width?: number;
      height?: number;
      url?: string;
    };
    region?: string;
  };
};
