import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TypedMemo } from '../../../common/types';
import { iconForLink, stylesForLink } from '../utils';
import { SocialLinkItemRendererProps } from './props';

const SocialLinkItemRendererComponent: React.FC<SocialLinkItemRendererProps> = ({ type }) => {
  return (
    <div style={stylesForLink(type)} className="smart-links-social-link rounded-circle">
      <FontAwesomeIcon icon={iconForLink(type)} size="sm" />
    </div>
  );
};

export const SocialLinkItemRenderer = TypedMemo(SocialLinkItemRendererComponent);
