import { useUserToken, useUserTokenDecoded } from '../../../redux/slices/appInfo/hooks';
import { useGetUserQuery } from '../../../redux/api/fymAPI';

export const useUser = () => {
  const userToken = useUserToken();
  const userTokenDecoded = useUserTokenDecoded();
  const { data, isLoading, error, refetch } = useGetUserQuery(
    { userId: userTokenDecoded?.id ?? 0 },
    { skip: !userToken }
  );

  return {
    data,
    isLoading,
    error,
    refetch,
  };
};
