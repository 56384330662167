import { SearchResponse } from './types';
import { AmazonMusicMatchedTrack } from './AmazonMusicMatchedTrack';
import { AmazonMusicAlbum } from './AmazonMusicAlbum';

export class AmazonMusicSearchResponse {
  private readonly data;

  constructor(data: SearchResponse | undefined) {
    this.data = data;
  }

  get tracks() {
    return (
      this.data?.data?.searchTracks?.edges
        ?.map((edge) => AmazonMusicMatchedTrack.fromData(edge?.node))
        .filter((track): track is AmazonMusicMatchedTrack => !!track) ?? []
    );
  }

  get albums() {
    return (
      this.data?.data?.searchAlbums?.edges
        ?.map((edge) => AmazonMusicAlbum.fromData(edge?.node))
        .filter((album): album is AmazonMusicAlbum => !!album) ?? []
    );
  }

  get firstTrack(): AmazonMusicMatchedTrack | undefined {
    if (this.tracks.length === 0) {
      return undefined;
    }
    return this.tracks[0];
  }

  get firstAlbum(): AmazonMusicAlbum | undefined {
    if (this.albums.length === 0) {
      return undefined;
    }
    return this.albums[0];
  }

  get tracksPageInfoToken() {
    return this.data?.data?.searchTracks?.pageInfo?.token ?? undefined;
  }

  get tracksHasNextPage() {
    return this.data?.data?.searchTracks?.pageInfo?.hasNextPage;
  }

  get albumsPageInfoToken() {
    return this.data?.data?.searchAlbums?.pageInfo?.token ?? undefined;
  }

  get albumsHasNextPage() {
    return this.data?.data?.searchAlbums?.pageInfo?.hasNextPage;
  }
}
