import { GenericCollectionItem } from '../generics/models/GenericCollectionItem';
import { NonNullableField } from '../types/utilityTypes';

export function moveItemsByOffset(props: {
  itemsToMove: NonNullableField<GenericCollectionItem, 'position'>[];
  allItems: NonNullableField<GenericCollectionItem, 'position'>[];
  offset: number;
}) {
  const { allItems, itemsToMove, offset } = props;
  itemsToMove.sort((a, b) => a.position - b.position);
  const firstItem = itemsToMove[0];
  const lastItem = itemsToMove[itemsToMove.length - 1];
  if (!firstItem || !lastItem) {
    throw new Error('Missing either firstItem or lastItem');
  }
  const indexOfFirstItemToMove = allItems.findIndex(({ rawId }) => rawId === firstItem.rawId);
  const indexOfLastItemToMove = allItems.findIndex(({ rawId }) => rawId === lastItem.rawId);
  if (indexOfFirstItemToMove === -1 || indexOfLastItemToMove === -1) {
    throw new Error('Invalid index');
  }
  const newPosition = firstItem.position + offset;
  const restItems = [...allItems.slice(0, indexOfFirstItemToMove), ...allItems.slice(indexOfLastItemToMove + 1)];
  return [...restItems.slice(0, newPosition), ...itemsToMove, ...restItems.slice(newPosition)];
}
