import { spotify } from './services/Spotify';
import { appleMusic } from './services/AppleMusic';
import { MusicServices } from './services/types';
import { amazonMusic } from './services/AmazonMusic';
import { deezer } from './services/Deezer';
import { pandora } from './services/Pandora';
import { tidal } from './services/Tidal';
import { youtube } from './services/YouTube';
import { soundcloud } from './services/SoundCloud';
import { qobuz } from './services/Qobuz';
import { qqMusic } from './services/QQMusic';
import { yandexMusic } from './services/YandexMusic';
import { anghami } from './services/Anghami';
import { youtubeMusic } from './services/YouTubeMusic';
import { napster } from './services/Napster';
import { vkMusic } from './services/VKMusic';
import { zvuk } from './services/Zvuk';
import { gaana } from './services/Gaana';
import { jiosaavn } from './services/JioSaavn';
import { fileBackup } from './services/FileBackup';
import { resso } from './services/Resso';
import { boomplay } from './services/Boomplay';

export const musicServices = [
  amazonMusic,
  appleMusic,
  deezer,
  pandora,
  soundcloud,
  spotify,
  tidal,
  youtube,
  youtubeMusic,
  napster,
  qobuz,
  qqMusic,
  yandexMusic,
  vkMusic,
  anghami,
  zvuk,
  gaana,
  jiosaavn,
  resso,
  boomplay,
  fileBackup,
];

export const musicServicesByAppID: MusicServices = {};
musicServices.forEach((ms) => {
  musicServicesByAppID[ms.importerID] = ms;
  musicServicesByAppID[ms.id] = ms;
  if (ms.musicAPIID) {
    musicServicesByAppID[ms.musicAPIID] = ms;
  }
});
export const musicServicesByLinkType: MusicServices = {};
musicServices.forEach((ms) => {
  if (ms.linkType !== undefined) {
    musicServicesByLinkType[ms.linkType] = ms;
  }
});
export const musicServicesByMusicAPIID: MusicServices = {};
musicServices.forEach((ms) => {
  if (ms.musicAPIID !== undefined) {
    musicServicesByMusicAPIID[ms.musicAPIID] = ms;
  }
});
