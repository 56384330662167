import { MusicService } from './types';
import { MusicAPIIntegrationID } from '../types';
import { ImporterID } from '../../importers/types';
import { LinkType } from '../../utils/recognizeLinkTypeByUrl';

export const resso: MusicService = {
  id: 'resso',
  importerID: ImporterID.Resso,
  linkType: LinkType.resso,
  musicAPIID: MusicAPIIntegrationID.Resso,
  shortName: 'Resso',
  name: 'Resso',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: true,
  isSupported: false,
  color: { label: '#FD0164', background: '#FC82B2' },
};
