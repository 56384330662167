import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../common/types';
import { Spacer } from '../common/views/spacer/Spacer';
import { Title } from '../common/views/title/Title';
import { SentryCrashDebugButtons } from './SentryCrashDebugButtons';
import { Margin } from '../common/views/margin/Margin';
import { FYMButton } from '../common/views/FYMButton/FYMButton';
import { FYMButtonsExamples } from './FYMButtonsExamples';
import { useLicenseInfo } from '../../redux/slices/license/hooks';
import { FYMText } from '../common/views/FYMText/FYMText';
import { resetLicenseState } from '../../redux/slices/license/slice';
import { store } from '../../store/Store';
import { SMLDebugViewRenderer } from './SMLDebugViewRenderer';

const SMLDebugViewComponent: React.FC = () => {
  const intl = useIntl();
  const licenseInfo = useLicenseInfo();
  const dispatch = useDispatch();
  const resetLicenseCallback = useCallback(() => {
    dispatch(resetLicenseState());
  }, [dispatch]);

  const clearLocalStorage = useCallback(() => {
    store.clear().catch(console.error);
  }, []);

  return (
    <SMLDebugViewRenderer>
      <Spacer paddingBottom={48} paddingTop={48} paddingLeft={8} paddingRight={8} alignItems="flex-start">
        <Title>Actions</Title>
        <Spacer flex flexDirection="column" alignItems="flex-start">
          <SentryCrashDebugButtons />
          <Margin marginRight={8} marginBottom={8}>
            <FYMButton color="secondary" onClick={resetLicenseCallback}>
              Reset license
            </FYMButton>
          </Margin>
          <Margin marginRight={8} marginBottom={8}>
            <FYMButton color="secondary" onClick={clearLocalStorage}>
              Clear local storage
            </FYMButton>
          </Margin>
        </Spacer>
        <Spacer flex flexDirection="column" alignItems="flex-start">
          <Title>License</Title>
          <FYMText>
            {licenseInfo?.validLicense ? JSON.stringify(licenseInfo.validLicense, undefined, 4) : 'NO LICENSE'}
          </FYMText>
          <Title>Intl</Title>
          <FYMText>{intl.formatRelativeTime(-10, 'day', { style: 'long' })}</FYMText>
          <FYMText>{intl.formatRelativeTime(-10, 'minutes', { style: 'long' })}</FYMText>
          <FYMText>{intl.formatRelativeTime(-10, 'seconds', { style: 'long' })}</FYMText>
        </Spacer>
        <FYMButtonsExamples />
      </Spacer>
    </SMLDebugViewRenderer>
  );
};
export const SMLDebugView = TypedMemo(SMLDebugViewComponent);
