import { LicenseModelFeatureFlags } from 'fym-common/src/license/types';
import { BASE_SONGS_LIMIT, LicenseModelTransactionVendor, LicenseType } from 'fym-common/src/license/consts';
import { defaultFeatureFlags } from 'fym-common/src/license/defaultFeatureFlags';
import { Store } from '../store/StoreInterface';
import { store } from '../store/Store';

export function transactionVendorToString(vendor: LicenseModelTransactionVendor | undefined) {
  switch (vendor) {
    case LicenseModelTransactionVendor.Amazon:
      return 'Amazon AppStore';
    case LicenseModelTransactionVendor.Apple:
      return 'Apple AppStore';
    case LicenseModelTransactionVendor.Google:
      return 'Google AppStore';
    default:
      return 'Unknown';
  }
}

export function transactionVendorToPlatformString(vendor: LicenseModelTransactionVendor | undefined) {
  switch (vendor) {
    case LicenseModelTransactionVendor.Amazon:
      return 'Amazon Android';
    case LicenseModelTransactionVendor.Apple:
      return 'iOS';
    case LicenseModelTransactionVendor.Google:
      return 'Android';
    default:
      return 'Unknown';
  }
}

export class LicenseInfo {
  isValid: boolean;

  license: Store['license'] | null;

  validLicense: Store['license'] | null;

  constructor(license: Store['license'] | null) {
    this.license = license;
    this.validLicense = license && license.isValid ? license : null;
    this.isValid = !!license && license.isValid;
  }

  get transactionVendor() {
    return this.license?.transactionVendor ?? LicenseModelTransactionVendor.Unknown;
  }

  get subscriptionExpiresAt() {
    return this.validLicense && this.validLicense.subscriptionExpiresAt !== null
      ? new Date(this.validLicense.subscriptionExpiresAt)
      : undefined;
  }

  get isLostBasketLicense() {
    return this.transactionVendor === LicenseModelTransactionVendor.LostBasket;
  }

  get isBasicLicenseValid() {
    return !!this.validLicense && !this.basicNotIncluded && !this.isLostBasketLicense;
  }

  get existingLicenseKey() {
    return this.license?.licenseKey ?? '';
  }

  areAllServicesSupportedForSharedCollections(availableServicesCount: number) {
    if (!this.validLicense || !this.hasSharingEnabled || this.sharingServicesLimit === null) {
      return false;
    }
    if (this.sharingServicesLimit === -1) {
      return true;
    }
    return availableServicesCount <= this.sharingServicesLimit;
  }

  isSubscriptionValid(): boolean {
    if (!this.isValid || !this.subscriptionExpiresAt) {
      return false;
    }
    return this.subscriptionExpiresAt.getTime() > Date.now();
  }

  get currentPlan(): LicenseType | undefined {
    if (this.transactionVendor === LicenseModelTransactionVendor.Trial) {
      return LicenseType.Trial;
    }
    if (this.isLostBasketLicense) {
      return LicenseType.LostBasket;
    }
    if (this.isSubscriptionValid()) {
      if (this.hasSharingEmbedAccess) {
        return LicenseType.SmartLinksArtist;
      }
      if (this.hasSharingAnalyticsAccess) {
        return LicenseType.SmartLinksInfluencer;
      }
      if (this.hasSharingEnabled) {
        return LicenseType.SmartLinksRookie;
      }
      return LicenseType.Premium;
    }
    if (this.isValid && !this.basicNotIncluded) {
      return LicenseType.Basic;
    }
    return undefined;
  }

  get hasSharingEnabled() {
    if (!this.isSubscriptionValid()) {
      return false;
    }
    return this.validLicense?.features.hasSharingEnabled ?? false;
  }

  get basicNotIncluded() {
    return this.validLicense?.features.basicNotIncluded ?? false;
  }

  get isSubscriptionValidOrBasicIncluded() {
    if (this.basicNotIncluded) {
      return this.isSubscriptionValid();
    }
    return !!this.validLicense;
  }

  get hasAlbumsTransferEnabled() {
    if (!this.isSubscriptionValidOrBasicIncluded) {
      return false;
    }
    return this.validLicense?.features.hasAlbumsTransferEnabled ?? false;
  }

  get disallowLikedPlaylists() {
    return this.validLicense?.features.disallowLikedPlaylists ?? false;
  }

  checkFeatureFlag(key: keyof LicenseModelFeatureFlags) {
    if (!this.license?.featureFlags) {
      return defaultFeatureFlags[key] ?? false;
    }
    return this.license.featureFlags[key] ?? false;
  }

  get hasAutoSyncEnabled() {
    if (!this.isSubscriptionValid()) {
      return false;
    }
    return this.validLicense?.features.hasAutoSyncEnabled ?? false;
  }

  get hasSharingAnalyticsAccess() {
    if (!this.isSubscriptionValid()) {
      return false;
    }
    return (this.hasSharingEnabled && this.validLicense?.features.hasSharingAnalyticsAccess) ?? false;
  }

  get hasSharingEmbedAccess() {
    if (!this.isSubscriptionValid()) {
      return false;
    }
    return (this.hasSharingEnabled && this.validLicense?.features.hasSharingEmbedAccess) ?? false;
  }

  get hasSMLRetargeting() {
    if (!this.isSubscriptionValid()) {
      return false;
    }
    return (this.hasSharingEnabled && this.validLicense?.features.hasSMLRetargeting) ?? false;
  }

  get hasSMLMusicPlayer() {
    if (!this.isSubscriptionValid()) {
      return false;
    }
    return (this.hasSharingEnabled && this.validLicense?.features.hasSMLMusicPlayer) ?? false;
  }

  get hasSMLCustomization() {
    if (!this.isSubscriptionValid()) {
      return false;
    }
    return (this.hasSharingEnabled && this.validLicense?.features.hasSMLCustomization) ?? false;
  }

  get hasSMLWhiteLabel() {
    if (!this.isSubscriptionValid()) {
      return false;
    }
    return (this.hasSharingEnabled && this.validLicense?.features.hasSMLWhiteLabel) ?? false;
  }

  get hasReMatchEnabled() {
    return this.validLicense?.features.hasReMatchEnabled ?? defaultFeatureFlags.rematch;
  }

  get sharingServicesLimit() {
    return this.validLicense?.limits.sharingServicesLimit ?? null;
  }

  get smlAccountsLimit() {
    if (!this.validLicense) {
      return 0;
    }
    return this.validLicense.limits.smlAccountsLimit;
  }

  get smlSmartLinksLimit() {
    if (!this.validLicense) {
      return 0;
    }
    return this.validLicense.limits.smlSmartLinksLimit;
  }

  get smlUsersLimit() {
    if (!this.validLicense) {
      return 0;
    }
    return this.validLicense.limits.smlUsersLimit;
  }

  get songsLimit() {
    if (this.validLicense && this.isSubscriptionValidOrBasicIncluded) {
      return this.validLicense.limits.songsLimit;
    }
    return BASE_SONGS_LIMIT;
  }

  get availableMusicServicesSource() {
    if (this.validLicense) {
      return this.validLicense.limits.availableMusicServicesSource;
    }
    return null;
  }

  get availableMusicServicesDestination() {
    if (this.validLicense) {
      return this.validLicense.limits.availableMusicServicesDestination;
    }
    return null;
  }

  static async get(): Promise<LicenseInfo> {
    const licenseRequest = await store.get('license');
    return new LicenseInfo(licenseRequest);
  }

  get paddleManagementURLs() {
    return this.license?.paddleManagementURLs ?? null;
  }
}
