import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionAccess, CollectionType } from '../../../generics/models/Collection';
import { tryParseInt } from '../../../utils/tryParseInt';
import { buildAnghamiCoverUrl } from '../utils';

function convertPublicToAccess(_public: boolean | string | undefined) {
  return _public === true || _public === 'true' ? CollectionAccess.public : CollectionAccess.private;
}

export class AnghamiPlaylist extends GenericCollection {
  constructor(data: Omit<CollectionModelData, 'type'>) {
    super({ type: CollectionType.PLAYLIST, ...data });
  }

  static fromData(data: any, description?: string): AnghamiPlaylist | null {
    const fixedName = data.name.replace('$1234567890DOWNLOADED#', '~Downloaded').replace('$1234567890LIKED#', '~Liked');
    if (!data?.id || !fixedName) {
      return null;
    }
    return new AnghamiPlaylist({
      rawId: `${data.id}`,
      name: fixedName,
      itemCount: data.PlaylistCount ?? data.count,
      followers: tryParseInt(data.followers),
      additionalData: {
        description: data.description ?? description,
        imageUrl: buildAnghamiCoverUrl(data.coverArtID ?? data.coverArt ?? data.coverArtImage),
      },
      access: convertPublicToAccess(data.Public ?? data.public),
    });
  }
}
