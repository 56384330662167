import { MusicService } from './types';
import { ImporterID } from '../../importers/types';
import { MusicAPIIntegrationID } from '../types';
import { LinkType } from '../../utils/recognizeLinkTypeByUrl';

export const zvuk: MusicService = {
  id: 'zvuk',
  importerID: ImporterID.Zvuk,
  linkType: LinkType.zvuk,
  musicAPIID: MusicAPIIntegrationID.Zvuk,
  shortName: 'Zvuk',
  name: 'Zvuk (Звук)',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: true,
  color: { label: '#37D0DC', background: '#47E0DC99' },
};
