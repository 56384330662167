import { tryParseFloat } from './tryParseFloat';

export function tryParseDurationToMs(value: string | number | undefined | null, unit: 'ms' | 's'): number | undefined {
  const parsedDuration = tryParseFloat(value);
  if (parsedDuration === undefined) {
    return undefined;
  }
  const durationInMs = unit === 's' ? parsedDuration * 1000 : parsedDuration;
  return Math.abs(Math.round(durationInMs));
}
