import { SoundCloudPlaylistsRawResponse } from './responseTypes';
import { SoundCloudPlaylist } from './SoundCloudPlaylist';
import { CollectionType } from '../../../generics/models/Collection';

export class SoundCloudPlaylistsResponse {
  private readonly data;

  private readonly userId;

  private readonly collectionType;

  constructor(data: SoundCloudPlaylistsRawResponse | undefined, userId: string, collectionType: CollectionType) {
    this.data = data;
    this.userId = userId;
    this.collectionType = collectionType;
  }

  get playlists() {
    if (!this.data?.collection) {
      return [];
    }
    return this.data.collection
      .map((playlistData) => SoundCloudPlaylist.fromData(playlistData, this.userId, this.collectionType))
      .filter((playlist): playlist is NonNullable<SoundCloudPlaylist> => !!playlist);
  }

  get nextHref() {
    return this.data?.next_href;
  }
}
