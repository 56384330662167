import React, { useCallback, useState } from 'react';
import { SerializedAccount } from 'fym-common/src/db/serializersTypes';
import { TypedMemo } from '../../common/types';
import { AccountStatsViewRenderer } from './AccountStatsViewRenderer';
import { useFetchAccountStatsQuery } from '../../../redux/api/fymAPI';
import { useLicenseInfo } from '../../../redux/slices/license/hooks';
import { openExternal } from '../../../utils/openExternal';
import { SmartLinkUpsell } from '../upselling/SmartLinkUpsell';
import { ErrorView } from '../../common/views/ErrorView/ErrorView';

interface AccountStatsViewProps {
  account: SerializedAccount;
}

const AccountStatsViewComponent: React.FC<AccountStatsViewProps> = ({ account }) => {
  const {
    data: stats,
    error,
    isLoading,
    refetch,
  } = useFetchAccountStatsQuery({
    accountId: account.id,
  });
  const [isUpsellingOpen, setIsUpsellingOpen] = useState<boolean>(false);
  const closeUpselling = useCallback(() => setIsUpsellingOpen(false), []);

  const licenseInfo = useLicenseInfo();
  const onMainStatsClick = useCallback(() => {
    if (!licenseInfo.hasSharingAnalyticsAccess) {
      setIsUpsellingOpen(true);
      return;
    }
    if (!account.plausibleStatsUrl) {
      return;
    }
    openExternal(account.plausibleStatsUrl).catch(console.error);
  }, [licenseInfo.hasSharingAnalyticsAccess, account.plausibleStatsUrl]);

  if (error) {
    return <ErrorView error={error} retry={refetch} />;
  }

  return (
    <>
      <SmartLinkUpsell isOpen={isUpsellingOpen} onClose={closeUpselling} />
      <AccountStatsViewRenderer isLoading={isLoading} stats={stats} onMainStatsClick={onMainStatsClick} />
    </>
  );
};

export const AccountStatsView = TypedMemo(AccountStatsViewComponent);
