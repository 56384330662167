import { CollectionItemAdditionalData } from './AdditionalData';

export type MatchedItemModelData = {
  rawId: string;
  name: string;
  album?: string;
  artist?: string;
  isrc?: string | null;
  imageUrl?: string | null;
  duration?: number | null;
  previewUrl?: string | null;
  sourceURL?: string | null;
  additionalData?: CollectionItemAdditionalData | null;
};

export class GenericMatchedItem implements MatchedItemModelData {
  public rawId;

  public name;

  public album;

  public artist;

  public isrc;

  public additionalData;

  public imageUrl;

  public duration;

  public previewUrl;

  public sourceURL;

  constructor(data: MatchedItemModelData) {
    const { rawId, name, album, artist, isrc, additionalData, imageUrl, duration, previewUrl, sourceURL } = data;
    this.rawId = Number.isInteger(rawId) ? `${rawId}` : rawId;
    this.name = name;
    this.album = album ?? '';
    this.artist = artist ?? '';
    this.isrc = isrc ?? '';
    this.additionalData = additionalData ?? null;
    this.imageUrl = imageUrl ?? null;
    this.duration = duration ?? null;
    this.previewUrl = previewUrl ?? null;
    this.sourceURL = sourceURL ?? null;
  }
}
