import { CollectionModelData, GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionType } from '../../../generics/models/Collection';
import { createZvukPlaylistUrl } from '../utils';

export class ZvukPlaylist extends GenericCollection {
  public readonly trackIds;

  public readonly updated;

  constructor(data: CollectionModelData, trackIds: number[], updated?: number) {
    super(data);
    this.trackIds = trackIds;
    this.updated = updated;
  }

  static fromData(data: any, userId: number | undefined): ZvukPlaylist | null {
    if (!data?.id || !data.title) {
      return null;
    }
    const isUserOwner = userId !== undefined && userId === data.user_id;
    const playlistType = isUserOwner ? CollectionType.PLAYLIST : CollectionType.LIKED_PLAYLIST;
    return new ZvukPlaylist(
      {
        type: playlistType,
        rawId: `${data.id}`,
        name: data.title,
        itemCount: data.track_ids.length,
        publicUrl: createZvukPlaylistUrl(data.id),
      },
      data.track_ids,
      data.updated
    );
  }

  static fromCreation(data: any): ZvukPlaylist | null {
    if (!data?.id || !data.name) {
      return null;
    }
    return new ZvukPlaylist(
      { type: CollectionType.PLAYLIST, rawId: `${data.id}`, name: data.name, itemCount: data.trackCount },
      data.trackIds,
      data.updated
    );
  }
}
