import React from 'react';
import { useIntl } from 'react-intl';
import { FYMAPIError } from 'fym-common/src/generics/errors/FYMAPIError';
import { TypedMemo } from '../../types';
import { ErrorViewProps } from './props';
import { SMLButton } from '../SMLButton/SMLButton';

const ErrorViewComponent: React.FC<ErrorViewProps> = ({ error, retry }) => {
  const intl = useIntl();
  const isOperationalError = error instanceof FYMAPIError && error.isOperational;
  return (
    <div className="d-flex justify-content-center align-items-center w-100 flex-column p-5 flex-grow-1">
      <h6 className="ui-text text-center mb-3">
        {isOperationalError
          ? error.message
          : intl.formatMessage({
              id: 'app.errors.fym_api.default',
              defaultMessage: `Something went wrong. Please try again, and if the problem persist, contact support@freeyourmusic.com.`,
            })}
      </h6>
      {retry && (
        <SMLButton onClick={retry}>
          {intl.formatMessage({
            id: 'app.errors.fym_api.try_again',
            defaultMessage: 'Try again',
          })}
        </SMLButton>
      )}
    </div>
  );
};

export const ErrorView = TypedMemo(ErrorViewComponent);
