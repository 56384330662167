import concat from 'lodash/concat';
import { YouTubeMusicAlbum } from './YouTubeMusicAlbum';
import { YouTubeMusicAlbumTrack } from './YouTubeMusicAlbumTrack';
import { nav, YouTubeMusicParser } from '../youTubeMusicParser';

export class YouTubeMusicAlbumWithTracksResponse {
  private readonly data: any;

  private readonly browseId: string;

  constructor(data: any, browseId: string) {
    this.data = data;
    this.browseId = browseId;
  }

  get album(): YouTubeMusicAlbum | null {
    return YouTubeMusicAlbum.fromAlbumResponse(this.data.header.musicDetailHeaderRenderer, this.browseId);
  }

  get tracks() {
    const { album } = this;
    const albumEntities = nav(
      this.data,
      concat(YouTubeMusicParser.SINGLE_COLUMN_TAB, YouTubeMusicParser.SHELF_RENDERER)
    );
    return albumEntities
      .map((item: any) => YouTubeMusicAlbumTrack.fromData(item, album))
      .filter((track: YouTubeMusicAlbumTrack | null) => !!track);
  }
}
