interface User {
  id?: string;
}

export enum AmazonPlaylistVisibility {
  public = 'PUBLIC',
  private = 'PRIVATE',
}

export interface Playlist {
  id?: string;
  title?: string;
  visibility?: AmazonPlaylistVisibility;
  curator?: User;
  duration?: number;
  description?: string;
  trackCount?: number;
  url?: string | null;
  images?: (Image | null)[];
}

export interface Image {
  height?: number | null;
  width?: number | null;
  url?: string;
  imageType?: string;
}

interface PageInfo {
  hasNextPage?: boolean;
  token?: string | null;
}

export interface PlaylistTrackConnection {
  pageInfo?: PageInfo;
  edgeCount?: number;
  edges?: (PlaylistTrackEdge | null)[];
}

export interface PlaylistTrackEdge {
  cursor?: string;
  node?: Track;
}

interface MusicTerritory {
  code?: string;
  territoryName?: string;
}

export interface Artist {
  id?: string;
  globalAsin?: string;
  images?: (Image | null)[] | null;
  localAsin?: string;
  musicTerritory?: MusicTerritory;
  name?: string;
  url?: string;
}

export interface Album {
  id?: string;
  globalAsin?: string;
  title?: string;
  shortTitle?: string;
  duration?: number;
  url?: string;
  release?: Release;
  releaseDate?: string;
  availableMarkets?: ({
    id?: string;
    name?: string;
  } | null)[];
  artists?: (Pick<Artist, 'id' | 'name' | 'url'> | null)[];
  eligibility?: Eligibility;
  parentalSettings?: ParentalSettings;
  tracks?: (Pick<Track, 'id' | 'title' | 'isrc' | 'url'> | null)[];
  trackCount?: number;
  images?: (Image | null)[];
  label?: string;
  copyright?: string;
  languageOfPerformance?: string;
}

interface ParentalSettings {
  isKidFriendly?: boolean;
  hasExplicitLanguage?: boolean;
}

interface Release {
  streetDate?: string;
  localOriginalReleaseDate?: string;
}

interface Eligibility {
  playback?: {
    canPlayOnDemand?: boolean;
    shuffleType?: string;
  };
  playbackSubscriptionTiers?: (string | null)[];
  isDownloadable?: boolean;
  isPurchaseable?: boolean;
  isPreviewable?: boolean;
  isPrimeEligible?: boolean;
}

export interface Track {
  id?: string;
  globalAsin?: string;
  localAsin?: string;
  title?: string;
  shortTitle?: string;
  duration?: number;
  release?: Release;
  isrc?: string;
  artists?: (Pick<Artist, 'id' | 'name' | 'url'> | null)[];
  musicTerritory?: MusicTerritory;
  eligibility?: Eligibility;
  parentalSettings?: ParentalSettings;
  album?: Pick<Album, 'id' | 'title' | 'url'>;
  url?: string;
  previewUrl?: string;
  images?: (Image | null)[] | null;
  label?: string;
}

type SearchFilterField = 'asin' | 'albumAsin' | 'albumName' | 'artistName' | 'title' | 'isrc';

export interface SearchFilter {
  query?: string;
  field?: SearchFilterField;
}

export interface LikedPlaylistsResponse {
  data?: {
    user?: {
      id?: string;
      name?: string | null;
      url?: string;
      followedPlaylists?: {
        pageInfo?: PageInfo;
        edgeCount?: number;
        edges?: ({
          cursor?: string | null;
          node?: Playlist;
        } | null)[];
      };
    };
  };
}

export interface MyPlaylistsResponse {
  data?: {
    user?: {
      id?: string;
      playlists?: {
        pageInfo?: PageInfo;
        edgeCount?: number;
        edges?: ({
          cursor?: string;
          node?: Playlist;
        } | null)[];
      };
    };
  };
}

export interface LikedTracksResponse {
  data?: {
    user?: {
      id?: string;
      name?: string | null;
      url?: string;
      tracks?: {
        pageInfo?: PageInfo;
        edgeCount?: number;
        edges?: ({
          cursor?: string | null;
          likeState?: string;
          node?: {
            id?: string;
            title?: string;
            isrc?: string;
            url?: string;
          };
        } | null)[];
      };
    };
  };
}

export type SearchResponse = {
  data?: {
    searchTracks?: {
      pageInfo?: PageInfo;
      edgeCount?: number;
      edges?: ({
        node?: Track;
      } | null)[];
    };
    searchAlbums?: {
      pageInfo?: PageInfo;
      edgeCount?: number;
      edges?: ({
        node?: Album;
      } | null)[];
    };
  };
};

export interface RecentlyPlayedTracksResponse {
  data?: {
    user?: {
      id?: string;
      name?: string;
      url?: string;
      recentTrackPlayback?: {
        pageInfo?: PageInfo;
        edgeCount?: number;
        edges?: ({
          cursor?: string | null;
          playedAt?: string; // i.e.: '2022-10-03T11:31:15.859Z';
          node?: {
            id?: string;
            title?: string;
            isrc?: string;
            url?: string;
          };
        } | null)[];
      };
    };
  };
  extensions?: Record<string, unknown>;
}

export interface AlbumsResponse {
  data?: {
    albums?: (Album | null)[];
  };
}

export interface ArtistsResponse {
  data?: {
    artists: (Artist | null)[];
  };
}
