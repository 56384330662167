import React from 'react';
import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import { SerializedPlayerWidget } from 'fym-common/src/db/serializersTypes';
import { isSupportedUrlForPlayerWidget } from 'fym-common/src/utils/isSupportedUrlForPlayerWidget';
import { TypedMemo } from '../../../../../common/types';
import { WidgetListItemProps } from '../../props';
import { logosForLink } from '../../../../socialLinksList/utils';

const PlayerWidgetListItemComponent: React.FC<WidgetListItemProps<SerializedPlayerWidget>> = ({ widget }) => {
  const { data } = widget;
  const isSupportedURL = isSupportedUrlForPlayerWidget(data.url);
  const images = logosForLink(data.musicServiceType);
  const Logo = images?.logoColor?.light ?? null;
  const intl = useIntl();

  return (
    <div className="widget-list-item-text-container">
      {intl.formatMessage({
        defaultMessage: 'Music Player for',
        id: 'app.sml.widgets.player.link-to',
      })}
      {Logo && <Logo className="ms-2" />}
      {!isSupportedURL ? <FontAwesomeIcon icon={faWarning} size="2x" className="widget-list-item-svg" /> : null}
    </div>
  );
};

export const PlayerWidgetListItem = TypedMemo(PlayerWidgetListItemComponent);
