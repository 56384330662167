import React, { useCallback } from 'react';
import { SMLSlugTakenModalProps, SMLSlugTakenModalFormProps } from './props';
import { TypedMemo } from '../../../types';
import { SMLSlugTakenModalForm } from './SMLSlugTakenModalForm';
import { usePublishSmartLinkMutation, useUpdateSmartLinkMutation } from '../../../../../redux/api/fymAPI';
import { isSuccessfulResult } from '../../../../../redux/api/utils/isSuccessfulResult';

const SMLSlugTakenModalComponent: React.FC<SMLSlugTakenModalProps> = ({
  isOpen,
  closeModal,
  accountId,
  smartLink,
  setIsPublishModalOpen,
}) => {
  const [updateSmartLink, { isLoading: isUpdateSmartLinkLoading, error: updateSmartLinkError }] =
    useUpdateSmartLinkMutation();
  const [publishSmartLink, { isLoading, error }] = usePublishSmartLinkMutation();

  const onSubmit: SMLSlugTakenModalFormProps['onSubmit'] = useCallback(
    async (data) => {
      const { slug } = data;
      const updateSmartLinkResult = await updateSmartLink({
        accountId,
        slug,
        smartLinkId: smartLink.id,
      });
      if (!isSuccessfulResult(updateSmartLinkResult)) {
        return;
      }
      const publishResult = await publishSmartLink({ smartLinkId: smartLink.id, accountId });
      if (!isSuccessfulResult(publishResult)) {
        return;
      }
      closeModal();
      setIsPublishModalOpen();
    },
    [updateSmartLink, accountId, smartLink.id, publishSmartLink, closeModal, setIsPublishModalOpen]
  );

  return (
    <SMLSlugTakenModalForm
      isOpen={isOpen}
      closeModal={closeModal}
      accountId={accountId}
      smartLink={smartLink}
      onSubmit={onSubmit}
      error={updateSmartLinkError || error}
      isLoading={isUpdateSmartLinkLoading || isLoading}
    />
  );
};

export const SMLSlugTakenModal = TypedMemo(SMLSlugTakenModalComponent);
