import { CollectionItemModelData, GenericCollectionItem } from '../../../generics/models/GenericCollectionItem';
import { createYoutubeVideoUrl } from '../utils';

export class YouTubeCollectionTrack extends GenericCollectionItem {
  public globalId;

  constructor(data: CollectionItemModelData & { globalId: string }) {
    super(data);
    this.globalId = data.globalId;
  }

  static fromData(data: any): YouTubeCollectionTrack | null {
    const { thumbnails, title, resourceId } = data.snippet;
    const image = thumbnails?.high ?? thumbnails?.medium ?? thumbnails?.default;
    const globalId = resourceId?.videoId ?? data.contentDetails?.videoId;
    const rawId = data.id;
    if (!rawId || !title || !globalId) {
      return null;
    }
    return new YouTubeCollectionTrack({
      rawId,
      globalId,
      name: title,
      imageUrl: image?.url,
      sourceURL: createYoutubeVideoUrl(globalId),
    });
  }
}
