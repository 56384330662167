import React, { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faKey } from '@fortawesome/pro-solid-svg-icons/faKey';
import { FieldsName, FieldsType } from './consts';
import { resolver } from './validators';
import { FormInput } from '../../../forms/components/FormInput/FormInput';
import { InputType } from '../../../forms/types';
import { SMLRoutes } from '../../../../routing/sml/SMLRoutes';
import { useSMLRouter } from '../../../../routing/sml/hooks';
import { isSuccessfulResult } from '../../../../redux/api/utils/isSuccessfulResult';
import { useUserLoginMutation } from '../../../../redux/api/fymAPI';
import { ErrorAlert } from '../../../common/views/ErrorAlert/ErrorAlert';
import { SMLButton } from '../../../common/views/SMLButton/SMLButton';

export function useLoginForm() {
  const intl = useIntl();
  const router = useSMLRouter();
  const [userLogin, { isLoading, error }] = useUserLoginMutation();

  const onSubmit: SubmitHandler<FieldsType> = useCallback(
    async ({ email, password }) => {
      const result = await userLogin({
        email,
        password,
      });
      if (!isSuccessfulResult(result)) {
        return;
      }
      router.popToRoot();
      router.replace(SMLRoutes.home);
    },
    [router, userLogin]
  );

  const { control, handleSubmit } = useForm<FieldsType>({
    resolver,
    defaultValues: {
      [FieldsName.email]: '',
      [FieldsName.password]: '',
    },
  });

  const submit = useCallback(
    (event?: React.BaseSyntheticEvent) => {
      handleSubmit(onSubmit)(event).catch(console.error);
    },
    [handleSubmit, onSubmit]
  );

  return {
    submit,
    EmailInput: (
      <FormInput
        name={FieldsName.email}
        control={control}
        type={InputType.email}
        disabled={isLoading}
        icon={faEnvelope}
        placeholder={intl.formatMessage({
          id: 'app.forms.login.email.placeholder',
          defaultMessage: 'Enter your email',
        })}
        label={intl.formatMessage({
          id: 'app.forms.login.email.label',
          defaultMessage: 'Email',
        })}
      />
    ),
    PasswordInput: (
      <FormInput
        name={FieldsName.password}
        control={control}
        type={InputType.password}
        disabled={isLoading}
        icon={faKey}
        placeholder={intl.formatMessage({
          id: 'app.forms.login.password.placeholder',
          defaultMessage: 'Enter your password',
        })}
        label={intl.formatMessage({
          id: 'app.forms.login.password.label',
          defaultMessage: 'Password',
        })}
        autoComplete="current-password"
      />
    ),
    SubmitButton: (
      <SMLButton
        color="secondary"
        onClick={submit}
        disabled={isLoading}
        isLoading={isLoading}
        stretch
        omitCap
        size="lg"
        submit
      >
        {intl.formatMessage({ id: 'app.forms.buttons.login.label', defaultMessage: 'Log in' })}
      </SMLButton>
    ),
    ErrorView: <>{error && <ErrorAlert error={error} />}</>,
  };
}
