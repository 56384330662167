import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Col, Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { TypedMemo } from '../../common/types';
import { EmbedSmartLinkModalProps } from './props';
import { Iframe } from '../../common/views/iframe/Iframe';
import { CopyableWrapper } from '../../common/views/copyableWrapper/CopyableWrapper';
import { getEmbedSmartLink } from './getEmbedSmartLink';
import { SMLButton } from '../../common/views/SMLButton/SMLButton';

const EmbedSmartLinkModalComponent: React.FC<EmbedSmartLinkModalProps> = ({ isOpen, closeModal, embedUrl }) => {
  const intl = useIntl();
  const embedPlayerCode = getEmbedSmartLink(embedUrl);
  return (
    <Modal isOpen={isOpen} toggle={closeModal} className="embed-modal-container" centered>
      <div className="embed-modal-close">
        <Button onClick={closeModal} color="link">
          <FontAwesomeIcon icon={faTimesCircle} />
        </Button>
      </div>
      <div className="embed-modal">
        <Col className="embed-modal-player-container">
          <Iframe embedUrl={embedUrl} />
        </Col>
        <Col className="p-4 d-flex flex-column justify-content-around">
          <h3 className="ui-text">
            {intl.formatMessage({
              id: 'app.embed_smartlink_modal.title',
              defaultMessage: 'Embed Smart Link',
            })}
          </h3>
          <h6 className="text-black">
            {intl.formatMessage({
              id: 'app.embed_smartlink_modal.subtitle',
              defaultMessage: 'Place your Smart Link on your blog or website as a sleek widget',
            })}
          </h6>
          <CopyableWrapper textToCopy={embedPlayerCode}>
            <SMLButton color="primary" size="lg">
              {intl.formatMessage({
                id: 'app.embed_smartlink_modal.copy_code',
                defaultMessage: 'Copy code',
              })}
            </SMLButton>
          </CopyableWrapper>
        </Col>
      </div>
    </Modal>
  );
};

export const EmbedSmartLinkModal = TypedMemo(EmbedSmartLinkModalComponent);
