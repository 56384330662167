import { SearchQueryProperties } from '../../generics/types';
import { convertQueryPropsToString } from '../services/MatchingService.helpers';
import {
  CoverArtFromPublicSearch,
  SpotifyArtistResponseType,
  SpotifyImageResponse,
  TrackFromPublicSearch,
} from './models/types';
import { artistsToString } from '../../utils/artistsConverter';

export function constructSearchQuery(queryProps: SearchQueryProperties, advancedSearch: boolean) {
  if (!advancedSearch) {
    return encodeURIComponent(convertQueryPropsToString(queryProps));
  }

  const { track, artist, album, isrc } = queryProps;
  const queryParts: string[] = [];
  if (isrc) {
    queryParts.push(`isrc:${encodeURIComponent(isrc)}`);
  }
  if (track) {
    queryParts.push(encodeURIComponent(track));
  }
  if (artist) {
    queryParts.push(`artist:${encodeURIComponent(artist)}`);
  }
  if (album) {
    queryParts.push(`album:${encodeURIComponent(album)}`);
  }
  const separator = encodeURIComponent(' ');
  return queryParts.join(separator);
}

export function getArtistsNamesAsString(artists: SpotifyArtistResponseType[] | undefined) {
  if (!artists || artists.length === 0) {
    return undefined;
  }

  const artistsNames = artists.reduce((result, artist) => {
    if (artist.name) {
      result.push(artist.name);
    }
    return result;
  }, [] as string[]);

  return artistsToString(artistsNames);
}

export function getArtistsWithRolesNamesAsString(artistsWithRoles: any) {
  const artistsNames: string[] | undefined = artistsWithRoles?.items
    ?.map((item: any) => item?.artist?.profile?.name)
    .filter((name: string | undefined) => !!name);

  return artistsToString(artistsNames);
}

export function getArtistsNamesFromPublicSearchAsString(artists: TrackFromPublicSearch['artists']) {
  const artistsNames = artists?.items?.map((item) => item.profile?.name).filter((name): name is string => !!name);
  return artistsToString(artistsNames);
}

function filterValidCoverArtFromPublicSearch(source: SpotifyImageResponse): source is Required<SpotifyImageResponse> {
  return !!(source.url && source.height !== undefined && source.width !== undefined);
}

export function getBiggestCovertArtFromPublicSearch(coverArt: CoverArtFromPublicSearch | undefined) {
  return coverArt?.sources?.filter(filterValidCoverArtFromPublicSearch).sort((a, b) => b.height - a.height)?.[0]?.url;
}

export function createPlaylistHref(playlistID: string) {
  return `https://api.spotify.com/v1/playlists/${playlistID}`;
}

const spotifyPublicResourceUrl = 'https://open.spotify.com';

export function createSpotifyTrackUrl(trackId: string) {
  return `${spotifyPublicResourceUrl}/track/${trackId}`;
}

export function createSpotifyPlaylistUrl(playlistId: string) {
  return `${spotifyPublicResourceUrl}/playlist/${playlistId}`;
}

export function createSpotifyAlbumUrl(albumId: string) {
  return `${spotifyPublicResourceUrl}/album/${albumId}`;
}
