import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { routingSliceName } from './constants';
import { RoutingState } from './state';
import { getActiveTab } from './utils';
import { DefaultRouteNameForFYMTab, FYMTabNames } from '../../../routing/fym/FYMRoutes';
import { RouteLocation } from './types';

type SaveHistoryPayload = RouteLocation;
type PopToRootHistoryPayload = { tabName: FYMTabNames };
type PopTabHistoryPayload = { tabName: FYMTabNames };

const routingSlice = createSlice({
  name: routingSliceName,
  initialState: RoutingState,
  reducers: {
    resetRoutingSlice: () => RoutingState,
    saveHistory: ({ tabsHistory }, { payload }: PayloadAction<SaveHistoryPayload>) => {
      const { pathname, search } = payload;
      const activeTab = getActiveTab(pathname);
      if (!activeTab) {
        return;
      }
      const pathContainOnlyTabName = pathname.split('/').filter((part) => !!part).length === 1;
      if (pathContainOnlyTabName) {
        return;
      }
      const tabHistory = tabsHistory[activeTab];
      const lastTabLocation = tabHistory[tabHistory.length - 1];
      if (lastTabLocation === undefined) {
        const path = `/${activeTab}/${DefaultRouteNameForFYMTab[activeTab]}`;
        // Pushing initial route to fix routing from other tabs
        if (payload.pathname !== path) {
          tabsHistory[activeTab].push({
            pathname: path,
            search: '',
          });
        }
        tabsHistory[activeTab].push(payload);
        return;
      }
      const isSamePathname = lastTabLocation.pathname === pathname;
      const isSameSearch = lastTabLocation.search === search;
      if (isSamePathname && isSameSearch) {
        return;
      }
      if (isSamePathname && !isSameSearch) {
        lastTabLocation.search = search;
      } else {
        tabsHistory[activeTab].push(payload);
      }
    },
    popToRootHistory: (state, action: PayloadAction<PopToRootHistoryPayload>) => {
      state.tabsHistory[action.payload.tabName] = [];
    },
    popTabHistory: (state, action: PayloadAction<PopTabHistoryPayload>) => {
      state.tabsHistory[action.payload.tabName].pop();
    },
  },
});

const { actions, reducer } = routingSlice;
export const { resetRoutingSlice, saveHistory, popToRootHistory, popTabHistory } = actions;
export { reducer as routingReducer };
