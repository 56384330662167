import React, { useCallback } from 'react';
import { Button, Modal } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GenericConfirmationModalAction, GenericConfirmationModalProps } from './GenericConfirmationModalProps';
import { TypedMemo } from '../../../types';
import { LoadingIcon } from '../../LoadingIcon';

const GenericConfirmationButton: React.FC<{ action: GenericConfirmationModalAction }> = ({ action }) => {
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      action.onClick();
      return false;
    },
    [action]
  );
  return (
    <Button
      key={action.key}
      color={action.isPrimary === true ? 'primary' : 'light'}
      onClick={onClick}
      className="mx-1 ui-text"
      disabled={action.isLoading}
    >
      {action.isLoading === true && (
        <>
          <LoadingIcon small />{' '}
        </>
      )}
      {action.title}
    </Button>
  );
};

const GenericConfirmationModalComponent: React.FC<React.PropsWithChildren<GenericConfirmationModalProps>> = ({
  isOpen,
  title,
  description,
  closeButtonTitle,
  closeModal,
  actions,
  error,
  children,
  icon,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={closeModal} className="confirmation-modal-container" centered>
      <div className="confirmation-modal-content py-5 px-4">
        <div>
          <div className="d-flex justify-content-center">
            {icon !== undefined && (
              <div className="gap-2">
                <FontAwesomeIcon icon={icon} size="2x" />
              </div>
            )}
            <h3 className="text-center px-3 ui-text text-uppercase">{title}</h3>
          </div>
          <p className="text-center px-2 ui-text">{description}</p>
          {error && <p className="fw-bold text-center px-2 text-danger ui-text">{error}</p>}
        </div>
        {children}
        <div className="d-flex justify-content-around mt-5">
          <Button color="light" onClick={closeModal} className="ui-text">
            {closeButtonTitle === undefined ? (
              <FormattedMessage id="app.modals.unlink_account.close" defaultMessage="CLOSE" />
            ) : (
              closeButtonTitle
            )}
          </Button>
          {actions?.map((action) => (
            <GenericConfirmationButton key={action.key} action={action} />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export const GenericConfirmationModal = TypedMemo(GenericConfirmationModalComponent);
