import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

export interface ImageWithPlaceholderProps {
  placeholder: string;
  src: HTMLImageElement['src'];
  style?: React.CSSProperties;
  className?: string;
  alt?: string;
}

export const ImageWithPlaceholder: React.FC<ImageWithPlaceholderProps> = ({
  src,
  placeholder,
  className,
  style,
  alt = '',
}) => {
  const [imgSrc, setImgSrc] = useState(placeholder);

  const onError = useCallback(() => setImgSrc(placeholder), [placeholder]);

  useEffect(() => {
    if (!src) {
      return;
    }
    const img = new Image();
    img.onload = () => {
      setImgSrc(src);
    };
    img.src = src;
  }, [src]);

  return <img src={imgSrc} className={className} style={style} alt={alt} onError={onError} />;
};
