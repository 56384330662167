export class YandexUser {
  private readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get fullName(): string | undefined {
    return this.data.fullName;
  }

  get displayName(): string | undefined {
    return this.data.displayName;
  }

  get id(): number {
    return this.data.uid;
  }
}
// {
//   "invocationInfo": {
//     "hostname": "music-stable-back-vla-25.vla.yp-c.yandex.net",
//     "req-id": "1588610841976936-8216202276280782963",
//     "exec-duration-millis": "61"
//   },
//   "result": {
//     "uid": 1077454186,
//     "login": "mhernas",
//     "name": "mhernas",
//     "displayName": "mhernas",
//     "fullName": "Hernas Michał",
//     "verified": false,
//     "statistics": {
//       "likedUsers": 0,
//       "likedByUsers": 0,
//       "hasTracks": true,
//       "likedArtists": 0,
//       "likedAlbums": 0,
//       "ugcTracks": 0
//     },
//     "socialProfiles": []
//   }
// }
