import qs from 'qs';
import { RessoUser } from './models/RessoUser';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { FetchError } from '../../generics/errors/FetchError';
import { ImporterID } from '../types';
import { RessoPlayList } from './models/RessoPlaylist';
import { RessoPlaylistsResponse } from './models/RessoPlaylistsResponse';
import { RessoTracksResponse } from './models/RessoTracksResponse';
import { RessoCollectionTrack } from './models/RessoCollectionTrack';
import { SearchQueryProperties } from '../../generics/types';
import { RessoSearchResponse } from './models/RessoSearchResponse';
import { RessoAlbum } from './models/RessoAlbum';
import { RessoAlbumsResponse } from './models/RessoAlbumsResponse';
import { RessoAlbumTracksResponse } from './models/RessoAlbumTracksResponse';
import { RessoMySongsResponse } from './models/RessoMySongsResponse';
import { Response } from '../../utils/fetch-types';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { isServer } from '../../config/isServer';
import { constructRessoSearchQuery } from './utils/constructRessoSearchQuery';

const ressoRetryOptions: RetryOptions = {
  delay: 500,
  statusCodes: [429, 501, 502, 503, 504],
  attempts: 5,
  shouldWait: !isServer,
};

export class RessoAPI {
  private static BASE_URL = 'https://open.resso.com/api/v1/';

  private readonly accessToken;

  public userId?;

  constructor(accessToken: string, userId?: string) {
    this.accessToken = accessToken;
    this.userId = userId;
  }

  private get requestHeaders(): Record<string, string> {
    return {
      Accept: 'application/json; charset=utf-8',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.accessToken}`,
    };
  }

  async fetch(url: string, options: any, retryOptions: RetryOptions = ressoRetryOptions): Promise<Response> {
    const response = await fetchRetry(url, options, retryOptions);
    if (response.status === 401) {
      const text = await response.text();

      throw new NotAuthenticatedError({ authId: this.userId, importerId: ImporterID.Resso }, text);
    }

    return response;
  }

  async fetchMe(): Promise<RessoUser> {
    const url = `${RessoAPI.BASE_URL}me`;
    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading userinfo in Resso[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new RessoUser(data);
  }

  async loadPaginatedPlaylists(onBatch: (collections: RessoPlayList[]) => Promise<void>): Promise<void> {
    const limit = 30;
    const loadPlaylists = async (nextCursor?: string): Promise<void> => {
      const playlistResponse = await this.loadPlaylistPage(nextCursor, limit);
      await onBatch(playlistResponse.playlists);
      if (playlistResponse.nextCursor) {
        await loadPlaylists(playlistResponse.nextCursor);
      }
    };

    await loadPlaylists();
  }

  async loadPlaylistPage(cursor?: string, limit = 10): Promise<RessoPlaylistsResponse> {
    const url = `${RessoAPI.BASE_URL}me/playlists?${qs.stringify({
      limit,
      cursor,
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading playlists in Resso[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new RessoPlaylistsResponse(data);
  }

  async loadPlaylist(id: string): Promise<RessoPlayList | null> {
    const response = await this.fetch(`${RessoAPI.BASE_URL}playlists/${id}`, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (response.status !== 200) {
      const text = await response.text();
      if (response.status === 404) {
        throw new CollectionDoesNotExistsError();
      }
      throw new FetchError(
        response.status,
        `When trying to get playlist (${id}) on Resso got wrong response[${response.status}]: ${text}`
      );
    }
    const data = await response.json();
    return RessoPlayList.fromData(data);
  }

  async loadPaginatedAlbums(onBatch: (collections: RessoAlbum[]) => Promise<void>): Promise<void> {
    const limit = 30;
    const loadAlbums = async (nextPage?: string): Promise<void> => {
      const albumResponse = await this.loadAlbumPage(nextPage, limit);
      await onBatch(albumResponse.albums);
      if (albumResponse.next) {
        await loadAlbums(nextPage);
      }
    };

    await loadAlbums();
  }

  async loadAlbumPage(cursor?: string, limit?: number) {
    const url = `${RessoAPI.BASE_URL}me/albums?${qs.stringify({
      limit,
      cursor,
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading albums in Resso[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new RessoAlbumsResponse(data);
  }

  async loadAlbum(albumId: string): Promise<RessoAlbum | null> {
    const response = await this.fetch(`${RessoAPI.BASE_URL}albums/${albumId}`, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading tracks for album ${albumId} in Resso[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return RessoAlbum.fromData(data);
  }

  async loadPaginatedAlbumItems(
    album: RessoAlbum,
    onBatch: (tracks: RessoCollectionTrack[]) => Promise<void>
  ): Promise<void> {
    const loadAlbumItems = async (nextPage?: string, limit?: number): Promise<void> => {
      const trackResponse = await this.loadAlbumItemsPage(album, limit, nextPage);
      await onBatch(trackResponse.tracks);
      if (trackResponse.next) {
        await loadAlbumItems(nextPage, limit);
      }
    };

    await loadAlbumItems();
  }

  async loadAlbumItemsPage(album: RessoAlbum, limit?: number, cursor?: string): Promise<RessoAlbumTracksResponse> {
    const url = `${RessoAPI.BASE_URL}albums/${album.rawId}/tracks?${qs.stringify({
      limit,
      cursor,
    })}`;
    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });
    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading tracks for album ${album.rawId} in Resso[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new RessoAlbumTracksResponse(data, album);
  }

  async loadPaginatedMySongs(onBatch: (tracks: RessoCollectionTrack[]) => Promise<void>): Promise<void> {
    const limit = 30;
    const loadMySongs = async (nextPage?: string): Promise<void> => {
      const playlistResponse = await this.loadMySongsPage(nextPage, limit);
      await onBatch(playlistResponse.tracks);
      if (playlistResponse.nextPage) {
        await loadMySongs(nextPage);
      }
    };

    await loadMySongs();
  }

  async loadMySongsPage(cursor?: string, limit?: number): Promise<RessoMySongsResponse> {
    const url = `${RessoAPI.BASE_URL}me/tracks?${qs.stringify({
      limit,
      cursor,
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading my songs in Resso[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new RessoMySongsResponse(data);
  }

  async loadPaginatedPlaylistsItems(
    forPlaylistId: string,
    onBatch: (tracks: RessoCollectionTrack[]) => Promise<void>
  ): Promise<void> {
    const limit = 30;
    const loadTracks = async (nextToken?: string): Promise<void> => {
      const trackResponse = await this.loadPlaylistItemPage(forPlaylistId, nextToken, limit);
      await onBatch(trackResponse.tracks);
      if (trackResponse.nextPageToken) {
        await loadTracks(trackResponse.nextPageToken);
      }
    };

    await loadTracks();
  }

  async loadPlaylistItemPage(forPlaylistId: string, cursor?: string, limit?: number): Promise<RessoTracksResponse> {
    const url = `${RessoAPI.BASE_URL}playlists/${forPlaylistId}/tracks?${qs.stringify({
      limit,
      cursor,
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading playlists items in Resso[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new RessoTracksResponse(data);
  }

  async createPlaylist(name: string): Promise<RessoPlayList | null> {
    const response = await this.fetch(`${RessoAPI.BASE_URL}me/playlists`, {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        name,
        description: '',
      }),
    });

    if (response.status !== 200 && response.status !== 201) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to create playlist (${name}) on Resso got wrong response[${response.status}]: ${text}`
      );
    }
    const data = await response.json();
    return RessoPlayList.fromData(data);
  }

  async addAlbumToLibrary(name: string): Promise<void> {
    const response = await this.fetch(`${RessoAPI.BASE_URL}me/albums`, {
      method: 'PUT',
      headers: this.requestHeaders,
      body: JSON.stringify({
        ids: [name],
      }),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to add albums got wrong response[${response.status}]: ${text}`
      );
    }
    // We need to read body for NOCK in tests to register it .
    await response.text();
  }

  async addTracksToPlaylist(playlistId: string, trackIds: string[]): Promise<void> {
    const response = await this.fetch(`${RessoAPI.BASE_URL}playlists/${playlistId}/tracks`, {
      method: 'PUT',
      headers: this.requestHeaders,
      body: JSON.stringify({
        ids: trackIds,
      }),
    });

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to add tracks (${trackIds}) to Resso for playlist [${playlistId}] got wrong response[${response.status}]: ${text}`
      );
    }
    // We need to read body for NOCK in tests to register it .
    await response.text();
  }

  async removeTracksFromPlaylist(playlistId: string, trackIds: string[]): Promise<void> {
    if (trackIds.length === 0) {
      return;
    }
    const body = JSON.stringify({
      ids: trackIds,
    });
    const response = await this.fetch(`${RessoAPI.BASE_URL}playlists/${playlistId}/tracks`, {
      method: 'DELETE',
      headers: this.requestHeaders,
      body,
    });

    if (response.status !== 200 && response.status !== 201) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to remove tracks (${body}) from Resso for playlist [${playlistId}] got wrong response[${response.status}]: ${text}`
      );
    }
  }

  async search(props: {
    queryProps: SearchQueryProperties;
    searchType: 'track' | 'album';
    advancedSearch: boolean;
    limit?: number;
  }): Promise<RessoSearchResponse> {
    const { queryProps, searchType, advancedSearch, limit = 10 } = props;
    const encodedQuery = constructRessoSearchQuery(queryProps, advancedSearch);
    if (!encodedQuery) {
      return new RessoSearchResponse(null);
    }
    const url = `${RessoAPI.BASE_URL}search?${qs.stringify({
      type: searchType,
      limit: Math.max(limit, 10), // TODO min limit is set due to Resso issue: https://linear.app/hernas/issue/DEV-319/resso-search-pagination-issues
      q: encodedQuery,
    })}`;

    const response = await this.fetch(
      url,
      {
        method: 'GET',
        headers: this.requestHeaders,
      },
      { ...ressoRetryOptions, shouldWait: true }
    );

    if (response.status !== 200) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `When trying to search Resso for "${encodedQuery}" got wrong response[${response.status}]: ${text}`
      );
    }

    const jsonBody = await response.json();
    return new RessoSearchResponse(jsonBody);
  }
}
