import { ReactComponent as YandexLight } from '../../../../assets/images/music-services/yandex-light.svg';
import { ReactComponent as YandexDark } from '../../../../assets/images/music-services/yandex-dark.svg';
import { ReactComponent as YandexLightPadding } from '../../../../assets/images/music-services/yandex-light-padding.svg';
import { ReactComponent as YandexDarkPadding } from '../../../../assets/images/music-services/yandex-dark-padding.svg';
import { ReactComponent as YandexColorLight } from '../../../../assets/images/music-services/yandex-color-light.svg';
import { ReactComponent as YandexColorDark } from '../../../../assets/images/music-services/yandex-color-dark.svg';
import { ReactComponent as YandexColorLightPadding } from '../../../../assets/images/music-services/yandex-color-light-padding.svg';
import { ReactComponent as YandexColorDarkPadding } from '../../../../assets/images/music-services/yandex-color-dark-padding.svg';
import { ReactComponent as YandexDarkDanger } from '../../../../assets/images/music-services-danger/yandex-dark-danger.svg';
import { ReactComponent as YandexLightDanger } from '../../../../assets/images/music-services-danger/yandex-light-danger.svg';
import { ReactComponent as YandexLightDangerPadding } from '../../../../assets/images/music-services-danger/yandex-light-padding-danger.svg';
import { ReactComponent as YandexDarkDangerPadding } from '../../../../assets/images/music-services-danger/yandex-dark-padding-danger.svg';
import { ReactComponent as YandexDarkWarning } from '../../../../assets/images/music-services-warning/yandex-dark-warning.svg';
import { ReactComponent as YandexLightWarning } from '../../../../assets/images/music-services-warning/yandex-light-warning.svg';
import { ReactComponent as YandexLightWarningPadding } from '../../../../assets/images/music-services-warning/yandex-light-padding-warning.svg';
import { ReactComponent as YandexDarkWarningPadding } from '../../../../assets/images/music-services-warning/yandex-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const yandexImages: ImporterImages = {
  logo: {
    light: YandexLight,
    dark: YandexDark,
  },
  logoPadding: {
    light: YandexLightPadding,
    dark: YandexDarkPadding,
  },
  logoColor: {
    light: YandexColorLight,
    dark: YandexColorDark,
  },
  logoColorPadding: {
    light: YandexColorLightPadding,
    dark: YandexColorDarkPadding,
  },
  logoOnBackground: YandexColorDark,
  logoDanger: {
    dark: YandexDarkDanger,
    light: YandexLightDanger,
  },
  logoDangerPadding: {
    dark: YandexDarkDangerPadding,
    light: YandexLightDangerPadding,
  },
  logoWarning: {
    dark: YandexDarkWarning,
    light: YandexLightWarning,
  },
  logoWarningPadding: {
    light: YandexLightWarningPadding,
    dark: YandexDarkWarningPadding,
  },
};
