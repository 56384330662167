import React, { Key, useCallback, useMemo } from 'react';
import { TypedMemo } from '../../../common/types';
import { Spacer } from '../../../common/views/spacer/Spacer';
import { ButtonColor } from '../../../common/views/FYMButton/props';
import { SMLButton } from '../../../common/views/SMLButton/SMLButton';

interface FormRadioButtonProps {
  onClick: (value: Key) => void;
  checked: boolean;
  option: {
    value: Key;
    displayLabel: string;
  };
  disabled?: boolean;
}
const FormRadioButtonComponent: React.FC<FormRadioButtonProps> = ({ onClick, checked, option, disabled }) => {
  const handleClick = useCallback(() => {
    onClick(option.value);
  }, [onClick, option]);

  const color: ButtonColor = useMemo(() => {
    if (checked) {
      return 'primary';
    }
    return 'dark';
  }, [checked]);

  return (
    <Spacer marginRight={5}>
      <SMLButton onClick={handleClick} color={color} size="sm" disabled={disabled}>
        {option.displayLabel}
      </SMLButton>
    </Spacer>
  );
};

export const FormRadioButton = TypedMemo(FormRadioButtonComponent);
