import { config } from 'fym-common/src/config/config';
import { Environment } from 'fym-common/src/config/types';
import { GenericAnalytics } from './genericAnalytics';
import { AnalyticsPurchasePlan } from '../types';

export const Analytics = {
  ...GenericAnalytics,
  async track(event: string, data?: any) {
    if (config.environment === Environment.local) {
      console.log(`Analytics: ${event}\n${JSON.stringify(data)}`);
    }
    await GenericAnalytics.track(event, data);
  },
  async trackPurchaseStarted(plan: AnalyticsPurchasePlan) {
    await Analytics.track('purchase_started', {
      plan,
    });
  },
  async trackPurchaseCompleted(data: { plan: AnalyticsPurchasePlan; value?: number; currency?: string }) {
    await Analytics.track('purchase_completed', data);
  },
  async trackPurchaseRestored(plan: AnalyticsPurchasePlan) {
    await Analytics.track('purchase_restored', { plan });
  },
  async trackPurchaseFailed(code?: string, message?: string) {
    await Analytics.track('purchase_failed', { code, message });
  },
  async trackAppRating(result: 'app-rated' | 'rated-modal-closed') {
    await Analytics.track('app_rating', { result });
  },
  async trackServiceLogin(serviceId: string, state: 'started' | 'finished' | 'failed', isRelogin: boolean) {
    await Analytics.track(`service_login_${state}`, { serviceId, isRelogin });
  },
  async trackServiceReAuth(serviceId: string, state: 'started' | 'finished' | 'failed') {
    await Analytics.track(`service_reAuth_${state}`, { serviceId });
  },
  async trackExportFailedTracks(collectionId: string) {
    await Analytics.track(`export_failed_tracks`, { collectionId });
  },
  async trackExportLost(collectionId: string) {
    await Analytics.track(`export_lost_tracks`, { collectionId });
  },
  async trackCollectionSyncRemoved(collectionId: string) {
    await Analytics.track(`collection_sync_removed`, { collectionId });
  },
  async trackTransferCreated(sourceServiceId: string, targetServiceId: string) {
    await Analytics.track(`transfer_created`, { sourceServiceId, targetServiceId });
  },
  async trackSourceServiceSelected(serviceId: string) {
    await Analytics.track(`service_source_selected`, { serviceId });
  },
  async trackTargetServiceSelected(serviceId: string) {
    await Analytics.track(`service_target_selected`, { serviceId });
  },
  async trackLostLicense() {
    await Analytics.track(`license_lost_tapped`);
  },
  async trackLicenseCodeEntered() {
    await Analytics.track(`license_code_entered`);
  },
};
