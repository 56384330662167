import { MusicService } from './types';
import { MusicAPIIntegrationID } from '../types';
import { ImporterID } from '../../importers/types';
import { LinkType } from '../../utils/recognizeLinkTypeByUrl';

export const spotify: MusicService = {
  id: 'spotify',
  importerID: ImporterID.Spotify,
  linkType: LinkType.spotify,
  musicAPIID: MusicAPIIntegrationID.Spotify,
  shortName: 'Spotify',
  name: 'Spotify',
  isComingSoon: false,
  areStatsSupported: true,
  areStatsComingSoon: false,
  isSupported: true,
  color: { label: '#1BD760', background: '#1BD76088' },
  tracksPerPlaylistLimit: 10000,
  addingTracksLimit: 100, // https://developer.spotify.com/documentation/web-api/reference/add-tracks-to-playlist
};
