import React from 'react';
import { useIntl } from 'react-intl';
import { GenericConfirmationModal } from '../genericConfirmationModal/GenericConfirmationModal';
import { TypedMemo } from '../../../types';
import { RemoveAccountDataModalSMLProps } from './RemoveAccountDataModalProps';
import { Paragraph } from '../../Paragraph/Paragraph';
import { useRemoveAccountData } from './useRemoveAccountData';

const RemoveAccountDataSMLComponent: React.FC<RemoveAccountDataModalSMLProps> = ({ isVisible, closeModal }) => {
  const intl = useIntl();
  const { handleRemoveAccountData, isLoading, error } = useRemoveAccountData();
  return (
    <GenericConfirmationModal
      isOpen={isVisible}
      error={
        error
          ? intl.formatMessage({
              defaultMessage:
                'There was a problem removing your account. Please try again and if problem persist contact support.',
              id: 'app.modals.remove_account.error',
            })
          : undefined
      }
      title={intl.formatMessage({
        id: 'app.modals.unlink_account.sure',
        defaultMessage: 'Are you sure?',
      })}
      description={
        <>
          <Paragraph center>
            {intl.formatMessage(
              {
                id: 'app.modals.remove_account.info.1',
                defaultMessage: 'All your data will be removed from {appName} app and servers.',
              },
              {
                appName: 'SmartLinks',
              }
            )}
          </Paragraph>
          <Paragraph center>
            {intl.formatMessage({
              id: 'app.modals.remove_account.info.2',
              defaultMessage: 'Your subscriptions will NOT be cancelled, you need to do that on your own.',
            })}
          </Paragraph>
        </>
      }
      closeModal={closeModal}
      actions={[
        {
          key: 'delete',
          title: intl.formatMessage({
            id: 'app.modals.remove_account.cta',
            defaultMessage: 'REMOVE MY ACCOUNT',
          }),
          onClick: handleRemoveAccountData,
          isPrimary: true,
          isLoading,
        },
      ]}
    />
  );
};

export const RemoveAccountDataModalSML = TypedMemo(RemoveAccountDataSMLComponent);
