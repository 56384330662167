import { ReactComponent as SpotifyLight } from '../../../../assets/images/music-services/spotify-light.svg';
import { ReactComponent as SpotifyDark } from '../../../../assets/images/music-services/spotify-dark.svg';
import { ReactComponent as SpotifyLightPadding } from '../../../../assets/images/music-services/spotify-light-padding.svg';
import { ReactComponent as SpotifyDarkPadding } from '../../../../assets/images/music-services/spotify-dark-padding.svg';
import { ReactComponent as SpotifyColorLight } from '../../../../assets/images/music-services/spotify-color-light.svg';
import { ReactComponent as SpotifyColorDark } from '../../../../assets/images/music-services/spotify-color-dark.svg';
import { ReactComponent as SpotifyColorLightPadding } from '../../../../assets/images/music-services/spotify-color-light-padding.svg';
import { ReactComponent as SpotifyColorDarkPadding } from '../../../../assets/images/music-services/spotify-color-dark-padding.svg';
import { ReactComponent as SpotifyDarkDanger } from '../../../../assets/images/music-services-danger/spotify-dark-danger.svg';
import { ReactComponent as SpotifyLightDanger } from '../../../../assets/images/music-services-danger/spotify-light-danger.svg';
import { ReactComponent as SpotifyLightDangerPadding } from '../../../../assets/images/music-services-danger/spotify-light-padding-danger.svg';
import { ReactComponent as SpotifyDarkDangerPadding } from '../../../../assets/images/music-services-danger/spotify-dark-padding-danger.svg';
import { ReactComponent as SpotifyDarkWarning } from '../../../../assets/images/music-services-warning/spotify-dark-warning.svg';
import { ReactComponent as SpotifyLightWarning } from '../../../../assets/images/music-services-warning/spotify-light-warning.svg';
import { ReactComponent as SpotifyLightWarningPadding } from '../../../../assets/images/music-services-warning/spotify-light-padding-warning.svg';
import { ReactComponent as SpotifyDarkWarningPadding } from '../../../../assets/images/music-services-warning/spotify-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const spotifyImages: ImporterImages = {
  logo: {
    light: SpotifyLight,
    dark: SpotifyDark,
  },
  logoPadding: {
    light: SpotifyLightPadding,
    dark: SpotifyDarkPadding,
  },
  logoColor: {
    light: SpotifyColorLight,
    dark: SpotifyColorDark,
  },
  logoColorPadding: {
    light: SpotifyColorLightPadding,
    dark: SpotifyColorDarkPadding,
  },
  logoOnBackground: SpotifyDark,
  logoDanger: {
    dark: SpotifyDarkDanger,
    light: SpotifyLightDanger,
  },
  logoDangerPadding: {
    dark: SpotifyDarkDangerPadding,
    light: SpotifyLightDangerPadding,
  },
  logoWarning: {
    dark: SpotifyDarkWarning,
    light: SpotifyLightWarning,
  },
  logoWarningPadding: {
    light: SpotifyLightWarningPadding,
    dark: SpotifyDarkWarningPadding,
  },
};
