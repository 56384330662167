import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LicenseInfo } from '../../../../realm/LicenseInfo';

export enum FeatureLabel {
  Premium,
  SmartLinksRookie,
  SmartLinksInfluencer,
  SmartLinksArtist,
  Basic,
}
export type Feature = {
  title: string;
  description: string;
  labels?: FeatureLabel[];
  icon: IconProp;
  hideForSetapp?: boolean;
};

export interface FeaturesProps {
  licenseInfo: LicenseInfo;
  features: Feature[];
}
