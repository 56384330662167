import React, { useCallback } from 'react';
import { TypedMemo } from '../../common/types';
import { useAccountId } from '../../../redux/slices/appInfo/hooks';
import {
  useGetAccountQuery,
  useGetSocialLinksQuery,
  usePatchSocialLinksMutation,
  useUpdateAccountMutation,
} from '../../../redux/api/fymAPI';
import { isSuccessfulResult } from '../../../redux/api/utils/isSuccessfulResult';
import { AccountForm } from '../../forms/AccountForm/AccountForm';
import { AccountFormProps } from '../../forms/AccountForm/props';

const AccountSettingsViewComponent: React.FC = () => {
  const accountId = useAccountId();
  const {
    data: accountResponse,
    isLoading: getAccountLoading,
    error: getAccountError,
  } = useGetAccountQuery({ accountId: accountId ?? 0 }, { skip: accountId === null });
  const {
    data: socialLinksResponse,
    isLoading: getSocialLinksLoading,
    error: getSocialLinksError,
  } = useGetSocialLinksQuery(accountId ?? 0, { skip: accountId === null });
  const [updateAccount, { isLoading: updateAccountLoading, error: updateAccountError }] = useUpdateAccountMutation();
  const [patchSocialLinks, { isLoading: patchSocialLinksLoading, error: patchSocialLinksError }] =
    usePatchSocialLinksMutation();

  const isLoading = getAccountLoading || getSocialLinksLoading || updateAccountLoading || patchSocialLinksLoading;
  const error = getAccountError ?? getSocialLinksError ?? updateAccountError ?? patchSocialLinksError;

  const onSubmit: AccountFormProps['onSubmit'] = useCallback(
    async (accountData, socialLinksData) => {
      if (!accountResponse) {
        return;
      }
      const { slug, name, avatar } = accountData;
      const updateAccountResult = await updateAccount({
        id: accountResponse.account.id,
        slug,
        name,
        avatarFileId: avatar?.id,
      });
      if (!isSuccessfulResult(updateAccountResult)) {
        return;
      }
      const { create, update, remove } = socialLinksData;
      await patchSocialLinks({
        create,
        update,
        remove,
        accountId: accountResponse.account.id,
      });
    },
    [updateAccount, accountResponse, patchSocialLinks]
  );

  return (
    <AccountForm
      onSubmit={onSubmit}
      account={accountResponse?.account}
      socialLinks={socialLinksResponse?.socialLinks}
      isLoading={isLoading}
      error={error}
    />
  );
};

export const AccountSettingsView = TypedMemo(AccountSettingsViewComponent);
