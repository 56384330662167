import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { passwordSchema } from 'fym-common/src/joi/schemas';
import { FieldsName } from './consts';

export const createResolver = (isLoggedUser: boolean) => {
  const currentPasswordSchemaBase = Joi.string().label('Current Password');
  const schema = Joi.object({
    [FieldsName.currentPassword]: isLoggedUser
      ? currentPasswordSchemaBase.required()
      : currentPasswordSchemaBase.optional().allow(''),
    [FieldsName.newPassword]: passwordSchema.label('New Password').required(),
  });
  return joiResolver(schema);
};
