import React, { useCallback, useState } from 'react';
import { Button, Card, CardBody, CardTitle, Col, Modal, ModalBody, Row } from 'reactstrap';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../../common/types';
import { DownloadLinksProps } from './types';
import { openExternal } from '../../../utils/openExternal';
import { ReactComponent as AndroidDownload } from '../../../assets/images/download/download-googleplay-en.svg';
import { ReactComponent as IOSDownload } from '../../../assets/images/download/download-appstore-en-light.svg';

const iOSSmartLinkDownloadURL = 'https://geo.itunes.apple.com/us/app/smart-links-promote-music/id1629444203?mt=8';
const AndroidSmartLinkDownloadURL = 'https://play.google.com/store/apps/details?id=com.smartlinksapp.app';
const DownloadLinksRendererComponent: React.FC<React.PropsWithChildren<DownloadLinksProps>> = ({ data }) => {
  const intl = useIntl();

  const onAndroidClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    openExternal(AndroidSmartLinkDownloadURL).catch(console.error);
  }, []);
  const oniOSClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    openExternal(iOSSmartLinkDownloadURL).catch(console.error);
  }, []);

  const [macOSPickerShown, setMacOSPickerShown] = useState<boolean>(false);

  const onMacOSClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setMacOSPickerShown(true);
  }, []);
  const macOSModalToggle = useCallback(() => {
    setMacOSPickerShown(false);
  }, []);

  const onMacOSIntelClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      openExternal(data.mac.downloadURL).catch(console.error);
    },
    [data.mac.downloadURL]
  );
  const onMacOSARMClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      openExternal(data.macARM.downloadURL).catch(console.error);
    },
    [data.macARM.downloadURL]
  );
  const onWindowsClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      openExternal(data.windows.downloadURL).catch(console.error);
    },
    [data.windows.downloadURL]
  );
  const onLinuxClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      openExternal(data.linux.downloadURL).catch(console.error);
    },
    [data.linux.downloadURL]
  );
  return (
    <>
      <div className="download-buttons d-flex align-items-center">
        <div>
          <a href={AndroidSmartLinkDownloadURL} onClick={onAndroidClick}>
            <AndroidDownload className="img-fluid download-native-store-image" />
          </a>
          <a href={iOSSmartLinkDownloadURL} onClick={oniOSClick}>
            <IOSDownload className="img-fluid download-native-store-image" />
          </a>
        </div>
        <div className="text-end">
          <Button href={data.mac.downloadURL} onClick={onMacOSClick} className="download-button macos-download mb-2">
            {intl.formatMessage({
              id: 'web.download_buttons.download.macos',
              defaultMessage: 'Download macOS',
            })}
          </Button>
          <Button
            href={data.windows.downloadURL}
            onClick={onWindowsClick}
            className="download-button windows-download mb-2"
          >
            {intl.formatMessage({
              id: 'web.download_buttons.download.windows',
              defaultMessage: 'Download Windows',
            })}
          </Button>
          <Button href={data.linux.downloadURL} onClick={onLinuxClick} className="download-button linux-download">
            {intl.formatMessage({
              id: 'web.download_buttons.download.linux',
              defaultMessage: 'Download Linux',
            })}
          </Button>
        </div>
      </div>

      <Modal isOpen={macOSPickerShown} toggle={macOSModalToggle} centered>
        <ModalBody className="px-4">
          <h3 className="text-center pt-4 pb-3">
            {intl.formatMessage({
              defaultMessage: 'Select the version that’s right for your Mac',
              id: 'web.macos-version-picker.title',
            })}
          </h3>
          <Row className="py-3">
            <Col className="text-center">
              <Button block color="primary" size="lg" href={data.mac.downloadURL} onClick={onMacOSIntelClick}>
                {intl.formatMessage({
                  defaultMessage: 'Mac with Intel chip',
                  id: 'web.macos-version-picker.button.intel',
                })}
              </Button>
            </Col>
            <Col>
              <Button block color="primary" size="lg" href={data.macARM.downloadURL} onClick={onMacOSARMClick}>
                {intl.formatMessage({
                  defaultMessage: 'Mac with Apple chip',
                  id: 'web.macos-version-picker.button.apple',
                })}
              </Button>
            </Col>
          </Row>
          <Card className="my-5" color="dark">
            <CardBody>
              <CardTitle tag="h5">
                {intl.formatMessage({
                  defaultMessage: 'Check which chip your Mac has',
                  id: 'web.macos-version-picker.check.title',
                })}
              </CardTitle>
              <ol className="px-3">
                <li>
                  {intl.formatMessage({
                    defaultMessage: 'At the top left, open the Apple menu.',
                    id: 'web.macos-version-picker.check.step1',
                  })}
                </li>
                <li>
                  {intl.formatMessage({
                    defaultMessage: 'Select About This Mac.',
                    id: 'web.macos-version-picker.check.step2',
                  })}
                </li>
                <li>
                  {intl.formatMessage({
                    defaultMessage: 'In the “Overview” tab, look for “Processor” or “Chip”.',
                    id: 'web.macos-version-picker.check.step3',
                  })}
                </li>
                <li>
                  {intl.formatMessage({
                    defaultMessage: 'Check if it says “Intel” or “Apple”.',
                    id: 'web.macos-version-picker.check.step4',
                  })}
                </li>
              </ol>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </>
  );
};

export const DownloadLinksRenderer = TypedMemo(DownloadLinksRendererComponent);
