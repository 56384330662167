import { configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { setupListeners } from '@reduxjs/toolkit/query';
import { AnyAction } from 'redux';
import { decodeAPITokenJWT } from 'fym-common/src/utils/decodeAPITokenJWT';
import { Environment } from 'fym-common/src/config/types';
import { LicenseStateType } from 'fym-common/src/license/types';
import { fymApi } from 'fym-common/src/universal/fymApi/FymAPI';
import { config } from '../config/config';
import { RootState } from './root/state';
import { rootReducer } from './root/reducer';
import { fymAPI } from './api/fymAPI';
import { musicAPI } from './api/musicAPI';
import { licenseSliceName } from './slices/license/constants';
import { appInfoSliceName } from './slices/appInfo/constants';

export const createReduxStore = ({
  deviceId,
  license,
  userToken,
}: {
  deviceId: string;
  license: LicenseStateType | null;
  userToken: string | null;
}) => {
  console.log(`DeviceID: ${deviceId}`);
  fymApi.deviceId = deviceId;

  const turnOnReduxLogger = false;
  const isProduction = config.environment === Environment.production;
  const userTokenDecoded = decodeAPITokenJWT(userToken);

  const store = configureStore({
    reducer: rootReducer,
    preloadedState: {
      ...RootState,
      appInfo: {
        ...RootState[appInfoSliceName],
        deviceId,
        userToken,
        userTokenDecoded,
      },
      license: license ?? RootState[licenseSliceName],
    },
    middleware: (getDefaultMiddleware) => {
      const defaultMiddleware = getDefaultMiddleware({ serializableCheck: false });
      const middlewareArray = [...defaultMiddleware, fymAPI.middleware, musicAPI.middleware];
      return isProduction || !turnOnReduxLogger ? middlewareArray : [...middlewareArray, logger];
    },
    devTools: !isProduction,
  });
  setupListeners(store.dispatch);
  return store;
};

export type StoreType = ReturnType<typeof createReduxStore> & {
  dispatch: ThunkDispatch<ReturnType<typeof createReduxStore>['getState'], any, AnyAction>;
};

export type RootStateType = ReturnType<StoreType['getState']>;
