import React, { useMemo } from 'react';
import { TypedMemo } from '../../../../types';
import { FYMModalTextProps } from './props';
import { TextSize, TextWeight } from './types';

export function getFontSize(size?: TextSize) {
  switch (size) {
    case 'small':
      return 14;
    case 'big':
      return 26;
    case 'medium':
    default:
      return 22;
  }
}

export function getFontWeight(weight?: TextWeight) {
  switch (weight) {
    case 'light':
      return 100;
    case 'bold':
      return 700;
    case 'regular':
    default:
      return 500;
  }
}

const FYMModalTextComponent: React.FC<React.PropsWithChildren<FYMModalTextProps>> = ({
  children,
  weight,
  size,
  marginTop = 0,
  marginBottom = 0,
  color,
}) => {
  const styles: React.CSSProperties = useMemo(
    () => ({
      color,
      textAlign: 'center',
      fontWeight: getFontWeight(weight),
      fontSize: getFontSize(size),
      marginTop: `${marginTop}px`,
      marginBottom: `${marginBottom}px`,
    }),
    [color, weight, size, marginTop, marginBottom]
  );
  return (
    <span style={styles} className="ui-text">
      {children}
    </span>
  );
};

export const FYMModalText = TypedMemo(FYMModalTextComponent);
