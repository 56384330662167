import { AppleMusicCollectionTrack } from './AppleMusicCollectionTrack';
import { AppleMusicTracksRawResponse } from './types';

export class AppleMusicTracksResponse {
  private readonly data;

  constructor(data: AppleMusicTracksRawResponse | undefined) {
    this.data = data;
  }

  get tracks(): AppleMusicCollectionTrack[] {
    if (!this.data?.data) {
      return [];
    }

    return this.data.data
      .map((item) => AppleMusicCollectionTrack.fromData(item))
      .filter((track): track is AppleMusicCollectionTrack => !!track);
  }

  get next(): string | undefined {
    return this.data?.next;
  }

  get total(): number | undefined {
    return this.data?.meta?.total;
  }
}
