export enum CollectionType {
  PLAYLIST,
  MY_SONGS,
  LIKED_PLAYLIST,
  ALBUM,
  TOP_SONGS,
}

export const publicCollectionTypes = [CollectionType.LIKED_PLAYLIST, CollectionType.ALBUM];

export const CollectionTypesAvailableForStats = [
  CollectionType.PLAYLIST,
  CollectionType.LIKED_PLAYLIST,
  CollectionType.ALBUM,
];

export enum CollectionAccess {
  public = 'public',
  private = 'private',
}
