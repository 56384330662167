import { createIntl as createIntlOriginal, createIntlCache } from 'react-intl';
import { IntlErrorCode } from '@formatjs/intl';
import { translations } from '../../../i18n/utils';

const defaultOnError: Parameters<typeof createIntlOriginal>[0]['onError'] = (err) => {
  if (err.code === IntlErrorCode.MISSING_TRANSLATION) {
    // console.warn('Missing translation', err.message);
    return;
  }
  if (err.code === IntlErrorCode.MISSING_DATA) {
    // console.warn('Missing translation', err.message);
    return;
  }
  console.error(err);
};

export function createIntl({
  locale,
  onError = defaultOnError,
}: {
  locale: string;
  onError?: Parameters<typeof createIntlOriginal>[0]['onError'];
}) {
  const cache = createIntlCache();

  return createIntlOriginal(
    {
      locale,
      messages: translations[locale],
      onError,
    },
    cache
  );
}
