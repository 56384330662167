import { SpotifyCollectionTrack } from './SpotifyCollectionTrack';
import { SpotifyMySongsResponseType } from './types';

export class SpotifyMySongsResponse {
  private readonly data;

  constructor(data: SpotifyMySongsResponseType | undefined) {
    this.data = data;
  }

  get tracks(): SpotifyCollectionTrack[] {
    if (!this.data?.items) {
      return [];
    }
    return this.data.items
      .map((item) => SpotifyCollectionTrack.fromData(item.track))
      .filter((track): track is SpotifyCollectionTrack => !!track);
  }

  get next() {
    return this.data?.next;
  }

  get total(): number | undefined {
    return this.data?.total;
  }
}
