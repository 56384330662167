import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { urlSchema } from 'fym-common/src/joi/schemas';
import { FieldsName } from './consts';

const schema = Joi.object({
  [FieldsName.url]: urlSchema.required(),
});

export const resolver = joiResolver(schema);
