import { GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionAccess, CollectionType } from '../../../generics/models/Collection';
import { BoomplayPlaylistPrivacy, BoomplayPlaylistRawResponse } from './types';

function convertPrivacyToAccess(privacy: BoomplayPlaylistPrivacy | undefined) {
  switch (privacy) {
    case BoomplayPlaylistPrivacy.public:
      return CollectionAccess.public;
    case BoomplayPlaylistPrivacy.private:
      return CollectionAccess.private;
    case undefined:
    default:
      return undefined;
  }
}

export class BoomplayPlaylist extends GenericCollection {
  static fromData(data: BoomplayPlaylistRawResponse | undefined, type: CollectionType): BoomplayPlaylist | null {
    if (!data || data.playlist_id === undefined || !data.playlist_title) {
      return null;
    }
    return new BoomplayPlaylist({
      type,
      rawId: `${data.playlist_id}`,
      name: data.playlist_title,
      publicUrl: data.web_url,
      access: convertPrivacyToAccess(data.privacy),
      additionalData: {
        description: data.description,
        imageUrl: data.artwork?.url,
      },
    });
  }

  static fromShareUrlData(data: any) {
    const urlParts = data?.['@id']?.split('/');
    if (!data || !urlParts) {
      return null;
    }
    const rawId = urlParts[urlParts.length - 1];
    return new BoomplayPlaylist({
      type: CollectionType.LIKED_PLAYLIST,
      rawId,
      name: data.name,
      publicUrl: data.url ?? data['@id'],
      itemCount: data.track?.length,
    });
  }
}
