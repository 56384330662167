import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FYMAPIError } from 'fym-common/src/generics/errors/FYMAPIError';
import { TypedMemo } from '../../types';
import { ErrorAlertProps } from './props';
import { ErrorAlertRenderer } from './ErrorAlertRenderer';

const ErrorAlertComponent: React.FC<ErrorAlertProps> = ({ error, customMessage }) => {
  const intl = useIntl();
  const message = useMemo(() => {
    const isOperationalError = error instanceof FYMAPIError && error.isOperational;
    if (customMessage) {
      return customMessage;
    }
    if (isOperationalError) {
      return error.message;
    }
    return intl.formatMessage({
      id: 'app.errors.fym_api.default',
      defaultMessage: `Something went wrong. Please try again, and if the problem persist, contact support@freeyourmusic.com.`,
    });
  }, [customMessage, error, intl]);

  return <ErrorAlertRenderer message={message} />;
};

export const ErrorAlert = TypedMemo(ErrorAlertComponent);
