import { ClearingIterationCallback, GenericImporter, GenericImporterClass } from '../../generics/GenericImporter';
import { convertMatchedItemToCollectionItem } from '../../generics/typeConverter';
import { CollectionType } from '../../generics/models/Collection';
import { JioSaavnPlaylist } from './models/JioSaavnPlaylist';
import { FetchError } from '../../generics/errors/FetchError';
import { CouldNotCreateCollection } from '../../generics/errors/CouldNotCreateCollection';
import { JioSaavnAuthenticationData } from './JioSaavnAuthenticationData';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { JioSaavnAPI } from './JioSaavnAPI';
import { SearchQueryProperties } from '../../generics/types';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { GenericMatchedItem } from '../../generics/models/GenericMatchedItem';
import { GenericCollectionItem } from '../../generics/models/GenericCollectionItem';
import { GenericAuthenticationData } from '../../generics/models/GenericAuthenticationData';
import { convertQueryPropsToString } from '../services/MatchingService.helpers';
import { ImporterID } from '../types';
import { jiosaavn } from '../../musicServices/services/JioSaavn';

const createJioSaavnInstance = (authenticationData: JioSaavnAuthenticationData): JioSaavnAPI => {
  return new JioSaavnAPI(authenticationData.additionalData.cookies, authenticationData.authId);
};

export const JioSaavnImporter: GenericImporterClass<GenericImporter> = class implements GenericImporter {
  public static id = ImporterID.JioSaavn;

  public static musicService = jiosaavn;

  public authenticationData: JioSaavnAuthenticationData;

  private jioSaavnApi: JioSaavnAPI;

  constructor(authenticationData: GenericAuthenticationData) {
    this.authenticationData = authenticationData as JioSaavnAuthenticationData;
    this.jioSaavnApi = createJioSaavnInstance(this.authenticationData);
  }

  setAuthenticationData(authenticationData: GenericAuthenticationData): void {
    this.authenticationData = authenticationData as JioSaavnAuthenticationData;
    this.jioSaavnApi = createJioSaavnInstance(this.authenticationData);
  }

  async getPaginatedCollections(onBatch: (collections: JioSaavnPlaylist[]) => Promise<void>): Promise<void> {
    await onBatch(await this.jioSaavnApi.loadAllPlaylists());
  }

  async getCollection(collection: GenericCollection): Promise<JioSaavnPlaylist> {
    const playlist = await this.jioSaavnApi.loadPaginatedPlaylistsItems(collection.rawId);
    if (!playlist) {
      throw new CollectionDoesNotExistsError();
    }
    return playlist;
  }

  async createCollection(collection: GenericCollection): Promise<JioSaavnPlaylist> {
    let newCollection: JioSaavnPlaylist | null = null;
    try {
      newCollection = await this.jioSaavnApi.createPlaylist(collection.name);
    } catch (e) {
      if (e instanceof FetchError) {
        throw new CouldNotCreateCollection(e.message);
      }
      throw e;
    }
    if (!newCollection) {
      throw new CouldNotCreateCollection();
    }
    return newCollection;
  }

  async addItemToCollection(collection: GenericCollection, matchedItem: GenericMatchedItem) {
    await this.jioSaavnApi.addTrackToPlaylist(collection.rawId, matchedItem.rawId);
    return convertMatchedItemToCollectionItem(matchedItem);
  }

  async removeItemsFromCollection(
    collection: GenericCollection,
    collectionItems: GenericCollectionItem[],
    callback?: ClearingIterationCallback
  ): Promise<void> {
    if (collection.type !== CollectionType.PLAYLIST || collectionItems.length === 0) {
      return;
    }
    const itemsIds = collectionItems.map((item) => item.rawId);
    let i = 0;
    for (const itemId of itemsIds) {
      await this.jioSaavnApi.removeTrackFromPlaylist(collection.rawId, itemId);
      if (callback) {
        callback(i, itemsIds.length);
      }
      i += 1;
    }
  }

  async clearCollection(collection: GenericCollection, callback?: ClearingIterationCallback) {
    const itemsIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    await this.getPaginatedItems(collection, async (items) => {
      itemsIds.push(...items.map((item) => item.rawId));
    });
    let i = 0;
    for (const itemId of itemsIds) {
      await this.jioSaavnApi.removeTrackFromPlaylist(collection.rawId, itemId);
      if (callback) {
        callback(i, itemsIds.length);
      }
      i += 1;
    }
  }

  async matchItems(queryProps: SearchQueryProperties) {
    const query = convertQueryPropsToString(queryProps);
    const { tracks } = await this.jioSaavnApi.searchSongs(query);
    return tracks;
  }

  async reAuthenticate(_withData: GenericAuthenticationData): Promise<GenericAuthenticationData> {
    return Promise.reject(new Error('Not supported'));
  }

  doesSupportReAuth() {
    return false;
  }

  public doesSupportAlbums(): boolean {
    return false;
  }

  public doesSupportRemovingTracks(): boolean {
    return true;
  }

  public doesSupportPublishingPlaylists(): boolean {
    return true;
  }

  public doesSupportSearchByISRC(): boolean {
    return false;
  }

  public doesSupportAddingItemOnPosition(): boolean {
    return false;
  }

  public doesSupportMovingManyItems(): boolean {
    return false;
  }

  public doesSupportMovingItem(): boolean {
    return false;
  }

  async getPaginatedItems(
    forCollection: GenericCollection,
    onBatch: (items: GenericCollectionItem[]) => Promise<void>
  ): Promise<void> {
    switch (forCollection.type) {
      case CollectionType.PLAYLIST:
      case CollectionType.LIKED_PLAYLIST:
        await this.jioSaavnApi.loadPaginatedPlaylistsItems(forCollection.rawId, onBatch);
        return undefined;
      default:
        return undefined;
    }
  }

  async getCollectionPublicUrl(collection: GenericCollection) {
    const { playlist } = await this.jioSaavnApi.loadPlaylistItemPage(collection.rawId);
    return playlist?.additionalData?.permaUrl ?? null;
  }
};
