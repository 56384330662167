import { GenericCollectionItem } from '../../../generics/models/GenericCollectionItem';
import { buildArtworkUrl } from '../utils';
import { AppleMusicTrackRawResponse } from './types';

export class AppleMusicCollectionTrack extends GenericCollectionItem {
  static fromData(data: AppleMusicTrackRawResponse | undefined, url?: string): AppleMusicCollectionTrack | null {
    if (!data?.id || !data.attributes?.name) {
      return null;
    }

    const albumId = data.relationships?.albums?.data?.[0]?.id;
    const artistsIds = data.relationships?.artists?.data
      ?.map((artistData) => artistData.id)
      ?.filter((id): id is string => !!id);

    return new AppleMusicCollectionTrack({
      rawId: data.id,
      globalId: data.attributes.playParams?.catalogId ?? data.relationships?.catalog?.data?.[0]?.id,
      name: data.attributes.name,
      album: data.attributes.albumName,
      artist: data.attributes.artistName,
      additionalData: albumId || artistsIds ? { albumId, artistsIds } : undefined,
      imageUrl: buildArtworkUrl(data.attributes.artwork),
      duration: data.attributes.durationInMillis,
      sourceURL: url ?? data.attributes.url,
      isrc: data.attributes.isrc ?? data.relationships?.catalog?.data[0]?.attributes?.isrc,
    });
  }
}
