import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { availableSearchSources, SearchType } from 'fym-common/src/types/searchService';
import { TypedMemo } from '../../../common/types';
import { useLazySearchQuery } from '../../../../redux/api/musicAPI';
import { ReleaseLinkData } from './types';
import { parseSearchResults } from './utils';
import { useReleaseLinkForm } from '../forms/ReleaseLinkForm/useReleaseLinkForm';
import { ReleaseLinkWizardFormViewProps } from './props';
import { SMLAnalytics } from '../../../analytics/sml/analytics';
import { ReleaseLinkWizardFormViewRenderer } from './ReleaseLinkWizardFormViewRenderer';
import { Alert } from '../../../forms/components/Alert/Alert';
import { AlertLevel } from '../../../forms/components/Alert/props';

const ReleaseLinkWizardFormViewComponent: React.FC<ReleaseLinkWizardFormViewProps> = ({ onResults }) => {
  const intl = useIntl();
  const [search, { error: searchError, isLoading: isSearchLoading }] = useLazySearchQuery();

  const onSuccess = useCallback(
    (releaseLinkData: ReleaseLinkData) => {
      const { data, type, source } = releaseLinkData;
      if (type === SearchType.unknown) {
        onResults(releaseLinkData, []);
        return;
      }
      search({
        track: data.track ?? undefined,
        album: data.album ?? undefined,
        artist: data.artist ?? undefined,
        sources: availableSearchSources.filter((importer) => importer !== source),
        type,
      })
        .then((searchResult) => {
          if (!searchResult.isSuccess) {
            return;
          }
          const { tracks, albums } = searchResult.data;
          const items = parseSearchResults([...(tracks ?? []), ...(albums ?? [])]);

          onResults(releaseLinkData, items);
          SMLAnalytics.trackMediaSmartLinkStarted(type).catch(console.error);
        })
        .catch(console.error);
    },
    [onResults, search]
  );

  const {
    isLoading: isReleaseLinkLoading,
    error: releaseLinkError,
    submit,
    MediaUrlInput,
  } = useReleaseLinkForm({ onSuccess, useMinimalistic: false });

  return (
    <ReleaseLinkWizardFormViewRenderer
      isLoading={isReleaseLinkLoading || isSearchLoading}
      error={releaseLinkError ?? searchError}
      onSubmit={submit}
    >
      {MediaUrlInput}
      <Alert level={AlertLevel.info} center={false}>
        {intl.formatMessage({
          id: 'app.forms.track_link.help2',
          defaultMessage:
            'Using this link we will create a music service widget and search for \n' +
            'a match on all other music streaming services.',
        })}
      </Alert>
    </ReleaseLinkWizardFormViewRenderer>
  );
};

export const ReleaseLinkWizardFormView = TypedMemo(ReleaseLinkWizardFormViewComponent);
