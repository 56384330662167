import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { SmartLinkEditionView } from '../../modules/smartLinks/smartLinkEditionView/SmartLinkEditionView';
import { SmartLinkManageView } from '../../modules/smartLinks/smartLinkManageView/SmartLinkManageView';
import { SmartLinkDetailsView } from '../../modules/smartLinks/smartLinkDetailsView/SmartLinkDetailsView';
import { WidgetEditionView } from '../../modules/smartLinks/widgets/views/widgetEditionView/WidgetEditionView';
import { SmartLinksMainView } from '../../modules/smartLinks/smlHomeView/SmartLinksMainView';
import { SMLSignUpView } from '../../modules/smartLinks/smlSignUp/SMLSignUpView';
import { SMLLogInView } from '../../modules/smartLinks/smlLogIn/SMLLogInView';
import { SMLOnboarding1View } from '../../modules/smartLinks/onboarding/SMLOnboarding1View';
import { SMLOnboarding2View } from '../../modules/smartLinks/onboarding/SMLOnboarding2View';
import { SMLOnboarding3View } from '../../modules/smartLinks/onboarding/SMLOnboarding3View';
import { SMLListView } from '../../modules/smartLinks/smlListView/SMLListView';
import { AccountSettingsView } from '../../modules/smartLinks/accountSettingsView/AccountSettingsView';
import { SMLSettingsView } from '../../modules/settings/views/SMLSettingsView';
import { SMLDebugView } from '../../modules/debug/SMLDebugView';
import { SMLRouteNames } from './SMLRoutes';
import { ReleaseLinkWizardView } from '../../modules/smartLinks/wizards/releaseLink/ReleaseLinkWizardView';
import { SMLRemindPasswordView } from '../../modules/smartLinks/smlRemindPassword/SMLRemindPasswordView';
import { SMLChangePasswordView } from '../../modules/smartLinks/smlChangePassword/SMLChangePasswordView';
import { SmartLinksPremiumView } from '../../modules/premium/views/SmartLinksPremiumView';
import { TrackersListView } from '../../modules/smartLinks/trackers/list/TrackersListView';
import { TrackerEditionView } from '../../modules/smartLinks/trackers/edit/TrackerEditionView';
import { SMLDuplicationView } from '../../modules/smartLinks/smlDuplicateView/SMLDuplicationView';
import { PlaylistLinkWizardView } from '../../modules/smartLinks/wizards/playlistLink/PlaylistLinkWizardView';
import { SMLChangePasswordLoggedInView } from '../../modules/smartLinks/smlChangePassword/SMLChangePasswordLoggedInView';
import { PrivateOnlyRouteController } from '../../modules/smartLinks/authRouteControllers/PrivateOnlyRouteController';
import { PublicOnlyRouteController } from '../../modules/smartLinks/authRouteControllers/PublicOnlyRouteController';
import { SMLFormModalRouter } from './SMLFormModalRouter';

export const SMLAppNavigationRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={SMLRouteNames.home} replace />} />
      <Route
        path={SMLRouteNames.home}
        element={
          <PrivateOnlyRouteController>
            <SMLFormModalRouter path={SMLRouteNames.home}>
              <Outlet />
            </SMLFormModalRouter>
            <SmartLinksMainView />
          </PrivateOnlyRouteController>
        }
      >
        <Route path={SMLRouteNames.create} element={<SmartLinkEditionView />} />
        <Route path={SMLRouteNames.releaseLink} element={<ReleaseLinkWizardView />} />
        <Route path={SMLRouteNames.playlistLink} element={<PlaylistLinkWizardView />} />
      </Route>
      <Route
        path={SMLRouteNames.signup}
        element={
          <PublicOnlyRouteController>
            <SMLSignUpView />
          </PublicOnlyRouteController>
        }
      />
      <Route
        path={SMLRouteNames.login}
        element={
          <PublicOnlyRouteController>
            <SMLLogInView />
          </PublicOnlyRouteController>
        }
      />
      <Route
        path={SMLRouteNames.remindPassword}
        element={
          <PublicOnlyRouteController>
            <SMLRemindPasswordView />
          </PublicOnlyRouteController>
        }
      />
      <Route path={SMLRouteNames.changePassword} element={<SMLChangePasswordView />} />
      <Route path={SMLRouteNames.changePasswordLoggedIn} element={<SMLChangePasswordLoggedInView />} />
      <Route path={SMLRouteNames.premium} element={<SmartLinksPremiumView />} />
      <Route
        path={SMLRouteNames.onboarding1}
        element={
          <PrivateOnlyRouteController>
            <SMLOnboarding1View />
          </PrivateOnlyRouteController>
        }
      />
      <Route
        path={SMLRouteNames.onboarding2}
        element={
          <PrivateOnlyRouteController>
            <SMLOnboarding2View />
          </PrivateOnlyRouteController>
        }
      />
      <Route
        path={SMLRouteNames.onboarding3}
        element={
          <PrivateOnlyRouteController>
            <SMLOnboarding3View />
          </PrivateOnlyRouteController>
        }
      />
      <Route
        path={SMLRouteNames.account}
        element={
          <PrivateOnlyRouteController>
            <AccountSettingsView />
          </PrivateOnlyRouteController>
        }
      />
      <Route
        path={SMLRouteNames.all}
        element={
          <PrivateOnlyRouteController>
            <SMLListView />
          </PrivateOnlyRouteController>
        }
      />
      <Route
        path={SMLRouteNames.manage}
        element={
          <PrivateOnlyRouteController>
            <SMLFormModalRouter path={SMLRouteNames.manage}>
              <Outlet />
            </SMLFormModalRouter>
            <SmartLinkManageView />
          </PrivateOnlyRouteController>
        }
      >
        <Route path={SMLRouteNames.duplicate} element={<SMLDuplicationView />} />
      </Route>
      <Route
        path={SMLRouteNames.details}
        element={
          <PrivateOnlyRouteController>
            <SMLFormModalRouter path={SMLRouteNames.details}>
              <Outlet />
            </SMLFormModalRouter>
            <SmartLinkDetailsView />
          </PrivateOnlyRouteController>
        }
      >
        <Route path={SMLRouteNames.edit} element={<SmartLinkEditionView />} />
        <Route path={SMLRouteNames.widgetEdit} element={<WidgetEditionView />} />
      </Route>
      <Route
        path={SMLRouteNames.settings}
        element={
          <PrivateOnlyRouteController>
            <SMLSettingsView />
          </PrivateOnlyRouteController>
        }
      />
      <Route path={SMLRouteNames.debug} element={<SMLDebugView />} />
      <Route
        path={SMLRouteNames.trackers}
        element={
          <PrivateOnlyRouteController>
            <SMLFormModalRouter path={SMLRouteNames.trackers}>
              <Outlet />
            </SMLFormModalRouter>
            <TrackersListView />
          </PrivateOnlyRouteController>
        }
      >
        <Route path={SMLRouteNames.trackerEdit} element={<TrackerEditionView />} />
      </Route>
    </Routes>
  );
};
