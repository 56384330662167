import { getMetaContentByName, getMetaContentByProperty, getScriptsContents } from '../../../utils/htmlUtils';
import { buildCoverUrlFromMetaImage, convertAnghamiTrackData } from '../utils';
import { tryParseDurationToMs } from '../../../utils/tryParseDurationToMs';
import { tryParseJSON } from '../../../utils/tryParseJSON';
import { GenericMatchedItem } from '../../../generics/models/GenericMatchedItem';

export class AnghamiMatchedTrack extends GenericMatchedItem {
  static fromData(data: any): AnghamiMatchedTrack | null {
    const trackData = convertAnghamiTrackData(data);
    if (!trackData) {
      return null;
    }
    return new AnghamiMatchedTrack(trackData);
  }

  static fromShareURLData(props: {
    HTMLString: string;
    url: string;
    metaElements: HTMLCollectionOf<HTMLMetaElement>;
  }): AnghamiMatchedTrack | null {
    const { HTMLString, url, metaElements } = props;
    const scriptsContents = getScriptsContents(HTMLString);
    const dataString = scriptsContents.find(
      (content) => content.includes('context') && content.includes('MusicRecording')
    );
    const data = tryParseJSON(dataString);
    const rawId = data?.url?.split('/').pop();
    const name = data?.name;
    if (!rawId || !name) {
      return null;
    }
    const metaImageUrl = getMetaContentByProperty(metaElements, 'og:image');
    const durationString = getMetaContentByName(metaElements, 'music:duration');

    return new AnghamiMatchedTrack({
      rawId,
      name,
      album: data.inAlbum?.name,
      artist: data.byArtist?.name,
      imageUrl: buildCoverUrlFromMetaImage(metaImageUrl),
      duration: tryParseDurationToMs(durationString, 's'),
      sourceURL: data.url ?? url,
    });
  }
}
