import { GenericCollection } from '../../../generics/models/GenericCollection';
import { CollectionAccess, CollectionType } from '../../../generics/models/Collection';
import { domParser, getScriptsContents } from '../../../utils/htmlUtils';
import { buildCoverUrl, createTidalPlaylistUrl, getArtistsNamesAsString, getImageUrlFromMetaElements } from '../utils';

function convertPublicToAccess(_public: boolean | undefined) {
  switch (_public) {
    case true:
      return CollectionAccess.public;
    case false:
      return CollectionAccess.private;
    case undefined:
    default:
      return undefined;
  }
}

export class TidalPlaylist extends GenericCollection {
  static fromData(data: any, userId?: string, access?: CollectionAccess): TidalPlaylist | null {
    if (!data?.uuid || !data.title) {
      return null;
    }
    const isUserOwner = `${userId}` === `${data.creator.id}`;
    const type = isUserOwner ? CollectionType.PLAYLIST : CollectionType.LIKED_PLAYLIST;
    return new TidalPlaylist({
      type,
      rawId: data.uuid,
      name: data.title,
      itemCount: data.numberOfTracks,
      publicUrl: createTidalPlaylistUrl(data.uuid),
      access: access ?? convertPublicToAccess(data.publicPlaylist),
      additionalData: { description: data.description, imageUrl: buildCoverUrl(data.squareImage) },
    });
  }

  static fromShareURLData(HTMLString: string, url: string): TidalPlaylist | null {
    const htmlDoc = domParser.parseFromString(HTMLString);
    const scriptsContents = getScriptsContents(HTMLString);
    const playlistDataString = scriptsContents.find((content) => content.includes('context'));
    if (!playlistDataString) {
      return null;
    }
    let playlistData: any;
    try {
      playlistData = JSON.parse(playlistDataString);
    } catch (error) {
      console.error(error);
      return null;
    }
    if (!playlistData) {
      return null;
    }
    const metaElements = htmlDoc.getElementsByTagName('meta');
    const urlParts = playlistData.url.split('/');
    const rawId = urlParts[urlParts.length - 1];
    if (!rawId || !playlistData.name) {
      return null;
    }

    return new TidalPlaylist({
      rawId,
      type: CollectionType.LIKED_PLAYLIST,
      itemCount: playlistData.numTracks,
      name: playlistData.name,
      additionalData: {
        artist: getArtistsNamesAsString(playlistData.byArtist),
        imageUrl: getImageUrlFromMetaElements(metaElements),
      },
      publicUrl: url,
    });
  }
}
