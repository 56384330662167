import React, { useCallback, useEffect } from 'react';
import { tryParseInt } from 'fym-common/src/utils/tryParseInt';
import { TypedMemo } from '../../../common/types';
import { SMLRoutes } from '../../../../routing/sml/SMLRoutes';
import { useSMLRouter } from '../../../../routing/sml/hooks';
import { useQueryParam } from '../../../../utils/useQueryParam';
import { PlaylistLinkWizardViewProps } from './props';
import { SMLAnalytics } from '../../../analytics/sml/analytics';
import { SmartLinkResponse } from '../../../../redux/api/fymAPI.types';
import { PlaylistLinkWizardFormView } from './PlaylistLinkWizardFormView';

const PlaylistLinkWizardViewComponent: React.FC<PlaylistLinkWizardViewProps> = ({ route }) => {
  const router = useSMLRouter();
  const accountIdString = useQueryParam('accountId', route);
  const accountId = tryParseInt(accountIdString);

  if (accountId === undefined) {
    throw new Error(`Missing accountId:[${accountId}] in PlaylistLinkWizardView`);
  }

  const onClose = useCallback(() => {
    router.goBack(SMLRoutes.playlistLink);
  }, [router]);

  const onSuccess = useCallback(
    (response: SmartLinkResponse) => {
      router.popToRoot();
      router.push(SMLRoutes.details, { accountId, smartLinkId: response.smartLink.id });
      SMLAnalytics.trackPlaylistSmartLinkCreated().catch(console.error);
    },
    [accountId, router]
  );

  useEffect(() => {
    SMLAnalytics.trackPlaylistSmartLinkStarted().catch(console.error);
  }, []);
  return <PlaylistLinkWizardFormView accountId={accountId} onSuccess={onSuccess} onClose={onClose} />;
};

export const PlaylistLinkWizardView = TypedMemo(PlaylistLinkWizardViewComponent);
