import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import { faMagicWandSparkles } from '@fortawesome/pro-solid-svg-icons';
import { ReactComponent as WaveEmbedPlayer } from '../../../assets/images/smartLinks/embed-player-wave.svg';
import { ReactComponent as WaveAnalytics } from '../../../assets/images/smartLinks/analytics-wave.svg';
import { ReactComponent as WaveMusicPlayer } from '../../../assets/images/smartLinks/music-player-wave.svg';
import { smartLinksFeaturesForUpsell } from '../../premium/views/features/consts';
import { ReactComponent as UpsellBg } from '../../../assets/images/smartLinks/upsell-bg.svg';
import { SmartLinkUpsellFeaturesProps } from './props';
import { SMLButton } from '../../common/views/SMLButton/SMLButton';

const waves = [WaveEmbedPlayer, WaveAnalytics, WaveMusicPlayer];

export const SmartLinkUpsellFeatures: React.FC<SmartLinkUpsellFeaturesProps> = ({ onClick }) => {
  const intl = useIntl();
  return (
    <>
      <div style={{ position: 'relative' }}>
        <Container>
          <Row>
            {smartLinksFeaturesForUpsell.map((feature, index) => {
              const Wave = waves[index % waves.length] ?? WaveEmbedPlayer;
              return (
                <Col key={feature.title} className="mb-3">
                  <div className="upsell-box h-100">
                    <div className="d-flex flex-column h-100">
                      <div className="d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon icon={feature.icon} className="pt-5 upsell-icon" size="2x" />
                      </div>
                      <span className="upsell-box-title pt-2">{feature.title}</span>
                      <span className="fw-normal upsell-box-subtitle pb-2">{feature.description}</span>
                    </div>
                    <Wave className="waves d-none d-md-block" />
                  </div>
                </Col>
              );
            })}
          </Row>
          <div className="upsell-main-bg">
            <UpsellBg />
          </div>
        </Container>
      </div>
      <div className="d-flex pt-5 justify-content-center">
        <SMLButton onClick={onClick} color="secondary" size="lg" leftIcon={faMagicWandSparkles}>
          {intl.formatMessage({
            id: 'app.modals.shared-collection-upsell.upgrade',
            defaultMessage: `Upgrade`,
          })}
        </SMLButton>
      </div>
    </>
  );
};
