import { collectionsPickersReducer } from '../slices/collectionsPickers/slice';
import { collectionsPickersSliceName } from '../slices/collectionsPickers/constants';
import { appInfoReducer } from '../slices/appInfo/slice';
import { appInfoSliceName } from '../slices/appInfo/constants';
import { statsSliceName } from '../slices/stats/constants';
import { statsReducer } from '../slices/stats/slice';
import { licenseReducer } from '../slices/license/slice';
import { fymAPI } from '../api/fymAPI';
import { musicAPI } from '../api/musicAPI';
import { licenseSliceName } from '../slices/license/constants';
import { alertSliceName } from '../slices/alerts/constants';
import { alertReducer } from '../slices/alerts/slice';
import { transferCollectionsSliceName } from '../slices/transferCollections/constants';
import { transferCollectionsReducer } from '../slices/transferCollections/slice';
import { routingSliceName } from '../slices/routing/constants';
import { routingReducer } from '../slices/routing/slice';

export const rootReducer = {
  [appInfoSliceName]: appInfoReducer,
  [collectionsPickersSliceName]: collectionsPickersReducer,
  [statsSliceName]: statsReducer,
  [licenseSliceName]: licenseReducer,
  [fymAPI.reducerPath]: fymAPI.reducer,
  [musicAPI.reducerPath]: musicAPI.reducer,
  [alertSliceName]: alertReducer,
  [transferCollectionsSliceName]: transferCollectionsReducer,
  [routingSliceName]: routingReducer,
};
