import { Response } from '../fetch-types';
import { UserAgentType } from '../UserAgentType';
import { AppInformation } from '../types';

export interface PlatformBasedUtilsProps {
  appInformation?: AppInformation;
  fetch: (url: string, options: any) => Promise<Response>;
  getUserAgent: (type?: UserAgentType, mobileType?: UserAgentType) => string;
  sha256: (value: string) => Promise<string>;
  xhr: any;
}

class PlatformBasedUtils {
  private static instance: PlatformBasedUtils | undefined;

  public appInformation;

  public fetch;

  public sha256;

  public getUserAgent;

  public xhr;

  private constructor(props: PlatformBasedUtilsProps) {
    this.appInformation = props.appInformation;
    this.fetch = props.fetch;
    this.sha256 = props.sha256;
    this.getUserAgent = props.getUserAgent;
    this.xhr = props.xhr;
  }

  public static getInstance(props: PlatformBasedUtilsProps): PlatformBasedUtils {
    if (!PlatformBasedUtils.instance) {
      PlatformBasedUtils.instance = new PlatformBasedUtils(props);
    }

    return PlatformBasedUtils.instance;
  }
}

let platformBasedUtils: PlatformBasedUtils | undefined;

export function initialisePlatformBasedUtils(props: PlatformBasedUtilsProps) {
  platformBasedUtils = PlatformBasedUtils.getInstance(props);
}

export function getPlatformBasedUtils(): PlatformBasedUtils {
  if (platformBasedUtils === undefined) {
    throw new Error(`PlatformBasedUtils wasn't initialised yet!`);
  }
  return platformBasedUtils;
}
