import { ReactComponent as SoundCloudLight } from '../../../../assets/images/music-services/soundcloud-light.svg';
import { ReactComponent as SoundCloudDark } from '../../../../assets/images/music-services/soundcloud-dark.svg';
import { ReactComponent as SoundCloudLightPadding } from '../../../../assets/images/music-services/soundcloud-light-padding.svg';
import { ReactComponent as SoundCloudDarkPadding } from '../../../../assets/images/music-services/soundcloud-dark-padding.svg';
import { ReactComponent as SoundCloudColorLight } from '../../../../assets/images/music-services/soundcloud-color-light.svg';
import { ReactComponent as SoundCloudColorDark } from '../../../../assets/images/music-services/soundcloud-color-dark.svg';
import { ReactComponent as SoundCloudColorLightPadding } from '../../../../assets/images/music-services/soundcloud-color-light-padding.svg';
import { ReactComponent as SoundCloudColorDarkPadding } from '../../../../assets/images/music-services/soundcloud-color-dark-padding.svg';
import { ReactComponent as SoundCloudDarkDanger } from '../../../../assets/images/music-services-danger/soundcloud-dark-danger.svg';
import { ReactComponent as SoundCloudLightDanger } from '../../../../assets/images/music-services-danger/soundcloud-light-danger.svg';
import { ReactComponent as SoundCloudLightDangerPadding } from '../../../../assets/images/music-services-danger/soundcloud-light-padding-danger.svg';
import { ReactComponent as SoundCloudDarkDangerPadding } from '../../../../assets/images/music-services-danger/soundcloud-dark-padding-danger.svg';
import { ReactComponent as SoundCloudDarkWarning } from '../../../../assets/images/music-services-warning/soundcloud-dark-warning.svg';
import { ReactComponent as SoundCloudLightWarning } from '../../../../assets/images/music-services-warning/soundcloud-light-warning.svg';
import { ReactComponent as SoundCloudLightWarningPadding } from '../../../../assets/images/music-services-warning/soundcloud-light-padding-warning.svg';
import { ReactComponent as SoundCloudDarkWarningPadding } from '../../../../assets/images/music-services-warning/soundcloud-dark-padding-warning.svg';

import { ImporterImages } from '../../generics/ImporterImages';

export const soundCloudImages: ImporterImages = {
  logo: {
    light: SoundCloudLight,
    dark: SoundCloudDark,
  },
  logoPadding: {
    light: SoundCloudLightPadding,
    dark: SoundCloudDarkPadding,
  },
  logoColor: {
    light: SoundCloudColorLight,
    dark: SoundCloudColorDark,
  },
  logoColorPadding: {
    light: SoundCloudColorLightPadding,
    dark: SoundCloudColorDarkPadding,
  },
  logoOnBackground: SoundCloudDark,
  logoDanger: {
    dark: SoundCloudDarkDanger,
    light: SoundCloudLightDanger,
  },
  logoDangerPadding: {
    dark: SoundCloudDarkDangerPadding,
    light: SoundCloudLightDangerPadding,
  },
  logoWarning: {
    dark: SoundCloudDarkWarning,
    light: SoundCloudLightWarning,
  },
  logoWarningPadding: {
    light: SoundCloudLightWarningPadding,
    dark: SoundCloudDarkWarningPadding,
  },
};
