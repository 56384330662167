import Joi from 'joi';
import { StoreType } from '../../../redux/store';
import { isSuccessfulResult } from '../../../redux/api/utils/isSuccessfulResult';
import { intl } from '../../app/views/intl';
import { FieldsName } from '../../smartLinks/smlLogIn/LoginForm/consts';
import { fymAPI } from '../../../redux/api/fymAPI';
import { asyncDebounce } from '../../../utils/asyncDebounce';

const verifyAccountEmail = (store: StoreType, email: string) =>
  store.dispatch(fymAPI.endpoints.verifyAccountEmail.initiate({ email }));

const debouncedVerifyAccountEmail = asyncDebounce(verifyAccountEmail, 500, { leading: true });

export function getValidateEmailFn(store: StoreType) {
  return async (email: string) => {
    const validationResult = await debouncedVerifyAccountEmail(store, email);
    if (!isSuccessfulResult(validationResult)) {
      throw new Joi.ValidationError(
        'email.validationError',
        [
          {
            message: intl.formatMessage({
              id: 'app.validator.error',
              defaultMessage: `Validation error, please try again later`,
            }),
            path: [FieldsName.email],
          },
        ],
        email
      );
    }

    const { isAvailable } = validationResult.data;
    if (!isAvailable) {
      throw new Joi.ValidationError(
        'email.taken',
        [
          {
            message: intl.formatMessage(
              {
                id: 'app.validator.email.not-available',
                defaultMessage: `Email "{email}" is taken`,
              },
              {
                email,
              }
            ),
            path: [FieldsName.email],
          },
        ],
        email
      );
    }
  };
}
