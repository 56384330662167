import qs from 'qs';
import { YouTubePlaylist } from './models/YouTubePlaylist';
import { YouTubePlaylistsResponse } from './models/YouTubePlaylistsResponse';
import { YouTubeUser } from './models/YouTubeUser';
import { YouTubeCollectionTrack } from './models/YouTubeCollectionTrack';
import { YouTubeTracksResponse } from './models/YouTubeTracksResponse';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { defaultRetryOptions, fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { YouTubeSearchResponse } from './models/YouTubeSearchResponse';
import { FetchError } from '../../generics/errors/FetchError';
import { ImporterID } from '../types';
import { Response } from '../../utils/fetch-types';
import { wait } from '../../utils/wait';

export class YouTubeAPI {
  public static BASE_URL = 'https://www.googleapis.com/';

  public static PRIVATE_SECRET_KEY = '';

  private readonly accessToken: string;

  private readonly userId?: string;

  constructor(accessToken: string, userId?: string) {
    this.accessToken = accessToken;
    this.userId = userId;
  }

  private get requestHeaders(): Record<string, string> {
    return {
      Accept: 'application/json; charset=utf-8',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.accessToken}`,
    };
  }

  async loadPaginatedPlaylistsItems(
    forPlaylistId: string,
    onBatch: (tracks: YouTubeCollectionTrack[]) => Promise<void>
  ): Promise<void> {
    const loadTracks = async (nextToken?: string): Promise<void> => {
      const trackResponse = await this.loadPlaylistItemPage(forPlaylistId, nextToken);
      await onBatch(trackResponse.tracks);
      if (trackResponse.nextPageToken) {
        await loadTracks(trackResponse.nextPageToken);
      }
    };

    await loadTracks();
  }

  async fetch(
    url: string,
    options: any,
    retryOptions: RetryOptions = defaultRetryOptions,
    retried = false
  ): Promise<Response> {
    const response = await fetchRetry(url, options, retryOptions);
    if (response.status === 401) {
      const text = await response.text();
      if (!this.userId) {
        throw new NotAuthenticatedError({ importerId: ImporterID.Youtube }, text);
      }
      throw new NotAuthenticatedError({ authId: this.userId, importerId: ImporterID.Youtube }, text);
    }

    if (response.status === 429 && !retried) {
      await wait(5000);
      return this.fetch(url, options, retryOptions, true);
    }
    return response;
  }

  async loadPlaylistItemPage(forPlaylistId: string, nextToken?: string): Promise<YouTubeTracksResponse> {
    const url = `${YouTubeAPI.BASE_URL}youtube/v3/playlistItems?${qs.stringify({
      part: 'id,snippet',
      mine: true,
      maxResults: 50,
      playlistId: forPlaylistId,
      pageToken: nextToken,
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading playlists items in YouTube[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new YouTubeTracksResponse(data);
  }

  async loadPaginatedPlaylists(onBatch: (collections: YouTubePlaylist[]) => Promise<void>): Promise<void> {
    const loadPlaylists = async (nextToken?: string): Promise<void> => {
      const playlistResponse = await this.loadPlaylistPage(undefined, nextToken);
      await onBatch(playlistResponse.playlists);
      if (playlistResponse.nextPageToken) {
        await loadPlaylists(playlistResponse.nextPageToken);
      }
    };

    await loadPlaylists();
  }

  async loadPlaylistPage(playlistIds?: [string], nextToken?: string): Promise<YouTubePlaylistsResponse> {
    const params: Record<string, any> = {
      part: 'id,snippet,contentDetails,status',
      maxResults: 10,
      pageToken: nextToken,
    };
    if (playlistIds) {
      params.id = playlistIds.join(',');
    } else {
      params.mine = 'true';
    }
    const url = `${YouTubeAPI.BASE_URL}youtube/v3/playlists?${qs.stringify(params)}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading playlists in YouTube[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new YouTubePlaylistsResponse(data);
  }

  async search(props: { query: string; limit?: number; nextPageToken?: string }): Promise<YouTubeSearchResponse> {
    const { query, limit = 1, nextPageToken } = props;
    if (!query) {
      return new YouTubeSearchResponse(null);
    }
    const url = `${YouTubeAPI.BASE_URL}youtube/v3/search?${qs.stringify({
      part: 'snippet',
      maxResults: limit,
      q: query,
      type: 'video',
      pageToken: nextPageToken,
    })}`;

    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when searching in YouTube[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new YouTubeSearchResponse(data);
  }

  async createPlaylist(
    title: string,
    props?: { isPublic?: boolean; description?: string }
  ): Promise<YouTubePlaylist | null> {
    const { isPublic = false, description } = props ?? {};
    const url = `${YouTubeAPI.BASE_URL}youtube/v3/playlists?${qs.stringify({
      part: 'id,snippet,status,contentDetails',
    })}`;
    const response = await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        snippet: { title, description },
        status: {
          privacyStatus: isPublic ? 'public' : 'private',
        },
      }),
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when creating playlist in YouTube[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return YouTubePlaylist.fromData(data);
  }

  async updatePlaylist(
    playlistId: string,
    props: { title?: string; isPublic?: boolean; description?: string }
  ): Promise<YouTubePlaylist | null> {
    const { title, isPublic, description } = props;
    const url = `${YouTubeAPI.BASE_URL}youtube/v3/playlists?${qs.stringify({
      part: 'id,snippet,status,contentDetails',
    })}`;
    const response = await this.fetch(url, {
      method: 'PUT',
      headers: this.requestHeaders,
      body: JSON.stringify({
        id: playlistId,
        snippet: { title, description },
        status:
          isPublic !== undefined
            ? {
                privacyStatus: isPublic ? 'public' : 'private',
              }
            : undefined,
      }),
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when updating playlist[${playlistId}] in YouTube[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return YouTubePlaylist.fromData(data);
  }

  async removePlaylist(playlistId: string): Promise<void> {
    const url = `${YouTubeAPI.BASE_URL}youtube/v3/playlists?${qs.stringify({ id: playlistId })}`;
    const response = await this.fetch(url, { method: 'DELETE', headers: this.requestHeaders });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when removing playlist[${playlistId}] in YouTube[${response.status}]: ${text}`
      );
    }
  }

  async addItemToPlaylist(playlistId: string, videoId: string): Promise<YouTubeCollectionTrack | null> {
    const url = `${YouTubeAPI.BASE_URL}youtube/v3/playlistItems?${qs.stringify({
      part: 'snippet,status,contentDetails',
    })}`;

    const response = await this.fetch(url, {
      method: 'POST',
      headers: this.requestHeaders,
      body: JSON.stringify({
        snippet: {
          playlistId,
          resourceId: {
            kind: 'youtube#video',
            videoId,
          },
        },
      }),
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when adding item to playlist in YouTube[${response.status}]: ${text}`
      );
    }
    const data = await response.json();
    return YouTubeCollectionTrack.fromData(data);
  }

  async removeItemFromPlaylist(entryId: string): Promise<void> {
    const url = `${YouTubeAPI.BASE_URL}youtube/v3/playlistItems?id=${entryId}`;
    const response = await this.fetch(url, {
      method: 'DELETE',
      headers: this.requestHeaders,
    });
    if (response.status === 404) {
      // It means, the item was already deleted
      return;
    }

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when removing item from playlist in YouTube[${response.status}]: ${text}`
      );
    }
  }

  async moveTrackInPlaylist(playlistId: string, entryId: string, position: number, videoId: string): Promise<void> {
    const url = `${YouTubeAPI.BASE_URL}youtube/v3/playlistItems?${qs.stringify({
      part: 'snippet',
    })}`;

    const response = await this.fetch(url, {
      method: 'PUT',
      headers: this.requestHeaders,
      body: JSON.stringify({
        snippet: {
          playlistId,
          resourceId: {
            kind: 'youtube#video',
            videoId,
          },
          position,
        },
        id: entryId,
      }),
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when moving item from playlist in YouTube[${response.status}]: ${text}`
      );
    }
  }

  async fetchMe(): Promise<YouTubeUser> {
    const url = `${YouTubeAPI.BASE_URL}oauth2/v3/userinfo`;
    const response = await this.fetch(url, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new FetchError(
        response.status,
        `Got wrong response when loading /oauth2/v3/userinfo in YouTube[${response.status}]: ${text}`
      );
    }

    const data = await response.json();
    return new YouTubeUser(data);
  }
}
