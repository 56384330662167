import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { faCode } from '@fortawesome/pro-solid-svg-icons/faCode';
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy';
import { Col, Row } from 'reactstrap';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare';
import { useMediaQuery } from 'react-responsive';
import { TypedMemo } from '../../common/types';
import { SmartLinkManageViewRendererProps } from './props';
import { CopyableWrapper } from '../../common/views/copyableWrapper/CopyableWrapper';
import { SmartLinkStatsView } from '../smartLinkStatsView/SmartLinkStatsView';
import { SmartLinksDraftBadge } from '../smartLinksDraftBadge/SmartLinksDraftBadge';
import { isSmartLinkDraft } from '../utils/isSmartLinkDraft';
import { SMLBreadcrumbs } from '../smlBreadcrumbs/SMLBreadcrumbs';
import { SMLContainer } from '../smlContainer/SMLContainer';
import { useSMLRouter } from '../../../routing/sml/hooks';
import { SMLRoutes } from '../../../routing/sml/SMLRoutes';
import { SMLButton } from '../../common/views/SMLButton/SMLButton';
import { SMLPreview } from '../smlPreview/SMLPreview';

const SmartLinkManageViewRendererComponent: React.FC<SmartLinkManageViewRendererProps> = ({
  smartLink,
  openInWeb,
  openEmbedModal,
  goToSmartLinkDetailsView,
  goToSmartLinkDuplicationView,
  onDelete,
  disabledEmbedCode,
}) => {
  const intl = useIntl();
  const router = useSMLRouter();
  const {
    shareUrl,
    recentSmartLinkVersion: { name, slug },
    isMainSmartLink,
  } = smartLink;

  const isDraft = isSmartLinkDraft(smartLink);
  const title = isMainSmartLink ? intl.formatMessage({ id: 'app.sml.bio-link', defaultMessage: 'Bio Link' }) : name;

  const breadcrumbs = useMemo(() => {
    return [
      {
        name: intl.formatMessage({ id: 'app.sml.breadcrumbs.home', defaultMessage: 'Home' }),
        onClick: () => router.push(SMLRoutes.home),
      },
      {
        name: title,
      },
    ];
  }, [intl, router, title]);
  const isBootstrapLessThanLG = useMediaQuery({ query: '(max-width: 991px)' });

  return (
    <SMLContainer
      header={
        <SMLBreadcrumbs breadcrumbs={breadcrumbs} suffix={isDraft ? <SmartLinksDraftBadge size="lg" /> : null}>
          <SMLButton onClick={goToSmartLinkDuplicationView} size="md" leftIcon={faCopy} outline>
            {intl.formatMessage({ id: 'app.buttons.duplicate', defaultMessage: 'Duplicate' })}
          </SMLButton>
          {!smartLink.isMainSmartLink && (
            <>
              <div className="me-2" />
              <SMLButton onClick={onDelete} size="md" leftIcon={faTrash} color="danger" outline>
                {intl.formatMessage({ id: 'app.buttons.delete', defaultMessage: 'Delete' })}
              </SMLButton>
            </>
          )}
        </SMLBreadcrumbs>
      }
    >
      <Row className="h-100">
        <Col xs={12} lg={6}>
          <Row className="mt-5">
            <Col xs={12} md={10}>
              <div className="smart-link-manage-url-container position-relative p-4">
                <span className="smart-link-manage-url-title ui-text px-3">
                  {smartLink.isMainSmartLink
                    ? intl.formatMessage({ id: 'app.smart_link.manage.bio-link', defaultMessage: 'Your Bio Link' })
                    : intl.formatMessage({ id: 'app.smart_link.manage.subtitle', defaultMessage: 'Your Smart Link' })}
                </span>
                <div className="smart-link-manage-share-url py-3 px-4">
                  <span className="text-3-line">{shareUrl ?? slug}</span>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col className="col-auto pe-0 mb-3 me-2">
              <SMLButton color="secondary" onClick={goToSmartLinkDetailsView} leftIcon={faEdit}>
                {intl.formatMessage({ id: 'app.buttons.edit', defaultMessage: 'Edit' })}
              </SMLButton>
            </Col>
            <Col className="col-auto pe-0 mb-3">
              <SMLButton onClick={openInWeb} outline leftIcon={faArrowUpRightFromSquare}>
                {intl.formatMessage({
                  id: 'app.buttons.open',
                  defaultMessage: 'Open',
                })}
              </SMLButton>
            </Col>
            {shareUrl && (
              <Col className="col-auto pe-0 mb-3">
                <CopyableWrapper textToCopy={shareUrl}>
                  <SMLButton outline leftIcon={faLink}>
                    {intl.formatMessage({
                      id: 'app.buttons.copy_link',
                      defaultMessage: 'COPY LINK',
                    })}
                  </SMLButton>
                </CopyableWrapper>
              </Col>
            )}
            <Col className="col-auto pe-0 mb-3">
              <SMLButton outline onClick={openEmbedModal} leftIcon={faCode} disabled={disabledEmbedCode}>
                {intl.formatMessage({
                  id: 'app.buttons.embed_code',
                  defaultMessage: 'EMBED CODE',
                })}
              </SMLButton>
            </Col>
          </Row>
          <SmartLinkStatsView smartLink={smartLink} />
        </Col>
        <Col xs={12} lg={6} className="d-none d-lg-flex">
          {!isBootstrapLessThanLG && <SMLPreview smartLink={smartLink} type="column" showPublished />}
        </Col>
      </Row>
    </SMLContainer>
  );
};

export const SmartLinkManageViewRenderer = TypedMemo(SmartLinkManageViewRendererComponent);
