import { AppleMusicStorefrontRawResponse } from './types';

export class AppleStorefront {
  public data;

  constructor(data: AppleMusicStorefrontRawResponse | undefined) {
    this.data = data;
  }

  get id(): string | undefined {
    return this.data?.data?.[0]?.id;
  }
}
