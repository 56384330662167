import isArray from 'lodash/isArray';
import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';
import { VKPlaylist } from './VKPlaylist';

export class VKPlaylistsResponse {
  private readonly data: any;

  private readonly userId: any;

  constructor(data: any, userId: string) {
    this.data = data;
    this.userId = userId;
  }

  get playlists(): VKPlaylist[] {
    const { playlists } = this.data.payload[1][1];
    if (!playlists) {
      return [];
    }
    const itemsCounts = this.getItemsCountForPlaylistId();
    return playlists
      .filter((p: any) => p.type === 'playlist' && !p.isBlocked)
      .map((item: any) => VKPlaylist.fromData(item, this.userId, itemsCounts[item.id]));
  }

  getItemsCountForPlaylistId() {
    let html = this.data.payload[1][0];
    if (isArray(html)) {
      html = html.join('\n');
    }
    const regex = new RegExp(
      `data-raw-id="${this.userId}_([0-9]+?)">[^\0]*?<div class="audio_pl__stats_count _audio_pl__stats_count">([0-9]+?)</div>`,
      'gimu'
    );
    const totalCountMatches = Array.from<string[]>(html.matchAll(regex));
    return totalCountMatches.reduce<Record<string, number>>((dict, groups: string[]) => {
      const itemsCount = toNumber(groups[2]);
      if (isNaN(itemsCount)) {
        return dict;
      }
      const key = groups[1];
      if (key) {
        dict[key] = itemsCount;
      }
      return dict;
    }, {});
  }

  get nextToken(): string {
    return this.data.payload[1][1].nextFrom;
  }
}
