import { DeezerListRaw } from '../rawTypes/DeezerListRaw';
import { DeezerTrackRaw } from '../rawTypes/DeezerTrackRaw';
import { DeezerAlbumRaw } from '../rawTypes/DeezerAlbumRaw';
import { DeezerTracksResponse } from './DeezerTracksResponse';
import { DeezerAlbumsResponse } from './DeezerAlbumsResponse';
import { DeezerAlbum } from './DeezerAlbum';
import { DeezerMatchedTrack } from './DeezerMatchedTrack';

export class DeezerSearchResponse {
  private readonly data;

  private readonly type;

  constructor(data: DeezerListRaw<DeezerTrackRaw | DeezerAlbumRaw> | null, type: 'track' | 'album') {
    this.data = data;
    this.type = type;
  }

  get tracks(): DeezerMatchedTrack[] {
    if (this.type === 'album') {
      return [];
    }
    return new DeezerTracksResponse(this.data).tracks;
  }

  get albums(): DeezerAlbum[] {
    if (this.type === 'track') {
      return [];
    }
    return new DeezerAlbumsResponse(this.data).albums;
  }

  get total() {
    return this.data?.total;
  }

  get next() {
    return this.data?.next;
  }
}
