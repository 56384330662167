import { CollectionItemModelData, GenericCollectionItem } from '../../../generics/models/GenericCollectionItem';
import { DeezerTrackRaw } from '../rawTypes/DeezerTrackRaw';
import { DeezerAlbum } from './DeezerAlbum';
import { convertDeezerTrackData } from '../utils';

export class DeezerCollectionTrack extends GenericCollectionItem {
  constructor(data: CollectionItemModelData, public albumExtended: DeezerAlbum | null = null) {
    super(data);
  }

  static fromData(data: DeezerTrackRaw): DeezerCollectionTrack | null {
    const trackData = convertDeezerTrackData(data);
    if (!trackData) {
      return null;
    }
    return new DeezerCollectionTrack(
      { ...trackData, globalId: trackData.rawId },
      data.album ? DeezerAlbum.fromData(data.album, data.artist) : null
    );
  }
}
