import React from 'react';
import { Col, Row } from 'reactstrap';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { useIntl } from 'react-intl';
import { TypedMemo } from '../../common/types';
import { SMLStatsBox } from './SMLStatsBox/SMLStatsBox';
import { SMLStatsProps } from './props';
import { boldIntlText } from '../../common/views/bold/boldIntlText';

const SMLStatsComponent: React.FC<React.PropsWithChildren<SMLStatsProps>> = ({
  pageViews,
  onStatsClick,
  uniques,
  isSmall,
}) => {
  const intl = useIntl();
  return (
    <Row>
      <Col
        xs={12}
        sm={isSmall === true ? 12 : 6}
        md={isSmall === true ? 6 : 4}
        lg={isSmall === true ? 6 : 3}
        className="mb-3"
      >
        <SMLStatsBox count={pageViews} onClick={onStatsClick} waveColor="pink" icon={faEye}>
          {intl.formatMessage(
            {
              id: 'app.sml.stats.views_today',
              defaultMessage: 'VIEWS / <bold>TODAY</bold>',
            },
            {
              bold: boldIntlText,
            }
          )}
        </SMLStatsBox>
      </Col>

      <Col
        xs={12}
        sm={isSmall === true ? 12 : 6}
        md={isSmall === true ? 6 : 4}
        lg={isSmall === true ? 6 : 3}
        className="mb-3"
      >
        <SMLStatsBox count={uniques} onClick={onStatsClick} waveColor="blue" icon={faUsers}>
          {intl.formatMessage(
            {
              id: 'app.sml.stats.users_today',
              defaultMessage: 'VISITORS / <bold>TODAY</bold>',
            },
            {
              bold: boldIntlText,
            }
          )}
        </SMLStatsBox>
      </Col>
    </Row>
  );
};

export const SMLStats = TypedMemo(SMLStatsComponent);
