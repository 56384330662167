/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'reactstrap';
import { TypedMemo } from '../../../../common/types';
import { FeatureBoxRendererProps } from './props';
import { featureLabelToBackgroundColor, featureLabelToTranslatableMessage } from './featureLabelToTranslatableMessage';

const FeatureBoxRendererComponent: React.FC<FeatureBoxRendererProps> = ({
  feature,
  color,
  isDisabled = false,
  darkerBackground,
}) => {
  const intl = useIntl();
  return (
    <Col
      sm={12}
      md={5}
      className={classnames('opacity-bg feature-box', {
        'opacity-bg-darker': darkerBackground,
        'opacity-56': isDisabled,
      })}
    >
      {feature.labels !== undefined && feature.labels.length > 0 && (
        <div className="feature-box-premium-label-container">
          {feature.labels.map((label, index) => (
            <div
              key={index}
              className="feature-box-premium-label"
              style={{ backgroundColor: featureLabelToBackgroundColor(label) }}
            >
              <span>{intl.formatMessage(featureLabelToTranslatableMessage(label))}</span>
            </div>
          ))}
        </div>
      )}

      <div className="d-flex">
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={feature.icon} className={`me-3 ${color}`} size="2x" />
        </div>
        <div>
          <span className="feature-box-title">{feature.title}</span>
          <span className="fw-light">{feature.description}</span>
        </div>
      </div>
    </Col>
  );
};

export const FeatureBoxRenderer = TypedMemo(FeatureBoxRendererComponent);
