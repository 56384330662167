import { SerializedPublicAccountTrackerType } from 'fym-common/src/db/serializersTypes';
import { ReactComponent as Meta } from '../../../../assets/images/trackers/meta.svg';
import { ReactComponent as GoogleAnalytics } from '../../../../assets/images/trackers/google-analytics.svg';
import { ReactComponent as GoogleTagManager } from '../../../../assets/images/trackers/google-tag-manager.svg';
import { ReactComponent as Plausible } from '../../../../assets/images/trackers/plausible.svg';

export function getUIDataForTrackerType(trackerType: SerializedPublicAccountTrackerType) {
  switch (trackerType) {
    case SerializedPublicAccountTrackerType.facebookPixel:
      return { Logo: Meta, name: 'Meta Pixel', color: '#0068e0' };
    case SerializedPublicAccountTrackerType.googleAnalyticsLegacy:
      return { Logo: GoogleAnalytics, name: 'Google Analytics', color: '#e37400' };
    case SerializedPublicAccountTrackerType.googleAnalytics:
      return { Logo: GoogleAnalytics, name: 'Google Analytics', color: '#e37400' };
    case SerializedPublicAccountTrackerType.googleGlobalSite:
      return { Logo: GoogleAnalytics, name: 'Google Analytics', color: '#e37400' };
    case SerializedPublicAccountTrackerType.googleTagManager:
      return { Logo: GoogleTagManager, name: 'Google Tag Manager', color: '#8ab4f8' };
    case SerializedPublicAccountTrackerType.plausible:
      return { Logo: Plausible, name: 'Plausible', color: '#6574cd' };
    default:
      return undefined;
  }
}
