import { GenericCollectionItem } from '../../../generics/models/GenericCollectionItem';
import { YouTubeMusicAlbum } from './YouTubeMusicAlbum';
import { getItemText, nav, YouTubeMusicParser } from '../youTubeMusicParser';
import { createYouTubeMusicTrackUrl } from '../utils';
import { tryParseInt } from '../../../utils/tryParseInt';

export class YouTubeMusicAlbumTrack extends GenericCollectionItem {
  static fromData(data: any, album: YouTubeMusicAlbum | null): YouTubeMusicAlbumTrack | null {
    if (!data?.musicResponsiveListItemRenderer) {
      return null;
    }
    const track = data.musicResponsiveListItemRenderer;
    let duration: string | undefined;
    if (track.fixedColumns) {
      if (track.fixedColumns?.[0]?.musicResponsiveListItemFixedColumnRenderer?.text?.simpleText) {
        duration = track?.fixedColumns?.[0]?.musicResponsiveListItemFixedColumnRenderer?.text?.simpleText;
      } else {
        duration = track?.fixedColumns?.[0]?.musicResponsiveListItemFixedColumnRenderer?.text?.runs?.[0]?.text;
      }
    }

    let parsedDuration: number | undefined;
    if (duration) {
      const [minutes, seconds] = duration.split(':');
      parsedDuration = ((tryParseInt(minutes) ?? 0) * 60 + (tryParseInt(seconds) ?? 0)) * 1000;
    }
    const title = getItemText(track, 0);
    const playButton = nav(track, YouTubeMusicParser.PLAY_BUTTON);
    const videoId = playButton.playNavigationEndpoint?.watchEndpoint.videoId ?? track.playlistItemData.videoId;
    const entryId = track.playlistItemData.playlistSetVideoId;
    if (!entryId || !title) {
      return null;
    }
    return new YouTubeMusicAlbumTrack({
      rawId: entryId,
      globalId: videoId,
      name: title,
      album: album?.name,
      artist: album?.additionalData?.artist,
      imageUrl: album?.additionalData?.imageUrl,
      duration: parsedDuration,
      sourceURL: createYouTubeMusicTrackUrl(videoId),
    });
  }
}
