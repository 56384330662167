import qs from 'qs';
import iconv from 'iconv-lite';
import { defaultRetryOptions, fetchRetry, RetryOptions } from '../../utils/fetch-retry';
import { NotAuthenticatedError } from '../../generics/errors/NotAuthenticatedError';
import { FetchError } from '../../generics/errors/FetchError';
import { VKSearchResponse } from './models/VKSearchResponse';
import { VKPlaylistsResponse } from './models/VKPlaylistsResponse';
import { VKPlaylist } from './models/VKPlaylist';
import { VKPlaylistWithTracksResponse } from './models/VKPlaylistWithTracksResponse';
import { VKCollectionTrack } from './models/VKCollectionTrack';
import { xhrFetchRetry } from '../../utils/xhrFetch-retry';
import { GenericCollection } from '../../generics/models/GenericCollection';
import { getUserAgent } from '../../utils/getUserAgent';
import { UserAgentType } from '../../utils/UserAgentType';
import { ImporterID } from '../types';
import { CollectionDoesNotExistsError } from '../../generics/errors/CollectionDoesNotExistsError';
import { RequestHeaders } from '../../utils/fetch-types';
import { VKAuthenticationData } from './VKAuthenticationData';

export class VKAPI {
  private static APP_ID = '6287487';

  private static APP_VERSION = '5.231';

  public static LOGIN_URL = 'https://m.vk.com/login?u=2';

  private static BASE_URL = 'https://vk.com/al_audio.php';

  public readonly cookies: { [key: string]: string };

  public readonly userId: string;

  public readonly accessToken: string | undefined;

  constructor(cookies: { [key: string]: string }, userId: string) {
    this.cookies = cookies;
    this.userId = userId;
  }

  private get requestHeaders(): RequestHeaders {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest',
      Cookie: Object.keys(this.cookies)
        .map((key) => {
          return `${key}=${this.cookies[key]}`;
        })
        .join('; '),
      'User-Agent': getUserAgent(UserAgentType.Desktop),
    };
  }

  async fetch(
    url: string,
    options: any,
    retryOptions: RetryOptions = defaultRetryOptions
  ): Promise<{ [key: string]: any }> {
    const response = await xhrFetchRetry(url, { ...options, responseType: 'arraybuffer' }, retryOptions);
    const text = iconv.decode(await response.asBuffer(), 'win1251');

    if (response.status === 401 || response.status === 403) {
      throw new NotAuthenticatedError({ authId: this.userId, importerId: ImporterID.VK }, text);
    }
    const data = JSON.parse(text.startsWith('<!--') ? text.substring('<!--'.length) : text);
    if (`${data.payload[0]}` === '3') {
      throw new NotAuthenticatedError({ authId: this.userId, importerId: ImporterID.VK }, text);
    }
    if (`${data.payload[0]}` !== '0') {
      throw new FetchError(400, JSON.stringify(data.payload[1]));
    }
    return data;
  }

  static async fetchMe(cookies: { [key: string]: string }): Promise<VKAuthenticationData> {
    const { access_token: accessToken } = await this.fetchToken(cookies);
    const response = await fetchRetry(
      `https://api.vk.com/method/account.getProfileNavigationInfo?v=${VKAPI.APP_VERSION}&client_id=${VKAPI.APP_ID}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Referer: 'https://vk.com/',
          Origin: 'https://vk.com',
          'Content-Type': 'application/x-www-form-urlencoded',
          Cookie: Object.keys(cookies)
            .map((key) => {
              return `${key}=${cookies[key]}`;
            })
            .join('; '),
          'User-Agent': getUserAgent(UserAgentType.Mobile),
        },
        body: qs.stringify({
          v: VKAPI.APP_VERSION,
          app_id: VKAPI.APP_ID,
          access_token: accessToken,
        }),
      }
    );
    const data = await response.json();
    const user = data?.response?.account_navigation_info;
    if (!user) {
      throw new Error(`Could not fetch VK user: ${JSON.stringify(data)}`);
    }
    return {
      userUUID: null,
      authId: `${user.user_id}`,
      title: user.domain ?? `${user.user_id}`,
      subTitle: [user.first_name, user.last_name].filter((p) => !!p).join(' ') || null,
      imageUrl: user.photo_200 ?? null,
      expiresAt: null, // We have `expiresIn` property when fetching token, but it is short lived (few minutes)
      // and we dont use the token for anything except fetching user profile
      additionalData: {
        cookies,
      },
    };
  }

  static async fetchToken(cookies: { [key: string]: string }): Promise<{
    access_token: string;
    expires: number;
    logout_hash: string;
    user_id: number;
  }> {
    const response = await fetchRetry(`https://login.vk.com/?act=web_token`, {
      method: 'POST',
      headers: {
        Referer: 'https://vk.com/',
        Origin: 'https://vk.com',
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Cookie: Object.keys(cookies)
          .map((key) => {
            return `${key}=${cookies[key]}`;
          })
          .join('; '),
        'User-Agent': getUserAgent(UserAgentType.Desktop),
      },
      body: qs.stringify({
        version: 1,
        app_id: VKAPI.APP_ID,
      }),
    });
    const data = await response.json();
    if (data.type !== 'okay') {
      throw new Error(data.error_info);
    }

    return data.data;
  }

  async loadPaginatedPlaylists(onBatch: (collections: VKPlaylist[]) => Promise<void>): Promise<void> {
    const loadPlaylists = async (nextToken?: string): Promise<void> => {
      const playlistResponse = await this.loadPlaylistPage(nextToken);
      await onBatch(playlistResponse.playlists);
      if (playlistResponse.nextToken && playlistResponse.playlists.length > 0) {
        await loadPlaylists(playlistResponse.nextToken);
      }
    };

    await loadPlaylists();
  }

  async loadPlaylistPage(nextToken?: string): Promise<VKPlaylistsResponse> {
    let bodyData: Record<string, any> = {
      act: 'section',
      al: '1',
      section: 'playlists',
      owner_id: this.userId,
    };
    if (nextToken) {
      const [startFrom, sectionId] = nextToken.split(' ');
      bodyData = {
        act: 'load_catalog_section',
        al: '1',
        section_id: sectionId,
        start_from: startFrom,
      };
    }
    const data = await this.fetch(`${VKAPI.BASE_URL}`, {
      method: 'POST',
      headers: this.requestHeaders,
      body: qs.stringify(bodyData),
    });
    return new VKPlaylistsResponse(data, this.userId);
  }

  async loadPaginatedPlaylistItems(
    collection: GenericCollection,
    onBatch: (tracks: VKCollectionTrack[]) => Promise<void>
  ): Promise<VKPlaylist | null> {
    const loadItems = async (offset: number): Promise<VKPlaylist | null> => {
      const itemsResponse = await this.loadPlaylistItemPage(collection, offset);
      await onBatch(itemsResponse.tracks);
      if (itemsResponse.hasMore) {
        return loadItems(itemsResponse.nextOffset);
      }
      return itemsResponse.playlist;
    };

    return loadItems(0);
  }

  async loadPlaylistItemPage(playlist: GenericCollection, offset = 0): Promise<VKPlaylistWithTracksResponse> {
    const ownerId = playlist.additionalData?.ownerId ?? this.userId;
    const accessHash = playlist.additionalData?.accessHash ?? '';
    const data = await this.fetch(`${VKAPI.BASE_URL}?act=load_section`, {
      method: 'POST',
      headers: this.requestHeaders,
      body: qs.stringify({
        access_hash: accessHash,
        al: '1',
        claim: '0',
        context: 'my_playlists',
        from_id: this.userId,
        is_loading_all: '1',
        is_preload: '0',
        offset,
        owner_id: ownerId,
        playlist_id: playlist.rawId,
        type: 'playlist',
      }),
    });

    if (data.payload?.[1]?.[0]?.ownerId === undefined) {
      throw new CollectionDoesNotExistsError();
    }
    return new VKPlaylistWithTracksResponse(data, this.userId);
  }

  async search(query: string): Promise<VKSearchResponse> {
    if (!query) {
      return new VKSearchResponse(null);
    }
    const data = await this.fetch(`${VKAPI.BASE_URL}`, {
      method: 'POST',
      headers: this.requestHeaders,
      body: qs.stringify({
        access_hash: '',
        act: 'load_section',
        al: '1',
        al_ad: '0',
        claim: '0',
        offset: '0',
        owner_id: this.userId,
        // "playlist_id": "-494012199",
        search_history: '0',
        search_q: query,
        track_type: 'default',
        type: 'search',
      }),
    });
    return new VKSearchResponse(data);
  }

  async createPlaylist(name: string): Promise<VKPlaylist | null> {
    const hash = await this.getNewPlaylistHash();
    if (!hash) {
      throw new Error('Could not get playlist creation hash from VK');
    }
    const data = await this.fetch(`${VKAPI.BASE_URL}?act=save_playlist`, {
      method: 'POST',
      headers: this.requestHeaders,
      body: qs.stringify({
        Audios: '',
        al: '1',
        cover: '0',
        description: 'Created by FreeYourMusic.com',
        hash,
        owner_id: this.userId,
        playlist_id: '0',
        title: name,
      }),
    });
    return VKPlaylist.fromData(data.payload[1][0], this.userId);
  }

  async getNewPlaylistHash(): Promise<string | undefined> {
    const response = await xhrFetchRetry(`https://vk.com/audios${this.userId}`, {
      method: 'GET',
      headers: this.requestHeaders,
    });

    const text = iconv.decode(await response.asBuffer(), 'windows-1251', {
      stripBOM: true,
    });
    const matches = text.match(/"newPlaylistHash":"(.+?)"/i);
    if (!matches) {
      return undefined;
    }
    return matches[1];
  }

  async addTracksToPlaylist(
    collection: GenericCollection,
    trackIds: { id: string; searchId: string }[]
  ): Promise<VKPlaylist | null> {
    const allPreviousTracksIds: string[] = [];
    // eslint-disable-next-line @typescript-eslint/require-await
    const playlist = await this.loadPaginatedPlaylistItems(collection, async (tracks) => {
      allPreviousTracksIds.push(...tracks.map((track) => `${track.rawId}_`));
    });
    return this.setTracksInPlaylist(
      collection,
      [
        ...allPreviousTracksIds,
        ...trackIds.map(({ id, searchId }) => {
          return `${id}_${searchId}`;
        }),
      ],
      playlist
    );
  }

  async setTracksInPlaylist(
    collection: GenericCollection,
    trackIds: string[],
    playlist?: VKPlaylist | null
  ): Promise<VKPlaylist | null> {
    let finalPlaylist = playlist;
    if (!finalPlaylist) {
      finalPlaylist = (await this.loadPlaylistItemPage(collection)).playlist;
    }
    if (!finalPlaylist) {
      throw new CollectionDoesNotExistsError();
    }
    const data = await this.fetch(`${VKAPI.BASE_URL}?act=save_playlist`, {
      method: 'POST',
      headers: this.requestHeaders,
      body: qs.stringify({
        Audios: trackIds.join(','),
        al: '1',
        description: finalPlaylist.description,
        hash: finalPlaylist.editHash,
        owner_id: this.userId,
        playlist_id: collection.rawId,
        title: finalPlaylist.name,
      }),
    });
    return VKPlaylist.fromData(data.payload[1][0], this.userId);
  }
}
