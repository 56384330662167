import { SearchQueryProperties } from '../../../generics/types';
import { convertQueryPropsToString } from '../../services/MatchingService.helpers';

export function constructRessoSearchQuery(queryProps: SearchQueryProperties, advancedSearch: boolean) {
  if (!advancedSearch) {
    return encodeURIComponent(convertQueryPropsToString(queryProps));
  }

  const { track, artist, album, isrc } = queryProps;
  const queryParts: string[] = [];
  const separator = encodeURIComponent(' ');

  if (isrc) {
    queryParts.push(`isrc:${encodeURIComponent(isrc)}`);
  }
  if (track) {
    queryParts.push(`name:${encodeURIComponent(track)}`);
  }
  if (artist) {
    queryParts.push(`artist:${encodeURIComponent(artist)}`);
  }
  if (album) {
    queryParts.push(`album:${encodeURIComponent(album)}`);
  }

  return queryParts.join(separator);
}
